<template>
  <v-app id="inspire">
    <!-- <v-parallax
      style="height:100%"
       src="../../assets/kids.png"
      
      
    > -->
    <v-main>
      <v-container class="fill-height" fluid style="background-color:teal">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5">
            <!-- <v-img width="250" height="120" class="mx-auto" src="../../assets/school_pilot.png"/> -->
            <v-card class="elevation-12">
              <v-toolbar color="#006633" dark flat>
                <v-toolbar-title>Parent Login</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form
                  class="text-xs-center"
                  dark="dark"
                  ref="form"
                  lazy-validation
                >
                  <v-container id="dropdown-example-3">
                    <v-text-field
                      @keyup.enter="onTrack"
                      id="studentid"
                      success
                      required
                      v-model="selectedStudent"
                      label="Enter student id"
                      name="studentid"
                      prepend-icon="mdi-account"
                      type="text"
                    ></v-text-field>
                    <v-text-field
                      @keyup.enter="onLogin"
                      id="parentmobile"
                      success
                      required
                      v-model="parentMobile"
                      label="Enter Mobile Number"
                      name="parentmobile"
                      prepend-icon="mdi-phone"
                      type="text"
                    ></v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onTrack" class="white--text" color="#006633"
                  >Track</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- </v-parallax> -->
  </v-app>
</template>

<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      selectedStudent: null,
      parentMobile: null
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  created() {
    // reset login status
    // this.$store.dispatch("authentication/logout");
  },
  methods: {
    onTrack() {
      if (this.selectedStudent!= '' && this.parentMobile != '') {
        var studentid = this.selectedStudent;
        var parentmobile = this.parentMobile;
        this.$store.dispatch("authentication/parentlogin", {
          studentid,
          parentmobile
        });
      }
    }
  }
};
</script>
