<script>
import Vue from "vue";
import moment from "moment";
export default {
  name: "busStopProgress",
  data() {
    return {
      mode: null,
      users: JSON.parse(localStorage.getItem("alcotrackerparentuser")),
      oplist: [],
      event: {},
      mark: {},
      viewResult: true,
      loading: "Nodata Found"
    };
  },
  props: ["data"],
  mounted() {
    this.operationview();
    setTimeout(() => {
      setInterval(() => {
        this.operationview();
      }, 800)
    }, 1000)
  },
  // beforeMount() {
  //   console.log(this.data)
  //   Vue.axios
  //     .get("https://robopower.xyz/app/v2data/getdeviceoperations?devid=" + this.data.Vehicle.cbid)
  //     .then(response => {
  //       if (response.data != null) {
  //         var oplist = response.data;
  //         this.oplist = (oplist.sort((a, b) => a.id - b.id)).reverse();
  //         console.log(this.oplist)
  //       }
  //     })

  // },
  created() {
  },
  beforeUnmount() {
  },
  beforeDestroy() { },
  methods: {
    operationview() {
      // console.log(this.data)
      Vue.axios
        .get("https://robopower.xyz/app/v2data/getdeviceoperations?devid=" + this.data.Vehicle.cbid)
        .then(response => {
          if (response.data != null) {
            this.loading = "Loading...."
            var oplist = response.data;
            this.oplist = (oplist.sort((a, b) => a.id - b.id)).reverse();
            // console.log(this.oplist)
          }
        })
    },
    getEventtype(name) {
      if (name == "85") {
        return "VIOLATION RESET"
      } else if (name == "84") {
        return "ON DEMAND TEST"
      } else if (name == "82") {
        return "DEVICE RESTART"
      } else if (name == "83") {
        return "PICTURE CAPTURED"
      } else if (name == "88") {
        return "VEHICLE START"
      } else if (name == "89") {
        return "VEHICLE STOP"
      } else {
        return null
      }
    },
    getEvent(element) {
      if (element.typeofevent == 254) {
        //initialtest
        if (
          element.randominitial == 2 ||
          element.randominitial == 0
        ) {
          if (element.passfailstatus == 1) {
            return "PASS TEST";
          } else if (element.passfailstatus == 0) {
            return "FAIL TEST";
          } else {
            return " ";
          }
        } else if (
          element.randominitial == 3 ||
          element.randominitial == 1
        ) {
          return "RANDOM TEST";
        } else {
          return "NA";
        }

      } else if (element.typeofevent == 15) {
        if (element.enginerunstate == 0) {
          return "ENGINE OFF";
        } else if (element.enginerunstate == 1) {
          return "ENGINE ON";
        } else {
          return "NA";
        }
      } else if (element.typeofevent == 65) {
        return "INSUFFICIENTVOLUME";
      } else if (element.typeofevent == 66) {
        return "HARD_BLOW";
      } else if (element.typeofevent == 65) {
        return "INSUFFICIENTVOLUME";
      } else if (element.typeofevent == 21) {
        return "CAR_BATTERY_TAMPERING";
      } else if (element.typeofevent == 19) {
        return "VIOLATION_LOCKOUT";
      } else if (element.typeofevent == 4) {
        return "BYPASS";
      } else if (element.typeofevent == 18) {
        return "TEST_REFUSED_RR_TEST";
      } else if (element.typeofevent == 28) {
        return "TAMPERED";
      } else if (element.typeofevent == 22) {
        return "CALIBRATION DONE";
      } else if (element.typeofevent == 29) {
        return "START_TEST_ATTEMPT";
      } else if (element.typeofevent == 34) {
        return "ENGINE_NOT_STARTED";
      } else if (element.typeofevent == 27) {
        return "COOL_SAMPLE";
      } else if (element.typeofevent == 25) {
        return "HOT_SAMPLE";
      } else if (element.typeofevent == 8) {
        return "ONE_TIME_PASS_CODE";
      } else if (element.typeofevent == 6) {
        return "SERVICE_PERIOD_SET";
      } else if (element.typeofevent == 7) {
        return "VOILATION_RESET";
      } else if (element.typeofevent == 11) {
        return "LOG_READ";
      } else if (element.typeofevent == 220) {
        return "LOCKOUT 0";
      } else if (element.typeofevent == 221) {
        return "LOCKOUT 1";
      } else if (element.typeofevent == 222) {
        return "LOCKOUT 2";
      } else if (element.typeofevent == 223) {
        return "LOCKOUT 3";
      } else if (element.typeofevent == 224) {
        return "LOCKOUT 4";
      } else if (element.typeofevent == 225) {
        return "LOCKOUT 5";
      } else if (element.typeofevent == 226) {
        return "LOCKOUT 6";
      } else if (element.typeofevent == 227) {
        return "LOCKOUT 7";
      } else if (element.typeofevent == 56) {
        return "TAKE_A_PICTURE";
      } else if (element.typeofevent == 101) {
        return "PICTURE_CAPTURED";
      } else if (element.typeofevent == 60) {
        return "BYPASS_START";
      } else if (element.typeofevent == 61) {
        return "BYPASS_STOP";
      } else if (element.typeofevent == 14) {
        return "SERVICE_ALERT";
      } else if (element.typeofevent == 34) {
        return "ENGINE_NOT_STARTED";
      } else if (element.typeofevent == 62) {
        return "SAMPLER_DISCONNECTED";
      } else if (element.typeofevent == 10) {
        return "CAR_BATTERY_OFF";
      } else if (element.typeofevent == 20) {
        return "CAR_BATTERY_ON";
      } else if (element.typeofevent == 217) {
        return "MEMSET";
      } else if (element.typeofevent == 219) {
        return "TIME_SET";
      } else if (element.typeofevent == 218) {
        return "DEVICE RESTART";
      } else if (element.typeofevent == 151) {
        return "ALCOHOL_SENSOR_FAIL";
      } else if (element.typeofevent == 152) {
        return "BREATH_PRESSURE_SEN_FAIL";
      } else if (element.typeofevent == 153) {
        return "BREATH_TEMP_SEN_FAIL";
      } else if (element.typeofevent == 154) {
        return "BMP180_ALTITUDE_FAIL";
      } else if (element.typeofevent == 155) {
        return "PROXIMITY_SENSOR_FAIL";
      } else if (element.typeofevent == 156) {
        return "TMP75_AMB_TEMP_SENS_FAIL";
      } else if (element.typeofevent == 157) {
        return "RTC_FAIL";
      } else {
        return "NA"
      }

    },
    onviewResult(myop) {
      console.log(myop)
      let promises = [];
      var itemname2 = myop.actionusatime;
      var eventtype = this.getEventtype(myop.operation);
      for (var i = -120; i < 120; i++) {
        // console.log(i)
        // Start second check
        // if(Math.sign(i)== -1){
        //    itemname2 = moment(myop.actionusatime).subtract(i, 'seconds').format('MM/DD/YYYY HH:mm:ss');
        // console.log(itemname2)
        // } else{
        itemname2 = moment(myop.actionusatime).add(i, 'seconds').format('MM/DD/YYYY HH:mm:ss');
        // console.log(itemname2)
        // }
        //  itemname2 = moment(itemname2).add(i, 'seconds').format('MM/DD/YYYY HH:mm:ss');
        // console.log(itemname2)
        promises.push(Vue.axios
          .get(
            "https://robopower.xyz/us/calogs/getoperationevent?cbid=" + this.data.Vehicle.cbid + "&rtime=" + itemname2 + "&event=" +
            eventtype
          ))
      }
      Promise.all(promises).then((res) => {
        for (i = 0; i < res.length; i++) {
          if (res[i].data) {
            console.log(res[i].data)
            this.event = res[i].data;
            this.viewResult = false;
            break;
          } else {
            console.log("noooo")
            if (i == res.length - 1) {
              this.$store.dispatch("alert/error", "NO EVENT FOUND").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
          }
        }
      }).catch((error) => {
        this.$store.dispatch("alert/error", error).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })

    },
    onClose() {
      this.oplist = [];
      this.viewResult = true;

    }

  }
};
</script>

<template>
  <div style="background-color: aliceblue;margin-bottom: -10%;">
    <div class="button"></div>
    <v-flex xs12>
      <div v-if="oplist">
        <v-card v-for="myop in oplist" style="padding: 20px;margin-bottom:10px;" :key="myop.id" color="#009688"
          class="white--text">
          <div>Triggered By: {{ myop.triggeredBy }}</div>
          <div>Operation: {{ getEventtype(myop.operation) }}</div>
          <v-row>
            <v-col cols="12">
              <div class="progress">
                <div class="step" :class="{ done: 1, reached: 1 }">
                  <div class="step-progress"></div>
                  <div style="margin-top: 1%;">Action Triggered</div>
                  <div class="icon-wrapper">
                    <svg class="icon icon-checkmark" viewBox="0 0 32 32">
                      <path class="path1" d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
                    </svg>
                    <div class="step-text">{{ myop.indiatime.split(' ')[0] }}<span><br />{{ myop.indiatime.split(' ')[1].slice(0,8)}}</span></div>
                  </div>
                </div>
                <div class="step" :class="{ done: 1, reached: myop.status == 'SUCCESS' ? 1 : 0 }">
                  <div class="step-progress"></div>
                  <div style="margin-top: 1%;">
                    Result Time</div>
                  <div class="icon-wrapper">
                    <svg class="icon icon-checkmark" viewBox="0 0 32 32">
                      <path class="path1" d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
                    </svg>
                    <div class="step-text">{{ myop.actionindiatime.split(' ')[0] }}<span><br />{{
                      myop.actionindiatime.split(' ')[1].slice(0, 8) }}</span></div>
                  </div>
                </div>
                <div class="step" :class="{ done: myop.status == 'SUCCESS' ? 1 : 0, reached: 0 }">
                  <div style="margin-top: 15%;">Result</div>
                  <div class="icon-wrapper">
                    <svg class="icon icon-checkmark" viewBox="0 0 32 32">
                      <path class="path1" d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
                    </svg>
                    <div class="step-text">{{ myop.status }}</div>
                  </div>
                </div>
              </div>
            </v-col>
            &nbsp; &nbsp; &nbsp;
          </v-row>
          <div>
          </div>
          &nbsp;
        </v-card>
      </div>
      <div v-else>
        <span>{{ this.loading }}</span>
      </div>
    </v-flex>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin-left: 10px;
}

.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: none;
}

.hidden {
  display: none;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-left: 10px;
}

.step {
  flex-grow: 1;
  position: relative;
}

.white--text {
  height: 170px;
  opacity: 0.9;
  font-size: x-small;
}

.step-progress {
  width: 100%;
  height: 5px;
  background: white;
}

.icon-wrapper {
  text-align: center;
  display: inline-block;
}

.step.done .step-progress:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 0;
  background-color: #00695c;
  animation: growLine 2s linear forwards;
}

.icon-checkmark {
  position: absolute;
  top: -0.55em;
  left: -0.125em;
  border: 0.125em solid #e0e0e0;
  background: #e0e0e0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  padding: 0.125em;
  border-radius: 50%;
  transition: all 0.25s linear;
}

.step.done .icon-checkmark,
.step.reached .icon-checkmark {
  background: #55c9ff;
  border-color: #004d40;
}

.icon-checkmark .path1 {
  stroke: #aaa;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: transparent;
}

.step.done .icon-checkmark .path1 {
  animation: dash 5s linear forwards;
  stroke: #00403a;
}

.step-text {
  position: absolute;
  letter-spacing: 1px;
  /* font-weight: bold; */
  top: -70px;
  left: -35px;
  color: white;
  /* margin-top: 0; */
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-size: 10px;
  width: 100px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.step:nth-child(odd) .step-text {
  top: 50px;
  left: -10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  text-align: left;
}

.step:last-child {
  max-width: 40px;
}

.step:last-child .step-text {
  left: -40px;
  top: 60px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  text-align: left;
}

.step.done .step-text {
  color: white;
  /* color: dodgerblue; */
}

.end {
  position: absolute;
  right: 0;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes growLine {
  to {
    width: 100%;
  }
}

@keyframes dropText {
  to {
    padding-top: 1em;
    opacity: 1;
  }
}
</style>
