<script>
//import getInfo from "../scripts/getInfo";
import {mapGetters} from 'vuex';
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    states: [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
      "INDIA"
    ],
    // access: [{ text: "Admin" }, { text: "Manager" }, { text: "Technician" }],
    headers: [
      // { text: "ID", value: "officeId" },
      {
        text: "Name",
        align: "left",
        value: "officeName"
      },
      { text: "Phone", value: "officeMobile1" },
      // { text: "Phone 2", value: "officeMobile2" },
      { text: "Dealer", value: "dealer" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" }
    ],
    editedIndex: -1,
    defcountry:'us',
    editedItem: {
      form:"",
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework:"",
      officepassword:"",
      officeaddress:"",
      city:"",
      state:"",
      zipcode:"",
      isbar:"1",
      officelicense:"",
      dealerName: ""
    },
    defaultItem: {
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework:"",   
      officepassword:"",
      officeaddress:"",
      city:"",
      state:"",
      zipcode:"",
      isbar:"1",
      officelicense:"",
      dealerName: ""
    },
    valid: true,
    cityRules: [
      v => !!v || 'City is required',
      v => v && v.length >= 3 || 'City must contain 3 or more characters',
      ],
      stateRules: [
        v => !!v || 'Select State',
      ],
      zipRules: [
      v => !!v || 'Zip is required',
      v => v && v.length == 5 || 'Enter valid zip code',
      ],
    nameRules: [
      v => !!v || 'Name is required',
      v => v && v.length >= 3 || 'Name must contain 3 or more characters',
      v => v && v.length <= 50 || 'Name must be less than 50 characters'
    ],
    idRules: [
      v => !!v || 'Id is required',
    ],
    //  phoneRules: [
    //     v => !!v || "Phone is Required",
    //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
    //     v => v != 0 || "Phone Number can't be zeoro!"
    // ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 10 && v.length <= 14) || "Enter valid phone number",
    ],
      phoneRulesa: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
      phone2Rulesa: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
      ],
     passwordRules: [
        v => !!v || 'Password is required',
        v => v && v.length >= 8 || 'Password must contain 8 or more charactors'
      ],
      addressRules: [
      v => !!v || 'Address is required',
      ],
      licenseRules: [
      v => !!v || 'Bar License is required',
      ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v =>
          (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "E-mail must be valid"
      ],
     phone2Rules: [
        v => !!v || "Phone2 is Required",
        v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
        v => v != 0 || "Phone Number can't be zeoro!"
    ],
    //   phoneRules: [
    //   v => !!v || 'Phone is required',
    //   v => v && v.length > 10 && v.length < 15 || 'Enter valid phone number',
    // ],
    
    dealerRules: [
      v => !!v || 'Select Dealer',
    ],
  }),

  computed: {
    resultQuery() {
      if(this.expanded.length>0 ){
      var mylist = this.expanded;
        return mylist
      } else{
        return this.offices
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Office" : "Edit Office";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['offices']),
    ...mapGetters(['dealers'])
  },

  watch: {
    defcountry(val) {
      this.defcountry = val;
      setTimeout(() => {
        this.defcountry = 'us'
      },500)
    },
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    if(this.user.role == "dealer") {
      this.editedItem.dealerName = this.user.dealerId;
    } 
    this.mountdata();
  },

  methods: {
    onSelect({ dialCode }) {
      this.editedItem.officehome = dialCode;
    },
    onSelect2({ dialCode }) {
      this.editedItem.officework = dialCode;
    },
        isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mountdata(){
     this.$store.dispatch('fetchDealers')
    if(this.user.role == "Admin"|| this.user.role == 'superadmin'){
    this.$store.dispatch('fetchOffices')
    } else {
    this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
    }
    },
    deleteOffice(office) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Are you sure? You want to delete Office - ' + office.officeName,
          text: 'You can\'t revert your action',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes Delete it!',
          cancelButtonText: 'No, Keep it!',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch('deleteOffice', office.officeId).then(() => {
              this.mounteddata();
            }).catch((err) => {
              console.log("first")
              this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            })
            // this.$swal('Deleted', 'You successfully deleted this file', 'success')
            console.log("second")
            this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })

          } else {
            // this.$swal('Cancelled', 'Your file is still intact', 'info')
            console.log("third")
            this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officehome + this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officework + this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    editloadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    initialize() {},

    editItem(item) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
       // console.log(item)
      this.editedIndex = this.offices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true; 
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
      
    },

    close() {
      this.dialog = false;
      this.defcountry = 'US';
      this.$refs.form.reset();
      this.editedIndex = -1;
      
    },
    
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if(this.editedItem.officeMobile2 == undefined)
        this.editedItem.officeMobile2 = "";

      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch('createOffice', fd).then(()=>{
          this.mountdata();
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
      } else {
        const editfd = this.editloadFormData()
        var postdata ={
          odata:editfd,
          id:this.editedItem.officeId
        }
        this.$store.dispatch('editOffice', postdata).then(()=>{
          this.mountdata();
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
       }
          this.close();
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px !important">
      <v-data-table
        dense
        :headers="headers"
        :items="resultQuery"
        item-key="officeId"
        sort-by="office_name"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          
             <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
            <v-toolbar flat color="#00A3A3" dark dense class="mb-2" style="border-radius:20px">
              <span style="font-size:40%">
              <v-toolbar-title>OFFICES</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="620px">
                  <template v-slot:activator="{ on }">
         <v-row>
          <v-col cols="12" mx="4">
           <v-layout justify-end>
                <!-- <v-btn color="black"   class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px" >
                  Add Office
                </v-btn> -->
                 <!-- <v-icon v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on"  size="40px"  color="white" style="background:black;border-radius:50%">mdi-plus</v-icon> -->
                 <v-btn  v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on" style="border-radius:20px;background:black"> <h2>+</h2><v-icon size="20px"  color="dark">mdi-home-modern</v-icon></v-btn>
           </v-layout>
              </v-col>
                  </v-row>
          </template>
                <v-card style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col v-if="user.role == 'Admin'|| user.role == 'superadmin'" cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense outlined
                            required
                            :rules="dealerRules"
                            v-model="editedItem.dealer"
                            :items="dealers"
                            label="Select Dealer*"
                            item-text="dealerName"
                            item-value="dealerName"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            required outlined
                            v-on:keypress="isLetter($event)"
                            :rules="nameRules"
                            dense
                            v-model="editedItem.officeName"
                            label="Name*"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            required
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="phoneRules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeMobile1"
                            label="Phone*"
                          ></v-text-field>
                        </v-col> -->
                        <!-- <div v-if="editedIndex == -1"> -->





                          
                        <div class="left-btn" v-if="editedIndex == -1">
                                                  <vue-country-code
                                                  v-model="editedItem.officehome"
                                                      @onSelect="onSelect"
                                                      :enabledCountryCode="true"
                                                      :defaultCountry='defcountry'
                                                      style="border:none"
                                                      :preferredCountries="['in', 'us', 'gb']"
                                                    >
                                    <input type="text"  name="phone" id="phone" placeholder="phone number" />
                                  </vue-country-code>
                        </div>
                        
                            <div class="left-btn ml-2" v-if="editedIndex == -1">
                                <v-text-field
                                dense outlined
                                required
                                :rules="phoneRulesa"
                                v-on:keypress="isNumber($event)"
                                v-model="editedItem.officeMobile1"
                                label="Phone*"  style="width:200px"
                              ></v-text-field>
                            </div>
                            <v-col cols="12" sm="12" md="6" v-else>
                              <v-text-field
                                dense outlined
                                required
                                :rules="phoneRules"
                                v-on:keypress="isNumber($event)"
                                v-model="editedItem.officeMobile1"
                                label="Phone*" 
                              ></v-text-field>
                            </v-col>
                        <!-- <div v-if="editedIndex == -1"> -->
                         
                              <div class="left-btn">
                                    <vue-country-code
                                      v-model="editedItem.officework"
                                          @onSelect="onSelect2"
                                          :enabledCountryCode="true"
                                          :defaultCountry='defcountry'
                                          style="border:none"
                                          :preferredCountries="['in', 'us', 'gb']"
                                        >
                                        <input type="text" name="phone" id="phone" placeholder="phone number" />
                                    </vue-country-code>
                              </div>
                        
                              <div class="left-btn ml-2" v-if="editedIndex == -1">
                                  <v-text-field
                                  dense outlined
                                  required
                                  :rules="phone2Rulesa"
                                  v-on:keypress="isNumber($event)"
                                  v-model="editedItem.officeMobile2"
                                  label="Phone 2" style="width:200px"
                                ></v-text-field>
                              </div>
                      <!-- </div> -->
                              <v-col cols="12" sm="12" md="6" v-else>
                                  <v-text-field
                                    dense outlined
                                    required
                                    :rules="phoneRules"
                                    v-on:keypress="isNumber($event)"
                                    v-model="editedItem.officeMobile2"
                                    label="Phone 2"
                                  ></v-text-field>
                                </v-col>









                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="emailRules"
                            v-model="editedItem.officeemail"
                            label="Email*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="passwordRules"
                            v-model="editedItem.officepassword"
                            label="Password*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="addressRules"
                            v-model="editedItem.officeaddress"
                            label="Address*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="cityRules"
                            v-model="editedItem.city"
                            label="City*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense outlined
                            required
                            :rules="stateRules"
                            v-model="editedItem.state"
                            :items="states"
                            label="Select State*"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="zipRules"
                            v-model="editedItem.zipcode"
                            label="Zipcode*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                              <v-checkbox
                              false-value="0"
                              true-value="1"
                              v-model="editedItem.isbar"
                              :label="`isBAR License`"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="licenseRules"
                            v-model="editedItem.officelicense"
                            :label="editedItem.isbar == '1'?'BAR License':'BEARHFTI License'"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="close"  style="background:#ff3d41;color:white">Cancel</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="save">{{buttonTitle}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
             </v-form>
          
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteOffice(item)">
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item}">
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
         
            <v-col class="col-md-6">
              <v-card class="mt-2 mb-2" color="#334c3e" style="border-radius:20px;padding:10px">
              <v-list dense class="ma-0 pa-0" style="background:#334c3e">
               <v-list-item>
                 <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                  <v-list-item-content class="content1">ID:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeId }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-account</v-icon>
                  <v-list-item-content class="content1">Name:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeName }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                  <v-list-item-content class="content1">Phone:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeMobile1 }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-cellphone-basic</v-icon>
                  <v-list-item-content class="content1">Phone 2:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeMobile2 }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon class="off-icon">mdi-email-outline</v-icon>
                  <v-list-item-content class="content1">Email:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeemail }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon class="off-icon">mdi-home-outline</v-icon>
                  <v-list-item-content class="content1">Address:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeaddress }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-book-open</v-icon>
                  <v-list-item-content class="content1">Bar License 2:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officelicense }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-account-tie</v-icon>
                  <v-list-item-content class="content1">Dealer:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.dealer }}</v-list-item-content>
                </v-list-item>
           </v-list>
            </v-card>
            </v-col>
         
            
           
           </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
.content2
{
  color:#14c7c7 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}
</style>
