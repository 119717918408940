<template>
  <div v-if="!isMobile()">
    <div ref="map" class="map-containergeo"></div>
  </div>
  <div v-else>
    <div ref="map" class="map-containergeo1"></div>
  </div>
</template>
  
<script>
import mapboxgl from 'mapbox-gl';

export default {
  data() {
    return {
      map: null,
      markers: [
        { id: 1, name: 'Marker 1', coordinates: [-74.5, 40] },
        { id: 2, name: 'Marker 2', coordinates: [-74.6, 40.1] },
        // Add more markers as needed
      ],
      geofenceCoordinates: [[-74.5, 40], [-74.6, 40], [-74.6, 40.1], [-74.5, 40.1], [-74.5, 40]] // Define geofence boundary coordinates
    };
  },
  mounted() {
    mapboxgl.accessToken = "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";

    this.map = new mapboxgl.Map({
      container: this.$refs.map,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74.5, 40],
      zoom: 9
    });

    this.addMarkers();
    this.addGeofence();
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobileview = true;
        return true
      } else {
        this.mobileview = false;
        return false
      }
    },
    addMarkers() {
      this.markers.forEach(marker => {
        new mapboxgl.Marker()
          .setLngLat(marker.coordinates)
          .setPopup(new mapboxgl.Popup().setHTML(`<h3>${marker.name}</h3>`))
          .addTo(this.map);
      });
    },
    addGeofence() {
      this.map.on('load', () => {
        this.map.addLayer({
          id: 'geofence',
          type: 'fill',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [this.geofenceCoordinates]
              }
            }
          },
          layout: {},
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.4
          }
        });
      });
    }
  }
};
</script>
  
<style scoped>
.map-containergeo {
  height: 300px;
  width: 80%;
  margin-left: 9%;
}

.map-containergeo1 {
  height: 300px;
  width: 95%;
  display: inline-flex;
  margin-left: 2%;
}
</style>