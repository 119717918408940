import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import VueSignaturePad from 'vue-signature-pad';
import DatetimePicker from "vuetify-datetime-picker";
// import i18n from "./i18n";
// import VueToParser from "vue-html-to-paper";
import VueApexCharts from "vue-apexcharts";
import {BootstrapVue,BootstrapVueIcons} from "bootstrap-vue"
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQRCodeComponent from 'vue-qrcode-component'
import VuePapaParse from 'vue-papa-parse';
import VueCountryCode from "vue-country-code";
import JwPagination from 'jw-vue-pagination';
import "bootstrap-vue/dist/bootstrap-vue.min.js"
import "bootstrap-vue/dist/bootstrap-vue.min.css"
import { Bar, Line,mixins } from 'vue-chartjs';
// import VuePrompt from 'vue-prompt';



// Vue.use(VuePrompt);
Vue.use(VueCountryCode);
window.Event = new Vue();
Vue.prototype.$eventBus = new Vue();
Vue.use(VueSignaturePad);
Vue.use(DatetimePicker);
Vue.use(VuePapaParse)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('jw-pagination', JwPagination);
Vue.prototype.$http = axios;
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component('bar-chart', Bar);
Vue.component('line-chart', Line);
Vue.component('mixins', mixins);

Vue.config.productionTip = false;
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://api.tiles.mapbox.com/mapbox-gl-js/v2.6.0/mapbox-gl.css',
    'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css',
    'https://api.tiles.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);
new Vue({
  vuetify,
  store,
  router,
  // i18n,
  render: h => h(App),
 
}).$mount('#app')
