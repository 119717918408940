<template>
  <v-app>
       <div v-if="alert.message">
          <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="-1"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ alert.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="clear"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
       </div>
      <router-view></router-view>
  </v-app>
</template>
<script>

export default {
  name: 'App',
   data () {
      return {
        color: '',
        mode: '',
        snackbar: true,
        x: null,
        y: 'top',
      }
    },
    
computed: {
        alert () {
            /* eslint-disable no-console */
            // console.log(this.$store.state.alert)
            return this.$store.state.alert
        }
    },
    methods:{
      clear() {
        this.$store.dispatch('alert/clear');
      }
    }, 
};
</script>
