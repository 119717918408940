<template>
    <v-container id="flutterpayment" fluid tag="section">
        <v-card class="elevation-12">

<v-col cols="12">
  <v-row class="justify-center">
    <v-col class="col-md-8" align="center">
      <div style="background:#334c3e !important;color:white;border-radius:20px;padding:10px"><v-icon color="green"
          class="mr-4">mdi-cash-multiple</v-icon>You are paying the amount {{ this.$route.query.amount/100 }}
        </div>
    </v-col>
  </v-row>
</v-col>
<div v-if="created" class="container">
  <!-- <stripe-element-payment  ref="paymentRef" :pk="pk" 
  :elements-options="elementsOptions"
  @confirm-params="handleConfirmParams"></stripe-element-payment> -->
  <stripe-element-payment  ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
  :confirm-params="{  return_url:
                `https://alcobrakeadmin.com/successtransaction?mobile=` +
                this.$route.query.mobile +
                `&regnum=` +
                this.$route.query.regnum +
                `&email=` +
                this.$route.query.email +
                `&message=Dear customer your last transaction  is success with Payment id:` +
                this.$route.query.pi +
                `&paymentid=` +
                this.$route.query.pi +
                `&clearall=true`,
            }" />
  <br />
  <v-layout justify-center>
    <!-- dialog paynow -->
    <!-- <v-btn class="mr-4" @click="history.back()" dark style="background:#ff3d41;color:white">Cancel</v-btn> -->
    <v-btn class="primary" left @click="finalPay">Pay Now</v-btn>
  </v-layout>
</div>
<div v-else>
  <h1>Thank You for Choosing Alcobrake</h1>
</div>
</v-card>
   
    </v-container>
  </template>
  
  
<script>
import Vue from "vue";
import moment from "moment";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
export default {
    name:"Flutterflow",
  props: {
    source: String
  },
  data() {
    return {
      allparams:{"fields":{}},
      myApi:null,
      selectedStudent: null,
      parentMobile: null,
      // pk:"pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
       pk: process.env.VUE_APP_STRIPE_KEY,
    elementsOptions: {
        appearance: {},
        clientsecret:'pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt', // appearance options
      },
      created:false
    };
  },
  components: {
    StripeElementPayment,
  },
  watch:{
    allparams(val){
      this.allparams = val;
    },
    myApi(val){
      this.myApi = val;
    },
    created(val){
      this.created = val;
    }
    
  },  
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  mounted(){
this.elementsOptions.clientSecret=this.$route.query.pi;
console.log(this.$route.query.pi,"piii");
console.log(this.$route.query.amount,"amount");
// this.setData();
  },
  created() {
    this.created=true;
  },
  methods: {
    finalPay(){
     
      this.$refs.paymentRef.submit();


    },
    
  }
};
</script>