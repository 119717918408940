export const alert = {
    namespaced: true,
    state: {
      type: null,
      message: null
    },
    actions: {
      success({ commit }, message) {
        commit("success", message);
      //   setTimeout(() => {
      //     console.log("test2")
      //     commit("clear");
      // }, 7000);
      },
      error({ commit }, message) {
        commit("error", message);
      },
      clear({ commit }) {
        commit("clear");
      }
    },
    mutations: {
      success(state, message) {
        state.type = "alert-success";
        state.message = message;
      },
      error(state, message) {
        state.type = "alert-danger";
        state.message = message;
      },
      clear(state) {
        state.type = null;
        state.message = null;
      }
    }
  };
  