<script src="https://js.stripe.com/v3"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/1.3.8/FileSaver.js"></script>
<script>
import Vue from "vue";
import moment from "moment";
import moment2 from 'moment-timezone';
import Papa from "papaparse";
import Invoices from "./Invoices";
import Autorenew from "./autorenew";
import Invoices2 from "./Invoices2";
import Cals from "./Calibrations";
import axios from "axios";
import router from "../router";
import { mapGetters } from "vuex";
import DatetimePicker from "vuetify-datetime-picker";
import VueHtml2pdf from "vue-html2pdf";
import {
  StripeElementPayment,
  StripeElementCard,
} from "@vue-stripe/vue-stripe";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
// import FileSaver from 'file-saver';
import CA from "../stateforms/CA.vue";
import TX from "../stateforms/TX.vue";
import JSZip from "jszip";
import REPORT from "../components/Getreport.vue";
import Timezones from "./timezones.json";
// import this.oledDevices from "./oled_list.json";
import { saveAs } from "file-saver";
import Swapdevice from "./swapdevice.vue";
import Subscription from "./subscriptiondialog";
// import Routeplay from "../subcomponents/routeplay.vue"
import Eventemit from "./eventemit";
import {distance,payonline,paymentLink,Report,Report2} from "../components/clients2";
import Quecomponent from "./postcommandview";


// Vue.use(JSZip);
Vue.use(axios);
Vue.use(DatetimePicker);
export default {
  data() {
    return {
      otps: [],
      positions: ["0","1", "2", "3", "4"],
      selectedforrenewal:null,
      autorenewdialog:false,
      checkboxText: "Show Active Clients",
      isChecked: false,
      selectedRouteId:[],
      routeId:'',
      downloadabledata: [
        { column1: "Control Box S/N:", column2: "Customer No.:" },
        { column1: "Start Date:", column2: "End Date:" },
        { column1: "First Name:", column2: "Middle Name:" },
        { column1: "Last Name:", column2: "License No.:" },
        { column1: "Agency Name:", column2: "State/Country:" },
        { column1: "Low Alc:", column2: "Med Alc:" },
        { column1: "High Alc:", column2: "Illegal Start:" },
        { column1: "Failed Retest:", column2: "Refuse Retest:" },
        { column1: "Total Violations:", column2: "Total Test:" },
      ],
      datastats :{
            IllegalStarts: 0,
            HighBrAC: 0,
            RRRefused: 0,
            EngineStops: 0,
            EngineStarts:0,
            HandsetDisconnects: 0,
            HandsetConnects: 0,
            AuthorizedStarts: 0,
            Aborts: 0,
      },
      token: null,
      switchDisabled: false,
      passevents:null,
      failevents:null,
      totaltest:null,
      allArray:null,
      validAmount: false,
      validdiscountAmount: false,
      splDialog:false,
      splclientId:'',
      subdialog: false,
      stops: [],
      stripecustomerList: [],
      isloading:null,
      paymentlink:"",
      user: JSON.parse(localStorage.getItem("user")),
      calevent: "",
      nextcalevent: "",
      decimalPlaces: 0,
      licencedialog: false,
      imgfile: "",
      fileimage: "",
      csvupload: false,
      allDataevents: [],
      imgfile2: "",
      fileimage2: "",
      totaleventscount: 0,
      countrycode: "us",
      imagecount: 0,
      eventscount: [],
      eventsfilter: [],
      t1: null,
      t2: null,
      issubscribed:null,
      subscribed:1,
      unsubscribed:0,
      printme: false,
      dlimage: "",
      dialog20: false,
      postdialog:false, //for post commands queue
      queuedialog:false, //for post commands queue
      mytzone: null,
      pdfedit: false,
      updatelicencedialog: false,
      dobmenu: false,
      adddobmenu: false,
      cbid: "",
      mytype: "install",
      isnewinstall: 0,
      distancetravel:null,
      statuss: "",
      min: 1,
      max: 400,
      myzones: Timezones,
      noimage: false,
      thresholdrules: {
        required: (value) => !!value || "Required.",
        min: (v) => v >= this.min || `The Min is ${this.min}`,
        max: (v) => v <= this.max || `Limit Up ${this.max}`,
      },
      tzinterval: null,
      normaltimesetinterval: null,
      fgicon: "mdi-signal-off",
      wifiicon: "mdi-wifi-off",
      bacdevid: null,
      baccommand: null,
      bacregnum: null,
      bacfield: null,
      pingfreqdialog: false,
      pingdevid: null,
      pingcommand: null,
      pingregnum: null,
      pinginterval: null,
      onPaying:null,
      calfield: null,
      swappingdialog: false,
      newdialog:false,
      calregnum: null,
      bacdialog: false,
      caldialog: false,
      thresholddialog: false,
      caldevid: null,
      calcommand: null,
      thresholddevid: null,
      thresholdcommand: null,
      thresholdfield: null,
      thresholdialog: false,
      alldatas: "",
      numb: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // itemsPerPage: 10,
      page: 1,
      items: [],
      options: {
        itemsPerPage: 10,
      },
      datetime: {
        type: [Date, String],
        default: null,
      },
      label: {
        type: String,
        default: "",
      },
      width: {
        type: Number,
        default: 340,
      },
      format: {
        type: String,
        default: "YYYY-MM-DD HH:mm:ss",
      },
      timePickerFormat: {
        type: String,
        default: "24hr",
      },
      locale: {
        type: String,
        default: "en-us",
      },
      clearText: {
        type: String,
        default: "CLEAR",
      },
      okText: {
        type: String,
        default: "OK",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      errorMessages: {
        type: [String, Array],
        default: () => [],
      },
      errorCount: {
        type: [Number, String],
        default: 1,
      },
      error: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      appendIcon: {
        type: String,
      },
      prependIcon: {
        type: String,
      },
      csvheaders: [
        {
          text: "Date ",
          align: "left",
          value: "Date",
          sortable: false,
        },
        {
          text: "Week",
          align: "left",
          value: "Week",
          sortable: false,
        },
        {
          text: "EventType",
          align: "left",
          value: "EventType",
          sortable: false,
        },
        {
          text: "brac",
          align: "left",
          value: "brac",
          sortable: false,
        },
        {
          text: "Result",
          align: "left",
          value: "Result",
          sortable: false,
        },
      ],
      blowtype:'low',
      pagination: {},
      payind2: null,
      payind1: null,
      iscreated: false,
      iscreated2: false,
      addclr: null,
      istexasloading: false,
      confirmtexasloading: false,
      timezonetext: "Please Select the Timezone/State",
       pk: process.env.VUE_APP_STRIPE_KEY,
      // pk: "pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
      elementsOptions: {
        appearance: {},
        clientsecret:
          "pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt", // appearance options
      },
      elementsOptions2: {
        appearance: {},
        clientsecret:
          "pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt", // appearance options
      },
      confirmParams: {
        // return_url: "https://alcobrakeadmin.com/dashboard", // success url
        return_url: "https://alcobrakeadmin.com/successtransaction?mobile=", //+paymentItem.clientphone1+"&email="+paymentItem.email+" & message=Dear customer your last transaction  is success with Payment id:"+payind1,
      },
      confirmParams2: {
        return_url: "https://alcobrakeadmin.com/successtransaction?mobile=", //+paymentItem.clientphone1+"&email="+paymentItem.email+" & message=Dear customer your last transaction  is success with Payment id:"+payind2,
        // return_url: "https://alcobrakeadmin.com/dashboard", // success url
      },
      name: "take my money!",
      loading: false,
      amount: 100,
      // publishableKey:
        // "pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
      token: null,
      charge: null,
      iscamera: "",
      alldata: "",
      firmwares: "",
      show2: false,
      dealeremail: "",
      dealerpassword: "",
      mydealerpassword:"",
      messagepassing: "hi passing",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      loginemail: {
        required: (value) => !!value || "Required.",
      },
      // templogs:[],
      currentevent: null,
      imageBytes: null,
      imageSize:null,
      bacvalue: "",
      devid: "",
      rtime: "",
      test: "",
      client: "",
      filename: "",
      // user: JSON.parse(localStorage.getItem("user")),
      firmware: "",
      cbversion: "",
      models: null,
      caltrackdata: [],
      caltrackdata2: [],
      calparamsdata: [],
      acceptancedata: [],
      dialog13: false,
      dialog14: false,
      dialog16: false,
      dialog17: false,
      lastupdate: null,
      engoffevents: [],
      engonevents: [],
      failtestevents: [],
      passtestevents: [],
      ismemloading: false,
      setzone: false,
      loadingtext: "Please Wait",
      tempvariable: "",
      cfile: "",
      uploadPercentage: null,
      pdfdialog: false,
      imgdialog: false,
      // cloading: false,
      alignments: ["start", "center", "end"],
      // Reminder:[],
      addInfo: {
        invoiceId: null,
        office: null,
        client: null,
        date: "",
        service: [],
        isButtonDisabled: false,
        regnum: "",
        total: "0.00",
        prevbalance: "0.00",
        grandtotal: "0.00",
        amountpaid: "0.00",
      },
      // tempdialog:false,
      emptyInfo: {
        invoiceId: null,
        office: null,
        client: null,
        date: "",
        service: [],
        regnum: "",
        total: "0.00",
        prevbalance: "0.00",
        grandtotal: "0.00",
        amountpaid: "0.00",
      },
      texasdisplay: "Downloading data please wait.....",
      e1: 1,
      prevBalance: 0.0,
      currentamountpaid: "0.00",
      previouspaid: "0.00",
      valid2: true,
      post: null,
      // items: [],
      s3version: null,
      licensetext: "Please Wait Image Loading",
      licenseloading: false,
      dialog6: false,
      dialog7: false,
      result: null,
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      signdialog: false,
      accepdialog: false,
      clearlogdialog: false,
      dialog9: false,
      dialog10: false,
      dialog15: false,
      dialog11: false, //view invoices
      dialog18: false, //view invoices on more
      dialog12: false, //for signature
      dialog19: false, //for pay cash
      dialog21: false, //for pay cash
      linkemail:"",
      linkmobile:"",
      isDisabled: false,
      allvehicles: [],
      myclient: null,
      datalist: null,
      dataevents: null,
      dataevents1: null,
      names1: null,
      names2: null,
      names3: null,
      lowalcdata: null,
      highalcdata: null,
      refusetotdata: null,
      resdata: null,
      illegaldata: null,
      totaldata: null,
      medalcdata: null,
      faildata: null,
      totalvoilationdata: null,
      search: "",
      search2: "",
      search1: "",
      expanded: [],
      downloaddata: "",
      ping: [],
      menu: false,
      menu1: false,
      menux: false,
      menu2: false,
      menu5: false,
      menu6: false,
      menu3: false,
      menu4: false,
      menu10: false,
      menu11: false,
      menu12: false,
      interlockdialog: false,
      reportdialog:false,
      clientdata:null,
      inter: null,
      sign: "",
      menu13: false,
      menu15: false,
      menu16: false,
      valid: true,
      checkbox2: false,
      samplercheckbox: false,
      beforedata: "",
      valid3: true,
      states: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "INDIA",
      ],
      clientsimg: "",
      faceswitch: 0,
      issmsswitch: 0,
      isunavailablevehicle:0,
      commands: [
        { text: "GET DEVICE INFO", color: "orange", command: "80" },
        { text: "VOILATION RESET", color: "grey", command: "85" },
        { text: "TAKE A TEST", color: "blue", command: "84" },
        // { text: "ALLOW START", color: "green", command: "88" },
        // { text: "NOT TO START", color: "red", command: "89" },
        { text: "Device Reset", color: "pink", command: "87" },
        // { text: "TAMPER ENABLE", color: "orange", command: "90" },
        // { text: "TAMPER DISABLE", color: "green", command: "91" },
        // // { text: "SET RR DATA", color: "red", command: "0X82" },
        // { text: "GET CALIBRATION", color: "gery", command: "0B8E" }
      ],
      show: false,
      logheaders: [],
      iscam: "",
      headers: [],
      headers2: [],
      headers3: [
        {
          text: "id",
          align: "left",
          value: "id",
        },
        { text: "Operation", value: "operation" },
        { text: "Triggered Time(INDIA)", value: "indiatime" },
        { text: "Triggered Time(USA-CAL)", value: "catime" },
        { text: "Action time", value: "actionindiatime" },
        { text: "Status", value: "status" },
        { text: "Activated By", value: "triggeredBy" },
      ],
      accepheaders: [
        {
          text: "id",
          align: "left",
          value: "id",
        },
        { text: "FileName", value: "fileName" },
        { text: "Timestamp", value: "timestamp" },
        { text: "Client", value: "client" },
        { text: "Device", value: "device" },
      ],
      headers4: [
        {
          text: "DeviceId",
          align: "left",
          value: "cbid",
        },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
        },
        { text: "Updated Time", align: "left", value: "updatedtime" },
        { text: "Calibration Date", value: "caldate" },
        { text: "ASO", value: "alcooffset.String" },
        { text: "Brac Peak Factor", value: "bracpeakfactor" },
        { text: "Offset Pressure", value: "offset_pressure" },
        { text: "F/W version", value: "fwversion" },
        { text: "C/B version", value: "cbversion" },
        { text: "Engine Run ADC", value: "erunadc.String" },
      ],
      editedIndex: -1,
      lastop: {},
      todaysdate: new Date(),
      emptyItem: {
        clienthome: "",
        clientwork: "",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile: "",
        ticketfile: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink: false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile: "",
        facedetection: "",
        issms: "",
      },
      postcommandItem: {
        clienthome: "",
        clientwork: "",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile: "",
        ticketfile: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink: false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile: "",
        facedetection: "",
        issms: "",
      },
      paymentItem: {
        clienthome: "",
        clientwork: "",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile: "",
        ticketfile: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink: false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile: "",
        facedetection: "",
        issms: "",
      },
      editedItem: {
        clienthome: "",
        clientwork: "",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile: "",
        ticketfile: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        cbid: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink: false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile: "",
        issms: "",
        facedetection: "",
      },
      customtimestamp: null,

      defaultItem: {
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "",
        driverisincompliance: "",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "",
        dealer: "",
        clientphone1: "",
        isresidsame: "",
        isbar: "",
        licensefile: "",
        ticketfile: "",
        officelicensenumber: "",
        installer: "",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        cbid: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        editlicensefile: "",
      },
      createdTime:'',
      splId:'',
      splbtn : '',
      invoicespllistText:'Create Special Offer',
      invoiceservices:  [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ],
      invoiceservices2:  [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ],
      clientsArray: [
        "Licence Number",
        "Client Name",
        "Suffix",
        "Client Address(W)",
        "City(W)",
        "State(W)",
        "Zipcode(W)",
        "Client Address(R)",
        "City(R)",
        "State(R)",
        "Zipcode(R)",
        "Date Of Birth",
        "Client Primary Mobile",
        "Client Secondary Mobile",
        "Sno",
        "Office Name",
        "Office License Number",
        "Office Address",
        "Office City",
        "Office State",
        "Office Zipcode",
        "InstallDate",
        "Make",
        "Year",
        "License Plate Number",
        "VIN Number",
        "Prohibition Start Date",
        "Prohibition End Date",
        "Installer Printed Name",
        "Installer Telephone Number",
        // "APARTMENT/SPACE NUMBER"
      ],
      // logs:[],
      req: {
        iscam: null,
        issign: null,
      },
      logg: {
        total: 0,
        pass: 0,
        fail: 0,
        enginerunstate: 0,
        refused: 0,
        IncorrectBreathSamples: 0,
        Bypass: 0,
        othertampering: 0,
        hot: 0,
        permanantlockout: 0,
        PowerDisconnections: 0,
        HandsetDisconnections: 0,
        HardwareErrors: 0,
        highalc: 0,
        medalc: 0,
        lowalc: 0,
      },
      defaultlogg: {
        total: 0,
        pass: 0,
        fail: 0,
      },
      paymentelements: {
        amount: "",
        email: "",
      },
      paymentelements2: {
        amount: "",
        email: "",
      },
      invos: {
        invs: null,
        files: 0,
        totalamount: 0,
        paidamount: 0,
        pending: 0,
      },
      defaultinvos: {
        files: 0,
        totalamount: 0,
        paidamount: 0,
        pending: 0,
      },
      caldetails: {
        status: "0",
        calibrationdate: "",
        nextcaldate: "",
        devid: "",
        iswifi: "",
        lastevent: "NO UPDATE",
      },
      isplay: false,
      fromdate: null,
      todate: null,
      defaultcaldetails: {
        status: "",
        calibrationdate: "",
        iswifi: "",
        nextcaldate: "",
        devid: "",
      },
      // ecode:["91","1"],
      // ecode2:["91","1"],
      sorts: ["prohibitions", "drivers", "vehicles"],
      // officeRules: [
      //         v => !!v || 'Select office date',
      //       ],
      // clientRules: [
      //   v => !!v || 'Select client id',
      // ],
      vehicleRules: [(v) => !!v || "Select vehicle id"],
      motoristRules: [
        (v) => !!v || "Motorist is required",
        (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
        (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      //^\d+(\.\d{1,2})?$
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => (v && /^\d+(\.\d{1,2})?$/.test(v)) || "Enter valid Amount",
      ],
      regRules: [
        (v) => !!v || "Registration number is required",
        (v) =>
          (v && v.length > 5 && v.length < 15) ||
          "Enter a valid Registration number",
      ],
      ecodeRules: [(v) => !!v || "Select Country Code"],
      phoneRules: [
        (v) => !!v || "Home Telephone Required",
        (v) =>
          (v && v.length >= 10 && v.length <= 10) || "Enter valid phone number",
        (v) =>
          !this.fullclients.find((driver) => driver.clientphone1 == v) ||
          this.editedIndex != -1 ||
          "This Number already exists use different one",
        (v) =>
          !this.fullclients.find((driver) => driver.clientphone2 == v) ||
          this.editedIndex != -1 ||
          "This Number already exists use different one",
      ],
      ephoneRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          (v && v.length >= 10 && v.length <= 14) || "Enter valid phone number",
      ],
      calRules: [
        (v) => !!v || "Pressure value is required",
        (v) =>
          (v && v.length.numb >= 1 && v.length.numb <= 2) ||
          "Enter valid Pressure value",
      ],
      addressRules: [
        (v) => !!v || "Address is required",
        (v) => (v && v.length < 100) || "Enter valid phone number",
      ],
      clientRules: [(v) => !!v || "Select client"],
      deviceRules: [(v) => !!v || "Select Device"],
      installDateRules: [(v) => !!v || "Select Install date"],
      calibDateRules: [(v) => !!v || "Select Calibration date"],
      makeRules: [
        (v) => !!v || "Make is required",
        (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
        (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      modelRules: [
        (v) => !!v || "Model is required",
        (v) => (v && v.length >= 2) || "Name must contain 2 or more characters",
        (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      yearRules: [
        (v) => !!v || "Year is required",
        (v) => (v && v.length == 4) || "Enter valid Year",
      ],
      colorRules: [
        (v) => !!v || "Color is required",
        (v) =>
          (v && v.length >= 3) ||
          "Color name must contain 3 or more characters",
      ],
      vinRules: [(v) => !!v || "vin is required"],
      tagRules: [(v) => !!v || "tag is required"],
      tagStateRules: [(v) => !!v || "Select Tag State"],
      dlRules: [
        (v) => !!v || "DL Number is required",
        (v) => (v && v.length >= 8) || "Enter a valid DL number",
      ],
      calibRules: [(v) => !!v || "Select Calibration Period"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          !this.fullclients.find((driver) => driver.client == v) ||
          this.editedIndex != -1 ||
          "This Name already exists use different one",

        (v) => (v && v.length >= 5) || "Name must contain 5 or more characters",
        // (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      enameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 5) || "Name must contain 5 or more characters",
        // (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      idRules: [(v) => !!v || "Id is required"],
      officeRules: [(v) => !!v || "Select office"],
      birthdayRules: [(v) => !!v || "Select birthday date"],
      phone2Rules: [
        (v) => !!v || "Work Telephone Required",
        (v) =>
          !v ||
          (v && v.length == 10) ||
          v.length == 10 ||
          "Enter valid phone number",
        (v) =>
          !this.fullclients.find((driver) => driver.clientphone1 == v) ||
          this.editedIndex != -1 ||
          "This Number already exists use different one",
        (v) =>
          !this.fullclients.find((driver) => driver.clientphone2 == v) ||
          this.editedIndex != -1 ||
          "This Number already exists use different one",
      ],
      ephone2Rules: [
        (v) => !!v || "Work Telephone Required",
        (v) =>
          !v ||
          (v && v.length == 10) ||
          v.length == 12 ||
          "Enter valid phone number",
      ],
      dobRules: [(v) => !!v || "Birth Date is required"],
      emailRules: [
      (v) => !!v || "E-mail is required",
        (v) =>
          (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "E-mail must be valid",
        (v) =>
          !this.fullclients.find((driver) => driver.email == v) ||
          this.editedIndex != -1 ||
          "This Email already exists use different one",
      ],
      eemailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "E-mail must be valid",
      ],
      cityRules: [
        (v) => !!v || "City is required",
        (v) => (v && v.length >= 3) || "City must contain 3 or more characters",
      ],
      stateRules: [(v) => !!v || "Select State"],
      suffixRules: [(v) => !!v || "Select Suffix"],
      facilityRules: [(v) => !!v || "Select Facility"],
      zipRules: [
        (v) => !!v || "Zip is required",
        (v) => (v && v.length == 5) || "Enter valid zip code",
      ],
      selectdevRules: [
        (v) => !!v || "Device Id is required",
        (v) =>
          !this.fullclients.find((driver) => driver.sno == v) ||
          this.editedIndex != -1 ||
          "This Number already exists use different one",
      ],
      eselectdevRules: [(v) => !!v || "Device Id is required"],
      ConvDivRules: [(v) => !!v || "Select Conviction/Diversion"],
      probationRules: [(v) => !!v || "Select Probation Number"],
      dateofnoticeRules: [(v) => !!v || "Notice date is required"],
      dateofinspectionRules: [(v) => !!v || "Inspection date is required"],
      installerRules: [(v) => !!v || "Select Installer"],
    };
  },
  components: {
    Quecomponent,
    Subscription,
    VueHtml2pdf,
    StripeElementPayment,
    StripeElementCard,
    CA,
    TX,
    Swapdevice,
    REPORT,
    progressInfo: () => import("../subcomponents/operations-progress.vue"),
    GoogleMaps: () => import("../subcomponents/googlemaps2.vue"),
    RouteMaps: () => import("../subcomponents/routeplay.vue"),
    Invoices,
    Invoices2,
    Autorenew,
  },
  computed: {
    otpRows() {
      let rows = [];
      for (let i = 0; i < this.positions.length; i += 3) {
        let row = this.positions.slice(i, i + 3).map(position => {
// console.log("otps");
// console.log(this.otps)
          let otp = this.otps.find(otp => otp.position === position);
          return otp ? otp : { position: position, otp: "XXXX" };
        });
        rows.push(row);
      }
      return rows;
    },
    // sortedData() {
    //   //       const sorted = [{"id":6997306,"devid":"ABS_a8_03_2a_ff_68_d0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"01/03/2023 04:12:17","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6997300,"devid":"ABS_a8_03_2a_ff_68_d0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"01/02/2023 21:26:04","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6997292,"devid":"ABS_a8_03_2a_ff_68_d0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"04/11/2023 19:09:15","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6997254,"devid":"ABS_a8_03_2a_ff_69_c0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"05/03/2023 16:43:40","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6997248,"devid":"ABS_a8_03_2a_ff_69_c0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"09/03/2023 09:53:04","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6997246,"devid":"ABS_a8_03_2a_ff_69_c0","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"10/03/2023 09:51:40","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"},{"id":6952484,"devid":"ABS_a8_03_2a_ff_68_bc","controlId":"ABC_a8_03_2a_ff_67_a4","gtime":"NA","rtime":"11/14/2023 13:34:40","typeofevent":"254","bacvalue":"0","passfailstatus":"1","enginerunstate":"NA","randominitial":"0","lat":"NA","lon":"NA","vbat":"40"}]
    //   //               // Sort data by the 'rtime' field
    //   //       const sortedArray = sorted.sort((a, b) =>
    //   //         moment(a.rtime, 'MM/DD/YYYY HH:mm:ss').diff(moment(b.rtime, 'MM/DD/YYYY HH:mm:ss'))
    //   //       );

    //   //       // Group data by every two months
    //   //       const groupedData = [];
    //   //       let currentMonthRange = null;
    //   //       sortedArray.forEach((item) => {
    //   //         const originalDatetime = moment('01/01/2023 00:00:00', 'MM/DD/YYYY HH:mm:ss');
    //   // const newDatetime = originalDatetime.add(2, 'months');
    //   // console.log(newDatetime.format('MM/DD/YYYY HH:mm:ss'));

    //   //         const itemMonthRange = moment(item.rtime, 'MM/DD/YYYY HH:mm:ss').format('MMM YYYY');
    //   //         console.log()

    //   //         if (itemMonthRange !== currentMonthRange) {
    //   //           currentMonthRange = itemMonthRange;
    //   //           groupedData.push({ monthRange: currentMonthRange, data: [] });
    //   //         }
    //   //         groupedData[groupedData.length - 1].data.push(item);
    //   //       });

    //   //       return groupedData;

    //   // Start datetime
    //   const startDatetime = moment(
    //     "04/02/2023 00:00:00",
    //     "MM/DD/YYYY HH:mm:ss"
    //   );

    //   // Array to store objects
    //   const dataList = [];

    //   // Number of objects to create
    //   const numberOfObjects = 5;

    //   // Loop to create objects with incremented datetime and push data
    //   for (let i = 0; i < numberOfObjects; i++) {
    //     const currentDatetime = startDatetime.clone().add(2 * i, "months");

    //     // Example data to push into the object
    //     const dataObject = {
    //       id: i + 1,
    //       datetime: currentDatetime.format("MM/DD/YYYY HH:mm:ss"),
    //       // Add other fields as needed
    //     };

    //     // Push the dataObject into the dataList array
    //     dataList.push(dataObject);
    //   }

    //   return dataList;
    // },
     minToDate() {
      // Set the minimum allowed date for "To Date" picker based on the selected "From Date"
      return this.fromdate ? moment(this.fromdate).format("YYYY-MM-DDTHH:mm:ss") : null;
    },
    computedDateFormatted() {
      return this.formatDate(this.editedItem.dob);
    },
    computedaddDateFormatted() {
      return this.addformatDate(this.editedItem.dob);
    },
    firstItem() {
      return this.pagination.pageStart;
    },
    itemsPerPage() {
      return this.pagination.itemsPerPage;
    },
    htmlToPdfOptions() {
      return {
        margin: 1,
        filename: `form.pdf`,
        image: {
          type: "png",
          quality: 1,
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          //unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      };
    },
    devId() {
      return this.editedItem.devId.devId;
    },
    Reminder() {
      if (
        this.user.emailId == "Justin@alcobrake.com" ||
        this.user.state == "INDIA"
      ) {
        this.headers = [
          { text: "", value: "data-table-expand", sortable: false },
          { text: "SNO", value: "sno" },
          { text: "RegNum", value: "regnum", sortable: false },

          {
            text: "Driver Name",
            align: "left",
            value: "client",
            sortable: false,
          },

          // { text: "State", value: "state"},
          { text: "Driver Mobile", value: "clientphone1", sortable: false },
          // { text: "Address", value: "clientAddress", sortable: false },
          // { text: "History", value: "history" },
          // { text: "Device reset", value: "resetdevice", sortable: false },
          { text: "GPS", value: "location", sortable: false },
          { text: "Add More", value: "addmore", sortable: false },
          // { text: "Installation Form", value: "acceptance", sortable: false },
          // { text: "DL_Photo", value: "dlphoto", sortable: false },
          // { text: "VIEW/EDIT", value: "action", sortable: false },
          // { text: "Add to Removals", value: "delete", sortable: false },
          // { text: "Subscription", value: "isunavailablevehicle" },
        ];
      } else {
        //  this.cloading = true;
        // setTimeout(() => {
        this.headers = [
          // { text: "ID", value: "clientId" },
          { text: "", value: "data-table-expand", sortable: false },
          { text: "SNO", value: "sno" },
          {
            text: "Client Name",
            align: "left",
            value: "client",
            sortable: false,
          },
          { text: "RegNum", value: "regnum", sortable: false },
          // { text: "DLnum", value: "DLnum" },
          // { text: "Zipcode", value: "zipcode" },
          // { text: "Office", value: "officeName" },
          // { text: "City", value: "city" },
          // { text: "State", value: "state"},
          { text: "Primary Mobile", value: "clientphone1", sortable: false },
          // { text: "Secondary Mobile", value: "clientphone2" },
          // { text: "Email", value: "email" },
          // { text: "Address", value: "clientAddress", sortable: false },
          { text: "Install Date", value: "installDate" },
          { text: "CalibrationDate", value: "calibrationDate" },
          // { text: "City", value: "city" },
          // { text: "State", value: "state" },
          // { text: "Zipcode", value: "zipcode" },
          // { text: "City", value: "city" },
          // { text: "State", value: "state" },
          // { text: "History", value: "history" },
          // { text: "Device reset", value: "resetdevice", sortable: false },
          { text: "Invoices", value: "invoc" },
          // { text: "Add Probhition", value: "Probhition", sortable: false },
          // { text: "Add Invoice", value: "pay", sortable: false },
          // { text: "Installation Form", value: "acceptance", sortable: false },
          // { text: "DL_Photo", value: "dlphoto", sortable: false },
          // { text: "VIEW/EDIT", value: "action", sortable: false },
          { text: "Subscription", value: "isunavailablevehicle" },
          { text: "Auto Renewal", value: "autorenewal" },
          { text: "Vreset", value: "vreset", command: "85", sortable: false },
          { text: "GPS", value: "location", sortable: false },
          { text: "More", value: "addmore", sortable: false },
          { text: "Add to Removals", value: "delete", sortable: false },
          // { text: "SWAP", value: "swap", sortable: false },
        ];
      //   this.cloading = false;
      // }, 8000);
      } 
        
      // console.log(this.fullclients)
      return this.fullclients;
    },
    formatcalDate() {
      return this.editedItem.calibrationDate
        ? moment(this.editedItem.calibrationDate).format("MM/DD/YYYY")
        : "";
    },
    formatInstalldate() {
      return this.editedItem.installDate
        ? moment(this.editedItem.installDate).format("MM/DD/YYYY")
        : "";
    },
    formatnextcalDate() {
      return this.editedItem.nextcmonitordate
        ? moment(this.editedItem.nextcmonitordate).format("MM/DD/YYYY")
        : "";
    },

    formTitle() {
      return this.editedIndex === -1 ? "Add Client" : "Edit Client";
    },
    ...mapGetters(["uniqueinvoice2"]),
    ...mapGetters(["operations"]),
    ...mapGetters(["fullclients"]),
    ...mapGetters(["offices"]),
    ...mapGetters(["devices"]),
    ...mapGetters(["invoices"]),
    ...mapGetters(["logs"]),
    ...mapGetters(["splitlogs"]),
    ...mapGetters(["clients"]),
    ...mapGetters(["officeclients"]),
    ...mapGetters(["uniqueclient"]),
    ...mapGetters(["vehicles"]),
    ...mapGetters(["users"]),
    ...mapGetters(["templogs"]),
    ...mapGetters(["oledDevices"]),
    // ...mapGetters(["invoiceservices"]),
    all_items() {
      if (this.addInfo.service != null) {
        return this.addInfo.service.reduce((accumulator, item) => {
          return accumulator + (item.actualamount - item.discount);
        }, 0);
      } else {
        return 0;
      }
    },
    date: () => new Date().toLocaleString(),
    // balance() {
    //   return 10;
    // },
    previous_balance() {
      /* eslint-disable no-console */
      this.getBalance();
      if (this.currentamountpaid == "0" && this.previouspaid == "0") {
        return parseFloat(this.total_amount_due);
      } else {
        return parseFloat(this.addInfo.prevbalance);
      }
    },
    total_amount_due() {
      if (this.previouspaid !== undefined) {
        /* eslint-disable no-console */
        var cpaid = this.all_items - this.previouspaid;
        return cpaid;
      }
      return this.all_items;
    },
    total_all_items() {
      if (this.addInfo.service != null) {
        return this.all_items;
      } else {
        return 0;
      }
    },
    formTitle2() {
      return this.editedIndex === -1 ? "Add Invoice" : "Edit Invoice";
    },
  },

  watch: {
    otps(val){
      this.otps = val;
    },
    selectedforrenewal(val){
      this.selectedforrenewal =val;
    },
    fromdate(val){
      this.fromdate=val
    },
       todate(val){
      this.todate=val
    },
    validdiscountAmount(val) {
      this.validdiscountAmount = val;
    },
    validAmount(val) {
      this.validAmount = val;
    },
    payind2(val) {
      this.payind2 = val;
    },
    currentamountpaid(val) {
      this.currentamountpaid = val;
    },
    paymentelements2(val) {
      this.paymentelements2 = val;
    },
    elementsOptions2(val) {
      this.elementsOptions2 = val;
    },
    myclient(val) {
      this.myclient = val;
    },
    faceswitch(val) {
      this.faceswitch = val;
    },
    issmsswitch(val) {
      this.issmsswitch = val;
    },
    // isunavailablevehicle(val){
    //   this.isunavailablevehicle=val;
    // },
    checkbox2(val) {
      this.checkbox2 = val;
    },
    samplercheckbox(val) {
      this.samplercheckbox = val;
    },
    countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = "us";
      }, 500);
    },
    licensefile: function (val) {
      if (val == null) {
        this.editedItem.licensefile = val;
      }
    },
    ticketfile: function (val) {
      if (val == null) {
        this.editedItem.ticketfile = val;
      }
    },
    isplay(val) {
      this.isplay = val;
    },
    todate(val) {
      this.todate = val;
    },
    uniqueinvoice2(val) {
      if (val.length > 0) {
        this.invos.invs = val.length;
        val.map((res) => {
          if (res.amountpaid != "") {
            this.invos.paidamount =
              parseFloat(this.invos.paidamount) + parseFloat(res.amountpaid);
          }
          if (res.total != "") {
            this.invos.totalamount =
              this.invos.totalamount + parseFloat(res.total);
          }
          if (res.prevbalance != "") {
            this.invos.pending =
              this.invos.pending + parseFloat(res.prevbalance);
          }
        });
      } else {
        this.invos = Object.assign({}, this.defaultinvos);
      }
    },
    logs(val) {
      // console.log(val);
      var onlycal = val.filter(
        (item) => item.typeofevent === "CALIBRATION DONE"
      );
      // console.log(onlycal)
      onlycal = onlycal.sort(
        (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
      );
      if (onlycal.length > 0) {
        this.calevent = onlycal[onlycal.length - 1].rtime;
        this.nextcalevent = moment(onlycal[onlycal.length - 1].rtime)
          .add(67, "days")
          .format("MM/DD/YYYY HH:mm:ss");
      }
      this.logg.pass = val.filter(
        (item) => item.passfailstatus === "PASS"
      ).length;
      this.logg.fail = val.filter(
        (item) => item.passfailstatus === "FAIL"
      ).length;
      this.logg.enginerunstate = val.filter(
        (item) => item.typeofevent === "ENGINE ON"
      ).length;
      this.logg.refused = val.filter(
        (item) => item.typeofevent === "TEST_REFUSED_RR_TEST"
      ).length;
      this.logg.othertampering = val.filter(
        (item) =>
          item.typeofevent === "COOL_SAMPLE" ||
          item.typeofevent === "HOT_SAMPLE"
      ).length;
      this.logg.IncorrectBreathSamples = val.filter(
        (item) => item.typeofevent === "INSUFFICIENTVOLUME"
      ).length;
      this.logg.Bypass = val.filter(
        (item) => item.typeofevent === "BYPASS"
      ).length;
      this.logg.PowerDisconnections = val.filter(
        (item) => item.typeofevent === "CAR_BATTERY_TAMPERING"
      ).length;
      this.logg.HandsetDisconnections = val.filter(
        (item) => item.typeofevent === "SAMPLER_DISCONNECTED"
      ).length;
      this.logg.permanantlockout = val.filter(
        (item) => item.typeofevent === "LOCKOUT 0"
      ).length;
      this.logg.highalc = val.filter(
        (item) => parseFloat(item.bacval) >= 20 && parseFloat(item.bacval) < 255
      ).length;
      this.logg.medalc = val.filter(
        (item) => parseFloat(item.bacval) >= 10 && parseFloat(item.bacval) < 20
      ).length;
      this.logg.lowalc = val.filter(
        (item) => parseFloat(item.bacval) >= 0 && parseFloat(item.bacval) < 10
      ).length;
      this.logg.total = val.length;
      if (val.length > 0) {
        this.caldetails.lastevent = val[0].rtime;
      } else {
        this.caldetails.lastevent = "NO UPDATE";
      }
    },

//     clearPostcommandItem() {
//       console.log("cleared")
//   this.postcommandItem = Object.assign({}, this.emptyItem);
    
// },

    
    expanded(val) {
      if (val.length == 0) {
        this.samplercheckbox = false;
        clearInterval(this.t1);
        clearInterval(this.t2);
        var ele = document.getElementById("myBlinkingDiv");
        var ele2 = document.getElementById("myBlinkingDiv2");
        ele.style.color = "black";
        ele2.style.color = "black";
        this.models = null;
        this.invos = Object.assign({}, this.defaultinvos);
        this.caldetails = Object.assign({}, this.defaultcaldetails);
        this.logg = Object.assign({}, this.defaultlogg);
        this.calevent = "";
        this.nextcalevent = "";
      } else {
        console.log(val[0])
        Vue.axios
          .get(
            "https://robopower.xyz/v2/ping/getdeviceotps?deviceId=" +
              val[0].devId
          )
          .then((response) => {
            if (response.data != null) {
            this.otps = response.data;
            console.log(response.data)

            }
          })
        Vue.axios
          .get(
            "https://robopower.xyz/app/v2data/getdeviceoperations?devid=" +
              val[0].cbid
          )
          .then((response) => {
            if (response.data != null) {
              var oplist = response.data;
              oplist.sort((a, b) => a.id - b.id);
              // console.log(oplist[oplist.length -1])
              this.lastop = oplist[oplist.length - 1];
            }
          });
        this.faceswitch = val[0].facedetection;
        this.issmsswitch = val[0].issms;
        this.paymentItem = Object.assign({}, val[0]);
        this.postcommandItem = Object.assign({}, val[0]);
        (this.logg = {
          total: "Loading",
          pass: "Loading",
          fail: "Loading",
          enginerunstate: "Loading",
          refused: "Loading",
          IncorrectBreathSamples: "Loading",
          Bypass: "Loading",
          othertampering: "Loading",
          hot: "Loading",
          permanantlockout: "Loading",
          PowerDisconnections: "Loading",
          HandsetDisconnections: "Loading",
          HardwareErrors: "Loading",
          lowalc: "Loading",
          medalc: "Loading",
          highalc: "Loading",
        }),
          (this.caldetails = {
            status: "Loading",
            calibrationdate: "Loading",
            nextcaldate: "Loading",
            devid: "Loading",
            iswifi: "Loading",
            lastevent: "Loading",
          }),
          // this.invos = {
          //   invs: "Loading",
          //   files: "Loading",
          //   totalamount: "Loading",
          //   paidamount: "Loading",
          //   pending: "Loading"
          // },
          (this.cbversion = "Loading");
        this.firmware = "Loading";
        this.s3version = "Loading";

        Vue.axios
          .get(
            "https://robopower.xyz/us/cadevices/getuniquedevice?sno=" +
              val[0].sno
          )
          .then((res) => {
            if (res.data) {
              var datawifi = res.data[0];
              this.statuss = datawifi.status;
              let date_1 = res.data[0].lastupdate;
              let date_3 = res.data[0].lastwifiupdate;
              let date_2 = moment2(new Date()).tz('GMT').format('MM/DD/YYYY HH:mm:ss');
                var min_diff_4G = moment(date_2).diff(moment(date_1), "minutes");
                var min_diff_wifi = moment(date_2).diff(moment(date_3), "minutes");
                if (min_diff_4G >= 0 && min_diff_4G < 1) {
                    var ele4g = document.getElementById("myBlinkingDiv");
                    if (ele4g) {
                      ele4g.style.color = "green";
                    }
                    // var elems = document.getElementById("myBlinkingDiv2");
                    // if (elems) {
                    //   elems.style.color = "black";
                    //   elems.style.visibility = "visible";
                    // }
                  } else {
                  this.fgicon = "mdi-signal-off";
                  // this.wifiicon = "mdi-wifi-off";
                  // var elewifi = document.getElementById("myBlinkingDiv2");
                  // elewifi.style.color = "black";
                  var elem = document.getElementById("myBlinkingDiv");
                  elem.style.color = "black";
                  // elewifi.style.visibility = "visible";
                  elem.style.visibility = "visible";
                  // console.log("device offline");
                }
                if (min_diff_wifi >= 0 && min_diff_wifi < 1) {
                    // var ele4g = document.getElementById("myBlinkingDiv");
                    // if (ele4g) {
                    //   ele4g.style.color = "green";
                    // }
                    var elems = document.getElementById("myBlinkingDiv2");
                    if (elems) {
                      elems.style.color = "black";
                      elems.style.visibility = "visible";
                    }
                  } else {
                  // this.fgicon = "mdi-signal-off";
                  this.wifiicon = "mdi-wifi-off";
                  var elewifi = document.getElementById("myBlinkingDiv2");
                  elewifi.style.color = "black";
                  // var elem = document.getElementById("myBlinkingDiv");
                  // elem.style.color = "black";
                  elewifi.style.visibility = "visible";
                  // elem.style.visibility = "visible";
                  // console.log("device offline");
                }
                    var that = this;
                    that.t1 = setInterval(function () {
                      // checkloop
                      Vue.axios
                        .get(
                          " https://robopower.xyz/us/cadevices/getuniquedevice?sno=" +
                            val[0].sno
                        )
                        .then((res) => {
                          // var datawifi = res.data[0]
                          let date_1 = res.data[0].lastupdate;
                          let date_3 = res.data[0].lastwifiupdate;
                          this.statuss = res.data[0].status;
                          let date_2 =  moment2().tz('GMT').format('MM/DD/YYYY HH:mm:ss');
                            var min_diff_4G = moment(date_2).diff(
                              moment(date_1),
                              "minutes"
                            );
                            var min_diff_wifi = moment(date_2).diff(
                              moment(date_3),
                              "minutes"
                            );
                            //4G Blink
                            if (min_diff_4G < 1) {
                              //    that.fgicon='mdi-signal-off';
                              // that.wifiicon='mdi-wifi-off';
                              that.fgicon = "mdi-signal-4g";
                              that.wifiicon = "mdi-wifi-off";
                              var ele4g2 =
                                document.getElementById("myBlinkingDiv");
                              if (ele4g2) {
                                ele4g2.style.color = "green";
                                ele4g2.style.visibility =
                                  ele4g2.style.visibility == "hidden"
                                    ? ""
                                    : "hidden";
                              }
                            } else {
                              that.fgicon = "mdi-signal-off";
                              var ele4g2 =
                                document.getElementById("myBlinkingDiv");
                              if (ele4g2) {
                                ele4g2.style.color = "black";
                                ele4g2.style.visibility = "visible";
                              }
                            }
                            //wifi Blink
                            if (min_diff_wifi < 1) {
                              that.wifiicon = "mdi-wifi";
                              var elewifi =
                                document.getElementById("myBlinkingDiv2");
                              if (elewifi) {
                                elewifi.style.color = "green";
                                elewifi.style.visibility =
                                  elewifi.style.visibility == "hidden"
                                    ? ""
                                    : "hidden";
                              }
                            } else {
                              that.wifiicon = "mdi-wifi-off";
                              var elewifi =
                                document.getElementById("myBlinkingDiv2");
                              if (elewifi) {
                                elewifi.style.color = "black";
                                elewifi.style.visibility = "visible";
                              }
                              
                            }
                          
                        });
                      // .catch((err)=>{
                      //               this.$store.dispatch("alert/error").then(()=>{
                      //     setTimeout(()=>{
                      //       this.$store.dispatch("alert/clear")
                      //     },2000)
                      //     })
                      //             })
                    }, 5000);
                

            }
          });
      //     this.postcommandItem = Object.assign({}, this.emptyItem);         
      // console.log(this.postcommandItem,"ppoooooo")
      // Eventemit.$emit("postcommand",{commanditem:this.postcommandItem})


        //     .catch((err)=>{
        //           this.$store.dispatch("alert/error").then(()=>{
        // setTimeout(()=>{
        //   this.$store.dispatch("alert/clear")
        // },2000)
        // })
        //         })
        // },1000)
        this.fetchAll(val[0]);
      
      }
     
    },
    dialog12(val) {
      val || this.close12();
    },
    dialog(val) {
      val || this.addclose();
    },
    dialog2(val) {
      val || this.close2();
      //  this.dialog=val;
    },
    dialog3(val) {
      val || this.close3();
      //  this.dialog=val;
    },
    pdfedit(val) {
      val || this.pdfclose3();
      //  this.dialog=val;
    },
    dialog6(val) {
      val || this.close6();
      //  this.dialog=val;
    },
    dialog7(val) {
      val || this.close7();
      //  this.dialog=val;
    },
    dialog14(val) {
      val || this.close14();
      //  this.dialog=val;
    },
    accclose(val) {
      val || this.accepdialog();
    },
  },

  created() {
    this.initialize();
  
    // this.$store.dispatch("authentication/logout");
  },
  
      
  // },
  beforeMount() {
    this.mounteddata();
    // Vue.axios
    //     .get(
    //       "https://robopower.xyz/app/vehicleroutes/getroutestops2?routeId=RT-102"
    //     )
    //     .then(response => {
    //       this.stops = response.data;
    //       this.stops.sort((a, b) => a.stopid - b.stopid);
    //     });
  },
  mounted() {
     Eventemit.$on("distancetravelled", this.handledistance);
     this.$store.dispatch('fetchDealers');
     this.getList();
      if (this.isplay == false) {
        setInterval(() => {
          this.onRefresh();
        }, 10000);
      }
      // this.clearPostcommandItem()
  },
  // close13() {
  //   // this.editedItem = Object.assign({}, this.defaultItem);
  //   this.caltrackdata = [];
  //   this.dialog13 = false;
  // },

  methods: {

    clearPostcommandItem() {
      console.log("clll")
  this.postcommandItem = Object.assign({}, this.emptyItem);
  this.queuedialog = false;

},

    clearRenewalparams(){
      this.selectedforrenewal = null;
      this.autorenewdialog = false;
      this.mounteddata();
    },
    isautorenewChange(item){
      console.log(item)
      this.selectedforrenewal = item;
      var showtext = item.autorenewal == "1" ? "Disable Autorenew" : "Enable Autorenew";
const Swal = require("sweetalert2");
Swal.fire({
  title: "Are you sure? You want to " + showtext + " for Monthly Subscription",
  text: "You can't revert your action",
  icon: "warning", // Use "icon" instead of "type"
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  showCloseButton: true,
  showLoaderOnConfirm: true,
  preConfirm: () => {
    return new Promise((resolve,reject) => {
      
      var value = item.autorenewal;
      console.log(value)
      const formData = new FormData();
      if (value == "1") {
        formData.append("autorenewal", "0");
        formData.append("autorenewal_id","");
        resolve(formData);
      } else if(value =="0") {
        formData.append("autorenewal", "1");
        resolve(formData);
      } else{
        reject("Failed");
      }

    });
  },
}).then(async(result) => {
  if (result.isConfirmed) {
    if(item.autorenewal == "1"){
      try {
        const headers = {
          Authorization:  process.env.VUE_APP_STRIPE_TOKEN,
        };
        const response = await Vue.axios.delete(
          `https://api.stripe.com/v1/subscriptions/${item.autorenewal_id}`,
          { headers }
        );
        this.cancellationResult = `Subscription ${this.subscriptionId} cancelled successfully.`;
            Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editautorenewal/"+item.id, result.value)
        .then((response) => {
                 setTimeout(()=>{
                  this.selectedforrenewal = null;
      this.mounteddata();
    },1000)
        });
      } catch (error) {
        this.cancellationResult = `Error cancelling subscription: ${error.response.data.error.message}`;
      }
  
    // Vue.axios
    //     .post(
    //       "https://robopower.xyz/us/fullclients/editautorenewal/"+item.id, result.value)
    //     .then((response) => {
    //              setTimeout(()=>{
    //     this.mounteddata();
    // },1000)
    //     });
    } else{
      this.autorenewdialog = true;
    }
      //open dialog and enter card
      
    

  } else {
            setTimeout(()=>{
           this.mounteddata();
    },1000)
  }
});

    },
    openComponent() {
      // this.isset = action;
      // this.postdialog = false; // Close the dialog
      this.queuedialog =true;

      
    },
    issubscribeChange(item){
      var showtext = item.isunavailablevehicle == "1" ? "Remove" : "Add";
const Swal = require("sweetalert2");
Swal.fire({
  title: "Are you sure? You want to " + showtext + " the Subscription",
  text: "You can't revert your action",
  icon: "warning", // Use "icon" instead of "type"
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  showCloseButton: true,
  showLoaderOnConfirm: true,
  preConfirm: () => {
    return new Promise((resolve,reject) => {
      var value = item.isunavailablevehicle;
      const formData = new FormData();
      if (value == "1") {
        formData.append("issubscription", "0");
        resolve(formData);
      } else if(value =="0") {
        formData.append("issubscription", "1");
        resolve(formData);
      } else{
        reject("Failed");
      }

    });
  },
}).then((result) => {
  if (result.isConfirmed) {
    // Success message or any other action if the user confirms
        Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editsubscription/"+item.id, result.value)
        .then((response) => {
                 setTimeout(()=>{
        this.mounteddata();
    },1000)
        });

  } else {
            setTimeout(()=>{
           this.mounteddata();
    },1000)
  }
});

    },
    handledistance(data){
    const { distancetravel, isloading } = distance(data,this.decimalPlaces);
    this.distancetravel = distancetravel;
    this.isloading = isloading;
    },
    linkclose(){
      this.dialog21=false;
    },
    closepaycash(){
      this.dialog19 = false;
    },
    sendpaymentLink(item){
      axios
    .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + item.invoc.pending)
    .then((response) => {
      // console.log(item.email,'hsbfkjsf')
      this.linkemail = item.email;
      this.linkmobile= item.clientphone1;
      this.dialog21 = true;
      this.paymentlink = "https://alcobrakeadmin.com/paymentlink?pi="+response.data.client_secret+"&regnum="+item.regnum+"&amount="+item.invoc.pending+"&mobile="+item.clientphone1+"&email="+item.email
      // console.log(this.linkemail,this.linkmobile,this.paymentlink,"linkemail,linkmobile,dialog,paymentlink")
      // return {linkemail,linkmobile,dialog,paymentlink};
     })
      .catch((err) => {
      this.$store.dispatch("alert/error", err).then(() => {
        setTimeout(() => {
          this.$store.dispatch("alert/clear");
        }, 2000);
      });
    });
    },
    payCash(){
      this.dialog19 = true;
    },
    // getmonthList(id) {
    //   //initial install date
    //   const startDatetime = moment(id.installDate, "MM/DD/YYYY HH:mm:ss");

    //   // Array to store objects
    //   const dataList = [];

    //   // Number of objects to create

    //   const endDat = moment(
    //     moment().format("MM/DD/YYYY HH:mm:ss"),
    //     "MM/DD/YYYY HH:mm:ss"
    //   );
    //   // const endDat = moment().format('MM/DD/YYYY HH:mm:ss');
    //   // console.log(startDatetime)
    //   const monthsDiff = endDat.diff(startDatetime, "months");

    //   // console.log(Math.round(monthsDiff+1));
    //   const numberOfObjects = Math.round(monthsDiff + 1) / 2;

    //   // Loop to create objects with incremented datetime and push data
    //   for (let i = 1; i < numberOfObjects + 1; i++) {
    //     const currentDatetime = startDatetime.clone().add(2 * i - 2, "months");
    //     const endedDatetime = startDatetime.clone().add(2 * i, "months");
    //     // console.log(currentDatetime);
    //     // console.log(endedDatetime);
    //     var fData = [];
    //     // let currentMonthRange = null;
    //     this.logs.forEach((item) => {
    //       1;
    //       const checkDate = moment(item.rtime, "MM/DD/YYYY HH:mm:ss");
    //       const isBetween = checkDate.isBetween(currentDatetime, endedDatetime);
    //       // console.log(isBetween)
    //       if (isBetween) {
    //         fData.push(item);
    //       }
    //     });
    //     const dataObject = {
    //       id: i + 1,
    //       startDate: currentDatetime.format("MM/DD/YYYY HH:mm:ss"),
    //       endDate: endedDatetime.format("MM/DD/YYYY HH:mm:ss"),
    //       data: fData,
    //     };
    //     dataList.push(dataObject);
    //   }

    //   return dataList;
    // },
    subsubmit() {
      this.$refs.elementRef.submit();
    },
    tokenCreated1(token) {
      console.log(token);
    },
    getList() {
      const headers = {
        Authorization:
        process.env.VUE_APP_STRIPE_TOKEN,
      };

      Vue.axios
        .get("https://api.stripe.com/v1/customers", { headers })
        .then((res) => {
          if (res.status == 200) {
            this.stripecustomerList = res.data.data;
          }
        });
    },
    onSubscribe(item) {
      // console.log(item)
      this.subdialog = true;
      if (item.isunavailablevehicle == "1") {
        alert("Already Subscribed");
      } else {
        // console.log("please subscribe")
        var customer = this.stripecustomerList.find(
          (res) => res.email == item.email
        );
        console.log(customer);
      }
    },
    openParams(item) {
      // console.log(this.caltrackdata2);
      this.calparamsdata = this.caltrackdata2.filter((ele) => {
        if (
          ele.caldate == item.caldate &&
          ele.cbid == item.cbid &&
          ele.devid == item.devid
        ) {
          return ele;
        }
      });
      this.dialog17 = true;
    },
    opHistory() {
      if (this.firmware > 34) {
        this.$store.dispatch("fetchdeviceOperations", this.expanded[0].cbid);
        this.dialog16 = true;
      } else {
        this.$store.dispatch("fetchdeviceOperations", this.expanded[0].devId);
        this.dialog16 = true;
      }
    },
    close16() {
      this.dialog16 = false;
      this.$store.state.operations = [];
    },
    close17() {
      this.dialog17 = false;
      this.calparamsdata = [];
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    addformatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    clearcsv() {
      this.csvupload = true;
    },
    csvup() {
      this.csvupload = true;
    },
    csvFileUpload(event) {
      if (!event.target.files[0]) {
        alert("Upload a file.");
      } else {
        let file = event.target.files[0];
        if (file) {
          let reader = new FileReader();
          let config = {
            delimiter: "",
            newline: "",
            quoteChar: '"',
            escapeChar: '"',
            header: true,
            trimHeaders: false,
          };
          reader.onload = (e) =>
            this.$emit("load", Papa.parse(event.target.result, config));
          reader.onload = function (event) {
            let results = Papa.parse(event.target.result, config);
            setTimeout(() => {
              this.csvdata = results.data;
              // console.log(this.csvdata)
              if (this.csvdata != null) {
                var newArr = [];
                this.csvdata.map((element) => {
                  if (element.Date != "") {
                    var eventType,
                      passfailstatus,
                      Bacvalue,
                      randominitial,
                      rtime,
                      enginerunstate = "0";
                    if (
                      element.EventType.replace(/ /g, "") == "INITIALTEST" ||
                      element.EventType.replace(/ /g, "") == "RANDOMTEST"
                    ) {
                      eventType = "254";
                      if (
                        element.EventType.replace(/ /g, "") == "INITIALTEST"
                      ) {
                        randominitial = "2";
                      } else if (
                        element.EventType.replace(/ /g, "") == "RANDOMTEST"
                      ) {
                        randominitial = "1";
                      }
                      rtime = element.Date;
                      Bacvalue = element.brac;
                      if (
                        element.Result == "FAIL" ||
                        element.Result == "PASS"
                      ) {
                        if (element.Result == "FAIL") {
                          passfailstatus = "0";
                          rtime = element.Date;
                          Bacvalue = element.brac;
                        } else if (element.Result == "PASS") {
                          passfailstatus = "1";
                          rtime = element.Date;
                          Bacvalue = element.brac;
                        }
                      }
                    }
                    if (
                      element.EventType.replace(/ /g, "") == "ENGINEON" ||
                      element.EventType.replace(/ /g, "") == "ENGINEOFF"
                    ) {
                      eventType = "15";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                      if (element.EventType.replace(/ /g, "") == "ENGINEON") {
                        enginerunstate = "1";
                      } else {
                        enginerunstate = "0";
                      }
                    } else if (
                      element.EventType.replace(/ /g, "") == "HARD_BLOW"
                    ) {
                      eventType = "66";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "INSUFFICIENTVOLUME"
                    ) {
                      eventType = "65";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "CAR_BATTERY_TAMPERING"
                    ) {
                      eventType = "21";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "VIOLATION_LOCKOUT"
                    ) {
                      eventType = "19";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "BYPASS"
                    ) {
                      eventType = "4";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "TEST_REFUSED_RR_TEST"
                    ) {
                      eventType = "18";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "TAMPERED"
                    ) {
                      eventType = "28";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "CALIBRATIONDONE"
                    ) {
                      eventType = "22";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "START_TEST_ATTEMPT"
                    ) {
                      eventType = "29";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "ENGINE_NOT_STARTED"
                    ) {
                      eventType = "34";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "COOL_SAMPLE"
                    ) {
                      eventType = "27";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "HOT_SAMPLE"
                    ) {
                      eventType = "25";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "ONE_TIME_PASS_CODE"
                    ) {
                      eventType = "8";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "SERVICE_PERIOD_SET"
                    ) {
                      eventType = "6";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "VOILATION_RESET"
                    ) {
                      eventType = "7";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOG_READ"
                    ) {
                      eventType = "11";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT0"
                    ) {
                      eventType = "220";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT1"
                    ) {
                      eventType = "221";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT2"
                    ) {
                      eventType = "222";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT3"
                    ) {
                      eventType = "223";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT4"
                    ) {
                      eventType = "224";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT5"
                    ) {
                      eventType = "225";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT6"
                    ) {
                      eventType = "226";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "LOCKOUT7"
                    ) {
                      eventType = "227";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "TAKE_A_PICTURE"
                    ) {
                      eventType = "56";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "PICTURE_CAPTURED"
                    ) {
                      eventType = "101";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "BYPASS_START"
                    ) {
                      eventType = "60";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "BYPASS_STOP"
                    ) {
                      eventType = "61";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "SERVICE_ALERT"
                    ) {
                      eventType = "14";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "ENGINE_NOT_STARTED"
                    ) {
                      eventType = "34";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") ==
                      "SAMPLER_DISCONNECTED"
                    ) {
                      eventType = "62";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "CAR_BATTERY_OFF"
                    ) {
                      eventType = "10";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    } else if (
                      element.EventType.replace(/ /g, "") == "CAR_BATTERY_ON"
                    ) {
                      eventType = "20";
                      rtime = element.Date;
                      Bacvalue = "255";
                      passfailstatus = "255";
                      randominitial = "255";
                    }
                    // console.log(element)
                    var obj = {
                      bacvalue: Bacvalue,
                      controlId: element.controlId,
                      devid: element.devid,
                      enginerunstate: enginerunstate,
                      gtime: "NA",
                      lat: "NA",
                      lon: "NA",
                      passfailstatus: passfailstatus,
                      randominitial: randominitial,
                      rtime: rtime,
                      typeofevent: eventType,
                      vbat: "NA",
                    };
                    newArr.push(obj);
                  }
                });
              } else {
                alert("error");
              }
              setTimeout(() => {
                Vue.axios
                  .post("https://robopower.xyz/us/causers/addlogs", newArr)
                  .then((res2) => {})
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }, 10000);
            }, 1000);
          };
          reader.readAsText(file);
        } else {
          // alert('Please select a file to upload.')
          this.$store
            .dispatch("alert/error", "Please select a file to upload.")
            .then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
        }
      }
    },
    toggleChecksampler() {
      // console.log(this.iscam)
      this.ismemloading = true;
      this.dialog9 = false;
      this.loadingtext = "Please Wait";
      this.samplercheckbox = !this.samplercheckbox;
      this.$emit("setCheckboxSampler", this.samplercheckbox);
      if (this.samplercheckbox == false) {
        var firmversion = parseFloat(this.firmware);
        // if(firmversion > 34){
        if (this.iscam == "YES") {
          this.logheaders = [
            // { text: "Id", value: "id" },
            { text: "DeviceId", value: "controlId" },
            {
              text: "HandSet",
              align: "left",
              value: "devid",
            },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
          ];
        } else {
          this.logheaders = [
            // { text: "Id", value: "id" },
            { text: "DeviceId", value: "controlId" },
            {
              text: "HandSet",
              align: "left",
              value: "devid",
            },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            // { text: "IMAGE", value: "image", sortable: false },
          ];
  
        }
        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("controlidfetchLogs", obj);
        setTimeout(() => {
          this.ismemloading = false;
          // this.dialog9 = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        }, 6000);
      } else {
        var firmversion = parseFloat(this.firmware);
        // if(firmversion > 34){
        if (this.iscam == "YES") {
          this.logheaders = [
            // { text: "Id", value: "id" },
            {
              text: "SamplerId",
              align: "left",
              value: "devid",
            },
            { text: "CBID", value: "controlId" },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
          ];
        } else {
          this.logheaders = [
            // { text: "Id", value: "id" },
            {
              text: "SamplerId",
              align: "left",
              value: "devid",
            },
            { text: "CBID", value: "controlId" },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            // { text: "IMAGE", value: "image", sortable: false },
          ];
        }
        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("samplefetchLogs", obj);
        setTimeout(() => {
          this.ismemloading = false;
          // this.dialog9 = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        }, 6000);
      }
    },
    toggleCheck() {
      this.ismemloading = true;
      this.dialog9 = false;
      this.checkbox2 = !this.checkbox2;
      this.loadingtext = "Please Wait";
      // this.samplercheckbox = !this.samplercheckbox
      this.$emit("setCheckboxSampler", this.samplercheckbox);
      if (this.samplercheckbox == false) {
        var firmversion = parseFloat(this.firmware);
        // if(firmversion > 34){
        if (this.iscam == "YES") {
          this.logheaders = [
            // { text: "Id", value: "id" },
            { text: "DeviceId", value: "controlId" },
            {
              text: "HandSet",
              align: "left",
              value: "devid",
            },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
          ];
        } else {
          this.logheaders = [
            // { text: "Id", value: "id" },
            { text: "DeviceId", value: "controlId" },
            {
              text: "HandSet",
              align: "left",
              value: "devid",
            },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            // { text: "IMAGE", value: "image", sortable: false },
          ];
        }
        // }else{
        //     if (this.iscam == "YES") {
        //     this.logheaders = [
        //       // { text: "Id", value: "id" },
        //       { text: "DeviceId", value: "controlId" },
        //       {
        //         text: "HandSet",
        //         align: "left",
        //         value: "devid",
        //       },
        //       { text: "TIME", value: "rtime" },
        //       { text: "EVENT", value: "typeofevent", sortable: false },
        //       { text: "BAC", value: "bacvalue", sortable: false },
        //       { text: "RESULT", value: "passfailstatus", sortable: false },
        //       { text: "IMAGE", value: "image", sortable: false },
        //     ]
        //   } else {
        //     this.logheaders = [
        //       // { text: "Id", value: "id" },
        //       { text: "DeviceId", value: "controlId" },
        //   {
        //     text: "HandSet",
        //     align: "left",
        //     value: "devid",
        //       },
        //   { text: "TIME", value: "rtime" },
        //   { text: "EVENT", value: "typeofevent", sortable: false },
        //   { text: "BAC", value: "bacvalue", sortable: false },
        //   { text: "RESULT", value: "passfailstatus", sortable: false },
        //   // { text: "IMAGE", value: "image", sortable: false },
        // ]
        //   }
        // }
        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("controlidfetchLogs", obj);
        setTimeout(() => {
          this.ismemloading = false;
          this.dialog9 = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        }, 6000);
      } else {
        var firmversion = parseFloat(this.firmware);
        // if(firmversion > 34){
        if (this.iscam == "YES") {
          this.logheaders = [
            // { text: "Id", value: "id" },
            {
              text: "SamplerId",
              align: "left",
              value: "devid",
            },
            { text: "CBID", value: "controlId" },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
          ];
        } else {
          this.logheaders = [
            // { text: "Id", value: "id" },
            {
              text: "SamplerId",
              align: "left",
              value: "devid",
            },
            { text: "CBID", value: "controlId" },
            ,
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "pressure Offset", value: "vbat[0]", sortable: false },
            // { text: "Alcohol Offset", value: "vbat[1]", sortable: false },
            // { text: "AMB Temp", value: "vbat[2]", sortable: false },
            // { text: "Altitude", value: "vbat[4]", sortable: false },
            // { text: "IMAGE", value: "image", sortable: false },
          ];
        }
        // }else{
        //    if (this.iscam == "YES") {
        //     this.logheaders = [
        //       // { text: "Id", value: "id" },
        //      {
        //           text: "SamplerId",
        //           align: "left",
        //           value: "devid",
        //         },
        //         { text: "CBID", value: "controlId" },
        //       { text: "TIME", value: "rtime" },
        //       { text: "EVENT", value: "typeofevent", sortable: false },
        //       { text: "BAC", value: "bacvalue", sortable: false },
        //       { text: "RESULT", value: "passfailstatus", sortable: false },
        //       { text: "IMAGE", value: "image", sortable: false },
        //     ]
        //   } else {
        //     this.logheaders = [
        //       // { text: "Id", value: "id" },
        //     {
        //           text: "SamplerId",
        //           align: "left",
        //           value: "devid",
        //         },
        //         { text: "CBID", value: "controlId" },,
        //   { text: "TIME", value: "rtime" },
        //   { text: "EVENT", value: "typeofevent", sortable: false },
        //   { text: "BAC", value: "bacvalue", sortable: false },
        //   { text: "RESULT", value: "passfailstatus", sortable: false },
        //   // { text: "IMAGE", value: "image", sortable: false },
        // ]
        //   }
        // }
        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("samplefetchLogs", obj);
        setTimeout(() => {
          this.ismemloading = false;
          this.dialog9 = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        }, 6000);
      }
    },
    onSelect({ dialCode }) {
      this.editedItem.clienthome = dialCode;
    },
    onSelect2({ dialCode }) {
      this.editedItem.clientwork = dialCode;
    },
    licenceclose() {
      this.licencedialog = false;
      this.dlimage = "";
    },
    async DLimage(item) {
      this.alldatas = item;
      this.licenseloading = true;
      if (this.alldatas != null) {
        const response = await fetch(
          "https://robopower.xyz/app/getImage/getlicense?client=" +
            this.alldatas.client
        );
        const blob = await response.blob();
        // console.log(blob)
        if (blob.type != "") {
          this.licenseloading = false;
          this.licencedialog = true;
          const url = URL.createObjectURL(blob);
          // console.log(url)
          this.dlimage = url;
        } else {
          // console.log("no image")
          this.licenseloading = false;
          this.licencedialog = true;
          this.$store.dispatch("alert/error", "Image Not Found").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        }
      } else {
        console.log("error");
      }
    },
    downloaddlimage() {
      var a = document.createElement("a"); //Create <a>
      a.href = this.dlimage; //Image rawdata Goes here
      a.download = this.alldatas.client; //File name Here
      a.click(); //Downloaded fil
    },
    updatelicenseImage(e) {
      const fileimage = e.target.files[0];
      var fullimg = new File([fileimage], "license" + ".jpg", {
        type: "data:image/jpeg;charset=utf-8",
      });
      this.editedItem.editlicensefile = fullimg;
    },
    updatelicense() {
      this.licensetext = "Please wait";
      this.licenseloading = true;
      const formData22 = new FormData();
      formData22.append("client", this.alldatas.client);
      formData22.append("licensefile", this.editedItem.editlicensefile);
      Vue.axios
        .post("https://robopower.xyz/app/getImage/updatelicense", formData22, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store
            .dispatch("alert/success", "New Driver License Added")
            .then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          this.updatelicencedialog = false;
          this.licenseloading = false;
          this.licensetext = "Please Wait Image Loading";
          this.editedItem.editlicensefile = "";
          document.getElementById("clearupdateinput").value = "";
        });
    },
    dlupdate() {
      this.updatelicencedialog = true;
      this.licencedialog = false;
    },
    closeupdate() {
      this.editedItem.editlicensefile = "";
      document.getElementById("clearupdateinput").value = "";
      this.updatelicencedialog = false;
      this.licencedialog = true;
    },
    uploadlicenseImage(e) {
      const fileimage = e.target.files[0];
      // console.log(fileimage)
      var licensename = new File([fileimage], "license" + ".jpg", {
        type: "data:text/image;charset=utf-8",
      });
      this.editedItem.licensefile = licensename;
    },
    uploadticketImage(e) {
      const fileimage2 = e.target.files[0];
      var ticketname = new File([fileimage2], "ticket" + ".jpg", {
        type: "data:text/image;charset=utf-8",
      });
      this.editedItem.ticketfile = ticketname;
    },
    ophistoryupdatePage(pagination) {
      var count = this.$store.state.operations.length;
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < count; p++) {
          const start = p * pagination.itemsPerPage;
          const end = start + pagination.itemsPerPage;
          if (this.firstItem >= start && this.itemsPerPage < end) {
            pagination.page = p + 1;
            // break out of the loop
            break;
          }
        }
      }
      this.pagination = pagination;
    },
    historyupdatePage(pagination) {
      var count = this.$store.state.logs.length;
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < count; p++) {
          const start = p * pagination.itemsPerPage;
          const end = start + pagination.itemsPerPage;
          if (this.firstItem >= start && this.itemsPerPage < end) {
            pagination.page = p + 1;
            // break out of the loop
            break;
          }
        }
      }
      this.pagination = pagination;
    },
    logsupdatePage(pagination) {
      var count = this.$store.state.logs.length;
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < count; p++) {
          const start = p * pagination.itemsPerPage;
          const end = start + pagination.itemsPerPage;
          if (this.firstItem >= start && this.itemsPerPage < end) {
            pagination.page = p + 1;
            // break out of the loop
            break;
          }
        }
      }
      this.pagination = pagination;
    },
    swappingclose() {
      this.swappingdialog = false;
    },
    SwapDevice(item) {
      if (item.state == "CA" || item.state == "INDIA" || item.state == "TX") {
        var obj = {
          role: this.user.role,
          item: item,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("controlidfetchLogs", obj);
        this.myclient = item;
        this.Acceptance(item);
        this.ismemloading = true;
        this.loadingtext = "Please Wait";
        setTimeout(() => {
          this.swappingdialog = true;
          this.ismemloading = false;
          this.loadingtext = "Please Wait";
        }, 5000);
      } else {
        console.log("empty");
      }
    },
    setTimeformat(ind) {
      // var customtztime = moment(new Date().toLocaleString("en-US", { timeZone: ind.timezone })).format("HH:mm:ss MM/DD/YYYY");
      //     this.customtimestamp = customtztime;
      var count = 15;
      clearInterval(this.tzinterval);
      this.tzinterval = setInterval(() => {
        // console.log(this.customtimestamp)
        if (count < 1) {
          this.timezonetext = "Please Select the Timezone/State";
          this.cancelcustomTime();
          clearInterval(this.tzinterval);
        } else {
          this.customtimestamp = ind.timezone;
          // this.customtimestamp = moment(this.customtimestamp).add(1, 'seconds').format("HH:mm:ss MM/DD/YYYY")
          this.timezonetext = "Please Click OK in " + count + "secs";
        }
        count--;
      }, 1000);
    },
    setcustomTime() {
      clearInterval(this.tzinterval);
      this.timezonetext = "Time Set to " + this.customtimestamp;
      var firmwareversion = parseFloat(this.firmware);
      this.onFinaltimeset(
        firmwareversion > 34 ? this.expanded[0].cbid : this.expanded[0].devId,
        this.customtimestamp,
        "86"
      );
      this.setzone = false;
    },
    cancelcustomTime() {
      clearInterval(this.tzinterval);
      this.ismemloading = false;
      this.customtimestamp = null;
      this.setzone = false;
      this.mytzone = null;
      this.timezonetext = "Please Select the Timezone/State";
    },
    getItemText(item) {
      return `${item.countrycode} ${item.countryname} ${item.timezone} ${item.gmtoffset}`;
    },
    interlockDownloadform() {
      var that = this;
      html2canvas(document.getElementById("interlockpdfcontent")).then(
        function (canvas) {
          var doc = new jsPDF("p", "mm", "a4");
          var imgData = canvas.toDataURL("image/png");
          doc.setFontSize(800);
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          doc.addImage(imgData, "JPEG", 0, 0, width, height);
          doc.save(that.filename);
          // var blob = new Blob([canvas.toDataURL("image/png")], {
          //   type: "text/plain;charset=utf-8",
          // });
        }
      );
      // this.accepdialog = false
      this.interlockdialog = false;
    },
     reportDownloadform() {
      var that = this;
      html2canvas(document.getElementById("reportpdfcontent")).then(
        function (canvas) {
          var doc = new jsPDF("p", "mm", "a4");
          var imgData = canvas.toDataURL("image/png");
          doc.setFontSize(800);
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          doc.addImage(imgData, "JPEG", 0, 0, width, height);
          doc.save(that.filename);
          // var blob = new Blob([canvas.toDataURL("image/png")], {
          //   type: "text/plain;charset=utf-8",
          // });
        }
      );
      // this.accepdialog = false
      this.reportdialog = false;
    },
    // interockprint() {
    //     this.$htmlToPaper("interlockpdfcontent");
    // },
    Prohibitionclose() {
      this.myclient = null;
      this.dialog5 = false;
      this.search = "";
      this.mounteddata();
    },
    payclose() {
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      // console.log(this.payind1)
      this.dialog15 = false;
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
            this.payind1
        )
        .then((res) => {
          if (res.status == 200) {
            this.$store
              .dispatch("alert/error", "Payment Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    payprevious() {
      this.e1 = 1;
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
            this.payind2
        )
        .then((res) => {
          if (res.status == 200) {
            this.payind2 = null;
            this.isCreated2 = false;
            this.$store
              .dispatch("alert/error", "Last Payment Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    payclose2() {
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      // console.log(this.payind2)
      this.dialog7 = false;
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
            this.payind2
        )
        .then((res) => {
          if (res.status == 200) {
            this.$store
              .dispatch("alert/error", "Payment Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    pay() {
      const that = this;
      that.$refs.paymentRef.submit();
    },
    pay2() {
      const that = this;
      that.$refs.paymentRef2.submit();
    },
    async generatePaymentIntent() {
      var amt = this.paymentelements.amount.toFixed(2).toString();
      amt = amt.replace(/\./g, "");
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          var that = this;
          this.payind1 = response.data.id;
          that.elementsOptions.clientSecret = response.data.client_secret;
          this.iscreated = true;
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    // pay2 () {
    //   this.$refs.paymentRef2.submit();
    // },
    async generatePaymentIntent2(obj) {
      this.addclr = obj;
      var amt = this.paymentelements2.amount.toString();
      amt = amt.replace(/\./g, "");
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          if (response.data.client_secret != "") {
            var that = this;
            that.payind2 = response.data.id;
            that.elementsOptions2.clientSecret = response.data.client_secret;
            that.iscreated2 = true;
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    login() {
      if (this.dealeremail && this.dealerpassword) {
        var usermail = this.dealeremail;
        var userpassword = this.dealerpassword;
        Vue.axios
          .get(
            "https://robopower.xyz/us/calogin/getuniquelogin?emailId=" +
              usermail +
              "&password=" +
              userpassword
          )
          .then((response) => {
            if (
              this.oledDevices.filter((value) => value.sno == this.expanded[0].sno)
                .length == 0
            ) {
              var logindata = response.data[0];
              if (
                this.dealeremail == logindata.emailId &&
                this.dealerpassword == logindata.password
              ) {
                this.$store.dispatch("clearcbidclientlogs", this.alldata);

                if (this.isnewinstall == 1) {
                  this.resetAll(this.expanded[0], this.firmwares);
                } else {
                  this.clearlogdialog = false;
                  this.expanded = [];
                  this.search = "";
                }
              } else {
                console.log("error");
              }
            } else {
              var logindata = response.data[0];
              if (
                this.dealeremail == logindata.emailId &&
                this.dealerpassword == logindata.password
              ) {
                this.$store.dispatch("clearclientlogs", this.alldata);

                // this.clearlogdialog = false;
                if (this.isnewinstall == 1) {
                  this.resetAll(this.expanded[0], this.firmwares);
                } else {
                  this.clearlogdialog = false;
                  this.expanded = [];
                  this.search = "";
                }
              } else {
                console.log("error");
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
    },
    fullscreen() {
      var elem = document.getElementById("my-video");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    dlfullscreen() {
      var elem = document.getElementById("cleardlimage");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    print() {
      if (this.editedItem.imobile != "") {
        var imobile = this.editedItem.imobile.split("");
        var iphones =
          imobile[2] +
          imobile[3] +
          imobile[4] +
          imobile[5] +
          " " +
          " " +
          " " +
          " " +
          imobile[6] +
          imobile[7] +
          imobile[8] +
          imobile[9] +
          imobile[10] +
          imobile[11] +
          imobile[12] +
          imobile[13];
      } else {
        iphones = "";
      }
      if (this.editedItem.clientphone1 != "") {
        var clientphone1 = this.editedItem.clientphone1.split("");
        var phone1 =
          clientphone1[2] +
          clientphone1[3] +
          clientphone1[4] +
          clientphone1[5] +
          " " +
          " " +
          " " +
          clientphone1[6] +
          clientphone1[7] +
          clientphone1[8] +
          clientphone1[9] +
          clientphone1[10] +
          clientphone1[11] +
          clientphone1[12] +
          clientphone1[13];
      } else {
        phone1 = "";
      }
      if (this.editedItem.clientphone2 != "") {
        var clientphone2 = this.editedItem.clientphone2.split("");
        var phone2 =
          this.editedItem.clientphone1 != this.editedItem.clientphone2
            ? clientphone2[2] +
              clientphone2[3] +
              clientphone2[4] +
              clientphone2[5] +
              " " +
              " " +
              " " +
              " " +
              clientphone2[6] +
              clientphone2[7] +
              clientphone2[8] +
              clientphone2[9] +
              clientphone2[10] +
              clientphone2[11] +
              clientphone2[12] +
              clientphone2[13]
            : "";
      } else {
        phone2 = "";
      }
      var alldataonj = {
        dlnum: this.editedItem.dlnum,
        client: this.editedItem.client,
        suffix: this.editedItem.suffix,
        clientAddress: this.editedItem.clientAddress,
        city: this.editedItem.city,
        state: this.editedItem.state,
        zipcode: this.editedItem.zipcode,
        rclientAddress: this.editedItem.rclientAddress,
        rspace: this.editedItem.rspace,
        rcity: this.editedItem.rcity,
        rstate: this.editedItem.rstate,
        rzipcode: this.editedItem.rzipcode,
        dob: this.editedItem.dob,
        clientphone1: phone1,
        clientphone2: phone2,
        sno: this.editedItem.sno,
        officeName: this.editedItem.officeName,
        officelicensenumber: this.editedItem.officelicensenumber,
        faddress: this.editedItem.faddress,
        fcity: this.editedItem.fcity,
        fstate: this.editedItem.fstate,
        fzipcode: this.editedItem.fzipcode,
        installDate: this.editedItem.installDate,
        make: this.editedItem.make,
        year: this.editedItem.year,
        regnum: this.editedItem.regnum,
        vin: this.editedItem.vin,
        userName: this.editedItem.installer.userName,
        imobile: iphones,
        space: this.editedItem.space,
        umake: this.editedItem.umake,
        uyear: this.editedItem.uyear,
        uregnum: this.editedItem.uregnum,
        uvin: this.editedItem.uvin,
        calibrationDate: this.editedItem.calibrationDate,
        nextcmonitordate: this.editedItem.nextcmonitordate,
        prohibstart: this.editedItem.prohibstart,
        prohibend: this.editedItem.prohibend,
        myview: "web",
      };

      Vue.axios
        .post("https://alcobraketest.in/genpdf", alldataonj)
        .then((res) => {
          if (res.data.status != "NO FILE FOUND") {
            const link = document.createElement("a");
            link.href = window.open(res.data.status);
          } else {
            alert("NO FILE FOUND");
          }
        });
    },

    formclose: function () {
      (this.pdfdialog = false), this.formclearall();
    },
    formclearall() {
      (this.editedItem.dlnum = ""),
        (this.editedItem.client = ""),
        (this.editedItem.suffix = ""),
        (this.editedItem.clientAddress = ""),
        (this.editedItem.city = ""),
        (this.editedItem.email = ""),
        (this.editedItem.space = ""),
        (this.editedItem.state = ""),
        (this.editedItem.zipcode = ""),
        (this.editedItem.rclientAddress = ""),
        (this.editedItem.rcity = ""),
        (this.editedItem.rstate = ""),
        (this.editedItem.rzipcode = ""),
        (this.editedItem.dob = ""),
        // this.editedItem.device = "",
        // this.editedItem.cbid = "",
        (this.editedItem.sno = ""),
        (this.editedItem.calibrationperiod = ""),
        (this.editedItem.calibrationdate = ""),
        (this.editedItem.nextmoniterdate = ""),
        (this.editedItem.model = ""),
        (this.editedItem.color = ""),
        (this.editedItem.tag = ""),
        (this.editedItem.tagstate = ""),
        (this.editedItem.prohibitionnumber = ""),
        (this.editedItem.conviction = ""),
        (this.editedItem.clienthome = ""),
        (this.editedItem.clientwork = ""),
        (this.editedItem.clientphone1 = ""),
        (this.editedItem.clientphone2 = ""),
        (this.editedItem.sno = ""),
        (this.editedItem.officeId = ""),
        (this.editedItem.officeName = ""),
        (this.editedItem.officelicensenumber = ""),
        (this.editedItem.faddress = ""),
        (this.editedItem.fcity = ""),
        (this.editedItem.fstate = ""),
        (this.editedItem.fzipcode = ""),
        (this.editedItem.installDate = ""),
        (this.editedItem.make = ""),
        (this.editedItem.year = ""),
        (this.editedItem.regnum = ""),
        (this.editedItem.vin = ""),
        (this.editedItem.prohibstart = ""),
        (this.editedItem.prohibend = ""),
        (this.editedItem.installer = ""),
        (this.editedItem.imobile = "");
    },
    interlockformclose() {
      this.interlockdialog = false;
    },
     reportformclose() {
      this.reportdialog = false;
    },
    submit(data) {
      const that = this;
      that.paymentelements.amount = data.pending;
      that.paymentelements.email = that.expanded[0].email;
      that.dialog15 = true;
      that.generatePaymentIntent();
    },
    confirmSendlink(){
      // console.log(this.paymentlink,'confmlink')
      var fData = new FormData();
            fData.append('to', this.linkemail)
            console.log(this.paymentlink,'confirmlink')
            fData.append('message', "Dear Customer, You have an outstanding payment Pending. To make the payment securely, please click on the following link:"+this.paymentlink)
            fData.append('subject', "PENDING PAYMENT")
                  Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                //  console.log(res.status,'confirm sentlink')
                if (res.status == 200) {
                  this.$store
              .dispatch("alert/error", "Payment Link Sent")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 1000);
              this.dialog21=false;
              this.newdialog=false;

              });
                }
        })
    },
    confirmPaycash(){
      // console.log("confirmed cash paid");
      var usermail = this.user.emailId;
        var userpassword = this.mydealerpassword;
        Vue.axios
          .get(
            "https://robopower.xyz/us/calogin/getuniquelogin?emailId=" +
              usermail +
              "&password=" +
              userpassword
          )
          .then((response) => {
              if(response.data){
              var logindata = response.data[0];
              if (
                usermail == logindata.emailId &&
                userpassword == logindata.password
              ) {
      Vue.axios.get('https://robopower.xyz/us/cainvoices/clearallinvoices?regnum=' + this.onPaying.regnum)
        .then((res) => {

          if (res.status == 200) {
            // console.log(this.onPaying.email)
            var fData = new FormData();
            fData.append('to', this.onPaying.email)
            fData.append('message', "Dear customer your last transaction  of Amount"+this.onPaying.invoc.pending+"is recieved by cash")
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                // console.log(res.status)
                if (res.status == 200) {
                  this.$store
              .dispatch("alert/error", "Cash collected")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 1000);
              });
                  setTimeout(() => {
                    this.mydealerpassword = "";
                    window.location.reload();
                  }, 3000)

                }
              }).catch((err) => {
                alert(err)
              })

          }
        }).catch((err) => {
          alert(err)
        })
      } else{
        this.$store
              .dispatch("alert/error", "Entered Incorrect Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
      }
    } else{
      this.$store
              .dispatch("alert/error", "Entered Incorrect Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
    }
      })
    },
    submit3(item) {
     
      this.newdialog = true;
      this.onPaying = item;
    },
    submit2(data) {
       const {clientphone1,regnum,email,amount} = payonline(data);
      this.paymentItem.clientphone1 = clientphone1;
      this.paymentItem.regnum = regnum;
      this.paymentItem.email = email;
      this.paymentelements.amount = amount;
      this.paymentelements.email = email;
      this.dialog15 = true;
      this.generatePaymentIntent();
    },
    paymentclose(){
      this.newdialog=false;
    },
    tokenCreated(token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.id,
        amount: this.amount, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
        description: this.description, // optional description that will show up on stripe when looking at payments
      };
      this.sendTokenToServer(this.charge);
    },
    sendTokenToServer(charge) {
      // Send to charge to your backend server to be processed
      // Documentation here: https://stripe.com/docs/api/charges/create
    },
    accclose() {
      this.accepdialog = false;
      this.signdialog = false;
    },
    onRefresh() {
      if (this.isplay == false) {
        if (this.$refs.liveTrack) {
          this.$refs.liveTrack.onLoad();
        }
      }
    },
    calctime(data) {
      // console.log("time calculating")
      // console.log(data)
      if(data){
      var data1 = data;
      if (data1.gtime != "") {
        var before = moment(data1.gtime);
        var present = moment(moment().format("MM/DD/YYYY HH:mm:ss"));
        var calculate = present.diff(before, "hours");
        if (calculate > 24) {
          var calculate2 = present.diff(before, "days");
          this.lastupdate = calculate2 + " days Ago.";
        } else {
          var calculate3 = moment.duration(present.diff(before));
          var hours = parseInt(calculate3.asHours());
          var minutes = parseInt(calculate3.asMinutes()) - hours * 60;
          this.lastupdate = hours + " hour and " + minutes + " minutes Ago.";
        }
      } else {
        this.lastupdate = "NO UPDATE";
      }
    } else{
      this.lastupdate = "NO UPDATE";
    }
    },
    routeclosed() {
        const Swal = require("sweetalert2");
        Swal.fire("No route found").then(() => {
          // console.log('No route found33')
          this.stop();
        });
    },
    changeFdate() {
      this.isplay = false;
      this.fromdate = moment(this.fromdate, "YYYY-MM-DD").format(
        "YYYY-MM-DD HH:mm:ss"
      );
       if(this.todate != null){
          this.checkTime();
      }
      return;
    },
    changeTdate() {
      this.isplay = false;
      this.todate = moment(this.todate, "YYYY-MM-DD").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      if(this.fromdate != null){
        this.checkTime();
      }
      
    },
    checkTime() {
      const fromDateTime = moment(this.fromdate, "YYYY-MM-DD HH:mm:ss");
      const toDateTime = moment(this.todate, "YYYY-MM-DD HH:mm:ss");

      const duration = moment.duration(toDateTime.diff(fromDateTime));
      const hours = duration.asHours();

      if (hours > 24) {
        // Display alert or take any other action
         this.fromdate = null;
         this.todate= null;
         this.$refs.datetimeform.reset();
         this.stop();
         this.$store
              .dispatch("alert/error", "The choosen date and time range must not exceed a 24-hour period")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
      } 
    },
    openMap(item) {
      // console.log(item);
      var obj = {
        role: this.user.role,
        item: item,
        sorted: this.checkbox2,
      };

      this.$store.dispatch("controlidfetchLogs", obj);
      this.ping = [item];
      this.dialog14 = true;
    },
    clearstorage(item, firmware, isnewinstall) {
      this.isnewinstall = isnewinstall;
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Add " + item.devId + " to clear log",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.firmwares = firmware;
            this.alldata = item;
            this.clearlogdialog = true;
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    clearclose() {
      this.clearlogdialog = false;
    },
    newgoCommand(item, command) {
      var mainid =
        this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ? item.cbid
          : item.devId;
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            Vue.axios
              .post("https://robopower.xyz/app/commands/permanant", data)
              .then((res) => {
                if (res.data == "SUCCESS") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  this.$store
                    .dispatch("alert/success", "OPERATION SENT")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                } else {
                  this.$store
                    .dispatch("alert/success", "OPERATION NOT DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
          // })
          // }
          // })
          //     } else {
          //       // this.$swal("Cancelled", "Your Request Cancelled", "info");
          //        this.$store.dispatch("alert/error", "Your Request Cancelled").then(()=>{
          // setTimeout(()=>{
          //   this.$store.dispatch("alert/clear")
          // },2000)
          // })
          //     }
        });
    
    },
    goCommand(item, command, firmware) {
      var mainid =
        this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ? item.cbid
          : item.devId;
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  this.$store
                    .dispatch("alert/success", "OPERATION DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                } else {
                  this.$store
                    .dispatch("alert/success", "OPERATION NOT DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
          // })
          // }
          // })
          //     } else {
          //       // this.$swal("Cancelled", "Your Request Cancelled", "info");
          //        this.$store.dispatch("alert/error", "Your Request Cancelled").then(()=>{
          // setTimeout(()=>{
          //   this.$store.dispatch("alert/clear")
          // },2000)
          // })
          //     }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    memgoCommand(item, command, firmware) {
      var firmwareversion = parseFloat(firmware);
      if (this.user.emailId != "Justin@alcobrake.com") {
        var that = this;
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            if (
              this.oledDevices.filter((value) => value.sno == item.sno).length == 0
            ) {
              Vue.axios
                .get(
                  "https://robopower.xyz/v2/eeprom/deletecbwifilogs?cbid=" +
                    item.cbid
                )
                .then((res) => {
                  if (res.status == 200) {
                    //log read
                    that.ismemloading = true;
                    that.loadingtext = "Data Uploading Please Wait";
                    var cmdData = {
                      devId: item.cbid,
                      command: firmwareversion > 0.94 ? "92" : "97",
                      resp: "",
                      refby:
                        this.user.role == "office"
                          ? this.user.officeId
                          : this.user.dealerId,
                      role: this.user.role,
                    };
                    var data = JSON.stringify(cmdData);
                    var logcount = 0;
                    var checkcount = 6;
                    var zerologcount = 5;
                    Vue.axios
                      .post("https://robopower.xyz/app/commands", data)
                      .then((resp) => {
                        if (resp.data == "OPERATION DONE") {
                          that.loadingtext = "Data Uploading Please Wait";
                          that.inter = setInterval(() => {
                            Vue.axios
                              .get(
                                "https://robopower.xyz/v2/eeprom/getcbwifilogs?cbid=" +
                                  item.cbid
                              )
                              .then((res) => {
                                if (res.data != null) {
                                  if (logcount == res.data.length) {
                                    checkcount--;
                                    Vue.axios
                                      .get(
                                        "https://robopower.xyz/v2/ping/wifiresponse/" +
                                          item.cbid
                                      )
                                      .then((res) => {
                                        // console.log(res.data)
                                        if (res.data != null) {
                                          if (checkcount < 1) {
                                            that.ismemloading = false;
                                            clearInterval(that.inter);
                                            const Swal = require("sweetalert2");
                                            Swal.fire({
                                              title: "Data Uploading Finished",
                                              icon: "success",
                                              showCloseButton: true,
                                            });
                                            setTimeout(() => {
                                              checkcount = 6;
                                              that.clearWifidata(
                                                item,
                                                command,
                                                firmware
                                              );
                                            }, 1000);
                                          } else {
                                            that.loadingtext =
                                              "Data Uploading Please Wait" +
                                              " " +
                                              checkcount +
                                              " seconds";
                                          }
                                        } else {
                                          that.loadingtext =
                                            "Data Uploading Please Wait" +
                                            " " +
                                            checkcount +
                                            " seconds";
                                          if (checkcount < 1) {
                                            clearInterval(that.inter);
                                            that.ismemloading = false;
                                            that.loadingtext = "Please Wait";
                                            const Swal = require("sweetalert2");
                                            Swal.fire({
                                              title: "Data Uploading Finished",
                                              icon: "success",
                                              animation: true,
                                              showCloseButton: true,
                                            });
                                            setTimeout(() => {
                                              checkcount = 6;
                                              that.clearWifidata(
                                                item,
                                                command,
                                                firmware
                                              );
                                            }, 1000);
                                          }
                                        }
                                      })
                                      .catch((err) => {
                                        that.ismemloading = false;
                                        that.loadingtext = "Please Wait";
                                        clearInterval(that.inter);
                                        this.$store
                                          .dispatch("alert/error", err)
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                      });
                                  } else {
                                    checkcount = 6;
                                    logcount = res.data.length;
                                    that.loadingtext =
                                      "Data Uploading Please Wait";
                                  }
                                } else {
                                  that.loadingtext =
                                    "Please Wait " + zerologcount + " seconds";
                                  if (zerologcount < 1) {
                                    clearInterval(that.inter);
                                    that.ismemloading = false;
                                    that.loadingtext = "Please Wait";
                                    const Swal = require("sweetalert2");
                                    Swal.fire({
                                      title: "Logs Not Found",
                                      icon: "error",
                                      animation: true,
                                      showCloseButton: true,
                                    });
                                  } else {
                                    zerologcount--;
                                  }
                                }
                              })
                              .catch((err) => {
                                that.ismemloading = false;
                                that.loadingtext = "Please Wait";
                                clearInterval(that.inter);
                                this.$store
                                  .dispatch("alert/error", err)
                                  .then(() => {
                                    setTimeout(() => {
                                      this.$store.dispatch("alert/clear");
                                    }, 2000);
                                  });
                              });
                          }, 2000);
                        } else {
                          const Swal = require("sweetalert2");
                          Swal.fire({
                            title: "Data Uploading Failed ",
                            icon: "error",
                            animation: true,
                            showCloseButton: true,
                          });
                          that.ismemloading = false;
                          that.loadingtext = "Please Wait";
                        }
                      })
                      .catch((err) => {
                        that.ismemloading = false;
                        that.loadingtext = "Please Wait";
                        this.$store.dispatch("alert/error", err).then(() => {
                          setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                          }, 2000);
                        });
                      });
                  }
                })
                .catch((err) => {
                  this.$store.dispatch("alert/error", err).then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });
                });
            } else {
              // console.log(item.devId)
              Vue.axios
                .get(
                  "https://robopower.xyz/v2/eeprom/deletewifilogs?deviceId=" +
                    item.devId
                )
                .then((res) => {
                  if (res.status == 200) {
                    //log read
                    that.ismemloading = true;
                    that.loadingtext = "Data Uploading Please Wait";
                    var cmdData = {
                      devId: item.devId,
                      command: firmware > 0.94 ? "92" : "97",
                      resp: "",
                      refby:
                        this.user.role == "office"
                          ? this.user.officeId
                          : this.user.dealerId,
                      role: this.user.role,
                    };
                    var data = JSON.stringify(cmdData);
                    var logcount = 0;
                    var checkcount = 6;
                    var zerologcount = 5;
                    Vue.axios
                      .post("https://robopower.xyz/app/commands", data)
                      .then((resp) => {
                        if (resp.data == "OPERATION DONE") {
                          that.loadingtext = "Data Uploading Please Wait";
                          that.inter = setInterval(() => {
                            Vue.axios
                              .get(
                                "https://robopower.xyz/v2/eeprom/getwifilogs?deviceId=" +
                                  item.devId
                              )
                              .then(function (res) {
                                if (res.data != null) {
                                  if (logcount == res.data.length) {
                                    checkcount--;
                                    Vue.axios
                                      .get(
                                        "https://robopower.xyz/v2/ping/wifiresponse/" +
                                          item.devId
                                      )
                                      .then((res) => {
                                        if (res.data != null) {
                                          if (
                                            res.data[0].response.includes(
                                              "data_upload"
                                            ) ||
                                            checkcount < 1
                                          ) {
                                            that.ismemloading = false;
                                            that.loadingtext = "Please Wait";
                                            clearInterval(that.inter);
                                            const Swal = require("sweetalert2");
                                            Swal.fire({
                                              title: "Data Uploading Finished",
                                              icon: "success",
                                              showCloseButton: true,
                                            });
                                            setTimeout(() => {
                                              checkcount = 6;
                                              that.clearWifidata(
                                                item,
                                                command,
                                                firmware
                                              );
                                            }, 1000);
                                          } else {
                                            that.loadingtext =
                                              "Data Uploading Please Wait" +
                                              " " +
                                              checkcount +
                                              " seconds";
                                          }
                                        } else {
                                          that.loadingtext =
                                            "Data Uploading Please Wait" +
                                            " " +
                                            checkcount +
                                            " seconds";
                                          if (checkcount < 1) {
                                            clearInterval(that.inter);
                                            that.ismemloading = false;
                                            that.loadingtext = "Please Wait";
                                            const Swal = require("sweetalert2");
                                            Swal.fire({
                                              title: "Data Uploading Finished",
                                              icon: "success",
                                              animation: true,
                                              showCloseButton: true,
                                            });
                                            setTimeout(() => {
                                              checkcount = 6;
                                              that.clearWifidata(
                                                item,
                                                command,
                                                firmware
                                              );
                                            }, 1000);
                                          }
                                        }
                                      })
                                      .catch((err) => {
                                        that.ismemloading = false;
                                        that.loadingtext = "Please Wait";
                                        clearInterval(that.inter);
                                        this.$store
                                          .dispatch("alert/error", err)
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                      });
                                  } else {
                                    checkcount = 6;
                                    logcount = res.data.length;
                                    that.loadingtext =
                                      "Data Uploading Please Wait";
                                  }
                                } else {
                                  that.loadingtext =
                                    "Please Wait " + zerologcount + " seconds";
                                  if (zerologcount < 1) {
                                    clearInterval(that.inter);
                                    that.ismemloading = false;
                                    that.loadingtext = "Please Wait";
                                    const Swal = require("sweetalert2");
                                    Swal.fire({
                                      title: "Logs Not Found",
                                      icon: "error",
                                      animation: true,
                                      showCloseButton: true,
                                    });
                                  } else {
                                    zerologcount--;
                                  }
                                }
                              })
                              .catch((err) => {
                                that.ismemloading = false;
                                that.loadingtext = "Please Wait";
                                clearInterval(that.inter);
                                this.$store
                                  .dispatch("alert/error", err)
                                  .then(() => {
                                    setTimeout(() => {
                                      this.$store.dispatch("alert/clear");
                                    }, 2000);
                                  });
                              });
                          }, 2000);
                        } else {
                          that.ismemloading = false;
                          that.loadingtext = "Please Wait";
                          const Swal = require("sweetalert2");
                          Swal.fire({
                            title: "Request Not Sent",
                            icon: "error",
                            animation: true,
                            showCloseButton: true,
                          });
                          //                this.$store.dispatch("alert/error", "OPERATION NOT DONE").then(()=>{
                          // setTimeout(()=>{
                          //   this.$store.dispatch("alert/clear")
                          // },2000)
                          // })
                        }
                      })
                      .catch((err) => {
                        that.ismemloading = false;
                        that.loadingtext = "Please Wait";
                        this.$store.dispatch("alert/error", err).then(() => {
                          setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                          }, 2000);
                        });
                      });
                  }
                })
                .catch((err) => {
                  this.$store.dispatch("alert/error", err).then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });
                });
            }
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    clearWifidata(item, check, firmware) {
      var obj = {
        role: this.user.role,
        item: item,
        sorted: this.checkbox2,
      };
      this.$store.dispatch("controlidfetchLogs", obj);
      if (this.oledDevices.filter((value) => value.sno == item.sno).length == 0) {
        Vue.axios
          .get(
            "https://robopower.xyz/v2/eeprom/deletecbwifilogs?cbid=" + item.cbid
          )
          .then((res) => {
            if (res.status == 200) {
              if (check == 0) {
                // memory reset
                // clearInterval(this.inter);
                this.confirmMemoryreset(item, "87", firmware);
              } else {
                // log read
                this.ismemloading = false;
                this.loadingtext = "Please Wait";
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        Vue.axios
          .get(
            "https://robopower.xyz/v2/eeprom/deletewifilogs?deviceId=" +
              item.devId
          )
          .then((res) => {
            if (res.status == 200) {
              if (check == 0) {
                // memory reset
                this.confirmMemoryreset(item, "87", firmware);
              } else {
                // log read
                this.ismemloading = false;
                this.loadingtext = "Please Wait";
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
    },
    confirmMemoryreset(item, command, firmware) {
      var that = this;
      that.ismemloading = false;
      that.loadingtext = "Please Wait";
      const Swal = require("sweetalert2");
      Swal.fire({
        title:
          "Successfully Data Uploaded, Are you sure? You want to Clear Memory of " +
          item.regnum +
          "Vehicle",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          if (
            this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ) {
            var cmdData = {
              devId: item.cbid,
              command: command,
              resp: "",
            };
          } else {
            var cmdData = {
              devId: item.devId,
              command: command,
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
          }
          var data = JSON.stringify(cmdData);
          this.loadingtext = "Please Wait";
          this.ismemloading = true;
          Vue.axios
            .post("https://robopower.xyz/app/commands", data)
            .then((res) => {
              if (res.data == "OPERATION DONE") {
                this.text = command + " " + "command sent successfully";
                this.alerts = true;
                this.ismemloading = false;
                this.$store
                  .dispatch("alert/error", "OPERATION DONE")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });
              } else {
                this.ismemloading = false;
                this.$store
                  .dispatch("alert/error", "OPERATION NOT DONE")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });
              }
            })
            .catch((err) => {
              this.$store.dispatch("alert/error", err).then(() => {
                this.ismemloading = false;
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            });
          this.$store.dispatch("alert/success", "Action Performed").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/success");
            }, 2000);
          });
        } else {
          clearInterval(this.inter);
          this.$store
            .dispatch("alert/error", "Your Request Cancelled")
            .then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/error");
              }, 2000);
            });
        }
      });
    },
    
    viogoCommand(item, command, firmware) {
      var mainid;
      var refid;
      var firmwareversion = parseFloat(firmware);
      if (this.oledDevices.filter((value) => value.sno == item.sno).length == 0) {
        mainid = item.cbid;
        refid = item.devId;
      } else {
        mainid = item.devId;
        refid = item.cbid;
      }
      // console.log(mainid)
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            // this.loadingtext = "Please Wait"
            // this.ismemloading = true
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  // this.ismemloading = false
                  this.$store
                    .dispatch("alert/success", "OPERATION DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                } else {
                  if (firmwareversion > 0) {
                    this.$store
                      .dispatch("alert/success", "OPERATION NOT DONE")
                      .then(() => {
                        setTimeout(() => {
                          this.$store.dispatch("alert/clear");
                        }, 2000);
                      });
                  } else {
                    //here sending command to sampler id if failed with control board
                    var cmdData = {
                      devId: refid,
                      command: command,
                      resp: "",
                      refby:
                        this.user.role == "office"
                          ? this.user.officeId
                          : this.user.dealerId,
                      role: this.user.role,
                    };
                    var data = JSON.stringify(cmdData);
                    // this.loadingtext = "Please Wait"
                    // this.ismemloading = true
                    Vue.axios
                      .post("https://robopower.xyz/app/commands", data)
                      .then((res) => {
                        if (res.data == "OPERATION DONE") {
                          this.text =
                            command + " " + "command sent successfully";
                          this.alerts = true;
                          // this.ismemloading = false
                          this.$store
                            .dispatch("alert/success", "OPERATION DONE")
                            .then(() => {
                              setTimeout(() => {
                                this.$store.dispatch("alert/clear");
                              }, 2000);
                            });
                        } else {
                          // this.ismemloading = false
                          this.$store
                            .dispatch("alert/success", "OPERATION NOT DONE")
                            .then(() => {
                              setTimeout(() => {
                                this.$store.dispatch("alert/clear");
                              }, 2000);
                            });
                        }
                      })
                      .catch((error) => {
                        this.$store.dispatch("alert/error", error).then(() => {
                          // this.ismemloading = false
                          setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                          }, 2000);
                        });
                      });
                  }
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            // this.$swal("Action Performed", "", "success");
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          } else {
            // this.$swal("Cancelled", "Your Request Cancelled", "info");
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    deleteResponce(item, firmware) {
      var firmversion = parseFloat(firmware);
      if (firmversion > 34) {
        Vue.axios
          .get("https://robopower.xyz/v2/ping/deletewifiresp/" + item.cbid)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
        Vue.axios
          .get("https://robopower.xyz/v2/ping/deletefgresp/" + item.cbid)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
        Vue.axios
          .get("https://robopower.xyz/app/commands/deletecommand/" + item.cbid)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        Vue.axios
          .get("https://robopower.xyz/v2/ping/deletewifiresp/" + item.devId)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
        Vue.axios
          .get("https://robopower.xyz/v2/ping/deletefgresp/" + item.devId)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
        Vue.axios
          .get("https://robopower.xyz/app/commands/deletecommand/" + item.devId)
          .then(() => {})
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
    },
    loadFormDataDeviceStatus(item) {
      const formData = new FormData();
      formData.append("cbid", item.cbid);
      formData.append("status", "Installed");
      return formData;
    },
    loadFormDataDeviceStatus2(item) {
      const formData = new FormData();
      formData.append("devid", item.devId);
      formData.append("status", "Installed");
      return formData;
    },
    goCommandinstall2(item, firmware) {
      this.downloadItem(item, 1);
    },
   
    downloadPDF(){
    this.$http.get('/files/Attachment III - IID Program Data Field Description - FINAL.pdf', { responseType: 'blob' })
        .then(response => {
          console.log(typeof(response))
          const url = window.URL.createObjectURL(new Blob([response.body]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error('Error downloading PDF:', error);
        });
    },

    resetAll(item, firmware) {
      this.clearlogdialog = false;
      const Swal = require("sweetalert2");
      // Memory reset
      Swal.fire({
        title:
          "Are you sure? You want do Memory Reset of " +
          item.regnum +
          " " +
          "Vehicle",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var cmdData;
          if (
            this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ) {
            cmdData = {
              devId: item.cbid,
              command: "87",
              resp: "",
            };
            // 87
          } else {
            cmdData = {
              devId: item.devId,
              command: "87",
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
          }
          var data = JSON.stringify(cmdData);
          this.loadingtext = "Please Wait";
          this.ismemloading = true;
          Vue.axios
            .post("https://robopower.xyz/app/commands", data)
            .then((res) => {
              if (res.data == "OPERATION DONE") {
                this.text = "command sent successfully";
                this.alerts = true;
                // this.ismemloading = false
                this.$store
                  .dispatch("alert/error", "MEM SET OPERATION DONE")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });

                // Device Config
                const Swal = require("sweetalert2");
                Swal.fire({
                  title:
                    "Are you sure? You want to Perform an Device Config on " +
                    item.regnum +
                    " " +
                    "Vehicle",
                  text: "You can't revert your action",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    var cmdData;
                    if (
                      this.oledDevices.filter((value) => value.sno == item.sno)
                        .length == 0
                    ) {
                      cmdData = {
                        devId: item.cbid,
                        command: "94",
                        resp: "60,30,25",
                      };
                    } else {
                      cmdData = {
                        devId: item.devId,
                        command: "94",
                        resp: "60,30,25",
                        refby:
                          this.user.role == "office"
                            ? this.user.officeId
                            : this.user.dealerId,
                        role: this.user.role,
                      };
                    }
                    var data = JSON.stringify(cmdData);
                    this.loadingtext = "Please Wait";
                    this.ismemloading = true;
                    Vue.axios
                      .post("https://robopower.xyz/app/commands", data)
                      .then((res) => {
                        if (res.data == "OPERATION DONE") {
                          // this.ismemloading = false;
                          this.loadingtext = "Please Wait";
                          this.text = "command sent successfully";
                          this.alerts = true;
                          this.expanded = [];
                          this.ismemloading = false;
                          if (
                            this.oledDevices.filter(
                              (value) => value.sno == item.sno
                            ).length == 0
                          ) {
                            //edit device status
                            const fd = this.loadFormDataDeviceStatus(item);
                            this.$store.dispatch("editDevicemode", fd);
                          } else {
                            const fd = this.loadFormDataDeviceStatus2(item);
                            this.$store.dispatch("editDevicemode2", fd);
                          }
                          //create Invoice
                          this.autoCreateInvoice(item);
                          this.$store
                            .dispatch(
                              "alert/error",
                              "DEVICE CONFIG OPERATION DONE"
                            )
                            .then(() => {
                              setTimeout(() => {
                                this.$store.dispatch("alert/error");
                              }, 2000);
                            });
                        } else {
                          this.loadingtext = "Please Wait";
                          this.text = "command sent successfully";
                          this.alerts = true;
                          this.expanded = [];
                          this.ismemloading = false;
                          this.$store
                            .dispatch("alert/error", "DEVICE CONFIG FAILED")
                            .then(() => {
                              setTimeout(() => {
                                this.$store.dispatch("alert/error");
                              }, 2000);
                            });
                        }
                      });
                  }
                });
              } else {
                this.loadingtext = "Please Wait";
                this.text = "command sent successfully";
                this.alerts = true;
                this.expanded = [];
                this.ismemloading = false;
                this.$store
                  .dispatch("alert/error", "MEMORY RESET FAILED")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });
              }
            });
        }
      });
    },
    autoCreateInvoice(item) {
      var body;
      var newInfo = {
        invoiceId: null,
        office: item.officeId,
        client: item.client,
        date: moment(new Date()).format("MM/DD/YYYY"),
        service: [
          {
            actualamount: "75.00",
            description: "",
            discount: "0",
            invoiceId: null,
            service: "New-Installation",
            total: "75",
          },
        ],
        regnum: item.regnum,
        total: "70.00",
        prevbalance: "75.00",
        grandtotal: "0.00",
        amountpaid: "0.00",
      };
      body = JSON.stringify(newInfo);
      this.$store
        .dispatch("createInvoice", body)
        .then((resp) => {})
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    goCommandinstall(item, firmware) {
      const Swal = require("sweetalert2");
      // Memory reset
      Swal.fire({
        title:
          "Are you sure? You want do Memory Reset of " +
          item.regnum +
          " " +
          "Vehicle",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var cmdData;
          if (
            this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ) {
            cmdData = {
              devId: item.cbid,
              command: "87",
              resp: "",
            };
            // 87
          } else {
            cmdData = {
              devId: item.devId,
              command: "87",
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
          }
          var data = JSON.stringify(cmdData);
          this.loadingtext = "Please Wait";
          this.ismemloading = true;
          Vue.axios
            .post("https://robopower.xyz/app/commands", data)
            .then((res) => {
              if (res.data == "OPERATION DONE") {
                this.text = "command sent successfully";
                this.alerts = true;
                // this.ismemloading = false
                this.$store
                  .dispatch("alert/error", "OPERATION DONE")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });

                // Device Config
                const Swal = require("sweetalert2");
                Swal.fire({
                  title:
                    "Are you sure? You want to Perform an Device Config on " +
                    item.regnum +
                    " " +
                    "Vehicle",
                  text: "You can't revert your action",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                })
                  .then((result) => {
                    if (result.value) {
                      var cmdData;
                      if (
                        this.oledDevices.filter((value) => value.sno == item.sno)
                          .length == 0
                      ) {
                        cmdData = {
                          devId: item.cbid,
                          command: "94",
                          resp: "60,30,25",
                        };
                      } else {
                        cmdData = {
                          devId: item.devId,
                          command: "94",
                          resp: "60,30,25",
                          refby:
                            this.user.role == "office"
                              ? this.user.officeId
                              : this.user.dealerId,
                          role: this.user.role,
                        };
                      }
                      var data = JSON.stringify(cmdData);
                      this.loadingtext = "Please Wait";
                      this.ismemloading = true;
                      Vue.axios
                        .post("https://robopower.xyz/app/commands", data)
                        .then((res) => {
                          if (res.data == "OPERATION DONE") {
                            // this.ismemloading = false;
                            this.loadingtext = "Please Wait";
                            this.text = "command sent successfully";
                            this.alerts = true;

                            //edit device status
                            const fd = this.loadFormDataDeviceStatus(item);
                            this.$store.dispatch("editDevicemode", fd);

                            // logs download
                            if (item.state == "TX") {
                              this.istexasloading = true;
                              var zip = new JSZip();
                              var img = zip.folder("images");
                              var updatecount = 0;
                            }
                            this.loadingdialog = true;
                            this.loadingtext = "Please Wait Data Downloading";
                            Vue.axios
                              .get(
                                "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" +
                                  item.cbid
                              )
                              .then((response) => {
                                if (response.data != null) {
                                  var remData = response.data;
                                  // .filter((r)=>{
                                  //   return moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") >= moment(item.installDate).format("MM/DD/YYYY HH:mm:ss") && moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") < moment(new Date()).add(1, 'days').format("MM/DD/YYYY HH:mm:ss")
                                  // })

                                  this.eventscount = response.data.filter(
                                    (res) => {
                                      return res.typeofevent == "254";
                                    }
                                  );

                                  var wholedata = response.data;
                                  this.totaleventscount = wholedata.length;
                                  wholedata = wholedata.sort(
                                    (a, b) =>
                                      moment(a.rtime).valueOf() -
                                      moment(b.rtime).valueOf()
                                  );
                                  if (this.user.role == "superadmin") {
                                    remData = remData.filter(
                                      (o) => o.typeofevent !== "0"
                                    );
                                  } else {
                                    remData = remData.filter(
                                      (o) =>
                                        o.typeofevent !== "0" &&
                                        o.typeofevent !== "151" &&
                                        o.typeofevent !== "152" &&
                                        o.typeofevent !== "153" &&
                                        o.typeofevent !== "154" &&
                                        o.typeofevent !== "155" &&
                                        o.typeofevent !== "156" &&
                                        o.typeofevent !== "157" &&
                                        o.typeofevent !== "218" &&
                                        o.typeofevent !== "219"
                                    );
                                  }
                                  var sorteddata = wholedata.reverse();
                                  var arrData = sorteddata;
                                  arrData.push(remData);
                                  var today = new Date();
                                  var row_width = 5;
                                  var object;
                                  //CSV data
                                  this.texasdisplay =
                                    "Preparing Logs please wait.....";
                                  object =
                                    "Alcobrake" +
                                    new Array(row_width).join(" ") +
                                    "Log Summary Report \r\n\r\nDevice Id:" +
                                    item.cbid +
                                    // +"\r\n \r\n";
                                    new Array(row_width).join(" ") +
                                    "Device Installed On :" +
                                    item.installDate +
                                    "\r\n \r\n";
                                  object +=
                                    "LogRead Date " +
                                    new Array(row_width).join(" ") +
                                    today +
                                    "\r\n \r\n";
                                  object +=
                                    "Record id" +
                                    new Array(row_width + 5).join(" ") +
                                    ";" +
                                    "Date" +
                                    new Array(row_width + 10).join(" ") +
                                    ";" +
                                    "Week" +
                                    new Array(row_width + 15).join(" ") +
                                    ";" +
                                    "EventType" +
                                    new Array(row_width + 9).join(" ") +
                                    ";" +
                                    "brac" +
                                    new Array(row_width + 8).join(" ") +
                                    ";" +
                                    "Result \r\n";
                                  if (remData != null) {
                                    var rcid = 1;
                                    var neProm = new Promise(
                                      (resolve, reject) => {
                                        if (remData.length > 0) {
                                          remData.map((element) => {
                                            if (element.typeofevent != "101") {
                                              if (element.typeofevent != "29") {
                                                var dt = new Date(
                                                  element.rtime
                                                );
                                                var week = [
                                                  "Sunday",
                                                  "Monday",
                                                  "Tuesday",
                                                  "Wednesday",
                                                  "Thursday",
                                                  "Friday",
                                                  "Saturday",
                                                ];
                                                var weekday = week[dt.getDay()];
                                                var eventType = " ";
                                                var Result = " ";
                                                var Bacvalue = " ";
                                                //AlcoholTest
                                                if (
                                                  element.typeofevent == 254
                                                ) {
                                                  //initialtest
                                                  if (
                                                    element.randominitial ==
                                                      2 ||
                                                    element.randominitial == 0
                                                  ) {
                                                    eventType = "INITIAL TEST";
                                                  } else if (
                                                    element.randominitial ==
                                                      3 ||
                                                    element.randominitial == 1
                                                  ) {
                                                    eventType = "RANDOM TEST";
                                                  } else {
                                                    eventType = "NA";
                                                  }
                                                  if (
                                                    element.passfailstatus == 1
                                                  ) {
                                                    Result = "PASS";
                                                  } else if (
                                                    element.passfailstatus == 0
                                                  ) {
                                                    Result = "FAIL";
                                                  } else {
                                                    Result = " ";
                                                  }
                                                } else if (
                                                  element.typeofevent == 15
                                                ) {
                                                  if (
                                                    element.enginerunstate == 0
                                                  ) {
                                                    eventType = "ENGINE OFF";
                                                  } else if (
                                                    element.enginerunstate == 1
                                                  ) {
                                                    eventType = "ENGINE ON";
                                                  } else {
                                                    eventType = "NA";
                                                  }
                                                } else if (
                                                  element.typeofevent == 65
                                                ) {
                                                  eventType =
                                                    "INSUFFICIENTVOLUME";
                                                } else if (
                                                  element.typeofevent == 66
                                                ) {
                                                  eventType = "HARD_BLOW";
                                                } else if (
                                                  element.typeofevent == 65
                                                ) {
                                                  eventType =
                                                    "INSUFFICIENTVOLUME";
                                                } else if (
                                                  element.typeofevent == 21
                                                ) {
                                                  eventType =
                                                    "CAR_BATTERY_TAMPERING";
                                                } else if (
                                                  element.typeofevent == 19
                                                ) {
                                                  eventType =
                                                    "VIOLATION_LOCKOUT";
                                                } else if (
                                                  element.typeofevent == 4
                                                ) {
                                                  eventType = "BYPASS";
                                                } else if (
                                                  element.typeofevent == 18
                                                ) {
                                                  eventType =
                                                    "TEST_REFUSED_RR_TEST";
                                                } else if (
                                                  element.typeofevent == 28
                                                ) {
                                                  eventType = "TAMPERED";
                                                } else if (
                                                  element.typeofevent == 22
                                                ) {
                                                  eventType =
                                                    "CALIBRATION DONE";
                                                } else if (
                                                  element.typeofevent == 29
                                                ) {
                                                  eventType =
                                                    "START_TEST_ATTEMPT";
                                                } else if (
                                                  element.typeofevent == 34
                                                ) {
                                                  eventType =
                                                    "ENGINE_NOT_STARTED";
                                                } else if (
                                                  element.typeofevent == 27
                                                ) {
                                                  eventType = "COOL_SAMPLE";
                                                } else if (
                                                  element.typeofevent == 25
                                                ) {
                                                  eventType = "HOT_SAMPLE";
                                                } else if (
                                                  element.typeofevent == 8
                                                ) {
                                                  eventType =
                                                    "ONE_TIME_PASS_CODE";
                                                } else if (
                                                  element.typeofevent == 6
                                                ) {
                                                  eventType =
                                                    "SERVICE_PERIOD_SET";
                                                } else if (
                                                  element.typeofevent == 7
                                                ) {
                                                  eventType = "VOILATION_RESET";
                                                } else if (
                                                  element.typeofevent == 11
                                                ) {
                                                  eventType = "LOG_READ";
                                                } else if (
                                                  element.typeofevent == 220
                                                ) {
                                                  eventType = "LOCKOUT 0";
                                                } else if (
                                                  element.typeofevent == 221
                                                ) {
                                                  eventType = "LOCKOUT 1";
                                                } else if (
                                                  element.typeofevent == 222
                                                ) {
                                                  eventType = "LOCKOUT 2";
                                                } else if (
                                                  element.typeofevent == 223
                                                ) {
                                                  eventType = "LOCKOUT 3";
                                                } else if (
                                                  element.typeofevent == 224
                                                ) {
                                                  eventType = "LOCKOUT 4";
                                                } else if (
                                                  element.typeofevent == 225
                                                ) {
                                                  eventType = "LOCKOUT 5";
                                                } else if (
                                                  element.typeofevent == 226
                                                ) {
                                                  eventType = "LOCKOUT 6";
                                                } else if (
                                                  element.typeofevent == 227
                                                ) {
                                                  eventType = "LOCKOUT 7";
                                                } else if (
                                                  element.typeofevent == 56
                                                ) {
                                                  eventType = "TAKE_A_PICTURE";
                                                } else if (
                                                  element.typeofevent == 101
                                                ) {
                                                  eventType =
                                                    "PICTURE_CAPTURED";
                                                } else if (
                                                  element.typeofevent == 60
                                                ) {
                                                  eventType = "BYPASS_START";
                                                } else if (
                                                  element.typeofevent == 61
                                                ) {
                                                  eventType = "BYPASS_STOP";
                                                } else if (
                                                  element.typeofevent == 14
                                                ) {
                                                  eventType = "SERVICE_ALERT";
                                                } else if (
                                                  element.typeofevent == 34
                                                ) {
                                                  eventType =
                                                    "ENGINE_NOT_STARTED";
                                                } else if (
                                                  element.typeofevent == 62
                                                ) {
                                                  eventType =
                                                    "SAMPLER_DISCONNECTED";
                                                } else if (
                                                  element.typeofevent == 10
                                                ) {
                                                  eventType = "CAR_BATTERY_OFF";
                                                } else if (
                                                  element.typeofevent == 20
                                                ) {
                                                  eventType = "CAR_BATTERY_ON";
                                                } else if (
                                                  element.typeofevent == 217
                                                ) {
                                                  eventType = "MEMSET";
                                                } else if (
                                                  element.typeofevent == 219
                                                ) {
                                                  eventType = "TIME_SET";
                                                } else if (
                                                  element.typeofevent == 218
                                                ) {
                                                  eventType = "DEVICE RESTART";
                                                }
                                                if (element.bacvalue != 255) {
                                                  Bacvalue = element.bacvalue;
                                                }

                                                var rcidlength,
                                                  rtimelength,
                                                  braclength;

                                                if (
                                                  remData
                                                    .indexOf(element)
                                                    .toString().length == 1
                                                ) {
                                                  rcidlength = row_width + 10;
                                                } else if (
                                                  remData
                                                    .indexOf(element)
                                                    .toString().length == 2
                                                ) {
                                                  rcidlength = row_width + 9;
                                                } else if (
                                                  remData
                                                    .indexOf(element)
                                                    .toString().length == 3
                                                ) {
                                                  rcidlength = row_width + 8;
                                                } else if (
                                                  remData
                                                    .indexOf(element)
                                                    .toString().length == 4
                                                ) {
                                                  rcidlength = row_width + 7;
                                                }
                                                if (weekday) {
                                                  if (weekday.length == 6) {
                                                    rtimelength =
                                                      row_width + 12;
                                                  } else if (
                                                    weekday.length == 7
                                                  ) {
                                                    rtimelength =
                                                      row_width + 11;
                                                  } else if (
                                                    weekday.length == 8
                                                  ) {
                                                    rtimelength =
                                                      row_width + 10;
                                                  } else if (
                                                    weekday.length == 9
                                                  ) {
                                                    rtimelength = row_width + 9;
                                                  } else if (
                                                    weekday.length == 10
                                                  ) {
                                                    rtimelength = row_width + 8;
                                                  } else if (
                                                    weekday.length == 11
                                                  ) {
                                                    rtimelength = row_width + 7;
                                                  }
                                                }
                                                if (eventType.length == 9) {
                                                  braclength = row_width + 12;
                                                } else if (
                                                  eventType.length == 10
                                                ) {
                                                  braclength = row_width + 11;
                                                } else if (
                                                  eventType.length == 11
                                                ) {
                                                  braclength = row_width + 10;
                                                } else if (
                                                  eventType.length == 12
                                                ) {
                                                  braclength = row_width + 9;
                                                } else if (
                                                  eventType.length == 13
                                                ) {
                                                  braclength = row_width + 8;
                                                } else if (
                                                  eventType.length == 14
                                                ) {
                                                  braclength = row_width + 7;
                                                } else if (
                                                  eventType.length == 15
                                                ) {
                                                  braclength = row_width + 6;
                                                } else if (
                                                  eventType.length == 16
                                                ) {
                                                  braclength = row_width + 5;
                                                } else if (
                                                  eventType.length == 17
                                                ) {
                                                  braclength = row_width + 4;
                                                } else if (
                                                  eventType.length == 18
                                                ) {
                                                  braclength = row_width + 3;
                                                } else if (
                                                  eventType.length == 19
                                                ) {
                                                  braclength = row_width + 2;
                                                } else if (
                                                  eventType.length == 20
                                                ) {
                                                  braclength = row_width + 1;
                                                } else if (
                                                  eventType.length == 21
                                                ) {
                                                  braclength = row_width;
                                                }
                                                object +=
                                                  rcid +
                                                  new Array(rcidlength).join(
                                                    " "
                                                  ) +
                                                  ";" +
                                                  moment(element.rtime).format(
                                                    "MM/DD/YYYY hh:mm:ss"
                                                  ) +
                                                  new Array(row_width).join(
                                                    " "
                                                  ) +
                                                  ";" +
                                                  weekday +
                                                  new Array(rtimelength).join(
                                                    " "
                                                  ) +
                                                  ";" +
                                                  eventType +
                                                  new Array(braclength).join(
                                                    " "
                                                  ) +
                                                  ";" +
                                                  Bacvalue +
                                                  new Array(
                                                    row_width + 10
                                                  ).join(" ") +
                                                  ";" +
                                                  Result +
                                                  "\r\n";
                                                rcid++;
                                              }
                                            }
                                          });
                                          resolve("success");
                                        } else {
                                          this.dialog10 = false;
                                          this.loadingdialog = false;
                                          reject("No Logs Found");
                                          const Swal = require("sweetalert2");
                                          Swal.fire({
                                            title:
                                              "No Logs Found From Device Install Date",
                                            icon: "error",
                                            showCloseButton: true,
                                            confirmButtonText: "Ok",
                                          });
                                        }
                                      }
                                    ); //end promise
                                    neProm.then(async (res) => {
                                      if (res == "success") {
                                        this.texasdisplay =
                                          "Downloading Images Please wait.....";
                                        if (item.state == "TX") {
                                          await Promise.all(
                                            this.eventscount.map((element) => {
                                              if (
                                                element.randominitial == 2 ||
                                                element.randominitial == 0
                                              ) {
                                                this.istexasloading = true;
                                                var itemname5 = element.rtime;
                                                var stname5 =
                                                  itemname5.replaceAll(
                                                    "/",
                                                    "_"
                                                  );
                                                var ssname5 =
                                                  stname5.replaceAll(" ", "_");
                                                var smname5 =
                                                  ssname5.replaceAll(":", "_");
                                                Vue.axios
                                                  .get(
                                                    "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                                      element.controlId +
                                                      "&Image=" +
                                                      itemname5
                                                  )
                                                  .then((res) => {
                                                    if (res.data) {
                                                      img.file(
                                                        smname5 + ".png",
                                                        res.data,
                                                        { base64: true }
                                                      );
                                                      this.imagecount++;
                                                      updatecount++;
                                                    } else {
                                                      
                                                          this.$store
                                                            .dispatch(
                                                              "alert/error",
                                                              "No Image Found"
                                                            )
                                                            .then(() => {
                                                              this.loadingdialog = false;
                                                              setTimeout(() => {
                                                                this.$store.dispatch(
                                                                  "alert/clear"
                                                                );
                                                              }, 2000);
                                                            });
                                                    }
                                                  })
                                                  .catch(function (error) {
                                                    updatecount++;
                                                    this.$store
                                                      .dispatch(
                                                        "alert/error",
                                                        error
                                                      )
                                                      .then(() => {
                                                        setTimeout(() => {
                                                          this.$store.dispatch(
                                                            "alert/clear"
                                                          );
                                                        }, 2000);
                                                      });
                                                  });
                                              } else if (
                                                element.randominitial == 3 ||
                                                element.randominitial == 1
                                              ) {
                                                // console.log(element);
                                                this.istexasloading = true;
                                                var itemname6 = element.rtime;
                                                var stname6 =
                                                  itemname6.replaceAll(
                                                    "/",
                                                    "_"
                                                  );
                                                var ssname6 =
                                                  stname6.replaceAll(" ", "_");
                                                var smname6 =
                                                  ssname6.replaceAll(":", "_");
                                                // console.log(smname);
                                                Vue.axios
                                                  .get(
                                                    "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                                      element.controlId +
                                                      "&Image=" +
                                                      itemname6
                                                  )
                                                  .then((res) => {
                                                    if (res.data) {
                                                      img.file(
                                                        smname6 + ".png",
                                                        res.data,
                                                        { base64: true }
                                                      );
                                                      this.imagecount++;
                                                      updatecount++;
                                                    } else {
                                                      
                                                          this.$store
                                                            .dispatch(
                                                              "alert/error",
                                                              "No Image Found"
                                                            )
                                                            .then(() => {
                                                              setTimeout(() => {
                                                                this.$store.dispatch(
                                                                  "alert/clear"
                                                                );
                                                              }, 2000);
                                                            });
                                                    }
                                                  })
                                                  .catch(function (error) {
                                                    updatecount++;
                                                    this.$store
                                                      .dispatch(
                                                        "alert/error",
                                                        error
                                                      )
                                                      .then(() => {
                                                        setTimeout(() => {
                                                          this.$store.dispatch(
                                                            "alert/clear"
                                                          );
                                                        }, 2000);
                                                      });
                                                  });
                                              } else {
                                                this.loadingdialog = false;
                                              }
                                              // }
                                            })
                                          );
                                          var checkimagesInterval = setInterval(
                                            () => {
                                              var completedper =
                                                (updatecount /
                                                  this.eventscount.length) *
                                                100;
                                              this.texasdisplay =
                                                "Zipping files..... " +
                                                parseInt(completedper) +
                                                " %";
                                              if (
                                                updatecount >=
                                                this.eventscount.length
                                              ) {
                                                this.confirmtexasloading = true;
                                                clearInterval(
                                                  checkimagesInterval
                                                );
                                                this.istexasloading = false;
                                                setTimeout(() => {
                                                  this.confirmtexasloading = false;
                                                  zip.file(
                                                    item.client + ".csv",
                                                    object
                                                  );
                                                  zip
                                                    .generateAsync({
                                                      type: "blob",
                                                    })
                                                    .then(function (content) {
                                                      //make default
                                                      saveAs(
                                                        content,
                                                        item.client + ".zip"
                                                      );
                                                    })
                                                    .catch((err) => {
                                                      this.$store
                                                        .dispatch(
                                                          "alert/error",
                                                          err
                                                        )
                                                        .then(() => {
                                                          setTimeout(() => {
                                                            this.$store.dispatch(
                                                              "alert/clear"
                                                            );
                                                          }, 2000);
                                                        });
                                                    });
                                                  this.loadingdialog = false;
                                                  this.imagecount = 0;
                                                  this.eventscount = [];
                                                  updatecount = 0;
                                                  (this.texasdisplay =
                                                    "Downloading data please wait....."),
                                                    (this.dialog10 = false);
                                                }, 5000);
                                              }
                                            },
                                            2000
                                          );
                                        } else {
                                          const link =
                                            document.createElement("a");
                                          link.setAttribute(
                                            "href",
                                            "data:text/csv;charset=utf-8," +
                                              encodeURIComponent(object)
                                          );
                                          link.setAttribute(
                                            "download",
                                            remData[0].controlId + ".csv"
                                          );
                                          link.click();
                                          this.loadingdialog = false;
                                          this.dialog10 = false;
                                        }
                                      }
                                    });
                                  } else {
                                    this.istexasloading = false;
                                    this.loadingdialog = false;
                                    this.$store
                                      .dispatch("alert/error", "Log file empty")
                                      .then(() => {
                                        setTimeout(() => {
                                          this.$store.dispatch("alert/clear");
                                        }, 2000);
                                      });
                                  }

                                  // Clear Storage ( database)
                                  setTimeout(() => {
                                    this.ismemloading = false;
                                    this.loadingtext = "Please Wait";
                                    this.clearstorage(item, firmware, 0);
                                  }, 100);
                                } else {
                                  this.istexasloading = false;
                                  this.loadingdialog = false;
                                  this.$store
                                    .dispatch("alert/error", "Log file empty")
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                }
                              });
                          } else {
                            this.ismemloading = false;
                            this.$store
                              .dispatch("alert/error", "Your Request Cancelled")
                              .then(() => {
                                setTimeout(() => {
                                  this.$store.dispatch("alert/clear");
                                }, 2000);
                              });
                          }
                        });
                    } else {
                      this.ismemloading = false;
                      this.$store
                        .dispatch("alert/error", "OPERATION NOT DONE")
                        .then(() => {
                          setTimeout(() => {
                            this.$store.dispatch("alert/error");
                          }, 2000);
                        });
                    }
                  })
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      this.ismemloading = false;
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              } else {
                this.ismemloading = false;
                this.$store
                  .dispatch("alert/error", "Your Request Cancelled")
                  .then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/error");
                    }, 2000);
                  });
              }
            });
        }
      });
    },

    deviceinfoCommand(item, command, firmware) {
      var mainid;
      var firmwareversion = parseFloat(firmware);
      if (firmwareversion > 0) {
        mainid = firmwareversion > 34 ? item.cbid : item.devId;
      } else {
        mainid = item.cbid;
      }
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var count = 0;
            var data = JSON.stringify(cmdData);
            this.loadingtext = "Please Wait";
            this.ismemloading = true;
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  // alert(command + "command sent successfully");
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  //            this.$store.dispatch("alert/error", "OPERATION DONE").then(()=>{
                  // setTimeout(()=>{
                  //   this.$store.dispatch("alert/clear")
                  // },2000)
                  // })
                  var myInt = setInterval(() => {
                    count++;
                    Vue.axios
                      .all([
                        Vue.axios.get(
                          "https://robopower.xyz/v2/ping/wifiresponse/" + mainid
                        ),
                        Vue.axios.get(
                          "https://robopower.xyz/v2/ping/fgresponse/" + mainid
                        ),
                      ])
                      .then(
                        Vue.axios.spread((data1, data2) => {
                          if (data1.data != null) {
                            var data2 =
                              data1.data[data1.data.length - 1].response.split(
                                ","
                              );
                            if (
                              data2[1] != "undefined" ||
                              data2[8] != "undefined"
                            ) {
                              if (parseInt(data2[6]) > 2100) {
                                this.ismemloading = false;
                                const Swal = require("sweetalert2");
                                Swal.fire(
                                  "Device Info",
                                  "deviceId:" +
                                    item.devId +
                                    "\n" +
                                    "S-firmware:" +
                                    data2[1] +
                                    "\n" +
                                    "CB-firmware:" +
                                    data2[5] +
                                    "\n" +
                                    "device time:" +
                                    data2[2] +
                                    "\n" +
                                    "present calibration time:" +
                                    data2[3] +
                                    "\n" +
                                    "next calibration time:" +
                                    data2[4] +
                                    "\n" +
                                    // "service cycle days:" +
                                    // data2[5] +
                                    // "\n" +
                                    data2[7] +
                                    "\n" +
                                    data2[8] +
                                    "\n" +
                                    "Voltage:" +
                                    (
                                      ((parseInt(data2[6]) + 145) / 1000) *
                                      6
                                    ).toFixed(2)
                                );
                              } else {
                                if (data2.length == 10) {
                                  this.ismemloading = false;
                                  var cbids =
                                    data2[7].substring(0, 3) == "ABC"
                                      ? data2[7]
                                      : "null";
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      item.devId +
                                      "\n" +
                                      "controlId:" +
                                      cbids +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      // "service cycle days:" +
                                      // data2[5] +
                                      // "\n" +
                                      data2[8] +
                                      "\n" +
                                      data2[9] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                } else if (data2.length == 8) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      item.devId +
                                      "\n" +
                                      "controlId:" +
                                      data2[7] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                } else if (data2.length == 16) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "HandSet:" +
                                      data2[0] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "DeviceId:" +
                                      data2[7] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "BracPeakFactor:" +
                                      data2[13] +
                                      "\n" +
                                      "Altitude:" +
                                      data2[12] +
                                      "\n" +
                                      "OffsetPressure:" +
                                      data2[8] +
                                      "\n" +
                                      "AlcoADC:" +
                                      data2[9] +
                                      "\n" +
                                      "AmbTemp:" +
                                      data2[10] +
                                      "\n" +
                                      "BreathTemp:" +
                                      data2[11] +
                                      "\n" +
                                      "CalFlag:" +
                                      data2[14] +
                                      "\n" +
                                      "S3-Version:" +
                                      data2[15] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                  setTimeout(() => {
                                    clearInterval(myInt);
                                    this.fetchAll(item);
                                    this.deleteResponce(item, firmware);
                                  }, 200);
                                } else if (data2.length == 15) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "HandSet:" +
                                      data2[0] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "DeviceId:" +
                                      data2[7] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "BracPeakFactor:" +
                                      data2[13] +
                                      "\n" +
                                      "Altitude:" +
                                      data2[12] +
                                      "\n" +
                                      "OffsetPressure:" +
                                      data2[8] +
                                      "\n" +
                                      "AlcoADC:" +
                                      data2[9] +
                                      "\n" +
                                      "AmbTemp:" +
                                      data2[10] +
                                      "\n" +
                                      "BreathTemp:" +
                                      data2[11] +
                                      "\n" +
                                      "CalFlag:" +
                                      data2[14] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                  setTimeout(() => {
                                    clearInterval(myInt);
                                    this.fetchAll(item);
                                    this.ismemloading = false;
                                    this.deleteResponce(item, firmware);
                                  }, 200);
                                } else {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      item.devId +
                                      "\n" +
                                      "controlId:" +
                                      "null" +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      // "service cycle days:" +
                                      // data2[5] +
                                      // "\n" +
                                      data2[8] +
                                      "\n" +
                                      data2[7] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        ((parseInt(data2[6]) + 100) / 1000) *
                                        6
                                      ).toFixed(2)
                                  );
                                }
                              }
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.ismemloading = false;
                                this.deleteResponce(item, firmware);
                              }, 500);
                            }
                          } else if (data2.data != null) {
                            var data2 =
                              data2.data[data2.data.length - 1].response.split(
                                ","
                              );
                            if (
                              data2[1] != "undefined" ||
                              data2[8] != "undefined"
                            ) {
                              if (parseInt(data2[6]) > 2100) {
                                this.ismemloading = false;
                                const Swal = require("sweetalert2");
                                Swal.fire(
                                  "Device Info",
                                  "deviceId:" +
                                    item.devId +
                                    "\n" +
                                    "controlId:" +
                                    data2[0] +
                                    "\n" +
                                    "S-firmware:" +
                                    data2[1] +
                                    "\n" +
                                    "CB-firmware:" +
                                    data2[8] +
                                    "\n" +
                                    "device time:" +
                                    data2[2] +
                                    "\n" +
                                    "present calibration time:" +
                                    data2[3] +
                                    "\n" +
                                    "next calibration time:" +
                                    data2[4] +
                                    "\n" +
                                    "service cycle days:" +
                                    data2[5] +
                                    "\n" +
                                    "Offset Pressure:" +
                                    data2[7] +
                                    "\n" +
                                    "Brac Factor:" +
                                    data2[6] +
                                    "\n" +
                                    "Voltage:" +
                                    (
                                      ((parseInt(data2[9]) + 145) / 1000) *
                                      6
                                    ).toFixed(2)
                                );
                              } else {
                                if (data2.length == 10) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  var cbids =
                                    data2[0].substring(0, 3) == "ABC"
                                      ? data2[0]
                                      : "null";
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      item.devId +
                                      "\n" +
                                      "controlId:" +
                                      cbids +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[8] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "service cycle days:" +
                                      data2[5] +
                                      "\n" +
                                      "Offset Pressure:" +
                                      data2[7] +
                                      "\n" +
                                      "Brac Factor:" +
                                      data2[6] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[9]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                } else if (data2.length == 16) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      data2[0] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CBID:" +
                                      data2[7] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "BracPeakFactor:" +
                                      data2[13] +
                                      "\n" +
                                      "Altitude:" +
                                      data2[12] +
                                      "\n" +
                                      "OffsetPressure:" +
                                      data2[8] +
                                      "\n" +
                                      "AlcoADC:" +
                                      data2[9] +
                                      "\n" +
                                      "AmbTemp:" +
                                      data2[10] +
                                      "\n" +
                                      "BreathTemp:" +
                                      data2[11] +
                                      "\n" +
                                      "CalFlag:" +
                                      data2[14] +
                                      "\n" +
                                      "S3-Version:" +
                                      data2[15] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                  setTimeout(() => {
                                    clearInterval(myInt);
                                    this.fetchAll(item);
                                    this.ismemloading = false;
                                    this.deleteResponce(item, firmware);
                                  }, 200);
                                } else if (data2.length == 15) {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      data2[0] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CBID:" +
                                      data2[7] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      "BracPeakFactor:" +
                                      data2[13] +
                                      "\n" +
                                      "Altitude:" +
                                      data2[12] +
                                      "\n" +
                                      "OffsetPressure:" +
                                      data2[8] +
                                      "\n" +
                                      "AlcoADC:" +
                                      data2[9] +
                                      "\n" +
                                      "AmbTemp:" +
                                      data2[10] +
                                      "\n" +
                                      "BreathTemp:" +
                                      data2[11] +
                                      "\n" +
                                      "CalFlag:" +
                                      data2[14] +
                                      "\n" +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                  setTimeout(() => {
                                    clearInterval(myInt);
                                    this.fetchAll(item);
                                    this.ismemloading = false;
                                    this.deleteResponce(item, firmware);
                                  }, 200);
                                } else {
                                  this.ismemloading = false;
                                  const Swal = require("sweetalert2");
                                  // var cbids = data2[0].substring(0, 3) == 'ABC'?data2[0]:'null'
                                  Swal.fire(
                                    "Device Info",
                                    "deviceId:" +
                                      item.devId +
                                      "\n" +
                                      "controlId:" +
                                      data2[7] +
                                      "\n" +
                                      "S-firmware:" +
                                      data2[1] +
                                      "\n" +
                                      "CB-firmware:" +
                                      data2[5] +
                                      "\n" +
                                      "device time:" +
                                      data2[2] +
                                      "\n" +
                                      "present calibration time:" +
                                      data2[3] +
                                      "\n" +
                                      "next calibration time:" +
                                      data2[4] +
                                      "\n" +
                                      // "service cycle days:" +
                                      // data2[5] +
                                      // "\n" +
                                      // "Offset Pressure:" +
                                      // data2[7] +
                                      // "\n" +
                                      // "Brac Factor:" +
                                      // data2[6] +
                                      // "\n"
                                      // +
                                      "Voltage:" +
                                      (
                                        (parseInt(data2[6]) / 1000 / 1.5 + 3) *
                                          3 +
                                        0.1
                                      ).toFixed(2)
                                  );
                                }
                              }
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.ismemloading = false;
                                this.deleteResponce(item, firmware);
                              }, 500);
                            }
                          } else {
                            if (count >= 15) {
                              const Swal = require("sweetalert2");
                              Swal.fire({
                                title: "Device Not connected",
                                icon: "error",
                                showCloseButton: true,
                              });
                              clearInterval(myInt);
                              this.ismemloading = false;
                              this.deleteResponce(item, firmware);
                            }
                          }
                        })
                      );
                    //  .catch((err)=>{
                    //               this.$store.dispatch("alert/error", err).then(()=>{
                    //     setTimeout(()=>{
                    //       this.$store.dispatch("alert/clear")
                    //     },2000)
                    //     })
                    //             })
                  }, 3000);
                } else {
                  if (firmwareversion > 0) {
                    this.ismemloading = false;
                    const Swal = require("sweetalert2");
                    Swal.fire({
                      title: "Device Not connected",
                      icon: "error",
                      showCloseButton: true,
                    });
                    this.$store
                      .dispatch("alert/error", "OPERATION NOT DONE")
                      .then(() => {
                        setTimeout(() => {
                          this.$store.dispatch("alert/clear");
                        }, 2000);
                      });
                  } else {
                    this.deviceinfoCommand2(item, "80", firmware);
                  }
                }
              });

            //            this.$store.dispatch("alert/error", "Action Performed").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          } else {
            // this.$swal("Cancelled", "Your Request Cancelled", "info");
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    deviceinfoCommand2(item, command, firmware) {
      var mainid;
      mainid = item.devId;
      if (this.user.emailId != "Justin@alcobrake.com") {
        var cmdData = {
          devId: mainid,
          command: command,
          resp: "",
          refby:
            this.user.role == "office"
              ? this.user.officeId
              : this.user.dealerId,
          role: this.user.role,
        };
        var count = 0;
        var data = JSON.stringify(cmdData);
        this.loadingtext = "Please Wait";
        this.ismemloading = true;
        Vue.axios
          .post("https://robopower.xyz/app/commands", data)
          .then((res) => {
            if (res.data == "OPERATION DONE") {
              // alert(command + "command sent successfully");
              this.text = command + " " + "command sent successfully";
              this.alerts = true;
              //            this.$store.dispatch("alert/error", "OPERATION DONE").then(()=>{
              // setTimeout(()=>{
              //   this.$store.dispatch("alert/clear")
              // },2000)
              // })
              var myInt = setInterval(() => {
                count++;
                Vue.axios
                  .all([
                    Vue.axios.get(
                      "https://robopower.xyz/v2/ping/wifiresponse/" + mainid
                    ),
                    Vue.axios.get(
                      "https://robopower.xyz/v2/ping/fgresponse/" + mainid
                    ),
                  ])
                  .then(
                    Vue.axios.spread((data1, data2) => {
                      if (data1.data != null) {
                        var data2 =
                          data1.data[data1.data.length - 1].response.split(",");
                        if (
                          data2[1] != "undefined" ||
                          data2[8] != "undefined"
                        ) {
                          if (parseInt(data2[6]) > 2100) {
                            this.ismemloading = false;
                            const Swal = require("sweetalert2");
                            Swal.fire(
                              "Device Info",
                              "deviceId:" +
                                item.devId +
                                "\n" +
                                "S-firmware:" +
                                data2[1] +
                                "\n" +
                                "CB-firmware:" +
                                data2[5] +
                                "\n" +
                                "device time:" +
                                data2[2] +
                                "\n" +
                                "present calibration time:" +
                                data2[3] +
                                "\n" +
                                "next calibration time:" +
                                data2[4] +
                                "\n" +
                                // "service cycle days:" +
                                // data2[5] +
                                // "\n" +
                                data2[7] +
                                "\n" +
                                data2[8] +
                                "\n" +
                                "Voltage:" +
                                (
                                  ((parseInt(data2[6]) + 145) / 1000) *
                                  6
                                ).toFixed(2)
                            );
                          } else {
                            if (data2.length == 10) {
                              this.ismemloading = false;
                              var cbids =
                                data2[7].substring(0, 3) == "ABC"
                                  ? data2[7]
                                  : "null";
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  item.devId +
                                  "\n" +
                                  "controlId:" +
                                  cbids +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  // "service cycle days:" +
                                  // data2[5] +
                                  // "\n" +
                                  data2[8] +
                                  "\n" +
                                  data2[9] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                            } else if (data2.length == 8) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  item.devId +
                                  "\n" +
                                  "controlId:" +
                                  data2[7] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                            } else if (data2.length == 16) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "HandSet:" +
                                  data2[0] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "DeviceId:" +
                                  data2[7] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "BracPeakFactor:" +
                                  data2[13] +
                                  "\n" +
                                  "Altitude:" +
                                  data2[12] +
                                  "\n" +
                                  "OffsetPressure:" +
                                  data2[8] +
                                  "\n" +
                                  "AlcoADC:" +
                                  data2[9] +
                                  "\n" +
                                  "AmbTemp:" +
                                  data2[10] +
                                  "\n" +
                                  "BreathTemp:" +
                                  data2[11] +
                                  "\n" +
                                  "CalFlag:" +
                                  data2[14] +
                                  "\n" +
                                  "S3-Version:" +
                                  data2[15] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.deleteResponce(item, firmware);
                              }, 200);
                            } else if (data2.length == 15) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "HandSet:" +
                                  data2[0] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "DeviceId:" +
                                  data2[7] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "BracPeakFactor:" +
                                  data2[13] +
                                  "\n" +
                                  "Altitude:" +
                                  data2[12] +
                                  "\n" +
                                  "OffsetPressure:" +
                                  data2[8] +
                                  "\n" +
                                  "AlcoADC:" +
                                  data2[9] +
                                  "\n" +
                                  "AmbTemp:" +
                                  data2[10] +
                                  "\n" +
                                  "BreathTemp:" +
                                  data2[11] +
                                  "\n" +
                                  "CalFlag:" +
                                  data2[14] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.ismemloading = false;
                                this.deleteResponce(item, firmware);
                              }, 200);
                            } else {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  item.devId +
                                  "\n" +
                                  "controlId:" +
                                  "null" +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  // "service cycle days:" +
                                  // data2[5] +
                                  // "\n" +
                                  data2[8] +
                                  "\n" +
                                  data2[7] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    ((parseInt(data2[6]) + 100) / 1000) *
                                    6
                                  ).toFixed(2)
                              );
                            }
                          }
                          setTimeout(() => {
                            clearInterval(myInt);
                            this.fetchAll(item);
                            this.ismemloading = false;
                            this.deleteResponce(item, firmware);
                          }, 500);
                        }
                      } else if (data2.data != null) {
                        var data2 =
                          data2.data[data2.data.length - 1].response.split(",");
                        if (
                          data2[1] != "undefined" ||
                          data2[8] != "undefined"
                        ) {
                          if (parseInt(data2[6]) > 2100) {
                            this.ismemloading = false;
                            const Swal = require("sweetalert2");
                            Swal.fire(
                              "Device Info",
                              "deviceId:" +
                                item.devId +
                                "\n" +
                                "controlId:" +
                                data2[0] +
                                "\n" +
                                "S-firmware:" +
                                data2[1] +
                                "\n" +
                                "CB-firmware:" +
                                data2[8] +
                                "\n" +
                                "device time:" +
                                data2[2] +
                                "\n" +
                                "present calibration time:" +
                                data2[3] +
                                "\n" +
                                "next calibration time:" +
                                data2[4] +
                                "\n" +
                                "service cycle days:" +
                                data2[5] +
                                "\n" +
                                "Offset Pressure:" +
                                data2[7] +
                                "\n" +
                                "Brac Factor:" +
                                data2[6] +
                                "\n" +
                                "Voltage:" +
                                (
                                  ((parseInt(data2[9]) + 145) / 1000) *
                                  6
                                ).toFixed(2)
                            );
                          } else {
                            if (data2.length == 10) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              var cbids =
                                data2[0].substring(0, 3) == "ABC"
                                  ? data2[0]
                                  : "null";
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  item.devId +
                                  "\n" +
                                  "controlId:" +
                                  cbids +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[8] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "service cycle days:" +
                                  data2[5] +
                                  "\n" +
                                  "Offset Pressure:" +
                                  data2[7] +
                                  "\n" +
                                  "Brac Factor:" +
                                  data2[6] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[9]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                            } else if (data2.length == 16) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  data2[0] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CBID:" +
                                  data2[7] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "BracPeakFactor:" +
                                  data2[13] +
                                  "\n" +
                                  "Altitude:" +
                                  data2[12] +
                                  "\n" +
                                  "OffsetPressure:" +
                                  data2[8] +
                                  "\n" +
                                  "AlcoADC:" +
                                  data2[9] +
                                  "\n" +
                                  "AmbTemp:" +
                                  data2[10] +
                                  "\n" +
                                  "BreathTemp:" +
                                  data2[11] +
                                  "\n" +
                                  "CalFlag:" +
                                  data2[14] +
                                  "\n" +
                                  "S3-Version:" +
                                  data2[15] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.ismemloading = false;
                                this.deleteResponce(item, firmware);
                              }, 200);
                            } else if (data2.length == 15) {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  data2[0] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CBID:" +
                                  data2[7] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  "BracPeakFactor:" +
                                  data2[13] +
                                  "\n" +
                                  "Altitude:" +
                                  data2[12] +
                                  "\n" +
                                  "OffsetPressure:" +
                                  data2[8] +
                                  "\n" +
                                  "AlcoADC:" +
                                  data2[9] +
                                  "\n" +
                                  "AmbTemp:" +
                                  data2[10] +
                                  "\n" +
                                  "BreathTemp:" +
                                  data2[11] +
                                  "\n" +
                                  "CalFlag:" +
                                  data2[14] +
                                  "\n" +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                              setTimeout(() => {
                                clearInterval(myInt);
                                this.fetchAll(item);
                                this.ismemloading = false;
                                this.deleteResponce(item, firmware);
                              }, 200);
                            } else {
                              this.ismemloading = false;
                              const Swal = require("sweetalert2");
                              // var cbids = data2[0].substring(0, 3) == 'ABC'?data2[0]:'null'
                              Swal.fire(
                                "Device Info",
                                "deviceId:" +
                                  item.devId +
                                  "\n" +
                                  "controlId:" +
                                  data2[7] +
                                  "\n" +
                                  "S-firmware:" +
                                  data2[1] +
                                  "\n" +
                                  "CB-firmware:" +
                                  data2[5] +
                                  "\n" +
                                  "device time:" +
                                  data2[2] +
                                  "\n" +
                                  "present calibration time:" +
                                  data2[3] +
                                  "\n" +
                                  "next calibration time:" +
                                  data2[4] +
                                  "\n" +
                                  // "service cycle days:" +
                                  // data2[5] +
                                  // "\n" +
                                  // "Offset Pressure:" +
                                  // data2[7] +
                                  // "\n" +
                                  // "Brac Factor:" +
                                  // data2[6] +
                                  // "\n"
                                  // +
                                  "Voltage:" +
                                  (
                                    (parseInt(data2[6]) / 1000 / 1.5 + 3) * 3 +
                                    0.1
                                  ).toFixed(2)
                              );
                            }
                          }
                          setTimeout(() => {
                            clearInterval(myInt);
                            this.fetchAll(item);
                            this.ismemloading = false;
                            this.deleteResponce(item, firmware);
                          }, 500);
                        }
                      } else {
                        if (count >= 15) {
                          const Swal = require("sweetalert2");
                          Swal.fire({
                            title: "Device Not connected",
                            icon: "error",
                            showCloseButton: true,
                          });
                          clearInterval(myInt);
                          this.ismemloading = false;
                          this.deleteResponce(item, firmware);
                        }
                      }
                    })
                  );
                //  .catch((err)=>{
                //               this.$store.dispatch("alert/error", err).then(()=>{
                //     setTimeout(()=>{
                //       this.$store.dispatch("alert/clear")
                //     },2000)
                //     })
                //             })
              }, 3000);
            } else {
              this.ismemloading = false;
              const Swal = require("sweetalert2");
              Swal.fire({
                title: "Device Not connected",
                icon: "error",
                showCloseButton: true,
              });
              this.$store
                .dispatch("alert/error", "OPERATION NOT DONE")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          });
      }
    },
    goCommandLock(item, command, firmware) {
      var mainid =
        this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ? item.cbid
          : item.devId;
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "0,30,25",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  // this.ismemloading = false
                  this.$store
                    .dispatch("alert/success", "OPERATION DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/success");
                      }, 2000);
                    });
                } else {
                  // this.ismemloading = false
                  this.$store
                    .dispatch("alert/error", "OPERATION NOT DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/success");
                }, 2000);
              });
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    setBlow(param) {
      if(param == 0){
        this.blowtype='low'
        document.getElementById('low').style.backgroundColor = "teal"; // Change color to a darker shade
      document.getElementById('low').style.color = "#ffffff";
      document.getElementById('medium').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('medium').style.color = "#ffffff";
      document.getElementById('high').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('high').style.color = "#ffffff";
      console.log("Button 1 clicked");
       
        }  else if(param == 1){
          this.blowtype = 'medium'
          document.getElementById('low').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('low').style.color = "#ffffff";
      document.getElementById('medium').style.backgroundColor = "teal"; // Change color to a darker shade
      document.getElementById('medium').style.color = "#ffffff";
      document.getElementById('high').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('high').style.color = "#ffffff";
      console.log("Button 2 clicked");
      } else{
        this.blowtype='high';
        document.getElementById('low').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('low').style.color = "#ffffff";
      document.getElementById('medium').style.backgroundColor = "#6c757d"; // Change color to a darker shade
      document.getElementById('medium').style.color = "#ffffff";
      document.getElementById('high').style.backgroundColor = "teal"; // Change color to a darker shade
      document.getElementById('high').style.color = "#ffffff";
      console.log("Button 3 clicked");
      }
    },
    goCommandconfig(item, command, firmware) {
      console.log("LATEST FIRMWARE")
      console.log(firmware)
      if(firmware >48){
        var mainid =
        this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ? item.cbid
          : item.devId;
        const Swal = require("sweetalert2");
        Swal.fire({
        title: `<div>
                  <span>Are you sure? You want to Perform an action on ${item.regnum} Vehicle & Please select Blow Type</span>
                  <div class="row" style="margin-top: 10px;margin-left:"10px"; justify-content:'center'">
                   <button id="low" style="margin: 5px; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; background-color: #0056b3; color: white;">Low</button>
                    <button id="medium" style="margin: 5px; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; background-color: #6c757d; color: white;">Medium</button>
                    <button id="high" style="margin: 5px; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; background-color: #6c757d; color: white;" >High</button>
                  </div>
                </div>`,
        html: "You can't revert your action",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // Action on confirm button click
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          document.getElementById('low').addEventListener('click', () => {
            this.setBlow(0)
        });
          document.getElementById('medium').addEventListener('click', () => {
             this.setBlow(1)
        });
          document.getElementById('high').addEventListener('click', () => { 
            this.setBlow(2)
        });
        }
      }).then((result) => {
        if (result.isConfirmed) {
          //start dev config with blow hard
          var configval = this.blowtype=="low"?"60,30,25,35,800":this.blowtype=="medium"?"60,30,25,55,1000":"60,30,25,60,1100"
          var cmdData = {
              devId: mainid,
              command: command,
              resp: configval,
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  this.blowtype="low"
                  this.$store
                    .dispatch("alert/success", "OPERATION DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/success");
                      }, 2000);
                    });
                } else {
                  this.blowtype="low"
                  this.$store
                    .dispatch("alert/error", "OPERATION NOT DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/success");
                }, 2000);
              });
              //ended dev config with blow hard
        }
      });

      } else{
       
      var mainid =
        this.oledDevices.filter((value) => value.sno == item.sno).length == 0
          ? item.cbid
          : item.devId;
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var cmdData = {
              devId: mainid,
              command: command,
              resp: "60,30,25",
              refby:
                this.user.role == "office"
                  ? this.user.officeId
                  : this.user.dealerId,
              role: this.user.role,
            };
            var data = JSON.stringify(cmdData);
            Vue.axios
              .post("https://robopower.xyz/app/commands", data)
              .then((res) => {
                if (res.data == "OPERATION DONE") {
                  this.text = command + " " + "command sent successfully";
                  this.alerts = true;
                  // this.ismemloading = false
                  this.$store
                    .dispatch("alert/success", "OPERATION DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/success");
                      }, 2000);
                    });
                } else {
                  // this.ismemloading = false
                  this.$store
                    .dispatch("alert/error", "OPERATION NOT DONE")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              })
              .catch((error) => {
                this.$store.dispatch("alert/error", error).then(() => {
                  // this.ismemloading = false
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              });
            this.$store
              .dispatch("alert/success", "Action Performed")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/success");
                }, 2000);
              });
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    }
    },
    bacclose() {
      this.bacdialog = false;
      this.bacfield = "";
    },
    pingfrequencyclose() {
      this.pingfreqdialog = false;
      this.pinginterval = "";
    },
    thresholdclose() {
      this.thresholddialog = false;
      this.thresholdfield = "";
    },
    autocalclose() {
      this.caldialog = false;
      this.calfield = "";
    },
    setThresholdCommand() {
      var cmdData = {
        devId: this.thresholddevid,
        command: this.thresholdcommand,
        resp: this.thresholdfield,
        refby:
          this.user.role == "office" ? this.user.officeId : this.user.dealerId,
        role: this.user.role,
      };
      var data = JSON.stringify(cmdData);
      Vue.axios
        .post("https://robopower.xyz/app/commands", data)
        .then(() => {
          // alert(command + "command sent successfully");
          this.text = this.thresholdcommand + " " + "command sent successfully";
          this.alerts = true;
          this.thresholddialog = false;
          this.thresholdfield = "";
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    thresholdCmd(item, command) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.thresholddialog = true;
            this.thresholdregnum = item.regnum;
            this.thresholddevid = item.devId;
            this.thresholdcommand = command;
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    setCalCommand() {
      var cmdData = {
        devId: this.caldevid,
        command: this.calcommand,
        resp: this.calfield,
        refby:
          this.user.role == "office" ? this.user.officeId : this.user.dealerId,
        role: this.user.role,
      };
      var data = JSON.stringify(cmdData);
      Vue.axios
        .post("https://robopower.xyz/app/commands", data)
        .then(() => {
          // alert(command + "command sent successfully");
          this.text = this.calcommand + " " + "command sent successfully";
          this.alerts = true;
          this.caldialog = false;
          this.calfield = "";
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    AutoCal(item, command) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.caldialog = true;
            this.calregnum = item.regnum;
            this.caldevid = item.devId;
            this.calcommand = command;
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    setPingfreqCommand() {
      var cmdData = {
        devId: this.pingdevid,
        command: this.pingcommand,
        resp: this.pinginterval,
        refby:
          this.user.role == "office" ? this.user.officeId : this.user.dealerId,
        role: this.user.role,
      };
      var data = JSON.stringify(cmdData);
      Vue.axios
        .post("https://robopower.xyz/app/commands", data)
        .then((res) => {
          if (res.data == "OPERATION DONE") {
            // alert(command + "command sent successfully");
            this.text = this.pingcommand + " " + "command sent successfully";
            this.alerts = true;
            this.pinginterval = "";
            // this.ismemloading = false
            this.$store.dispatch("alert/error", "OPERATION DONE").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
              this.pingfreqdialog = false;
            });
          } else {
            this.ismemloading = false;
            this.pingfreqdialog = false;
            this.pinginterval = "";
            this.$store
              .dispatch("alert/error", "OPERATION NOT DONE")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })

        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            this.ismemloading = false;
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
      this.bacdialog = false;
      this.$store.dispatch("alert/error", "Action Performed").then(() => {
        setTimeout(() => {
          this.$store.dispatch("alert/clear");
        }, 2000);
      });
    },
    setBracCommand() {
      var cmdData = {
        devId: this.bacdevid,
        command: this.baccommand,
        resp: this.bacfield,
        refby:
          this.user.role == "office" ? this.user.officeId : this.user.dealerId,
        role: this.user.role,
      };
      // var data = JSON.stringify(cmdData);
      // Vue.axios
      //   .post("https://robopower.xyz/app/commands", data)
      //   .then((res) => {
      //     if (res.data == "OPERATION DONE") {
      //       this.text = this.baccommand + " " + "command sent successfully";
      //       this.alerts = true;
      //       this.bacfield = "";
      //       this.$store.dispatch("alert/error", "OPERATION DONE").then(() => {
      //         setTimeout(() => {
      //           this.$store.dispatch("alert/clear");
      //         }, 2000);
      //       });
      //     } else {
      //       this.ismemloading = false;
      //       this.bacfield = "";
      //       this.$store
      //         .dispatch("alert/error", "OPERATION NOT DONE")
      //         .then(() => {
      //           setTimeout(() => {
      //             this.$store.dispatch("alert/clear");
      //           }, 2000);
      //         });
      //     }
      //   })

      //   .catch((err) => {
      //     this.$store.dispatch("alert/error", err).then(() => {
      //       this.ismemloading = false;
      //       setTimeout(() => {
      //         this.$store.dispatch("alert/clear");
      //       }, 2000);
      //     });
      //   });
      // this.bacdialog = false;
      // this.$store.dispatch("alert/error", "Action Performed").then(() => {
      //   setTimeout(() => {
      //     this.$store.dispatch("alert/clear");
      //   }, 2000);
      // });
    },
    setBrac(item, command, firmware) {
      if (this.user.emailId != "Justin@alcobrake.com") {
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.bacregnum = item.regnum;
            this.bacdevid =
              this.oledDevices.filter((value) => value.sno == item.sno).length == 0
                ? item.cbid
                : item.devId;
            this.baccommand = command;
            this.bacdialog = true;
          } else {
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    changepingFrequency(item, command) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title:
          "Are you sure? You want to Perform an action on " +
          item.regnum +
          "Vehicle",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.pingregnum = item.regnum;
          this.pingdevid =
            this.oledDevices.filter((value) => value.sno == item.sno).length == 0
              ? item.cbid
              : item.devId;
          this.pingcommand = command;
          this.pingfreqdialog = true;
        } else {
          this.$store
            .dispatch("alert/error", "Your Request Cancelled")
            .then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
        }
      });
    },
    onFinaltimeset(devid, time, command) {
      var cmdData = {
        devId: devid,
        command: command,
        resp: time,
        refby:
          this.user.role == "office" ? this.user.officeId : this.user.dealerId,
        role: this.user.role,
      };

      var data = JSON.stringify(cmdData);
      Vue.axios
        .post("https://robopower.xyz/app/commands", data)
        .then((res) => {
          //    const formData = new FormData();
          // formData.append("cbid", devid);
          // formData.append("timezone",time );
          //   this.$store.dispatch('edittimezone',formData);
          this.cancelcustomTime();
          this.customtimestamp = null;
          if (res.data == "OPERATION DONE") {
            // alert(command + "command sent successfully");
            this.text = command + " " + "command sent successfully";
            this.alerts = true;
            // this.ismemloading = false;
            this.$store.dispatch("alert/error", "OPERATION DONE").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          } else {
            // this.ismemloading = false;
            this.$store
              .dispatch("alert/error", "OPERATION NOT DONE")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error).then(() => {
            this.ismemloading = false;
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
      this.$store.dispatch("alert/error", "Action Performed").then(() => {
        setTimeout(() => {
          this.$store.dispatch("alert/clear");
        }, 2000);
      });
    },
    goCommandset(item, command, firmware) {
      if (this.user.emailId != "justin@alcobrake.com") {
        // this.ismemloading = true;
        Vue.axios
          .get(
            "https://robopower.xyz/us/cadevices/getuniquedevice?sno=" + item.sno
          )
          .then((res) => {
            if (res.data) {
              if (res.data[0].timezone == "" || res.data[0].timezone == null) {
                this.ismemloading = false;
                this.setzone = true;
              } else {
                this.ismemloading = false;
                var mytz = res.data[0].timezone;
                // var counts = 10;
                this.customtimestamp = mytz;
                const Swal = require("sweetalert2");
                Swal.fire({
                  title:
                    "Are you sure? You want to Change time of " +
                    item.regnum +
                    " " +
                    "Vehicle to" +
                    " " +
                    this.customtimestamp,
                  text: "You can't revert your action",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                  timer: 10000,
                }).then((result) => {
                  if (result.value) {
                    clearInterval(this.normaltimesetinterval);
                    this.onFinaltimeset(
                      this.oledDevices.filter((value) => value.sno == item.sno)
                        .length == 0
                        ? item.cbid
                        : item.devId,
                      this.customtimestamp,
                      command
                    );
                  } else {
                    clearInterval(this.normaltimesetinterval);
                    this.customtimestamp = null;
                    this.$store
                      .dispatch("alert/error", "Your Request Cancelled")
                      .then(() => {
                        setTimeout(() => {
                          this.$store.dispatch("alert/clear");
                        }, 2000);
                      });
                  }
                });
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        this.$store
          .dispatch(
            "alert/success",
            "YOU DONT HAVE PERMISSION TO DO THIS ACTION"
          )
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    getTimes() {
      var currentTime = new Date();
      var hours = currentTime.getHours();
      var minutes = currentTime.getMinutes();
      var seconds = currentTime.getSeconds();
      var month = currentTime.getMonth() + 1;
      var date = currentTime.getDate();
      var year = currentTime.getFullYear();

      // Set Minutes
      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      // Set Seconds
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      // put together the string that displays the time
      var clockTime =
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        " " +
        month +
        "/" +
        date +
        "/" +
        year;

      return clockTime;
    },
    getUniqueObjects(array, key1, key2) {
      const seen = new Set();
      return array.filter((obj) => {
        const key = obj[key1] + "|" + obj[key2];
        if (!seen.has(key)) {
          seen.add(key);
          return true;
        }
        return false;
      });
    },
    calTracking(id) {
      const mProm = new Promise((res, rej) => {
        Vue.axios
          .get(
            "https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid=" + id.cbid
          )
          .then((response) => {
            if (response.data != null) {
              var calibdata = response.data;
              calibdata = calibdata.sort(
                (a, b) =>
                  moment(a.updatedtime).valueOf() -
                  moment(b.updatedtime).valueOf()
              );
              var revcalib = calibdata.reverse();
              this.caltrackdata2 = revcalib;
              let unique_values = this.getUniqueObjects(
                revcalib,
                "caldate",
                "devid"
              );
              //   let unique_values = revcalib.map((item) => item.caldate)
              // .filter((value, index, self) => self.indexOf(value) === index);
              this.caltrackdata = unique_values;
              res({ status: "ok", vdata: unique_values });
            } else {
              // rej("Data Not Exists");
              Vue.axios
                .get(
                  "https://robopower.xyz/v2/caltrack/getdevcaltracks?devId=" +
                    id.devId
                )
                .then((response) => {
                  if (response.data != null) {
                    var calibdata = response.data;
                    calibdata = calibdata.sort(
                      (a, b) =>
                        moment(a.updatedtime).valueOf() -
                        moment(b.updatedtime).valueOf()
                    );
                    var revcalib = calibdata.reverse();
                    this.caltrackdata2 = revcalib;
                    let unique_values = this.getUniqueObjects(
                      revcalib,
                      "caldate",
                      "devid"
                    );
                    this.caltrackdata = unique_values;
                    res({ status: "ok", vdata: unique_values });
                  } else {
                    rej("Data Not Exists");
                  }
                })
                .catch((err) => {
                  this.$store.dispatch("alert/error", err).then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });
                });
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      });
      mProm
        .then((red) => {
          if (red != null) {
            var fw = red.vdata[0];
            var fwver = parseFloat(fw.fwversion);
            if (fwver > 34) {
              this.headers2 = [
                {
                  text: "DeviceId",
                  align: "left",
                  value: "cbid",
                },
                {
                  text: "HandSet",
                  align: "left",
                  value: "devid",
                },
                // { text: "Updated Time", value: "updatedtime" },
                { text: "Calibration Date", value: "caldate" },
                { text: "View Parameters", value: "parameters" },
                // { text: "Offset Pressure", value: "offset_pressure" },
                // { text: "F/W version", value: "fwversion" },
                // { text: "C/B version", value: "cbversion" },
                // { text: "S3 Version", value: "s3version.String" },
              ];
            } else {
              this.headers2 = [
                // { text: "Id", value: "id" },
                {
                  text: "DeviceId",
                  align: "left",
                  value: "cbid",
                },
                {
                  text: "HandSet",
                  align: "left",
                  value: "devid",
                },
                // { text: "Updated Time", value: "updatedtime" },
                { text: "Calibration Date", value: "caldate" },
                { text: "View Parameters", value: "parameters" },
                // { text: "Brac Peak Factor", value: "bracpeakfactor" },
                // { text: "Offset Pressure", value: "offset_pressure" },
                // { text: "F/W version", value: "fwversion" },
                // { text: "C/B version", value: "cbversion" },
                // { text: "S3 Version", value: "s3version.String" },
              ];
            }

            this.dialog13 = true;
          } else {
            console.log("empty");
          }
          // setTimeout(() => {
          //   this.dialog13 = true;
          // }, 1000);
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });

      // this.caltrack = true;
    },
    play() {
      var fromdate = moment(this.fromdate).format("MM/DD/YYYY HH:MM:ss");
      var todate = moment(this.todate).format("MM/DD/YYYY HH:MM:ss");
      Vue.axios
                  .get(
                    "https://robopower.xyz/v2/eeprom/getcustomfulllogs?cbid="+this.ping[0].cbid+"&from="+ fromdate + "&to=" + todate
                  )
                  .then((res) => {
                    if(res.data !=null){
                    var logs= res.data;
      this.isplay = false;
      var myProm;
      myProm = new Promise((resolve, reject) => {
        if (logs.length > 0) {
          
          logs.map((res) => {            
            if (
              (res.typeofevent == "254" &&
              res.passfailstatus == 1)
            ) {
              if (res.lat != "NA") {
                this.passtestevents.push(res);
             
              } else {
                Vue.axios
                  .get(
                    "https://robopower.xyz/app/v2data/gettimearoundpingv2?devId=" +
                      res.devid +
                      "&timer=" +
                      res.rtime
                  )
                  .then((res) => {
                    var ifnodata = res.data;
                    ifnodata = ifnodata.filter((res) => res.lat != "NA");
                    ifnodata = ifnodata.slice(-1)[0];
                    var endtime = res.rtime;
                    var starttime = ifnodata.gtime;
                    // var duration = moment.duration(data.rtime.diff(ifnodata.gtime));
                    var calculate = moment(endtime).diff(
                      moment(starttime),
                      "minutes"
                    );
                    if (calculate < 3) {
                      var data2 = {
                        bacvalue: res.bacvalue,
                        devid: res.devid,
                        gtime: res.gtime,
                        id: res.id,
                        lat: ifnodata.lat,
                        lon: ifnodata.lon,
                        passfailstatus: res.passfailstatus,
                        randominitial: res.randominitial,
                        rtime: res.rtime,
                        typeofevent: res.typeofevent,
                      };
                      this.passtestevents.push(data2);
                    } else {
                      Vue.axios
                        .get(
                          "https://robopower.xyz/app/v2data/gettimeabovepingv2?devId=" +
                            res.devid +
                            "&timer=" +
                            res.rtime
                        )
                        .then((res) => {
                          if (res.data) {
                            var ifnodata2 = res.data;
                            ifnodata2 = ifnodata2.filter(
                              (res) => res.lat != "NA"
                            );
                            ifnodata2 = ifnodata2.slice(0, 1)[0];
                            var starttime = res.rtime;
                            var endtime = ifnodata2.gtime;
                            var calculate = moment(endtime).diff(
                              moment(starttime),
                              "minutes"
                            );
                            if (calculate < 3) {
                              var data2 = {
                                bacvalue: res.bacvalue,
                                devid: res.devid,
                                gtime: res.gtime,
                                id: res.id,
                                lat: ifnodata2.lat,
                                lon: ifnodata2.lon,
                                passfailstatus: res.passfailstatus,
                                randominitial: res.randominitial,
                                rtime: res.rtime,
                                typeofevent: res.typeofevent,
                              };
                              this.passtestevents.push(data2);
                            }
                          }
                        })
                        .catch((err) => {
                          this.$store.dispatch("alert/error", err).then(() => {
                            setTimeout(() => {
                              this.$store.dispatch("alert/clear");
                            }, 2000);
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            } else if (
              res.typeofevent == "254" &&
              res.passfailstatus == 0
            ) {
              if (res.lat != "NA") {
                this.failtestevents.push(res);
              } else {
                Vue.axios
                  .get(
                    "https://robopower.xyz/app/v2data/gettimearoundpingv2?devId=" +
                      res.devid +
                      "&timer=" +
                      res.rtime
                  )
                  .then((res) => {
                    var ifnodata = res.data;
                    ifnodata = ifnodata.filter((res) => res.lat != "NA");
                    ifnodata = ifnodata.slice(-1)[0];
                    var endtime = res.rtime;
                    var starttime = ifnodata.gtime;
                    // var duration = moment.duration(data.rtime.diff(ifnodata.gtime));
                    var calculate = moment(endtime).diff(
                      moment(starttime),
                      "minutes"
                    );
                    if (calculate < 3) {
                      var data2 = {
                        bacvalue: res.bacvalue,
                        devid: res.devid,
                        gtime: res.gtime,
                        id: res.id,
                        lat: ifnodata.lat,
                        lon: ifnodata.lon,
                        passfailstatus: res.passfailstatus,
                        randominitial: res.randominitial,
                        rtime: res.rtime,
                        typeofevent: res.typeofevent,
                      };
                      this.failtestevents.push(data2);
                    } else {
                      Vue.axios
                        .get(
                          "https://robopower.xyz/app/v2data/gettimeabovepingv2?devId=" +
                            res.devid +
                            "&timer=" +
                            res.rtime
                        )
                        .then((res) => {
                          if (res.data) {
                            var ifnodata2 = res.data;
                            ifnodata2 = ifnodata2.filter(
                              (res) => res.lat != "NA"
                            );
                            ifnodata2 = ifnodata2.slice(0, 1)[0];
                            var starttime = res.rtime;
                            var endtime = ifnodata2.gtime;
                            var calculate = moment(endtime).diff(
                              moment(starttime),
                              "minutes"
                            );
                            if (calculate < 3) {
                              var data2 = {
                                bacvalue: res.bacvalue,
                                devid: res.devid,
                                gtime: res.gtime,
                                id: res.id,
                                lat: ifnodata2.lat,
                                lon: ifnodata2.lon,
                                passfailstatus: res.passfailstatus,
                                randominitial: res.randominitial,
                                rtime: res.rtime,
                                typeofevent: res.typeofevent,
                              };
                              this.failtestevents.push(data2);
                            }
                          }
                        })
                        .catch((err) => {
                          this.$store.dispatch("alert/error", err).then(() => {
                            setTimeout(() => {
                              this.$store.dispatch("alert/clear");
                            }, 2000);
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            } else if (res.typeofevent == "15" && res.enginerunstate==1) {
              if (res.lat != "NA") {
                this.engonevents.push(res);
              } else {
                Vue.axios
                  .get(
                    "https://robopower.xyz/app/v2data/gettimearoundpingv2?devId=" +
                      res.devid +
                      "&timer=" +
                      res.rtime
                  )
                  .then((res) => {
                    var ifnodata = res.data;
                    ifnodata = ifnodata.filter((res) => res.lat != "NA");
                    ifnodata = ifnodata.slice(-1)[0];
                    var endtime = res.rtime;
                    var starttime = ifnodata.gtime;
                    // var duration = moment.duration(data.rtime.diff(ifnodata.gtime));
                    var calculate = moment(endtime).diff(
                      moment(starttime),
                      "minutes"
                    );
                    if (calculate < 3) {
                      var data2 = {
                        bacvalue: res.bacvalue,
                        devid: res.devid,
                        gtime: res.gtime,
                        id: res.id,
                        lat: ifnodata.lat,
                        lon: ifnodata.lon,
                        passfailstatus: res.passfailstatus,
                        randominitial: res.randominitial,
                        rtime: res.rtime,
                        typeofevent: res.typeofevent,
                      };
                      this.engonevents.push(data2);
                    } else {
                      Vue.axios
                        .get(
                          "https://robopower.xyz/app/v2data/gettimeabovepingv2?devId=" +
                            res.devid +
                            "&timer=" +
                            res.rtime
                        )
                        .then((res) => {
                          if (res.data) {
                            var ifnodata2 = res.data;
                            ifnodata2 = ifnodata2.filter(
                              (res) => res.lat != "NA"
                            );
                            ifnodata2 = ifnodata2.slice(0, 1)[0];
                            var starttime = res.rtime;
                            var endtime = ifnodata2.gtime;
                            var calculate = moment(endtime).diff(
                              moment(starttime),
                              "minutes"
                            );
                            if (calculate < 3) {
                              var data2 = {
                                bacvalue: res.bacvalue,
                                devid: res.devid,
                                gtime: res.gtime,
                                id: res.id,
                                lat: ifnodata2.lat,
                                lon: ifnodata2.lon,
                                passfailstatus: res.passfailstatus,
                                randominitial: res.randominitial,
                                rtime: res.rtime,
                                typeofevent: res.typeofevent,
                              };
                              this.engonevents.push(data2);
                            }
                          }
                        })
                        .catch((err) => {
                          this.$store.dispatch("alert/error", err).then(() => {
                            setTimeout(() => {
                              this.$store.dispatch("alert/clear");
                            }, 2000);
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            } else if (res.typeofevent == "15" && res.enginerunstate==0) {
              if (res.lat != "NA") {
                this.engoffevents.push(res);
              } else {
                Vue.axios
                  .get(
                    "https://robopower.xyz/app/v2data/gettimearoundpingv2?devId=" +
                      res.devid +
                      "&timer=" +
                      res.rtime
                  )
                  .then((res) => {
                    var ifnodata = res.data;
                    ifnodata = ifnodata.filter((res) => res.lat != "NA");
                    ifnodata = ifnodata.slice(-1)[0];
                    var endtime = res.rtime;
                    var starttime = ifnodata.gtime;
                    // var duration = moment.duration(data.rtime.diff(ifnodata.gtime));
                    var calculate = moment(endtime).diff(
                      moment(starttime),
                      "minutes"
                    );
                    if (calculate < 3) {
                      var data2 = {
                        bacvalue: res.bacvalue,
                        devid: res.devid,
                        gtime: res.gtime,
                        id: res.id,
                        lat: ifnodata.lat,
                        lon: ifnodata.lon,
                        passfailstatus: res.passfailstatus,
                        randominitial: res.randominitial,
                        rtime: res.rtime,
                        typeofevent: res.typeofevent,
                      };
                      this.engoffevents.push(data2);
                    } else {
                      Vue.axios
                        .get(
                          "https://robopower.xyz/app/v2data/gettimeabovepingv2?devId=" +
                            res.devid +
                            "&timer=" +
                            res.rtime
                        )
                        .then((res) => {
                          if (res.data) {
                            var ifnodata2 = res.data;
                            ifnodata2 = ifnodata2.filter(
                              (res) => res.lat != "NA"
                            );
                            ifnodata2 = ifnodata2.slice(0, 1)[0];
                            var starttime = res.rtime;
                            var endtime = ifnodata2.gtime;
                            var calculate = moment(endtime).diff(
                              moment(starttime),
                              "minutes"
                            );
                            if (calculate < 3) {
                              var data2 = {
                                bacvalue: res.bacvalue,
                                devid: res.devid,
                                gtime: res.gtime,
                                id: res.id,
                                lat: ifnodata2.lat,
                                lon: ifnodata2.lon,
                                passfailstatus: res.passfailstatus,
                                randominitial: res.randominitial,
                                rtime: res.rtime,
                                typeofevent: res.typeofevent,
                              };
                              this.engoffevents.push(data2);
                            }
                          }
                        })
                        .catch((err) => {
                          this.$store.dispatch("alert/error", err).then(() => {
                            setTimeout(() => {
                              this.$store.dispatch("alert/clear");
                            }, 2000);
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    this.$store.dispatch("alert/error", err).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            }
            resolve({ status: "ok" });
          });
        } else {
          reject("Promise rejected");
        }
      });
      myProm
        .then(() => {
          if (this.isplay == true) {
            this.isplay = false;
            setTimeout(() => {
              this.isplay = true;
            }, 200);
          } else {
            this.isplay = true;
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
      } else{
           if (this.isplay == true) {
            this.isplay = false;
            setTimeout(() => {
              this.isplay = true;
            }, 200);
          } else {
            this.isplay = true;
          }
        //removed alert here
      }
      })
      
    },
    stop() {
      this.isplay = false;
      // this.$refs.datetimeform.reset();
      this.$refs.datetimeform.reset();
      
      this.todate = null;
      this.distancetravel = null;
      this.passtestevents = [];
      this.failtestevents = [];
      this.engonevents = [];
      this.engoffevents = [];
      this.totaltest = null;
      this.isloading = false;
    },
    saveSign() {
      var _this = this;
      this.$refs.signaturePad.resizeCanvas();
      var png = _this.$refs.signaturePad.saveSignature();
      this.sign = png.data;
    },
    dSign(item) {
      if (item.state == "CA" || item.state == "INDIA") {
        this.myclient = item;
        this.filename = item.client + "_" + item.regnum;
        this.Acceptance(item);
        this.pdfdialog = true;
      } else {
        this.myclient = item;
        this.filename = item.client + "_" + item.regnum;
        this.Acceptance(item);
        this.interlockdialog = true;
      }
    },
    newacceptance(myclient) {
      this.dialog12 = true;
      this.Acceptance(myclient);
      this.pdfdialog = true;
      this.signdialog = false;
    },
    acceptance(id) {
      this.myclient = id;
      this.accepdialog = true;
    },
    closeacc() {
      this.signdialog = false;
    },
    undo() {
      var _this = this;
      _this.$refs.signaturePad.undoSignature();
    },
    Acceptance(item) {
      this.editedItem.id = item.id;
      this.editedItem.officeId = item;
      this.editedItem.client = item.client;
      if (item.clientphone1 != "") {
        var mdb1 = item.clientphone1.split("");
        var mb1 =
          mdb1[0] +
          " " +
          mdb1[1] +
          mdb1[2] +
          mdb1[3] +
          " " +
          mdb1[4] +
          mdb1[5] +
          mdb1[6] +
          "-" +
          mdb1[7] +
          mdb1[8] +
          mdb1[9] +
          mdb1[10];
        this.editedItem.clientphone1 = mb1;
      }
      if (item.clientphone2 != "") {
        var mdb2 = item.clientphone2.split("");
        var mb2 =
          mdb2[0] +
          " " +
          mdb2[1] +
          mdb2[2] +
          mdb2[3] +
          " " +
          mdb2[4] +
          mdb2[5] +
          mdb2[6] +
          "-" +
          mdb2[7] +
          mdb2[8] +
          mdb2[9] +
          mdb2[10];
        this.editedItem.clientphone2 = mb2;
      }
      this.editedItem.email = item.email;
      var std = item.dob.split("-");
      this.editedItem.dob = std[1] + " / " + std[2] + " / " + std[0];
      this.editedItem.dlnum = item.DLnum;
      this.editedItem.clientAddress = item.clientAddress;
      this.editedItem.space = item.space;
      this.editedItem.state = item.state;
      this.editedItem.city = item.city;
      this.editedItem.zipcode = item.zipcode;
      this.editedItem.regdate = item.regdate;
      this.editedItem.regnum = item.regnum;
      // this.editedItem.device = item.device;
      // this.editedItem.cbid = item.cbid;
      this.editedItem.installDate = item.installDate;
      this.editedItem.calibrationDate = item.calibrationDate;
      this.editedItem.nextcmonitordate = item.nextcmonitordate;
      this.editedItem.make = item.make;
      this.editedItem.model = item.model;
      this.editedItem.sno = item.sno;
      this.editedItem.year = item.year;
      this.editedItem.color = item.color;
      this.editedItem.vin = item.vin;
      this.editedItem.tag = item.tag;
      this.editedItem.tagstate = item.tagstate;
      // this.editedItem.dlnum = item.dlnum;
      this.editedItem.prohibitionnumber = item.prohibitionnumber;
      this.editedItem.prohibstart = item.prohibstart;
      this.editedItem.prohibend = item.prohibend;
      this.editedItem.conviction = item.conviction;
      this.editedItem.calibrationperiod = item.calibrationperiod;
      this.editedItem.suffix = item.suffix;
      this.editedItem.rclientAddress = item.rclientaddress;
      this.editedItem.rspace = item.rspace;
      this.editedItem.rstate = item.rstate;
      this.editedItem.rcity = item.rcity;
      this.editedItem.rzipcode = item.rzipcode;
      this.editedItem.isresidsame = item.isresidsame;
      this.editedItem.isinstalledandworking = item.isinstalledandworking;
      this.editedItem.driverisincompliance = item.driverisincompliance;
      this.editedItem.isunavailablevehicle = item.isunavailablevehicle;
      this.editedItem.uregnum = item.uregnum;
      this.editedItem.umake = item.umake;
      this.editedItem.uyear = item.uyear;
      this.editedItem.uvin = item.uvin;
      this.fetchInstaller(item.installer);
      this.fetchOfficedetails();
    },
    facevaluechange(item) {
      var facevalue = Number(this.faceswitch);
      const formData = new FormData();
      formData.append("facedetection", facevalue);
      Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editfrservices/" + item.id,
          formData
        )
        .then((res) => {
          if (res.status == 200) {
            this.reLoad();
            if (item.facedetection == 0) {
              this.$store
                .dispatch("alert/success", "FACE RECOGNITION ON")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            } else {
              this.$store
                .dispatch("alert/success", "FACE RECOGNITION OFF")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          }
        });
    },
    issmsvaluechange(item) {
      var issmsvalue = Number(this.issmsswitch);
      const formData2 = new FormData();
      formData2.append("issms", issmsvalue);
      Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editsmsservices/" + item.id,
          formData2
        )
        .then((res) => {
          if (res.status == 200) {
            this.reLoad();
            if (item.issms == 0) {
              this.$store.dispatch("alert/success", "SMS ALERT ON").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            } else {
              this.$store
                .dispatch("alert/success", "SMS ALERT OFF")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          } else {
            this.$store.dispatch("alert/error", "error").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          }
        });
    },
    viewImage(data, firmware) {
      this.currentevent = data;
      this.imgdialog = true;
      this.dialog9 = false;
      if (
        this.oledDevices.filter((value) => value.sno == this.expanded[0].sno)
          .length == 0
      ) {
        var itemname = data.imgtime;
        var stname = itemname.replaceAll("/", "_");
        var ssname = stname.replaceAll(" ", "_");
        var smname = ssname.replaceAll(":", "_");
        this.bacvalue = data.bacvalue;
        this.devid = data.devid;
        this.rtime = data.rtime;
        this.result = data.passfailstatus;
        Vue.axios
          .get(
            "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
              data.controlId +
              "&Image=" +
              itemname
          )
          .then((res) => {
            if (res.data) {
              this.imageBytes = res.data;
              const imageSizeInBytes = this.imageBytes.length;
              const imageSizeInKb = imageSizeInBytes / 1024; 
              this.imageSize = imageSizeInKb
            } else {
                  this.$store.dispatch("alert/error", "No Image Found").then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        var itemname = data.rtime;
        var stname = itemname.replaceAll("/", "_");
        var ssname = stname.replaceAll(" ", "_");
        var smname = ssname.replaceAll(":", "_");
        this.bacvalue = data.bacvalue;
        this.devid = data.devid;
        this.rtime = data.rtime;
        this.result = data.passfailstatus;
        Vue.axios
          .get(
            "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
              data.devid +
              "&Image=" +
              itemname
          )
          .then((res) => {
            // check Image at exact time
            if (res.data) {
              this.imageBytes = res.data;
              const imageSizeInBytes = this.imageBytes.length;
              const imageSizeInKb = imageSizeInBytes / 1024; 
              this.imageSize = imageSizeInKb
            } else {
             
                  this.$store.dispatch("alert/error", "No Image Found").then(() => {
                    setTimeout(() => {
                      this.$store.dispatch("alert/clear");
                    }, 2000);
                  });

              // End second check
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
    },

    downloadImage(data) {
      this.downloaddata = data;
      if (this.firmware > 34) {
        if (this.imageBytes != "") {
          var itemname = data.rtime;
          var stname = itemname.replaceAll("/", "_");
          var ssname = stname.replaceAll(" ", "_");
          var smname = ssname.replaceAll(":", "_");
          Vue.axios
            .get(
              "https://robopower.xyz/app/getImage/download?deviceId=" +
                data.controlId +
                "&Image=" +
                smname +
                ".txt"
            )
            .then((response) => {
              if (response.data) {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:image/png;base64," + response.data; //Image Base64 Goes here
                a.download =
                  this.downloaddata.controlId +
                  "--" +
                  this.downloaddata.rtime +
                  ".png"; //File name Here
                a.click(); //Downloaded fil
              } else {
                let promises = [];
                for (var i = -10; i < 10; i++) {
                  var itemname2 = moment(itemname)
                    .add(i, "seconds")
                    .format("MM/DD/YYYY HH:mm:ss");
                  var stname2 = itemname2.replaceAll("/", "_");
                  var ssname2 = stname2.replaceAll(" ", "_");
                  var smname2 = ssname2.replaceAll(":", "_");
                  promises.push(
                    Vue.axios.get(
                      "https://robopower.xyz/app/getImage/download?deviceId=" +
                        data.controlId +
                        "&Image=" +
                        smname2 +
                        ".txt"
                    )
                  );
                }
                Promise.all(promises)
                  .then((res) => {
                    for (i = 0; i < res.length; i++) {
                      if (res[i].data) {
                        var a = document.createElement("a"); //Create <a>
                        a.href = "data:image/png;base64," + res[i].data; //Image Base64 Goes here
                        a.download =
                          this.downloaddata.controlId +
                          "--" +
                          this.downloaddata.rtime +
                          ".png"; //File name Here
                        a.click();
                        break;
                      }
                    }
                  })
                  .catch((error) => {
                    this.$store.dispatch("alert/error", error).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            })
            .catch((err) => {
              this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            });
        }
      } else {
        if (this.imageBytes != "") {
          var itemname = data.rtime;
          var stname = itemname.replaceAll("/", "_");
          var ssname = stname.replaceAll(" ", "_");
          var smname = ssname.replaceAll(":", "_");
          Vue.axios
            .get(
              "https://robopower.xyz/app/getImage/download?deviceId=" +
                data.devid +
                "&Image=" +
                smname +
                ".txt"
            )
            .then((response) => {
              if (response.data) {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:image/png;base64," + response.data; //Image Base64 Goes here
                a.download =
                  this.downloaddata.devid +
                  "--" +
                  this.downloaddata.rtime +
                  ".png"; //File name Here
                a.click(); //Downloaded fil
              } else {
                let promises = [];
                for (var i = -10; i < 10; i++) {
                  var itemname2 = moment(itemname)
                    .add(i, "seconds")
                    .format("MM/DD/YYYY HH:mm:ss");
                  var stname2 = itemname2.replaceAll("/", "_");
                  var ssname2 = stname2.replaceAll(" ", "_");
                  var smname2 = ssname2.replaceAll(":", "_");
                  promises.push(
                    Vue.axios.get(
                      "https://robopower.xyz/app/getImage/download?deviceId=" +
                        data.devid +
                        "&Image=" +
                        smname2 +
                        ".txt"
                    )
                  );
                }
                Promise.all(promises)
                  .then((res) => {
                    for (i = 0; i < res.length; i++) {
                      if (res[i].data) {
                        // console.log(res[i])
                        var a = document.createElement("a"); //Create <a>
                        a.href = "data:image/png;base64," + res[i].data; //Image Base64 Goes here
                        a.download =
                          this.downloaddata.devid +
                          "--" +
                          this.downloaddata.rtime +
                          ".png"; //File name Here
                        a.click();
                        break;
                      }
                    }
                  })
                  .catch((error) => {
                    this.$store.dispatch("alert/error", error).then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                  });
              }
            })
            .catch((err) => {
              this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            });
        }
      }
    },

    getColor(it) {
      if (it.passfailstatus == "FAIL") return "red";
      else if (it.result == null) return "green";
      else if (it.passfailstatus == "PASS") return "green";
      else return "orange";
    },

    Downloadform() {
      var alldata = [
        this.editedItem.dlnum,
        this.editedItem.client,
        this.editedItem.suffix,
        this.editedItem.clientAddress,
        this.editedItem.city,
        this.editedItem.state,
        this.editedItem.zipcode,
        this.editedItem.rclientAddress,
        this.editedItem.rcity,
        this.editedItem.rstate,
        this.editedItem.rzipcode,
        this.editedItem.dob,
        this.editedItem.clientphone1,
        this.editedItem.clientphone2,
        this.editedItem.sno,
        this.editedItem.officeName,
        this.editedItem.officelicensenumber,
        this.editedItem.faddress,
        this.editedItem.fcity,
        this.editedItem.fstate,
        this.editedItem.fzipcode,
        this.editedItem.installDate,
        this.editedItem.make,
        this.editedItem.year,
        this.editedItem.regnum,
        this.editedItem.vin,
        this.editedItem.installer.userName,
        this.editedItem.imobile,
        // this.editedItem.space,
      ];
      // var nullindexes = [];
      // alldata.map((ele, index) => {
      //   if (ele == null || ele == "" || ele == undefined) {
      //     nullindexes.push(index);
      //   }
      // });
      // if (nullindexes.length > 0) {
      //   var message = "";
      //   nullindexes.map((el) => {
      //     message = message + "\n" + this.clientsArray[el] + ",";
      //   });
      //   const Swal = require("sweetalert2");
      //   Swal.fire({
      //     title: "The following fields are empty!",
      //     text: message,
      //     icon: "error",
      //     confirmButtonText: "Ok",
      //   });
      // } else {
        var that = this;
        html2canvas(document.getElementById("pdfcontent")).then(function (
          canvas
        ) {
          var doc = new jsPDF("p", "mm", "a4");
          var imgData = canvas.toDataURL("image/png");
          doc.setFontSize(800);
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          doc.addImage(imgData, "JPEG", 0, 0, width, height);
          doc.save(that.filename);
          var blob = new Blob([canvas.toDataURL("image/png")], {
            type: "text/plain;charset=utf-8",
          });
          const formData = new FormData();
          formData.append("file", blob);
          formData.append(
            "filename",
            that.editedItem.client + "_" + that.editedItem.regnum
          );
          formData.append("client", that.editedItem.client);
          formData.append("device", that.editedItem.devId);
          that.$store.dispatch("uploadAcceptance", formData);
        });
        this.pdfdialog = false;
      // }
      // this.accepdialog = false
    },
    viewInvoices() {
      this.dialog11 = true;
    },
    viewInvoices2() {
      this.dialog18 = true;
    },
    downloadItem(datas, isnewinstallation) {
      if (datas.state == "TX") {
        this.istexasloading = true;
        var zip = new JSZip();
        var img = zip.folder("images");
        var updatecount = 0;
      }
      if (this.oledDevices.filter((value) => value.sno  == datas.sno).length == 0) {
        Vue.axios
          .get("https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + datas.cbid)
          .then((response) => {
            if (response.data != null) {
              // console.log("cbid based")
              var remData = response.data;
              //  .filter((r)=>{
              //   return moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") <= moment(datas.installDate).format("MM/DD/YYYY HH:mm:ss") && moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") < moment(new Date()).add(1, 'days').format("MM/DD/YYYY HH:mm:ss")
              // })
              this.eventscount = response.data.filter((res) => {
                return res.typeofevent == "254";
              });

              var wholedata = response.data;
              this.totaleventscount = wholedata.length;
              wholedata = wholedata.sort(
                (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
              );
              var sorteddata = wholedata.reverse();
              var arrData = sorteddata;
              if (this.user.role == "superadmin") {
                remData = remData.filter((o) => o.typeofevent !== "0");
              } else {
                remData = remData.filter(
                  (o) =>
                    o.typeofevent !== "0" &&
                    o.typeofevent !== "151" &&
                    o.typeofevent !== "152" &&
                    o.typeofevent !== "153" &&
                    o.typeofevent !== "154" &&
                    o.typeofevent !== "155" &&
                    o.typeofevent !== "156" &&
                    o.typeofevent !== "157" &&
                    o.typeofevent !== "218" &&
                    o.typeofevent !== "219"
                );
              }
              arrData.push(remData);
              var today = new Date();
              var row_width = 5;
              var object;
              if (this.type == "text") {
                object =
                  "Alcobrake" +
                  new Array(row_width).join(" ") +
                  "Log Summary Report \r\n\r\nDevice Id:" +
                  datas.cbid +
                  // +"\r\n \r\n";
                  new Array(row_width).join(" ") +
                  "Device Installed On:" +
                  datas.installDate +
                  "\r\n \r\n";
                object +=
                  "LogRead Date " +
                  new Array(row_width).join(" ") +
                  today +
                  "\r\n \r\n";

                object +=
                  "Record id" +
                  new Array(row_width + 5).join(" ") +
                  "Date" +
                  new Array(row_width + 10).join(" ") +
                  "Week" +
                  new Array(row_width + 15).join(" ") +
                  "EventType" +
                  new Array(row_width + 9).join(" ") +
                  "brac" +
                  new Array(row_width + 8).join(" ") +
                  "Result \r\n";
                if (remData != null) {
                  var rcid = 1;
                  var neProm2 = new Promise((resolve, reject) => {
                    if (remData.length > 0) {
                      remData.map((element) => {
                        if (element.typeofevent != "101") {
                          if (element.typeofevent != "29") {
                            var dt = new Date(element.rtime);
                            var week = [
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ];
                            var weekday = week[dt.getDay()];
                            var eventType = " ";
                            var Result = " ";
                            var Bacvalue = " ";
                            //AlcoholTest
                            if (element.typeofevent == 254) {
                              //initialtest
                              if (
                                element.randominitial == 2 ||
                                element.randominitial == 0
                              ) {
                                eventType = "INITIAL TEST";
                              } else if (
                                element.randominitial == 3 ||
                                element.randominitial == 1
                              ) {
                                eventType = "RANDOM TEST";
                              } else {
                                eventType = "NA";
                              }
                              if (element.passfailstatus == 1) {
                                Result = "PASS";
                              } else if (element.passfailstatus == 0) {
                                Result = "FAIL";
                              } else {
                                Result = " ";
                              }
                            } else if (element.typeofevent == 15) {
                              if (element.enginerunstate == 0) {
                                eventType = "ENGINE OFF";
                              } else if (element.enginerunstate == 1) {
                                eventType = "ENGINE ON";
                              } else {
                                eventType = "NA";
                              }
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 66) {
                              eventType = "HARD_BLOW";
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 21) {
                              eventType = "CAR_BATTERY_TAMPERING";
                            } else if (element.typeofevent == 19) {
                              eventType = "VIOLATION_LOCKOUT";
                            } else if (element.typeofevent == 4) {
                              eventType = "BYPASS";
                            } else if (element.typeofevent == 18) {
                              eventType = "TEST_REFUSED_RR_TEST";
                            } else if (element.typeofevent == 28) {
                              eventType = "TAMPERED";
                            } else if (element.typeofevent == 22) {
                              eventType = "CALIBRATION DONE";
                            } else if (element.typeofevent == 29) {
                              eventType = "START_TEST_ATTEMPT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 27) {
                              eventType = "COOL_SAMPLE";
                            } else if (element.typeofevent == 25) {
                              eventType = "HOT_SAMPLE";
                            } else if (element.typeofevent == 8) {
                              eventType = "ONE_TIME_PASS_CODE";
                            } else if (element.typeofevent == 6) {
                              eventType = "SERVICE_PERIOD_SET";
                            } else if (element.typeofevent == 7) {
                              eventType = "VOILATION_RESET";
                            } else if (element.typeofevent == 11) {
                              eventType = "LOG_READ";
                            } else if (element.typeofevent == 220) {
                              eventType = "LOCKOUT 0";
                            } else if (element.typeofevent == 221) {
                              eventType = "LOCKOUT 1";
                            } else if (element.typeofevent == 222) {
                              eventType = "LOCKOUT 2";
                            } else if (element.typeofevent == 223) {
                              eventType = "LOCKOUT 3";
                            } else if (element.typeofevent == 224) {
                              eventType = "LOCKOUT 4";
                            } else if (element.typeofevent == 225) {
                              eventType = "LOCKOUT 5";
                            } else if (element.typeofevent == 226) {
                              eventType = "LOCKOUT 6";
                            } else if (element.typeofevent == 227) {
                              eventType = "LOCKOUT 7";
                            } else if (element.typeofevent == 56) {
                              eventType = "TAKE_A_PICTURE";
                            } else if (element.typeofevent == 101) {
                              eventType = "PICTURE_CAPTURED";
                            } else if (element.typeofevent == 60) {
                              eventType = "BYPASS_START";
                            } else if (element.typeofevent == 61) {
                              eventType = "BYPASS_STOP";
                            } else if (element.typeofevent == 14) {
                              eventType = "SERVICE_ALERT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 62) {
                              eventType = "SAMPLER_DISCONNECTED";
                            } else if (element.typeofevent == 10) {
                              eventType = "CAR_BATTERY_OFF";
                            } else if (element.typeofevent == 20) {
                              eventType = "CAR_BATTERY_ON";
                            } else if (element.typeofevent == 217) {
                              eventType = "MEMSET";
                            } else if (element.typeofevent == 219) {
                              eventType = "TIME_SET";
                            } else if (element.typeofevent == 218) {
                              eventType = "DEVICE RESTART";
                            } else if (element.typeofevent == 151) {
                              eventType = "ALCOHOL_SENSOR_FAIL";
                            } else if (element.typeofevent == 152) {
                              eventType = "BREATH_PRESSURE_SEN_FAIL";
                            } else if (element.typeofevent == 153) {
                              eventType = "BREATH_TEMP_SEN_FAIL";
                            } else if (element.typeofevent == 154) {
                              eventType = "BMP180_ALTITUDE_FAIL";
                            } else if (element.typeofevent == 155) {
                              eventType = "PROXIMITY_SENSOR_FAIL";
                            } else if (element.typeofevent == 156) {
                              eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                            } else if (element.typeofevent == 157) {
                              eventType = "RTC_FAIL";
                            }
                            if (element.bacvalue != 255) {
                              Bacvalue = element.bacvalue;
                            }

                            var rcidlength, rtimelength, braclength;

                            if (
                              remData.indexOf(element).toString().length == 1
                            ) {
                              rcidlength = row_width + 10;
                            } else if (
                              remData.indexOf(element).toString().length == 2
                            ) {
                              rcidlength = row_width + 9;
                            } else if (
                              remData.indexOf(element).toString().length == 3
                            ) {
                              rcidlength = row_width + 8;
                            } else if (
                              remData.indexOf(element).toString().length == 4
                            ) {
                              rcidlength = row_width + 7;
                            }
                            if (weekday) {
                              if (weekday.length == 6) {
                                rtimelength = row_width + 12;
                              } else if (weekday.length == 7) {
                                rtimelength = row_width + 11;
                              } else if (weekday.length == 8) {
                                rtimelength = row_width + 10;
                              } else if (weekday.length == 9) {
                                rtimelength = row_width + 9;
                              } else if (weekday.length == 10) {
                                rtimelength = row_width + 8;
                              } else if (weekday.length == 11) {
                                rtimelength = row_width + 7;
                              } else if (weekday.length == 12) {
                                rtimelength = row_width + 6;
                              } else if (weekday.length == 13) {
                                rtimelength = row_width + 5;
                              } else if (weekday.length == 14) {
                                rtimelength = row_width + 4;
                              } else if (weekday.length == 15) {
                                rtimelength = row_width + 3;
                              } else if (weekday.length == 16) {
                                rtimelength = row_width + 2;
                              } else if (weekday.length == 17) {
                                rtimelength = row_width + 1;
                              } else if (weekday.length == 18) {
                                rtimelength = row_width;
                              }
                            }
                            if (eventType.length == 9) {
                              braclength = row_width + 12;
                            } else if (eventType.length == 10) {
                              braclength = row_width + 11;
                            } else if (eventType.length == 11) {
                              braclength = row_width + 10;
                            } else if (eventType.length == 12) {
                              braclength = row_width + 9;
                            } else if (eventType.length == 13) {
                              braclength = row_width + 8;
                            } else if (eventType.length == 14) {
                              braclength = row_width + 7;
                            } else if (eventType.length == 15) {
                              braclength = row_width + 6;
                            } else if (eventType.length == 16) {
                              braclength = row_width + 5;
                            } else if (eventType.length == 17) {
                              braclength = row_width + 4;
                            } else if (eventType.length == 18) {
                              braclength = row_width + 3;
                            } else if (eventType.length == 19) {
                              braclength = row_width;
                            } else if (eventType.length == 20) {
                              braclength = row_width;
                            } else if (eventType.length == 21) {
                              braclength = row_width;
                            }

                            object +=
                              rcid +
                              new Array(rcidlength).join(" ") +
                              moment(element.rtime).format(
                                "MM/DD/YYYY hh:mm:ss"
                              ) +
                              new Array(row_width).join(" ") +
                              weekday +
                              new Array(rtimelength).join(" ") +
                              eventType +
                              new Array(braclength).join(" ") +
                              Bacvalue +
                              new Array(row_width + 10).join(" ") +
                              Result +
                              "\r\n";
                            rcid++;
                          }
                        }
                      });
                      resolve("success");
                    } else {
                      this.dialog10 = false;
                      this.istexasloading = false;
                      reject("No Logs Found");
                      const Swal = require("sweetalert2");
                      Swal.fire({
                        title: "No Logs Found From Device Install Date",
                        icon: "error",
                        showCloseButton: true,
                        confirmButtonText: "Ok",
                      });
                    }
                  }); //end promise
                  neProm2.then(async (res) => {
                    if (res == "success") {
                      this.texasdisplay = "Downloading Images Please wait.....";
                      if (datas.state == "TX") {
                        await Promise.all(
                          this.eventscount.map((element) => {
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");

                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.controlId +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".png", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                                                            this.$store
                                          .dispatch("alert/error", "No Image Found")
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  this.$store
                                    .dispatch("alert/error", error)
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                });
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              Vue.axios
                                .get(
                                  "http://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.controlId +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".png", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    
                                        this.$store
                                          .dispatch("alert/error", "No Image Found")
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  this.$store
                                    .dispatch("alert/error", error)
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                });
                            } else {
                              console.log("NA");
                            }
                            // }
                          })
                        ).catch((err) => {
                          console.log(err);
                          //next process
                        });
                        var checkimagesInterval = setInterval(() => {
                          var completedper =
                            (updatecount / this.eventscount.length) * 100;
                          this.texasdisplay =
                            "Zipping files..... " +
                            parseInt(completedper) +
                            " %";
                          if (updatecount >= this.eventscount.length) {
                            setTimeout(() => {
                              this.confirmtexasloading = true;
                              clearInterval(checkimagesInterval);
                              this.istexasloading = false;
                              setTimeout(() => {
                                this.confirmtexasloading = false;
                                zip.file(datas.client + ".txt", object);
                                zip
                                  .generateAsync({ type: "blob" })
                                  .then(function (content) {
                                    //make default
                                    saveAs(content, datas.client + ".zip");
                                    if (isnewinstallation == 1) {
                                      this.clearstorage(
                                        datas,
                                        this.firmware,
                                        1
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    this.$store
                                      .dispatch("alert/error", err)
                                      .then(() => {
                                        setTimeout(() => {
                                          this.$store.dispatch("alert/clear");
                                        }, 2000);
                                      });
                                  });
                                this.istexasloading = false;
                                this.imagecount = 0;
                                this.eventscount = [];
                                updatecount = 0;
                                (this.texasdisplay =
                                  "Downloading data please wait....."),
                                  (this.dialog10 = false);
                              }, 5000);
                            }, 2000);
                          }
                        }, 2000);

                        //next process
                      } else {
                        var element = document.createElement("a");
                        element.setAttribute(
                          "href",
                          "data:text/plain;charset=utf-8," +
                            encodeURIComponent(object)
                        );
                        element.setAttribute(
                          "download",
                          remData[0].controlId + "_log"
                        );
                        // element.style.display = "none";
                        // document.body.appendChild(element);
                        element.click();
                        // document.body.removeChild(element);
                        this.dialog10 = false;
                        if (isnewinstallation == 1) {
                          this.clearstorage(datas, this.firmware, 1);
                        }
                      }
                    }
                  });
                } else {
                  this.istexasloading = false;
                  this.$store
                    .dispatch("alert/error", "Log file empty")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                      }, 2000);
                    });
                }
              } else {
                //CSV data
                this.texasdisplay = "Preparing Logs please wait.....";
                // console.log(datas+"dddd")
                object =
                  "Alcobrake" +
                  new Array(row_width).join(" ") +
                  "Log Summary Report \r\n\r\nDevice Id:" +
                  datas.cbid +
                  // +"\r\n \r\n";
                  new Array(row_width).join(" ") +
                  "Device Installed On :" +
                  datas.installDate +
                  "\r\n \r\n";
                object +=
                  "LogRead Date " +
                  new Array(row_width).join(" ") +
                  today +
                  "\r\n \r\n";
                object +=
                  "Record id" +
                  new Array(row_width + 5).join(" ") +
                  ";" +
                  "Date" +
                  new Array(row_width + 10).join(" ") +
                  ";" +
                  "Week" +
                  new Array(row_width + 15).join(" ") +
                  ";" +
                  "EventType" +
                  new Array(row_width + 9).join(" ") +
                  ";" +
                  "brac" +
                  new Array(row_width + 8).join(" ") +
                  ";" +
                  "Result \r\n";
                if (remData != null) {
                  rcid = 1;
                  var neProm = new Promise((resolve, reject) => {
                    if (remData.length > 0) {
                      remData.map((element) => {
                        if (element.typeofevent != "101") {
                          if (element.typeofevent != "29") {
                            var dt = new Date(element.rtime);
                            var week = [
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ];
                            var weekday = week[dt.getDay()];
                            var eventType = " ";
                            var Result = " ";
                            var Bacvalue = " ";
                            //AlcoholTest
                            if (element.typeofevent == 254) {
                              //initialtest
                              if (
                                element.randominitial == 2 ||
                                element.randominitial == 0
                              ) {
                                eventType = "INITIAL TEST";
                              } else if (
                                element.randominitial == 3 ||
                                element.randominitial == 1
                              ) {
                                eventType = "RANDOM TEST";
                              } else {
                                eventType = "NA";
                              }
                              if (element.passfailstatus == 1) {
                                Result = "PASS";
                              } else if (element.passfailstatus == 0) {
                                Result = "FAIL";
                              } else {
                                Result = " ";
                              }
                            } else if (element.typeofevent == 15) {
                              if (element.enginerunstate == 0) {
                                eventType = "ENGINE OFF";
                              } else if (element.enginerunstate == 1) {
                                eventType = "ENGINE ON";
                              } else {
                                eventType = "NA";
                              }
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 66) {
                              eventType = "HARD_BLOW";
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 21) {
                              eventType = "CAR_BATTERY_TAMPERING";
                            } else if (element.typeofevent == 19) {
                              eventType = "VIOLATION_LOCKOUT";
                            } else if (element.typeofevent == 4) {
                              eventType = "BYPASS";
                            } else if (element.typeofevent == 18) {
                              eventType = "TEST_REFUSED_RR_TEST";
                            } else if (element.typeofevent == 28) {
                              eventType = "TAMPERED";
                            } else if (element.typeofevent == 22) {
                              eventType = "CALIBRATION DONE";
                            } else if (element.typeofevent == 29) {
                              eventType = "START_TEST_ATTEMPT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 27) {
                              eventType = "COOL_SAMPLE";
                            } else if (element.typeofevent == 25) {
                              eventType = "HOT_SAMPLE";
                            } else if (element.typeofevent == 8) {
                              eventType = "ONE_TIME_PASS_CODE";
                            } else if (element.typeofevent == 6) {
                              eventType = "SERVICE_PERIOD_SET";
                            } else if (element.typeofevent == 7) {
                              eventType = "VOILATION_RESET";
                            } else if (element.typeofevent == 11) {
                              eventType = "LOG_READ";
                            } else if (element.typeofevent == 220) {
                              eventType = "LOCKOUT 0";
                            } else if (element.typeofevent == 221) {
                              eventType = "LOCKOUT 1";
                            } else if (element.typeofevent == 222) {
                              eventType = "LOCKOUT 2";
                            } else if (element.typeofevent == 223) {
                              eventType = "LOCKOUT 3";
                            } else if (element.typeofevent == 224) {
                              eventType = "LOCKOUT 4";
                            } else if (element.typeofevent == 225) {
                              eventType = "LOCKOUT 5";
                            } else if (element.typeofevent == 226) {
                              eventType = "LOCKOUT 6";
                            } else if (element.typeofevent == 227) {
                              eventType = "LOCKOUT 7";
                            } else if (element.typeofevent == 56) {
                              eventType = "TAKE_A_PICTURE";
                            } else if (element.typeofevent == 101) {
                              eventType = "PICTURE_CAPTURED";
                            } else if (element.typeofevent == 60) {
                              eventType = "BYPASS_START";
                            } else if (element.typeofevent == 61) {
                              eventType = "BYPASS_STOP";
                            } else if (element.typeofevent == 14) {
                              eventType = "SERVICE_ALERT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 62) {
                              eventType = "SAMPLER_DISCONNECTED";
                            } else if (element.typeofevent == 10) {
                              eventType = "CAR_BATTERY_OFF";
                            } else if (element.typeofevent == 20) {
                              eventType = "CAR_BATTERY_ON";
                            } else if (element.typeofevent == 217) {
                              eventType = "MEMSET";
                            } else if (element.typeofevent == 219) {
                              eventType = "TIME_SET";
                            } else if (element.typeofevent == 218) {
                              eventType = "DEVICE RESTART";
                            } else if (element.typeofevent == 151) {
                              eventType = "ALCOHOL_SENSOR_FAIL";
                            } else if (element.typeofevent == 152) {
                              eventType = "BREATH_PRESSURE_SEN_FAIL";
                            } else if (element.typeofevent == 153) {
                              eventType = "BREATH_TEMP_SEN_FAIL";
                            } else if (element.typeofevent == 154) {
                              eventType = "BMP180_ALTITUDE_FAIL";
                            } else if (element.typeofevent == 155) {
                              eventType = "PROXIMITY_SENSOR_FAIL";
                            } else if (element.typeofevent == 156) {
                              eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                            } else if (element.typeofevent == 157) {
                              eventType = "RTC_FAIL";
                            }
                            if (element.bacvalue != 255) {
                              Bacvalue = element.bacvalue;
                            }

                            var rcidlength, rtimelength, braclength;

                            if (
                              remData.indexOf(element).toString().length == 1
                            ) {
                              rcidlength = row_width + 10;
                            } else if (
                              remData.indexOf(element).toString().length == 2
                            ) {
                              rcidlength = row_width + 9;
                            } else if (
                              remData.indexOf(element).toString().length == 3
                            ) {
                              rcidlength = row_width + 8;
                            } else if (
                              remData.indexOf(element).toString().length == 4
                            ) {
                              rcidlength = row_width + 7;
                            }
                            if (weekday) {
                              if (weekday.length == 6) {
                                rtimelength = row_width + 12;
                              } else if (weekday.length == 7) {
                                rtimelength = row_width + 11;
                              } else if (weekday.length == 8) {
                                rtimelength = row_width + 10;
                              } else if (weekday.length == 9) {
                                rtimelength = row_width + 9;
                              } else if (weekday.length == 10) {
                                rtimelength = row_width + 8;
                              } else if (weekday.length == 11) {
                                rtimelength = row_width + 7;
                              }
                            }
                            if (eventType.length == 9) {
                              braclength = row_width + 12;
                            } else if (eventType.length == 10) {
                              braclength = row_width + 11;
                            } else if (eventType.length == 11) {
                              braclength = row_width + 10;
                            } else if (eventType.length == 12) {
                              braclength = row_width + 9;
                            } else if (eventType.length == 13) {
                              braclength = row_width + 8;
                            } else if (eventType.length == 14) {
                              braclength = row_width + 7;
                            } else if (eventType.length == 15) {
                              braclength = row_width + 6;
                            } else if (eventType.length == 16) {
                              braclength = row_width + 5;
                            } else if (eventType.length == 17) {
                              braclength = row_width + 4;
                            } else if (eventType.length == 18) {
                              braclength = row_width + 3;
                            } else if (eventType.length == 19) {
                              braclength = row_width + 2;
                            } else if (eventType.length == 20) {
                              braclength = row_width + 1;
                            } else if (eventType.length == 21) {
                              braclength = row_width;
                            }
                            object +=
                              rcid +
                              new Array(rcidlength).join(" ") +
                              ";" +
                              moment(element.rtime).format(
                                "MM/DD/YYYY hh:mm:ss"
                              ) +
                              new Array(row_width).join(" ") +
                              ";" +
                              weekday +
                              new Array(rtimelength).join(" ") +
                              ";" +
                              eventType +
                              new Array(braclength).join(" ") +
                              ";" +
                              Bacvalue +
                              new Array(row_width + 10).join(" ") +
                              ";" +
                              Result +
                              "\r\n";
                            rcid++;
                          }
                        }
                      });
                      resolve("success");
                    } else {
                      this.dialog10 = false;
                      this.istexasloading = false;
                      reject("No Logs Found");
                      const Swal = require("sweetalert2");
                      Swal.fire({
                        title: "No Logs Found From Device Install Date",
                        icon: "error",
                        showCloseButton: true,
                        confirmButtonText: "Ok",
                      });
                      if (isnewinstallation == 1) {
                        this.clearstorage(datas, this.firmware, 1);
                      }
                    }
                  }); //end promise
                  neProm.then(async (res) => {
                    if (res == "success") {
                      this.texasdisplay = "Downloading Images Please wait.....";
                      if (datas.state == "TX") {
                        await Promise.all(
                          this.eventscount.map((element) => {
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.controlId +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".png", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                   
                                        this.$store
                                          .dispatch("alert/error", "No Image Found")
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  this.$store
                                    .dispatch("alert/error", error)
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                });
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.controlId +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".png", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    
                                        this.$store
                                          .dispatch("alert/error", "No Image Found")
                                          .then(() => {
                                            setTimeout(() => {
                                              this.$store.dispatch(
                                                "alert/clear"
                                              );
                                            }, 2000);
                                          });
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  this.$store
                                    .dispatch("alert/error", error)
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                });
                            } else {
                              console.log("NA");
                            }
                            // }
                          })
                        );
                        var checkimagesInterval2 = setInterval(() => {
                          var completedper =
                            (updatecount / this.eventscount.length) * 100;
                          this.texasdisplay =
                            "Zipping files..... " +
                            parseInt(completedper) +
                            " %";
                          if (updatecount >= this.eventscount.length) {
                            this.confirmtexasloading = true;
                            clearInterval(checkimagesInterval2);
                            this.istexasloading = false;
                            setTimeout(() => {
                              this.confirmtexasloading = false;
                              zip.file(datas.client + ".csv", object);
                              zip
                                .generateAsync({ type: "blob" })
                                .then(function (content) {
                                  //make default
                                  saveAs(content, datas.client + ".zip");
                                  if (isnewinstallation == 1) {
                                    this.clearstorage(datas, this.firmware, 1);
                                  }
                                })
                                .catch((err) => {
                                  this.$store
                                    .dispatch("alert/error", err)
                                    .then(() => {
                                      setTimeout(() => {
                                        this.$store.dispatch("alert/clear");
                                      }, 2000);
                                    });
                                });
                              this.istexasloading = false;
                              this.imagecount = 0;
                              this.eventscount = [];
                              updatecount = 0;
                              (this.texasdisplay =
                                "Downloading data please wait....."),
                                (this.dialog10 = false);
                            }, 5000);
                          }
                        }, 2000);
                      } else {
                        // console.log(remData[0])
                        const link = document.createElement("a");
                        link.setAttribute(
                          "href",
                          "data:text/csv;charset=utf-8," +
                            encodeURIComponent(object)
                        );
                        link.setAttribute(
                          "download",
                          remData[0].controlId + ".csv"
                        );
                        link.click();

                        this.dialog10 = false;
                        if (isnewinstallation == 1) {
                          this.clearstorage(datas, this.firmware, 1);
                        }
                      }
                    }
                  });
                } else {
                  this.istexasloading = false;
                  this.$store
                    .dispatch("alert/error", "Log file empty")
                    .then(() => {
                      setTimeout(() => {
                        this.$store.dispatch("alert/clear");
                        if (isnewinstallation == 1) {
                          this.clearstorage(datas, this.firmware, 1);
                        }
                      }, 2000);
                    });
                }
              }
            } else {
              this.istexasloading = false;
              this.$store.dispatch("alert/error", "Log file empty").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                  if (isnewinstallation == 1) {
                    this.clearstorage(datas, this.firmware, 1);
                  }
                }, 2000);
              });
            }
          });
      } else {
        // sampler id based
        if (datas.state == "TX") {
          this.istexasloading = true;
          var zip = new JSZip();
          var img = zip.folder("images");
          var updatecount = 0;
        }
        Vue.axios
          .get(
            "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" + datas.devId
          )
          .then((response) => {
            if (response.data != null) {
              this.eventscount = response.data.filter((res) => {
                return res.typeofevent == "254";
              });

              var wholedata = response.data;
              this.totaleventscount = wholedata.length;
              // var sorteddata = wholedata.sort((a,b)=>b.rtime - a.rtime)
              wholedata = wholedata.sort(
                (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
              );
              var sorteddata = wholedata.reverse();
              var arrData = sorteddata;
              var today = new Date();
              var row_width = 5;
              var object;
              if (this.type == "text") {
                object =
                  "Alcobrake" +
                  new Array(row_width).join(" ") +
                  "Log Summary Report \r\n\r\nDevice Id:" +
                  datas.devId +
                  "\r\n \r\n";

                object +=
                  "LogRead Date " +
                  new Array(row_width).join(" ") +
                  today +
                  "\r\n \r\n";
                object +=
                  "Record id" +
                  new Array(row_width + 5).join(" ") +
                  "Date" +
                  new Array(row_width + 10).join(" ") +
                  "Week" +
                  new Array(row_width + 15).join(" ") +
                  "EventType" +
                  new Array(row_width + 9).join(" ") +
                  "brac" +
                  new Array(row_width + 8).join(" ") +
                  "Result \r\n";
                if (arrData != null) {
                  var rcid = 1;
                  var neProm2 = new Promise((resolve, reject) => {
                    if (arrData.length > 0) {
                      arrData.map((element) => {
                        if (element.typeofevent != "101") {
                          if (element.typeofevent != "29") {
                            var dt = new Date(element.rtime);
                            var week = [
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ];
                            var weekday = week[dt.getDay()];
                            var eventType = " ";
                            var Result = " ";
                            var Bacvalue = " ";
                            //AlcoholTest
                            if (element.typeofevent == 254) {
                              //initialtest
                              if (
                                element.randominitial == 2 ||
                                element.randominitial == 0
                              ) {
                                eventType = "INITIAL TEST";
                              } else if (
                                element.randominitial == 3 ||
                                element.randominitial == 1
                              ) {
                                eventType = "RANDOM TEST";
                              } else {
                                eventType = "NA";
                              }
                              if (element.passfailstatus == 1) {
                                Result = "PASS";
                              } else if (element.passfailstatus == 0) {
                                Result = "FAIL";
                              } else {
                                Result = " ";
                              }
                            } else if (element.typeofevent == 15) {
                              if (element.enginerunstate == 0) {
                                eventType = "ENGINE OFF";
                              } else if (element.enginerunstate == 1) {
                                eventType = "ENGINE ON";
                              } else {
                                eventType = "NA";
                              }
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 66) {
                              eventType = "HARD_BLOW";
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 21) {
                              eventType = "CAR_BATTERY_TAMPERING";
                            } else if (element.typeofevent == 19) {
                              eventType = "VOILATION_LOCKOUT";
                            } else if (element.typeofevent == 4) {
                              eventType = "BYPASS";
                            } else if (element.typeofevent == 18) {
                              eventType = "TEST_REFUSED_RR_TEST";
                            } else if (element.typeofevent == 28) {
                              eventType = "TAMPERED";
                            } else if (element.typeofevent == 22) {
                              eventType = "CALIBRATION DONE";
                            } else if (element.typeofevent == 29) {
                              eventType = "START_TEST_ATTEMPT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 27) {
                              eventType = "COOL_SAMPLE";
                            } else if (element.typeofevent == 25) {
                              eventType = "HOT_SAMPLE";
                            } else if (element.typeofevent == 8) {
                              eventType = "ONE_TIME_PASS_CODE";
                            } else if (element.typeofevent == 6) {
                              eventType = "SERVICE_PERIOD_SET";
                            } else if (element.typeofevent == 7) {
                              eventType = "VOILATION_RESET";
                            } else if (element.typeofevent == 11) {
                              eventType = "LOG_READ";
                            } else if (element.typeofevent == 220) {
                              eventType = "LOCKOUT 0";
                            } else if (element.typeofevent == 221) {
                              eventType = "LOCKOUT 1";
                            } else if (element.typeofevent == 222) {
                              eventType = "LOCKOUT 2";
                            } else if (element.typeofevent == 223) {
                              eventType = "LOCKOUT 3";
                            } else if (element.typeofevent == 224) {
                              eventType = "LOCKOUT 4";
                            } else if (element.typeofevent == 225) {
                              eventType = "LOCKOUT 5";
                            } else if (element.typeofevent == 226) {
                              eventType = "LOCKOUT 6";
                            } else if (element.typeofevent == 227) {
                              eventType = "LOCKOUT 7";
                            } else if (element.typeofevent == 56) {
                              eventType = "TAKE_A_PICTURE";
                            } else if (element.typeofevent == 101) {
                              eventType = "PICTURE_CAPTURED";
                            } else if (element.typeofevent == 60) {
                              eventType = "BYPASS_START";
                            } else if (element.typeofevent == 61) {
                              eventType = "BYPASS_STOP";
                            } else if (element.typeofevent == 14) {
                              eventType = "SERVICE_ALERT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 62) {
                              eventType = "SAMPLER_DISCONNECTED";
                            } else if (element.typeofevent == 10) {
                              eventType = "CAR_BATTERY_OFF";
                            } else if (element.typeofevent == 20) {
                              eventType = "CAR_BATTERY_ON";
                            }
                            if (element.bacvalue != 255) {
                              Bacvalue = element.bacvalue;
                            }

                            var rcidlength, rtimelength, braclength;

                            if (
                              arrData.indexOf(element).toString().length == 1
                            ) {
                              rcidlength = row_width + 10;
                            } else if (
                              arrData.indexOf(element).toString().length == 2
                            ) {
                              rcidlength = row_width + 9;
                            } else if (
                              arrData.indexOf(element).toString().length == 3
                            ) {
                              rcidlength = row_width + 8;
                            } else if (
                              arrData.indexOf(element).toString().length == 4
                            ) {
                              rcidlength = row_width + 7;
                            }

                            if (weekday.length == 6) {
                              rtimelength = row_width + 12;
                            } else if (weekday.length == 7) {
                              rtimelength = row_width + 11;
                            } else if (weekday.length == 8) {
                              rtimelength = row_width + 10;
                            } else if (weekday.length == 9) {
                              rtimelength = row_width + 9;
                            } else if (weekday.length == 10) {
                              rtimelength = row_width + 8;
                            } else if (weekday.length == 11) {
                              rtimelength = row_width + 7;
                            } else if (weekday.length == 12) {
                              rtimelength = row_width + 6;
                            } else if (weekday.length == 13) {
                              rtimelength = row_width + 5;
                            } else if (weekday.length == 14) {
                              rtimelength = row_width + 4;
                            } else if (weekday.length == 15) {
                              rtimelength = row_width + 3;
                            } else if (weekday.length == 16) {
                              rtimelength = row_width + 2;
                            } else if (weekday.length == 17) {
                              rtimelength = row_width + 1;
                            } else if (weekday.length == 18) {
                              rtimelength = row_width;
                            }

                            if (eventType.length == 9) {
                              braclength = row_width + 12;
                            } else if (eventType.length == 10) {
                              braclength = row_width + 11;
                            } else if (eventType.length == 11) {
                              braclength = row_width + 10;
                            } else if (eventType.length == 12) {
                              braclength = row_width + 9;
                            } else if (eventType.length == 13) {
                              braclength = row_width + 8;
                            } else if (eventType.length == 14) {
                              braclength = row_width + 7;
                            } else if (eventType.length == 15) {
                              braclength = row_width + 6;
                            } else if (eventType.length == 16) {
                              braclength = row_width + 5;
                            } else if (eventType.length == 17) {
                              braclength = row_width + 4;
                            } else if (eventType.length == 18) {
                              braclength = row_width + 3;
                            } else if (eventType.length == 19) {
                              braclength = row_width;
                            } else if (eventType.length == 20) {
                              braclength = row_width;
                            } else if (eventType.length == 21) {
                              braclength = row_width;
                            }

                            object +=
                              rcid +
                              new Array(rcidlength).join(" ") +
                              element.rtime +
                              new Array(row_width).join(" ") +
                              weekday +
                              new Array(rtimelength).join(" ") +
                              eventType +
                              new Array(braclength).join(" ") +
                              Bacvalue +
                              new Array(row_width + 10).join(" ") +
                              Result +
                              "\r\n";
                            rcid++;
                          }
                        }
                      });
                      resolve("success");
                    } else {
                      reject("No Logs Found");
                    }
                  }); //end promise
                  neProm2.then(async (res) => {
                    if (res == "success") {
                      this.texasdisplay = "Downloading Images Please wait.....";
                      if (datas.state == "TX") {
                        await Promise.all(
                          this.eventscount.map((element) => {
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              // console.log(smname);
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.devid +
                                    "&Image=" +
                                   itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".jpg", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    updatecount++;
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                });
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              // console.log(element);
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              // console.log(smname);
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.devid +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    img.file(smname + ".jpg", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    updatecount++;
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                });
                            } else {
                              console.log("NA");
                            }
                            // }
                          })
                        );
                        var checkimagesInterval = setInterval(() => {
                          var completedper =
                            (updatecount / this.eventscount.length) * 100;
                          this.texasdisplay =
                            "Zipping files..... " +
                            parseInt(completedper) +
                            " %";
                          if (updatecount >= this.eventscount.length) {
                            setTimeout(() => {
                              this.confirmtexasloading = true;
                              clearInterval(checkimagesInterval);
                              this.istexasloading = false;
                              setTimeout(() => {
                                this.confirmtexasloading = false;
                                zip.file(datas.client + ".txt", object);
                                zip
                                  .generateAsync({ type: "blob" })
                                  .then(function (content) {
                                    //make default
                                    saveAs(content, datas.client + ".zip");
                                    if (isnewinstallation == 1) {
                                      this.clearstorage(
                                        datas,
                                        this.firmware,
                                        1
                                      );
                                    }
                                  });
                                this.imagecount = 0;
                                this.eventscount = [];
                                updatecount = 0;
                                (this.texasdisplay =
                                  "Downloading data please wait....."),
                                  (this.dialog10 = false);
                              }, 5000);
                            }, 2000);
                          }
                        }, 2000);
                      } else {
                        var element = document.createElement("a");
                        element.setAttribute(
                          "href",
                          "data:text/plain;charset=utf-8," +
                            encodeURIComponent(object)
                        );
                        element.setAttribute("download", datas.client+"_"+datas.cbid + "_log");
                        element.style.display = "none";
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        this.dialog10 = false;
                        if (isnewinstallation == 1) {
                          this.clearstorage(datas, this.firmware, 1);
                        }
                      }
                    }
                  });
                } else {
                  this.istexasloading = false;
                  this.$store.dispatch("alert/error", "Log file empty");
                  if (isnewinstallation == 1) {
                    this.clearstorage(datas, this.firmware, 1);
                  }
                }
              } else {
                //CSV data
                this.texasdisplay = "Preparing Logs please wait.....";
                object =
                  "Alcobrake" +
                  new Array(row_width).join(" ") +
                  "Log Summary Report \r\n\r\nDevice Id:" +
                  datas.cbid +
                  "\r\n \r\n";
                object +=
                  "LogRead Date " +
                  new Array(row_width).join(" ") +
                  today +
                  "\r\n \r\n";
                object +=
                  "Record id" +
                  new Array(row_width + 5).join(" ") +
                  ";" +
                  "Date" +
                  new Array(row_width + 10).join(" ") +
                  ";" +
                  "Week" +
                  new Array(row_width + 15).join(" ") +
                  ";" +
                  "EventType" +
                  new Array(row_width + 9).join(" ") +
                  ";" +
                  "brac" +
                  new Array(row_width + 8).join(" ") +
                  ";" +
                  "Result \r\n";
                if (arrData != null) {
                  rcid = 1;
                  var neProm = new Promise((resolve, reject) => {
                    if (arrData.length > 0) {
                      arrData.map((element) => {
                        if (element.typeofevent != "101") {
                          if (element.typeofevent != "29") {
                            var dt = new Date(element.rtime);
                            var week = [
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ];
                            var weekday = week[dt.getDay()];
                            var eventType = " ";
                            var Result = " ";
                            var Bacvalue = " ";
                            //AlcoholTest
                            if (element.typeofevent == 254) {
                              //initialtest
                              if (
                                element.randominitial == 2 ||
                                element.randominitial == 0
                              ) {
                                eventType = "INITIAL TEST";
                              } else if (
                                element.randominitial == 3 ||
                                element.randominitial == 1
                              ) {
                                eventType = "RANDOM TEST";
                              } else {
                                eventType = "NA";
                              }
                              if (element.passfailstatus == 1) {
                                Result = "PASS";
                              } else if (element.passfailstatus == 0) {
                                Result = "FAIL";
                              } else {
                                Result = " ";
                              }
                            } else if (element.typeofevent == 15) {
                              if (element.enginerunstate == 0) {
                                eventType = "ENGINE OFF";
                              } else if (element.enginerunstate == 1) {
                                eventType = "ENGINE ON";
                              } else {
                                eventType = "NA";
                              }
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 66) {
                              eventType = "HARD_BLOW";
                            } else if (element.typeofevent == 65) {
                              eventType = "INSUFFICIENTVOLUME";
                            } else if (element.typeofevent == 21) {
                              eventType = "CAR_BATTERY_TAMPERING";
                            } else if (element.typeofevent == 19) {
                              eventType = "VOILATION_LOCKOUT";
                            } else if (element.typeofevent == 4) {
                              eventType = "BYPASS";
                            } else if (element.typeofevent == 18) {
                              eventType = "TEST_REFUSED_RR_TEST";
                            } else if (element.typeofevent == 28) {
                              eventType = "TAMPERED";
                            } else if (element.typeofevent == 22) {
                              eventType = "CALIBRATION DONE";
                            } else if (element.typeofevent == 29) {
                              eventType = "START_TEST_ATTEMPT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 27) {
                              eventType = "COOL_SAMPLE";
                            } else if (element.typeofevent == 25) {
                              eventType = "HOT_SAMPLE";
                            } else if (element.typeofevent == 8) {
                              eventType = "ONE_TIME_PASS_CODE";
                            } else if (element.typeofevent == 6) {
                              eventType = "SERVICE_PERIOD_SET";
                            } else if (element.typeofevent == 7) {
                              eventType = "VOILATION_RESET";
                            } else if (element.typeofevent == 11) {
                              eventType = "LOG_READ";
                            } else if (element.typeofevent == 220) {
                              eventType = "LOCKOUT 0";
                            } else if (element.typeofevent == 221) {
                              eventType = "LOCKOUT 1";
                            } else if (element.typeofevent == 222) {
                              eventType = "LOCKOUT 2";
                            } else if (element.typeofevent == 223) {
                              eventType = "LOCKOUT 3";
                            } else if (element.typeofevent == 224) {
                              eventType = "LOCKOUT 4";
                            } else if (element.typeofevent == 225) {
                              eventType = "LOCKOUT 5";
                            } else if (element.typeofevent == 226) {
                              eventType = "LOCKOUT 6";
                            } else if (element.typeofevent == 227) {
                              eventType = "LOCKOUT 7";
                            } else if (element.typeofevent == 56) {
                              eventType = "TAKE_A_PICTURE";
                            } else if (element.typeofevent == 101) {
                              eventType = "PICTURE_CAPTURED";
                            } else if (element.typeofevent == 60) {
                              eventType = "BYPASS_START";
                            } else if (element.typeofevent == 61) {
                              eventType = "BYPASS_STOP";
                            } else if (element.typeofevent == 14) {
                              eventType = "SERVICE_ALERT";
                            } else if (element.typeofevent == 34) {
                              eventType = "ENGINE_NOT_STARTED";
                            } else if (element.typeofevent == 62) {
                              eventType = "SAMPLER_DISCONNECTED";
                            } else if (element.typeofevent == 10) {
                              eventType = "CAR_BATTERY_OFF";
                            } else if (element.typeofevent == 20) {
                              eventType = "CAR_BATTERY_ON";
                            }
                            if (element.bacvalue != 255) {
                              Bacvalue = element.bacvalue;
                            }

                            var rcidlength, rtimelength, braclength;

                            if (
                              arrData.indexOf(element).toString().length == 1
                            ) {
                              rcidlength = row_width + 10;
                            } else if (
                              arrData.indexOf(element).toString().length == 2
                            ) {
                              rcidlength = row_width + 9;
                            } else if (
                              arrData.indexOf(element).toString().length == 3
                            ) {
                              rcidlength = row_width + 8;
                            } else if (
                              arrData.indexOf(element).toString().length == 4
                            ) {
                              rcidlength = row_width + 7;
                            }

                            if (weekday.length == 6) {
                              rtimelength = row_width + 12;
                            } else if (weekday.length == 7) {
                              rtimelength = row_width + 11;
                            } else if (weekday.length == 8) {
                              rtimelength = row_width + 10;
                            } else if (weekday.length == 9) {
                              rtimelength = row_width + 9;
                            } else if (weekday.length == 10) {
                              rtimelength = row_width + 8;
                            } else if (weekday.length == 11) {
                              rtimelength = row_width + 7;
                            }

                            if (eventType.length == 9) {
                              braclength = row_width + 12;
                            } else if (eventType.length == 10) {
                              braclength = row_width + 11;
                            } else if (eventType.length == 11) {
                              braclength = row_width + 10;
                            } else if (eventType.length == 12) {
                              braclength = row_width + 9;
                            } else if (eventType.length == 13) {
                              braclength = row_width + 8;
                            } else if (eventType.length == 14) {
                              braclength = row_width + 7;
                            } else if (eventType.length == 15) {
                              braclength = row_width + 6;
                            } else if (eventType.length == 16) {
                              braclength = row_width + 5;
                            } else if (eventType.length == 17) {
                              braclength = row_width + 4;
                            } else if (eventType.length == 18) {
                              braclength = row_width + 3;
                            } else if (eventType.length == 19) {
                              braclength = row_width + 2;
                            } else if (eventType.length == 20) {
                              braclength = row_width + 1;
                            } else if (eventType.length == 21) {
                              braclength = row_width;
                            }
                            object +=
                              rcid +
                              new Array(rcidlength).join(" ") +
                              ";" +
                              element.rtime +
                              new Array(row_width).join(" ") +
                              ";" +
                              weekday +
                              new Array(rtimelength).join(" ") +
                              ";" +
                              eventType +
                              new Array(braclength).join(" ") +
                              ";" +
                              Bacvalue +
                              new Array(row_width + 10).join(" ") +
                              ";" +
                              Result +
                              "\r\n";
                            rcid++;
                          }
                        }
                      });
                      resolve("success");
                    } else {
                      reject("No Logs Found");
                    }
                  }); //end promise
                  neProm.then(async (res) => {
                    if (res == "success") {
                      this.texasdisplay = "Downloading Images Please wait.....";
                      if (datas.state == "TX") {
                        await Promise.all(
                          this.eventscount.map((element) => {
                            if (
                              element.randominitial == 2 ||
                              element.randominitial == 0
                            ) {
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              // console.log(smname);
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.devid +
                                    "&Image=" +
                                    itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    // console.log(res)
                                    img.file(smname + ".jpg", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    updatecount++;
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  console.log("Network Error:" + error);
                                });
                            } else if (
                              element.randominitial == 3 ||
                              element.randominitial == 1
                            ) {
                              // console.log(element);
                              this.istexasloading = true;
                              var itemname = element.rtime;
                              var stname = itemname.replaceAll("/", "_");
                              var ssname = stname.replaceAll(" ", "_");
                              var smname = ssname.replaceAll(":", "_");
                              // console.log(smname);
                              Vue.axios
                                .get(
                                  "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                    element.devid +
                                    "&Image=" +
                                   itemname
                                )
                                .then((res) => {
                                  if (res.data) {
                                    // console.log(res)
                                    img.file(smname + ".jpg", res.data, {
                                      base64: true,
                                    });
                                    this.imagecount++;
                                    updatecount++;
                                  } else {
                                    updatecount++;
                                  }
                                })
                                .catch(function (error) {
                                  updatecount++;
                                  console.log("Network Error:" + error);
                                });
                            }
                          })
                        );
                        var checkimagesInterval = setInterval(() => {
                          var completedper =
                            (updatecount / this.eventscount.length) * 100;
                          this.texasdisplay =
                            "Zipping files..... " +
                            parseInt(completedper) +
                            " %";
                          if (updatecount >= this.eventscount.length) {
                            this.confirmtexasloading = true;
                            clearInterval(checkimagesInterval);
                            this.istexasloading = false;
                            setTimeout(() => {
                              this.confirmtexasloading = false;
                              zip.file(datas.client + ".csv", object);
                              zip
                                .generateAsync({ type: "blob" })
                                .then(function (content) {
                                  //make default
                                  saveAs(content, datas.client + ".zip");
                                  if (isnewinstallation == 1) {
                                    this.clearstorage(datas, this.firmware, 1);
                                  }
                                });
                              this.imagecount = 0;
                              this.eventscount = [];
                              updatecount = 0;
                              (this.texasdisplay =
                                "Downloading data please wait....."),
                                (this.dialog10 = false);
                            }, 5000);
                          }
                        }, 2000);
                      } else {
                        const link = document.createElement("a");
                        link.setAttribute(
                          "href",
                          "data:text/csv;charset=utf-8," +
                            encodeURIComponent(object)
                        );
                        link.setAttribute(
                          "download",
                          arrData[0].devid + ".csv"
                        );
                        link.click();

                        this.dialog10 = false;
                        if (isnewinstallation == 1) {
                          this.clearstorage(datas, this.firmware, 1);
                        }
                      }
                    }
                  });
                } else {
                  this.istexasloading = false;
                  this.$store.dispatch("alert/error", "Log file empty");
                  if (isnewinstallation == 1) {
                    this.clearstorage(datas, this.firmware, 1);
                  }
                }
              }
            } else {
              this.istexasloading = false;
              this.$store.dispatch("alert/error", "Log file empty");
              if (isnewinstallation == 1) {
                this.clearstorage(datas, this.firmware, 1);
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err);
          });
      }
    },
    removelsdownloadItem(datas) {
      Vue.axios
        .get(
          "https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid=" +
            datas.cbid
        )
        .then((response) => {
          if (response.data != null) {
            // console.log(response.data)
            var firmwaredata = response.data;
            firmwaredata = firmwaredata.sort(
              (a, b) =>
                moment(a.updatedtime).valueOf() -
                moment(b.updatedtime).valueOf()
            );
            if (datas.state == "TX") {
              this.istexasloading = true;
              var zip = new JSZip();
              var img = zip.folder("images");
              var updatecount = 0;
            }
            var rcid = 1;
            if (
              this.oledDevices.filter((value) => value.sno == datas.sno).length ==
              0
            ) {
              Vue.axios
                .get(
                  "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" +
                    datas.cbid
                )
                .then((response) => {
                  if (response.data != null) {
                    // console.log("cbid based")
                    var remData = response.data;
                    // .filter((r)=>{
                    //   return (r.rtime >= datas.installDate && r.rtime < moment(new Date()).add(1, 'days').format("MM/DD/YYYY HH:mm:ss"))
                    // })
                    // console.log(remData)
                    this.eventscount = response.data.filter((res) => {
                      return res.typeofevent == "254";
                    });

                    var wholedata = response.data;
                    this.totaleventscount = wholedata.length;
                    wholedata = wholedata.sort(
                      (a, b) =>
                        moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                    );
                    if (this.user.role == "superadmin") {
                      remData = remData.filter((o) => o.typeofevent !== "0");
                    } else {
                      remData = remData.filter(
                        (o) =>
                          o.typeofevent !== "0" &&
                          o.typeofevent !== "151" &&
                          o.typeofevent !== "152" &&
                          o.typeofevent !== "153" &&
                          o.typeofevent !== "154" &&
                          o.typeofevent !== "155" &&
                          o.typeofevent !== "156" &&
                          o.typeofevent !== "157" &&
                          o.typeofevent !== "218" &&
                          o.typeofevent !== "219"
                      );
                    }
                    var sorteddata = wholedata.reverse();
                    var arrData = sorteddata;
                    arrData.push(remData);
                    var today = new Date();
                    var row_width = 5;
                    var object;
                    //CSV data
                    this.texasdisplay = "Preparing Logs please wait.....";
                    object =
                      "Alcobrake" +
                      new Array(row_width).join(" ") +
                      "Log Summary Report \r\n\r\nDevice Id:" +
                      datas.cbid +
                      // +"\r\n \r\n";
                      new Array(row_width).join(" ") +
                      "Device Installed On :" +
                      datas.installDate +
                      "\r\n \r\n";
                    object +=
                      "LogRead Date " +
                      new Array(row_width).join(" ") +
                      today +
                      "\r\n \r\n";
                    object +=
                      "Record id" +
                      new Array(row_width + 5).join(" ") +
                      ";" +
                      "Date" +
                      new Array(row_width + 10).join(" ") +
                      ";" +
                      "Week" +
                      new Array(row_width + 15).join(" ") +
                      ";" +
                      "EventType" +
                      new Array(row_width + 9).join(" ") +
                      ";" +
                      "brac" +
                      new Array(row_width + 8).join(" ") +
                      ";" +
                      "Result \r\n";
                    if (remData != null) {
                      rcid = 1;
                      var neProm = new Promise((resolve, reject) => {
                        if (remData.length > 0) {
                          remData.map((element) => {
                            // console.log(element)
                            if (element.typeofevent != "101") {
                              if (element.typeofevent != "29") {
                                var dt = new Date(element.rtime);
                                var week = [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ];
                                var weekday = week[dt.getDay()];
                                var eventType = " ";
                                var Result = " ";
                                var Bacvalue = " ";
                                //AlcoholTest
                                if (element.typeofevent == 254) {
                                  //initialtest
                                  if (
                                    element.randominitial == 2 ||
                                    element.randominitial == 0
                                  ) {
                                    eventType = "INITIAL TEST";
                                  } else if (
                                    element.randominitial == 3 ||
                                    element.randominitial == 1
                                  ) {
                                    eventType = "RANDOM TEST";
                                  } else {
                                    eventType = "NA";
                                  }
                                  if (element.passfailstatus == 1) {
                                    Result = "PASS";
                                  } else if (element.passfailstatus == 0) {
                                    Result = "FAIL";
                                  } else {
                                    Result = " ";
                                  }
                                } else if (element.typeofevent == 15) {
                                  if (element.enginerunstate == 0) {
                                    eventType = "ENGINE OFF";
                                  } else if (element.enginerunstate == 1) {
                                    eventType = "ENGINE ON";
                                  } else {
                                    eventType = "NA";
                                  }
                                } else if (element.typeofevent == 65) {
                                  eventType = "INSUFFICIENTVOLUME";
                                } else if (element.typeofevent == 66) {
                                  eventType = "HARD_BLOW";
                                } else if (element.typeofevent == 65) {
                                  eventType = "INSUFFICIENTVOLUME";
                                } else if (element.typeofevent == 21) {
                                  eventType = "CAR_BATTERY_TAMPERING";
                                } else if (element.typeofevent == 19) {
                                  eventType = "VIOLATION_LOCKOUT";
                                } else if (element.typeofevent == 4) {
                                  eventType = "BYPASS";
                                } else if (element.typeofevent == 18) {
                                  eventType = "TEST_REFUSED_RR_TEST";
                                } else if (element.typeofevent == 28) {
                                  eventType = "TAMPERED";
                                } else if (element.typeofevent == 22) {
                                  eventType = "CALIBRATION DONE";
                                } else if (element.typeofevent == 29) {
                                  eventType = "START_TEST_ATTEMPT";
                                } else if (element.typeofevent == 34) {
                                  eventType = "ENGINE_NOT_STARTED";
                                } else if (element.typeofevent == 27) {
                                  eventType = "COOL_SAMPLE";
                                } else if (element.typeofevent == 25) {
                                  eventType = "HOT_SAMPLE";
                                } else if (element.typeofevent == 8) {
                                  eventType = "ONE_TIME_PASS_CODE";
                                } else if (element.typeofevent == 6) {
                                  eventType = "SERVICE_PERIOD_SET";
                                } else if (element.typeofevent == 7) {
                                  eventType = "VOILATION_RESET";
                                } else if (element.typeofevent == 11) {
                                  eventType = "LOG_READ";
                                } else if (element.typeofevent == 220) {
                                  eventType = "LOCKOUT 0";
                                } else if (element.typeofevent == 221) {
                                  eventType = "LOCKOUT 1";
                                } else if (element.typeofevent == 222) {
                                  eventType = "LOCKOUT 2";
                                } else if (element.typeofevent == 223) {
                                  eventType = "LOCKOUT 3";
                                } else if (element.typeofevent == 224) {
                                  eventType = "LOCKOUT 4";
                                } else if (element.typeofevent == 225) {
                                  eventType = "LOCKOUT 5";
                                } else if (element.typeofevent == 226) {
                                  eventType = "LOCKOUT 6";
                                } else if (element.typeofevent == 227) {
                                  eventType = "LOCKOUT 7";
                                } else if (element.typeofevent == 56) {
                                  eventType = "TAKE_A_PICTURE";
                                } else if (element.typeofevent == 101) {
                                  eventType = "PICTURE_CAPTURED";
                                } else if (element.typeofevent == 60) {
                                  eventType = "BYPASS_START";
                                } else if (element.typeofevent == 61) {
                                  eventType = "BYPASS_STOP";
                                } else if (element.typeofevent == 14) {
                                  eventType = "SERVICE_ALERT";
                                } else if (element.typeofevent == 34) {
                                  eventType = "ENGINE_NOT_STARTED";
                                } else if (element.typeofevent == 62) {
                                  eventType = "SAMPLER_DISCONNECTED";
                                } else if (element.typeofevent == 10) {
                                  eventType = "CAR_BATTERY_OFF";
                                } else if (element.typeofevent == 20) {
                                  eventType = "CAR_BATTERY_ON";
                                } else if (element.typeofevent == 217) {
                                  eventType = "MEMSET";
                                } else if (element.typeofevent == 219) {
                                  eventType = "TIME_SET";
                                } else if (element.typeofevent == 218) {
                                  eventType = "DEVICE RESTART";
                                } else if (element.typeofevent == 151) {
                                  eventType = "ALCOHOL_SENSOR_FAIL";
                                } else if (element.typeofevent == 152) {
                                  eventType = "BREATH_PRESSURE_SEN_FAIL";
                                } else if (element.typeofevent == 153) {
                                  eventType = "BREATH_TEMP_SEN_FAIL";
                                } else if (element.typeofevent == 154) {
                                  eventType = "BMP180_ALTITUDE_FAIL";
                                } else if (element.typeofevent == 155) {
                                  eventType = "PROXIMITY_SENSOR_FAIL";
                                } else if (element.typeofevent == 156) {
                                  eventType = "TMP75_AMB_TEMP_SENS_FAIL";
                                } else if (element.typeofevent == 157) {
                                  eventType = "RTC_FAIL";
                                }
                                if (element.bacvalue != 255) {
                                  Bacvalue = element.bacvalue;
                                }

                                var rcidlength, rtimelength, braclength;

                                if (
                                  remData.indexOf(element).toString().length ==
                                  1
                                ) {
                                  rcidlength = row_width + 10;
                                } else if (
                                  remData.indexOf(element).toString().length ==
                                  2
                                ) {
                                  rcidlength = row_width + 9;
                                } else if (
                                  remData.indexOf(element).toString().length ==
                                  3
                                ) {
                                  rcidlength = row_width + 8;
                                } else if (
                                  remData.indexOf(element).toString().length ==
                                  4
                                ) {
                                  rcidlength = row_width + 7;
                                }
                                if (weekday) {
                                  if (weekday.length == 6) {
                                    rtimelength = row_width + 12;
                                  } else if (weekday.length == 7) {
                                    rtimelength = row_width + 11;
                                  } else if (weekday.length == 8) {
                                    rtimelength = row_width + 10;
                                  } else if (weekday.length == 9) {
                                    rtimelength = row_width + 9;
                                  } else if (weekday.length == 10) {
                                    rtimelength = row_width + 8;
                                  } else if (weekday.length == 11) {
                                    rtimelength = row_width + 7;
                                  }
                                }
                                if (eventType.length == 9) {
                                  braclength = row_width + 12;
                                } else if (eventType.length == 10) {
                                  braclength = row_width + 11;
                                } else if (eventType.length == 11) {
                                  braclength = row_width + 10;
                                } else if (eventType.length == 12) {
                                  braclength = row_width + 9;
                                } else if (eventType.length == 13) {
                                  braclength = row_width + 8;
                                } else if (eventType.length == 14) {
                                  braclength = row_width + 7;
                                } else if (eventType.length == 15) {
                                  braclength = row_width + 6;
                                } else if (eventType.length == 16) {
                                  braclength = row_width + 5;
                                } else if (eventType.length == 17) {
                                  braclength = row_width + 4;
                                } else if (eventType.length == 18) {
                                  braclength = row_width + 3;
                                } else if (eventType.length == 19) {
                                  braclength = row_width + 2;
                                } else if (eventType.length == 20) {
                                  braclength = row_width + 1;
                                } else if (eventType.length == 21) {
                                  braclength = row_width;
                                }
                                object +=
                                  rcid +
                                  new Array(rcidlength).join(" ") +
                                  ";" +
                                  moment(element.rtime).format(
                                    "MM/DD/YYYY hh:mm:ss"
                                  ) +
                                  new Array(row_width).join(" ") +
                                  ";" +
                                  weekday +
                                  new Array(rtimelength).join(" ") +
                                  ";" +
                                  eventType +
                                  new Array(braclength).join(" ") +
                                  ";" +
                                  Bacvalue +
                                  new Array(row_width + 10).join(" ") +
                                  ";" +
                                  Result +
                                  "\r\n";
                                rcid++;
                              }
                            }
                          });
                          resolve("success");
                        } else {
                          this.dialog10 = false;
                          this.istexasloading = false;
                          reject("No Logs Found");
                          const Swal = require("sweetalert2");
                          Swal.fire({
                            title: "No Logs Found From Device Install Date",
                            icon: "error",
                            showCloseButton: true,
                            confirmButtonText: "Ok",
                          });
                        }
                      }); //end promise
                      neProm.then(async (res) => {
                        if (res == "success") {
                          this.texasdisplay =
                            "Downloading Images Please wait.....";
                          if (datas.state == "TX") {
                            await Promise.all(
                              this.eventscount.map((element) => {
                                if (
                                  element.randominitial == 2 ||
                                  element.randominitial == 0
                                ) {
                                  this.istexasloading = true;
                                  var itemname = element.rtime;
                                  var stname = itemname.replaceAll("/", "_");
                                  var ssname = stname.replaceAll(" ", "_");
                                  var smname = ssname.replaceAll(":", "_");
                                  // console.log(smname);
                                  Vue.axios
                                    .get(
                                      "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                        element.controlId +
                                        "&Image=" +
                                        itemname
                                    )
                                    .then((res) => {
                                      if (res.data) {
                                        img.file(smname + ".png", res.data, {
                                          base64: true,
                                        });
                                        this.imagecount++;
                                        updatecount++;
                                      } else {
                                      
                                            this.$store
                                              .dispatch("alert/error", "No Image Found")
                                              .then(() => {
                                                setTimeout(() => {
                                                  this.$store.dispatch(
                                                    "alert/clear"
                                                  );
                                                }, 2000);
                                              });
                                      }
                                    })
                                    .catch(function (error) {
                                      updatecount++;
                                      this.$store
                                        .dispatch("alert/error", error)
                                        .then(() => {
                                          setTimeout(() => {
                                            this.$store.dispatch("alert/clear");
                                          }, 2000);
                                        });
                                    });
                                } else if (
                                  element.randominitial == 3 ||
                                  element.randominitial == 1
                                ) {
                                  // console.log(element);
                                  this.istexasloading = true;
                                  var itemname = element.rtime;
                                  var stname = itemname.replaceAll("/", "_");
                                  var ssname = stname.replaceAll(" ", "_");
                                  var smname = ssname.replaceAll(":", "_");
                                  // console.log(smname);
                                  Vue.axios
                                    .get(
                                      "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                        element.controlId +
                                        "&Image=" +
                                        itemname
                                    )
                                    .then((res) => {
                                      if (res.data) {
                                        img.file(smname + ".png", res.data, {
                                          base64: true,
                                        });
                                        this.imagecount++;
                                        updatecount++;
                                      } else {
                                                                                   this.$store
                                              .dispatch("alert/error", error)
                                              .then(() => {
                                                setTimeout(() => {
                                                  this.$store.dispatch(
                                                    "alert/clear"
                                                  );
                                                }, 2000);
                                              });
                                      }
                                    })
                                    .catch(function (error) {
                                      updatecount++;
                                      this.$store
                                        .dispatch("alert/error", error)
                                        .then(() => {
                                          setTimeout(() => {
                                            this.$store.dispatch("alert/clear");
                                          }, 2000);
                                        });
                                    });
                                } else {
                                  console.log("NA");
                                }
                                // }
                              })
                            );
                            var checkimagesInterval = setInterval(() => {
                              var completedper =
                                (updatecount / this.eventscount.length) * 100;
                              this.texasdisplay =
                                "Zipping files..... " +
                                parseInt(completedper) +
                                " %";
                              if (updatecount >= this.eventscount.length) {
                                this.confirmtexasloading = true;
                                clearInterval(checkimagesInterval);
                                this.istexasloading = false;
                                setTimeout(() => {
                                  this.confirmtexasloading = false;
                                  zip.file(datas.client + ".csv", object);
                                  zip
                                    .generateAsync({ type: "blob" })
                                    .then(function (content) {
                                      //make default
                                      saveAs(content, datas.client + ".zip");
                                    })
                                    .catch((err) => {
                                      this.$store
                                        .dispatch("alert/error", err)
                                        .then(() => {
                                          setTimeout(() => {
                                            this.$store.dispatch("alert/clear");
                                          }, 2000);
                                        });
                                    });
                                  this.istexasloading = false;
                                  this.imagecount = 0;
                                  this.eventscount = [];
                                  updatecount = 0;
                                  (this.texasdisplay =
                                    "Downloading data please wait....."),
                                    (this.dialog10 = false);
                                }, 5000);
                              }
                            }, 2000);
                          } else {
                            const link = document.createElement("a");
                            link.setAttribute(
                              "href",
                              "data:text/csv;charset=utf-8," +
                                encodeURIComponent(object)
                            );
                            link.setAttribute(
                              "download",
                              remData[0].controlId + ".csv"
                            );
                            link.click();

                            this.dialog10 = false;
                          }
                        }
                      });
                    } else {
                      this.istexasloading = false;
                      this.$store
                        .dispatch("alert/error", "Log file empty")
                        .then(() => {
                          setTimeout(() => {
                            this.$store.dispatch("alert/clear");
                          }, 2000);
                        });
                    }
                  } else {
                    this.istexasloading = false;
                    this.$store
                      .dispatch("alert/error", "Log file empty")
                      .then(() => {
                        setTimeout(() => {
                          this.$store.dispatch("alert/clear");
                        }, 2000);
                      });
                  }
                });
            } else {
              // sampler id based
              if (datas.state == "TX") {
                this.istexasloading = true;
                var zip = new JSZip();
                var img = zip.folder("images");
                var updatecount = 0;
              }
              Vue.axios
                .get(
                  "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                    datas.devId
                )
                .then((response) => {
                  if (response.data != null) {
                    this.eventscount = response.data.filter((res) => {
                      return res.typeofevent == "254";
                    });

                    var wholedata = response.data;
                    this.totaleventscount = wholedata.length;
                    // var sorteddata = wholedata.sort((a,b)=>b.rtime - a.rtime)
                    wholedata = wholedata.sort(
                      (a, b) =>
                        moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
                    );
                    var sorteddata = wholedata.reverse();
                    var arrData = sorteddata;
                    // console.log(arrData)
                    var today = new Date();
                    var row_width = 5;
                    var object;
                    //CSV data
                    this.texasdisplay = "Preparing Logs please wait.....";
                    object =
                      "Alcobrake" +
                      new Array(row_width).join(" ") +
                      "Log Summary Report \r\n\r\nDevice Id:" +
                      datas.device +
                      "\r\n \r\n";
                    object +=
                      "LogRead Date " +
                      new Array(row_width).join(" ") +
                      today +
                      "\r\n \r\n";
                    object +=
                      "Record id" +
                      new Array(row_width + 5).join(" ") +
                      ";" +
                      "Date" +
                      new Array(row_width + 10).join(" ") +
                      ";" +
                      "Week" +
                      new Array(row_width + 15).join(" ") +
                      ";" +
                      "EventType" +
                      new Array(row_width + 9).join(" ") +
                      ";" +
                      "brac" +
                      new Array(row_width + 8).join(" ") +
                      ";" +
                      "Result \r\n";
                    if (arrData != null) {
                      rcid = 1;
                      var neProm = new Promise((resolve, reject) => {
                        if (arrData.length > 0) {
                          arrData.map((element) => {
                            if (element.typeofevent != "101") {
                              if (element.typeofevent != "29") {
                                var dt = new Date(element.rtime);
                                var week = [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ];
                                var weekday = week[dt.getDay()];
                                var eventType = " ";
                                var Result = " ";
                                var Bacvalue = " ";
                                //AlcoholTest
                                if (element.typeofevent == 254) {
                                  //initialtest
                                  if (
                                    element.randominitial == 2 ||
                                    element.randominitial == 0
                                  ) {
                                    eventType = "INITIAL TEST";
                                  } else if (
                                    element.randominitial == 3 ||
                                    element.randominitial == 1
                                  ) {
                                    eventType = "RANDOM TEST";
                                  } else {
                                    eventType = "NA";
                                  }
                                  if (element.passfailstatus == 1) {
                                    Result = "PASS";
                                  } else if (element.passfailstatus == 0) {
                                    Result = "FAIL";
                                  } else {
                                    Result = " ";
                                  }
                                } else if (element.typeofevent == 15) {
                                  if (element.enginerunstate == 0) {
                                    eventType = "ENGINE OFF";
                                  } else if (element.enginerunstate == 1) {
                                    eventType = "ENGINE ON";
                                  } else {
                                    eventType = "NA";
                                  }
                                } else if (element.typeofevent == 65) {
                                  eventType = "INSUFFICIENTVOLUME";
                                } else if (element.typeofevent == 66) {
                                  eventType = "HARD_BLOW";
                                } else if (element.typeofevent == 65) {
                                  eventType = "INSUFFICIENTVOLUME";
                                } else if (element.typeofevent == 21) {
                                  eventType = "CAR_BATTERY_TAMPERING";
                                } else if (element.typeofevent == 19) {
                                  eventType = "VOILATION_LOCKOUT";
                                } else if (element.typeofevent == 4) {
                                  eventType = "BYPASS";
                                } else if (element.typeofevent == 18) {
                                  eventType = "TEST_REFUSED_RR_TEST";
                                } else if (element.typeofevent == 28) {
                                  eventType = "TAMPERED";
                                } else if (element.typeofevent == 22) {
                                  eventType = "CALIBRATION DONE";
                                } else if (element.typeofevent == 29) {
                                  eventType = "START_TEST_ATTEMPT";
                                } else if (element.typeofevent == 34) {
                                  eventType = "ENGINE_NOT_STARTED";
                                } else if (element.typeofevent == 27) {
                                  eventType = "COOL_SAMPLE";
                                } else if (element.typeofevent == 25) {
                                  eventType = "HOT_SAMPLE";
                                } else if (element.typeofevent == 8) {
                                  eventType = "ONE_TIME_PASS_CODE";
                                } else if (element.typeofevent == 6) {
                                  eventType = "SERVICE_PERIOD_SET";
                                } else if (element.typeofevent == 7) {
                                  eventType = "VOILATION_RESET";
                                } else if (element.typeofevent == 11) {
                                  eventType = "LOG_READ";
                                } else if (element.typeofevent == 220) {
                                  eventType = "LOCKOUT 0";
                                } else if (element.typeofevent == 221) {
                                  eventType = "LOCKOUT 1";
                                } else if (element.typeofevent == 222) {
                                  eventType = "LOCKOUT 2";
                                } else if (element.typeofevent == 223) {
                                  eventType = "LOCKOUT 3";
                                } else if (element.typeofevent == 224) {
                                  eventType = "LOCKOUT 4";
                                } else if (element.typeofevent == 225) {
                                  eventType = "LOCKOUT 5";
                                } else if (element.typeofevent == 226) {
                                  eventType = "LOCKOUT 6";
                                } else if (element.typeofevent == 227) {
                                  eventType = "LOCKOUT 7";
                                } else if (element.typeofevent == 56) {
                                  eventType = "TAKE_A_PICTURE";
                                } else if (element.typeofevent == 101) {
                                  eventType = "PICTURE_CAPTURED";
                                } else if (element.typeofevent == 60) {
                                  eventType = "BYPASS_START";
                                } else if (element.typeofevent == 61) {
                                  eventType = "BYPASS_STOP";
                                } else if (element.typeofevent == 14) {
                                  eventType = "SERVICE_ALERT";
                                } else if (element.typeofevent == 34) {
                                  eventType = "ENGINE_NOT_STARTED";
                                } else if (element.typeofevent == 62) {
                                  eventType = "SAMPLER_DISCONNECTED";
                                } else if (element.typeofevent == 10) {
                                  eventType = "CAR_BATTERY_OFF";
                                } else if (element.typeofevent == 20) {
                                  eventType = "CAR_BATTERY_ON";
                                }
                                if (element.bacvalue != 255) {
                                  Bacvalue = element.bacvalue;
                                }

                                var rcidlength, rtimelength, braclength;

                                if (
                                  arrData.indexOf(element).toString().length ==
                                  1
                                ) {
                                  rcidlength = row_width + 10;
                                } else if (
                                  arrData.indexOf(element).toString().length ==
                                  2
                                ) {
                                  rcidlength = row_width + 9;
                                } else if (
                                  arrData.indexOf(element).toString().length ==
                                  3
                                ) {
                                  rcidlength = row_width + 8;
                                } else if (
                                  arrData.indexOf(element).toString().length ==
                                  4
                                ) {
                                  rcidlength = row_width + 7;
                                }

                                if (weekday.length == 6) {
                                  rtimelength = row_width + 12;
                                } else if (weekday.length == 7) {
                                  rtimelength = row_width + 11;
                                } else if (weekday.length == 8) {
                                  rtimelength = row_width + 10;
                                } else if (weekday.length == 9) {
                                  rtimelength = row_width + 9;
                                } else if (weekday.length == 10) {
                                  rtimelength = row_width + 8;
                                } else if (weekday.length == 11) {
                                  rtimelength = row_width + 7;
                                }

                                if (eventType.length == 9) {
                                  braclength = row_width + 12;
                                } else if (eventType.length == 10) {
                                  braclength = row_width + 11;
                                } else if (eventType.length == 11) {
                                  braclength = row_width + 10;
                                } else if (eventType.length == 12) {
                                  braclength = row_width + 9;
                                } else if (eventType.length == 13) {
                                  braclength = row_width + 8;
                                } else if (eventType.length == 14) {
                                  braclength = row_width + 7;
                                } else if (eventType.length == 15) {
                                  braclength = row_width + 6;
                                } else if (eventType.length == 16) {
                                  braclength = row_width + 5;
                                } else if (eventType.length == 17) {
                                  braclength = row_width + 4;
                                } else if (eventType.length == 18) {
                                  braclength = row_width + 3;
                                } else if (eventType.length == 19) {
                                  braclength = row_width + 2;
                                } else if (eventType.length == 20) {
                                  braclength = row_width + 1;
                                } else if (eventType.length == 21) {
                                  braclength = row_width;
                                }
                                object +=
                                  rcid +
                                  new Array(rcidlength).join(" ") +
                                  ";" +
                                  element.rtime +
                                  new Array(row_width).join(" ") +
                                  ";" +
                                  weekday +
                                  new Array(rtimelength).join(" ") +
                                  ";" +
                                  eventType +
                                  new Array(braclength).join(" ") +
                                  ";" +
                                  Bacvalue +
                                  new Array(row_width + 10).join(" ") +
                                  ";" +
                                  Result +
                                  "\r\n";
                                rcid++;
                              }
                            }
                          });
                          resolve("success");
                        } else {
                          reject("No Logs Found");
                        }
                      }); //end promise
                      neProm.then(async (res) => {
                        if (res == "success") {
                          this.texasdisplay =
                            "Downloading Images Please wait.....";
                          if (datas.state == "TX") {
                            await Promise.all(
                              this.eventscount.map((element) => {
                                if (
                                  element.randominitial == 2 ||
                                  element.randominitial == 0
                                ) {
                                  this.istexasloading = true;
                                  var itemname = element.rtime;
                                  var stname = itemname.replaceAll("/", "_");
                                  var ssname = stname.replaceAll(" ", "_");
                                  var smname = ssname.replaceAll(":", "_");
                                  // console.log(smname);
                                  Vue.axios
                                    .get(
                                      "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                        element.devid +
                                        "&Image=" +
                                        itemname
                                    )
                                    .then((res) => {
                                      if (res.data) {
                                        img.file(smname + ".jpg", res.data, {
                                          base64: true,
                                        });
                                        this.imagecount++;
                                        updatecount++;
                                      } else {
                                        updatecount++;
                                      }
                                    })
                                    .catch(function (error) {
                                      updatecount++;
                                      console.log("Network Error:" + error);
                                    });
                                } else if (
                                  element.randominitial == 3 ||
                                  element.randominitial == 1
                                ) {
                                  // console.log(element);
                                  this.istexasloading = true;
                                  var itemname = element.rtime;
                                  var stname = itemname.replaceAll("/", "_");
                                  var ssname = stname.replaceAll(" ", "_");
                                  var smname = ssname.replaceAll(":", "_");
                                  // console.log(smname);
                                  Vue.axios
                                    .get(
                                      "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
                                        element.devid +
                                        "&Image=" +
                                       itemname
                                    )
                                    .then((res) => {
                                      if (res.data) {
                                        img.file(smname + ".jpg", res.data, {
                                          base64: true,
                                        });
                                        this.imagecount++;
                                        updatecount++;
                                      } else {
                                        updatecount++;
                                      }
                                    })
                                    .catch(function (error) {
                                      updatecount++;
                                      console.log("Network Error:" + error);
                                    });
                                } else {
                                  console.log("NA");
                                }
                                // }
                              })
                            );
                            var checkimagesInterval = setInterval(() => {
                              var completedper =
                                (updatecount / this.eventscount.length) * 100;
                              this.texasdisplay =
                                "Zipping files..... " +
                                parseInt(completedper) +
                                " %";
                              if (updatecount >= this.eventscount.length) {
                                this.confirmtexasloading = true;
                                clearInterval(checkimagesInterval);
                                this.istexasloading = false;
                                setTimeout(() => {
                                  this.confirmtexasloading = false;
                                  zip.file(datas.client + ".csv", object);
                                  zip
                                    .generateAsync({ type: "blob" })
                                    .then(function (content) {
                                      //make default
                                      saveAs(content, datas.client + ".zip");
                                    });
                                  this.imagecount = 0;
                                  this.eventscount = [];
                                  updatecount = 0;
                                  (this.texasdisplay =
                                    "Downloading data please wait....."),
                                    (this.dialog10 = false);
                                }, 5000);
                              }
                            }, 2000);
                          } else {
                            const link = document.createElement("a");
                            link.setAttribute(
                              "href",
                              "data:text/csv;charset=utf-8," +
                                encodeURIComponent(object)
                            );
                            link.setAttribute(
                              "download",
                              arrData[0].devid + ".csv"
                            );
                            link.click();

                            this.dialog10 = false;
                          }
                        }
                      });
                    } else {
                      this.istexasloading = false;
                      this.$store.dispatch("alert/error", "Log file empty");
                    }
                  } else {
                    this.istexasloading = false;
                    this.$store.dispatch("alert/error", "Log file empty");
                  }
                })
                .catch((err) => {
                  this.$store.dispatch("alert/error", err);
                });
            }
          }
        });
    },
    textdownload() {
      this.type = "text";
      this.downloadItem(this.expanded[0], 0);
      this.postdata = null;
      this.dialog = false;
    },
    // csvdownload2(item) {
    //   this.type = "csv";
    //   this.downloadItem(item,0);
    //   this.postdata = null;
    //   this.dialog = false;
    // },
    csvdownload() {
      this.type = "csv";
      this.downloadItem(this.expanded[0], 0);
      this.postdata = null;
      this.dialog = false;
    },
    removelscsvdownload(item) {
      // this.type = "text";
      this.removelsdownloadItem(item);
      this.postdata = null;
      this.dialog = false;
    },
    closetemplogs() {
      this.dialog20 = false;
      // this.templogs = null
      this.pagination.page = 1;
      this.options.itemsPerPage = 10;
      this.checkbox2 = false;
      this.expanded = [];
      this.search1 = "";
    },
    close9() {
      this.dialog9 = false;
      this.pagination.page = 1;
      this.options.itemsPerPage = 10;
      this.checkbox2 = false;
      this.expanded = [];
      this.search1 = "";
      this.samplercheckbox = false;
      this.checkbox2 = false;
    },
    imgclose() {
      this.imgdialog = false;
      this.dialog9 = true;
      this.imageBytes = null;
    },
    close10() {
      this.dialog10 = false;
    },
    close18() {
      this.dialog18 = false;
      this.Prohibitionclose();
    },
    close11() {
      this.dialog11 = false;

      this.checkbox2 = false;
      if (this.expanded.length > 0) {
        this.expanded = [];
      }
    },
    viewLogs() {
      if (this.logg.pass == "Loading") {
        this.$store
          .dispatch("alert/error", "Data Loading Please Wait")
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      } else {
        this.dialog9 = true;
      }
    },
    fetchtempLogs(item) {
      if (this.templogs != []) {
        this.$store.dispatch("fetchtempLogs", item.devId);
        this.ismemloading = true;
        this.loadingtext = "PLEASE WAIT";
        setTimeout(() => {
          this.ismemloading = false;
          this.dialog20 = true;
        }, 2000);
      } else {
        this.$store.dispatch("alert/error", "NO WIFI LOGS").then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear");
          }, 2000);
        });
        this.ismemloading = false;
        this.dialog20 = true;
      }
    },
    downloadLogs() {
      if (this.logg.pass == "Loading") {
        this.$store
          .dispatch("alert/error", "Data Loading Please Wait")
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      } else {
        this.dialog10 = true;
      }
    },
    fetchAll2(item) {
      this.beforedata = item;
      if (this.oledDevices.filter((value) => value.sno == item.sno).length == 0) {
        //fetch events based on control id
        var obj = {
          role: this.user.role,
          item: item,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("controlidfetchsplitLogs", obj);
      } else {
        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("samplefetchsplitLogs", obj);
      }
    },
    fetchAll(item) {
      this.beforedata = item;
      //fetch Invoices based on regnum
      this.$store.dispatch("fetchuniquerInvoice2", item.regnum);
      if (this.oledDevices.filter((value) => value.sno == item.sno).length == 0) {
        //fetch events based on control id
        var obj = {
          role: this.user.role,
          item: item,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("controlidfetchLogs", obj);
        //fetch tracking history based on controlid
        Vue.axios
          .get(
            "https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid=" +
              item.cbid
          )
          .then((response) => {
            if (response.data != null) {
              // console.log(response.data)
              var firmwaredata = response.data;
              firmwaredata = firmwaredata.sort(
                (a, b) =>
                  moment(a.updatedtime).valueOf() -
                  moment(b.updatedtime).valueOf()
              );
              var revfirm = firmwaredata.reverse();
              this.firmware = revfirm[0].fwversion;
              var s3data = revfirm.filter((o) => {
                return o.s3version.String !== "";
              });
              if (s3data != "") {
                this.s3version = s3data[0].s3version.String;
              } else {
                this.s3version = "";
              }
              if (revfirm[0].cbversion != "") {
                this.cbversion = revfirm[0].cbversion;
              } else {
                this.cbversion = null;
              }
              var mydetails = revfirm[0];
              this.editedItem.calibrationDate = mydetails.caldate;
              var newdate = moment(mydetails.caldate, "MM/DD/YYYY").add(
                this.editedItem.calibrationperiod,
                "days"
              );
              // console.log(newdate)
              this.caldetails.calibrationdate = newdate.format("MM/DD/YYYY");
              // var nextdate = moment(
              //   item.calibrationDate,
              //   "MM/DD/YYYY"
              // ).add(parseInt(item.calibrationperiod), "days");
              var nextdate = moment(this.caldetails.calibrationdate).add(
                parseInt(item.calibrationperiod),
                "days"
              );
              var nxtDate = moment(nextdate).format("MM/DD/YYYY");
              this.caldetails.nextcaldate = nxtDate;
              this.caldetails.devid = item.devId;
              // this.caldetails.iswifi = item.iswifi;
            } else {
              ///
              console.log("No history Found");
            }
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + item.cbid
              )
              .then((resp) => {
                if (resp.data == null) {
                  this.logg = {
                    total: "0",
                    pass: "0",
                    fail: "0",
                    enginerunstate: "0",
                    refused: "0",
                    IncorrectBreathSamples: "0",
                    Bypass: "0",
                    othertampering: "0",
                    hot: "0",
                    permanantlockout: "0",
                    PowerDisconnections: "0",
                    HandsetDisconnections: "0",
                    HardwareErrors: "0",
                    lowalc: "0",
                    medalc: "0",
                    highalc: "0",
                  };
                  this.caldetails.lastevent = "NO UPDATES";
                }
              });
            Vue.axios
              .get(
                "https://robopower.xyz/us/req/getuniquestaterequirement?state=" +
                  item.tagstate
              )
              .then((res) => {
                if (res.data) {
                  this.req.iscam = res.data[0].iscamera;
                  this.req.issign = res.data[0].issignature;
                  // console.log(this.samplercheckbox)
                  this.iscam = res.data[0].iscamera;
                  if (res.data[0].iscamera == "YES") {
                    this.logheaders = [
                      // { text: "Id", value: "id" },
                      { text: "DeviceId", value: "controlId" },
                      {
                        text: "HandSet",
                        align: "left",
                        value: "devid",
                      },
                      { text: "TIME", value: "rtime" },
                      { text: "EVENT", value: "typeofevent", sortable: false },
                      { text: "BAC", value: "bacvalue", sortable: false },
                      {
                        text: "RESULT",
                        value: "passfailstatus",
                        sortable: false,
                      },
                      {
                        text: "pressure Offset",
                        value: "vbat[0]",
                        sortable: false,
                      },
                      // {
                      //   text: "Alcohol Offset",
                      //   value: "vbat[1]",
                      //   sortable: false,
                      // },
                      // { text: "AMB Temp", value: "vbat[2]", sortable: false },
                      // { text: "Altitude", value: "vbat[4]", sortable: false },
                      { text: "IMAGE", value: "image", sortable: false },
                    ];
                  } else {
                    this.logheaders = [
                      // { text: "Id", value: "id" },
                      { text: "DeviceId", value: "controlId" },
                      {
                        text: "HandSet",
                        align: "left",
                        value: "devid",
                      },
                      { text: "TIME", value: "rtime" },
                      { text: "EVENT", value: "typeofevent", sortable: false },
                      { text: "BAC", value: "bacvalue", sortable: false },
                      {
                        text: "RESULT",
                        value: "passfailstatus",
                        sortable: false,
                      },
                      {
                        text: "pressure Offset",
                        value: "vbat[0]",
                        sortable: false,
                      },
                      // {
                      //   text: "Alcohol Offset",
                      //   value: "vbat[1]",
                      //   sortable: false,
                      // },
                      // { text: "AMB Temp", value: "vbat[2]", sortable: false },
                      // { text: "Altitude", value: "vbat[4]", sortable: false },
                      // { text: "IMAGE", value: "image", sortable: false },
                    ];
                  }
                }
              });
          })
          .catch((err) => {
            this.$store
              .dispatch("alert/error", "NO DEVICE HISTORY")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          });
      } else {
        //fetch based on Samplerid
        // console.log("fetch based on sampler");

        var obj = {
          role: this.user.role,
          item: this.beforedata,
          sorted: this.checkbox2,
        };
        this.$store.dispatch("samplefetchLogs", obj);
        //  console.log(item)
        Vue.axios
          .get(
            "https://robopower.xyz/v2/caltrack/getdevcaltracks?devId=" +
              item.devId
          )
          .then((response) => {
            if (response.data != null) {
              // console.log(response.data)
              var firmwaredata = response.data;
              firmwaredata = firmwaredata.sort(
                (a, b) =>
                  moment(a.updatedtime).valueOf() -
                  moment(b.updatedtime).valueOf()
              );
              var revfirm = firmwaredata.reverse();
              this.firmware = revfirm[0].fwversion;
              var s3data = revfirm.filter((o) => {
                return o.s3version.String !== "";
              });
              if (s3data != "") {
                this.s3version = s3data[0].s3version.String;
              } else {
                this.s3version = "";
              }
              if (revfirm[0].cbversion != "") {
                this.cbversion = revfirm[0].cbversion;
              } else {
                this.cbversion = null;
              }
              var mydetails = revfirm[0];
              this.editedItem.calibrationDate = mydetails.caldate;
              var newdate = moment(mydetails.caldate, "MM/DD/YYYY").add(
                this.editedItem.calibrationperiod,
                "days"
              );
              // console.log(newdate)
              this.caldetails.calibrationdate = newdate.format("MM/DD/YYYY");
              // var nextdate = moment(
              //   item.calibrationDate,
              //   "MM/DD/YYYY"
              // ).add(parseInt(item.calibrationperiod), "days");
              var nextdate = moment(this.caldetails.calibrationdate).add(
                parseInt(item.calibrationperiod),
                "days"
              );
              var nxtDate = moment(nextdate).format("MM/DD/YYYY");
              this.caldetails.nextcaldate = nxtDate;
              this.caldetails.devid = item.devId;
              // this.caldetails.iswifi = item.iswifi;
            } else {
              ///
              console.log("No history Found");
            }
            Vue.axios
              .get(
                "https://robopower.xyz/v2/eeprom/getalllogs?deviceId=" +
                  item.devId
              )
              .then((resp) => {
                if (resp.data == null) {
                  this.logg = {
                    total: "0",
                    pass: "0",
                    fail: "0",
                    enginerunstate: "0",
                    refused: "0",
                    IncorrectBreathSamples: "0",
                    Bypass: "0",
                    othertampering: "0",
                    hot: "0",
                    permanantlockout: "0",
                    PowerDisconnections: "0",
                    HandsetDisconnections: "0",
                    HardwareErrors: "0",
                    lowalc: "0",
                    medalc: "0",
                    highalc: "0",
                  };
                  this.caldetails.lastevent = "NO UPDATES";
                }
              });
            Vue.axios
              .get(
                "https://robopower.xyz/us/req/getuniquestaterequirement?state=" +
                  item.tagstate
              )
              .then((res) => {
                if (res.data) {
                  this.req.iscam = res.data[0].iscamera;
                  this.req.issign = res.data[0].issignature;
                  this.iscam = res.data[0].iscamera;
                  if (res.data[0].iscamera == "YES") {
                    this.logheaders = [
                      // { text: "Id", value: "id" },
                      {
                        text: "SamplerId",
                        align: "left",
                        value: "devid",
                      },
                      { text: "CBID", value: "controlId" },
                      { text: "TIME", value: "rtime" },
                      { text: "EVENT", value: "typeofevent", sortable: false },
                      { text: "BAC", value: "bacvalue", sortable: false },
                      {
                        text: "RESULT",
                        value: "passfailstatus",
                        sortable: false,
                      },
                      {
                        text: "pressure Offset",
                        value: "vbat[0]",
                        sortable: false,
                      },
                      // {
                      //   text: "Alcohol Offset",
                      //   value: "vbat[1]",
                      //   sortable: false,
                      // },
                      // { text: "AMB Temp", value: "vbat[2]", sortable: false },
                      // { text: "Altitude", value: "vbat[4]", sortable: false },
                      { text: "IMAGE", value: "image", sortable: false },
                    ];
                  } else {
                    this.logheaders = [
                      // { text: "Id", value: "id" },
                      {
                        text: "SamplerId",
                        align: "left",
                        value: "devid",
                      },
                      { text: "CBID", value: "controlId" },
                      { text: "TIME", value: "rtime" },
                      { text: "EVENT", value: "typeofevent", sortable: false },
                      { text: "BAC", value: "bacvalue", sortable: false },
                      {
                        text: "RESULT",
                        value: "passfailstatus",
                        sortable: false,
                      },
                      {
                        text: "pressure Offset",
                        value: "vbat[0]",
                        sortable: false,
                      },
                      // {
                      //   text: "Alcohol Offset",
                      //   value: "vbat[1]",
                      //   sortable: false,
                      // },
                      // { text: "AMB Temp", value: "vbat[2]", sortable: false },
                      // { text: "Altitude", value: "vbat[4]", sortable: false },
                      // { text: "IMAGE", value: "image", sortable: false },
                    ];
                  }
                }
              });
          })
          .catch((err) => {
            this.$store
              .dispatch("alert/error", "NO DEVICE HISTORY")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          });
      }
    },
    getCalibdetails() {
      Vue.axios
        .get(
          "https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid=" +
            this.editedItem.cbid
        )
        .then((response) => {
          if (response.data != null) {
            var mydetails = response.data[response.data.length - 1];
            this.editedItem.calibrationDate = mydetails.caldate;
            var newdate = moment(mydetails.caldate, "MM/DD/YYYY").add(
              this.editedItem.calibrationperiod,
              "days"
            );
            this.editedItem.nextcmonitordate = newdate.format("MM/DD/YYYY");
            this.editedItem.installDate = moment(new Date()).format(
              "MM/DD/YYYY"
            );
          } else {
            this.editedItem.calibrationDate = "";
            this.editedItem.nextcmonitordate = "";
            this.editedItem.installDate = "";
            this.addclose();
            this.$store
              .dispatch("alert/error", "Please do the calibration and Install")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchInstaller(id) {
      Vue.axios
        .get("https://robopower.xyz/us/causers/getuniqueuser?userId=" + id)
        .then((response) => {
          if (response.data != null) {
            var ssm = response.data[0];
            this.editedItem.installer = ssm;
            var mdb1 = ssm.userMobile1.split("");
            var mbb =
              mdb1[0] +
              " " +
              mdb1[1] +
              mdb1[2] +
              mdb1[3] +
              " " +
              mdb1[4] +
              mdb1[5] +
              mdb1[6] +
              "-" +
              mdb1[7] +
              mdb1[8] +
              mdb1[9] +
              mdb1[10];
            this.editedItem.imobile = mbb;
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    fetchOfficedetails() {
      Vue.axios
        .get(
          "https://robopower.xyz/us/caoffices/getuniqueoffice?officeId=" +
            this.editedItem.officeId.officeId
        )
        .then((response) => {
          if (response.data != null) {
            var x = response.data[0];
            this.editedItem.officeId = x;
            this.editedItem.officeName = x.officeName;
            this.editedItem.isbar = x.isbar;
            this.editedItem.faddress = x.officeaddress;
            this.editedItem.fcity = x.city;
            this.editedItem.fstate = x.state;
            this.editedItem.fzipcode = x.zipcode;
            this.editedItem.officelicensenumber = x.officelicense;
            this.editedItem.dealer = x.dealerName;
            this.$store.dispatch("fetchdealerUsers", x.dealerId);
            if (this.user.role == "Admin" || this.user.role == "superadmin") {
              this.$store.dispatch("fetchDevices");
            } else {
              this.$store.dispatch("fetchdealerDevices", x.dealerId);
            }
            if (this.editedIndex == -10) {
              this.dialog = true;
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    getBalance() {
      const amountPattern = /^\d+(\.\d{1,2})?$/;
      this.validAmount = amountPattern.test(this.currentamountpaid);
      this.addInfo.amountpaid = (
        parseFloat(this.previouspaid) + parseFloat(this.currentamountpaid)
      ).toString();
      this.addInfo.prevbalance = (
        parseFloat(this.total_amount_due) - parseFloat(this.currentamountpaid)
      ).toString();
    },
    getTotal(index, val) {
      //validating discount amount
      const amountPattern = /^\d+(\.\d{1,2})?$/;
      this.validdiscountAmount = amountPattern.test(val);
      // console.log(index)
      let selectedService = this.invoiceservices.find(
        (service) => service.servicetype == this.addInfo.service[index].service
      );
      if (selectedService !== undefined) {
        this.addInfo.service[index].actualamount = selectedService.rate;
        this.addInfo.service[index].total = (
          selectedService.rate - this.addInfo.service[index].discount
        ).toString();
      }
    },
    format_price: (number) => {
      return "$" + parseFloat(number).toFixed(2);
    },
    total_price: (number) => {
      return parseFloat(number).toFixed(2);
    },
    add_item: function () {
      this.addInfo.service.push({
        invoiceId: null,
        service: "",
        actualamount: 0,
        discount: "0",
        description: "",
        total: "",
      });
    },
    update_value: function (index, event, property) {
      this.addInfo.service[index][property] = event.target.innerText;
    },
    remove_item: function (index) {
      this.addInfo.service.splice(index, 1);
    },
    getServiceInfo(index) {
      /* eslint-disable no-console */
      let selectedService = this.invoiceservices.find(
        (service) => service.servicetype == this.addInfo.service[index].service
      );
      if (selectedService !== undefined) {
        this.addInfo.service[index].actualamount = selectedService.rate;
      }
      this.getTotal(index, this.addInfo.service[index].discount);
    },
    isNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    close6() {
      this.dialog6 = false;
      this.$refs.form6.reset();
      this.editedIndex = -1;
    },
    close15(){
      this.dialog14 = false;
    },
    close14() {
      if(this.isplay){
        this.stop();
      } else{
 const googleMapsComponent = this.$refs.liveTrack;
      if (googleMapsComponent && typeof googleMapsComponent.onClearall === 'function') {
        // Call the function in the dynamically loaded component
        googleMapsComponent.onClearall();

      } else {
        console.error('Function not found in GoogleMaps component');
      }
      }
      this.lastupdate = null;
      this.ping = [];
     
  
      this.dialog14 = false;
     
    },
    close7() {
      this.e1 = 1;
      if (this.editedIndex == -1) {
        this.dialog7 = false;
        this.dialog5 = false;
        document.getElementById("generateInvoice").reset();
        this.addInfo = this.emptyInfo;
        this.close6();
      } else {
        document.getElementById("generateInvoice").reset();
        this.addInfo.invoiceId = null;
        this.addInfo.office = null;
        this.addInfo.client = null;
        this.addInfo.date = "";
        this.addInfo.service = [];
        this.addInfo.regnum = "";
        this.addInfo.total = "0.00";
        this.addInfo.prevbalance = "0.00";
        this.addInfo.grandtotal = "0.00";
        this.addInfo.amountpaid = "0.00";
        this.dialog7 = false;
        this.dialog5 = false;
        this.addInfo = this.emptyInfo;
        this.prevBalance = 0.0;
        this.currentamountpaid = "0.00";
        this.previouspaid = "0.00";
        this.editedIndex = -1;
      }
    },

    next() {
      // if (!this.$refs.form.validate()) {
      //   return;
      // }
      // // console.log(this.addInfo.regnum)
      // this.$store.dispatch('fetchuniquefullClient',this.addInfo.client)
      // // this.$store.dispatch('fetchuniqueVehicle',this.addInfo.regnum)
      // this.$store.dispatch('fetchInvoiceservices',this.addInfo.office)
      // this.dialog7 = true
    },
    save3() {
      this.paymentelements2.amount = this.currentamountpaid;
      this.e1 = 2;
      this.generatePaymentIntent2(this.addInfo);
    },
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      // console.log("=== End ===");
      setTimeout(() => {
        this.saveSign();
      }, 500);
    },
    addClservice(pstatus) {
      if (this.addclr.service.length > 0) {
        this.addclr.service.map((resp) => {
          let today = new Date();
          let date =
            today.getMonth() +
            1 +
            "/" +
            today.getDate() +
            "/" +
            today.getFullYear() +
            " " +
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          const formData = new FormData();
          formData.append("servicename", resp.service);
          formData.append("amount", resp.actualamount);
          formData.append("regnum", this.addclr.regnum);
          formData.append("emailid", this.myclient.email);
          formData.append("created_date", date);
          formData.append("status", "requested");
          formData.append("payment", pstatus);
          formData.append("installer", this.myclient.installer);
          formData.append("requested_date", date);
          formData.append("scheduled_at", "");
          formData.append("finished_at", "");
          Vue.axios
            .post("https://robopower.xyz/us/clientservices/addclserv", formData)
            .then((resp) => {
              if (resp.status == "200") {
                this.$store.dispatch("alert/success", "Updated").then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              }
            })
            .catch((err) => {
              this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            });
        });
      }
    },
    save4() {
      var body;
      if (this.editedIndex == -1) {
        if (this.currentamountpaid == "0") {
          this.addInfo.prevbalance = parseFloat(
            this.total_amount_due
          ).toString();
        }
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY");
        this.addInfo.service.map((item)=>{
          console.log(item.actualamount)
        })
        console.log(this.addInfo.service)
        body = JSON.stringify(this.addInfo);
        this.$store
          .dispatch("createInvoice", body)
          .then(() => {
            this.el = 2;
            this.addClservice("online");
            if (this.currentamountpaid > 0.0) {
              this.$refs.paymentRef2.submit();
            } else {
              this.dialog7 = false;
              // this.payclose2();
            }
            // this.payclose2();
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY");
        body = JSON.stringify(this.addInfo);
        var post = {
          id: this.addInfo.invoiceId,
          data: body,
        };
        this.$store.dispatch("editInvoice", post);
        this.el = 2;
        this.close7();
      }
    },
    saved() {
      var body;
      if (this.editedIndex == -1) {
        if (this.currentamountpaid == "0") {
          this.addInfo.prevbalance = parseFloat(
            this.total_amount_due
          ).toString();
        }
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY");
        body = JSON.stringify(this.addInfo);
        this.$store
          .dispatch("createInvoice", body)
          .then(() => {
            this.addClservice("offline");
            this.el = 2;
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      } else {
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY");
        body = JSON.stringify(this.addInfo);
        var post = {
          id: this.addInfo.invoiceId,
          data: body,
        };
        this.$store.dispatch("editInvoice", post);
        this.el = 2;
      }
      this.close7();
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mounteddata() {
    //   Vue.axios.get('https://robopower.xyz/app/vehicleroutes/getdealerallroutes?dealerId=258').then((res)=>{
    //   // console.log(res.data)
    //   if(res.data != null){
    //   this.selectedRouteId =  res.data;
    //   }
    // })
      this.$store.dispatch('fetchOLEDDevices');
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch("fetchfullClients");
        this.$store.dispatch("fetchOffices");
        // this.$store.dispatch("fetchDevices");
        this.$store.dispatch("fetchDrivers");
        this.$store.dispatch("fetchdealerUsers", this.user.dealerId);
      } else {
        // this.$store.dispatch("fetchdealerDevices",this.user.dealerId);
        this.$store.dispatch("fetchdealerfullClients", this.user.dealerId);
        this.$store.dispatch("fetchdealerOffices", this.user.dealerId);
        this.$store.dispatch("fetchdealerUsers", this.user.dealerId);
      }
      
    },
    reLoad() {
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch("fetchfullClients");
        this.$store.dispatch("fetchOffices");
        // this.$store.dispatch("fetchDevices");
        this.$store.dispatch("fetchDrivers");
        this.$store.dispatch("fetchdealerUsers", this.user.dealerId);
      } else {
        // this.$store.dispatch("fetchdealerDevices",this.user.dealerId);
        this.$store.dispatch("fetchdealerfullClients", this.user.dealerId);
        this.$store.dispatch("fetchdealerOffices", this.user.dealerId);
        this.$store.dispatch("fetchdealerUsers", this.user.dealerId);
      }
    },
    loadFormData2() {
      //  var std = new Date()
      //  var regdates = std.format("MM/DD/YYYY HH:mm:ss")
      const formData2 = new FormData();
      formData2.append("client", this.editedItem.client);
      formData2.append("office", this.editedItem.officeId.officeId);
      formData2.append("suffix", this.editedItem.suffix);
      formData2.append("dob", this.editedItem.dob);
      formData2.append("clientphone1", this.editedItem.clientphone1);
      formData2.append("clientphone2", this.editedItem.clientphone2);
      formData2.append("email", this.editedItem.email);

      formData2.append("clientAddress", this.editedItem.clientAddress);
      formData2.append("space", this.editedItem.space);
      formData2.append("city", this.editedItem.city);
      formData2.append("sno", this.editedItem.sno);
      formData2.append("state", this.editedItem.state);
      formData2.append("zipcode", this.editedItem.zipcode);
      if (this.editedItem.isresidsame == "1") {
        formData2.append("rclientAddress", this.editedItem.clientAddress);
        formData2.append("rspace", this.editedItem.space);
        formData2.append("rcity", this.editedItem.city);
        formData2.append("rstate", this.editedItem.state);
        formData2.append("rzipcode", this.editedItem.zipcode);
      } else {
        formData2.append("rclientAddress", this.editedItem.rclientAddress);
        formData2.append("rspace", this.editedItem.rspace);
        formData2.append("rcity", this.editedItem.rcity);
        formData2.append("rstate", this.editedItem.rstate);
        formData2.append("rzipcode", this.editedItem.rzipcode);
      }

      formData2.append("dlnum", this.editedItem.dlnum);
      formData2.append("isresidsame", this.editedItem.isresidsame);
      formData2.append("licensefile", this.editedItem.licensefile);
      formData2.append("ticketfile", this.editedItem.ticketfile);
      return formData2;
    },
    probloadFormData() {
      var std = new Date();
      var regdates = moment(std).format("MM/DD/YYYY HH:mm:ss");
      const formData = new FormData();
      formData.append("isresidsame", this.editedItem.isresidsame);
      formData.append("manufacturer", "Alcobrake");
      formData.append(
        "isinstalledandworking",
        this.editedItem.isinstalledandworking
      );
      formData.append(
        "driverisincompliance",
        this.editedItem.driverisincompliance
      );
      formData.append(
        "isunavailablevehicle",
        this.editedItem.isunavailablevehicle
      );
      formData.append("installer", this.editedItem.installer.userId);
      formData.append("suffix", this.editedItem.suffix);
      formData.append("space", this.editedItem.space);
      if (this.editedItem.isresidsame == "1") {
        formData.append("rclientAddress", this.editedItem.clientAddress);
        formData.append("rspace", this.editedItem.space);
        formData.append("rcity", this.editedItem.city);
        formData.append("rstate", this.editedItem.state);
        formData.append("rzipcode", this.editedItem.zipcode);
      } else {
        formData.append("rclientAddress", this.editedItem.rclientAddress);
        formData.append("rspace", this.editedItem.rspace);
        formData.append("rcity", this.editedItem.rcity);
        formData.append("rstate", this.editedItem.rstate);
        formData.append("rzipcode", this.editedItem.rzipcode);
      }
      formData.append("uregnum", this.editedItem.uregnum);
      formData.append("umake", this.editedItem.umake);
      formData.append("uyear", this.editedItem.uyear);
      formData.append("uvin", this.editedItem.uvin);

      formData.append("client", this.editedItem.client);
      if (this.editedIndex == -10) {
        formData.append("office", this.editedItem.officeId.officeId);
      } else {
        formData.append("office", this.editedItem.officeId.officeId);
      }
      if (this.editedIndex == -1) {
        formData.append("regdate", regdates);
      } else {
        formData.append("regdate", this.editedItem.regdate);
      }
      // formData.append("device", this.editedItem.device);
      // formData.append("cbid", this.editedItem.cbid);
      formData.append("clientphone1", this.editedItem.clientphone1);
      formData.append("clientphone2", this.editedItem.clientphone2);
      formData.append("email", this.editedItem.email);
      formData.append("clientAddress", this.editedItem.clientAddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("regnum", this.editedItem.regnum);
      formData.append("installDate", this.editedItem.installDate);
      formData.append("calibrationDate", this.formatcalDate);
      formData.append("nextcmonitordate", this.formatnextcalDate);
      formData.append("sno", this.editedItem.sno);
      formData.append("make", this.editedItem.make);
      formData.append("dob", this.editedItem.dob);
      formData.append("model", this.editedItem.model);
      formData.append("year", this.editedItem.year);
      formData.append("color", this.editedItem.color);
      formData.append("vin", this.editedItem.vin);
      formData.append("tag", this.editedItem.tag);
      formData.append("tagstate", this.editedItem.tagstate);
      formData.append("dlnum", this.editedItem.dlnum);
      formData.append("prohibitionnumber", this.editedItem.prohibitionnumber);
      formData.append("prohibstart", this.editedItem.prohibstart);
      formData.append("prohibend", this.editedItem.prohibend);
      formData.append("conviction", this.editedItem.conviction);
      formData.append("calibrationperiod", this.editedItem.calibrationperiod);
      formData.append("licensefile", this.editedItem.licensefile);
      formData.append("ticketfile", this.editedItem.ticketfile);
      formData.append("issms", 0);
      formData.append("facedetection", 0);
      return formData;
    },
    loadFormData() {
      var std = new Date();
      var regdates = moment(std).format("MM/DD/YYYY HH:mm:ss");
      const formData = new FormData();
      formData.append("isresidsame", this.editedItem.isresidsame);
      formData.append("manufacturer", "Alcobrake");
      formData.append(
        "isinstalledandworking",
        this.editedItem.isinstalledandworking
      );
      formData.append(
        "driverisincompliance",
        this.editedItem.driverisincompliance
      );
      formData.append(
        "isunavailablevehicle",
        this.editedItem.isunavailablevehicle
      );
      formData.append("installer", this.editedItem.installer.userId);
      formData.append("suffix", this.editedItem.suffix);
      formData.append("space", this.editedItem.space);
      if (this.editedItem.isresidsame == "1") {
        formData.append("rclientAddress", this.editedItem.clientAddress);
        formData.append("rspace", this.editedItem.space);
        formData.append("rcity", this.editedItem.city);
        formData.append("rstate", this.editedItem.state);
        formData.append("rzipcode", this.editedItem.zipcode);
      } else {
        formData.append("rclientAddress", this.editedItem.rclientAddress);
        formData.append("rspace", this.editedItem.rspace);
        formData.append("rcity", this.editedItem.rcity);
        formData.append("rstate", this.editedItem.rstate);
        formData.append("rzipcode", this.editedItem.rzipcode);
      }
      formData.append("uregnum", this.editedItem.uregnum);
      formData.append("umake", this.editedItem.umake);
      formData.append("uyear", this.editedItem.uyear);
      formData.append("uvin", this.editedItem.uvin);

      formData.append("client", this.editedItem.client);
      if (this.editedIndex == -10) {
        formData.append("office", this.editedItem.officeId.officeId);
      } else {
        formData.append("office", this.editedItem.officeId.officeId);
      }
      if (this.editedIndex == -1) {
        formData.append("regdate", regdates);
      } else {
        formData.append("regdate", this.editedItem.regdate);
      }
      // formData.append("device", this.editedItem.device);
      // formData.append("cbid", this.editedItem.cbid);
      formData.append("clientphone1", this.editedItem.clientphone1);
      formData.append("clientphone2", this.editedItem.clientphone2);
      formData.append("email", this.editedItem.email);
      formData.append("sno", this.editedItem.sno);
      formData.append("clientAddress", this.editedItem.clientAddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("regnum", this.editedItem.regnum);
      formData.append("installDate", this.editedItem.installDate);
      formData.append("calibrationDate", this.formatcalDate);
      formData.append("nextcmonitordate", this.formatnextcalDate);
      formData.append("make", this.editedItem.make);
      formData.append("dob", this.editedItem.dob);
      formData.append("model", this.editedItem.model);
      formData.append("year", this.editedItem.year);
      formData.append("color", this.editedItem.color);
      formData.append("vin", this.editedItem.vin);
      formData.append("tag", this.editedItem.tag);
      formData.append("tagstate", this.editedItem.tagstate);
      formData.append("dlnum", this.editedItem.dlnum);
      formData.append("prohibitionnumber", this.editedItem.prohibitionnumber);
      formData.append("prohibstart", this.editedItem.prohibstart);
      formData.append("prohibend", this.editedItem.prohibend);
      formData.append("conviction", this.editedItem.conviction);
      formData.append("calibrationperiod", this.editedItem.calibrationperiod);
      formData.append("licensefile", this.editedItem.licensefile);
      formData.append("ticketfile", this.editedItem.ticketfile);
      return formData;
    },
    initialize() {},
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    deleteItem2(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title:
          "Are you sure? You want to Add " +
          " " +
          item.regnum +
          "to Removals" +
          " " +
          item.installDate,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      })
        .then((result) => {
          if (result.value) {
            this.removelscsvdownload(item);
            this.$store.dispatch("deleteFullclientprohib", item);
            if (this.user.role == "Admin" || this.user.role == "superadmin") {
              this.$store.dispatch("fetchfullClients");
            } else {
              this.$store.dispatch(
                "fetchdealerfullClients",
                this.user.dealerId
              );
            }
            this.$store
              .dispatch("alert/success", "Added to Removals")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          } else {
            this.$store
              .dispatch("alert/error", "Your file is still intact")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    padString(str, length, index, columnnumber) {
      // console.log(this.myclient);
      // console.log(this.logg);
      var names = this.myclient.client.split(" ");
      const spacesToAdd = length - str.length;
      if (columnnumber == 0) {
        console.log(index);
        var udata = "";
        if (index == 0) {
          udata = this.myclient.cbid;
        } else if (index == 1) {
          udata = this.myclient.installDate;
        } else if (index == 2) {
          udata = names[0];
        } else if (index == 3) {
         
          if (names.length > 2) {
            for (var i = 1; i < names.length; i++) {
              console.log(names[i]);
              udata = udata + names[i];
            }
          } else {
            udata = "";
          }
        } else if (index == 4) {
          udata = this.myclient.manufacturer;
        } else if (index == 5) {
          udata = this.logg.lowalc;
        } else if (index == 6) {
          udata = this.logg.highalc;
        } else if (index == 7) {
          udata = this.logg.fail;
        } else {
          udata =
            this.logg.othertampering +
            this.logg.HandsetDisconnections +
            this.logg.PowerDisconnections;
        }
        return str + udata + " ".repeat(spacesToAdd);
      } else {
        var udata = "";
        // console.log(index);
        if (index == 0) {
          udata = this.myclient.id;
        } else if (index == 1) {
          udata = this.myclient.prohibend;
        } else if (index == 2) {
          if (names.length > 1) {
            udata = names[1];
          } else {
            udata = "";
          }
        } else if (index == 3) {
          // console.log(this.myclient);
          udata = this.myclient.DLnum;
        } else if (index == 4) {
          udata = this.myclient.state;
        } else if (index == 5) {
          udata = this.logg.medalc;
        } else if (index == 6) {
          udata = this.logg.Bypass;
        } else if (index == 7) {
          udata = this.logg.refused;
        } else {
          udata = this.logg.total;
        }
        return str + udata + " ".repeat(spacesToAdd);
      }
    },
    getReport(item) {
      this.fetchAll(item);
      setTimeout(() => {
        Report(item,this.myclient,this.user,this.logs)
      });
    },
    specialOffers(myclient){
      console.log(this.myclient.id)
      Vue.axios.get("https://robopower.xyz/us/cainvoices/cagetclientspecialrates?clientId=" + this.myclient.id)
      .then((res)=>{
        console.log(res.data.status)
        this.splbtn = res.data.status
        if(res.data){
          this.invoicespllistText = "Update Special Offers"
          this.invoiceservices2 = res.data.pricelist
          this.splDialog = true
        }else{
          this.invoicespllistText = "Create Special Offers"
          this.splDialog = true
          this.invoiceservices2 = [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ]
        }
      })
    },
    updateSave(item){

      Vue.axios.get("https://robopower.xyz/us/cainvoices/cagetclientspecialrates?clientId=" + this.myclient.id)
      .then((res)=>{
        this.createdTime = res.data.created_at
        this.splId = res.data.id
        console.log(this.splId )

      // console.log(this.myclient.id)
      console.log(this.splId,'splId' )
      var clockTime = moment(new Date()).format("MM/DD/YYYY HH:mm:ss")
      var specialformData1 = new FormData();
      specialformData1.append('client', this.myclient.id )
      specialformData1.append('pricelist', JSON.stringify(item))
      specialformData1.append('status', 'Active')
      specialformData1.append('created_at', this.createdTime )
      specialformData1.append('updated_at', clockTime)
      console.log(clockTime,"updated_at")
      Vue.axios.post("https://robopower.xyz/us/caspecialprices/editspecialprice/" + this.splId, specialformData1)
      .then(response => {
          console.log('Data sent successfully1', response);
          this.splDialog = false
          // this.splclientId = ''
          // this.createdTime = ''
        })
        .catch(error => {
          console.error('Error sending data', error);
        });
      })
    },
    splSave(item){
      console.log(this.myclient.id)
      var clockTime = moment(new Date()).format("MM/DD/YYYY HH:mm:ss")
      var specialformData = new FormData();
      specialformData.append('client', this.myclient.id )
      specialformData.append('pricelist', JSON.stringify(item))
      specialformData.append('status', 'Active')
      specialformData.append('created_at', clockTime )
      specialformData.append('updated_at', '')
      // console.log(item)
      Vue.axios.post("https://robopower.xyz/us/caspecialprices/addspecialprices", specialformData)
      .then(response => {
          console.log('Data sent successfully', response);
          this.splDialog = false
          this.splclientId = ''
        })
        .catch(error => {
          console.error('Error sending data', error);
        });
    },
     

    getReport2(item){
       const {llegalStarts,HighBrAC,RRRefused,EngineStops,EngineStarts,HandsetDisconnections} = Report2(item,this.logs);
          this.datastats.IllegalStarts = llegalStarts
          this.datastats.HighBrAC = HighBrAC
          this.datastats.RRRefused = RRRefused
          this.datastats.EngineStops = EngineStops
          this.datastats.EngineStarts = EngineStarts
          this.datastats.HandsetDisconnections = HandsetDisconnections
          this.clientdata = item;
          this.reportdialog=true;
    },
    listViewchange(){
      // console.log(this.myclient.id,'clientid')
      // console.log('Checkbox is now:', this.isChecked);
      Vue.axios.get("https://robopower.xyz/us/cainvoices/cagetclientspecialrates?clientId=" + this.myclient.id)
      .then((res)=>{
        // console.log(this.myclient.id)
        // console.log(res.data)
        if (this.isChecked == false && res.data.status === 'Active') {
        this.isChecked = false;
        this.invoiceservices =  [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ]
        // console.log(this.isChecked,'hiiitrue1')
      }else if (this.isChecked == true && res.data.status === 'Active') {
        this.isChecked = true;
        this.invoiceservices = res.data.pricelist
        // console.log(this.isChecked,'hiiitrue2')
      } else if (this.isChecked == true && res.data.status != 'Active') {
        // console.log(this.myclient)
        const Swal = require("sweetalert2");
        Swal.fire({
          title: "Are you sure? You want to add the Special Offer to " + this.myclient.client,
          text: "You can't revert your action",
          icon: "warning", // Use "icon" instead of "type"
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            this.splDialog = true;
          } else {
            this.isChecked = false
            this.$store
              .dispatch("alert/error", "Your Request Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
          }
        })
      } else {
        console.log('hiiifalse')
      }
      }).catch(error => {
      console.error('Error fetching data:', error);
    });
    },
    addInvoice(list) {
      this.paymentItem.clientphone1 = list.clientphone1;
      this.paymentItem.regnum = list.regnum;
      this.paymentItem.email = list.email;
      this.paymentelements2.email = list.email;
      this.addInfo.office = list.officeId;
      this.addInfo.client = list.client;
      this.addInfo.regnum = list.regnum;
      this.$store.dispatch("fetchuniquefullClient", list.client);
      // this.$store.dispatch("fetchInvoiceservices", list.officeId);
      this.dialog7 = true;
      this.listViewchange();
      
    },
    AddEditmore2(item) {
      this.myclient = item;
      this.allvehicles = item.list;
      this.dialog5 = true;
    },
    AddEditmore(item) {
      this.dialog5 = false;
      this.editedItem.id = item.id;
      this.editedItem.officeId = item;
      this.editedItem.client = item.client;
      this.editedItem.clientphone1 = item.clientphone1;
      this.editedItem.clientphone2 = item.clientphone2;
      this.editedItem.email = item.email;
      this.editedItem.dob = item.dob;
      // this.editedItem.sno = item.sno;
      this.editedItem.clientAddress = item.clientAddress;
      this.editedItem.space = item.space;
      this.editedItem.state = item.state;
      this.editedItem.city = item.city;
      this.editedItem.zipcode = item.zipcode;
      this.editedItem.rclientAddress = item.rclientAddress;
      this.editedItem.rspace = item.rspace;
      this.editedItem.rstate = item.rstate;
      this.editedItem.rcity = item.rcity;
      this.editedItem.rzipcode = item.rzipcode;
      this.editedItem.dlnum = item.DLnum;
      this.editedItem.suffix = item.suffix;
      this.editedItem.isresidsame = item.isresidsame;
      this.fetchOfficedetails();
      this.dialog3 = true;
      this.editedIndex = -1;
      this.isDisabled = true;
    },
    editItem(item) {
      this.dialog5 = false;
      this.editedIndex = this.fullclients.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.officeId = item;
      this.editedItem.client = item.client;
      this.editedItem.clientphone1 = item.clientphone1;
      this.editedItem.clientphone2 = item.clientphone2;
      this.editedItem.email = item.email;
      this.editedItem.dob = item.dob;
      this.editedItem.dlnum = item.DLnum;
      this.editedItem.clientAddress = item.clientAddress;
      this.editedItem.space = item.space;
      this.editedItem.state = item.state;
      this.editedItem.city = item.city;
      this.editedItem.zipcode = item.zipcode;
      this.editedItem.regdate = item.regdate;
      this.editedItem.regnum = item.regnum;
      this.editedItem.sno = item.sno;
      this.editedItem.installDate = item.installDate;
      this.editedItem.calibrationDate = item.calibrationDate;
      this.editedItem.nextcmonitordate = item.nextcmonitordate;
      this.editedItem.make = item.make;
      this.editedItem.model = item.model;
      this.editedItem.year = item.year;
      this.editedItem.color = item.color;
      this.editedItem.vin = item.vin;
      this.editedItem.tag = item.tag;
      this.editedItem.tagstate = item.tagstate;
      // this.editedItem.dlnum = item.dlnum;
      this.editedItem.prohibitionnumber = item.prohibitionnumber;
      this.editedItem.prohibstart = item.prohibstart;
      this.editedItem.prohibend = item.prohibend;
      this.editedItem.conviction = item.conviction;
      this.editedItem.calibrationperiod = item.calibrationperiod;
      this.editedItem.suffix = item.suffix;
      this.editedItem.rclientAddress = item.rclientaddress;
      this.editedItem.rspace = item.rspace;
      this.editedItem.rstate = item.rstate;
      this.editedItem.rcity = item.rcity;
      this.editedItem.rzipcode = item.rzipcode;
      this.editedItem.isresidsame = item.isresidsame;
      this.editedItem.isinstalledandworking = item.isinstalledandworking;
      this.editedItem.driverisincompliance = item.driverisincompliance;
      this.editedItem.isunavailablevehicle = item.isunavailablevehicle;
      this.editedItem.uregnum = item.uregnum;
      this.editedItem.umake = item.umake;
      this.editedItem.uyear = item.uyear;
      this.editedItem.uvin = item.uvin;
      this.fetchInstaller(item.installer);
      this.fetchOfficedetails();
      this.dialog3 = true;
    },
    close12() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog12 = false;
      this.undo();
    },
    close13() {
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.caltrackdata = [];
      this.pagination.page = 1;
      this.options.itemsPerPage = 10;
      this.dialog13 = false;
    },
    addclose() {
      this.$refs.form.reset();
      this.dialog = false;
      this.editedIndex = -1;
      this.countrycode = "US";
      this.tempvariable = null;
      document.getElementById("licensefile").value = "";
      document.getElementById("ticketfile").value = "";
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    close2() {
      this.$refs.form2.reset();
      this.dialog2 = false;
      this.dialog = false;
      this.dialog3 = false;
      this.isDisabled = false;
      this.editedIndex = -1;
      this.tempvariable = "";
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      // }, 300);
    },
    close3() {
      this.$refs.form3.reset();
      // this.dialog2 = false;
      // this.dialog = false;
      this.dialog3 = false;
      this.isDisabled = false;
      this.editedIndex = -1;
      this.tempvariable = "";
      this.editedItem.dob = "";
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      // }, 300);
    },
    save() {
      if (!this.$refs.form3.validate()) {
        return;
      }
      if (this.editedItem.clientphone2 == undefined)
        this.editedItem.clientphone2 = "";
      if (this.editedIndex == -1) {
        const fd = this.loadFormData();
        this.$store.dispatch("createfullClient", fd);
      } else {
        const fd = this.probloadFormData();
        var postdata = {
          cdata: fd,
          id: this.editedItem.id,
        };
        this.$store.dispatch("editfullProhib", postdata);
        this.expended = [];
        this.models = null;
        // setTimeout(()=>{
        //   window.location.reload()
        // })
      }
      this.close3();
    },
    save5() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedItem.clientphone2 == undefined)
        this.editedItem.clientphone2 = "";
      if (this.editedIndex == -1) {
        const fd = this.loadFormData();
        this.$store.dispatch("createfullClient", fd);
      } else {
        const fd = this.probloadFormData();
        var postdata = {
          cdata: fd,
          id: this.editedItem.id,
        };
        this.$store.dispatch("editfullProhib", postdata);
        this.expended = [];
        this.models = null;
        // setTimeout(()=>{
        //   window.location.reload()
        // })
      }
      this.addclose();
    },
    save2() {
      if (!this.$refs.form2.validate()) {
        return;
      }
      if (this.editedItem.clientphone2 == undefined)
        this.editedItem.clientphone2 = "";
      if (this.editedIndex == -1) {
        const fd = this.loadFormData();
        this.$store.dispatch("createfullClient", fd);
        // this.dialog8==true;
        // setTimeout(()=>{
        //   this.$htmlToPaper('printme');

        // },2000)
        // getInfo.addData("caclients", "addclient", fd);
      } else if (this.editedIndex == -10) {
        const fd = this.probloadFormData();
        var postdata = {
          cdata: fd,
          id: this.editedItem.id,
        };
        this.$store.dispatch("editfullProhib", postdata);
        this.expended = [];
        this.models = null;
        setTimeout(() => {
          window.location.reload();
        });
      } else {
        const fd = this.loadFormData2();
        var postdata2 = {
          cdata: fd,
          id: this.tempvariable,
        };
        this.$store.dispatch("editfullClient", postdata2);
        // getInfo.editData("caclients", "editclient", this.editedItem.clientId, fd);
      }
      this.close2();
    },

    //  updateAcceptance() {
    //    const formData = new FormData();
    //   formData.append("file", this.cfile);
    //   formData.append("version", this.version);
    //   formData.append("comment", this.comment);

    // this.$store.dispatch("createGuifirmware", formData);
    // },
  },
};
</script>
<template>
  <!-- v-if = "!cloading" -->
  <!-- loading-text="Loading... Please wait" -->
  <v-container fluid>
    <v-dialog v-model="confirmtexasloading" max-width="600px">
      <v-card>
        <v-card-title class="justify-center"> Summary</v-card-title>
        <v-card-text>Total Events:{{ totaleventscount }}</v-card-text>
        <v-card-text>Test Events:{{ eventscount.length }}</v-card-text>
        <v-card-text>Total Images:{{ imagecount }}</v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="istexasloading" max-width="800px">
      <v-card><v-card-title class="justify-center">{{
        texasdisplay
          }}</v-card-title></v-card></v-dialog>
    <v-dialog v-model="ismemloading" width="450px">
      <v-card style="background-color: black; color: azure">
        <v-card-title class="justify-center">{{ loadingtext }}</v-card-title>
      </v-card>
    </v-dialog>
    <!-- swapping -->

    <v-dialog v-model="swappingdialog" max-width="955px">
      <Swapdevice :myclient="myclient" :mylogdata="logs" :close="swappingclose"></Swapdevice>
    </v-dialog>
    <v-dialog v-model="setzone" width="400px" content-class="overflow-visible" @click:outside="cancelcustomTime">
      <v-card>
        <v-card-title class="justify-center">{{ timezonetext }}</v-card-title>
        <v-autocomplete style="width: 360px !important; margin-left: 13px" label="Select Time Zone/State*" required
          v-on:change="setTimeformat" class="purple-input" v-model="mytzone" :items="myzones" :item-text="getItemText"
          return-object>
          <template slot="selection" slot-scope="data">
            {{ data.item.countryname }} {{ data.item.timezone }}
          </template>
        </v-autocomplete>
        <v-row style="margin-left: 10px">
          <v-col cols="8">
            <v-btn class="error" @click="cancelcustomTime">Cancel</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn class="primary" style="margin-left: 20px" @click="setcustomTime" :disabled="!mytzone">OK</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog15" max-width="800px">
      <v-card class="elevation-12">
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col class="col-md-8" align="center">
              <div style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                ">
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the amount ${{ paymentelements.amount }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <div class="container">
          <stripe-element-payment v-if="iscreated" ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
            :confirm-params="{
              return_url:
                `https://alcobrakeadmin.com/successtransaction?mobile=` +
                paymentItem.clientphone1 +
                `&regnum=` +
                paymentItem.regnum +
                `&email=` +
                paymentItem.email +
                `&message=Dear customer your last transaction  is success with Payment id:` +
                payind1 +
                `&paymentid=` +
                payind1 +
                `&clearall=true`,
            }" />
          <br />
          <v-layout justify-center>
            <!-- dialog paynow -->
            <v-btn class="mr-4" @click="payclose" dark style="background: #ff3d41; color: white">Cancel</v-btn>
            <v-btn class="primary" left @click="pay">Pay Now</v-btn>
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="autorenewdialog" max-width="800px">
      <v-card class="elevation-12">
        <v-toolbar text color="teal" class="" dark dense>
          <v-toolbar-title style="margin-left: 10px; color: black"><v-icon color="white"
              class="mr-4">mdi-cash-multiple</v-icon>Please Fill the details to Autorenew Monthly Renewal:
          </v-toolbar-title>
        </v-toolbar>
        <Autorenew :selectedclient="selectedforrenewal" @clearRenewal="clearRenewalparams" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog14" width="800px">
      <v-card style="border-radius: 20px; background: #334c3e">
        <v-toolbar v-if="lastupdate !== 'NO UPDATE'" color="#00A3A3" size="70"
          style="border-radius: 20px; border-bottom: 1px solid white">
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-form ref="datetimeform" v-model="valid3" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
            style="margin-top: 20px">
            <v-row>
              <v-datetime-picker label="From Date" ref="fromdate" v-model="fromdate"
                @input="changeFdate()"></v-datetime-picker>
              &nbsp;
              <v-datetime-picker label="To Date" v-model="todate" ref="todate" :min="minToDate"
                @input="changeTdate()"></v-datetime-picker>
            </v-row>
          </v-form>
          <v-form v-else v-model="valid3" ref="datetimeform">
            <v-layout style="margin-top: 20px">
              <v-datetime-picker label="From Date" v-model="fromdate" @input="changeFdate()">
              </v-datetime-picker>
              &nbsp;
              <v-datetime-picker label="To Date" v-model="todate" ref="todate"
                @input="changeTdate()"></v-datetime-picker>
            </v-layout>
          </v-form>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn @click="play" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
            style="background: green; color: white; border-radius: 20px">
            {{ isplay ? "Replay" : "Play" }}
          </v-btn>
          <v-layout v-else justify-end>
            <v-icon @click="play" size="30" color="white">{{
              isplay ? "mdi-play-pause" : "mdi-play-circle-outline"
            }}</v-icon>
          </v-layout>
          &nbsp;
          <v-btn class="mr-0" @click="stop" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
            style="background: #ff3d41; color: white; border-radius: 20px">
            Stop
          </v-btn>
          <v-icon @click="stop" size="30" left v-else color="white">mdi-stop-circle-outline</v-icon>
          <v-divider class="mx-8" inset vertical></v-divider>
          <v-btn color="black" class="sm-0" @click="close14" style="border-radius: 20px; color: white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar v-else color="#00A3A3" size="70" style="border-radius: 20px; border-bottom: 1px solid white">
          <v-toolbar v-show="false" color="#00A3A3" size="70"
            style="border-radius: 20px; border-bottom: 1px solid white"></v-toolbar>
          <v-btn color="black" @click="close15" style="border-radius: 20px; color: white;margin-left:680px;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <p style="
            font-size: 20px;
            text-align: center;
            margin-top: 10px;
            color: white;
            border-bottom: 2px dashed white;
          ">
          Real Time Location<v-icon class="ml-2" size="40" color="white">mdi-car-pickup</v-icon>
        </p>
        <div style="padding:10px;margin-top:-40px;">
          <v-col>
            <v-row>
              <v-col v-if="isloading">
                <v-flex v-if="isplay">
                  <b style="color: #26bb44;margin-left:-10px;">Total Alcohol Test:</b>
                  <span style="color: white">{{ this.passtestevents.length + this.failtestevents.length }}</span>
                </v-flex>
                <v-flex v-else>
                  <v-flex v-show="false"></v-flex>
                </v-flex>
              </v-col>
              <v-col v-if="isloading">
                <v-flex v-if="isplay">
                  <b style="color: #26bb44;margin-left:20px;">Pass:</b>
                  <span style="color: white">{{ this.passtestevents.length }}</span>
                </v-flex>
                <v-flex v-else>
                  <v-flex v-show="false"></v-flex>

                </v-flex>
              </v-col>
              <v-col v-if="isloading">
                <v-flex v-if="isplay"><b style="color: #26bb44;margin-left:15px;">Fail:</b><span style="color: white">{{
                  this.failtestevents.length }}</span></v-flex>

              </v-col>
            </v-row>
            <v-row style="margin-top:4%;">
              <v-flex><b style="color: #26bb44;">Last Updated on:</b><span style="color: white">{{ lastupdate
                  }}</span></v-flex>
              <div v-if="isloading">
                <v-flex v-if="isplay"><b style="color: #26bb44;margin-left:-109px;">Distance Travelled:</b><span
                    style="color: white">{{ this.distancetravel }}</span></v-flex>
              </div>
            </v-row>

          </v-col>
          <google-maps v-if="!isplay" ref="liveTrack" :data="ping" @changetime="calctime"></google-maps>
          <route-maps v-else :data="ping" :isPlay="isplay" :passdata="passtestevents" :faildata="failtestevents"
            :engineondata="engonevents" :engineoffdata="engoffevents" :starttime="fromdate" :endtime="todate"
            @routeclosed="routeclosed"></route-maps>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="accepdialog" max-width="800px">
      <v-toolbar text color="#26BB44" class="" dark dense>
        <v-row>
          <v-toolbar-title>Acceptance</v-toolbar-title>
          <v-layout justify-end>
            <v-btn color="primary" @click="accclose" dark
              v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
            <v-icon v-else color="primary" size="40px" @click="accclose">
              mdi-close
            </v-icon>
          </v-layout>
        </v-row>
      </v-toolbar>
      <v-data-table dense :headers="accepheaders" :items="acceptancedata" :search="search" class="elevation-1">
      </v-data-table>
    </v-dialog>

    <!-- clear log dialog -->

    <v-dialog v-model="clearlogdialog" max-width="600px">
      <v-card class="elevation-12">
        <v-toolbar text color="#26BB44" class="" dark dense>
          <v-row>
            <v-toolbar-title style="margin-left: 10px; color: black">Clear Storage</v-toolbar-title>
            <v-layout justify-end>
              <v-btn color="primary" @click="clearclose" dark
                v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
              <v-icon v-else color="primary" size="40px" @click="clearclose">
                mdi-close
              </v-icon>
            </v-layout>
          </v-row>
        </v-toolbar>

        <!-- <template> -->

        <v-card-text>
          <v-form>
            <v-text-field v-model="dealeremail" label="Email" name="dealeremail" prepend-icon="mdi-account"
              type="text"></v-text-field>

            <v-text-field v-model="dealerpassword" prepend-icon="mdi-lock"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" name="dealerpassword"
              label="Password" hint="At least 8 characters" counter @click:append="show2 = !show2"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-layout justify-end>
            <v-btn color="#666600" @click="login" style="color: white">RESET</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bacdialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> BRAC Value </v-card-title>

        <v-card-text>
          <v-text-field v-model="bacfield" type="number" label="BRAC VALUE" required></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="bacclose"> Close </v-btn>
          <v-btn color="primary" text :disabled="!bacfield" @click="setBracCommand">
            SET
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pingfreqdialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Frequency </v-card-title>

        <v-card-text>
          <v-text-field v-model="pinginterval" type="number" label="PING FREQUENCY" required></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="pingfrequencyclose">
            Close
          </v-btn>
          <v-btn color="primary" text :disabled="!pinginterval" @click="setPingfreqCommand">
            SET
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="thresholddialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> THRESHOLD </v-card-title>
        <v-card-text>
          <v-text-field v-model="thresholdfield" type="number" :rules="[
            thresholdrules.required,
            thresholdrules.min,
            thresholdrules.max,
          ]" label="THRESHOLD VALUE" required></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="thresholdclose"> Close </v-btn>
          <v-btn color="primary" text :disabled="!thresholdfield" @click="setThresholdCommand">
            SET
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="caldialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          AUTO CAL PRESSURE
        </v-card-title>

        <v-card-text>
          <v-select v-model="calfield" :items="numb" label="CAL VALUE" required></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="autocalclose"> Close </v-btn>
          <v-btn color="primary" text :disabled="!calfield" @click="setCalCommand">
            SET
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog17" max-width="1000px">
      <v-card style="border-radius: 20px">
        <v-toolbar text color="#00A3A3" class="mb-4" dark dense style="border-radius: 20px">
          <v-toolbar-title>Parameters</v-toolbar-title>
          <v-layout justify-end>
            <v-btn color="black" @click="close17" dark style="border-radius: 20px">
              <v-icon> mdi-close </v-icon></v-btn>
          </v-layout>
        </v-toolbar>
        <v-data-table dense :headers="headers4" :items="calparamsdata" :options.sync="options"
          :page.sync="pagination.page" @pagination="historyupdatePage" class="elevation-1">
          <template slot="item.parameters" slot-scope="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="blue" v-bind="attrs" v-on="on" @click="openParams(item)">
                  mdi-eye
                </v-icon>
              </template>
              <span>Show Parameters</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog16" max-width="900px">
      <v-card style="border-radius: 20px">
        <v-toolbar text color="#00A3A3" class="mb-4" dark dense style="border-radius: 20px">
          <v-toolbar-title>Operations History</v-toolbar-title>
          <v-layout justify-end>
            <v-btn color="black" @click="close16" dark style="border-radius: 20px">
              <v-icon> mdi-close </v-icon></v-btn>
          </v-layout>
        </v-toolbar>
        <v-card flat style="background-color: teal">
          <v-card-text>
            <progress-info :lastop="expanded" :firmware="firmware"></progress-info>
          </v-card-text>
        </v-card>
        <!-- <v-data-table 
               dense
        :headers="headers3"
        :items="operations"
         :options.sync="options"
        :page.sync="pagination.page"
        @pagination="ophistoryupdatePage"
        class="elevation-1">
              </v-data-table> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog13" max-width="900px">
      <v-card style="border-radius: 20px">
        <v-toolbar text color="#00A3A3" class="mb-4" dark dense style="border-radius: 20px">
          <v-toolbar-title>Calibration History</v-toolbar-title>
          <v-layout justify-end>
            <v-btn color="black" @click="close13" dark style="border-radius: 20px">
              <v-icon> mdi-close </v-icon></v-btn>
          </v-layout>
        </v-toolbar>
        <v-data-table dense :headers="headers2" :items="caltrackdata" :options.sync="options"
          :page.sync="pagination.page" @pagination="historyupdatePage" class="elevation-1">
          <template slot="item.parameters" slot-scope="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="blue" v-bind="attrs" v-on="on" @click="openParams(item)">
                  mdi-eye
                </v-icon>
              </template>
              <span>Show Parameters</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- TX STATE FORM interlock -->
    <v-dialog v-model="interlockdialog" max-width="955px">
      <TX :editedItem="editedItem" :type="mytype"></TX>
      <v-card>
        <v-card-text>
          &nbsp;
          <v-row justify="end">
            <v-btn color="#ff3d41" left @click="interlockformclose" style="color: white">Cancel</v-btn>
            &nbsp;
            <v-btn color="primary" @click="interlockDownloadform">Download</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportdialog" max-width="955px">
      <REPORT :editedItem="editedItem" :type="mytype" :myclient="clientdata" :events="datastats"></REPORT>
      <v-card>
        <v-card-text>
          &nbsp;
          <v-row justify="end">
            <v-btn color="#ff3d41" left @click="reportformclose" style="color: white">Cancel</v-btn>
            &nbsp;
            <v-btn color="primary" @click="reportDownloadform">Download</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- CA STATE FORM -->
    <v-dialog v-model="pdfdialog" max-width="955px">
      <CA :editedItem="editedItem" :type="mytype"></CA>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn color="#ff3d41" @click="formclose" style="color: white">Cancel</v-btn>
            </v-col>
            <v-col align="right">
              <v-btn color="#334c3e" class="mb-2" @click="print" style="color: white">print</v-btn>
              <v-btn color="primary" class="ml-4 mb-2" @click="Downloadform">Download</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </div> -->

    <!-- client table start   -->
    <v-data-table dense :headers="headers" :items="Reminder" sort-by="id" item-key="id" :search="search"
      class="elevation-1" show-expand single-expand :expanded.sync="expanded" style="border-radius: 20px">
      <template v-slot:top>
        <v-card color="grey lighten-4" text style="border-radius: 20px">
          <v-toolbar class="mb-4" text color="#00A3A3" dark dense style="border-radius: 20px">
            <span style="font-size: 40%">
              <v-toolbar-title>CLIENTS</v-toolbar-title>
            </span>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-row>
              <v-col cols="12" lg="12">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>
            <v-dialog v-model="dialog6" max-width="400px">
              <v-card color="grey lighten-4" text>
                <v-toolbar text color="#26BB44" class="" dark dense>
                  <v-toolbar-title>Select Vehicle</v-toolbar-title>
                </v-toolbar>
                <v-form id="clientSelection" autocomplete="off" ref="form6" v-model="valid2" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete dense required :rules="vehicleRules" v-model="addInfo.regnum"
                            :items="allvehicles" label="Select Vehicle" item-text="regnum"
                            item-value="regnum"></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close6">Cancel</v-btn>
                  <v-btn color="blue darken-1" text :disabled="!valid2" @click="next">Next</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog width="600" v-model="splDialog">
              <v-toolbar text color="#66bb6a" dark dense>
                <v-toolbar-title>{{ invoicespllistText }}</v-toolbar-title>
              </v-toolbar>
              <v-card>
                <v-card-text>
                  <table style="background-color:#bdbdbd;">
                    <thead>
                      <tr style="color: black;">
                        <th>ServiceTypes</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in invoiceservices2" :key="index" style="background-color: #eeeeee;">
                        <td style="width: 350px;">
                          <v-text-field :value="item.servicetype" solo dense readonly>
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="item.rate" required solo dense></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="splDialog = false"
                    style="background: #ff3d41;color: white;margin-right: 1%">Cancel</v-btn>
                  <v-btn text v-if="splbtn !== 'Active'" @click="splSave(invoiceservices2)"
                    style="background: #009688;color: white;">Save</v-btn>
                  <v-btn text v-else @click="updateSave(invoiceservices2)"
                    style="background: #009688;color: white;">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog width="600" v-model="dialog12">
              <v-toolbar text color="#26BB44" dark dense>
                <v-toolbar-title>SIGNATURE</v-toolbar-title>
              </v-toolbar>
              <v-card color="grey lighten-4" text>
                <VueSignaturePad id="signature" width="100%" height="400px" ref="signaturePad"
                  :options="{ onBegin, onEnd }" />
                <!-- <v-card-actions> -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close12">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- View Driver Licence -->
            <v-dialog v-model="licencedialog" persistent width="500px">
              <v-card style="border-radius: 20px" align="center">
                <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                  <v-row>
                    <v-toolbar-title style="margin-left: 10px; color: white">DRIVER LICENCE</v-toolbar-title>
                    <v-layout justify-end>
                      <v-icon v-if="dlimage != ''" @click="downloaddlimage()" color="white" size="20px" style="
                          background: black;
                          padding: 4px;
                          border-radius: 20px;
                          margin-right: 5px;
                        ">mdi-download</v-icon>
                      <v-icon v-if="dlimage != ''" @click="dlfullscreen()" color="white" size="30px" style="
                          background: black;
                          padding: 4px;
                          border-radius: 20px;
                          margin-right: 5px;
                        ">mdi-fullscreen</v-icon>
                      <v-btn color="black" @click="licenceclose" dark style="color: white; border-radius: 20px">
                        <v-icon> mdi-close </v-icon></v-btn>
                    </v-layout>
                  </v-row>
                </v-toolbar>
                <v-card-text>
                  <div v-if="dlimage == ''">
                    <h3>NO IMAGE</h3>
                  </div>
                  <div v-else>
                    <img class="dl-img1" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" id="cleardlimage"
                      :src="dlimage" />
                    <img class="dl-img2" v-else id="cleardlimage" :src="dlimage" />
                  </div>
                </v-card-text>
                <v-btn depressed @click="dlupdate" class="mb-2" color="primary">Change DL PHoto</v-btn>
              </v-card>
            </v-dialog>

            <!-- loading text dialog -->
            <v-dialog v-model="licenseloading" max-width="330px">
              <v-card style="background-color: black; color: azure">
                <v-card-title class="loading">{{ licensetext }}</v-card-title>
              </v-card>
            </v-dialog>

            <!-- csvlogs upload -->
            <v-dialog v-model="csvupload" max-width="730px">
              <v-card>
                <input type="file" id="csvFile" name="csvFile" accept=".csv" @click="clearcsv"
                  @change="csvFileUpload($event)" />
                <template>
                  <v-data-table :headers="csvheaders" :items="allDataevents" item-value="Date"
                    class="elevation-1"></v-data-table>
                </template>
              </v-card>
            </v-dialog>

            <!-- update license photo -->
            <v-dialog v-model="updatelicencedialog" persistent width="600px">
              <v-card style="border-radius: 20px">
                <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                  <v-toolbar-title style="color: white">UPDATE DRIVER LICENCE</v-toolbar-title>
                  <v-layout justify-end>
                    <v-btn color="black" @click="closeupdate" dark style="color: white; border-radius: 20px">
                      <v-icon> mdi-close </v-icon></v-btn>
                  </v-layout>
                </v-toolbar>
                <v-spacer></v-spacer>
                <div align="center" class="mt-4">
                  <div>
                    <input id="clearupdateinput" :v-model="editedItem.editlicensefile" type="file" accept="image/jpeg"
                      @change="updatelicenseImage" />
                  </div>
                  <div>
                    <label style="color: green; font-size: 11px" v-if="editedItem.editlicensefile">Correct JPG
                      Format</label>
                    <label style="color: red; font-size: 12px" v-else>Choose only JPG Format</label>
                  </div>

                  <v-btn class="mb-2 mt-2" depressed @click="updatelicense" :disabled="!editedItem.editlicensefile"
                    color="primary">Update</v-btn>
                </div>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog7" width="1000px">
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Add Details
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">
                    Payment
                  </v-stepper-step>
                </v-stepper-header>
                <!-- </v-stepper> -->

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form id="generateInvoice" autocomplete="off" ref="form2">
                      <v-card color="grey lighten-4" text>
                        <v-toolbar text color="blue-grey" class dark dense>
                          <v-toolbar-title>{{ formTitle2 }}</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                          <v-container>
                            <div>
                              <div class="row">
                                <div class="col s12 top-heading">
                                  <!-- <div class="col s6 inline"> -->
                                  <img src="/old_logo.png" height="25" />
                                  <!-- </div> -->
                                  <!-- <div>
                              <h5 class="inline">Invoice</h5>
                            </div>-->
                                </div>
                              </div>

                              <div class="row">
                                <div class="col s6 push-s1">
                                  To,
                                  <br />{{ uniqueclient.client }} <br />{{
                                    uniqueclient.clientAddress
                                  }}
                                  <br />{{ uniqueclient.city }},
                                  {{ uniqueclient.zip }} <br />Phone:
                                  {{ uniqueclient.clientphone1 }}
                                </div>
                                <div class="col s5 right-align">
                                  <div class="col s6 push-s1">
                                    Alco Brake LLC
                                    <br />1421 Champion Drive <br />Ste. 352,
                                    Carrollton <br />75006 <br />Phone: +1 844
                                    425 2627
                                  </div>
                                </div>
                              </div>

                              <div class="divider"></div>
                              <div class="section row">
                                <div class="col s11 right-align">
                                  Invoice Number:
                                  <div class="input-field inline">
                                    <input class="right-align" disabled v-model="addInfo.invoiceId" />
                                  </div>
                                  <br />
                                  Vehicle Registration no:
                                  <div class="input-filed inline">
                                    <input class="right-align" disabled v-model="addInfo.regnum" />
                                  </div>
                                  <br />
                                  Date: {{ date }}
                                  <!-- <br />
                            Amount due: {{format_price(total_amount_due)}} -->
                                </div>
                              </div>

                              <div class="divider"></div>
                              <div class="section row" v-if="isChecked">
                                <div class="col s12">
                                  <v-checkbox v-model="isChecked" label="Check me for Special Offers"
                                    @change="listViewchange()"></v-checkbox>
                                  <table class="striped highlight">
                                    <thead>
                                      <tr>
                                        <th>Service</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th>Description</th>
                                        <th>Total</th>
                                        <th class="hide-print">Remove</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr v-for="(item, index) in addInfo.service" :key="index">
                                        <td>
                                          <v-autocomplete solo dense required v-on:change="getServiceInfo(index)"
                                            v-model="item.service" :items="invoiceservices" label="Select Service"
                                            item-text="servicetype"></v-autocomplete>
                                          <!-- <input v-model="item.name" /> -->
                                        </td>
                                        <td>
                                          <v-text-field v-model="item.actualamount" required solo dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field v-on:change="
                                            getTotal(index, item.discount)
                                            " :rules="amountRules" v-model="item.discount" required solo
                                            dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field v-model="item.description" required solo dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field :value="item.total" required solo dense>
                                          </v-text-field>
                                        </td>
                                        <td class="hide-print">
                                          <v-btn class="ma-0 pa-0" fab dark small v-on:click="remove_item(index)"
                                            color="red">
                                            <v-icon dark>mdi-minus</v-icon>
                                          </v-btn>
                                          <!-- <button class="btn-floating" >X</button> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div class="section hide-print">
                                    <v-btn class="primary" v-on:click="add_item">Add Service</v-btn>
                                  </div>
                                </div>
                              </div>
                              <div class="section row" v-else>
                                <div class="col s12">
                                  <v-checkbox v-model="isChecked" label="Check me for Special Offers"
                                    @change="listViewchange()"></v-checkbox>
                                  <table class="striped highlight">
                                    <thead>
                                      <tr>
                                        <th>Service</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        <th>Description</th>
                                        <th>Total</th>
                                        <th class="hide-print">Remove</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr v-for="(item, index) in addInfo.service" :key="index">
                                        <td>
                                          <v-autocomplete solo dense required v-on:change="getServiceInfo(index)"
                                            v-model="item.service" :items="invoiceservices" label="Select Service"
                                            item-text="servicetype"></v-autocomplete>
                                          <!-- <input v-model="item.name" /> -->
                                        </td>
                                        <td>
                                          <v-text-field v-model="item.actualamount" required solo dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field v-on:change="
                                            getTotal(index, item.discount)
                                            " :rules="amountRules" v-model="item.discount" required solo
                                            dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field v-model="item.description" required solo dense></v-text-field>
                                        </td>
                                        <td>
                                          <v-text-field :value="item.total" required solo dense>
                                          </v-text-field>
                                        </td>
                                        <td class="hide-print">
                                          <v-btn class="ma-0 pa-0" fab dark small v-on:click="remove_item(index)"
                                            color="red">
                                            <v-icon dark>mdi-minus</v-icon>
                                          </v-btn>
                                          <!-- <button class="btn-floating" >X</button> -->
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div class="section hide-print">
                                    <v-btn class="primary" v-on:click="add_item">Add Service</v-btn>
                                  </div>
                                </div>
                              </div>

                              <div class="divider"></div>
                              <div class="row">
                                <div class="col s12 right-align">
                                  Total: {{ format_price(total_all_items) }}
                                  <br />
                                  Last amount Paid: ${{ previouspaid }}
                                  <br />
                                  Previous balance: {{ prevBalance }}
                                  <br />
                                  Grand Total: ${{ total_amount_due }}
                                  <br />
                                  <br />Amount paid:
                                  <div class="input-field inline">
                                    <v-text-field v-on:change="getBalance" :rules="amountRules"
                                      v-model="currentamountpaid" solo dense>
                                    </v-text-field>
                                    <!-- <input class="right-align" v-model="amount_pai" /> -->
                                  </div>
                                  <br />
                                  Balance: {{ previous_balance }}
                                </div>
                              </div>

                              <div class="divider"></div>
                              <div class="divider"></div>
                              <div class="row section">
                                <div class="col s12 center-align">
                                  <b>
                                    <i>Please pay the balance within 30 days.</i>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="close7" style="
                              background: #ff3d41;
                              color: white;
                              margin-right: 1%;
                            ">Cancel</v-btn>
                          <div v-if="
                            addInfo.service.length > 0 &&
                            validAmount &&
                            validdiscountAmount
                          ">
                            <v-btn text @click="save3" style="
                                background: #334c3e;
                                color: white;
                                width: 80%;
                              ">Next<v-icon>mdi-arrow-right</v-icon></v-btn>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-row class="justify-center">
                      <v-col class="col-md-5" align="center">
                        <div style="
                            background: black;
                            color: white;
                            border-radius: 20px;
                            padding: 10px;
                          ">
                          <v-icon color="green" class="mr-3">mdi-cash-multiple</v-icon>You are paying the amounts ${{
                            paymentelements2.amount
                          }}
                        </div>
                        <v-btn class="mt-4" @click="payprevious"
                          color="warning"><v-icon>mdi-arrow-left</v-icon>Previous</v-btn>
                      </v-col>
                    </v-row>

                    <div class="container" v-if="payind2">
                      &nbsp;&nbsp;
                      <stripe-element-payment ref="paymentRef2" :pk="pk" :elements-options="elementsOptions2"
                        :confirm-params="{
                          return_url:
                            `https://alcobrakeadmin.com/successtransaction?mobile=` +
                            paymentItem.clientphone1 +
                            `&regnum=` +
                            paymentItem.regnum +
                            `&email=` +
                            paymentItem.email +
                            `&message=Dear customer your last transaction  is success with Payment id:` +
                            payind2 +
                            `&paymentid=` +
                            payind2 +
                            `&clearall=false`,
                        }" />
                      <!-- <h1 v-else>Payment Request Failed</h1> -->
                      <!-- <br/> -->
                      <!-- <v-btn  class="primary" @click="pay2">Pay Now</v-btn> -->
                    </div>
                    <!-- You are paying Amount: ${{currentamountpaid}} -->

                    <v-layout justify-end>
                      <v-btn v-if="currentamountpaid > 0.0" color="primary" @click="save4">
                        PAY
                      </v-btn>
                      <v-btn v-else color="primary" @click="save4">
                        ADD INVOICE
                      </v-btn>
                      &nbsp;
                      <v-btn color="error" @click="payclose2">Cancel</v-btn>
                    </v-layout>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-dialog>
            <v-dialog v-model="dialog11" width="900px">
              <v-toolbar class="mb-4" flat color="#00A3A3" dark dense style="border-radius: 20px">
                <v-toolbar-title>INVOICES</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-row> </v-row>
                <v-btn color="black" @click="close11" style="border-radius: 20px"><v-icon color="white" size="40px"
                    class="lg-2">mdi-close</v-icon></v-btn>
              </v-toolbar>
              <Invoices :itemss="expanded[0]" @close11="close11"></Invoices>
            </v-dialog>
            <v-dialog v-model="dialog18" width="900px">
              <v-card>
                <v-toolbar class="mb-4" flat color="#00A3A3" dark dense style="border-radius: 20px">
                  <v-toolbar-title>INVOICES</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-row> </v-row>
                  <v-btn color="black" @click="close18" style="border-radius: 20px"><v-icon color="white" size="40px"
                      class="lg-2">mdi-close</v-icon></v-btn>
                </v-toolbar>
                <Invoices2 :itemss.sync="myclient"></Invoices2>
              </v-card>
            </v-dialog>

            <v-dialog width="400px" v-model="dialog10">
              <v-card text style="border-radius: 20px">
                <v-toolbar color="#00A3A3" style="border-radius: 20px; color: white">
                  <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-toolbar-title v-else style="font-size: 15px">
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-layout justify-end>
                    <v-btn color="black" @click="close10" class="sm-0" style="border-radius: 20px"><v-icon
                        color="white">
                        mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </v-toolbar>
                <div align="center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-4" tile color="green" v-bind="attrs" v-on="on" @click="csvdownload"
                        style="border-radius: 10px; color: white">
                        CSV
                        <v-icon right> mdi-download </v-icon>
                      </v-btn>
                    </template>

                    <span>split data with delimiter semi-colon(;)</span>
                  </v-tooltip>
                </div>
                <div align="center">
                  <v-btn class="mt-2 mb-2" tile color="#334c3e" @click="textdownload"
                    style="border-radius: 10px; color: white">
                    TEXT Document
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
            <v-dialog width="750" v-model="dialog20" @click:outside="closetemplogs">
              <v-card>
                <v-toolbar color="#00A3A3" style="border-radius: 20px; color: white">
                  <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    WIFI LOGS
                  </v-toolbar-title>
                  <v-toolbar-title v-else style="font-size: 15px">
                    WIFI LOGS
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-layout justify-end>
                    <v-btn color="black" @click="closetemplogs" class="sm-0" style="border-radius: 20px"><v-icon
                        color="white">
                        mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </v-toolbar>
                <v-data-table dense :headers="logheaders" :items="templogs" sort-by="office" :items-per-page="10"
                  :options.sync="options" :page.sync="pagination.page" @pagination="logsupdatePage" :search="search1"
                  class="elevation-1" style="border-radius: 20px"></v-data-table>
              </v-card>
            </v-dialog>
            <v-dialog width="880" v-model="dialog9" @click:outside="close9">
              <v-data-table dense :headers="logheaders" :items="logs" sort-by="office" :items-per-page="10"
                :options.sync="options" :page.sync="pagination.page" @pagination="logsupdatePage" :search="search1"
                class="elevation-1" style="border-radius: 20px">
                <template v-slot:top>
                  <v-toolbar class="mb-4" text color="#00A3A3" dark dense style="border-radius: 20px">
                    <span style="font-size: 40%">
                      <v-toolbar-title>Log Events</v-toolbar-title>
                    </span>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search1" append-icon="mdi-magnify" label="Search" single-line
                      hide-details></v-text-field>
                    <v-spacer></v-spacer>

                    <v-layout justify-end>
                      <div v-if="user.role == 'Admin' || user.role == 'superadmin'" style="margin-top: 8px">
                        <h6>Unsorted</h6>
                      </div>
                      &nbsp;

                      <div class="panel-body" v-if="user.role == 'Admin' || user.role == 'superadmin'"
                        style="margin-top: 5px">
                        <!--Only code you need is this label-->
                        <label class="switch">
                          <input type="checkbox" v-model="checkbox2" @click="toggleCheck" />
                          <div class="slider round"></div>
                        </label>
                      </div>
                      &nbsp;&nbsp;
                      <v-btn color="black" @click="close9" dark style="border-radius: 20px"><v-icon color="white">
                          mdi-close </v-icon></v-btn>
                    </v-layout>
                  </v-toolbar>
                </template>
                <template slot="item.image" slot-scope="{ item }">
                  <v-icon class="mt-2" @click="viewImage(item, firmware)" :color="getColor(item)" v-if="
                    item.typeofevent == 'RANDOM TEST' ||
                    item.typeofevent == 'INITIAL TEST' ||
                    item.typeofevent == 'PICTURE_CAPTURED'
                  ">
                    mdi-image-area
                  </v-icon>
                </template>
              </v-data-table>
            </v-dialog>
            <!-- image dialog -->
            <template>
              <v-dialog v-model="imgdialog" v-if="imageSize > 10" width="600px">
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <span style="font-size: 15px">TIME:{{ rtime }}</span>
                    &nbsp;&nbsp;<span style="font-size: 15px">BAC: {{ bacvalue }}</span></v-toolbar-title>
                  <v-toolbar-title v-else>
                    <span style="font-size: 11px">TIME:{{ rtime }}</span>&nbsp;<span style="font-size: 11px">BAC: {{
                      bacvalue }}</span></v-toolbar-title>
                  <v-layout justify-end>
                    <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"
                      style="background: black; padding: 4px; border-radius: 12px">mdi-download</v-icon>

                    <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white" class="mr-4"
                      style="background: black; padding: 4px; border-radius: 12px">mdi-fullscreen</v-icon>

                    <v-btn color="black" small @click="imgclose" dark style="border-radius: 20px"><v-icon color="white">
                        mdi-close </v-icon></v-btn>
                  </v-layout>
                </v-toolbar>
                <v-img id="my-video" :style="{ border: result == 'FAIL' ? '4px solid red' : '3px solid green', }"
                  v-bind:src="'data:image/jpeg;base64,' + imageBytes" v-if="imageSize > 1" />
                <v-card-text v-if="imageSize < 1" class="text-center">
                  <h2 :style="{
                    color: 'white',
                    padding: '5px',
                    borderRadius: '20px',
                    fontSize: '20px',
                    margin: '0 auto' // Center horizontally
                  }">
                    Image Not Found
                  </h2>
                </v-card-text>
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>
                    <span style="font-size: 16px">RESULT: {{ result }}</span>
                  </v-toolbar-title>
                </v-toolbar>
              </v-dialog>
              <v-dialog v-model="imgdialog" v-else width="425px">
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <span style="font-size: 15px">TIME:{{ rtime }}</span>
                    &nbsp;&nbsp;<span style="font-size: 15px">BAC: {{ bacvalue }}</span></v-toolbar-title>
                  <v-toolbar-title v-else>
                    <span style="font-size: 11px">TIME:{{ rtime }}</span>&nbsp;<span style="font-size: 11px">BAC: {{
                      bacvalue }}</span></v-toolbar-title>
                  <v-layout justify-end>
                    <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"
                      style="background: black; padding: 4px; border-radius: 12px">mdi-download</v-icon>

                    <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white" class="mr-4"
                      style="background: black; padding: 4px; border-radius: 12px">mdi-fullscreen</v-icon>

                    <v-btn color="black" small @click="imgclose" dark style="border-radius: 20px"><v-icon color="white">
                        mdi-close </v-icon></v-btn>
                  </v-layout>
                </v-toolbar>
                <v-img id="my-video"
                  :style="{ display: 'block', margin: '0 auto', width: imageSize > 10 ? '600px' : '325px', height: imageSize > 10 ? '600px' : '200px', border: result == 'FAIL' ? '4px solid red' : '3px solid green', }"
                  v-bind:src="'data:image/jpeg;base64,' + imageBytes" v-if="imageSize > 1" />
                <v-card-text v-if="imageSize < 1" class="text-center">
                  <h2 :style="{
                    color: 'white',
                    padding: '5px',
                    borderRadius: '20px',
                    fontSize: '20px',
                    margin: '0 auto' // Center horizontally
                  }">
                    Image Not Found
                  </h2>
                </v-card-text>
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>
                    <span style="font-size: 16px">RESULT: {{ result }}</span>
                  </v-toolbar-title>
                </v-toolbar>
              </v-dialog>
            </template>
            <v-dialog v-model="newdialog" max-width="800px" max-height="300px">
              <v-card class="elevation-12">
                <div>
                  <p style="color:green; text-align:center; font-size:20px; font-weight:bold;">
                    Select Payment Method
                  </p>
                  <v-btn color="green" style="border-radius:8px;margin-left:720px;margin-top:-65px;width:10px;"
                    @click="paymentclose">
                    <v-icon color="#FFFFFF" size="25px">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div class="container">
                  <v-layout justify-center>
                    <v-btn class="mr-4" @click="submit2(onPaying)" dark style="background: #e28743; color: white">Pay
                      Online</v-btn>
                    <v-btn class="primary" left @click="payCash">Pay Cash</v-btn>
                    <v-btn class="info" left style="margin-left:20px" @click="sendpaymentLink(onPaying)">Send Payment
                      Link</v-btn>
                  </v-layout>
                </div>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog21" max-width="800px">
              <v-card class="elevation-12">
                <v-toolbar text color="teal" class="" dark dense>
                  <v-row v-if="onPaying != null">
                    <v-toolbar-title style="margin-left: 10px; color: black"><v-icon color="white"
                        class="mr-4">mdi-cash-multiple</v-icon>Payment Link will send to: </v-toolbar-title>
                    <v-layout justify-end>
                      <v-btn color="primary" @click="linkclose" dark
                        v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="linkclose">
                        mdi-close
                      </v-icon>
                    </v-layout>
                  </v-row>
                </v-toolbar>

                <!-- <template> -->

                <v-card-text style="margin-top:20px">
                  <v-form>
                    <v-text-field v-model="linkemail" prepend-icon="mdi-email" name="dealerEmail"
                      label="Email"></v-text-field>
                    <v-text-field v-model="linkmobile" prepend-icon="mdi-phone" name="dealerMobile"
                      label="Mobile"></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-layout justify-end>
                    <v-btn color="#666600" v-if="linkemail != ''" @click="confirmSendlink" style="color: white">SEND
                      LINK</v-btn>
                  </v-layout>
                </v-card-actions>
              </v-card>
              <!-- <v-card class="elevation-12">
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col class="col-md-8" align="center">
              <div
                style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                "
              >
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount $xxx
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card> -->
            </v-dialog>
            <v-dialog v-model="dialog19" max-width="800px">
              <v-card class="elevation-12">
                <v-toolbar text color="teal" class="" dark dense>
                  <v-row v-if="onPaying != null">
                    <v-toolbar-title style="margin-left: 10px; color: black"><v-icon color="white"
                        class="mr-4">mdi-cash-multiple</v-icon>You
                      are paying the Cash amount ${{ onPaying.invoc.pending }} </v-toolbar-title>
                    <v-layout justify-end>
                      <v-btn color="primary" @click="closepaycash" dark
                        v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="closepaycash">
                        mdi-close
                      </v-icon>
                    </v-layout>
                  </v-row>
                </v-toolbar>

                <!-- <template> -->

                <v-card-text style="margin-top:20px">
                  <v-form>
                    <!-- <v-text-field
              v-model="dealeremail"
              label="Email"
              name="dealeremail"
              prepend-icon="mdi-account"
              type="text"
            ></v-text-field> -->
                    <!-- <div
                style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                "
              >
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount $xxx 
              </div> -->
                    <v-text-field v-model="mydealerpassword" prepend-icon="mdi-lock" name="dealerpassword"
                      label="EnterPassword" counter @click:append="show2 = !show2"></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-layout justify-end>
                    <v-btn color="#666600" @click="confirmPaycash" style="color: white">CASH PAID</v-btn>
                  </v-layout>
                </v-card-actions>
              </v-card>
              <!-- <v-card class="elevation-12">
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col class="col-md-8" align="center">
              <div
                style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                "
              >
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount $xxx
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card> -->
            </v-dialog>
            <v-dialog v-model="dialog5" max-width="500px">
              <v-card style="border-radius: 20px">
                <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                  <v-toolbar-title>More</v-toolbar-title>
                  <v-layout justify-end>
                    <v-icon color="white" @click="Prohibitionclose" style="
                        border-radius: 20px;
                        background: black;
                        padding: 5px;
                      ">mdi-close</v-icon>
                  </v-layout>
                </v-toolbar>
                <v-card-title class="pa-3">
                  <!-- &nbsp;&nbsp; -->
                  <v-row>
                    <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="success" width="250px" @click="calTracking(myclient)" style="color: white">
                        History
                      </v-btn>
                    </v-col>
                    <v-col v-if="user.state != 'INDIA'" cols="12" sm="12" md="12" align="center">
                      <v-btn color="primary" width="250px" @click="dSign(myclient)" style="color: white">
                        920 Form
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="orange" width="250px" @click="editItem(myclient)" style="color: white">
                        VIEW/EDIT
                      </v-btn>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="#334c3e" @click="AddEditmore(myclient)" style="color:white">
                        Prohibition
                      </v-btn>
                    </v-col> -->
                    <v-col v-if="user.state != 'INDIA'" cols="12" sm="12" md="12" align="center">
                      <v-btn color="red" width="250px" @click.prevent="addInvoice(myclient)" style="color: white">
                        Add Invoice
                      </v-btn>
                    </v-col>
                    <v-col v-if="user.state != 'INDIA'" cols="12" sm="12" md="12" align="center">
                      <v-btn color="purple" width="250px" @click="viewInvoices2" style="color: white">
                        Invoices
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="blue" width="250px" @click="getReport(myclient)" style="color: white">
                        GET FULL REPORT
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="teal" width="250px" @click="getReport2(myclient)" style="color: white">
                        GET REPORT(LAST 2MONTHS)
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="center">
                      <v-btn color="#8d6e63" width="250px" @click="specialOffers(myclient)" style="color: white">
                        Special Offers
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-dialog>

            <v-dialog v-model="signdialog" max-width="400px">
              <v-toolbar text color="orange">
                <v-toolbar-title>Do You Want to Add?</v-toolbar-title>
                <v-layout justify-end>
                  <v-icon color="primary" size="30px" @click="closeacc">
                    mdi-close
                  </v-icon>
                </v-layout>
              </v-toolbar>
              <v-row>
                <v-btn color="blue" @click="acceptance(myclient)" style="margin-left: 30px">
                  Acceptance
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" @click="newacceptance(myclient)" style="margin-right: 10px">
                  920 Form
                </v-btn>
              </v-row>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-row>
                  <v-col cols="12" mx="4">
                    <v-layout justify-end>
                      <!-- <v-btn color="black"  class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg && user.emailId != 'Justin@alcobrake.com'" style="border-radius:20px">
                  Add Client
                </v-btn> -->
                      <v-btn v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on"
                        style="border-radius: 20px"><v-icon>mdi-account-plus</v-icon></v-btn>
                    </v-layout>
                  </v-col>
                </v-row>
              </template>
              <v-card color="grey lighten-4" text style="border-radius: 20px">
                <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-form autocomplete="off" required ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col v-if="
                          user.role == 'Admin' || user.role == 'superadmin'
                        " cols="12" sm="12" md="12">
                          <!-- <v-autocomplete outlined dense label="RoteId*" class="purple-input" v-model="routeId"
                            :items="selectedRouteId" item-text="routeId" item-value="routeId"> -->
                          <!-- <template slot="selection">
                              {{ item.routeId }}
                            </template> -->
                          <!-- </v-autocomplete> -->
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense :disabled="isDisabled" required :rules="nameRules"
                            v-model="editedItem.client" label="Name(FIRST/MIDDLE/LAST)*"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            v-on:keypress="isNumber($event)"
                            disabled
                            v-model="editedItem.clientId"
                            label="ID*"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense label="Suffix" required :disabled="isDisabled"
                            class="purple-input" v-model="editedItem.suffix" :items="['JR.', 'SR.', 'III']">
                            <!-- :rules="suffixRules" -->
                            <template slot="selection" slot-scope="data">
                              {{ data.item }}
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <div class="left-btn">
                          <vue-country-code @onSelect="onSelect" :enabledCountryCode="true"
                            :defaultCountry="countrycode" style="border: none" :preferredCountries="['in', 'us', 'gb']">
                            <input type="text" v-model="editedItem.clienthome" name="phone" id="phone"
                              placeholder="Home Telephone" />
                          </vue-country-code>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="left-btn">
                          <v-text-field outlined dense v-on:keypress="isNumber($event)" :rules="phoneRules"
                            :disabled="isDisabled" v-model="editedItem.clientphone1" label="Home Telephone*"
                            class="textfeild"></v-text-field>
                        </div>
                        &nbsp;
                        <div class="left-btn">
                          <vue-country-code @onSelect="onSelect2" :enabledCountryCode="true"
                            :defaultCountry="countrycode" style="border: none" :preferredCountries="['in', 'us', 'gb']">
                            <input type="text" v-model="editedItem.clientwork" name="phone" id="phone"
                              placeholder="Work Telephone" />
                          </vue-country-code>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="left-btn">
                          <v-text-field outlined dense :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.clientphone2" label="Work Telephone" class="textfeild"></v-text-field>
                        </div>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense :disabled="isDisabled" :rules="emailRules"
                            v-model="editedItem.email" label="Email*"></v-text-field>
                        </v-col>
                        <!-- <v-col sm="12" md="6">
                            <v-menu
                              v-model="menu16"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field outlined
                                  dense
                                  required
                                  v-model="editedItem.dob"
                                  :disabled="isDisabled"
                                  label="DOB"
                                  :rules="dobRules"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.dob"
                                @input="menu16 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="adddobmenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined :value="computedaddDateFormatted" label="DOB" hint="MM/DD/YYYY"
                                persistent-hint readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.dob" no-title
                              @input="adddobmenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense required
                            v-model="editedItem.dlnum" :rules="dlRules" :disabled="isDisabled"
                            label="Driving Licence"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <div dense style="
                              background: #00a3a3;
                              color: white;
                              padding: 8px;
                              border-radius: 20px;
                              font-size: 20px;
                            ">
                            Mailing Address
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required :disabled="isDisabled"
                            v-model="editedItem.clientAddress" label="Mailing Address"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.space" label="Appartment/Space Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.city" label="City"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete dense outlined required :disabled="isDisabled" v-model="editedItem.state"
                            :items="states" label="Select State"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.zipcode" label="Zip"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox false-value="0" true-value="1" v-model="editedItem.isresidsame"
                            :label="`Residential Address (Same As Mailing Address)`"></v-checkbox>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="12">
                          <v-row>
                            <v-toolbar text color="#00A3A3" dark dense style="border-radius: 20px">
                              <v-toolbar-title>Residential Address</v-toolbar-title>
                            </v-toolbar>
                          </v-row>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :rules="addressRules"
                            :disabled="isDisabled" v-model="editedItem.rclientAddress"
                            label="Residential Address*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense outlined v-on:keypress="isLetter($event)" :rules="addressRules"
                            :disabled="isDisabled" v-model="editedItem.rspace"
                            label="Appartment/Space Number*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :rules="cityRules"
                            :disabled="isDisabled" v-model="editedItem.rcity" label="City*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-autocomplete dense outlined required :disabled="isDisabled" :rules="stateRules"
                            v-model="editedItem.rstate" :items="states" label="Select State*(R)"></v-autocomplete>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense required :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            :rules="zipRules" v-model="editedItem.rzipcode" label="Zip*(R)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <div dense style="
                              background: #00a3a3;
                              color: white;
                              padding: 8px;
                              border-radius: 20px;
                              font-size: 20px;
                            ">
                            Manufacturer/Facility Details
                          </div>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            value="Alcobrake"
                            v-model="editedItem.manufacturer"
                            label="Manufacturer"
                            required
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense label="Select Facility*" required :rules="facilityRules"
                            :disabled="isDisabled" class="purple-input" v-model="editedItem.officeId"
                            @change="fetchOfficedetails()" :items="offices" item-text="officeName" return-object>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.officeName }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-checkbox v-if="editedItem.officeId" disabled false-value="0" true-value="1"
                          v-model="editedItem.isbar" :label="`If BAR License Number`"></v-checkbox>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense disabled :rules="nameRules"
                            v-model="editedItem.officelicensenumber" :label="editedItem.isbar == '1' && editedItem.officeId
                              ? 'BAR License'
                              : 'BEARHFTI License'
                              " required></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field outlined dense required v-on:keypress="isLetter($event)" :rules="addressRules"
                            disabled v-model="editedItem.faddress" label="Facility Address*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :rules="cityRules"
                            disabled v-model="editedItem.fcity" label="City*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)" :rules="stateRules"
                            disabled v-model="editedItem.fstate" label="State*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense outlined required disabled v-on:keypress="isNumber($event)"
                            :rules="zipRules" v-model="editedItem.fzipcode" label="Zip*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <div dense style="
                              background: #00a3a3;
                              color: white;
                              padding: 8px;
                              border-radius: 20px;
                              font-size: 20px;
                            ">
                            Device & Vehicle Details
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete label="Select Device*" required outlined v-model="editedItem.sno"
                            class="purple-input" :items="devices" :rules="selectdevRules" item-text="sno"
                            item-value="sno">
                            <template slot="selection" slot-scope="data">
                              {{ data.item.sno }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            disabled
                            v-model="editedItem.cbid"
                            label="ControlId*"
                          ></v-text-field>
                        </v-col> -->
                        <!-- ADD -->
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required :rules="vinRules" v-on:keypress="isLetter($event)"
                            v-model="editedItem.vin" label="VIN(Vehicle Identification Number)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense required :rules="regRules" v-model="editedItem.regnum"
                            label="License Plate Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined v-on:keypress="isLetter($event)" dense required
                            v-model="editedItem.tagstate" :items="states" :rules="tagStateRules"
                            label="Tag State"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete dense outlined @change="getCalibdetails"
                            v-model="editedItem.calibrationperiod" :items="['30', '60', '90']"
                            label="Calibration Period">
                            <template slot="selection" slot-scope="data">
                              {{ data.item }} days
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="editedItem.installDate" dense outlined :rules="installDateRules"
                                :value="formatInstalldate" required label="Install Date" clearable
                                v-on="on"></v-text-field>
                            </template>
                            <v-datetime-picker v-model="editedItem.installDate"
                              @input="menu = false"></v-datetime-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense :value="formatcalDate" :rules="calibDateRules" disabled
                                required label="Calibration Date" readonly v-on="on"></v-text-field>
                            </template>
                            <v-datetime-picker v-model="editedItem.calibrationDate"
                              @input="menu4 = false"></v-datetime-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <v-menu v-model="menu10" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense required disabled :value="formatnextcalDate"
                                label="Date of Next Monitor(check)" readonly v-on="on"></v-text-field>
                            </template>
                            <v-datetime-picker v-model="editedItem.nextcmonitordate"
                              @input="menu10 = false"></v-datetime-picker>
                          </v-menu>
                        </v-col>
                        <!-- <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.nextcmonitordate"
                              label="Date of Next Monitor(check)"
                            ></v-text-field>
                          </v-col> -->

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.make"
                            label="Make"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense required
                            v-model="editedItem.model" label="Model"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isNumber($event)" dense required
                            v-model="editedItem.year" label="Year"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense required v-model="editedItem.color" label="Color"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense outlined required v-on:keypress="isLetter($event)"
                            v-model="editedItem.tag" label="Tag Number"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense required
                            v-model="editedItem.prohibitionnumber" label="Probation Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense required v-model="editedItem.conviction"
                            :items="['Conviction', 'Diversion']" label="Conviction/Diversion"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox false-value="0" true-value="1" v-model="editedItem.isunavailablevehicle"
                            :label="`Subscription`"></v-checkbox>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="12">
                          <v-row>
                            <v-toolbar text color="#26BB44" class="" dark dense>
                              <v-toolbar-title>Vehicle</v-toolbar-title>
                            </v-toolbar>
                          </v-row>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense required
                            v-model="editedItem.umake" :rules="makeRules" label="Make"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isNumber($event)" dense required :rules="yearRules"
                            v-model="editedItem.uyear" label="Year"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense required :rules="regRules" v-model="editedItem.uregnum"
                            label="License Plate Number"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense :rules="vinRules" required v-on:keypress="isLetter($event)"
                            v-model="editedItem.uvin" label="VIN(Vehicle Identification Number)"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <div dense style="
                              background: #00a3a3;
                              color: white;
                              padding: 8px;
                              border-radius: 20px;
                              font-size: 20px;
                            ">
                            Facility Inspection
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu12" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense v-model="editedItem.prohibstart"
                                label="Date of Notice of Non Compliance" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.prohibstart" @input="menu12 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu13" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense required v-model="editedItem.prohibend"
                                label="Date of Inspection Device" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.prohibend" @input="menu13 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense required :rules="installDateRules"
                                v-model="editedItem.installDate" label="Installation Date" readonly
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.installDate" @input="menu6 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense label="Select Installer*" required :rules="installerRules"
                            class="purple-input" v-model="editedItem.installer" :items="users" item-text="userName"
                            return-object>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.userName }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col v-if="editedIndex.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field dense :rules="phoneRules" required v-on:keypress="isLetter($event)"
                            v-model="editedItem.imobile" label="Installer Mobile"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <label>Driver License photo</label>
                          <input :v-model="editedItem.licensefile" type="file" accept="image/jpeg"
                            @change="uploadlicenseImage" id="licensefile" />
                          <label style="color: green; font-size: 11px" v-if="editedItem.licensefile">Correct JPG
                            Format</label>
                          <label style="color: red; font-size: 12px" v-else>Choose only JPG Format</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <label>Ticket photo</label>
                          <input :v-model="editedItem.ticketfile" type="file" accept="image/jpeg"
                            @change="uploadticketImage" id="ticketfile" />
                          <label style="color: green; font-size: 11px" v-if="editedItem.ticketfile">Correct JPG
                            Format</label>
                          <label style="color: red; font-size: 12px" v-else>Choose only JPG Format</label>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="12"> -->
                        <v-checkbox false-value="0" true-value="1" v-model="editedItem.isinstalledandworking"
                          :label="`Working Device is installed in the above vehicle`"></v-checkbox>
                        <!-- </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="12"> -->
                        <v-checkbox false-value="0" true-value="1" v-model="editedItem.driverisincompliance"
                          :label="`Above Driver is in compliance with the device maintainance and calibration requirements`"></v-checkbox>
                        <!-- </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="addclose" style="background: #ff3d41; color: white">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save5">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog3" max-width="600px">
              <v-card color="grey lighten-4" text style="border-radius: 20px">
                <v-toolbar text color="#00A3A3" dark dense style="border-radius: 20px">
                  <v-toolbar-title>View/Edit Client</v-toolbar-title>
                </v-toolbar>
                <v-form autocomplete="off" ref="form3" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-model="editedItem.client"
                            label="Name(FIRST/MIDDLE/LAST)*" required></v-text-field>
                        </v-col>
                        <v-col v-else cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-model="editedItem.client"
                            label="Name(FIRST/MIDDLE/LAST)*" required></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            v-on:keypress="isNumber($event)"
                            disabled
                            v-model="editedItem.clientId"
                            label="ID*"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete label="Suffix*" :disabled="isDisabled" class="purple-input"
                            v-model="editedItem.suffix" :items="['JR.', 'SR.', 'III']">
                            <template slot="selection" slot-scope="data">
                              {{ data.item }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isNumber($event)" :disabled="isDisabled"
                            v-model="editedItem.clientphone1" label="Home Telephone*"></v-text-field>
                        </v-col>
                        <v-col v-else cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isNumber($event)" :disabled="isDisabled"
                            v-model="editedItem.clientphone1" label="Home Telephone*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.clientphone2" label="Work Telephone"></v-text-field>
                        </v-col>
                        <v-col v-else cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.clientphone2" label="Work Telephone"></v-text-field>
                        </v-col>
                        <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-model="editedItem.email"
                            label="Email*"></v-text-field>
                        </v-col>
                        <v-col v-else cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-model="editedItem.email"
                            label="Email*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="dobmenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :disabled="isDisabled" :value="computedDateFormatted" label="DOB"
                                hint="MM/DD/YYYY" persistent-hint readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.dob" no-title @input="dobmenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field v-on:keypress="isLetter($event)" dense v-model="editedItem.dlnum"
                            :disabled="isDisabled" label="Driving Licence"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                            <v-toolbar-title>Mailing Address</v-toolbar-title>
                          </v-toolbar>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-model="editedItem.clientAddress"
                            label="Mailing Address*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.space" label="Appartment/Space Number*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.city" label="City*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete dense :disabled="isDisabled" v-model="editedItem.state" :items="states"
                            label="Select State*"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.zipcode" label="Zip*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox :disabled="isDisabled" false-value="0" true-value="1"
                            v-model="editedItem.isresidsame"
                            :label="`Residential Address (Same As Mailing Address)`"></v-checkbox>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="12">
                          <div style="
                              background: #00a3a3 !important;
                              color: white;
                              padding: 8px;
                              border-radius: 20px;
                              font-size: 20px;
                            ">
                            Residential Address
                          </div>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.rclientAddress" label="Residential Address*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.rspace" label="Appartment/Space Number*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" :disabled="isDisabled"
                            v-model="editedItem.rcity" label="City*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-autocomplete dense :disabled="isDisabled" v-model="editedItem.rstate" :items="states"
                            label="Select State*(R)"></v-autocomplete>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense :disabled="isDisabled" v-on:keypress="isNumber($event)"
                            v-model="editedItem.rzipcode" label="Zip*(R)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-toolbar color="#00A3A3" flat dark dense style="border-radius: 20px">
                            <v-toolbar-title>Manufacturer/Facility Details</v-toolbar-title>
                          </v-toolbar>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            value="Alcobrake"
                            v-model="editedItem.manufacturer"
                            label="Manufacturer"
                            required
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete label="Select Facility*" :disabled="isDisabled" class="purple-input"
                            v-model="editedItem.officeId" @change="fetchOfficedetails()" :items="offices"
                            item-text="officeName" return-object>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.officeName }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-checkbox v-if="editedItem.officeId" disabled false-value="0" true-value="1"
                          v-model="editedItem.isbar" :label="`If BAR License Number`"></v-checkbox>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense disabled v-model="editedItem.officelicensenumber" :label="editedItem.isbar == '1' && editedItem.officeId
                            ? 'BAR License'
                            : 'BEARHFTI License'
                            " required></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" disabled v-model="editedItem.faddress"
                            label="Facility Address*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" disabled v-model="editedItem.fcity"
                            label="City*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" disabled v-model="editedItem.fstate"
                            label="State*"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.officeId" cols="12" sm="12" md="6">
                          <v-text-field dense disabled v-on:keypress="isNumber($event)" v-model="editedItem.fzipcode"
                            label="Zip*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-toolbar text color="#00A3A3" class="tool" dense style="border-radius: 20px; color: white">
                            <v-toolbar-title>Device & Vehicle Details</v-toolbar-title>
                          </v-toolbar>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined label="Select Device*" dense :rules="selectdevRules"
                            class="purple-input" v-model="editedItem.sno" :items="devices" item-text="sno"
                            item-value="sno">
                            <template slot="selection" slot-scope="data">
                              {{ data.item.sno }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            disabled
                            v-model="editedItem.cbid"
                            label="ControlId*"
                          ></v-text-field>
                        </v-col> -->
                        <!-- Edit -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense v-model="editedItem.calibrationperiod"
                            :items="['30', '60', '90']" @change="getCalibdetails" label="Calibration Period">
                            <template slot="selection" slot-scope="data">
                              {{ data.item }} days
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <v-text-field outlined dense required :value="formatInstalldate"
                            v-model="editedItem.installDate" label="Install Date"></v-text-field>
                          <!-- <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field 
                                v-model="editedItem.installDate"
                                outlined
                                  dense
                                  :value="formatInstalldate"
                                  label="Install Date"
                                  clearable
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-datetime-picker
                                v-model="editedItem.installDate"
                                @change="menu = false"
                                locale="de"
                              ></v-datetime-picker>
                            </v-menu> -->
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <!-- <v-menu
                            v-model="menu4"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined
                                dense
                                :value="formatcalDate"
                                v-model="editedItem.calibrationDate"
                                label="Calibration Date*"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-datetime-picker
                              v-model="editedItem.calibrationDate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="menu4 = false"
                            ></v-datetime-picker>
                          </v-menu> -->
                          <v-text-field outlined dense required :value="formatcalDate"
                            v-model="editedItem.calibrationDate" label="Calibration Date"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.calibrationperiod" cols="12" sm="12" md="6">
                          <!-- <v-menu
                            v-model="menu11"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined
                                dense
                                :value="formatnextcalDate"
                                label="Date of Next Monitor(check)"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-datetime-picker
                              v-model="editedItem.nextcmonitordate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="menu11 = false"
                            ></v-datetime-picker>
                          </v-menu> -->
                          <v-text-field outlined dense required :value="formatnextcalDate"
                            v-model="editedItem.nextcmonitordate" label="Date of Next Monitor(check)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-model="editedItem.regnum"
                            label="License Plate Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.make"
                            label="Make"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.model"
                            label="Model"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isNumber($event)" dense v-model="editedItem.year"
                            label="Year"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-model="editedItem.color" label="Color"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.vin"
                            label="VIN(Vehicle Identification Number)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.tag"
                            label="Tag Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.tagstate"
                            :items="states" label="Tag State"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense
                            v-model="editedItem.prohibitionnumber" label="Probation Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense v-model="editedItem.conviction"
                            :items="['Conviction', 'Diversion']" label="Conviction/Diversion"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox false-value="0" true-value="1" v-model="editedItem.isunavailablevehicle"
                            :label="`Subscription`"></v-checkbox>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="12">
                          <v-row>
                            <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                              <v-toolbar-title>Vehicle</v-toolbar-title>
                            </v-toolbar>
                          </v-row>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.umake"
                            label="Make"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined v-on:keypress="isNumber($event)" dense v-model="editedItem.uyear"
                            label="Year"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-model="editedItem.uregnum"
                            label="License Plate Number"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.uvin"
                            label="VIN(Vehicle Identification Number)"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius: 20px">
                            <v-toolbar-title>Facility Inspection</v-toolbar-title>
                          </v-toolbar>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense v-model="editedItem.prohibstart"
                                label="Date of Notice of Non Compliance" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.prohibstart" @input="menu5 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field outlined dense v-model="editedItem.prohibend"
                                label="Date of Inspection Device" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.prohibend" @input="menu3 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense outlined v-model="editedItem.installDate" label="Installation Date"
                                readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.installDate" @input="menu6 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete outlined dense label="Select Installer*" class="purple-input"
                            v-model="editedItem.installer" :items="users" item-text="userName" return-object>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.userName }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                            <label>Driver License photo</label>
                           <input :v-model="editedItem.licensefile"  type="file" accept="image/*" @change="uploadlicenseImage" id="file-input"/>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <label>Ticket photo</label>
                            <input :v-model="editedItem.ticketfile"  type="file" accept="image/*" @change="uploadticketImage2" id="file-input"/>
                          </v-col> -->
                        <v-col v-if="editedIndex.isunavailablevehicle == '1'" cols="12" sm="12" md="6">
                          <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.imobile"
                            label="Installer Mobile"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="12"> -->
                        <v-checkbox false-value="0" true-value="1" v-model="editedItem.isinstalledandworking"
                          :label="`Working Device is installed in the above vehicle`"></v-checkbox>
                        <!-- </v-col> -->
                        <!-- <v-col cols="12" sm="12" md="12"> -->
                        <v-checkbox false-value="0" true-value="1" v-model="editedItem.driverisincompliance"
                          :label="`Above Driver is in compliance with the device maintainance and calibration requirements`"></v-checkbox>
                        <!-- </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="close3" style="background: #ff3d41; color: white">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog2" max-width="600px">
              <v-card color="grey lighten-4" text>
                <v-toolbar text color="#26BB44" class="" dark dense>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-form autocomplete="off" ref="form2" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense :rules="nameRules" v-on:keypress="isLetter($event)"
                            v-model="editedItem.client" label="Name(FIRST/MIDDLE/LAST)*" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete label="Suffix*" required :rules="suffixRules" :disabled="isDisabled"
                            class="purple-input" v-model="editedItem.suffix" :items="['JR.', 'SR.', 'III']">
                            <template slot="selection" slot-scope="data">
                              {{ data.item }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required disabled v-model="editedItem.regdate"
                            label="Reg Date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete label="Select Office*" required class="purple-input"
                            v-model="editedItem.officeId" :items="offices" item-text="officeName" return-object>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.officeName }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isNumber($event)" :rules="phoneRules"
                            v-model="editedItem.clientphone1" label="Phone*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense :rules="phone2Rules" v-on:keypress="isNumber($event)"
                            v-model="editedItem.clientphone2" label="Phone 2"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu v-model="menu15" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense required v-model="editedItem.dob" :rules="dobRules" label="DOB"
                                readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.dob" @input="menu15 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required :rules="emailRules" v-model="editedItem.email"
                            label="Email*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isLetter($event)" :rules="addressRules"
                            v-model="editedItem.clientAddress" label="Address*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" v-model="editedItem.space"
                            label="Appartment/Space Number*(R)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isLetter($event)" :rules="cityRules"
                            v-model="editedItem.city" label="City*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete dense required :rules="stateRules" v-model="editedItem.state" :items="states"
                            label="Select State*"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isNumber($event)" :rules="zipRules"
                            v-model="editedItem.zipcode" label="Zip*"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6">
                          <v-text-field v-on:keypress="isLetter($event)" dense required v-model="editedItem.dlnum"
                            :rules="dlRules" label="Driving Licence"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox false-value="0" true-value="1" v-model="editedItem.isresidsame"
                            :label="`Residential Address (Same As Mailing Address)`"></v-checkbox>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="12">
                          <v-row>
                            <v-toolbar text color="#26BB44" dark dense>
                              <v-toolbar-title>Residential Address</v-toolbar-title>
                            </v-toolbar>
                          </v-row>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isLetter($event)" :rules="addressRules"
                            v-model="editedItem.rclientAddress" label="Residential Address*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense v-on:keypress="isLetter($event)" v-model="editedItem.rspace"
                            label="Appartment/Space Number*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isLetter($event)" :rules="cityRules"
                            v-model="editedItem.rcity" label="City*(R)"></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-autocomplete dense required :rules="stateRules" v-model="editedItem.rstate" :items="states"
                            label="Select State*(R)"></v-autocomplete>
                        </v-col>
                        <v-col v-if="editedItem.isresidsame != '1'" cols="12" sm="12" md="6">
                          <v-text-field dense required v-on:keypress="isNumber($event)" :rules="zipRules"
                            v-model="editedItem.rzipcode" label="Zip*(R)"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close2">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save2" :disabled="!valid">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </v-card>
      </template>

      <template slot="item.action" slot-scope="{ item }">
        <v-icon class="mt-2" color="orange" @click="editItem(item)">
          mdi-border-color
        </v-icon>
        <!-- <v-icon color="red" @click="deleteItem2(item)">
            mdi-delete
          </v-icon> -->
      </template>

      <template slot="item.delete" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" dark v-bind="attrs" v-on="on" @click="deleteItem2(item)">
              mdi-arrow-right-bold-hexagon-outline
            </v-icon>
          </template>
          <span>Add To Removals</span>
        </v-tooltip>
      </template>

      <template slot="item.swap" slot-scope="{ item }">
        <v-icon class="mt-2" color="orange" @click="SwapDevice(item)">
          mdi-swap-horizontal
        </v-icon>
      </template>

      <template slot="item.addmore" slot-scope="{ item }">
        <v-icon class="mt-2" color="#26BB44" @click="AddEditmore2(item)">
          mdi-plus
        </v-icon>
      </template>
      <template slot="item.isunavailablevehicle" slot-scope="{ item }">
        <!-- <h1>{{ item.isunavailablevehicle }}</h1> -->

        <v-switch :input-value="item.isunavailablevehicle == '1'" flat
          :label="item.isunavailablevehicle == '1' ? 'Subscribed' : 'Unsubscribed'"
          @change="issubscribeChange(item)"></v-switch>
      </template>
      <template slot="item.autorenewal" slot-scope="{ item }">
        <!-- <h1>{{ item.isunavailablevehicle }}</h1> -->

        <v-switch :input-value="item.autorenewal == '1'" flat :label="item.autorenewal == '1' ? 'Disable' : 'Enable'"
          @change="isautorenewChange(item)"></v-switch>
      </template>
      <!-- <template slot=item.addmore slot-scope="{ item }">
          <v-icon class="mt-2" color="#26BB44" @click="csvup(item)">
            mdi-plus
          </v-icon>
        </template> -->
      <template slot="item.dlphoto" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img src="../assets/licence.jpg" alt="" v-bind="attrs" v-on="on" width="25" height="20"
              @click="DLimage(item)" />
          </template>
          <span>Driver License</span>
        </v-tooltip>
      </template>

      <template slot="item.Probhition" slot-scope="{ item }">
        <v-icon class="mt-2" color="#26BB44" @click="AddEditmore(item)">
          mdi-plus
        </v-icon>
      </template>
      <template slot="item.pay" slot-scope="{ item }">
        <!-- <v-icon class="mt-2" color="#26BB44" @click="addInvoice(item)">
            mdi-plus
          </v-icon> -->
        <v-btn class="primary" @click="addInvoice(item)">PAY</v-btn>
      </template>

      <template slot="item.location" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" v-bind="attrs" v-on="on" @click="openMap(item)">
              mdi-crosshairs-gps
            </v-icon>
          </template>
          <span>GPS Location</span>
        </v-tooltip>
      </template>

      <template slot="item.vreset" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" v-bind="attrs" v-on="on" v-on:click="goCommand(item, '85', firmware)" size="25"
              dense>mdi-restore</v-icon>
          </template>
          <span>Voilation Reset</span>
        </v-tooltip>
      </template>
      <!-- <template slot=item.isunavailablevehicle slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"> -->
      <!-- <v-btn class="primary" v-on="on" v-on:click="onSubscribe(item)">{{ item.isunavailablevehicle == 0 ? "SUBSCRIBE
                          NOW":"SUBSCRIBED"}}</v-btn> -->
      <!-- <v-icon right color="green"  v-bind="attrs"
          v-on="on" v-on:click="onSubscribe(item)" size="30" dense>mdi-subscr</v-icon> -->
      <!-- </template>
          <span>History</span>
        </v-tooltip>
      </template> -->
      <template v-if="user.state != 'INDIA'" slot="item.invoc" slot-scope="{ item }">
        <v-tooltip hide-details>
          <template v-slot:activator="{ on, attrs }">
            <div v-if="item.invoc && item.invoc.pending != 0">
              <v-btn color="orange" v-bind="attrs" style="color: white; border-radius: 50px" height="30px" width="5px"
                v-on="on" v-on:click="submit3(item)">{{ "$" + item.invoc.pending }}</v-btn>
            </div>
            <div v-else-if="item.invoc && item.invoc.pending == 0">NO DUE</div>
            <div v-else>NO INVOICES</div>
            <!-- <v-icon right color="green"  v-bind="attrs"
          v-on="on" v-on:click="onSubscribe(item)" size="30" dense>mdi-subscr</v-icon> -->
          </template>
          <span>History</span>
        </v-tooltip>
      </template>

      <template slot="item.acceptance" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="dSign(item)">
              mdi-note-text
            </v-icon>
          </template>
          <span>Acceptance</span>
        </v-tooltip>
        <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="dSign(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View File</span>
    </v-tooltip> -->
      </template>

      <template slot="item.resetdevice" slot-scope="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="goCommand(item, '85', firmware)">
              mdi-restart
            </v-icon>
          </template>
          <span>Violation Reset</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="initialize">NO DATA</v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="ma-0 pa-0">
          <v-col class="col-md-12">
            <v-row class="justify-center">
              <v-col class="col-md-3">
                <v-card class="elevation-8" text tile height="100%" style="border-radius: 20px !important">
                  <v-toolbar color="#334c3e" style="border-radius: 20px; color: white">
                    <v-toolbar-title>
                      Device
                      <v-icon class="ml-4" size="25" color="white" id="myBlinkingDiv" dense>{{ fgicon }}</v-icon>

                      <v-icon class="ml-4" size="25" color="white" id="myBlinkingDiv2" dense>{{ wifiicon }}</v-icon>
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-card-text class="p-right"><b class="p-left">DeviceId:</b>
                    {{ item.cbid }}</v-card-text>
                  <v-card-text class="p-right"><b class="p-left">HandSet:</b>
                    {{ item.devId }}</v-card-text>
                  <v-card-text class="p-right"><b class="p-left">S/B version:</b>
                    {{ firmware }}</v-card-text>
                  <v-card-text class="p-right"><b class="p-left">C/B version:</b>
                    {{ cbversion }}</v-card-text>
                  <v-card-text class="p-right" v-if="firmware > 34"><b class="p-left">S3 Version:</b>{{ s3version }}
                  </v-card-text>
                  <v-card-text class="p-right"><b class="p-left">Calibration Date:</b>
                    {{ calevent }}</v-card-text>
                  <v-card-text class="p-right"><b class="p-left">Lockout Date:</b>
                    {{ nextcalevent }}</v-card-text>
                  <v-card-text class="p-right"><b class="p-left">Last Update:</b>
                    {{ caldetails.lastevent }}</v-card-text>
                  <!-- <v-card-text></v-card-text> -->
                  <v-card-text class="p-right"><b class="p-left">Emergency OTP's</b></v-card-text>
                  <div v-for="row in otpRows" :key="row.id" class="row"
                    style=" display: flex;margin-left: 50px;margin-bottom:20px;">
                    <v-chip v-for="otp in row" :key="otp.position" :style="{
                      marginRight: '10px',
                      color: 'white',
                      backgroundColor: otp.otp !== 'XXXX' ? 'teal' : 'grey  '
                    }">
                      {{ otp.otp }}
                    </v-chip>
                  </div>
                  <!-- <v-card-text></v-card-text>
                    <v-card-text></v-card-text>
                    <v-card-text></v-card-text> -->
                  <!-- <v-chip class="ma-2" color="#77b588">
                    <b>Last Update:</b>&nbsp;&nbsp;&nbsp;&nbsp;{{caldetails.lastevent}}
                  </v-chip>    -->

                  <v-card style="background: #add8e6; border-radius: 20px;">
                    <v-card-text>
                      <v-switch :value="issmsswitch" v-model="issmsswitch" flat label="SMS Alert"
                        @change="issmsvaluechange(item)"></v-switch>
                    </v-card-text>
                    <!-- <v-card-text>
                      <v-switch
                      :value="faceswitch"
                      v-model="faceswitch"
                      flat
                      label="FACE RECOGNITION"
                      @change="facevaluechange(item)"></v-switch>
                    </v-card-text> -->
                  </v-card>
                </v-card>
              </v-col>

              <v-col class="col-md-3">
                <v-card text tile style="border-radius: 20px !important" class="mt-9 elevation-8">
                  <v-toolbar color="#334c3e" style="border-radius: 20px; color: white">
                    <v-toolbar-title>
                      Invoices({{ invos.invs }})
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-list dense class="ma-0 pa-0">
                    <v-list-item>
                      <v-list-item-content class="p-left">Total amount:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">${{ invos.totalamount }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="p-left">Paid Amount:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">${{ invos.paidamount }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="p-left">Balance:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">${{ invos.pending }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- <div class="wrapper">
                    <stripe-element-card
                    ref="elementsRef"
                    :pk="publishableKey"
                    :amount="amount"
                    locale="en"
                    @token="tokenCreated"
                    @loading="loading = $event"></stripe-element-card></div> -->
                  <v-card-actions v-if="user.role == 'Admin' || user.role == 'superadmin'" align="center">
                    <!-- expand invoice paynow -->

                    <v-btn :disabled="invos.invs == 0" color="primary" @click="submit(invos)">Pay Now
                      <v-icon>mdi-cash</v-icon></v-btn>
                    <v-btn :disabled="invos.invs == 0" color="green" @click="viewInvoices"
                      style="color: white">View<v-icon>mdi-eye</v-icon></v-btn>

                    <!-- <v-btn :disabled="invos.invs==0" color="orange" @click="viewInvoices">View</v-btn> -->
                    <!-- <v-btn :disabled="invos.invs==0" flat color="orange" @click="downInvoices">Download</v-btn> -->
                  </v-card-actions>
                  <v-card-actions v-if="user.role == 'dealer'">
                    <v-btn :disabled="invos.invs == 0" color="primary" @click="submit(invos)">Pay
                      Now<v-icon>mdi-cash</v-icon></v-btn>
                    <v-btn :disabled="invos.invs == 0" color="green" @click="viewInvoices"
                      style="color: white">View<v-icon>mdi-eye</v-icon></v-btn>
                    <!-- <v-btn :disabled="invos.invs==0" color="orange" @click="viewInvoices">View</v-btn> -->
                    <!-- <v-btn :disabled="invos.invs==0" flat color="orange" @click="downInvoices">Download</v-btn> -->
                  </v-card-actions>
                </v-card>
                <v-card text fill-height tile class="mt-8 elevation-8" style="border-radius: 20px !important">
                  <v-toolbar color="#334c3e" style="border-radius: 20px; color: white">
                    <v-toolbar-title> Log File </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <div v-if="user.role == 'Admin' || user.role == 'superadmin'"
                      style="margin-top:8px;margin-left:100px">
                      <h4 style="margin-top:-8px;"> V1</h4>
                    </div>
                    &nbsp;
                    <div class="panel-body" v-if="user.role == 'Admin' || user.role == 'superadmin'"
                      style="margin-top:5px">
                      <label class="switch">
                        <input type="checkbox" v-model="samplercheckbox" @click="toggleChecksampler">
                        <div class="slider round"></div>
                      </label>
                    </div> -->
                  </v-toolbar>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item>
                      <v-list-item-content class="p-left">Total Logs:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">{{
                        logg.total
                      }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="p-left">Pass Logs:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">{{
                        logg.pass
                      }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="p-left">Fail Logs:</v-list-item-content>
                      <v-list-item-content class="align-end p-right">{{
                        logg.fail
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-card-actions v-if="
                    user.role == 'Admin' ||
                    user.role == 'dealer' ||
                    user.role == 'superadmin'
                  ">
                    <v-btn color="green" @click="viewLogs" style="color: white" :disabled="logg.total == '0' || logg.total == 'Loading'
                      ? true
                      : false
                      ">View<v-icon>mdi-eye</v-icon></v-btn>
                    <v-btn color="blue-grey" @click="downloadLogs" :disabled="logg.total == '0' || logg.total == 'Loading'
                      ? true
                      : false
                      " style="color: white">Download<v-icon>mdi-download</v-icon></v-btn>
                  </v-card-actions>
                  <v-card-actions v-else>
                    <v-btn color="green" @click="viewLogs" :disabled="logg.total == '0' || logg.total == 'Loading'
                      ? true
                      : false
                      " style="color: white">View</v-btn>
                    <v-btn color="blue-grey" @click="downloadLogs" :disabled="logg.total == '0' || logg.total == 'Loading'
                      ? true
                      : false
                      " style="color: white">Download</v-btn>
                    <v-btn color="blue-grey" @click="fetchtempLogs(item)"
                      style="color: white"><v-icon>mdi-wifi</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col class="col-md-5">
                <v-card class="elevation-8" text height="100%" d-flex align-items-center justify-content-center tile
                  style="border-radius: 20px !important">
                  <v-toolbar color="#334c3e" style="border-radius: 20px; color: white">
                    <v-toolbar-title> Actions </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="opHistory" class="primary">history<span><v-icon
                          color="white">mdi-arrow-collapse</v-icon></span></v-btn>
                    <!-- <v-icon  @click="opHistory" color='white'>mdi-arrow-collapse</v-icon> -->
                  </v-toolbar>

                  <v-row no-gutters class="justify-center">
                    <v-container class="btn-container" style="margin-top: 15%">
                      <div align="center">
                        <v-btn class="mr-2 mb-5" v-if="user.state != 'INDIA'" color="#80cdb5"
                          @click="viogoCommand(item, '85', firmware)" width="220px"
                          style="color: white;font-weight: bold">RESET VIOLATION<span>
                            <b-icon style="width: 25px" icon="wifi" />/4G
                          </span></v-btn>

                        <v-btn v-if="
                          user.role == 'Admin' || user.role == 'superadmin'" class="mr-2 mb-5" tile color="#5c6bc0"
                          @click="goCommand(item, '84', firmware)" width="220px" style="color: white;font-weight: bold">
                          TAKE TEST&nbsp;<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>
                        <v-btn class="mr-2 mb-5" tile color="#ce93d8" @click="newgoCommand(item, '90', firmware)"
                          width="220px" style="color: white;font-weight: bold">
                          LOCK DEVICE(TE)&nbsp;<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>
                        <v-btn class="mr-2 mb-5" tile color="#64b5f6" @click="newgoCommand(item, '91', firmware)"
                          width="220px" style="color: white;font-weight: bold">
                          UNLOCK DEVICE(TD)<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" v-if="firmware > '0.940000'" color="#a87b32"
                          @click="goCommand(item, '82', firmware)" width="220px"
                          style="color: white;font-weight: bold">Restart Device&nbsp;<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" v-if="
                          user.role == 'Admin' || user.role == 'superadmin'
                        " tile color="green" @click="memgoCommand(item, 0, firmware)" width="220px"
                          style="color: white;font-weight: bold">
                          MEMORY RESET<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" v-if="user.state != 'INDIA'" tile color="#b07474"
                          @click="goCommand(item, '104', firmware)" width="220px"
                          style="color: white;font-weight: bold">Set OTP<span>
                            <b-icon style="width: 25px" icon="wifi" />/4g
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" v-if="user.state != 'INDIA'" tile color="#f0cdb5"
                          @click="goCommandset(item, '86', firmware)" width="220px"
                          style="color: white;font-weight: bold">SET TIME<span>
                            <b-icon style="width: 20px" icon="wifi" />/4G
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" tile
                          v-if="firmware > '0.940000' && user.state != 'INDIA' && user.role == 'superadmin'"
                          color="#f0cd35" @click="goCommandconfig(item, '94', firmware)" width="220px"
                          style="color: black;font-weight: bold;color: white">Device Config<span>/ <b-icon
                              style="width: 20px" icon="wifi" />/4G
                          </span></v-btn>
                        <!-- <v-btn
                          class="mr-2 mb-5"
                          tile
                          v-if="firmware > '0.940000' && user.state != 'INDIA'"
                          color="#e1bee7"
                          @click="goCommandLock(item, '94', firmware)"
                          width="220px"
                          style="color: white;font-weight: bold"
                          >Device Lock<span
                            >/ <b-icon style="width: 20px" icon="wifi" />/4G
                          </span></v-btn
                        > -->

                        <v-btn class="mr-2 mb-5" tile v-else-if="user.state != 'INDIA' && user.role == 'superadmin'"
                          color="#f0cd35" @click="goCommandconfig(item, '95', firmware)" width="220px"
                          style="color: white;font-weight: bold">Device Config<span>/ <b-icon style="width: 20px"
                              icon="wifi" />/4G
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" tile
                          v-if="firmware > '0.940000' && user.state != 'INDIA' && item.cbid != 'ABC_c4_dd_57_8c_a4_9c'"
                          color="#ab7ba3" @click="memgoCommand(item, 1, firmware)" width="220px"
                          style="color: white; font-weight: bold">READ LOG<span>
                            <b-icon style="width: 25px" icon="wifi" />/4G
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" tile
                          v-else-if="user.state != 'INDIA' && item.cbid != 'ABC_c4_dd_57_8c_a4_9c'" color="#ab7ba3"
                          @click="goCommand(item, '97', firmware)" width="220px"
                          style="color: white; font-weight: bold">READ LOG<span>
                            <b-icon style="width: 25px" icon="wifi" /> </span></v-btn>

                        <!-- <v-btn  class="mr-2 mb-5" tile v-else color="#ab7ba3" @click="goCommand(item,'97',firmware)" width="220px"
            style="color:white;font-weight: bold;">READ LOG<span>
                <b-icon style="width:25px" icon="wifi" />/4G
            </span></v-btn> -->

                        <v-btn v-if="
                          user.role == 'Admin' || user.role == 'superadmin'
                        " class="mr-2 mb-5" tile color="teal" @click="setBrac(item, '96', firmware)" width="220px"
                          style="color: white;font-weight: bold">
                          SET BRAC<span>
                            <b-icon style="width: 25px" icon="wifi" /> </span></v-btn>
                        <!-- <v-btn class="mr-2 mb-5" tile color="teal"
                          @click="changepingFrequency(item, '110')" width="220px" style="color:white"> Ping Frequency<span>
                            <b-icon style="width:25px" icon="wifi" />
                          </span></v-btn> -->

                        <v-btn v-if="user.state != 'INDIA'" class="mr-2 mb-5" color="orange"
                          @click="deviceinfoCommand(item, '80', firmware)" width="220px"
                          style="color: white;font-weight: bold">DEVICE INFO<span>
                            <b-icon style="width: 25px" icon="wifi" />&nbsp;/4G
                          </span></v-btn>

                        <v-btn v-if="
                          user.role == 'Admin' || user.role == 'superadmin'
                        " class="mr-2 mb-5" tile color="#18587a" width="220px" @click="clearstorage(item, firmware, 0)"
                          style="color: white;font-weight: bold">Clear
                          Storage<span>
                            <b-icon style="width: 25px" icon="cloud" /> </span></v-btn>

                        <v-btn class="mr-2 mb-5" tile color="#73949c" @click="goCommand(item, '88', firmware)"
                          width="220px" style="color: white; font-weight: bold">
                          Bypass 5<span>
                            <b-icon style="width: 20px" icon="wifi" />/4g
                          </span></v-btn>

                        <v-btn class="mr-2 mb-5" tile color="#334b4f" @click="goCommand(item, '89', firmware)"
                          width="220px" style="color: white; font-weight: bold">
                          UnBypass 5<span>
                            <b-icon style="width: 20px" icon="wifi" />/4g
                          </span></v-btn>
                        <!-- <v-btn
                          class="mr-2 mb-5"
                          v-if="user.state != 'INDIA'"
                          tile
                          color="#936c6c"
                          @click="goCommand(item, '84', firmware)"
                          width="220px"
                          style="color: white; font-weight: bold"
                        >
                          Unlock Device(OVERRIDE)<span>
                            <b-icon style="width: 20px" icon="wifi" />/4g
                          </span></v-btn
                        > -->
                        <v-btn v-if="
                          user.role == 'Admin' || user.role == 'superadmin'" class="mr-2 mb-5" tile color="#936c6c"
                          @click="goCommand(item, '83', firmware)" width="220px"
                          style="color: white; font-weight: bold">
                          TAKE PICTURE<span>
                            <b-icon style="width: 20px" icon="wifi" />/4g
                          </span></v-btn>
                        &nbsp;&nbsp;
                        <v-btn v-if="item.cbid != 'ABC_c4_dd_57_8c_a4_9c'" class="mr-2 mb-5" tile color="blue"
                          :disabled="statuss == 'Installed' ? true : false" @click="goCommandinstall2(item, firmware)"
                          width="220px" style="color: white; font-weight: bold">
                          NEW INSTALLATION <span>
                            <b-icon style="width: 20px" icon="wifi" />/4g
                          </span></v-btn>
                        <v-btn v-if="
                          user.role == 'Admin' || user.role == 'superadmin'
                        " class="mr-2 mb-5" tile color="#f48fb1" :disabled="statuss == 'Installed' ? true : false"
                          width="220px" style="color: white; font-weight: bold"
                          href="/files/Attachment III - IID Program Data Field Description - FINAL.pdf" download>
                          <a style="color: white; font-weight: bold;text-decoration: none;"
                            href="/files/Attachment III - IID Program Data Field Description - FINAL.pdf"
                            download>DOWNLOAD</a>
                          <b-icon style="width: 20px" icon="wifi" />/4g</v-btn>

                      </div>
                    </v-container>
                  </v-row>
                  <template>
                    <div>
                      <v-btn class="mr-2 mb-5" tile color="#4d4dff" @click="openComponent" width="220px"
                        style="color: white; font-weight: bold">
                        POST COMMAND
                      </v-btn>
                      <v-dialog v-model="queuedialog" max-width="800px">
                        <v-card>
                          <Quecomponent :item="paymentItem" @onClose="clearPostcommandItem()"/>
</v-card>
                      </v-dialog>
                     

                      <!-- Conditional Rendering of Components -->
                      <!-- <component v-if="currentComponent" :is="currentComponent"></component> -->
                    </div>
                  </template>

                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </td>
      </template>
    </v-data-table>
    <!-- client table end -->
  </v-container>
  <!-- <v-container v-else class="loading-container">
      <v-progress-circular
      :size="50"
      :width="5"
      color="teal"
      indeterminate
    ></v-progress-circular>
  </v-container> -->
</template>
<style scoped>
.interlocktable {
  padding-top: 20px;
  padding-left: 5px;
  height: 900px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}

.interlockborder {
  border-style: solid;
  border-width: 3px;
  margin-left: 60px;
  width: 725px;
}

.reduce {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  align-items: center;
  width: 720px;
}

.content {
  align-items: center;
  width: 720px;
}

.interlockrow {
  align-items: center;
  margin-left: 30px;
  width: 720px;
}

.interlocksection {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  align-items: center;
  width: 720px;
}

.interlocksection2 {
  border-top: 2px solid #333333;
  /* border-bottom: 2px solid #333333; */
  align-items: center;
  width: 720px;
}

.dateinst {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  align-items: center;
  width: 720px;
}

.my_text {
  font-family: "Verdana", sans-serif;
}

.wrapper {
  width: 250px;
  margin: 0 auto;
}

.select {
  width: 120px;
  font-size: large;
  min-height: 10px;
  /* margin-left: 130px; */
}

.textfeild {
  width: 420px;
  /* font-size: large; */
}

/* .cardColor {
       background-color: white!important;
       border-color: transparent!important;
       opacity: 0.65;
     } */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  margin-left: 90px;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  background: #ddd;
}

.container {
  background: white;
  padding: 0 0.7rem 1rem;
}

/* #hidepage{
  display: none;
    visibility: hidden;
} */

.top-heading {
  background: rgb(74, 118, 175);
  color: white;
}

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media screen and (min-width: 240px) and (max-width: 1024px) {
  .printme {
    display: flex;
    max-width: 100%;
    height: auto;
  }
}

@media print {
  .hide-print {
    display: none;
    visibility: hidden;
  }

  .input-field input,
  table input {
    border-bottom: none;
  }
}

/* loading dots */
.loading:after {
  content: " .";
  animation: dots 2s steps(1, end) infinite;
}

@keyframes dots {

  0%,
  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  37.5% {
    text-shadow: 0.5em 0;
  }

  50% {
    text-shadow: 0.5em 0, 1em 0;
  }

  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }

  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }

  87.5%,
  100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 23px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: blue;
}

input:focus+.slider {
  box-shadow: 0 0 1px blue;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 100%;
  background-color: tan;
}

.dl-img1 {
  display: block;
  width: 100%;
  height: 20;
  object-fit: cover;
}

.dl-img2 {
  display: block;
  width: 100%;
  height: 20;
  object-fit: cover;
}

.p-left {
  display: flex;
  color: #334c3e;
  margin-right: 5px;
  font-weight: bold;
}

.p-right {
  display: flex;
  color: #00a3a3 !important;
}

.v-input--selection-controls_ripple {
  color: red !important;
}

.btn-container {
  position: relative;
  margin-top: 5%;
}

.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: none;
}

.hidden {
  display: none;
}

.html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 70px;
  margin-left: 10px;
}

.step {
  flex-grow: 1;
  position: relative;
}

.white--text {
  opacity: 0.7;
}

.step-progress {
  width: 100%;
  height: 5px;
  background: #e0e0e0;
}

.icon-wrapper {
  text-align: center;
  display: inline-block;
}

.step.done .step-progress:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 0;
  background-color: #2bff56;
  animation: growLine 2s linear forwards;
}

.icon-checkmark {
  position: absolute;
  top: -0.55em;
  left: -0.125em;
  border: 0.125em solid #e0e0e0;
  background: #e0e0e0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  padding: 0.125em;
  border-radius: 50%;
  transition: all 0.25s linear;
}

.step.done .icon-checkmark,
.step.reached .icon-checkmark {
  background: #2bff56;
  border-color: #2bff56;
}

.icon-checkmark .path1 {
  stroke: #aaa;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: transparent;
}

.step.done .icon-checkmark .path1 {
  animation: dash 5s linear forwards;
  stroke: #00403a;
}

.step-text {
  position: absolute;
  letter-spacing: 1px;
  font-weight: bold;
  top: -60px;
  left: -20px;
  color: #e0e0e0;
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-size: 10px;
  width: 100px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.step:nth-child(odd) .step-text {
  top: 50px;
  left: -10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  text-align: left;
}

.step:last-child {
  max-width: 40px;
}

.step:last-child .step-text {
  left: -40px;
  top: 60px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  text-align: left;
}

.step.done .step-text {
  color: #2bff56;
}

.end {
  position: absolute;
  right: 0;
}

.v-dialog .v-dialog__content {
  overflow-x: hidden;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes growLine {
  to {
    width: 100%;
  }
}

@keyframes dropText {
  to {
    padding-top: 1em;
    opacity: 1;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 70vh;
  /* Full height of the viewport */
}

.swal2-btn {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.existing-otp {
  background-color: #4caf50;
  /* Green for existing OTP */
}

.missing-otp {
  background-color: #f44336;
  /* Red for missing OTP */
}

.v-card-title {
  text-align: center;
}
</style>