<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),

  }),

  computed: {

  },

  watch: {

  },

  created() {
    // this.initialize();
  },
  mounted() {
    console.log(this.$route.query)
    var formData = new FormData();
    formData.append('paymentmode', 'online')
    formData.append('paymentid', this.$route.query.payment_intent)
    Vue.axios.post('https://robopower.xyz/us/cadealerpayments/clearpayments/' + this.$route.query.dealer,formData)
      .then((res) => {
        console.log(res.data)
        if (res.data == "Dealer Payment cleared") {
          var fData = new FormData();
          fData.append('to', this.$route.query.email)
          fData.append('message', "Dear dealer, we are pleased to inform you that your outstanding payment has been successfully processed and cleared.")
          fData.append('subject', "SUCCESS TRANSACTION")
          Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
            .then((res) => {
              console.log(res.status)
              if (res.status == 200) {
                setTimeout(() => {
                  router.push("dashboard");
                }, 3000)
                //             this.$store.dispatch("alert/success", 'Your Last Transaction Success').then(()=>{
                // setTimeout(()=>{
                // this.$store.dispatch("alert/clear")
                // router.push("dashboard");
                // },2000)
                //             })
              }
            }).catch((err) => {
              alert(err)
            })
        }
      }).catch((err) => {
        alert(err)
      })

  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
    <v-card class="mx-auto" max-width="380" outlined style="margin-top:240px;background-color:white">
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            <v-img src="../../src/assets/successicon.png" width="100" height="100" />
            <b>Success</b>
          </div>
          <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
          <v-list-item-subtitle>Payment ID :<b>{{ this.$route.query.paymentid }}</b></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>
