<template>
    <v-app>
        <div v-if="!isMobile()">
            <div class="container-fluid" style="background-color: aliceblue;height: 100%;">
                <v-layout>
                    <v-dialog v-model="Cgeofencedlg" width="500px">
                        <v-card style="border-radius: 20px; background: aliceblue">
                            <v-toolbar color="#d1c4e9" size="70"
                                style="border-radius: 20px; border-bottom: 1px solid black">
                                <p
                                    style="font-size: 16px;font-weight:bold;text-align: center;margin-top: 20px;color: #5e35b1;">
                                    Add Geofence
                                </p>
                                <v-icon class="ml-2" size="40" color="#673ab7">mdi-select</v-icon>
                                <v-spacer></v-spacer>
                                <v-btn color="#e53935" class="sm-0" @click="Cgeofencedlg = false"
                                    style="border-radius: 20px; color: white;margin-left: 20%;">
                                    <v-icon color="black">mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <div>
                                <v-text-field v-model="gname" label="Name" style="width: 30%;margin-left: 10%;"
                                    append-icon="mdi-magnify"></v-text-field>
                                <div>
                                    <Geofencemap></Geofencemap>
                                </div>
                                <div class="d-flex" style="margin-right: 10%;margin-top: 5%;">
                                    <v-spacer></v-spacer>
                                    <v-btn style="background-color: red;color: aliceblue;margin-bottom: 5%;"
                                        @click="Cgeofencedlg = false">
                                        Cancel
                                    </v-btn>
                                    <span style="font-size:large;color:green;margin-left:5%;margin-bottom: 5%;"
                                        @click="allopTrack(item)">
                                        View</span>
                                </div>
                            </div>

                        </v-card>
                    </v-dialog>
                    <v-card style="width: 95%;margin-left: 2.5%;background-color: aliceblue;margin-top: 2%;">
                        <v-row style="width: 97%; margin-left: 1.5%;margin-top: 1%;">
                            <v-toolbar>
                                <v-icon color="black" @click="backgps">mdi-keyboard-backspace</v-icon>
                                <v-toolbar-title style="font-weight: bold;margin-left: 1%;color: black;">
                                    Back
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn style="color:black">
                                    + Add an Entity <v-icon color="black">mdi-chevron-down</v-icon>
                                </v-btn>
                                <v-btn style="margin-left: 1%;color:black" @click="Cgeofence">
                                    <v-icon color="black">mdi-select</v-icon> Create Geofence
                                </v-btn>
                            </v-toolbar>
                        </v-row>
                        <v-row style="width: 100%; margin-left: 0.5%;">
                            <v-col class="col-sm-4">
                                <v-toolbar>
                                    <v-toolbar-title>Map</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <div style="width: 30%;">
                                        <v-text-field v-model="search" append-icon="mdi-magnify"></v-text-field>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn style="margin-left: 1%;">
                                        <v-icon color="black">mdi-filter-outline</v-icon> Filter
                                    </v-btn>
                                </v-toolbar>
                                <v-list class="max-v-list-height">
                                    <v-list-item v-for="(item, index) in items" :key="index">
                                        <v-card style="padding: 2%;width: 100%;margin: 1%;">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-card>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col class="col-sm-8">
                                <div ref="map" class="map-container"></div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-layout>
            </div>
        </div>
        <div v-else>
            <div class="container-fluid" style="background-color: aliceblue;height: 100%;">
                <v-layout>
                    <v-dialog v-model="Cgeofencedlg" width="500px">
                        <v-card style="border-radius: 20px; background: aliceblue">
                            <v-toolbar color="#d1c4e9" size="70"
                                style="border-radius: 20px; border-bottom: 1px solid black">
                                <p
                                    style="font-size: 16px;font-weight:bold;text-align: center;margin-top: 20px;color: #5e35b1;">
                                    Add Geofence
                                </p>
                                <v-icon class="ml-2" size="40" color="#673ab7">mdi-select</v-icon>
                                <v-spacer></v-spacer>
                                <v-btn color="#e53935" class="sm-0" @click="Cgeofencedlg = false"
                                    style="border-radius: 20px; color: white;margin-left: 20%;">
                                    <v-icon color="black">mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <div>
                                <v-text-field v-model='gname' label="Name" style="width: 30%;margin-left: 4%;"
                                    append-icon="mdi-magnify"></v-text-field>
                                <div>
                                    <Geofencemap></Geofencemap>
                                </div>
                                <div class="d-flex" style="margin-right: 10%;margin-top: 5%;">
                                    <v-spacer></v-spacer>
                                    <v-btn style="background-color: red;color: aliceblue;margin-bottom: 5%;"
                                        @click="Cgeofencedlg = false">
                                        Cancel
                                    </v-btn>
                                    <span style="font-size:large;color:green;margin-left:5%;margin-bottom: 5%;"
                                        @click="allopTrack(item)">
                                        View</span>
                                </div>
                            </div>

                        </v-card>
                    </v-dialog>
                    <v-card style="width: 95%;margin-left: 2.5%;background-color: aliceblue;margin-top: 2%;">
                        <v-toolbar>
                            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                            <h5 style="margin-left: 2%;">Dashboard</h5>
                            <div style="width: 30%;margin-left: 50%;">
                                <v-text-field v-model="search" append-icon="mdi-magnify"></v-text-field>
                            </div>
                        </v-toolbar>
                        <v-navigation-drawer v-model="drawer" absolute left>
                            <v-list nav dense>
                                <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                                    <v-list-item>
                                        <v-icon @click="backgps" color="black">mdi-keyboard-backspace</v-icon>
                                        <span style="font-weight: bold;margin-left: 5%;">
                                            Back
                                        </span>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title color="black"><v-icon color="black">mdi-chevron-down</v-icon> Add
                                            an
                                            Entity</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title @click="Cgeofence" color="black"><v-icon
                                                color="black">mdi-select</v-icon> Create
                                            Geofence</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-navigation-drawer>
                        <v-row>
                            <v-spacer></v-spacer>
                            <div ref="map" class="map-container1"></div>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row style="width: 100%; margin-left: 0.5%;">
                            <v-col class="col-sm-4">
                                <v-toolbar>
                                    <v-toolbar-title>Map</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <div style="width: 30%;">
                                        <v-text-field v-model="search" append-icon="mdi-magnify"></v-text-field>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn style="margin-left: 1%;color:black;">
                                        <v-icon color="black">mdi-filter-outline</v-icon> Filter
                                    </v-btn>
                                </v-toolbar>
                                <v-list class="max-v-list-height">
                                    <v-list-item v-for="(item, index) in items" :key="index">
                                        <v-card style="padding: 2%;width: 100%;margin: 1%;">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-card>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-layout>
            </div>
        </div>
    </v-app>
</template>
  
<script>
import mapboxgl from 'mapbox-gl';
import Geofencemap from './geofencemap.vue';
import Vue from 'vue';

export default {
    name: 'MapComponent',
    data() {
        return {
            group: '',
            gname: '',
            search: '',
            drawer: false,
            Cgeofencedlg: false,
            items: [
                { title: 'Item 1', subtitle: 'Subtitle for Item 1' },
                { title: 'Item 2', subtitle: 'Subtitle for Item 2' },
                { title: 'Item 3', subtitle: 'Subtitle for Item 3' },
                { title: 'Item 1', subtitle: 'Subtitle for Item 1' },
                { title: 'Item 2', subtitle: 'Subtitle for Item 2' },
                { title: 'Item 3', subtitle: 'Subtitle for Item 3' }
            ],
            map: null,
            // geoJSONData: [],
            // geofenceCoordinates: [[-74.5, 40], [-74.6, 40], [-74.6, 40.1], [-74.5, 40.1], [-74.5, 40]] // Example coordinates
        };
    },
    components: {
        Geofencemap
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.mobileview = true;
                return true
            } else {
                this.mobileview = false;
                return false
            }
        },
        backgps() {
            this.$router.push('/fleet/gpsdashboard');
        },
        Cgeofence() {
            this.Cgeofencedlg = true;
        },
    },
    mounted() {
        mapboxgl.accessToken = "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";

        this.map = new mapboxgl.Map({
            container: this.$refs.map,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [78.3136, 17.4041],
            zoom: 9
        });
        Vue.axios.get('https://robopower.xyz/us/geofence/getallgeofences')
            .then(response => {
                const features = response.data.map(item => {
                    // Parse geometry from the data
                    const geometry = item.geometry
                        .replace('POLYGON((', '')
                        .replace('))', '')
                        .split(',')
                        .map(coord => {
                            const [lng, lat] = coord.split(' ');
                            return [parseFloat(lng), parseFloat(lat)];
                        });
                        console.log(geometry)

                    // Parse metadata from the data
                    const metadata = JSON.parse(item.metadata);
                
                    // Construct GeoJSON Feature
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [geometry]
                        },
                        properties: metadata
                    };
                });

                const geoJSONData = {
                    type: 'FeatureCollection',
                    features: features
                };

                // Add features to the map
                this.map.on('load', () => {
                    this.map.addSource('geofence', {
                        type: 'geojson',
                        data: geoJSONData
                    });

                    this.map.addLayer({
                        id: 'geofence-layer',
                        type: 'fill',
                        source: 'geofence',
                        layout: {},
                        paint: {
                            'fill-color': '#088',
                            'fill-opacity': 0.4
                        }
                    });
                });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        // this.map.on('load', () => {
        //     // Load image for geofence marker
        //     this.map.loadImage(
        //         'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        //         (error, image) => {
        //             if (error) throw error;

        //             // Add image to map
        //             this.map.addImage('custom-marker', image);

        //             // Add geofence layer
        //             this.map.addLayer({
        //                 id: 'geofence',
        //                 type: 'fill',
        //                 source: {
        //                     type: 'geojson',
        //                     data: {
        //                         type: 'Feature',
        //                         geometry: {
        //                             type: 'Polygon',
        //                             coordinates: []
        //                         }
        //                     }
        //                 },
        //                 layout: {},
        //                 paint: {
        //                     'fill-color': '#088',
        //                     'fill-opacity': 0.4
        //                 }
        //             });

        //             // Add geofence marker
        //             this.map.addLayer({
        //                 id: 'geofence-marker',
        //                 type: 'symbol',
        //                 source: {
        //                     type: 'geojson',
        //                     data: {
        //                         type: 'FeatureCollection',
        //                         features: [{
        //                             type: 'Feature',
        //                             geometry: {
        //                                 type: 'Point',
        //                                 coordinates: [-74.006, 40.7128] // Example coordinates
        //                             }
        //                         }]
        //                     }
        //                 },
        //                 layout: {
        //                     'icon-image': 'custom-marker',
        //                     'icon-size': 0.1
        //                 }
        //             });

        //             // Add click event listener
        //             this.map.on('click', 'geofence', (e) => {
        //                 console.log('Clicked inside geofence');
        //             });

        //             // Change cursor style on hover
        //             this.map.on('mouseenter', 'geofence', () => {
        //                 this.map.getCanvas().style.cursor = 'pointer';
        //             });

        //             // Reset cursor style on mouse leave
        //             this.map.on('mouseleave', 'geofence', () => {
        //                 this.map.getCanvas().style.cursor = '';
        //             });
        //         }
        //     );
        // });
    }
};
</script>
  
<style scoped>
.map-container {
    height: 500px;
    width: 100%;
}

.map-container1 {
    height: 500px;
    width: 95%;
    display: inline-flex;
}

.max-v-list-height {
    max-height: 440px;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-wrap: normal;
}
</style>