<script>
//import getInfo from "../scripts/getInfo";
import Vue from "vue";
import axios from "axios";
import moment from 'moment'
Vue.use(axios);
export default {
  name: "Californa",
  props: ["editedItem", "type","mylogdata","myclient","events"],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    prohibstartend:0,
    date:null,
    nextdate:null
    
  }),

  computed: {},

  watch: {},
  created() {},
  mounted() {
      var std = new Date();
      var regdates = moment(std).format("MM/DD/YYYY");
      this.date = regdates;
      var nextMonthDate = moment(std).add(1, 'months').format("MM/DD/YYYY");
      this.nextdate = nextMonthDate;
        var date1 = moment(this.editedItem.prohibstart)
    var date2 = moment(this.editedItem.prohibend)
    var sts = date2.diff(date1, 'days')
    this.prohibstartend = sts;    
   
  },

  methods: {},
};
</script>
<template>
  <v-container fluid>
    <div id="reportpdfcontent" v-if="type == 'install'">
      <v-card-text>
        <v-container>
          <v-form
            autocomplete="off"
            ref="form2"
            style="width: 100%"
            class="my_text"
          >
            <div id="color" class="my_text">
              <div class="interlocktable">
                <div class="row" style="overflow: hidden">  
                    <div  class="column">
                      <img
                        src="../assets/Alogo.png"
                        height="35"
                        style="margin-left: 20px"
                      />
                    </div>
                     <div class="column">
                      <h2 class="column" style="margin-top: 10px;color:#ffb366;margin-left:460px">
                       LOG REPORTER
                      </h2>
                    </div>
                  
                </div>
                <div class="row">
                  <h3 style="margin-top:10px;text-align:center;margin-left:340px">TX  Summary  Report</h3>
                </div>
                <div class="servicerow">
                    <div class="servicecolumn " style="margin-left:45px;">
                      <p  style="text-decoration:underline;font-weight:bold;font-size:17px;">
                        Service Information
                      </p>
                      <p style="margin-top:-10px;font-weight:500;">Name of Vendor :  Smart Alco Solutions LLC DBA Alcobrake</p>
                      <p style="margin-top:-14px;font-weight:500;">Device / Model : ALocobrake 200 </p>
                      <p style="margin-top:-14px;font-weight:500;">Camera Unit : Yes</p>
                      <p style="margin-top:-14px;font-weight:500;">Service Address : 1</p>
                      <p style="margin-top:-14px;font-weight:500;">Phone : (+1)844-4252627 | (+1)6123862775</p>
                      <p style="margin-top:-14px;font-weight:500;">Calibration Tech Name : Ram Palyam</p>
                     <div class="dateinst">
                        <div class="s2">
                            <p style="margin-top:-3px;font-weight:500;">
                                Data service conducted: 01-14-2024
                            </p>
                        </div>
                        <div class="s2">
                            <p style="margin-top:-3px;font-weight:500;" >
                                Log Begin Date : 12-12-2023
                            </p>
                        </div>
                        <div class="s2">
                            <p style="margin-top:-3px;font-weight:500;">
                               Log End Date : 12-12-2023
                            </p>
                        </div>
                    </div>                    
                    </div>
                    <div  class="servicecolumn">
                     <p  style="text-decoration:underline;font-weight:bold;font-size:17px;margin-left:-320px;" >
                        Client Image
                      </p>
                      <img
                        src="../assets/clients.jpg"
                     
                      />
                    </div>
                     <!-- <div  class="servicecolumn">
                      <img
                        src="../assets/clients.jpg"
                     
                      />
                    </div> -->
                </div>
                  <div class="interlockborder">
        <div class="center-border"></div>
        <div class="trow">
            <div class="tcolumn">
                <p class="ts2"  style="text-decoration:underline;font-weight:bold;font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;">
                    Client Information
                </p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Case/Cause # : <u>__________________</u></p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Installation Date : {{this.myclient.installDate}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Name : {{this.myclient.client}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Address : {{this.myclient.clientAddress}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">DL#: {{this.myclient.DLnum}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Phone : {{this.myclient.clientphone1}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">DOB : {{this.myclient.dob}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Cust ID : <u>__________________</u></p>
           
             
                <p class="ts2"  style="text-decoration:underline;font-weight:bold;font-size:14px;text-align:left;margin-left:-15px;margin-top:-6px;">
                    Vehicle Information
                </p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Year : {{this.myclient.year}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Mileage : <u>__________________</u></p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">License Plate # : {{this.myclient.regnum}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Color : {{this.myclient.color}}</p>
                </div>
            <div class="tcolumn">
                <p class="ts2"  style="text-decoration:underline;font-weight:bold;font-size:14px;text-align:left;margin-left:-5px;margin-top:-15px;">
                    Officer Information
                </p>
            </div>
        </div>

        <div class="trow reduce">
            <div class="tcolumn">
                <!-- Content for the second row, first column -->
                 <p class="ts2"  style="text-decoration:underline;font-weight:bold;font-size:14px;text-align:left;margin-left:-15px;margin-top:-20px;">
                    Summary of Events
                </p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Illegal Starts      : {{this.events.IllegalStarts}} </p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">High BrAC           : {{this.events.HighBrAC}} </p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">RR Refused          : {{this.events.RRRefused }}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Engine Stops        : {{this.events.EngineStops}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Engine Starts       : {{this.events.EngineStarts}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Handset Disconnects : {{this.events.HandsetDisconnects}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Handset Connects    : {{this.events.HandsetConnects}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Authorized Starts   : {{this.events.AuthorizedStarts}}</p>
                <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Aborts              : {{this.events.Aborts}}</p>
            </div>
            <div class="tcolumn">
                <!-- Content for the second row, second column -->
                <p class="ts2"  style="text-decoration:underline;font-weight:bold;font-size:14px;text-align:left;margin-left:-5px;margin-top:-135px;">
                    Comment Section
                </p>
            </div>
        </div>

        <div class="trow reduce">
            <div class="tcolumn">
                <!-- Content for the third row, first column -->
                 <p  style="text-decoration:underline;font-weight:bold;font-size:17px;margin-left:-15px;margin-top:-15px;">
                        Calibration Conformation Result
                      </p>
                       <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-15px;font-weight:500;">Value = 0.045  01-12-2024</p>
                        <p style="font-size:14px;text-align:left;margin-left:-15px;margin-top:-20px;font-weight:500;">in Range</p>

            </div>
            <div class="tcolumn">
                <!-- Content for the third row, second column -->
                <div class="row">
                  <div class="column">
                     <p  style="font-weight:bold;font-size:17px;margin-top:-15px;margin-left:8px;">
                        Date Downloaded : 
                      </p>
                  </div>
                    <div class="column">
                       <p  style="font-size:17px;margin-top:-15px;margin-left:35px;">
                        {{this.date}}
                      </p>
                  </div>
                  </div>
                  <div class="row">
                  <div class="column">
                        <p  style="font-weight:bold;font-size:17px;margin-left:8px;margin-top:-16px;">
                          Next Download Date: 
                      </p>
                      </div>
                    <div class="column">
                       <p  style="font-size:17px;margin-left:20px;margin-top:-18px;">
                        {{this.nextdate}}
                      </p>
                  </div>
                  </div>
                   <div class="row">
                  <div class="column">
                        <p  style="font-weight:bold;font-size:17px;margin-left:8px;margin-top:-15px;">
                         Reference     
                      </p>
                       </div>
                    <div class="column">
                       <p  style="font-size:17px;margin-left:110px;margin-top:-15px;">
                       {{this.myclient.sno}}
                      </p>
                  </div>
                  </div>
                  
            </div>
        </div>
    </div>

              </div>
            </div>
            <div class="row">
                    <p style="font-size:14px;text-align:center;margin-left:220px;margin-top:45px;font-weight:500;">Smart Alco Solutions ,LLC. DBA Alcobrake 12575HoneyflowerDr,Frisco ,TX75035
                    </p><p style="font-size:14px;text-align:center;margin-left:242px;margin-top:-15px;font-weight:500;">Toll Free : (+1)844-425 2627 | Fax : (515) 564-2020 | www.alcobrake.com
                      
                    </p>
                  </div>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
   
  </v-container>
</template>


<style scoped>
 .serviceinfo {
            max-width: 400px;
            margin: 20px auto;
            padding: 20px;
            border: 1px solid #ddd;
  }

   .servicerow{
            display: flex;
            justify-content: space-between;
        }

        .servicecolumn {
            flex: 1;
            padding: 20px;
            
        }

        .servicecolumn img {
            margin-left:-350px;
            height:160px;
            width:190px;
            margin-top:-13px;

        }
.interlocktable {
  padding-top: 20px;
  padding-left: 5px;
  height: 1000px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}
.interlocktable2 {
  padding-top: 20px;
  padding-left: 5px;
  height: 1310px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}
.interlockborder {
            border-style: solid;
            border-width: 2px;
            margin-left:65px;
            width: 785px; /* Adjusted width to accommodate the center border */
            height: 677px;
            position: relative;
        }

        .center-border {
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            border-left: 2px solid;
            transform: translateX(-50%);
        }

        .trow {
            display: flex;
            padding: 10px;
        }

        .tcolumn {
            flex: 1;
            padding: 10px;
            box-sizing: border-box;
            
        }

        .ts2 {
            padding-top: 1pt;
            text-indent: 0pt;
        }

.reduce {
  border-top: 2px solid #333333;
  /* border-bottom: 2px solid #333333; */
  align-items: center;
  margin-top: -30px;
}
table,th,td {
  border: 1px solid black;
  border-collapse: collapse;
}
.content {
  align-items: center;
}
.interlocksection {
  border-top: 2px solid #333333;
  align-items: center;
}
.dateinst {
            display: flex;
            justify-content: space-between;
        }

       

        .s2 {
            flex: 1;
            width:300px;
        }

        .s2 p {
            margin: 0; /* Remove default margin for <p> element */
        }
.my_text {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
/* .s2 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
  margin-bottom: -3px;
} */
.s3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin-bottom: 5px;
}
.s5 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 9pt;
}
</style>