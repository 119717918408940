<script>
import {mapGetters} from 'vuex';
export default {
  data: () => ({
    user  : JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    password: "Password",
    access: [{ text: "Manager" }, { text: "Technician" }],
    headers: [
      //  { text: "ID", value: "userId" },
      {
        text: "Name",
        align: "left",
        value: "userName"
      },
      { text: "Email", value: "userEmail" },
      { text: "Phone", value: "userMobile1" },
      { text: "Dealer Name", value: "dealerName" },
      // { text: "Access Level", value: "accesslevel" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" }
    ],
    editedIndex: -1,
    // ecode2: ['1', '91'],
    // ecode: ['1', '91'],
    countrycode:'us',
    editedItem: {
      userName: "",
      userId: "",
      userEmail: "",
      userMobile1: "",
      userMobile2: "",
      userhome: "",
      userwork:"",
      accesslevel: "",
      sdealer: "",
      userPassword: "",
      is_active: true
    },
    defaultItem: {
      userName: "",
      userId: "",
      userEmail: "",
      userMobile1: "",
      userMobile2: "",
      accesslevel: "",
      userhome: "",
      userwork:"",
      sdealer: "",
      userPassword: ""
    },
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
      v => v && v.length >= 3 || 'Name must contain 3 or more characters',
      v => v && v.length <= 20 || 'Name must be less than 20 characters'
      ],
    ecodeRules: [(v) => !!v || "Select Country Code"],
      emailRules: [
      v => !!v || 'E-mail is required',
      v =>
          (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "E-mail must be valid"
      ],
      dealerRules: [
      v => !!v || 'Select Dealer',
      ],
      accessRules: [
      v => !!v || 'Select access level',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v && v.length >= 8 || 'Password must contain 8 or more charactors'
      ],
      // phoneRules: [
      //   v => !!v || "Phone is Required",
      //   v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
      //   v => v != 0 || "Phone Number can't be zeoro!"
      // ],
    phone2Rules: [
      v => !!v || 'Phone2 is required',
      v => v && v.length > 10 && v.length < 15 || 'Enter valid phone number',
    ],
      phoneRules: [
      v => !!v || 'Phone is required',
      v => v && v.length > 10 && v.length < 15 || 'Enter valid phone number',
    ],
    phoneRuless: [
      v => !!v || 'Phone is required',
      v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
    ],
     phone2Ruless: [
      v => !!v || 'Phone2 is required',
      v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
    ],
  }),

  computed: {
     Reminder() {
      if(this.expanded.length >0){
        return this.expanded
      } else{
      return this.users
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Technician" : "Edit Technician";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['users']),
    ...mapGetters(['dealers']),
  },

  watch: {
    countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = 'us'
      },500)
    },
    dialog(val) {
      val || this.close();
      // this.countrycode == val
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    if(this.user.role=="Admin" || this.user.role == 'superadmin'){
     this.$store.dispatch('fetchUsers')
     this.$store.dispatch('fetchDealers')
    } else{
      this.$store.dispatch('fetchdealerUsers',this.user.dealerId)
    }
  },

  methods: {
        isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    deleteUser(user) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
        /* eslint-disable no-console */
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Are you sure? You want to delete User - ' + user.userName,
          text: 'You can\'t revert your action',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes Delete it!',
          cancelButtonText: 'No, Keep it!',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch('deleteUser', user.userId)
            // this.$swal('Deleted', 'You successfully deleted this file', 'success')
            this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          } else {
            // this.$swal('Cancelled', 'Your file is still intact', 'info')
            this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },
    editloadFormData() {
       console.log(this.editedItem)
      const formData = new FormData();
      formData.append("userId", this.editedItem.userId);
      formData.append("userName", this.editedItem.userName);
      formData.append("userEmail", this.editedItem.userEmail);
      formData.append("userMobile1", this.editedItem.userMobile1);
      formData.append("userMobile2", this.editedItem.userMobile2);
      formData.append("accesslevel", this.editedItem.accesslevel);
      if(this.user.role == "Admin"|| this.user.role == 'superadmin'){
        // if(this.editedIndex==-1) {
        if (this.editedItem.sdealer.dealer == undefined) {
          formData.append("dealer", this.editedItem.sdealer.dealerId);
        } else {
          formData.append("dealer", this.editedItem.sdealer.dealer);
        }
      
        // } else{
        //   formData.append("dealer", this.editedItem.sdealer.dealer);
        // }
      } else{
        formData.append("dealer", this.editedItem.sdealer.dealer);
      }
      formData.append("userPassword", this.editedItem.userPassword);
      return formData;
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("userId", this.editedItem.userId);
      formData.append("userName", this.editedItem.userName);
      formData.append("userEmail", this.editedItem.userEmail);
      formData.append("userMobile1", this.editedItem.userhome + this.editedItem.userMobile1);
      formData.append("userMobile2", this.editedItem.userwork + this.editedItem.userMobile2);
      formData.append("accesslevel", this.editedItem.accesslevel);
      if(this.user.role == "Admin" || this.user.role == 'superadmin'){
        // if(this.editedIndex==-1) {
      formData.append("dealer", this.editedItem.sdealer.dealerId);
        // } else{
        //   formData.append("dealer", this.editedItem.sdealer.dealer);
        // }
      } else{
        formData.append("dealer", this.user.dealerId);
      }
      formData.append("userPassword", this.editedItem.userPassword);
      return formData;
    },
    initialize() { },
    onSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.userhome = dialCode;
    },
    onSelect2({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.userwork = dialCode;
    },
    editItem(item) {
      // console.log(item)
      if (this.user.emailId != 'Justin@alcobrake.com') {
        this.editedIndex = this.users.indexOf(item);
        this.editedItem.userName = item.userName;
        this.editedItem.userId = item.userId;
        this.editedItem.userEmail = item.userEmail;
        this.editedItem.userMobile1 = item.userMobile1;
        this.editedItem.userMobile2 = item.userMobile2;
        this.editedItem.accesslevel = item.accesslevel;
        this.editedItem.sdealer = item;
        this.editedItem.userPassword = item.userPassword;
        // this.editedItem.is_active=item.
        this.dialog = true;
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },

    deleteItem() {
    },

    close() {
      this.dialog = false;
      this.countrycode = 'US'
      this.$refs.form.reset();
      this.editedIndex = -1;
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if(this.editedItem.userMobile1 == undefined)
        this.editedItem.userMobile1 = '';
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch('createUser',fd)
      } else {
        const editfd = this.editloadFormData()
        var postdata={
          udata:editfd,
          id:this.editedItem.userId
        }
        this.$store.dispatch('editUser',postdata)
      }
            // window.location.reload()
          this.close();
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px">
      <v-data-table
        dense
        :headers="headers"
        :items="Reminder"
        item-key="userId"
        sort-by="userName"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" text style="border-radius:20px">
            <v-toolbar text color="#00A3A3" dark dense style="border-radius:20px">
              <span v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
              <v-toolbar-title style="font-size:22px">TECHNICIANS</v-toolbar-title>
              </span>
               <span v-else>
              <v-toolbar-title style="font-size:15px">TECHNICIANS</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-row>
                <v-col cols="12" lg="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              </v-col>
              </v-row>        

              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
         <v-row>
          <v-col cols="12">
           <v-layout justify-end>
                <!-- <v-btn color="dark" class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px;background:black" >
                  Add Technician
                </v-btn > -->
                <v-btn  v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on" style="border-radius:20px;background:black"> <h2>+</h2><v-icon size="20px"  color="dark">mdi-wrench</v-icon></v-btn>
                
             
           </v-layout>
              </v-col>
                  </v-row>
          </template>
               
                <v-card style="border-radius:20px">
                  <v-toolbar text color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            v-on:keypress="isLetter($event)"
                            :rules="nameRules"
                            v-model="editedItem.userName"
                            label="Name*"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            v-on:keypress="isNumber($event)"
                            disabled
                            v-model="editedItem.userId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense outlined
                            required
                            :rules="emailRules"
                            v-model="editedItem.userEmail"
                            label="Email*"
                          ></v-text-field>
                        </v-col>
            <div class="left-btn" v-if="editedIndex == -1">
                   <vue-country-code           
                      @onSelect="onSelect"
                      :enabledCountryCode="true"
                      :defaultCountry='countrycode'
                      style="border:none"
                      :preferredCountries="['in', 'us', 'gb']"
                    >
    <input type="number" v-model="editedItem.userhome" name="phone" id="phone" placeholder="phone number" />
  </vue-country-code>
                    </div>
                  
                    <div class="left-btn" v-if="editedIndex == -1">
                        <v-text-field
                            dense outlined
                            required 
                            v-on:keypress="isNumber($event)"
                             :rules="phoneRuless"
                            v-model="editedItem.userMobile1"
                            label="Phone*"
                            class="textfeild"
                          ></v-text-field>
                </div>
                <v-col v-else cols="12" sm="12" md="6">
                  <v-text-field 
                            dense outlined
                            required
                            v-on:keypress="isNumber($event)"
                             :rules="phoneRules"
                            v-model="editedItem.userMobile1"
                            label="Phone*"
                          ></v-text-field>
                </v-col>
            
              <div class="left-btn" v-if="editedIndex == -1">

                  <vue-country-code
                      @onSelect="onSelect2"
                      :enabledCountryCode="true"
                      :defaultCountry='countrycode'
                      style="border:none"
                      :preferredCountries="['in', 'us', 'gb']"
                    >
    <input type="number" v-model="editedItem.userwork" name="phone" id="phone" placeholder="phone number" />
  </vue-country-code>
                        </div>
                
                        <div class="left-btn" v-if="editedIndex == -1">
                            <v-text-field
                            dense outlined
                            required
                            :rules="phone2Ruless"
                            v-on:keypress="isNumber($event)"
                             v-model="editedItem.userMobile2"
                            label="Phone 2"
                            class="textfeild"
                          ></v-text-field>
                      </div>
                      <v-col cols="12" sm="12" md="6" v-else>
                        <v-text-field
                            dense outlined
                            required
                            :rules="phone2Rules"
                            v-on:keypress="isNumber($event)"
                             v-model="editedItem.userMobile2"
                            label="Phone 2"
                          ></v-text-field>
                      </v-col>
                        <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            v-on:keypress="isNumber($event)"
                            :rules="phoneRules"
                            v-model="editedItem.userMobile1"
                            label="Phone*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            v-on:keypress="isNumber($event)"
                            :rules="phone2Rules"
                            v-model="editedItem.userMobile2"
                            label="Phone 2"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-select outlined
                            denserequired
                            :rules="accessRules"
                            v-model="editedItem.accesslevel"
                            :items="access"
                            label="Select Access Level*"
                            item-value="text"
                          ></v-select>
                        </v-col>
                        <v-col v-if="user.role=='Admin' || user.role=='superadmin'" cols="12" sm="12" md="6">
                          <!-- <v-select
                            denserequired
                            :rules="accessRules"
                            v-model="editedItem.sdealer"
                            :items="dealers"
                            label="Select Dealer*"
                            item-value="dealerName"
                          ></v-select> -->
                            <!-- <v-select
                            denserequired
                            v-model="editedItem.sdealer"
                            :items="dealers"
                            :rules="dealerRules"
                            label="Select Dealer"
                            item-text="dealerName"
                            item-value="dealerId"
                          >
                          </v-select> -->
                          <v-autocomplete
                              label="Select Dealer*"
                              :rules="dealerRules"
                              required outlined
                              class="purple-input"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.sdealer"
                              :items="dealers"
                              item-text= "dealerName"
                              return-object
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.dealerName }}
                              </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-text-field outlined
                            v-model="editedItem.userPassword"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            required
                            :rules="passwordRules"
                            :type="show ? 'text' : 'password'"
                            name="password"
                            label="Password*"
                            counter
                            @click:append="show = !show"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="close" style="background:#ff3d41;color:white"
                      >Cancel</v-btn>
                    <v-btn color="blue darken-1" text :disabled="!valid" @click="save">{{buttonTitle}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
               
            </v-toolbar>
          </v-card>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mr-2 mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteUser(item)"
                >
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item}" >
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
        
            <v-col class="col-md-6">
                  <v-card class="mt-2 mb-2" color="#334c3e" style="border-radius:20px;padding:10px" >
                    <v-list dense class="ma-0 pa-0" style="background:#334c3e">
                  <v-list-item>
                    <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                    <v-list-item-content class="content1">ID:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.userId }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-account</v-icon>
                    <v-list-item-content class="content1">Name:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.userName }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-email-outline</v-icon>
                    <v-list-item-content class="content1">Email:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.userEmail }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                    <v-list-item-content class="content1">Phone:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.userMobile1 }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-icon class="off-icon">mdi-cellphone-basic</v-icon>
                    <v-list-item-content class="content1">Phone 2:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.userMobile2 }}</v-list-item-content>
                  </v-list-item><v-list-item>
                    <v-icon class="off-icon">mdi-account-key</v-icon>
                    <v-list-item-content class="content1">Accesslevel:</v-list-item-content>
                    <v-list-item-content class="align-end content2">{{ item.accesslevel }}</v-list-item-content>
                  </v-list-item>

              </v-list>

                  </v-card>
            </v-col>
       
               
              
           </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<style scoped>
.content2
{
  color:#0ae2e2 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}

</style>