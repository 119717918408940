<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),

  }),

  computed: {

  },

  watch: {

  },

  created() {
    // this.initialize();
  },
  mounted() {
    Vue.axios.get(`https://robopower.xyz/us/cadealerpayments/cleardealershippayments?dealershipid=` + this.$route.query.dealershipid + `&dealerid=` + this.$route.query.dealerid + `&paymentmethod=online&paymentindent=` + this.$route.query.payment_intent)
      .then((res) => {
        console.log(res.data)
        if(res.data == 'Successfully Updated Dealership Transactions'){
          if(this.$route.query.logintype == "fleet"){
            this.$router.push('/fleet/gpsdashboard')
          }else{
            this.$router.push('dashboard')
          }
        }
      })
  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
    <v-card class="mx-auto" max-width="380" outlined style="margin-top:240px;background-color:white">
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            <v-img src="../../src/assets/successicon.png" width="100" height="100" />
            <b>Success</b>
          </div>
          <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
          <v-list-item-subtitle>Payment ID :<b>{{ this.$route.query.paymentid }}</b></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>
