<template>
    <div class="alcohol-test-report">
      <h2>Alcohol Test Report</h2>
      <div class="test-results">
        <div class="result">
          <h3>Pass Count</h3>
          <p>{{ passCount }}</p>
        </div>
        <div class="result">
          <h3>Fail Count</h3>
          <p>{{ failCount }}</p>
        </div>
      </div>
      <div class="bypass-count">
        <h3>Vehicles Started on Bypass</h3>
        <p>{{ bypassCount }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AlcoholTestReport',
    data() {
      return {
        passCount: 0,
        failCount: 0,
        bypassCount: 0
      };
    },
    created() {
      // Simulate fetching data from an API
      this.fetchTestReportData();
    },
    methods: {
      fetchTestReportData() {
        console.log("rr")
        // Replace with your API call
        this.passCount = 120; // Example data
        this.failCount = 30;  // Example data
        this.bypassCount = 15;  // Example data
      }
    }
  };
  </script>
  
  <style scoped>
  .alcohol-test-report {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  .test-results {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .result {
    text-align: center;
  }
  .bypass-count {
    text-align: center;
  }
  </style>
  