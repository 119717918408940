<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { StripeElementPayment,StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  props: ['itemss'],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    validAmount:false,
    validdiscountAmount:false,
    e1: 1,
    payind1:null,
    paymentelements: {
      invoiceid:"",
        amount: "",
        email: "",
      },
    stripecustomerList:[],
    vehicles: [],
    prevBalance: 0,
    currentamountpaid: "0",
    previouspaid: "0",
    valid: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    post: null,
    items: [],
    iscreated: false,
    // pk:"pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
     pk: process.env.VUE_APP_STRIPE_KEY,
    elementsOptions: {
        appearance: {},
        clientsecret:'pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt' // appearance options
      },
    paymentItem: {
        clienthome:"",
        clientwork:"",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile:"",
        ticketfile:"",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink:false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile:"",
        facedetection:'',
        issms:''
      },
    dialog6: false,
    dialog7: false,
    dialog8: false,
    dialog9: false,
    dialog15:false,
    search3: "",
    model: null,
    headers: [
      {
        text: " Invoice no",
        align: "left",
        value: "invoiceId"
      },
      { text: "Office", value: "office" },
      { text: "Date", value: "date" },
      { text: "Client", value: "client" },
      { text: "Total Amount", value: "total" },
      { text: "Amount paid", value: "amountpaid" },
      { text: "Remaining Balance", value: "prevbalance" },
      { text: "View", value: "view", sortable: false },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "Pay", value: "pay", sortable: false }
    ],
    invoiceservicess:  [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ],
    headers2: [
      {
        text: " Invoice no",
        align: "left",
        value: "invoiceId"
      },
      { text: "Office", value: "office" },
      { text: "Date", value: "date" },
      { text: "Client", value: "client" },
      { text: "Total Amount", value: "total" },
      { text: "Amount paid", value: "amountpaid" },
      { text: "Balance", value: "prevbalance" },
      // { text: "Edit", value: "edit", sortable: false },
      // { text: "Delete", value: "delete", sortable: false },
      { text: "Download", value: "download", sortable: false }
    ],
    editedIndex: -1,
    addInfo: {
      invoiceId: null,
      office: null,
      client: null,
      date: "",
      service: [],
      regnum: "",
      total: "0",
      prevbalance: "0",
      grandtotal: "0",
      amountpaid: "0",
    },
    emptyInfo: {
      invoiceId: null,
      office: null,
      client: null,
      date: "",
      service: [],
      regnum: "",
      total: "0",
      prevbalance: "0",
      grandtotal: "0",
      amountpaid: "0",
    },
    amountRules: [
      v => !!v || 'Amount is required',
      v => (v && /^\d+(\.\d{1,2})?$/.test(v)) || 'Enter valid Amount',
      ],
    officeRules: [
      v => !!v || 'Select office date',
    ],
    clientRules: [
      v => !!v || 'Select client id',
    ],
    vehicleRules: [
      v => !!v || 'Select vehicle id',
    ],
  }),
  components: {
    invoices: () => import('./invoiceitem.vue'),
    StripeElementPayment,
  },
  computed: {
    
    resultQuery() {
      if (this.search3) {
        return this.uniqueinvoice.filter((item) => {
          return item.client.includes(this.search3);
        });
      } else {
        return this.uniqueinvoice;
      }

    },
    ...mapGetters(['invoices']),
    ...mapGetters(['uniqueinvoice']),
    ...mapGetters(['offices']),
    ...mapGetters(['clients']),
    ...mapGetters(['officeclients']),
    ...mapGetters(['uniqueclient']),
    // ...mapGetters(['vehicles']),
    // ...mapGetters(['uniquevehicle']),
    ...mapGetters(['invoiceservices']),
    all_items() {
      if (this.addInfo.service != null) {
        return this.addInfo.service.reduce((accumulator, item) => {
          return accumulator + (item.actualamount - item.discount);
        }, 0);
      } else {
        return 0
      }
    },
    date: () => new Date().toLocaleString(),
    // balance() {
    //   return 10;
    // },
    previous_balance() {
      /* eslint-disable no-console */
      this.getBalance()
      if (this.currentamountpaid == "0" && this.previouspaid == "0") {
        return this.total_amount_due;
      } else {
        return this.addInfo.prevbalance;
      }
    },
    total_amount_due() {
      if (this.previouspaid !== undefined) {
        /* eslint-disable no-console */
        var cpaid = this.all_items - this.previouspaid;
        return cpaid;
      }
      return this.all_items;
    },
    total_all_items() {
      if (this.addInfo.service != null) {
        return this.all_items;
      } else {
        return 0
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Invoice" : "Edit Invoice";
    }
  },

  watch: {
    addInfo(val){
      this.addInfo = val;
    },
    itemss(val){
      if(val !=null){
        this.mounteddata();
      }
    },
    // total_amount_due(val) {
    //   this.currentamountpaid = val;
      // this.getBalance();
      // this.total_amount_due = val;
    // }
    // dialog2(val) {
    //   val || this.close();
    // },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.mounteddata();
  },

  methods: {
    payprevious(){
      this.e1 = 1
    },
    async generatePaymentIntent() {
      var amt = this.paymentelements.amount.toFixed(2).toString();
      amt = amt.replace(/\./g, "");
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          var that = this;
          this.payind1 = response.data.id;
          that.elementsOptions.clientSecret = response.data.client_secret;
          this.iscreated = true;
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    finalPay(){
      const that = this;
      that.$refs.paymentRef.submit();
    },
    pay(item){
      this.paymentItem=this.itemss
      this.paymentelements.amount = parseFloat(item.prevbalance);
      this.paymentelements.email = this.itemss.email;
      this.paymentelements.invoiceid = item.invoiceId;
      this.dialog15 = true;
      this.generatePaymentIntent();
    },
    payclose(){
      this.dialog15 = false;
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
            this.payind1
        )
        .then((res) => {
          if(res.status==200){
              this.$store.dispatch("alert/error", "Payment Cancelled").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    submit(){
      this.dialog15 = true;
    },
    close10() {
      this.model = null;
      this.$emit('close11')
    },
    close9() {
      this.dialog9 = false;
      this.model = null;
      this.dialog8 = false;
    },
    downLoadinvoice() {
      var dom = document.getElementById('downloadinvoice');
      html2canvas(dom).then(function (canvas) {
        var img = canvas.toDataURL("image/png");
        var doc = new jsPDF();
        doc.addImage(img, 'JPEG', 0, 0);
        doc.save('mydestination.pdf');

      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    downloadInvoice(item) {
      console.log(item)
      this.$store.dispatch('fetchuniquefullClient', item.client)
      // this.$store.dispatch('fetchuniqueClient',item.clientId)
      this.$store.dispatch('fetchInvoiceservices', item.officeId)
      this.editedIndex = this.uniqueinvoice.indexOf(item);
      this.addInfo.invoiceId = item.invoiceId;
      this.addInfo.office = item.officeId.toString();
      this.addInfo.client = item.client;
      this.addInfo.date = moment(item.date).format("MM/DD/YYYY");
      this.addInfo.service = item.Service;
      this.previouspaid = parseFloat(item.amountpaid);
      this.addInfo.regnum = item.regnum;
      this.addInfo.total = item.total;
      this.prevBalance = parseFloat(item.prevbalance);
      this.dialog9 = true;

    },
    AddInvoice(item) {
      this.addInfo.office = item.officeId.toString();
      this.addInfo.client = item.client;
      this.vehicles = item.list;
      this.dialog6 = true;
    },
    mounteddata() {
      this.$store.dispatch('fetchuniquerInvoice', this.itemss.regnum)
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch('fetchOffices')
      } else {
        this.$store.dispatch('fetchdealerOffices', this.user.dealerId)
      }
    },
    onClickChild(value) {
      this.dialog8 = value;
    },
    viewItem(data) {
      this.post = data
      this.dialog8 = true;
    },
    deleteInvoice(invoice) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to delete Invoices - " + invoice.invoiceId,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          console.log(invoice)
          this.$store.dispatch('deleteInvoice2', invoice);
          // this.$swal(
          //   "Deleted",
          //   "You successfully deleted this file",
          //   "success"
          // );
          this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    getBalance() {
      const amountPattern = /^\d+(\.\d{1,2})?$/;
        this.validAmount =amountPattern.test(this.currentamountpaid);
      this.addInfo.amountpaid = (this.previouspaid + parseFloat(this.currentamountpaid)).toString();
      this.addInfo.prevbalance = (this.total_amount_due - parseFloat(this.currentamountpaid)).toString();


    },
    getTotal(index,val) {
            //validating discount amount
            const amountPattern = /^\d+(\.\d{1,2})?$/;
        this.validdiscountAmount =amountPattern.test(val);
      let selectedService = this.invoiceservicess.find(service => service.servicetype == this.addInfo.service[index].service)
      if (selectedService !== undefined) {
        this.addInfo.service[index].actualamount = selectedService.rate;
        this.addInfo.service[index].total = (selectedService.rate - this.addInfo.service[index].discount).toString();
      }
    },
    format_price: number => {
      return "$" + parseFloat(number).toFixed(2);
    },
    add_item: function () {
      this.addInfo.service.push({ invoiceId: null, service: "", actualamount: 0, discount: "0", description: "", total: "" });
    },
    update_value: function (index, event, property) {
      this.addInfo.service[index][property] = event.target.innerText;
    },
    remove_item: function (index) {
      this.addInfo.service.splice(index, 1);
    },

    initialize() { },
    getId() {
      this.dialog6 = true;
    },
    getClients() {
      this.$store.dispatch('fetchofficeClients', this.addInfo.office)

    },
    getVehicles() {
      // this.$store.dispatch('fetchclientVehicles',this.addInfo.client)

    },
    getServiceInfo(index) {
      /* eslint-disable no-console */
      let selectedService = this.invoiceservices.find(service => service.servicetype == this.addInfo.service[index].service)
      if (selectedService !== undefined) {
        this.addInfo.service[index].actualamount = selectedService.rate;
      }
      this.getTotal(index,this.addInfo.service[index].discount);
    },
    addInvoice() {
      this.dialog7 = true;

    },
    editItem(item) {
      console.log(this.uniqueinvoice,"uniqueinvoice");
      this.$store.dispatch('fetchuniquefullClient', item.client)
      // this.$store.dispatch('fetchuniqueClient',item.clientId)
      this.$store.dispatch('fetchInvoiceservices', item.officeId)
      this.editedIndex = this.uniqueinvoice.indexOf(item);
      this.addInfo.invoiceId = item.invoiceId;
      this.addInfo.office = item.officeId.toString();
      this.addInfo.client = item.client;
      this.addInfo.date = moment(item.date).format("MM/DD/YYYY hh:mm:ss");
      this.addInfo.service = item.Service;
      this.previouspaid = parseFloat(item.amountpaid);
      this.addInfo.regnum = item.regnum;
      this.addInfo.total = item.total;
      this.prevBalance = parseFloat(item.prevbalance);
      this.dialog7 = true

    },
    close3() {
      this.dialog6 = false;
      this.$refs.form.reset();
      this.editedIndex = -1;
    },
    close4() {
      this.e1 = 1;
      if (this.editedIndex == -1) {
        this.dialog7 = false;
        document.getElementById("generateInvoice").reset();
        this.addInfo = this.emptyInfo;
        this.close3();
      } else {
        document.getElementById("generateInvoice").reset();
        this.addInfo.invoiceId = null;
        this.addInfo.office = null;
        this.addInfo.client = null;
        this.addInfo.date = "";
        this.addInfo.service = [];
        this.addInfo.regnum = "";
        this.addInfo.total = "0";
        this.addInfo.prevbalance = "0";
        this.addInfo.grandtotal = "0";
        this.addInfo.amountpaid = "0";
        this.dialog7 = false;
        this.addInfo = this.emptyInfo;
        this.prevBalance = 0;
        this.currentamountpaid = "0";
        this.previouspaid = "0";
        this.editedIndex = -1;
      }
      this.model = null;
      this.close10();
    },

    next() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch('fetchuniquefullClient', this.itemss.client)
      // this.$store.dispatch('fetchuniqueVehicle',this.addInfo.regnum)
      this.$store.dispatch('fetchInvoiceservices', this.itemss.officeId)
      this.dialog7 = true
    },
    save3() {
      this.e1 = 2
    },
    save4() {
      var body
      if (this.editedIndex == -1) {
        if (this.currentamountpaid == "0") {
          this.addInfo.prevbalance = this.total_amount_due.toString();
        }
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY hh:mm:ss");
        body = JSON.stringify(this.addInfo)
        this.$store.dispatch('createInvoice', body);
        this.el = 2
      } else {
        this.addInfo.grandtotal = this.total_amount_due.toString();
        this.addInfo.total = this.total_all_items.toString();
        this.addInfo.date = moment(this.date).format("MM/DD/YYYY");
        body = JSON.stringify(this.addInfo)
        var post = {
          id: this.addInfo.invoiceId,
          data: body
        }
        this.$store.dispatch('editInvoice', post);
        this.el = 2
      }
      this.close4();
    }
  }
};
</script>
<template v-if="uniqueinvoice">
  <v-container fluid>
    <v-dialog v-model="dialog15" max-width="800px">
      <v-card class="elevation-12">

        <v-col cols="12">
          <v-row class="justify-center">
            <v-col class="col-md-8" align="center">
              <div style="background:#334c3e !important;color:white;border-radius:20px;padding:10px"><v-icon color="green"
                  class="mr-4">mdi-cash-multiple</v-icon>You are paying the amount $ {{ this.paymentelements.amount }}
                </div>
            </v-col>
          </v-row>
        </v-col>
        <div class="container">
          <stripe-element-payment v-if="iscreated" ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
            :confirm-params="{ return_url: `https://alcobrakeadmin.com/successtransaction?mobile=` + paymentItem.clientphone1 + `&regnum=` + paymentItem.regnum + `&email=` + paymentItem.email + `&message=Dear customer your last transaction  is success with Payment id:` + payind1 + `&paymentid=` + payind1 + `&clearall=false&invoiceid=`+this.paymentelements.invoiceid }" />
          <br />
          <v-layout justify-center>
            <!-- dialog paynow -->
            <v-btn class="mr-4" @click="payclose" dark style="background:#ff3d41;color:white">Cancel</v-btn>
            <v-btn class="primary" left @click="finalPay">Pay Now</v-btn>
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog width="700px" v-model="dialog9">
      <v-card>
        <v-form id="downloadinvoice" autocomplete="off" ref="form3">
          <!-- <v-card-text> -->
          <v-container fluid>
            <div>
              <div class="row">
                <div class="col s12 top-heading">
                  <!-- <div class="col s6 inline"> -->
                  <img src="/old_logo.png" height="25" />
                </div>
              </div>

              <div class="row">
                <div class="col s6 push-s1">
                  To,
                  <br />{{ uniqueclient.client }}
                  <br />{{ uniqueclient.clientAddress }}
                  <br />{{ uniqueclient.city }}, {{ uniqueclient.zip }}
                  <br />Phone: {{ uniqueclient.clientphone1 }}
                </div>
                <div class="col s5 right-align">
                  <div class="col s6 push-s1">
                    Alco Brake LLC
                    <br />1421 Champion Drive
                    <br />Ste. 352, Carrollton
                    <br />75006
                    <br />Phone: +1 844 425 2627
                  </div>
                </div>
              </div>

              <div class="divider"></div>
              <div class="section row">
                <div class="col s11 right-align">
                  Invoice Number:
                  <!-- <div class="input-field inline"> -->
                    <input class="right-align" disabled v-model="addInfo.invoiceId" />
                  <!-- </div> -->
                  <br />
                  Vehicle Registration no:
                  <!-- <div class="input-filed inline"> -->
                    <input class="right-align" disabled v-model="addInfo.regnum" />
                  <!-- </div> -->
                  <br />
                  <br/>
                  Date: {{ date }}
                  <!-- <br />
                            Amount due: {{format_price(total_amount_due)}} -->
                </div>
              </div>

              <div class="divider"></div>
              <v-card style="background-color: #f5f5f5;margin-top: 5%;overflow: hidden;width: fit-content;">
              <div>
                <div class="col s12">
                  <table class="striped highlight">
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Amount</th>
                        <th>Discount</th>
                        <th>Description</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in addInfo.service" :key="index" style="background-color: #e0e0e0;">
                        <td>
                          {{ item.service }}
                          <!-- <v-autocomplete solo dense v-on:change="getServiceInfo(index)" v-model="item.service"
                            :items="invoiceservicess" label="Select Service" item-text="servicetype"></v-autocomplete> -->
                          <!-- <input v-model="item.name" /> -->
                        </td>
                        <td>
                          {{ item.actualamount }}
                          <!-- <v-text-field v-model="item.actualamount" solo dense :disabled="item.service != 'Others' ? true : false"></v-text-field> -->
                        </td>
                        <td>
                          {{ item.discount }}
                          <!-- <v-text-field v-on:change="getTotal(index,item.discount)" :rules="amountRules" v-model="item.discount" solo dense></v-text-field> -->
                        </td>
                        <td>
                          <textarea style="padding-bottom: -2%" :value="item.description" required
                                                                                readonly solo dense></textarea>
                          <!-- <v-text-field v-model="item.description" solo dense></v-text-field> -->
                        </td>
                        <td>
                          {{ '$' + item.total + '.00' }}
                          <!-- <v-text-field :value="item.total" disabled solo dense> </v-text-field> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-card>

              <div class="divider"></div>
              <div class="row">
                <div class="col s12 right-align" style="margin-top: 3%;">
                  Total: {{ format_price(total_all_items) }}
                  <br /> Last amount Paid: ${{ previouspaid }}
                  <br />
                  Previous balance: {{ prevBalance }}
                  <br />
                  Grand Total: ${{ total_amount_due }}
                  <br />
                  Balance: {{ previous_balance }}
                </div>
              </div>

              <div class="col s12">
                <b>
                  <i>Please pay the balance within 30 days.</i>
                </b>
              </div>
            </div>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-btn color="blue" @click="close9">Cancel</v-btn>
          <v-btn color="blue" @click="downLoadinvoice">Download</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialog8" max-width="800px">
      <invoices :data="post" @clicked="onClickChild"></invoices>
    </v-dialog>
    <v-card style="border-radius:20px">
      <v-data-table
        dense
        :headers="headers2"
        :items="uniqueinvoice"
        sort-by="id"
        item-key="id"
        :search="search3"
        class="elevation-1" 
      >
      <template v-slot:top>

               </template>
               <template slot=item.prevbalance slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="item.prevbalance && item.prevbalance != 0">
              <v-btn color="orange" v-bind="attrs" style="color:white;border-radius:50px" height="30px" width="5px"
                v-on="on" v-on:click="pay(item)">{{
                  "$" + item.prevbalance }}</v-btn>
            </div>
            <div v-else>
              NO DUE
            </div>
        <!-- <v-btn color="orange" @click="pay(item)"  v-bind="attrs"
          v-on="on">${{ item.prevbalance }}</v-btn> -->
      </template>
      <span>Pay Invoice</span>
    </v-tooltip>
        </template>
      <!-- <template slot=item.edit slot-scope="{ item }"> -->
          <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="red" @click="editItem(item)" alt="" v-bind="attrs"
          v-on="on">
          mdi-border-color
          </v-icon>
      </template>
      <span>Edit Invoice</span>
    </v-tooltip> -->
        <!-- </template> -->
  
        <!-- <template slot=item.delete slot-scope="{ item }"> -->
          <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="blue" @click="deleteInvoice(item)" alt="" v-bind="attrs"
          v-on="on">
           mdi-delete
          </v-icon>
      </template>
      <span>Delete Invoice</span>
    </v-tooltip> -->
        <!-- </template> -->
        <template slot=item.download slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="blue" @click="downloadInvoice(item)" alt="" v-bind="attrs"
          v-on="on">
           mdi-download
          </v-icon>
      </template>
      <span>Download Invoice</span>
    </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>
        
        
              </v-data-table>
              
      <v-dialog v-model="dialog6" max-width="400px">
        <v-card>
          <v-toolbar flat color="#26BB44" class="" dark dense>
            <v-toolbar-title>Select Vehicle</v-toolbar-title>
          </v-toolbar>
          <v-form id="clientSelection" autocomplete="off" ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            dense
                            required
                            :rules="officeRules"
                            v-on:change="getClients"
                            v-model="addInfo.office"
                            :items="offices"
                            label="Select Office"
                            item-text="officeName"
                            item-value="officeId"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            dense
                            required
                            :rules="clientRules"
                            v-on:change="getVehicles"
                            v-model="addInfo.client"
                            :items="officeclients"
                            label="Select Client"
                            item-text="client"
                            item-value="clientId"
                          ></v-autocomplete>
                        </v-col> -->
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete dense required :rules="vehicleRules" v-model="addInfo.regnum" :items="vehicles"
                      label="Select Vehicle" item-text="regnum" item-value="regnum"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close3">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-if="validAmount && validdiscountAmount" :disabled="!valid" @click="next">Next</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog7" width="1000px">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Add Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Payment
            </v-stepper-step>
          </v-stepper-header>
          <!-- </v-stepper> -->

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form id="generateInvoice" autocomplete="off" ref="form2">
                <v-card>
                  <v-toolbar flat color="blue-grey" class dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <div>
                        <div class="row">
                          <div class="col s12 top-heading">
                            <!-- <div class="col s6 inline"> -->
                            <img src="/old_logo.png" height="25" />
                            <!-- </div> -->
                            <!-- <div>
                              <h5 class="inline">Invoice</h5>
                            </div>-->
                          </div>
                        </div>

                        <div class="row">
                          <div class="col s6 push-s1">
                            To,
                            <br />{{ uniqueclient.client }}
                            <br />{{ uniqueclient.clientAddress }}
                            <br />{{ uniqueclient.city }}, {{ uniqueclient.zip }}
                            <br />Phone: {{ uniqueclient.clientphone1 }}
                          </div>
                          <div class="col s5 right-align">
                            <div class="col s6 push-s1">
                              Alco Brake LLC
                              <br />1421 Champion Drive
                              <br />Ste. 352, Carrollton
                              <br />75006
                              <br />Phone: +1 844 425 2627
                            </div>
                          </div>
                        </div>

                        <div class="divider"></div>
                        <div class="section row">
                          <div class="col s11 right-align">
                            Invoice Number:
                            <div class="input-field inline">
                              <input class="right-align" disabled v-model="addInfo.invoiceId" />
                            </div>
                            <br />
                            Vehicle Registration no:
                            <div class="input-filed inline">
                              <input class="right-align" disabled v-model="addInfo.regnum" />
                            </div>
                            <br />
                            Date: {{ date }}
                            <!-- <br />
                            Amount due: {{format_price(total_amount_due)}} -->
                          </div>
                        </div>

                        <div class="divider"></div>
                        <div class="section row">
                          <div class="col s12">
                            <table class="striped highlight">
                              <thead>
                                <tr>
                                  <th>Service</th>
                                  <th>Amount</th>
                                  <th>Discount</th>
                                  <th>Description</th>
                                  <th>Total</th>
                                  <th class="hide-print">Remove</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(item, index) in addInfo.service" :key="index">
                                  <td>
                                    <v-autocomplete solo dense v-on:change="getServiceInfo(index)" v-model="item.service"
                                      :items="invoiceservicess" label="Select Service"
                                      item-text="servicetype"></v-autocomplete>
                                    <!-- <input v-model="item.name" /> -->
                                  </td>
                                  <td>
                                    <v-text-field v-model="item.actualamount" solo dense :disabled="item.service != 'Others' ? true : false"></v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field v-on:change="getTotal(index,item.discount)" :rules="amountRules" v-model="item.discount" solo
                                      dense></v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field v-model="item.description" solo dense></v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field :value="item.total" disabled solo dense> </v-text-field>
                                  </td>
                                  <td class="hide-print">
                                    <v-btn class="ma-0 pa-0" fab dark small v-on:click="remove_item(index)" color="red">
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                    <!-- <button class="btn-floating" >X</button> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div v-if="editedIndex == -1" class="section hide-print">
                              <v-btn class="primary" v-on:click="add_item">Add Service</v-btn>
                            </div>
                          </div>
                        </div>

                        <div class="divider"></div>
                        <div class="row">
                          <div class="col s12 right-align">
                            Total: {{ format_price(total_all_items) }}
                            <br /> Last amount Paid: ${{ previouspaid }}
                            <br />
                            Previous balance: {{ prevBalance }}
                            <br />
                            Grand Total: ${{ total_amount_due }}
                            <br />
                            <br />Amount paid:
                            <div class="input-field inline">
                              <v-text-field v-on:change="getBalance" :rules="amountRules" v-model="currentamountpaid" solo dense>
                              </v-text-field>
                              <!-- <input class="right-align" v-model="amount_pai" /> -->
                            </div>
                            <br />
                            Balance: {{ previous_balance }}
                          </div>
                        </div>

                        <div class="divider"></div>
                        <div class="divider"></div>
                        <div class="row section">
                          <div class="col s12 center-align">
                            <b>
                              <i>Please pay the balance within 30 days.</i>
                            </b>
                          </div>
                        </div>
                      </div>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close4">Cancel</v-btn>
                    <v-btn color="blue darken-1" v-if="validAmount && validdiscountAmount" text @click="save3">Next</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>

            </v-stepper-content>
            <v-stepper-content step="2">
              <v-col cols="12">
                <v-row class="justify-center">
                      <v-col class="col-md-5" align="center">
                        <div style="background:black;color:white;border-radius:20px;padding:10px"><v-icon color="green"
                            class="mr-3">mdi-cash-multiple</v-icon>Your bill was updated to the amount {{ format_price(total_all_items)
                            }}
                        </div>
                        <v-btn class="mt-4" @click="payprevious"
                          color="warning"><v-icon>mdi-arrow-left</v-icon>Previous</v-btn>
                      </v-col>
                    </v-row>
        </v-col>
<v-row>
  <v-col>
  <v-btn  color="warning" @click="close4">
                CANCEL
              </v-btn>
            </v-col>
            <v-col>
              <v-btn style="float:right; margin-right:10px" color="primary" @click="save4">
                UPDATE
              </v-btn>
            </v-col>
              </v-row>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<style scoped>
body {
  background: #ddd;
}

.container {
  background: white;
  padding: 0 0.7rem 1rem;
}

.top-heading {
  background: rgb(74, 118, 175);
  color: white;
}

@media print {
  .hide-print {
    display: none;
    visibility: hidden;
  }

  .input-field input,
  table input {
    border-bottom: none;
  }
}
th, td {
            text-align: center; /* Center-align text inside both th and td elements */
        }
</style>
