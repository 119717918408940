<template>
  <div class="payment-keys">
    <h1>Payment Gateway Keys</h1>
    <form @submit.prevent="updateKeys">
      <div>
  <v-container>
    <h3 style="margin-top: 30px;">Stripe Gateway</h3>
    <div class="toggle-container">
      <span class="mode-label" :class="{ active: !isLiveMode }" id="test-mode">Test Mode</span>
      <v-switch
        v-model="isLiveMode"
        inset
        class="switch"
        @change="switchMode"
      ></v-switch>
      <span class="mode-label" :class="{ active: isLiveMode }" id="live-mode">Live Mode</span>
    </div>
  </v-container>
        <div class="key">
          <div style="width: 480px;">
            <v-text-field
              outlined
              dense
              v-model="stripe.test_key"
              label="Enter Stripe test key"
            ></v-text-field>
          </div>
          <div style="width: 480px;">
            <v-text-field
              outlined
              dense
              v-model="stripe.live_key"
              label="Enter Stripe live key"
            ></v-text-field>
          </div>
        </div>

        <div class="token">
          <div>
            <v-text-field
              outlined
              dense
              v-model="stripe.test_token"
              label="Enter Stripe test token"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              outlined
              dense
              v-model="stripe.live_token"
              label="Enter Stripe live token"
            ></v-text-field>
          </div>
        </div>
      </div>

      <!-- <h3>paypal Gateway</h3>
        <div class="key">
      <div  style="width: 480px;">
        <v-text-field outlined dense v-model="paypal.paypal_test_key" label="Enter paypal test key"></v-text-field>
      </div>
      <div style="width: 480px;">
        <v-text-field outlined dense v-model="paypal.paypal_live_key" label="Enter paypal live key"></v-text-field>
      </div>
    </div>
    <div class="token_paypal">
      <div>
        <v-text-field outlined dense v-model="paypal.paypal_test_token" label="Enter paypal test token"></v-text-field>
      </div>
      <div>
        <v-text-field outlined dense v-model="paypal.paypal_live_token" label="Enter paypal live token"></v-text-field>
      </div>
    </div> -->

      <!-- <div>
          <label>PayPal Test Key:</label>
          <input v-model="keys.paypalTest" type="text" placeholder="Enter PayPal test key" />
        </div>
        <div>
          <label>PayPal Live Key:</label>
          <input v-model="keys.paypalLive" type="text" placeholder="Enter PayPal live key" />
        </div> -->
      <button class="button" type="submit">Update Keys</button>
    </form> 
    <div v-if="message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isEdit: false,

      user: JSON.parse(localStorage.getItem("user")),
      dealerid: "257",
      gatewayid: null,
      isLiveMode:false,
      stripe: {
        test_key: "",
        live_key: "",
        test_token: "",
        live_token: "",
      },
      // paypal: {
      //   paypal_test_key: '',
      //   paypal_live_key: '',    
      //   paypal_test_token: '',
      //   paypal_live_token: '',
      // },
      message: "",
    };
  },
  mounted(){
  document.getElementById('mode-checkbox').addEventListener('change',this.switchmode);
  },
  methods: {
    async fetchKeys() {
      console.log("DD"+this.user.dealerId)
      try {
        const response = await axios.get(
      `https://robopower.xyz/v2/gateway/getdealergateways?dealerid=`+this.user.dealerId);
      console.log(response);
        if (response.data != null) {
          this.isEdit = true;
          var keys = response.data;
          var stripekeys = response.data[0].stripe_details;
          this.isLiveMode = response.data[0].mode == "TEST"?false:true;
          this.gatewayid = response.data[0].id;
          console.log(JSON.parse(stripekeys));
          this.stripe = JSON.parse(stripekeys);
          console.log(this.stripe);
        } else {
          this.isEdit = false;
        }
      } catch (error) {
        console.error("Error fetching keys:", error);
      }
    },
    async updateKeys() {
      try {
        var formdata = new FormData();
        if (this.isEdit) {
          const date = new Date();
          const datetime =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear() +
            " " +
            date
              .getHours()
              .toString()
              .padStart(2, "0") +
            ":" +
            date
              .getMinutes()
              .toString()
              .padStart(2, "0") +
            ":" +
            date
              .getSeconds()
              .toString()
              .padStart(2, "0");

          formdata.append("dealer_id",this.user.dealerId);
          formdata.append(
            "stripe_details",
            JSON.stringify({
              test_key:
                this.stripe.test_key,
              live_key:
                this.stripe.live_key,
              test_token:
                this.stripe.test_token,
              live_token:
                this.stripe.live_token,
            })
          );
          formdata.append("updated_at",datetime);
          console.log(datetime);
          console.log(this.gatewayid);
          await axios
            .post(
              "https://robopower.xyz/v2/gateway/editgateway/" + this.gatewayid,
              formdata,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              if (res.data == "Successfully Updated Payment Method") {
                this.message = "Keys updated successfully!";
              } else {
                this.message = "updating failed";
              }
            });
        }
       else {
          const date = new Date();

          const datetime =
            date.getMonth() +
            1 +
            "/" +
            date.getDate() +
            "/" +
            date.getFullYear() +
            " " +
            date
              .getHours()
              .toString()
              .padStart(2, "0") +
            ":" +
            date
              .getMinutes()
              .toString()
              .padStart(2, "0") +
            ":" +
            date
              .getSeconds()
              .toString()
              .padStart(2, "0");
          const formdata = new FormData();
          formdata.append("dealer_id",this.user.dealerId);
          formdata.append(
            "stripe_details",
            JSON.stringify({
              test_key:
                this.stripe.test_key,
              live_key:
                this.stripe.live_key,
              test_token:
                this.stripe.test_key,
              live_token:
                this.stripe.live_key,
            })
          );
          formdata.append("created_at", datetime);
          console.log(datetime);
          await axios
            .post("https://robopower.xyz/v2/gateway/addgateway", formdata, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              
            })
            .then((res) => {
              console.log("ppp")
              console.log(res.data)
              if (res.data == "successfully Added Payment Methods") {
                this.message = "Keys updated successfully!";
              } else if (res.data == "Payment Gateway Already Exists") {
                this.message = res.data;
              } else {
                this.message = "updating failed";
              }
            });
        }
      } catch (error) {
        console.error("Error updating keys:", error);
        this.message = "Failed to update keys.";
      }
    },
   async switchMode() {
      console.log(this.isLiveMode)
      try {
        const date = new Date();
        const datetime = 
          (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
          date.getDate().toString().padStart(2, '0') + '/' +
          date.getFullYear() + ' ' +
          date.getHours().toString().padStart(2, '0') + ':' +
          date.getMinutes().toString().padStart(2, '0') + ':' +
          date.getSeconds().toString().padStart(2, '0');

        const mode = this.isLiveMode ? 'LIVE' : 'TEST';
        console.log("MYMODE"+mode);
        const formdata = new FormData();
        formdata.append("dealer_id", this.user.dealerId);
        formdata.append("mode", mode);
        formdata.append("updated_at", datetime);
        console.log(this.gatewayid);
        const response = await axios({
          method: 'POST',
          url: 'https://robopower.xyz/v2/gateway/switchmode',
          data: formdata,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response);
      } catch (err) {
        console.error(err);
      }
    }
  },
  created() {
    this.fetchKeys();
  },
};
</script>

<style scoped>
.payment-keys {
  max-width: 1000px;
  margin: 0 auto;
}
form {
  display: flex;
  flex-direction: column;
}
div {
  margin-bottom: 15px;
}
label {
  font-weight: bold;
}
input {
  padding: 8px;
  font-size: 16px;
}
.button {
            padding: 10px ;
            font-size: 16px;
            color: white;
            background-color: #4CAF50;
            border: none;
            border-radius: 10px;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); 
            position: relative;
            transition: all 0.3s ease;
            width: 200px;
            margin-left: 350px;
        }
        .button::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.3); 
            border-radius: 10px;
            pointer-events: none;
        }
        .button:hover {
            background-color: #45a049;
        }
.key {
  margin-top: -35px;
}
.token {
  margin-top: -162px;
  margin-left: 500px;
}
.token_paypal {
  margin-left: 500px;
  margin-top: -177px;
}
.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.mode-label {
  margin: 0 10px;
  font-size: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
