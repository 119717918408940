<template>
    <v-container id="flutterpayment" fluid tag="section">
        <v-card class="elevation-12">

<v-col cols="12">
  <v-row class="justify-center">
    <v-col class="col-md-8" align="left">
      <h3>Add Your Payment Information</h3>
      <!-- <div style="background:#334c3e !important;color:white;border-radius:20px;padding:10px"><v-icon color="green"
          class="mr-4">mdi-cash-multiple</v-icon>You are paying the amount {{ this.$route.query.amount/100 }}
        </div> -->
    </v-col>
  </v-row>
</v-col>
<div v-if="created" class="container">
  <!-- <stripe-element-payment  ref="paymentRef" :pk="pk" 
  :elements-options="elementsOptions"
  @confirm-params="handleConfirmParams"></stripe-element-payment> -->
  <stripe-element-payment  ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
  :confirm-params="{ return_url:`https://alcobrakeadmin.com/fluttersuccesstransaction?&pi=`+this.$route.query.pi+`&email=`+this.$route.query.email+`&amount=`+this.$route.query.amount+`&docid=`+this.$route.query.docid+`&appointment=`+this.$route.query.appointment+`&createdDate=`+this.$route.query.createdDate+`&customer=`+this.$route.query.customer+`&user=`+this.$route.query.user}" />
  <br />
  <v-layout justify-center>
    <!-- dialog paynow -->
    <!-- <v-btn class="mr-4" @click="history.back()" dark style="background:#ff3d41;color:white">Cancel</v-btn> -->
    <v-btn  color="#67F2D6" left @click="finalPay">Pay ${{this.$route.query.amount}}</v-btn>
  </v-layout>
</div>
<div v-else>
  <h1>Thank You for Choosing Alcobrake</h1>
</div>
</v-card>
   
    </v-container>
  </template>
  
  
<script>
import Vue from "vue";
import moment from "moment";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
export default {
    name:"Flutterflow",
  props: {
    source: String
  },
  data() {
    return {
      allparams:{"fields":{}},
      myApi:null,
      selectedStudent: null,
      parentMobile: null,
      // pk:"pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
       pk: process.env.VUE_APP_STRIPE_KEY,
    elementsOptions: {
        appearance: {},
        clientsecret:'pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt', // appearance options
      },
      created:false
    };
  },
  components: {
    StripeElementPayment,
  },
  watch:{
    allparams(val){
      this.allparams = val;
    },
    myApi(val){
      this.myApi = val;
    },
    created(val){
      this.created = val;
    }
    
  },  
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  mounted(){
this.elementsOptions.clientSecret=this.$route.query.pi;
// this.setData();
  },
  created() {
    this.created=true;
  },
  methods: {
    finalPay(){

      const that = this;
      console.log(`https://robopower.xyz/v3/flutter/updatepayment?docid=`+that.$route.query.docid+`&data=`+JSON.stringify(that.allparams))
      console.log(JSON.stringify(that.allparams))
console.log(this.allparams)
    setTimeout(()=>{
      console.log(that.allparams)
      that.$refs.paymentRef.submit();
    },2000)  
    //here check for the Payment success
    //run a loop

    },
    setData(){
      const that = this;
      var transactiontime = moment(new Date()).toISOString();
const parsedDate = moment(this.$route.query.createdDate, 'MMMM DD, YYYY h:mm:ss A [UTC]Z', 'Asia/Kolkata');
const cdate = parsedDate.toISOString();
that.allparams.fields.amount = {"integerValue":this.$route.query.amount};
    that.allparams.fields.appointment = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/appointments/'+this.$route.query.appointment};
    that.allparams.fields.createdDate = {"timestampValue":cdate};
    that.allparams.fields.customer = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/customers/'+this.$route.query.customer};
    that.allparams.fields.status = {"stringValue":"success"};
    that.allparams.fields.transactionDate={"timestampValue":transactiontime};
    that.allparams.fields.user = {"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/users/'+this.$route.query.user};
  setTimeout(()=>{
    console.log(`https://robopower.xyz/v3/flutter/updateffpayment?docid=`+that.$route.query.docid+`&data=`+JSON.stringify(that.allparams))
    that.myApi = decodeURIComponent(`https://robopower.xyz/v3/flutter/updateffpayment?docid=`+that.$route.query.docid+`&data=`+JSON.stringify(that.allparams))
    console.log(that.myApi);
that.created = true;
  },2000)  
  }
  }
};
</script>