import { render, staticRenderFns } from "./lastoperations.vue?vue&type=template&id=4fdd5018&scoped=true&"
import script from "./lastoperations.vue?vue&type=script&lang=js&"
export * from "./lastoperations.vue?vue&type=script&lang=js&"
import style0 from "./lastoperations.vue?vue&type=style&index=0&id=4fdd5018&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdd5018",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VFlex,VRow})
