<script>
//import getInfo from "../scripts/getInfo";
import {mapGetters} from 'vuex';
import Vue from "vue";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    show: false,
    sendSaveStopOption: false,
    location: null,
    id: null,
      routeId: null,
      routess: [],
      clientid: null,
      stopList: [],
      //curr location
      gettingLocation: false,
    states: [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
      "INDIA"
    ],
    // access: [{ text: "Admin" }, { text: "Manager" }, { text: "Technician" }],
    headers: [
      // { text: "ID", value: "officeId" },
      {
        text: "Route Id",
        align: "left",
        value: "routeId"
      },
      { text: "School", value: "officeName" },
      // { text: "Phone 2", value: "officeMobile2" },
      { text: "Source", value: "Source.stopname" },
      { text: "Destination", value: "Destination.stopname" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" }
    ],
    editedIndex: -1,
    defcountry:'us',
    editedItem: {
      form:"",
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework:"",
      officepassword:"",
      officeaddress:"",
      city:"",
      state:"",
      zipcode:"",
      isbar:"1",
      officelicense:"",
      dealerName: ""
    },
    defaultItem: {
      officeName: "",
      officeId: "",
      officeMobile1: "",
      officeMobile2: "",
      officeemail: "",
      officehome: "",
      officework:"",   
      officepassword:"",
      officeaddress:"",
      city:"",
      state:"",
      zipcode:"",
      isbar:"1",
      officelicense:"",
      dealerName: ""
    },
    valid: true,
    // routeRules: [
    //     v => !!v || "RouteName is required",
    //     v =>
    //       !this.routes ||
    //       this.editedIndex != -1 ||
    //       (v &&
    //         !this.routes.find(
    //           route => route.routeId.toLowerCase() == v.toLowerCase()
    //         )) ||
    //       "Route id already exists in database"
    //   ],
  }),

  computed: {
    resultQuery() {
      if(this.expanded.length>0 ){
      var mylist = this.expanded;
        return mylist
      } else{
        return this.routes
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Office" : "Edit Office";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['offices']),
    ...mapGetters(['routes']),
    ...mapGetters(['dealers'])
  },
  components: {
    RouteMap: () => import("../subcomponents/routemap2.vue")
  },
  watch: {
    defcountry(val) {
      this.defcountry = val;
      setTimeout(() => {
        this.defcountry = 'us';
      },500)
    },
    dialog(val) {
      console.log("VAL",val)
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.getStops();
    this.sendSaveStopOption = true;
    if(this.user.role == "school") {
      this.editedItem.dealerName = this.user.dealerId;
    } 
    this.mountdata();
  },

  methods: {
   
    getStops(item) {
      console.log("III",item)
      Vue.axios
        .get(
          "https://robopower.xyz/app/routes/getroutestops?routeId=" +
            item.routeId
        )
        .then(resp => {
          console.log("RRotes",resp)
          var rest = [];
          var rel = {};
          var list = resp.data;
          this.stopList = list.sort((a, b) => {
            return a.stopid - b.stopid;
          });
          //this.routess = list;
          list.map(res => {
            rel = {
              lat: res.lat,
              lon: res.lon,
              stopid: res.stopid,
              stopname: res.stopname
            };
            rest.push(rel);
          });
          this.routess = rest;
          this.editroute = true;
        })
        .catch(error => {
          console.log("Error: ", error);
        });
    },
    onMapClick(latLng, inputValue) {
      var lati = latLng.lat.toFixed(3);
      var loni = latLng.lng.toFixed(3);
      var sid = this.routess.length;
      this.routess.push(
        Object.assign(
          {},
          {
            stopid: sid.toString(),
            stopname: inputValue,
            lat: lati,
            lon: loni
          }
        )
      );
    },
    removeMarker(marker) {
      this.routess.forEach((data, index) => {
        if (
          parseFloat(data.lat) === marker.lat &&
          parseFloat(data.lon) === marker.lon
        ) {
          this.routess.splice(index, 1);
        }
      });
      this.routess.forEach((data, index) => {
        var ssid = index;
        this.routess[index].stopid = ssid.toString();
      });
    },
    deleteRoute(route) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete the Route " + " " + route.routeId,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteRoute", route.routeId);
          Swal.fire("Deleted!", "Your Route has been deleted.", "success");
        }
      });
    },
    deleteRoute2(route) {
      this.$store.dispatch("deleteRoute", route.routeId);
      return route;
    },
    onSelect({ dialCode }) {
      this.editedItem.officehome = dialCode;
    },
    onSelect2({ dialCode }) {
      this.editedItem.officework = dialCode;
    },
        isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mountdata(){
     this.$store.dispatch('fetchDealers')
      this.$store.dispatch("fetchallschoolRoutes", this.user.dealerId);
    },
    deleteOffice(office) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Are you sure? You want to delete Office - ' + office.officeName,
          text: 'You can\'t revert your action',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes Delete it!',
          cancelButtonText: 'No, Keep it!',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch('deleteOffice', office.officeId).then(() => {
              this.mounteddata();
            }).catch((err) => {
              console.log("first")
              this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            })
            // this.$swal('Deleted', 'You successfully deleted this file', 'success')
            console.log("second")
            this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })

          } else {
            // this.$swal('Cancelled', 'Your file is still intact', 'info')
            console.log("third")
            this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officehome + this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officework + this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    editloadFormData() {
      const formData = new FormData();
      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    initialize() {},

    editItem(item) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
       // console.log(item)
      this.editedIndex = this.routes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true; 
      } else {
           this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
      
    },

    close() {
      this.dialog = false;
      this.defcountry = 'US';
      this.$refs.form.reset();
      this.editedIndex = -1;
      
    },
    
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedIndex == -1) {
        var fd;
        if (this.user.role == "ADMIN") {
          fd = {
            routeId: this.routeId,
            routes: this.routess,
            clientid: this.clientid.clientId.toString()
          };
        } else {
          fd = {
            routeId: this.routeId,
            routes: this.routess,
            clientid: this.user.clientId.toString()
          };
        }

        var data = JSON.stringify(fd);
        if (!this.$refs.form.validate()) {
          return;
        }
        this.$store.dispatch("createRoute", data);
        this.routess = [];
        this.stopList = [];
        this.close();
      } else {
        var item = {
          routeId: this.routeId
        };
        const myPromise = new Promise((resolve, reject) => {
          if (item) {
            Vue.axios
              .get(
                "https://robopower.xyz/app/routes/deleteroute/" + item.routeId
              )
              .then(() => {
                resolve({ ok: true, response: item });
              });
          } else {
            reject("Deletion failed");
          }
        });
        myPromise
          .then(() => {
            if (this.user.role == "ADMIN") {
              fd = {
                routeId: this.routeId,
                routes: this.routess,
                clientid: this.clientid.clientid
              };
            } else {
              fd = {
                routeId: this.routeId,
                routes: this.routess,
                clientid: this.user.clientId.toString()
              };
            }
            // data = JSON.stringify(fd);
            data = JSON.stringify(fd);
            this.$store.dispatch("createRoute", data);
            this.routess = [];
            this.stopList = [];
            this.close();
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px !important">
      <v-data-table
        dense
        :headers="headers"
        :items="resultQuery"
        item-key="officeId"
        sort-by="office_name"
        :search="search"
        class="elevation-1"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          
             <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
            <v-toolbar flat color="#00A3A3" dark dense class="mb-2" style="border-radius:20px">
              <span style="font-size:40%">
              <v-toolbar-title>ROUTES</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="620px">
          <template v-slot:activator="{ on }">
         <v-row>
          <v-col cols="12" mx="4">
           <v-layout justify-end>
                <!-- <v-btn color="black"   class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px" >
                  Add Office
                </v-btn> -->
                 <!-- <v-icon v-if="user.emailId != 'Justin@alcobrake.com'" v-on="on"  size="40px"  color="white" style="background:black;border-radius:50%">mdi-plus</v-icon> -->
  
                 <v-btn  v-if="user.emailId != 'Justin@alcobrake.com'"  v-on="on" style="border-radius:20px;background:black"> <h2>+</h2><v-icon size="20px"  color="dark">mdi-home-modern</v-icon></v-btn>
           </v-layout>
              </v-col>
                  </v-row>
          </template>
                <v-card style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                    <v-text-field
                      class="purple-input"
                      label="RouteId"
                      required
                      v-model="routeId"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <route-Map
                      id="routemap"
                      :route="routeId"
                      :AddRoutesaving="sendSaveStopOption"
                      name="routeMap"
                      :currentloc="location"
                      :editedIndex="editedIndex"
                      :stopList="stopList"
                      @mapOnClick="onMapClick"
                      @removeMarker="removeMarker"
                    ></route-Map>
                  </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  @click="close"  style="background:#ff3d41;color:white">Cancel</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="save">{{buttonTitle}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            </v-form>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteOffice(item)">
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item}">
          <td :colspan="headers.length" class="ma-0 pa-0" align="center">
         
            <v-col class="col-md-6">
              <v-card class="mt-2 mb-2" color="#334c3e" style="border-radius:20px;padding:10px">
              <v-list dense class="ma-0 pa-0" style="background:#334c3e">
               <v-list-item>
                 <v-icon class="off-icon">mdi-clipboard-account</v-icon>
                  <v-list-item-content class="content1">ID:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeId }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-account</v-icon>
                  <v-list-item-content class="content1">Name:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeName }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-cellphone-iphone</v-icon>
                  <v-list-item-content class="content1">Phone:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeMobile1 }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-cellphone-basic</v-icon>
                  <v-list-item-content class="content1">Phone 2:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeMobile2 }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon class="off-icon">mdi-email-outline</v-icon>
                  <v-list-item-content class="content1">Email:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeemail }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-icon class="off-icon">mdi-home-outline</v-icon>
                  <v-list-item-content class="content1">Address:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officeaddress }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-book-open</v-icon>
                  <v-list-item-content class="content1">Bar License 2:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.officelicense }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-icon class="off-icon">mdi-account-tie</v-icon>
                  <v-list-item-content class="content1">Dealer:</v-list-item-content>
                  <v-list-item-content class="align-end content2">{{ item.dealer }}</v-list-item-content>
                </v-list-item>
           </v-list>
            </v-card>
            </v-col>
           </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
.content2
{
  color:#14c7c7 !important;
  border-bottom:1px dashed white
}
.content1
{
  color: white !important;
  margin-left:8px;
}
.off-icon
{
  color:white;
  background:#00A3A3;
  padding:2px;
  border-radius:10px;
}
</style>

