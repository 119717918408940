<script>
// import getInfo from "../scripts/getInfo";
import { mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";
import moment from 'moment';
// import VueHtml2pdf from 'vue-html2pdf'
// import jsPDF from 'jspdf';
Vue.use(axios);
export default {
  data() {
    return {
      selectedmodel:null,
      selectedmodelId:null,
      selectedItem: null,
      selectedItemId: null,
      isSwitchOn: false,
      user: JSON.parse(localStorage.getItem("user")),
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      deviceoffice: "",
      selectoffice: false,
      loadingtext: "Please Wait",
      textloading: false,
      // content_control: ["Return from Service", "Send to Service","Available"],
      status: [
        "NotAssigned",
        "Assigned",
        "Removed",
        // "Went to Service",
      ],
      show: false,
      messages: "",
      qrname: null,
      dialog1: false,
      states: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "INDIA", // India Country with control board
        "INDIAV2", // India Country without control board
        "BZ" //brazil Country
      ],
      headers: [

      ],
      firmware: "",
      editedIndex: -1,
      // editedItem: {
        dealerName: "",
        dealerId: "",
        devId: "",
        cbid: "",
        snostate: "",
        iswifi: "",
        sno: "",
        lastopdate: "",
        lastoperation: "",
        autotimeset: "",
        calibrationdate: "",
        lastiddownload: 0,
        lastsentdate: 0,
        timezone: '',
        zigid:"",
        devicemodel:"",
        assigneddealer:"",
        updated_date:"",
        created_date:"",
        enablepayment:"",
        Active:"",
        Inactive:"",
        Disable:"",
        selectdealers:[],
      // },
      defaultItem: {
        dealerName: "",
        dealerId: "",
        devId: "",
      },
      valid: true,
      dealerRules: [(v) => !!v || "Select Dealer"],
      actionRules: [(v) => !!v || "Select action"],
      snoRules: [(v) => !!v || "Sno is required"],
      stateRules: [
        v => !!v || 'Select State',
      ],
      deviceRules: [
        (v) => !!v || "Device Id is required",
        v =>
          !this.oledDevices.find(device => device.devId == v) ||
          this.editedIndex != -1 ||
          "Device Id already exists use different one"
      ],
      controlidRules: [
        (v) => !!v || "ControlBoard Id is required",
        v =>
          !this.oledDevices.find(device => device.cbid == v) ||
          this.editedIndex != -1 ||
          "ControlBoard Id already exists use different one"
      ],
    }
  },

  computed: {
    mappedmodels(){
      return this.kitmodels.map(item => ({ name: item.devicemodel, id: item.id }));
    },
    mappedItems() {
      return this.dealers.map(item => ({ name: item.dealerName, id: item.dealerId }));
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Device" : "Edit Device";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['calibrationkit']),
    ...mapGetters(['devices']),
    ...mapGetters(['dealers']),
    ...mapGetters(['offices']),
    ...mapGetters(['kitmodels']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  beforeMount() {
    this.mounteddata();
  },
  mounted() {
    console.log(this.kitmodels,"kitmodels")
    console.log(this.dealers,'hiii')
    // this.selectdealers = this.dealers.map(item => ({ name: item.name, id: item.id }));
    // console.log(this.selectdealers,"dealers")
    this.$store.dispatch('fetchDealers');
    this.$store.dispatch('fetchkitmodels');
    if (this.user.role == 'dealer') {
      this.$store.dispatch('fetchdealerCalibrationkit',this.user.dealerId);
    } else {
      this.$store.dispatch('fetchCalibrationkit');
    }
      this.headers = [
        // {
        //   text: "Id",
        //   align: "left",
        //   value: "id",
        // },
        { text: "AssignedDealer", value: "assigneddealer"},
        { text: "Zigid", value: "zigid" },
        { text: "Model", value: "model" },
        { text: "Status", value: "status"},
        { text: "Created_Date", value: "created_date"},
        // { text: "Updated_Date", value: "updated_date"},
        { text: "Active", value: "active"},
       

        // { text: "status", value: "delete", sortable: false },
        // { text: "Device Status", value: "toggle" }
      ]
  },

  methods: {
    onSelectedmodelChanged() {
      if (this.selectedmodel) {
        this.selectedmodelId = this.selectedmodel.id;
      } else {
        this.selectedmodelId = null;
      }
    },
    onSelectedItemChanged() {
      if (this.selectedItem) {
        this.selectedItemId = this.selectedItem.id;
      } else {
        this.selectedItemId = null;
      }
    },
    toggleSwitch(item) {
      // You can perform additional actions here when the switch changes state
      console.log(this.isSwitchOn);
    },
    getSerialnum() {
      Vue.axios
        .get("https://robopower.xyz/us/cadevices/getserialnumber?state=" + this.editedItem.snostate)
        .then(response => {
          var mynum = parseInt(response.data) + 1
          var sno = "612" + this.editedItem.snostate + String(mynum).padStart(5, '0');
          // var sno = "612" + this.editedItem.snostate + String(mynum).padStart(5, '1000');
          if (this.editedItem.snostate) {
            this.editedItem.sno = sno;
          } else {
            this.editedItem.sno = "";
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mounteddata() {
        this.$store.dispatch('fetchCalibrationkit');
    },
    deleteDevice(device) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want To Delete This Device - " + device.devId,
        text: "You Can't Revert Your Action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteOLEDDevice', device.id);
          this.$store.dispatch("alert/success", "You successfully deleted this file").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    initialize() { },
    qrclose() {
      this.messages = "";
      this.dialog1 = false;
    },
    changeContent(content) {
      if (content == "Return from Service") {
        this.status = "Available";
      } else if (content == "Send to Service") {
        this.status = "Sent to Service";
      } else {
        this.status = "Available";
      }
    },
    onSelectoffice() {
      this.selectoffice = false;
    },
    onChangedealer(event) {
      if (this.editedIndex != -1) {
        //get dealer offices
        this.$store.dispatch("fetchdealerOffices", event.dealerId).then((res) => {
          this.selectoffice = true;
        })
        // getDealeroffices(event.dealerId)
      }

    },
    getOffice(item) {
      Vue.axios
        .get("https://robopower.xyz/us/fullclients/getuniquedevicefullclient?sno=" + item.sno).then((res) => {
          if (res.data) {
            this.deviceoffice = res.data[0].officeId;
          } else {
            this.$store.dispatch("alert/success", "Device Not Assigned to Client").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        })
    },
    editItem(item) {
      console.log(item)
    },
    samdoota(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do Sampler OTA - " + item.devId,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData = {
            devId: item.cbid,
            command: "103"
          };
          var cmdData2 = {
            devId: item.devId,
            command: "103"
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    cbdoota(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do Control Board OTA - " + item.cbid,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData2 = {
            devId: item.cbid,
            command: "98"
          };
          var cmdData = {
            devId: item.devId,
            command: "98"
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            this.$store.dispatch("alert/success", "OTA sent successfully").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          })
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            this.$store.dispatch("alert/success", "OTA sent successfully").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })

        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    s3ota(item, command) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do S3 OTA - " + item.cbid,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData2 = {
            devId: item.cbid,
            command: command,
            resp: "",
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " S3 OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " S3 OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
        } else {
          this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })

        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },

    generateqr(qrcode) {
      this.qrname = qrcode
      var alldate = {
        sno: qrcode.sno,
        devId: qrcode.devId,
        cbid: qrcode.cbid,
        calibrationdate: qrcode.calibrationdate,
        status: qrcode.status,
        dealerName: qrcode.dealerName
      }
      this.messages = JSON.stringify(alldate)
      this.dialog1 = true;
    },
    qrprint() {
      this.$htmlToPaper("printme");
      this.dialog1 = false
    },

    close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.deviceoffice = "";
    },
    loadFormData() {
      // console.log(this.editedItem.zigid,"kits")
      const parsedDate = moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
      // const formattedDate = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
      this.created_date = parsedDate;
      console.log("zigid", this.zigid)
      console.log("model", this.selectedmodel)
      console.log("assigneddealer", this.selectedItem)
      console.log("status", this.status)
      console.log("updated_date", '')
      console.log("created_date", this.created_date)
      let formData = new FormData();
      formData.append("zigid", this.zigid);
      formData.append("model", this.selectedmodel);
      formData.append("assigneddealer", this.selectedItem);
      formData.append("status", this.status);
      formData.append("updated_date", '');
      formData.append("created_date", this.created_date);
      return formData;
    },
    loadFormData2() {
      console.log(this.editedItem)
      const formattedDate = moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
      // const formattedDate = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
      this.created_date = formattedDate;
      const formData = new FormData();
      formData.append("zigid", this.zigid);
      formData.append("model", this.selectedmodel);
      formData.append("assigneddealer", this.selectedItem);
      formData.append("status", this.status);
      formData.append("updated_date", '');
      formData.append("created_date", this.created_date);
      return formData;
    },
    save() {
      console.log("save")
      if (!this.$refs.form.validate()) {
        console.log('1')
        return;
      }
      if (this.editedIndex == -1) {
        console.log('2')
        const fd = this.loadFormData();
        this.$store.dispatch('createKit',fd);
      } else {
        console.log('3')
        const fd = this.loadFormData2();
        var postdata = {
          devdata: fd,
          id: this.editedItem.sno
        }
        console.log(fd,'4')
        this.$store.dispatch('editDevice', postdata);
      }
      this.close();
    },
  },
};
</script>
<template>
  <v-container fluid>

    <v-data-table dense :headers="headers" :items="calibrationkit" sort-by="id" :search="search" class="elevation-1"
      style="border-radius:20px">
      <template v-slot:top>

        <v-toolbar flat color="#00A3A3" class="mb-4" dark dense style="border-radius:20px">
          <span style="font-size:40%">
            <v-toolbar-title>CALIBRATIONKIT</v-toolbar-title>
          </span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-row>
            <v-col cols="12" lg="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-dialog max-width="550px" v-model="selectoffice">
            <v-card>
              <div v-if="offices.length > 0">
                <!-- <h2>Please Select the Office </h2> -->
                <v-toolbar flat color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>Please Select the Office</v-toolbar-title>

                </v-toolbar>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete outlined dense v-model="deviceoffice" required :items="offices" label="Select Office*"
                    item-text="officeName" return-object>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.officeName }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-row>
                    <v-btn class="error" @click="selectoffice = false">Close</v-btn>
                    <v-layout justify-end>
                      <v-btn class="primary" @click="onSelectoffice()">Select</v-btn>
                    </v-layout>
                  </v-row>
                </v-col>
              </div>
              <div v-else>
                No Office details
              </div>
            </v-card>
          </v-dialog>
          <v-dialog v-model="textloading" width="450px">
            <v-card style="background-color:black;color: azure;">
              <v-card-title class="justify-center">{{ loadingtext }}</v-card-title>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-row>
                <v-col cols="12" mx="4">
                  <v-layout justify-end>
                    <!-- <v-btn color="black"  class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px" >
                  Add Device
                </v-btn> -->
                    <v-btn v-if="user.role == 'Admin' || user.role == 'superadmin'" v-on="on" style="border-radius:20px">
                      <h2>+</h2><v-icon>mdi-tablet-cellphone</v-icon>
                    </v-btn>


                  </v-layout>
                </v-col>
              </v-row>
            </template>
            <v-card style="border-radius:20px">
              <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-form autocomplete="off" ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- <v-col cols="12" sm="12" md="6" >
                        <v-autocomplete outlined dense  required :rules="stateRules" @change="getSerialnum"
                          v-model="editedItem.snostate" :items="states" label="Select State*"></v-autocomplete>
                      </v-col> -->
                      <v-col>
                        <v-text-field v-on:keypress="isLetter($event)" dense v-model="zigid" type="text"
                          required :rules="snoRules" label="Zigid*"></v-text-field>
                             
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="6" v-if="user.role == 'Admin' || user.role == 'superadmin'">
                        <v-autocomplete outlined dense v-model="editedItem.dealerName" required :rules="dealerRules" 
                          :items="dealers" @change="onChangedealer($event)" label="Select Dealer*" item-text="dealerName"
                          return-object>
                          <template slot="selection" slot-scope="data">
                            {{ data.item.dealerName }}
                          </template>
                        </v-autocomplete>
                      </v-col> -->
  
                      <v-col cols="12" sm="12" md="6">
                         <v-autocomplete outlined dense v-model="selectedmodel" required 
                          :items="mappedmodels" label="Select Model*"
                          item-text="name"
                          item-value="id"
                          @change="onSelectedmodelChanged">
                          </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <!-- <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.assigneddealer"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Assignedealer*"></v-text-field> -->
                          <v-autocomplete outlined dense
                          v-model="selectedItem"
                          :items="mappedItems"
                          item-text="name"
                          item-value="id"
                          label="Select Dealer*"
                          @change="onSelectedItemChanged"
                          ></v-autocomplete>
                          <!-- <v-autocomplete outlined dense v-model="assigneddealer" required  
                          :items="selectdealers" label="Select Dealer*" :item-text="item => item.dealerName" > -->
                          <!-- <template slot="selection" slot-scope="data">
                            {{ data.item.dealerName }}
                          </template> -->
                        <!-- </v-autocomplete> -->
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.status"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Status*"></v-text-field>
                      </v-col> -->
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete outlined dense label="Status*" required class="purple-input"
                          :rules="actionRules" v-model="status"
                          :items="['assigned', 'notassigned','removed']">
                        </v-autocomplete>
                      </v-col>
                       <!-- <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.created_date"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Created_date*"></v-text-field>
                      </v-col>
                       <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.updated_date"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Updated_date*"></v-text-field>
                      </v-col> -->
                      <!-- <v-col cols="12" sm="12" md="6">
                        <v-autocomplete outlined dense label="Status*" required class="purple-input"
                          :rules="actionRules" v-model="editedItem.status"
                          :items="['Installed', 'Removed', 'NotAssigned']">
                        </v-autocomplete>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close" style="background:#ff3d41;color:white">Cancel</v-btn>
                <v-btn color="blue darken-1" text :disabled="!valid" @click="save">{{ buttonTitle }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
   <template slot="item.active" slot-scope="{ item }">
  <v-layout class="mr-2 mt-2" align-center>
    <v-chip :color="item.status === 'assigned' ? 'green' : 'grey'" @click="editItem(item,1)" class="mr-2" label style="color: white;">
       {{item.status}}
    </v-chip>
    <!-- <v-chip color="orange" @click="editItem(item,0)" class="mr-2" label style="color: white;">
      {{item.status}}
    </v-chip>
    <v-chip color="red" @click="editItem(item,2)" label style="color: white;">
      {{item.status}}
    </v-chip> -->
  </v-layout>
</template>


      <!-- <template slot="item.inactive" slot-scope="{ item }">
       
      </template>
         <template slot="item.disable" slot-scope="{ item }">
        <v-icon class="mr-2 mt-2" larger color="red" @click="deleteDevice(item)" style="font-weight: bold;">
         mdi-cellphone-lock
        </v-icon>
      </template> -->
      <!-- <template v-slot:[`item.toggle`]="{ item }">
        <div>
          <label class="switch" :key="item">
            <input type="checkbox" v-model="isSwitchOn"  @change="toggleSwitch(item)">
            <span class="slider"></span>
          </label>
        </div>
      </template> -->
      <template v-slot:no-data>
        <v-btn color="error" @click="initialize">NO DATA</v-btn>
      </template>
    </v-data-table>

    <!-- this qr dialog -->
    <v-dialog v-model="dialog1" max-width="300px">
      <v-card style="border-radius:20px">
        <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
          <v-toolbar-title>QR Generate</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex align-center justify-space-between">
          <v-card-text>
            <qr-code :text="messages" :value="messages" id="printme"></qr-code>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="qrclose" style="background:#ff3d41;color:white">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="qrprint">Print</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style scoped>
.short {
  width: 180px;
  height: 25px;
  color: black;
  font-weight: bold;
  justify-content: center;
}

.short2 {
  width: 180px;
  height: 25px;
  color: white;
  font-weight: bold;
  justify-content: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 2px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  /* left:2px; */
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #00A3A3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00A3A3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>