<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
     
  }),

  computed: {

  },

  watch: {
    
  },

  created() {
    // this.initialize();
  },
  mounted() {
console.log(this.$route.query)
    if (this.$route.query.clearall == 'true') {
      Vue.axios.get('https://robopower.xyz/us/cainvoices/clearallinvoices?regnum=' + this.$route.query.regnum)
        .then((res) => {

          if (res.status == 200) {
            var fData = new FormData();
            fData.append('to', this.$route.query.email)
            fData.append('message', this.$route.query.message)
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                console.log(res.status)
                if (res.status == 200) {
                  setTimeout(() => {
                    router.push("dashboard");
                  }, 3000)
                  //             this.$store.dispatch("alert/success", 'Your Last Transaction Success').then(()=>{
                  // setTimeout(()=>{
                  // this.$store.dispatch("alert/clear")
                  // router.push("dashboard");
                  // },2000)
                  //             })
                }
              }).catch((err) => {
                alert(err)
              })

          }
        }).catch((err) => {
          alert(err)
        })
    } else if(this.$route.query.clearall == 'false'){
      if(this.$route.query.invoiceid){
            Vue.axios.get('https://robopower.xyz/us/cainvoices/caclearinvoice?invoiceId=' + this.$route.query.invoiceid)
        .then((res) => {

          if (res.status == 200) {
      var fData = new FormData();
            fData.append('to', this.$route.query.email)
            fData.append('message', this.$route.query.message)
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                console.log(res.status)
                if (res.status == 200) {
                  setTimeout(() => {
                    router.push("dashboard");
                  }, 3000)
                  //             this.$store.dispatch("alert/success", 'Your Last Transaction Success').then(()=>{
                  // setTimeout(()=>{
                  // this.$store.dispatch("alert/clear")
                  // router.push("dashboard");
                  // },2000)
                  //             })
                }
              }).catch((err) => {
                alert(err)
              })
            }
          })
        }else{
          console.log("paying at the time of add invoice")
          var fData = new FormData();
            fData.append('to', this.$route.query.email)
            fData.append('message', this.$route.query.message)
            fData.append('mobile', this.$route.query.mobile)
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendsmsemail', fData)
              .then((res) => {
                console.log(res.status)
                if (res.status == 200) {
                  setTimeout(() => {
                    router.push("dashboard");
                  }, 3000)
                  //             this.$store.dispatch("alert/success", 'Your Last Transaction Success').then(()=>{
                  // setTimeout(()=>{
                  // this.$store.dispatch("alert/clear")
                  // router.push("dashboard");
                  // },2000)
                  //             })
                }
              }).catch((err) => {
                alert(err)
              })
        }
    }
  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
  <v-card
    class="mx-auto"
    max-width="380"
    outlined
    style="margin-top:240px;background-color:white"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <v-img  src="../../src/assets/successicon.png" width="100" height="100"/>
          <b>Success</b>
        </div>
        <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
        <v-list-item-subtitle>Payment ID :<b>{{this.$route.query.paymentid}}</b></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  </v-container>
</template>
