<template>
  <div>
    <stripe-element-payment
    v-if="iscreated"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
    />
    <button @click="pay">Pay Now</button>
  </div>
</template>

<script>
import Vue from 'vue';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import axios from "axios";
Vue.use(axios);
export default {
  components: {
    StripeElementPayment,
  },
  data () {
    return {
      iscreated:false,
      // pk:"pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
       pk: process.env.VUE_APP_STRIPE_KEY,
      elementsOptions: {
        appearance: {},
        // clientsecret:'pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt' // appearance options
      },
      confirmParams: {
        return_url: 'https://alcobrakeadmin.com/dashboard', // success url
      },
    };
  },
  mounted () {
    this.generatePaymentIntent();
  },
  methods: {
    async generatePaymentIntent () {
    //   await Vue.axios
    // .get("https://robopower.xyz/us/payments/getsecretkeynew? dealerid="+this.user.dealerId+"&amount=50")
    // .then(response => {
    //   var that  = this;
    //   console.log(response.data.client_secret)
    //   that.elementsOptions.clientSecret = response.data.client_secret;
    //   this.iscreated =true;
    // })
    },
    pay () {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>