<template>
  <div style="height: auto;">
    <v-dialog v-model="imgdialog" width="600px">
      <v-toolbar text color="#00A3A3" class="" dark dense>
        <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> <span style="font-size:15px;">TIME:{{
          rtime }}</span>&nbsp;&nbsp;<span style="font-size:15px;">BAC:
            {{ bacval }}</span></v-toolbar-title>
        <v-toolbar-title v-else> <span style="font-size:11px;">TIME:{{ rtime }}</span>&nbsp;<span
            style="font-size:11px;">BAC: {{ bacval }}</span></v-toolbar-title>
        <v-layout justify-end>
          <!-- <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"  style="background:black;padding:4px;border-radius:12px">mdi-download</v-icon> -->

          <!-- <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white"  class="mr-4" style="background:black;padding:4px;border-radius:12px">mdi-fullscreen</v-icon> -->

          <v-btn color="black" small @click="imgclose" dark style="border-radius:20px;"><v-icon color="white">
              mdi-close
            </v-icon></v-btn>
        </v-layout>
      </v-toolbar>
      <img id="my-video" v-bind:src="'data:image/jpeg;base64,' + imageBytes" v-if="imageBytes != ''" />
      <v-card-text v-else class=text-center>
        <h2 style="background:black;color:white;padding:5px;border-radius:20px">Image Not Found</h2>
      </v-card-text>
      <v-toolbar text color="#00A3A3" class="" dark dense>
        <v-toolbar-title>
          <span style="font-size:16px;">RESULT: {{ result }}</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-dialog>
    <!-- <v-card
            class="mt-6 mx-auto" style="margin-top:10%;border-radius:20px;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);"> -->
    <!-- <v-sheet
              class="v-sheet--offset mx-auto"
              color="#00A3A3"
              elevation="12"
              max-width="calc(100% - 32px)" style="top: -30px;
            position: relative;"
            > -->
    <canvas v-if="datas.length > 0" ref="barChart" height="30px" width="200px"></canvas>
    <div v-else class="text-center" style="color:  #EA3C53;font-size:x-small;">No Speed Records Found</div>
    <!-- </v-sheet> -->
    <!-- </v-card> -->

  </div>
</template>

<script>
import Chart from 'chart.js';
import Vue from "vue";
import moment from 'moment';

export default {
  name: "alcoholInfo",
  props: ["devid"],
  data() {
    return {
      labels: [],
      datas: [],
      bgcolors: [],
      bacval: null,
      imageBytes: '',
      imgdialog: false,
      rtime: null,
      result: 'NA',
      isSet: true
    };
  },
  watch: {
    labels(val) {
      this.labels = val;
    },
    datas(val) {
      this.datas = val;
    },
    bgcolors(val) {
      this.bgcolors = val;
    },
  },
  beforeMount() {


  },
  mounted() {
    this.renderChart();
    // setTimeout(() => {
      setInterval(() => {
        this.renderChart();
      }, 8000)
    // }, 10000)
  },

  methods: {
    imgclose() {
      this.bacval = null;
      this.imageBytes = '';
      this.imgdialog = false;
      this.rtime = null;
      this.result = 'NA';
    },
    renderChart() {
      var myPromise = new Promise(async (res, rej) => {
        var labs = [];
        var data = [];
        var bgcols = [];
        if (this.isSet) {
          await Vue.axios
            .get(
              "https://robopower.xyz/app/v2data/getallpingsv222?cbid=" + this.devid.Vehicle.cbid
            )
            .then(response => {
              if (response.data != null) {
                var eventsdata = response.data;
                eventsdata.map((res) => {
                  this.labels.push(res.gtime)
                  this.datas.push(parseFloat(res.speed));
                  labs.push(res.gtime)
                  data.push(parseFloat(res.speed));
                  if (parseFloat(res.speed) >= 0 && parseFloat(res.speed) <= 40) {
                    this.bgcolors.push('green');
                    bgcols.push('green');
                  } else if (parseFloat(res.speed) > 40 && parseFloat(res.speed) <= 60) {
                    this.bgcolors.push('dodgerblue');
                    bgcols.push('dodgerblue');
                  } else if (parseFloat(res.speed) > 60 && parseFloat(res.speed) <= 80) {
                    this.bgcolors.push('orange');
                    bgcols.push('orange');
                  } else {
                    this.bgcolors.push('red');
                    bgcols.push('red');
                  }
                })
              }
            })
          res({ "status": "ok", "labelss": labs, "mydata": data, "mycolors": bgcols });
        } else {
          rej("Device Not Found")
        }
      })
      myPromise.then((res) => {
        var ctx = this.$refs.barChart.getContext('2d');
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: res.labelss,
            datasets: [
              {
                barPercentage: 0.6,
                // categoryPercentage: 0.5,
                label: "Vehicle:",
                backgroundColor: res.mycolors,
                // borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                data: res.mydata,
              },
            ],
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (tooltipItem, res) {
                  return false;
                }
              },
              enabled: false,
              xAlign: 'center',
              yAlign: 'center',
              footerMarginTop: 6
            },
            cutoutPercentage: 150,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                // stacked: true,

                gridLines: {
                  display: false
                },
                ticks: {
                  display: false,
                  maxRotation: 45,
                  minRotation: 45,
                }
              },
              ],
              yAxes: [{
                stacked: true,
                display: false,
                gridLines: {
                  display: false
                }
              }]

            },
            responsive: true,
            maintainAspectRatio: false,
            // onClick: this.onOpenimage
          }
        });
      })

    },
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
