<script>
// import getInfo from "../scripts/getInfo";
import {mapGetters} from "vuex";
export default {
  data: () => ({
    user:JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    menu1:false,
    show: false,

    headers: [
      {
        text: "Technician",
        align: "left",
        value: "technician"
      },
        // { text: "ID", value: "installId" },
      { text: "Device ID", value: "devId" },
      { text: "Client", value: "client" },
      // { text: "Status", value: "status" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" }
    ],
    editedIndex: -1,
    editedItem: {
      technician: "",
      installId: "",
      installdate:"",
      devId: "",
      client: "",
      status: ""
    },
    defaultItem: {
        technician: "",
      installId: "",
      installdate:"",
      devId: "",
      client: "",
      status: ""
    },
    valid: true,
    clientRules: [v => !!v || "Select Client"],
    deviceRules: [v => !!v || "Select Device"],
    technicianRules: [v => !!v || "Select Technician"],
    statusRules: [v => !!v || "Select Status"]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Installation" : "Edit Installation";
    },
     buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
...mapGetters(['installations']),
    ...mapGetters(['clients']),
    ...mapGetters(['clientdevices']),
    ...mapGetters(['users']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.mounteddata();
  },

  methods: {
    mounteddata(){
    if(this.user.role=="Admin" || this.user.role == 'superadmin'){
    this.$store.dispatch('fetchInstallations')
    this.$store.dispatch('fetchClients')
    this.$store.dispatch('fetchUsers')
    } else{
      this.$store.dispatch('fetchdealerInstallations',this.user.dealerId)
      this.$store.dispatch('fetchdealerClients',this.user.dealerId)
      this.$store.dispatch('fetchdealerUsers',this.user.dealerId)
    }
    },
    deleteInstallation(installation) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to delete Installation - " + installation.device,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
           this.$store.dispatch('deleteInstallation',installation.installId);
          // this.$swal(
          //   "Deleted",
          //   "You successfully deleted this file",
          //   "success"
          // );
          this.$store.dispatch("alert/success", "You successfully deleted this file").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    getclientDevices(){
      this.$store.dispatch('fetchclientDevices',this.editedItem.client.clientId)
    },
    loadFormData() {
      /* eslint-disable no-console */
      // console.log(this.editedItem.client.clientId)
      const formData = new FormData();
      formData.append("installId", this.editedItem.installId);
      formData.append("technician", this.editedItem.technician);
      formData.append("installdate", this.editedItem.installdate);
      formData.append("device", this.editedItem.devId.devId);
      formData.append("client", this.editedItem.client.clientId);
      formData.append("status", this.editedItem.status);
      return formData;
    },
    initialize() {},

    editItem(item) {
      this.$store.dispatch('fetchclientDevices',item.clientId)     
      this.editedIndex = this.installations.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem.technician=item.technician;
      this.editedItem.installId=item.installId;
      this.editedItem.devId=item;
      this.editedItem.installdate=item.installdate;
      this.editedItem.client=item;
      this.editedItem.status=item.status;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch('createInstallation',fd);
      } else {
        var postdata ={
          idata:fd,
          id:this.editedItem.installId
        }
        this.$store.dispatch('editInstallation',postdata);
      }
      this.close();
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="installations"
        item-key="installId"
        sort-by="technician"
        :search="search"
        class="elevation-1"
         show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#26BB44" class="" dark dense>
              <v-toolbar-title>Installations</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn color="blue" dark v-on="on">Add Installation</v-btn>
                </template>
                <v-card>
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                    autocomplete="off"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                      label="Select Client"
                      required
                      v-on:change="getclientDevices"
                      class="purple-input"
                      v-model="editedItem.client"
                      :rules="clientRules"
                      :items="clients"
                      item-text="client"
                      item-value="client"
                      return-object
                    >
                      <template slot="selection" slot-scope="data">
                        {{data.item.client}}
                      </template>
                    </v-autocomplete>

                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <!-- <v-autocomplete
                              dense
                              required
                              :rules="deviceRules"
                              v-model="editedItem.device"
                              :items="clientdevices"
                              label="Select Device*"
                              item-text="devId"
                            ></v-autocomplete> -->
                            <v-autocomplete
                      label="Select Device*"
                      required
                      class="purple-input"
                      v-model="editedItem.devId"
                      :rules="deviceRules"
                      :items="clientdevices"
                      item-text="devId"
                      item-value="devId"
                      return-object
                    >
                      <template slot="selection" slot-scope="data">
                        {{data.item.devId}}
                      </template>
                    </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              required
                              :rules="technicianRules"
                              v-model="editedItem.technician"
                              :items="users"
                              label="Select Technician*"
                              item-text="userName"
                              item-value="userName"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                          <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                required
                                v-model="editedItem.installdate"
                                label="Installation Date*"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.installdate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              required
                              :rules="statusRules"
                              v-model="editedItem.status"
                              :items= '["Pending", "Installed"]'
                              label="Status*"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!valid"
                      @click="save"
                      >{{buttonTitle}}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-card>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
mdi-delete
</v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteInstallation(item)"
                >
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>

         <template v-slot:expanded-item="{ headers, item}">
          <td :colspan="headers.length" class="ma-0 pa-0">
           <v-list dense class="ma-0 pa-0">
                <v-list-item>
                  <v-list-item-content>Technician:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.technician }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>ID:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.installId }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Device:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.devId }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Client:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.client }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Status:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.status }}</v-list-item-content>
                </v-list-item>
           </v-list>
           </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
