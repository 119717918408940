<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
import moment from 'moment';
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    mydetails:null,
    header:null,
    content:null
     
  }),

  computed: {

  },

  watch: {
    
  },

  created() {
    // this.initialize();
  },
  mounted() {
// console.log(this.$route.query.clearall)
// this.mydetails = JSON.parse(this.$route.query);
    if (this.$route.query.clearall == 'false') {
      this.header = "INVOICE ID"
      this.content = this.mydetails.billnum;
      var pdate =  moment(new Date()).format(
              "MM/DD/YYYY"
            );
        const paymentformData = new FormData();
      paymentformData.append("billnum", this.mydetails.billnum);
      paymentformData.append("client", this.mydetails.client);
      paymentformData.append("qty", this.mydetails.qty);
      paymentformData.append("items", this.mydetails.items);
      paymentformData.append("amounts", this.mydetails.amounts);
      paymentformData.append("total", this.mydetails.grandtotal);
      paymentformData.append("amtpaid", this.mydetails.grandtotal);
      paymentformData.append("balance", '0.00');
      paymentformData.append("status", 'paid');
      paymentformData.append("paymentindent", this.$route.query.paymentid);
      paymentformData.append("paytime", pdate);
        Vue.axios.post('https://robopower.xyz/v2/kvpayments/editbillpayment/'+this.mydetails.billnum,paymentformData).then(() => {
        // this.allData()
        // this.$refs.paymentform.reset()
        this.$store.dispatch("alert/success","PAYMENT SUCCESS").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      })
    } else{
      this.header = "CLIENT NAME"
      this.content = this.$route.query.clientid;
      Vue.axios.get('https://robopower.xyz/v2/kvpayments/clearallpayments?client='+this.$route.query.clientid+'&indent='+this.$route.query.paymentid).then(() => {
        // this.allData()
        // this.$refs.paymentform.reset()
        this.$store.dispatch("alert/success","TOTAL PAYMENT SUCCESS").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      })
    }
  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
  <v-card
    class="mx-auto"
    max-width="380"
    outlined
    style="margin-top:240px;background-color:white"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <v-img  src="../../src/assets/successicon.png" width="100" height="100"/>
          <b>Success</b>
        </div>
        <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
        <v-list-item-subtitle>{{header}} :<b>{{content}}</b></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  </v-container>
</template>
