<template>
    <div>
      <form @submit.prevent="handleSubmit" class="customer-form">
        <div>
          <label for="product">Select Product:</label>
          <select v-model="selectedProduct" @change="handleProductChange" aria-placeholder="Select The Product" class="form-field">
            <option disabled value="">Please select a product</option>
            <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
          </select>
        </div>
        <div v-if="selectedProduct">
          <!-- horizontal v-chip with for loop by showing Prices from pricelist  -->
          <div v-for="(price, index) in pricelist" :key="index" class="chip"
               :class="{ 'selected': price.id === selectedPriceId }"
               @click="selectedPrice(price)">
            {{ price.unit_amount / 100 }} {{ price.currency.toUpperCase() }}
          </div>
        </div>
        <div>
          <label for="name">Name:</label>
          <input type="text" v-model="name" required class="form-field"/>
        </div>
        <div>
          <label for="email">Email:</label>
          <input type="email" v-model="email" required class="form-field"/>
        </div>
        <div>
          <label for="aline1">Address Line 1:</label>
          <input type="text" v-model="aline1" required class="form-field"/>
        </div>
        <div>
          <label for="aline2">Address Line 2:</label>
          <input type="text" v-model="aline2" class="form-field"/>
        </div>
        <div>
          <label for="city">City:</label>
          <input type="text" v-model="city" required class="form-field"/>
        </div>
        <div>
          <label for="state">State:</label>
          <input type="text" v-model="state" required class="form-field"/>
        </div>
        <div>
          <label for="zipcode">Postal Code:</label>
          <input type="text" v-model="zipcode" required class="form-field"/>
        </div>
        <div>
          <label for="country">Country:</label>
          <input type="text" v-model="country" required class="form-field"/>
        </div>
        <div>
          <label for="card">Credit or Debit Card:</label>
          <div id="card-element" class="form-field"></div>
          <!-- <stripe-element-card ref="creditcard"  :pk="pk" class="form-field"/> -->
        </div>
        <button type="submit">Create Customer</button>
      </form>
      <div v-if="subscription">
        <h3>Subscription Created:</h3>
        <p>ID: {{ subscription.id }}</p>
      </div>
    </div>
  </template>
  
  <script src="https://js.stripe.com/v3"></script>
  <!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/1.3.8/FileSaver.js"></script> -->
  <script>
  import { StripeElementCard } from '@vue-stripe/vue-stripe';
  import Vue from "vue";
  import { loadStripe } from '@stripe/stripe-js';
  
  export default {
    components: {
      StripeElementCard,
    },
    props:["selectedclient"],
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        iscreated: false,
        name: '',
        email: '',
        mobile:'',
        aline1: '',
        aline2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        subscription: null,
        stripe: null,
        card: null,
        pk: process.env.VUE_APP_STRIPE_KEY,
        products:[],
        selectedProduct: null,
        pricelist:[],
        selectedPriceId: null
      };
    },
    async mounted() {
      console.log(this.selectedclient)
      var client = this.selectedclient;
      this.name = client.client;
      this.email = client.email;
      this.mobile = client.clientphone1;
      this.aline1 = client.clientAddress;
      this.aline2 ="";
      this.city = client.city;
      this.state = client.state;
      this.zipcode = client.zipcode;
      this.country = "USA";
      const headers = {
        Authorization:process.env.VUE_APP_STRIPE_TOKEN,
      };
  
      Vue.axios.get("https://api.stripe.com/v1/products",{headers})
        .then(async (res)=>{
          console.log(res.data.data)
          this.products = res.data.data;
          this.stripe = await loadStripe(this.pk);
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount('#card-element');
        });
  
      this.iscreated = true;
    },
    watch:{
      selectedclient(client){
        this.name = client.client;
      this.email = client.email;
      this.mobile = client.clientphone1;
      this.aline1 = client.clientAddress;
      this.aline2 ="";
      this.city = client.city;
      this.state = client.state;
      this.zipcode = client.zipcode;
      this.country = "USA";
      }
    
    },
    methods: {
      resetForm() {
        this.name ='';
        this.email ='';
        this.mobile='';
        this.aline1= '';
        this.aline2= '';
        this.city= '';
        this.state= '';
        this.zipcode= '';
        this.country= '';
    },
      selectedPrice(price) {
        // console.log("Selected price:", price);
        this.selectedPriceId = price.id; // Set selectedPriceId to the selected price's ID
      },
      handleProductChange() {
        // console.log('Selected Product:', this.selectedProduct);
        const headers = {
        Authorization: process.env.VUE_APP_STRIPE_TOKEN
        };
        Vue.axios.get(`https://api.stripe.com/v1/prices?product=${this.selectedProduct}`, { headers })
          .then(response => {
            var priceList = response.data.data;
            this.pricelist = priceList;
            
            // Select the default price (first one in the list) if pricelist is not empty
            if (priceList.length > 0) {
              this.selectedPrice(priceList[0]);
            }
          })
          .catch(error => {
            console.error('Error fetching prices:', error);
          });
      },
      async handleSubmit() {
        // var that = this;
        try {
            const { token, error } = await this.stripe.createToken(this.card);
      if (error) {
        console.error('Error creating token:', error);
        return;
      }
      console.log('Received Stripe token:', token);
            
            
          // Extracting the mobile number without the leading '+' sign
        //   var mobilenum = this.mobile.e164Number.substring(1);
  
        //   const customerData = {
        //     name: this.name,
        //     email: this.email,
        //     aline1: this.aline1,
        //     aline2: this.aline2,
        //     city: this.city,
        //     state: this.state,
        //     zipcode: this.zipcode,
        //     country: this.country,
        //     cardtoken: token.id
        //   };
        //   console.log(customerData);
  
          
        var mobilenum = this.mobile;
          var fd = new FormData();
      fd.append('name', this.name);
      fd.append("email", this.email);
      fd.append('phone', mobilenum);
      fd.append("aline1", this.aline1);
      fd.append('aline2', "");
      fd.append("city", this.city);
      fd.append('state', this.state);
      fd.append("zipcode", this.zipcode);
      fd.append("country", this.country);
      fd.append("cardtoken",token.id);
      fd.append("dealer_id",this.user.dealerId);
      Vue.axios
        .post(
          "https://robopower.xyz/us/payments/createcustomerandsubscribe",fd)
        .then((resp) => {
          // console.log("cust");
          // console.log(resp.data);
          // console.log("cust");
          var renewdetails = resp.data;
          const formData = new FormData();
          formData.append('autorenewal',"1")
          formData.append("autorenewal_id",renewdetails.id);
          Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editautorenewal/"+this.selectedclient.id, formData)
        .then((response) => {
          this.resetForm();
                 setTimeout(()=>{
                  this.$emit('clearRenewal');
    },1000)
        });
        })
        } catch (error) {
          console.error('Error creating customer:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  div {
    margin-bottom: 10px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc; /* Border style for form fields */
    border-radius: 5px;
    margin-bottom: 10px;
  }
  select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc; /* Border style for form fields */
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .chip {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #ccc; /* Default color for chips */
    color: white;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .chip.selected {
    background-color: #28a745; /* Color for selected chip */
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  button:hover {
    background-color: #218838;
  }
  .customer-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .form-field {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc; /* Border style for form fields */
    border-radius: 5px;
    margin-bottom: 10px;
  }
  </style>
  