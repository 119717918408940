<template>
  <v-container id="firmwares" fluid tag="section">
    <v-dialog v-model="uploadgifdialog" max-width="430px">
      <v-card style="background-color:#26BB44;color: azure;">
     <v-card-title>Please Wait Uploading Firmware..&nbsp;&nbsp;{{count}} %</v-card-title>
     </v-card>
    </v-dialog>
    <v-dialog v-model="firmwaredialog" max-width="600px">
                 <v-card class="elevation-12">
          <v-toolbar text color="#26BB44" class="" dark dense>
              <v-row>
                    <v-toolbar-title style="margin-left:10px;color:black">Please Confirm Your Firmware Deletion&nbsp;--&nbsp;{{this.alldata.version}}</v-toolbar-title>
                    <v-layout justify-end>
                      <v-icon color="primary" size="40px" @click="clearclose"> mdi-close </v-icon>
                </v-layout>
              </v-row>
                  </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field 
                v-model="dealeremail"
                label="Email"
                name="dealeremail"
                prepend-icon="mdi-account"
                type="text"
                ></v-text-field>

                <v-text-field
                  v-model="dealerpassword"
                  prepend-icon="mdi-lock"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="dealerpassword"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-layout justify-end>
              <v-btn color="#666600" @click="login" style="color:white">Delete</v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
              </v-dialog>
    <v-dialog v-model="dialog" max-width="750px" @click:outside="close">
        <v-card class="pa-3" style="border-radius:20px">
        <v-toolbar color="#00A3A3" dark flat style="border-radius:20px">
      <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">{{Device}}</v-toolbar-title>
      <v-toolbar-title v-else style="font-size:17px">{{Device}}</v-toolbar-title>
    </v-toolbar>
            <v-form
              autocomplete="off"
              id="form"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-container class="py-0">
                <v-row justify="center">
                  <v-col cols="12" md="4">
                    <v-file-input outlined
                      show-size
                      v-model="files"

                      
                      label="Upload File"
                      required
                      :rules="fileRules"
                      @change="selectFile"
                    ></v-file-input>
                    <progress 
                      max="100"
                       :value.prop="uploadPercentage"
                    ></progress>
                    <!-- <v-progress-linear
      v-model="knowledge"
      height="25"
    >
      <strong>{{ Math.ceil(knowledge) }}%</strong>
    </v-progress-linear> -->
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field outlined
                      class="purple-input"
                      label="Firmware Version"
                      required
                      :rules="versionRules"
                      type="number"
                      @keypress="IsNumber($event)"
                      v-model="version"
                    /> </v-col
                  ><v-col cols="12" md="4">
                    <v-text-field outlined
                      class="purple-input"
                      label="Comment"
                      counter
                      maxlength="100"
                      hint="This field uses maxlength 100"
                      required
                      :rules="commentRules"
                      v-model="comment"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn class="mr-0" @click="close" style="background:#ff3d41;color:white">
                     Cancel
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="success"
                      class="mr-0"
                      :disabled="!valid"
                      @click="updateFirmware"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
        </v-card>
    </v-dialog>
    
            <v-toolbar flat color="#00A3A3" dark dense class="hidden-sm-and-down" style="border-radius:20px">
              <v-toolbar-title>FIRMWARES</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn class="black" @click="addFirmware()" style="border-radius:20px">{{
                selectDevice
              }}</v-btn>
              <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title centered background-color="transparent" color="white" >
                  <v-tabs-slider color="black"></v-tabs-slider>

                  <v-tab style="font-weight:bold">
                    Control Board
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    Control Board V2
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    SAMPLER
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    TFT
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Pressure OLED
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    CA_TFT
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    S3_SAMPLER
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    TEST ZIG
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    INDIA-CONTROLBOARD
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    INDIA-SAMPLER
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Device manager
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Neoway N-58
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>

            <v-toolbar flat color="#00A3A3" dark dense class="hidden-md-and-up" style="border-radius:20px">
               <v-toolbar-title style="width:200px;font-size:12px">FIRMWARES</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
                  <v-row>
                  <v-layout justify-end>
                    <v-btn  @click="addFirmware()" style="border-radius:20px">
                     <v-icon >
                    mdi-plus
                  </v-icon>
                    </v-btn>
                  
                  </v-layout>
                  </v-row>
                  
              <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title  centered background-color="transparent" color="black" style="">
                  <v-tabs-slider color="black" style="margin-Right:200px;"></v-tabs-slider>
                  <v-tab style="font-weight:bold">
                    Control Board
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    Control Board V2
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Sampler
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    TFT
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Pressure OLED
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    CA_TFT
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    S3_SAMPLER
                  </v-tab>
                    <v-tab style="font-weight:bold">
                    Test Zig
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    INDIA-CONTROLBOARD
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    INDIA-SAMPLER
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Device manager
                  </v-tab>
                  <v-tab style="font-weight:bold">
                    Neoway N-58
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>
          <v-tabs-items v-model="tab" class="mt-6">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="cbfirmwares"
                    class="elevation-1"
                  >
                    <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin' || user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletecbfirmware(item,'https://robopower.xyz/v2/cbfw/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

              <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="cbv2firmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin' || user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletecb2firmware(item,'https://robopower.xyz/v2/cbfwv2/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="firmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletefirmware(item,'https://robopower.xyz/v2/fw/deleteoledfw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="tftfirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletetftfirmware(item,'https://robopower.xyz/v2/fw/deletetftfw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="pressfirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletepressfirmware(item,'https://robopower.xyz/v2/fw/deletempxvfw?id=')"
        >
         mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
<!-- CATFT -->
                        <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="catftfirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletecatftfirmware(item,'https://robopower.xyz/v2/catftfw/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

             <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="s3firmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletes3firmware(item,'https://robopower.xyz/v2/s3fw/deletes3fw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

             <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="cazigfirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletecazigfirmware(item,'https://robopower.xyz/v2/cazigfw/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

             <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="cbindiafirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletecbindiafirmware(item,'https://robopower.xyz/v1/cbota/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="sampindiafirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deletesampindiafirmware(item,'https://robopower.xyz/v1/sampota/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="guifirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deleteguifirmware(item,'https://robopower.xyz/guifw/deleteguifw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Gui Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- neoway n58 -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="neowayfirmwares"
                    class="elevation-1"
                  >
                  <template slot=item.delete slot-scope="{ item }">
          <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
        v-if="user.role == 'Admin'|| user.role == 'superadmin'"
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
          @click="deleteneowayfirmware(item,'https://robopower.xyz/v3/neowayfw/deletefw?id=')"
        >
          mdi-delete-circle
        </v-icon>
      </template>
      <span>Delete Gui Firmware</span>
    </v-tooltip>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
 
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "Firmwares",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      tab: null,
      dialog: false,
      uploadgifdialog: false,
      file: "",
      count:0,
      currentfile: "",
      dealeremail: '',
      deletefirmwareurl: '',
      dealerpassword: '',
      firmwaredialog: false,
      alldata: "",
      show2: false,
      version: "",
      comment: "",
      valid: true,
      files:[],
      search: "",
      percent: 90,
      uploadPercentage:0,
      headers: [
        { text: "FileName", value: "fileName" },
        { text: "Time", value: "timestamp" },
        { text: "Version", value: "version" },
        { text: "Comment", value: "comment" },
        { text: "Delete", value: "delete" },
        // { text: 'VIEW MORE', value: 'view' },
      ],
      versionRules: [v => !!v || "Select Version"],
      fileRules: [v => !!v || "Select File"],
      commentRules: [v => !!v || "Select Comment"]
    };
  },
  computed: {
    ...mapGetters(["firmwares"]),
    ...mapGetters(["cbfirmwares"]),
    ...mapGetters(["cbv2firmwares"]),
    ...mapGetters(["tftfirmwares"]),
    ...mapGetters(["pressfirmwares"]),
    ...mapGetters(["cazigfirmwares"]),
    ...mapGetters(["guifirmwares"]),
    ...mapGetters(["catftfirmwares"]),
    ...mapGetters(["s3firmwares"]),
    ...mapGetters(["cbindiafirmwares"]),
    ...mapGetters(["sampindiafirmwares"]),
    ...mapGetters(["neowayfirmwares"]),
    selectDevice() {
      return this.tab === 0
        ? "Add Control Board"
        : this.tab === 1
        ? "Add Control Board v2"
        : this.tab === 2
        ? "ADD Sampler"
        : this.tab === 3
        ? "ADD TFT FIRMWARE"
        : this.tab === 4
        ? "ADD POLED FIRMWARE"
        : this.tab === 5
        ? "ADD CA-TFT FIRMWARE"
        : this.tab === 6
        ? "ADD S3-SAMPLER FIRMWARE"
       : this.tab === 7
        ? "ADD TEST GIZ FIRMWARE"
        : this.tab === 8
        ? "ADD INDIA-CB FIRMWARE"
        : this.tab === 9
        ? "ADD INDIA-SAMP FIRMWARE"
        : this.tab ==10
        ? "ADD DEVICE MANAGER": "ADD NEOWAY N58"
        
    },
     Device() {
      return this.tab === 0
         ? "Add Control Board Firmware"
        : this.tab === 1
        ? "Add Control Board v2 Firmware"
        : this.tab === 2
        ? "ADD Sampler Firmware"
        : this.tab === 3
        ? "ADD TFT Firmware"
        : this.tab === 4
         ? "ADD POLED FIRMWARE"
        : this.tab === 5
        ? "ADD CATFT FIRMWARE"
        : this.tab === 6
        ? "ADD S3-SAMPLER FIRMWARE"
        : this.tab === 7
        ? "ADD TEST GIZ FIRMWARE"
       : this.tab === 8
        ? "ADD INDIA-CB FIRMWARE"
        : this.tab === 9
        ? "ADD INDIA-SAMP FIRMWARE"
        : this.tab ==10
        ? "ADD DEVICE MANAGER": "ADD NEOWAY N58"
         
        
    }
  },
  mounted() {
    this.$store.dispatch("fetchFirmwares"); // sampler firmware
    this.$store.dispatch("fetchCbfirmwares"); // control board firmware
    this.$store.dispatch("fetchCbv2firmwares"); // control board v2 firmware
    this.$store.dispatch("fetchTftfirmwares"); // tft display firmware
    this.$store.dispatch("fetchPressfirmwares"); // press oled firmware
    this.$store.dispatch("fetchGuifirmwares"); // gui firmware
    this.$store.dispatch("fetchCazigfirmwares"); // testzig firmware
    this.$store.dispatch("fetchCatftfirmwares"); // catft firmware
    this.$store.dispatch("fetchS3firmwares"); // catft firmware
    this.$store.dispatch("fetchcbindiafirmwares"); // cbindia firmware
    this.$store.dispatch("fetchsampindiafirmwares"); // sampindia firmware
    this.$store.dispatch("fetchneowayn58firmwares"); // neowayn58 firmware
  },
  watch: {
    uploadPercentage:function(val){
      if (val == 100 || val == undefined || val == 0) {
        // console.log(val)
        this.uploadgifdialog=false;
      } else{
        this.uploadgifdialog = true;
        this.count = val;
      }
    },
    currentfile: function(val) {
      if (val == null) {
        // console.log(this.uploadPercentage)
        // this.uploadPercentage = val;
        this.currentfile = val;
      }
    }
  },
  methods: {
     clearclose() {
      this.firmwaredialog = false;
    },
     login() {
      if (this.dealeremail && this.dealerpassword) {
        var usermail = this.dealeremail;
        var userpassword = this.dealerpassword;
        Vue.axios
          .get(
            "https://robopower.xyz/us/calogin/getuniquelogin?emailId=" +
              usermail +
              "&password=" +
              userpassword
          )
          .then((response) => {
            // console.log(response.data)
            var logindata = response.data[0];
            if (
              this.dealeremail == logindata.emailId &&
              this.dealerpassword == logindata.password
            ) {
          Vue.axios(this.deletefirmwareurl + this.alldata.id + "&version=" + this.alldata.version).then((res) => {
                  this.$store.dispatch("fetchCbv2firmwares");
                  this.$store.dispatch("fetchTftfirmwares");
                  this.$store.dispatch("fetchCbfirmwares");
                  this.$store.dispatch("fetchFirmwares");
                  this.$store.dispatch("fetchPressfirmwares");
                  this.$store.dispatch("fetchGuifirmwares");
            this.$store.dispatch("fetchCazigfirmwares");
            this.$store.dispatch("fetchCatftfirmwares");
            this.$store.dispatch("fetchcbindiafirmwares");
            this.$store.dispatch("fetchsampindiafirmwares");
            this.$store.dispatch("fetchneowayn58firmwares"); 
                  this.$store.dispatch("alert/success",res.data).then(()=>{
                  setTimeout(() => {
                    this.$store.dispatch("alert/success",res.data);
                  }, 1000)
                  })
      })
        this.firmwaredialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Deleted',
          icon: 'success',
          showCloseButton: true,
        });
            } else {
              console.log("error");
            }
          }).catch((err)=>{
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(()=>{
                  this.$store.dispatch("alert/error", err)
                })
              })
            });
      }
    },
   deletecbfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete Control Board " +" "+ item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
     deletecb2firmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete Control Board V2" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
       
    },
     deletefirmware(item,url) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete Sampler" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
            this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
   deletetftfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete TFT" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
     deletepressfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete Pressure" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
    deletecazigfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete ZIG" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
     deletecatftfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete CATFT" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
    deletes3firmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete S3" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
    deleteneowayfirmware(item,url) {
       const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete S3" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
     deleteguifirmware(item,url) {
        const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to Delete GUI App" +" "+  item.version + " Firmware version",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
           this.alldata = item;
          this.deletefirmwareurl = url;
          this.firmwaredialog = true;
        }
      });
    },
    selectFile(file) {
      if (file != undefined || file != "" || file != null) {
        this.currentfile = file;
      }
    },
    addFirmware() {
      this.dialog = true;
    },
  IsNumber(event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
    },
    updateFirmware() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const formData = new FormData();
      formData.append("file", this.currentfile);
      formData.append("version", this.version);
      formData.append("comment", this.comment);
      this.uploadgifdialog = true
      if (this.tab == 0) {
         Vue.axios
      .post("https://robopower.xyz/v2/cbfw/cbfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set CB Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchCbfirmwares");
        this.uploadgifdialog = false
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
         this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createCbfirmware", formData)
      } else if (this.tab == 1) {
         Vue.axios
      .post("https://robopower.xyz/v2/cbfwv2/cbfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
        })
      .then(() => {   
        this.$store.dispatch("alert/success", "Set CB Firmware v2 Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchCbv2firmwares");
        this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      }).catch(error => {
        this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      });
        // this.$store.dispatch("createCbv2firmware", formData)
      } else if (this.tab == 2) {
        Vue.axios
      .post("https://robopower.xyz/v2/fw/oledfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set Sampler Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchFirmwares");
        this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
         this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createFirmware", formData);
        
      } else if (this.tab == 3) {
        this.$store.dispatch("createTftfirmware", formData);
      }else if (this.tab == 4) {
        Vue.axios
      .post("https://robopower.xyz/v2/fw/oled_mpxv_pressfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set TFT Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchPressfirmwares");
         this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    });
         this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createPressfirmware", formData);
      }else if (this.tab == 5) {
        Vue.axios
      .post("https://robopower.xyz/v2/catftfw/catftfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set CATFT Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchCatftfirmwares");
         this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    });
         this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createPressfirmware", formData);
      }
      else if (this.tab == 6) {
        Vue.axios
      .post("https://robopower.xyz/v2/s3fw/fwUploads3", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set S3-Sampler Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchS3firmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
         this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
           this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createCazigfirmware", formData);
      }

      else if (this.tab == 7) {
        Vue.axios
      .post("https://robopower.xyz/v2/cazigfw/cazigfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set Cazig Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchCazigfirmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
         this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
           this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createCazigfirmware", formData);
      }else if (this.tab == 8) {
        Vue.axios
      .post("https://robopower.xyz/v1/cbota/cbfwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set ControlBoard Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchcbindiafirmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
         this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
           this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
      }else if (this.tab == 9) {
        Vue.axios
      .post("https://robopower.xyz/v1/sampota/fwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "Set Sampler Firmware Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchsampindiafirmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
         this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
           this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
      }
      else if (this.tab == 10)  {
    Vue.axios
      .post("https://robopower.xyz/v2/guifw/fwUpload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(res => {
        // this.$store.dispatch("alert/clear");
        // this.$store.dispatch("alert/loading", false);
        this.$store.dispatch("alert/success", "Set GUI Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchGuifirmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
        // this.$store.dispatch("createGuifirmware", formData);
      } else{
        //add neowayn58 firmware
        Vue.axios
      .post("https://robopower.xyz/v3/neowayfw/fwUploadneo", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(res => {
        // this.$store.dispatch("alert/clear");
        // this.$store.dispatch("alert/loading", false);
        this.$store.dispatch("alert/success", "Set NEOWAY N58 Success").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        this.$store.dispatch("fetchneowayn58firmwares");
          this.uploadgifdialog = false;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Updated',
          icon: 'success',
          showCloseButton: true,
        });
        this.close();
        this.uploadPercentage = 0;
      })
      .catch(error => {
        this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            this.currentFile = null;
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'Firmware Not Updated',
          icon: 'error',
          showCloseButton: true,
        });
      });
      }
      this.dialog = false;
      this.$refs.form.reset();
    },
    close() {
      this.$refs.form.reset();
      this.count = 0;
      this.dialog = false;
    }
  }
};
</script>