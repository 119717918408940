<script>
//import getInfo from "../scripts/getInfo";
import { mapGetters } from 'vuex'
import Vue from 'vue';
import moment from 'moment';
export default {
  data() {
    return {
      description: '',
      devicemodel: '',
      quantity: '',
      Dshiptype: '',
      selecteddealer: [],
      // select: ['Vuetify', 'Programming'],
      Dshipmode: ['subscription', 'owned'],
      qytlist: ['1', '2', '3', '4', '5', '6'],
      modellist: [],
      user: JSON.parse(localStorage.getItem("user")),
      dialog: false,
      dialog2: false,
      dialog3: false,
      dshipdlg: false,
      nodshipdlg: false,
      search2: null,
      dealership: true,
      models: null,
      selected: [],
      states: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "INDIA"
      ],
    
      paymentgateway: [
      "Stripe",
      "PayPal",
    ],
  
  
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      expanded: [],
      show: false,
      password: "Password",
      access: [{ text: "Admin" }, { text: "dealer" }],
      headers: [
        // { text: "ID", value: "dealerId" },
        { text: "Dealership", value: "Dship", sortable: false },
        { text: "Name", align: "left", value: "dealerName" },
        { text: "Email", value: "dealerEmail" },
        { text: "Phone", value: "dealerMobile1" },
        // { text: "Phone 2", value: "dealerMobile2" },
        // { text: "Access Level", value: "accesslevel" },
        { text: "Actions", value: "action", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
        { text: "Add Office", value: "addmore", sortable: false },

        { text: "More..", value: "data-table-expand" }

      ],
      items: [],
      editedIndex: -1,
      countrycode: 'us',
      editedItem: {
        dealerName: "",
        dealerId: "",
        dealerEmail: "",
        dealerMobile1: "",
        dealerMobile2: "",
        officehome: "",
        officework: "",
        dealerhome: "",
        dealerwork: "",
        addofficework: "",
        addofficehome: "",
        accesslevel: "dealer",
        dealerPassword: "",
        dealerAddress:'', //newly added
        dealerCity:"",//newly added
        dealerZipcode:"",//newly added
        dealerLat:"",//newly added
        dealerLon:"",//newly added
        is_active: true,
        officeName: "",
        officeId: "",
        officeMobile1: "",
        officeMobile2: "",
        officeemail: "",
        officepassword: "",
        officeaddress: "",
        city: "",
        state: "",
        zipcode: "",
        isbar: "1",
        officelicense: "",
      },
      defaultItem: {
        dealerName: "",
        dealerId: "",
        dealerEmail: "",
        dealerMobile1: "",
        dealerMobile2: "",
        officehome: "",
        officework: "",
        dealerhome: "",
        dealerwork: "",
        addofficework: "",
        addofficehome: "",
        accesslevel: "dealer",
        dealerPassword: "",
        is_active: true,
        officeName: "",
        officeId: "",
        officeMobile1: "",
        officeMobile2: "",
        officeemail: "",
        officepassword: "",
        officeaddress: "",
        city: "",
        state: "",
        zipcode: "",
        isbar: "1",
        officelicense: "",
      },
      valid: true,
      cityRules: [
        v => !!v || 'City is required',
        v => v && v.length >= 3 || 'City must contain 3 or more characters',
      ],
      stateRules: [
        v => !!v || 'Select State',
      ],
      zipRules: [
        v => !!v || 'Zip is required',
        v => v && v.length == 5 || 'Enter valid zip code',
      ],
      dealernameRules: [
        v => !!v || 'Name is required',
        v => v && v.length >= 3 || 'Name must contain 3 or more characters',
        v => v && v.length <= 50 || 'Name must be less than 50 characters',
        v =>
          !this.dealers.find(device => device.dealerName == v) ||
          this.editedIndex != -1 ||
          "Dealer with this name already exists use different one"
      ],
      officenameRules: [
        v => !!v || 'Name is required',
        v => v && v.length >= 3 || 'Name must contain 3 or more characters',
        v => v && v.length <= 50 || 'Name must be less than 50 characters',
        v =>
          !this.offices.find(device => device.officeName == v) ||
          this.editedIndex != -1 ||
          "Office with this name already exists use different one",
        v =>
          !this.offices.find(device => device.officeName == v) ||
          this.editedIndex != -11 ||
          "Office with this name already exists use different one"
      ],
      idRules: [
        v => !!v || 'Id is required',
      ],
      //  phoneRules: [
      //     v => !!v || "Phone is Required",
      //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
      //     v => v != 0 || "Phone Number can't be zeoro!"
      //   ],
      // phone2Rules: [
      //     v => !!v || "Phone is Required",
      //     v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
      //     v => v != 0 || "Phone Number can't be zeoro!"
      //   ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v && v.length >= 8 || 'Password must contain 8 or more charactors'
      ],
      addressRules: [
        v => !!v || 'Address is required',
      ],
      licenseRules: [
        v => !!v || 'Bar License is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v =>
          (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
          "E-mail must be valid"
      ],
      dealerRules: [
        v => !!v || 'Select Dealer',
      ],
      accessRules: [
        v => !!v || 'Select access level',
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
      ],
      phone2Rules: [
        v => !!v || 'Phone2 is required',
        v => v && v.length > 9 && v.length < 11 || 'Enter valid phone number',
      ],
      phone2Rulese: [
        v => !!v || 'Phone2 is required',
        v => v && v.length > 9 && v.length < 15 || 'Enter valid phone number',
      ],
      phoneRulese: [
        v => !!v || 'Phone is required',
        v => v && v.length > 9 && v.length < 15 || 'Enter valid phone number',
      ],
    }
  },
  

  computed: {
    Slist() {
      if (this.expanded.length > 0) {
        return this.expanded
      }
      else {
        return this.dealers
      }
    },
    resultQuery() {
      if (this.expanded.length > 0) {
        var mylist = this.expanded[0].list;
        if (this.search2) {
          return mylist.filter((item) => {
            return item.client.includes(this.search2);
          });
        } else {
          return mylist;
        }
      } else {
        return []
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Dealer" : "Edit Dealer";
    },
    formTitle2() {
      return this.editedIndex === -10 ? "Edit Office" : "Add Office";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    buttonTitle2() {
      return this.editedIndex === -10 ? "UPDATE" : "SAVE";
    },
    ...mapGetters(['dealers']),
    ...mapGetters(['offices'])
  },

  watch: {
    countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = 'us'
      }, 500)
    },
    expanded(val) {
      if (val.length == 0) {
        this.models = null;
      } else {
        this.models = val
        this.selected = false
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close2();
    },
    dialog3(val) {
      val || this.close3();
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$store.dispatch('fetchDealers');
    if (this.user.role == "Admin" || this.user.role == "superadmin") {
      this.$store.dispatch('fetchOffices')
    } else {
      this.$store.dispatch('fetchdealerOffices', this.user.dealerId)
    }
  },

  methods: {
    handleclick() {
      this.selected = true;
    },
    Dealershipsave() {
      var clockTime = moment(new Date()).format("MM/DD/YYYY")
      // console.log(typeof(this.selecteddealer.dealerId),typeof(this.description),typeof(this.quantity),typeof(this.devicemodel),typeof(clockTime))
      this.formData = new FormData();
      this.formData.append('dealerid', this.selecteddealer.dealerId)
      this.formData.append('description', this.description)
      this.formData.append('qty', this.quantity)
      this.formData.append('devicemodel', this.devicemodel)
      this.formData.append('type', this.Dshiptype)
      this.formData.append('paymentstatus', 'unpaid')
      this.formData.append('created_at', clockTime)
      this.formData.append('updated_at', '')
      // console.log(JSON.stringify(this.formData))
      // Example Axios POST request
      Vue.axios.post('https://robopower.xyz/us/cadealers/adddealership', this.formData)
        .then(response => {
          console.log('Data sent successfully', response);
          this.nodshipdlg = false
          // Optionally, reset form fields
          this.$store.dispatch('fetchDealers');
          this.description = ''
          this.quantity = ''
          this.devicemodel = ''
          this.Dshiptype = ''
        })
        .catch(error => {
          console.error('Error sending data', error);
        });
    },
    dshipclose() {
      this.dshipdlg = false;
      this.items = []
    },
    addmoredealerShip(item) {
      this.selecteddealer = item;
        this.dshipdlg = false;
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Dear ' + item.dealerName + ', Are you sure?',
          text: ' You want to Get More Dealership?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            // console.log(result.value)
            this.nodshipdlg = true;
            Vue.axios.get('https://robopower.xyz/us/cadevices/getdevicemodels').then((resp) => {
              console.log(resp.data)
              this.modellist = resp.data.map(item => item);
            })
          } else {
            console.log("Nodata of dealership")
          }
        })

    },
    adddealerShip(item) {
      this.selecteddealer = item;
      if (item.Hasdealership) {
        this.dshipdlg = true;
        // Make an Axios request to fetch the data
        Vue.axios.get('https://robopower.xyz/us/cadealers/getuniquedealership?dealerId=' + item.dealerId)
          .then(response => {
            // Once the data is fetched successfully, assign it to the items array
            this.items = response.data;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } else {
        // console.log("else")
        this.dshipdlg = false;
        const Swal = require("sweetalert2");
        Swal.fire({
          title: 'Dear ' + item.dealerName + ', Are you sure?',
          text: ' You want to Take Dealership?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            // console.log(result.value)
            this.nodshipdlg = true;
            Vue.axios.get('https://robopower.xyz/us/cadevices/getdevicemodels').then((resp) => {
              // console.log(resp.data)
              this.modellist = resp.data.map(item => item);
            })
          } else {
            console.log("Nodata of dealership")
          }
        })
      }

    },
    officeonSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.officehome = dialCode;
    },
    officeonSelect2({ dialCode }) {
      this.editedItem.officework = dialCode;
    },
    dealeronSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.dealerhome = dialCode;
    },
    dealeronSelect2({ dialCode }) {
      this.editedItem.dealerwork = dialCode;
    },
    addofficeonSelect({ dialCode }) {
      // console.log(dialCode)
      this.editedItem.addofficehome = dialCode;
    },
    addofficeonSelect2({ dialCode }) {
      this.editedItem.addofficework = dialCode;
    },
    Addeditmore(item) {
      this.editedItem.dealerName = item.dealerId;
      this.editedIndex = -11;

      this.dialog2 = true;
    },
    editItem2(it) {
      this.editedIndex = -10;
      // this.editedItem = Object.assign({}, JSON.parse(it));
      var std = JSON.parse(it);
      this.editedItem.officeName = std.officeName;
      this.editedItem.officeId = std.officeId;
      this.editedItem.officeMobile1 = std.officeMobile1;
      this.editedItem.officeMobile2 = std.officeMobile2;
      this.editedItem.officeemail = std.officeemail;
      this.editedItem.officepassword = std.officepassword;
      this.editedItem.officeaddress = std.officeaddress;
      this.editedItem.city = std.city;
      this.editedItem.state = std.state;
      this.editedItem.zipcode = std.zipcode;
      this.editedItem.isbar = std.isbar;
//payments
      this.editedItem.payment = std.patmentgateway;
      
      this.editedItem.officelicense = std.officelicense;
      this.editedItem.dealerName = std.dealerId;
      this.dialog2 = true;

    },
    isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    deleteDealer(dealer) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: 'Are you sure? You want to delete Dealer - ' + dealer.dealerName,
        text: 'You can\'t revert your action and All offices related to this will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes Delete it!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteDealer', dealer.dealerId)
          this.$store.dispatch('deletedealerOffices', dealer.dealerId)
          // this.$swal('Deleted', 'You successfully deleted this file', 'success')
          this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          // this.$swal('Cancelled', 'Your file is still intact', 'info')
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("dealerName", this.editedItem.dealerName);
      formData.append("dealerId", this.editedItem.dealerId);
      formData.append("dealerEmail", this.editedItem.dealerEmail);
      formData.append("dealerMobile1", this.editedItem.dealerhome + this.editedItem.dealerMobile1);
      formData.append("dealerMobile2", this.editedItem.dealerwork + this.editedItem.dealerMobile2);
      formData.append("accesslevel", this.editedItem.accesslevel);
      formData.append("dealerPassword", this.editedItem.dealerPassword);
      formData.append("dealerAddress", this.editedItem.dealerAddress);
      formData.append("dealerCity", this.editedItem.dealerCity);
      formData.append("dealerZipcode", this.editedItem.dealerZipcode);
      formData.append("dealerLat", this.editedItem.dealerLat);
      formData.append("dealerLon", this.editedItem.dealerLon);

      formData.append("officeName", this.editedItem.officeName);
      formData.append("officeMobile1", this.editedItem.officehome + this.editedItem.officeMobile1);
      formData.append("officeMobile2", this.editedItem.officework + this.editedItem.officeMobile2);
      formData.append("officeemail", this.editedItem.officeemail);
      formData.append("officepassword", this.editedItem.officepassword);
      formData.append("officeaddress", this.editedItem.officeaddress);
      formData.append("city", this.editedItem.city);
      formData.append("state", this.editedItem.state);
      formData.append("zipcode", this.editedItem.zipcode);
      formData.append("isbar", this.editedItem.isbar);
      formData.append("officelicense", this.editedItem.officelicense);
      return formData;
    },
    loaddealerFormData() {
      const formData = new FormData();
      formData.append("dealerName", this.editedItem.dealerName);
      formData.append("dealerId", this.editedItem.dealerId);
      formData.append("dealerEmail", this.editedItem.dealerEmail);
      formData.append("dealerMobile1", this.editedItem.dealerhome + this.editedItem.dealerMobile1);
      formData.append("dealerMobile2", this.editedItem.dealerwork + this.editedItem.dealerMobile2);
      formData.append("accesslevel", this.editedItem.accesslevel);
      formData.append("dealerPassword", this.editedItem.dealerPassword);
      return formData;
    },
    cloadofficeFormData() {
      const formData2 = new FormData();
      formData2.append("officeName", this.editedItem.officeName);
      formData2.append("officeMobile1", this.editedItem.addofficehome + this.editedItem.officeMobile1);
      formData2.append("officeMobile2", this.editedItem.addofficework + this.editedItem.officeMobile2);
      formData2.append("officeemail", this.editedItem.officeemail);
      formData2.append("officepassword", this.editedItem.officepassword);
      formData2.append("officeaddress", this.editedItem.officeaddress);
      formData2.append("city", this.editedItem.city);
      formData2.append("state", this.editedItem.state);
      formData2.append("zipcode", this.editedItem.zipcode);
      formData2.append("isbar", this.editedItem.isbar);
      formData2.append("officelicense", this.editedItem.officelicense);
      if (this.editedIndex == -11) {
        formData2.append("dealer", this.editedItem.dealerId);
      } else {
        formData2.append("dealer", this.editedItem.dealerId);
      }
      return formData2;
    },
    loadofficeFormData() {
      const formData2 = new FormData();
      formData2.append("officeName", this.editedItem.officeName);
      formData2.append("officeMobile1", this.editedItem.officeMobile1);
      formData2.append("officeMobile2", this.editedItem.officeMobile2);
      formData2.append("officeemail", this.editedItem.officeemail);
      formData2.append("officepassword", this.editedItem.officepassword);
      formData2.append("officeaddress", this.editedItem.officeaddress);
      formData2.append("city", this.editedItem.city);
      formData2.append("state", this.editedItem.state);
      formData2.append("zipcode", this.editedItem.zipcode);
      formData2.append("isbar", this.editedItem.isbar);
      formData2.append("officelicense", this.editedItem.officelicense);
      if (this.editedIndex == -11) {
        formData2.append("dealer", this.editedItem.dealerId);
      } else {
        formData2.append("dealer", this.editedItem.dealerId);
      }
      return formData2;
    },
    initialize() { },
    editItem(std) {
      this.editedIndex = this.dealers.indexOf(std);
      // this.editedItem = Object.assign({}, item);
      this.editedItem.dealerId = std.dealerId;
      this.editedItem.dealerName = std.dealerName;
      this.editedItem.dealerMobile1 = std.dealerMobile1;
      this.editedItem.dealerMobile2 = std.dealerMobile2;
      this.editedItem.dealerEmail = std.dealerEmail;
      this.editedItem.dealerPassword = std.dealerPassword;
      this.editedItem.accesslevel = std.accesslevel;
      this.dialog3 = true;
    },
    deleteOffice(office) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: 'Are you sure? You want to delete Office  ',
        text: 'You can\'t revert your action',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes Delete it!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteOffice', JSON.parse(office).officeId).then(() => {
            // this.mounteddata();
          })
          this.expanded = [];
          this.models = null;
          // this.$swal('Deleted', 'You successfully deleted this file', 'success')
          this.$store.dispatch("alert/success", 'You successfully deleted this file').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          // this.$swal('Cancelled', 'Your file is still intact', 'info')
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    close() {
      this.dialog = false;
      this.countrycode = 'US'
      this.editedIndex = -1;
      this.$refs.form1.reset()
      this.$store.dispatch('fetchDealers')
    },
    close2() {
      this.dialog2 = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.countrycode = 'US'
      this.$refs.form2.reset()
      this.expanded = [];
      this.models = null;
      this.$store.dispatch('fetchDealers')
    },
    close3() {
      this.dialog3 = false;
      this.$refs.form3.reset();
      // this.$refs.form1.reset();
      this.editedIndex = -1;
      this.$store.dispatch('fetchDealers')
    },
    save() {

      if (!this.$refs.form1.validate()) {
        return;
      }
      if (this.editedItem.dealerMobile1 == undefined)
        this.editedItem.dealerMobile1 = '';
      const fd = this.loadFormData();

      if (this.editedIndex == -1) {
        this.$store.dispatch('createDealer', fd)
        // getInfo.addData("cadealers", "adddealer", fd);
      } else if (this.editedIndex == -10) {
        const fd2 = this.loadofficeFormData();
        var data = {
          odata: fd2,
          id: this.editedItem.officeId
        }
        this.$store.dispatch('editOffice', data)
      } else if (this.editedIndex == -11) {
        const fd2 = this.cloadofficeFormData();
        this.$store.dispatch('createOffice', fd2)
      }
      else {
        const fd3 = this.loaddealerFormData();
        var data2 = {
          ddata: fd3,
          id: this.editedItem.dealerId
        }
        this.$store.dispatch('editDealer', data2)
        // getInfo.editData("cadealers", "editdealer", this.editedItem.dealerId, fd);
      }
      // this.close();
    },
    save2() {

      if (!this.$refs.form2.validate()) {
        return;
      }
      if (this.editedItem.dealerMobile1 == undefined)
        this.editedItem.dealerMobile1 = '';
      const fd = this.loadFormData();

      if (this.editedIndex == -10) {
        const fd2 = this.loadofficeFormData();
        var data = {
          odata: fd2,
          id: this.editedItem.officeId
        }
        this.$store.dispatch('editOffice', data)
      } else {
        const fd2 = this.cloadofficeFormData();
        this.$store.dispatch('createOffice', fd2)
      }
      this.close2();
    },
    save3() {

      if (!this.$refs.form3.validate()) {
        return;
      }
      if (this.editedItem.dealerMobile1 == undefined)
        this.editedItem.dealerMobile1 = '';
      const fd = this.loadFormData();

      if (this.editedIndex == -1) {
        this.$store.dispatch('createDealer', fd)
        // getInfo.addData("cadealers", "adddealer", fd);
      } else if (this.editedIndex == -10) {
        const fd2 = this.loadofficeFormData();
        var data = {
          odata: fd2,
          id: this.editedItem.officeId
        }
        this.$store.dispatch('editOffice', data)
      } else if (this.editedIndex == -11) {
        const fd2 = this.loadofficeFormData();
        this.$store.dispatch('createOffice', fd2)
      }
      else {
        const fd3 = this.loaddealerFormData();
        var data2 = {
          ddata: fd3,
          id: this.editedItem.dealerId
        }
        this.$store.dispatch('editDealer', data2)
        // getInfo.editData("cadealers", "editdealer", this.editedItem.dealerId, fd);
      }
      // window.location.reload()
      this.close3();
    },
  }
};
</script>
<template>
  <v-container fluid>
    <v-card style="border-radius:20px !important">
      <v-data-table dense :headers="headers" item-key="dealerId" :items="Slist" sort-by="dealerId" :search="search"
        class="elevation-1" show-expand single-expand :expanded.sync="expanded">
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat class="mb-3" dark style="background:#00A3A3 !important;border-radius:20px !important">
              <span style="font-size:40%">
                <v-toolbar-title>DEALERS</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>


              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-spacer></v-spacer> -->

              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-row>
                    <v-col cols="12" mx="4">
                      <v-layout justify-end>
                        <!-- <v-btn color="black" class="sm-0" v-on="on" v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.md" style="border-radius:20px" >
                  
               Add Dealer
                </v-btn> -->
                        <!-- <v-icon  v-else v-on="on"   color="dark" style="background:black;padding:5px;border-radius:50%">
                 mdi-account-multiple-outline</v-icon> -->
                        <v-btn v-on="on" style="border-radius:20px;background:black">
                          <h2>+</h2><v-icon size="20px" color="dark">
                            mdi-account-multiple-outline
                          </v-icon>
                        </v-btn>

                      </v-layout>
                    </v-col>
                  </v-row>

                </template>
                <v-card>
                  <v-toolbar flat color="#00A3A3" dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form autocomplete="off" ref="form1" v-model="valid" lazy-validation>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="dealernameRules"
                              v-on:keypress="isLetter($event)" v-model="editedItem.dealerName"
                              label="Dealer Name*"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            required
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.dealerId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="emailRules" v-model="editedItem.dealerEmail"
                              label="Email*"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-on:keypress="isNumber($event)"
                            required
                            :rules="phoneRules"
                            v-model="editedItem.dealerMobile1"
                            label="1Phone*"
                          ></v-text-field>
                        </v-col> -->
                          <div class="left-btn" v-if="formTitle == 'Add Dealer'">
                            <vue-country-code @onSelect="dealeronSelect" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none"
                              :preferredCountries="['in', 'us', 'gb']">
                              <input type="text" v-model="editedItem.dealerhome" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn" v-if="formTitle == 'Add Dealer'">
                            <v-text-field dense outlined v-on:keypress="isNumber($event)" required :rules="phoneRules"
                              v-model="editedItem.dealerMobile1" label="Phone*" class="dealertextfeild"></v-text-field>
                          </div>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phoneRulese"
                              v-model="editedItem.dealerMobile1" label="Phone*"></v-text-field>
                          </v-col>
                          <div class="left-btn" v-if="editedIndex == -1">
                            <vue-country-code @onSelect="dealeronSelect2" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none"
                              :preferredCountries="['in', 'us', 'gb']">
                              <input type="text" v-model="editedItem.dealerwork" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn" v-if="editedIndex == -1">
                            <v-text-field dense outlined v-on:keypress="isNumber($event)" required :rules="phone2Rules"
                              v-model="editedItem.dealerMobile2" label="Phone 2" class="dealertextfeild"></v-text-field>
                          </div>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field dense outlined v-on:keypress="isNumber($event)" required :rules="phone2Rulese"
                              v-model="editedItem.dealerMobile2" label="Phone 2"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-select
                            dense
                            required
                            :rules="accessRules"
                            v-model="editedItem.accesslevel"
                            :items="access"
                            label="Select Access Level*"
                            item-value="text"
                          ></v-select>
                        </v-col> -->
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field outlined v-if="formTitle == 'Add Dealer'" v-model="editedItem.dealerPassword"
                              required :rules="passwordRules" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'" name="password*" label="Password" counter
                              @click:append="show = !show"></v-text-field>
                          </v-col>
                          <!-- <v-col v-if="user.role == 'Admin'" cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            required
                            :rules="dealerRules"
                            v-model="editedItem.dealerId"
                            :items="dealers"
                            label="Select Dealer*"
                            item-text="dealerName"
                            item-value="dealerId"
                          ></v-autocomplete>
                        </v-col> -->
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="12">
                            <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px !important">
                              <v-toolbar-title>Add Office Details</v-toolbar-title>
                            </v-toolbar>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field required outlined v-on:keypress="isLetter($event)" :rules="officenameRules"
                              dense v-model="editedItem.officeName" label="Office Name*"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            required
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                          <div class="left-btn mt-4" v-if="editedIndex == -1">
                            <vue-country-code @onSelect="officeonSelect" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none"
                              :preferredCountries="['in', 'us', 'gb']">
                              <input type="text" v-model="editedItem.officehome" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn mt-3" v-if="editedIndex == -1">
                            <v-text-field dense outlined required :rules="phoneRules" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile1" label="Phone*" class="textfeild"></v-text-field>
                          </div>
                          <!-- <v-col v-if="editedIndex==-1" cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="phoneRules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeMobile1"
                            label="1Phone*"
                          ></v-text-field>
                        </v-col> -->
                          <div class="left-btn mt-4" v-if="editedIndex == -1">
                            <vue-country-code @onSelect="officeonSelect2" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none"
                              :preferredCountries="['in', 'us', 'gb']">
                              <input type="text" v-model="editedItem.officework" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn mt-3" v-if="editedIndex == -1">
                            <v-text-field dense outlined required :rules="phone2Rules" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile2" label="Phone 2" class="textfeild2"></v-text-field>
                          </div>
                          <!-- <v-col v-if="editedIndex==-1" cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="phone2Rules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeMobile2"
                            label="2Phone 2"
                          ></v-text-field>
                        </v-col> -->
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="emailRules" v-model="editedItem.officeemail"
                              label="Email*"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="passwordRules"
                              v-model="editedItem.officepassword" label="Password*"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="addressRules"
                              v-model="editedItem.officeaddress" label="Address*"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="cityRules" v-model="editedItem.city"
                              label="City*"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-autocomplete dense outlined required :rules="stateRules" v-model="editedItem.state"
                              :items="states" label="Select State*"></v-autocomplete>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="zipRules" v-model="editedItem.zipcode"
                              label="Zipcode*"></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-checkbox false-value="0" true-value="1" v-model="editedItem.isbar"
                              :label="`isBAR License`"></v-checkbox>
                          </v-col>
                          <v-col v-if="editedIndex == -1" cols="12" sm="12" md="6">
                            <v-text-field dense outlined required :rules="licenseRules"
                              v-model="editedItem.officelicense"
                              :label="editedItem.isbar == '1' ? 'BAR License' : 'BEARHFTI License'"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px !important">
  <v-toolbar-title>Payment Integration</v-toolbar-title>
</v-toolbar>
<v-col cols="12" sm="12" md="6">
  <v-autocomplete outlined dense v-model="editedItem.payment" :items="paymentgateway" label="Payment Gateway"></v-autocomplete>
</v-col>
<v-col >
  <!-- Conditional rendering of text fields based on selectedGateway -->
  <div v-if="editedItem.payment === 'Stripe'">
    <v-text-field label="STRIPE_LIVE_KEY"  outlined dense v-model="stripelivekey"></v-text-field>
    <v-text-field label="STRIPE_LIVE_TOKEN" outlined dense v-model="stripelivetoken"></v-text-field>
    <v-text-field label="STRIPE_TEST_KEY"  outlined dense v-model="stripetestkey"></v-text-field>
    <v-text-field label="STRIPE_TEST_TOKEN" outlined dense v-model="stripetesttoken"></v-text-field>
    </div>
    <!-- Add more fields as needed for Stripe -->
    <div v-if="editedItem.payment === 'PayPal'">
  <v-text-field label="PAYPAL_LIVE_KEY"  outlined dense v-model="PayPallivekey"></v-text-field>
    <v-text-field label="PAYPAL_LIVE_TOKEN" outlined dense v-model="PayPallivetoken"></v-text-field>
    <v-text-field label="PAYPAL_TEST_KEY"  outlined dense v-model="PayPaltestkey"></v-text-field>
    <v-text-field label="PAYPAL_TEST_TOKEN" outlined dense v-model="PayPaltesttoken"></v-text-field>
  <!-- Add more fields as needed for PayPal -->
</div>
  <!-- Add more conditions for other gateways if needed -->
</v-col>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close" style="background:#ff3d41;color:white">Cancel</v-btn>
                    <v-btn color="primary darken-1" text :disabled="!valid" @click="save">{{ buttonTitle }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialog2" max-width="600px">
                <v-card class="elevation-8" style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle2 }} </v-toolbar-title>
                  </v-toolbar>
                  <v-form autocomplete="off" ref="form2" v-model="valid" lazy-validation>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" v-if="formTitle2 == 'Add Office'">
                            <v-text-field outlined required v-on:keypress="isLetter($event)" :rules="officenameRules"
                              dense v-model="editedItem.officeName" label="Office Name*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field outlined required v-on:keypress="isLetter($event)" :rules="officenameRules"
                              dense v-model="editedItem.officeName" label="Office Name*"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            required
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                          <!-- <v-col  cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="phoneRules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeMobile1"
                            label="1Phone*"
                          ></v-text-field>
                        </v-col> -->
                          <div class="left-btn" v-if="formTitle2 == 'Add Office'">
                            <vue-country-code @onSelect="addofficeonSelect" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none" :preferredCountries="['in', 'us', 'gb']"
                              class="mt-3">
                              <input type="text" v-model="editedItem.addofficehome" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn" v-if="formTitle2 == 'Add Office'">
                            <v-text-field outlined dense required :rules="phoneRules" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile1" label="Phone*" class="textfeild mt-3"></v-text-field>
                          </div>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field outlined dense required :rules="phoneRulese" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile1" label="Phone*"></v-text-field>
                          </v-col>
                          <!-- <v-col  cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="phone2Rules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.officeMobile2"
                            label="2Phone 2"
                          ></v-text-field>
                        </v-col> -->
                          <div class="left-btn " v-if="formTitle2 == 'Add Office'">
                            <vue-country-code @onSelect="addofficeonSelect2" :enabledCountryCode="true"
                              :defaultCountry='countrycode' style="border:none" :preferredCountries="['in', 'us', 'gb']"
                              class="mt-3">
                              <input type="text" v-model="editedItem.addofficework" name="phone" id="phone"
                                placeholder="phone number" />
                            </vue-country-code>
                          </div>

                          <div class="left-btn" v-if="formTitle2 == 'Add Office'">
                            <v-text-field outlined dense required :rules="phone2Rules" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile2" label="Phone 2" class="textfeild mt-3"></v-text-field>
                          </div>
                          <v-col cols="12" sm="12" md="6" v-else>
                            <v-text-field dense outlined required :rules="phone2Rulese" v-on:keypress="isNumber($event)"
                              v-model="editedItem.officeMobile2" label="Phone 2"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="emailRules" v-model="editedItem.officeemail"
                              label="Email*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="passwordRules"
                              v-model="editedItem.officepassword" label="Password*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="addressRules"
                              v-model="editedItem.officeaddress" label="Address*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="cityRules" v-model="editedItem.city"
                              label="City*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete outlined dense required :rules="stateRules" v-model="editedItem.state"
                              :items="states" label="Select State*"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="zipRules" v-model="editedItem.zipcode"
                              label="Zipcode*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-checkbox false-value="0" true-value="1" v-model="editedItem.isbar"
                              :label="`isBAR License`"></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="licenseRules"
                              v-model="editedItem.officelicense"
                              :label="editedItem.isbar == '1' ? 'BAR License' : 'BEARHFTI License'"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close2" style="background:#ff3d41;color:white">Cancel</v-btn>
                    <v-btn color="primary darken-1" text :disabled="!valid" @click="save2">{{ buttonTitle2 }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog3" max-width="600px">
                <v-card style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form autocomplete="off" ref="form3" v-model="valid" lazy-validation outlined>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined dense required :rules="dealernameRules"
                              v-on:keypress="isLetter($event)" v-model="editedItem.dealerName"
                              label="Dealer Name*"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            required
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.dealerId"
                            label="ID"
                          ></v-text-field>
                        </v-col> -->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense required :rules="emailRules" v-model="editedItem.dealerEmail"
                              label="Email*" outlined></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phoneRulese"
                              v-model="editedItem.dealerMobile1" label="Phone*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phone2Rulese"
                              v-model="editedItem.dealerMobile2" label="Phone 2"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                          <v-select
                            dense
                            required
                            :rules="accessRules"
                            v-model="editedItem.accesslevel"
                            :items="access"
                            label="Select Access Level*"
                            item-value="text"
                          ></v-select>
                        </v-col> -->
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field v-if="formTitle == 'Add Dealer'" v-model="editedItem.dealerPassword" required
                              :rules="passwordRules" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'" name="password*" label="Password" counter
                              @click:append="show = !show"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close3" style="background:#ff3d41;color:white !important">Cancel</v-btn>
                    <v-btn text :disabled="!valid" @click="save3" style="background:#2E2EFF;color:white">{{ buttonTitle
                      }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dshipdlg" max-width="800px">
                <v-card style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>Dealerships</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="addmoredealerShip(selecteddealer)" size="30">mdi-plus-circle-multiple-outline</v-icon>
                    &nbsp;&nbsp;&nbsp;
                    <v-icon color="white" @click="dshipclose" style="background-color: #ff3d41;border-radius: 20px;width: 5%;">mdi-close</v-icon>
                  </v-toolbar>
                  <v-card class="d-flex justify-center align-center">
                    <v-table>
                      <thead>
                        <tr>
                          <th class="center-text">
                            Dealership Id
                          </th>
                          <th class="center-text">
                            Device Model
                          </th>
                          <th class="center-text">
                            Quantity
                          </th>
                          <th class="center-text">
                            Dealership Mode
                          </th>
                          <th class="text-left">
                            Paymentstatus
                          </th>
                          <th class="center-text">
                            Registration Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in items" :key="item.name">
                          <td class="center-text">{{ item.id }}</td>
                          <td class="center-text">{{ item.devicemodel }}</td>
                          <td class="center-text">{{ item.qty }}</td>
                          <td class="center-text">{{ item.type }}</td>
                          <td class="center-text">{{ item.paymentstatus }}</td>
                          <td class="center-text">{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn text @click="dshipclose" style="background:#ff3d41;color:white !important">Cancel</v-btn>
                    <v-btn text :disabled="!valid" @click="save3" style="background:#2E2EFF;color:white">{{ buttonTitle
                      }}</v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="nodshipdlg" max-width="600px">
                <v-card style="border-radius:20px">
                  <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form autocomplete="off" ref="form3" v-model="valid" lazy-validation outlined>
                    <v-card-text>
                      <v-container>
                        <!-- <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field outlined dense required :rules="dealernameRules"
                              v-on:keypress="isLetter($event)" v-model="editedItem.dealerName"
                              label="Dealer Id*"></v-text-field>
                          </v-col>
                        </v-row> -->
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field dense required label="Description*" outlined
                              v-model="description"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete label="Device Model"
                              required class="purple-input" v-model="devicemodel" :items="modellist"
                              item-text="devicemodel" item-value="id"></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-combobox label="Quantity" v-model="quantity" :items="qytlist"></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-combobox label="DealerShip Mode" v-model="Dshiptype" :items="Dshipmode"></v-combobox>
                          </v-col>
                        </v-row>
                        <!-- <v-col cols="12" sm="12" md="6">
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phone2Rulese"
                              v-model="editedItem.dealerMobile2" label="Paymentstatus"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phone2Rulese"
                              v-model="editedItem.dealerMobile2" label="Registration Date"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field dense v-on:keypress="isNumber($event)" required :rules="phone2Rulese"
                              v-model="editedItem.dealerMobile2" label="Installation Date"></v-text-field>
                          </v-col>
                        </v-row> -->
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="nodshipdlg = false"
                      style="background:#ff3d41;color:white !important">Cancel</v-btn>
                    <v-btn text :disabled="!valid" @click="Dealershipsave"
                      style="background:#2E2EFF;color:white">{{ buttonTitle }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-card>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mr-2 mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon class="mr-2 mt-2" larger color="red" @click="deleteDealer(item)">
            mdi-delete-circle
          </v-icon>
        </template>
        <template slot=item.addmore slot-scope="{ item }">
          <v-icon class="mt-2" color="green" @click="Addeditmore(item)">
            mdi-plus
          </v-icon>
        </template>
        <template slot=item.Dship slot-scope="{ item }">
          <img v-if="item.Hasdealership == true" alt="Icon" @click="adddealerShip(item)" src='../assets/dealershipdone.png'
            />
          <img v-else src='../assets/dealershipnotdone.png' alt="Icon" @click="adddealerShip(item)"
            />
        </template>
        <template v-slot:no-data>
          <v-btn color="error" @click="initialize">NO DATA</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0">
            <v-toolbar v-if="models && selected" light dense>
              <!-- <v-toolbar-title>INVOICES</v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
              <!-- <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field> -->

              <v-spacer></v-spacer>
              <div style="flex-direction: row-reverse">
                <v-btn color="orange" @click="editItem2(models)"> Edit
                  <v-icon class="mt-2">
                    mdi-border-color
                  </v-icon>
                </v-btn>
                &nbsp;&nbsp;&nbsp;
                <v-btn color="red" @click="deleteOffice(models)"> Delete
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
              <!-- <v-btn color="blue" dark @click="getId">Add Invoice</v-btn> -->
            </v-toolbar>
            <v-list v-if="item.list.length > 0" dense class="ma-0 pa-0">
              <v-list-item-group v-model="models">
                <!-- <v-list-item v-if="model"> -->

                <!-- </v-list-item> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><b>Select</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Name</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Mobile</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Alternate Mobile</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Email</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Address</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Bar License</b></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title><b>Dealer</b></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-for="(itemed, i) in item.list">
                  <v-divider v-if="!itemed" :key="`divider-${i}`"></v-divider>

                  <v-list-item v-else :key="`item-${i}`" :value="itemed"
                    active-class="deep-purple--text text--accent-4">
                    <template>
                      <v-list-item-action>
                        <v-checkbox v-model="i.selected" @click="handleclick" color="deep-purple accent-4"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <!-- eslint-disable -->
                        <v-list-item-title v-text="JSON.parse(itemed).officeName"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).officeMobile1"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).officeMobile2"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).officeemail"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).officeaddress"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).officelicense"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title v-text="JSON.parse(itemed).dealer"></v-list-item-title>
                      </v-list-item-content>
                      <!-- eslint-enable -->
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-list v-else>
              <v-btn color="blue" style="margin-left:50%">NO DATA</v-btn>
            </v-list>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>








<div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>






<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(243, 243, 243, 0.973);
}

/* thead {
  background-color: lightslategray;
} */
.center-text {
  text-align: center;
}

/* Add spacing between th and td */
td,
th {
  padding: 10px;
  /* Adjust as per your requirement */
}

.textfeild {
  width: 180px;
  /* font-size: large; */
}

.textfeild2 {
  width: 180px;

}

.dealertextfeild {
  width: 180px;

}
img {
    display: block; /* Ensures the image behaves like a block-level element */
    max-width: 60%; /* Ensures the image does not exceed its container's width */
    height: 100%; /* Allows the image's height to adjust proportionally with its width */
}
@media screen and (max-width: 600px) {
    img {
        /* You can add specific styles for smaller screens here if needed */
        display: block; /* Ensures the image behaves like a block-level element */
        max-width: 10%; /* Ensures the image does not exceed its container's width */
        height: 100%; 
        margin-left: 90%;
    }
}
</style>
