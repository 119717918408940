<template>
  <v-app id="inspire">
    <!-- <v-parallax
        style="height:100%"
         src="../../assets/kids.png"
        
        
      > -->
    <v-main>
      <v-container class="fill-height mainbody" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5">
            <v-card class="elevation-12">
              <v-toolbar class="multi-color-toolbar">
                <v-row>
                  <!-- <v-spacer></v-spacer> -->
                  <v-toolbar-title><img src='../../public/old_logo.png' width="30%" /></v-toolbar-title>
                </v-row>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form class="text-xs-center" dark="dark" ref="form" lazy-validation>
                  <v-container id="dropdown-example-3">
                    <v-text-field @keyup.enter="onTrack" id="selectedemail" class="custom-text-field" required
                      :color="textFieldColor" @click="changeColor" v-model="selectedEmail" label="Enter Emailid"
                      name="email" prepend-icon="mdi-account" type="text"></v-text-field>
                    <v-text-field @keyup.enter="onTrack" id="selectedpassword" class="custom-text-field" required
                      :color="textFieldColor" @click="changeColor" v-model="selectedPassword" label="Enter Password"
                      name="password" prepend-icon="mdi-phone" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword">
                    </v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onTrack" class="white--text" color="#00897b">Track</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- `<p>test</p>` -->
        </v-row>
      </v-container>
    </v-main>
    <!-- </v-parallax> -->
  </v-app>
</template>
  
<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      showPassword: false,
      textFieldColor: '#009688',
      selectedEmail: null,
      selectedPassword: null
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  created() {
    // reset login status
    // this.$store.dispatch("authentication/logout");
  },
  methods: {
    changeColor() {
      // Change the color of the text field on click
      this.textFieldColor = '#009688'; // Change to any color you prefer
    },
    onTrack() {
      if (this.selectedEmail != '' && this.selectedPassword != '') {
        var email = this.selectedEmail;
        var password = this.selectedPassword;
        this.$store.dispatch("authentication/fleetlogin", {
          email,
          password
        });
      }
    }
  }
};
</script>
<style>
.mainbody {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Adjust the height as needed */
  background:
    linear-gradient(to right, #26a69a, #b2dfdb, #009688);
  /* Blue background color */
  overflow: hidden;
}

.multi-color-toolbar {
  background: linear-gradient(to right, #e4e9ec, #80cbc4);
  /* Example gradient colors */
  color: white;
  /* Set text color to be visible on the background */
}
</style>
  