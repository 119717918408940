<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
     
  }),

  computed: {

  },

  watch: {
    
  },

  created() {
    this.initialize();
  },
  mounted() {
   this.$store.dispatch("alert/success", 'You Last Transaction Failed').then(()=>{
    setTimeout(()=>{
    this.$store.dispatch("alert/clear")
    router.push("dashboard");
    },2000)
                })
           
  },

  methods: {
  }

};
</script>
<template>
  <v-container fluid>
    <v-card>
        <v-card-title>Transaction Failed</v-card-title>
         </v-card>
  </v-container>
</template>
