<template>
  <div class="hello">
    <div v-if="!isLoading" id="map"></div>
    <div id="map" v-else>
      <h1 style="
          color: rgb(161, 231, 90);text-align: center;padding-left: 40%;padding-top: 30%;font-weight: bold;font-size: xx-large;
        ">
        Loading....
      </h1>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import this.oledDevices from "../components/oled_list.json";
// import Pusher from "pusher-js";
import mapboxgl from "mapbox-gl";
import { mapGetters } from 'vuex';
export default {
  name: "Goooglemap",
  props: ["data", "mydialog"],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      map: null,
      currentmarker: null,
      allmarkers: [],
      linemarkers: [],
      marker: null,
      distancetravelled: 0,
      isLoading:false,
    };
  },
  watch: {
    data(val) {
      if (this.data.length > 0) {
        this.onLoad2();
      }
    }
  },
  computed: {
    ...mapGetters(['oledDevices'])
  },
  methods: {
    onClearall() {
      // this.map= null;
      this.currentmarker = null;
      this.allmarkers = [];
      this.linemarkers = [];
      this.marker = null;
      this.isLoading = true;
      if(this.map){
      this.map.remove(); // Remove the map instance
      // Clear the contents of the "map" element
      const mapElement = document.getElementById("map");
      if (mapElement) {
        mapElement.innerHTML = "";
      }
      }
    },
    onLoad() {
      this.data.map((user) => {
        if (
          this.oledDevices.filter((value) => value.sno == user.Vehicle.sno).length == 0
        ) {
          Vue.axios
            .get(
              "https://robopower.xyz/app/v2data/getlatestpingv22?cbid=" +
              user.Vehicle.cbid
            )
            .then((response) => {
              if (response.data) {
                if (
                  response.data != null &&
                  response.data.lat != "NA" &&
                  response.data.lon != "NA"
                ) {
                  var deal = response.data;
                  var lati = parseFloat(deal.lat);
                  var long = parseFloat(deal.lon);
                  var marker = {
                    lat: lati,
                    lng: long,
                  };
                  this.$emit("changetime", response.data);
                  if (this.currentmarker) {
                    if (
                      this.currentmarker.lat == marker.lat &&
                      this.currentmarker.lng == marker.lng
                    ) {
                      //same marker no need to draw
                      console.log("same marker");
                    } else {
                      //updated latest marker
                      this.currentmarker = marker;
                      this.allmarkers.push(marker);
                      this.marker.setLngLat([
                        parseFloat(deal.lon),
                        parseFloat(deal.lat),
                      ]);
                      this.map.setCenter([
                        parseFloat(deal.lon),
                        parseFloat(deal.lat),
                      ]);
                      if (this.allmarkers.length > 1) {
                        var coords = [
                          [
                            parseFloat(
                              this.allmarkers[this.allmarkers.length - 2].lng
                            ),
                            parseFloat(
                              this.allmarkers[this.allmarkers.length - 2].lat
                            ),
                          ],
                          [parseFloat(deal.lon), parseFloat(deal.lat)],
                        ];

                        var alldata = [
                          {
                            type: "Feature",
                            properties: {
                              color: "green", // red
                            },
                            geometry: {
                              type: "LineString",
                              coordinates: coords,
                            },
                          },
                        ];
                        this.map.addSource("route" + this.allmarkers.length, {
                          type: "geojson",
                          data: {
                            type: "FeatureCollection",
                            features: alldata,
                          },
                        });
                        this.map.addLayer({
                          id: "drawroute" + this.allmarkers.length,
                          type: "line",
                          source: "route" + this.allmarkers.length,
                          layout: {
                            "line-join": "round",
                            "line-cap": "round",
                          },
                          paint: {
                            "line-color": ["get", "color"],
                            "line-width": 6,
                          },
                        });
                      }
                    }
                  } else {
                    //
                    this.currentmarker = marker;
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        } else {
          Vue.axios
            .get(
              "https://robopower.xyz/app/v2data/getlatestpingv2?devId=" +
              user.Vehicle.devId
            )
            .then((response) => {
              if (response.data) {
                if (
                  response.data != null &&
                  response.data.lat != "NA" &&
                  response.data.lon != "NA"
                ) {
                  var deal = response.data;
                  var lati = parseFloat(deal.lat);
                  var long = parseFloat(deal.lon);
                  var marker = {
                    lat: lati,
                    lng: long,
                  };
                  this.$emit("changetime", response.data);
                  if (this.currentmarker) {

                    if (
                      this.currentmarker.lat == marker.lat &&
                      this.currentmarker.lng == marker.lng
                    ) {
                      //same marker no need to draw
                      console.log("same marker");
                    } else {
                      var distance = this.calculateDistance(
                        this.currentmarker.lat,
                        this.currentmarker.lng,
                        marker.lat,
                        marker.lng
                      );

                      //updated latest marker
                      if (distance > 150) {

                        this.currentmarker = marker;
                        this.allmarkers.push(marker);
                        this.marker.setLngLat([
                          parseFloat(deal.lon),
                          parseFloat(deal.lat),
                        ]);
                        this.map.setCenter([
                          parseFloat(deal.lon),
                          parseFloat(deal.lat),
                        ]);
                        if (this.allmarkers.length > 1) {
                          var coords = [
                            [
                              parseFloat(
                                this.allmarkers[this.allmarkers.length - 2].lng
                              ),
                              parseFloat(
                                this.allmarkers[this.allmarkers.length - 2].lat
                              ),
                            ],
                            [parseFloat(deal.lon), parseFloat(deal.lat)],
                          ];
                          var alldata = [
                            {
                              type: "Feature",
                              properties: {
                                color: "green", // red
                              },
                              geometry: {
                                type: "LineString",
                                coordinates: coords,
                              },
                            },
                          ];
                          this.map.addSource("route" + this.allmarkers.length, {
                            type: "geojson",
                            data: {
                              type: "FeatureCollection",
                              features: alldata,
                            },
                          });
                          this.map.addLayer({
                            id: "drawroute" + this.allmarkers.length,
                            type: "line",
                            source: "route" + this.allmarkers.length,
                            layout: {
                              "line-join": "round",
                              "line-cap": "round",
                            },
                            paint: {
                              "line-color": ["get", "color"],
                              "line-width": 6,
                            },
                          });
                        }
                      }
                    }
                  }
                } else {
                  //
                  this.currentmarker = marker;
                }
              }
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        }
      });
      // var alldata = [{
      //             type: "Feature",
      //             properties: {
      //               color: "green" // red
      //             },
      //             geometry: {
      //               type: "LineString",
      //               coordinates:  [[81.897,17.987],
      //           [78.982,18.987]]
      //             }
      //           },{
      //             type: "Feature",
      //             properties: {
      //               color: "green" // red
      //             },
      //             geometry: {
      //               type: "LineString",
      //               coordinates:  [[78.897,19.987],
      //           [82.982,18.987]]
      //             }
      //           }]
      // this.map.addSource("route", {
      //   type: "geojson",
      //   data: {
      //     type: "FeatureCollection",
      //     features: alldata
      //   }
      // });
      // this.map.addLayer({
      //   id: "drawroute",
      //   type: "line",
      //   source: "route",
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "round"
      //   },
      //   paint: {
      //     "line-color": ["get", "color"],
      //     "line-width": 6
      //   }
      // });
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c * 1000; // Distance in meters
      return distance;
    },
    //   calculatelatlondis(coords1,coords2){

    //    const R = 6371; // Radius of the Earth in kilometers

    // const [lon1, lat1] = coords1.map(coord => this.deg3grad(coord));
    // const [lon2, lat2] = coords2.map(coord => this.deg3grad(coord));

    // const dLon = lon2 - lon1;
    // const dLat = lat2 - lat1;

    // const a =
    //   Math.sin(dLat / 2) ** 2 +
    //   Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;

    // const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // const distance = R * c; // Distance in kilometers

    // return distance;
    //   },
    //   deg3grad(deg){
    //     return deg * (Math.PI / 180);
    //   },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    onLoad2() {
      this.isLoading = true;
      this.data.map((user) => {
        if (
          this.oledDevices.filter((value) => value.sno == user.Vehicle.sno).length == 0
        ) {
          Vue.axios
            .get(
              "https://robopower.xyz/app/v2data/getlatestpingv22?cbid=" +
              user.Vehicle.cbid
            )
            .then((response) => {
              if (response.data) {
                if (
                  response.data != null &&
                  response.data.lat != "NA" &&
                  response.data.lon != "NA"
                ) {
                  var deal = response.data;
                  var lati = parseFloat(deal.lat);
                  var long = parseFloat(deal.lon);
                  var marker = {
                    lat: lati,
                    lng: long,
                  };
                  this.$emit("changetime", response.data);
                  this.init(marker, user.Vehicle.cbid);
                }
              }
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        } else {
          Vue.axios
            .get(
              "https://robopower.xyz/app/v2data/getlatestpingv2?devId=" +
              user.Vehicle.devId
            )
            .then((response) => {
              if (response.data) {
                if (
                  response.data != null &&
                  response.data.lat != "NA" &&
                  response.data.lon != "NA"
                ) {
                  var deal = response.data;
                  var lati = parseFloat(deal.lat);
                  var long = parseFloat(deal.lon);
                  var marker = {
                    lat: lati,
                    lng: long,
                  };
                  this.$emit("changetime", response.data);

                  //updated latest marker
                  this.currentmarker = marker;

                  this.allmarkers.push(marker);
                  this.init(marker, user.Vehicle.devId);
                }
              } else {
                var marker3 = {
                  lat: 0.0,
                  lng: 0.0,
                };
                this.init(marker3, user.Vehicle.devId);
              }
            })
            .catch((error) => {
              console.error("Error: ", error);
            });
        }
      });
    },
    init(locs, devid) {
      // setTimeout(()=>{
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
      if (locs.lat != '' && locs.lng != '') {
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          attributionControl: false,
          center: locs,
          zoom: 6,
        });
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.scrollZoom.disable();
        var popup = new mapboxgl.Popup().setText(devid).addTo(this.map);
        // map.rotateTo(180, { duration: 1000 });
        var el = document.createElement("div");
        el.className = "marker";
        // var marker = new mapboxgl.Marker(el,{offset: [0, -50/2]})
        //   .setLngLat([locs.lng,locs.lat])
        //   // .setPopup(popup)
        //   .addTo(map);
        this.marker = new mapboxgl.Marker()
          .setLngLat(locs)
          .setPopup(popup)
          .addTo(this.map);
          this.isLoading = false;
        return this.marker;
      } else {
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: locs,
          zoom: 1,
        });
        this.map.addControl(new mapboxgl.NavigationControl());
        // map.scrollZoom.disable();
        const places = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                description: "NO DATA FOUND",
              },
              geometry: {
                type: "Point",
                coordinates: [0.0, 0.0],
              },
            },
          ],
        };
        this.map.on("zoom", () => {
          const currentZoom = this.map.getZoom();
          if (currentZoom != 12) {
            // console.log(currentZoom);
          } else {
            console.log(currentZoom);
          }
        });
        this.map.on("load", () => {
          // Add a GeoJSON source containing place coordinates and information.
          this.map.addSource("places", {
            type: "geojson",
            data: places,
          });
          // const marker = new mapboxgl.Marker({
          //   color: "#F84C4C"
          // });
          this.map.addLayer({
            id: "poi-labels",
            type: "symbol",
            source: "places",
            layout: {
              "text-field": [
                "format",
                ["upcase", ["get", "description"]],
                { "font-scale": 1.8 },
                "\n",
              ],
              "text-variable-anchor": ["top", "bottom", "left", "right"],
              "text-radial-offset": 0.5,
              "text-justify": "auto",
              "icon-image": ["get", "icon"],
            },
          });
          this.isLoading = false;
          // map.rotateTo(180, { duration: 10000 });
        });
        // map.resize()
        this.isLoading = false;
      }

      // },100)
    },
  },
  beforeMount() {
    this.$store.dispatch('fetchOLEDDevices');
  },
  mounted() {
    this.onLoad2();
  },
};
</script>
<style scoped>
@import "https://api.tiles.mapbox.com/mapbox-gl-js/v1.6.0/mapbox-gl.css";
@import "https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css";

#map {
  display: inline-flex;
  height: 600px;
  width: 114%;
  margin-left: 3%;
  /* position: absolute; */
  top: 0;
  bottom: 0;
}

.marker {
  background-image: url("https://secure.webtoolhub.com/static/resources/icons/set101/1dc0e500.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>
