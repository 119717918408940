<script>
//import getInfo from "../scripts/getInfo";
import Vue from "vue";
import axios from "axios";
import moment from 'moment'
Vue.use(axios);
export default {
  name: "Californa",
  props: ["editedItem", "type", "mylogdata"],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    prohibstartend: 0
  }),

  computed: {},

  watch: {},

  created() { },
  mounted() {
    // console.log(this.editedItem)
    var date1 = moment(this.editedItem.prohibstart)
    var date2 = moment(this.editedItem.prohibend)
    var sts = date2.diff(date1, 'days')
    // console.log(sts)
    this.prohibstartend = sts
    // this.mylogdata.total = "Loading"
    // this.mylogdata.pass = "Loading"
    //  this.mylogdata.enginerunstate = "Loading"
    //   this.mylogdata.refused= "Loading"
    //   this.mylogdata.fail= "Loading"
    //   this.mylogdata.IncorrectBreathSamples= "Loading"    
    //   this.mylogdata.Bypass= "Loading"            
    //   this.mylogdata.PowerDisconnections= "Loading"              
    //   this.mylogdata.HandsetDisconnections= "Loading"            
    //   this.mylogdata.othertampering= "Loading"
    //   this.mylogdata.permanantlockout = "Loading"


  },

  methods: {},
};
</script>
<template>
  <v-container fluid>
    <div id="interlockpdfcontent" v-if="type == 'install'">
      <v-card-text>
        <v-container>
          <v-form autocomplete="off" ref="form2" style="width: 100%" class="my_text">
            <div id="color" class="my_text">
              <div class="interlocktable" style="margin-top: 100px">
                <div class="interlockborder" style="overflow: hidden">
                  <v-row>
                    <v-col cols="4" class="py-2" style="margin-top: 1%;"> <!-- Adjust cols as needed -->
                      <div style="font-size: 15px;margin-left: 2%;"><b>Service Provider:</b></div>
                      <div style="font-size: 10px; ">
                        <h3 style="margin-left: 2%;">
                          Alcobrake Private Limited<br />
                          VIP Hills, Silicon Valley<br />
                          Madhapur, Telangana 500081 <br />
                          8977285706
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="3" class="py-2">
                      <div class="text-center" style="font-size: 20px;margin-top: 5%;">
                        VERIFICATION OF <br />INSTALLATION<br />IGNITION <br />INTERLOCK
                      </div>
                    </v-col>
                    <v-col cols="5" class="py-2" align="center" style="margin-top: 5%;margin-bottom: 7%;">
                      <img src="../assets/Alogo.png" width="400" />
                    </v-col>
                  </v-row>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="interlockborder">
                  <div>
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      1&nbsp;&nbsp;&nbsp;&nbsp;CLIENT&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CLIENT’S NAME (FIRST,MIDDLE,LAST)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.client }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DRIVERS LICENSE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.dlnum }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STREET ADDRESS(STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.clientAddress }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        APPARMENTSPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.space }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 266pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.city }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.state }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.zipcode }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 266pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        BIRTH DATE (MONTH,DAY,YEAR)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.dob.replaceAll(",", '/').split('/').join(' / ') }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        HOME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}
                        </b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        WORK TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}
                        </b>
                      </p>
                    </td>
                  </div>
                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #d9d9d9">
                      &nbsp;SECTION
                      2&nbsp;&nbsp;VEHICLE&nbsp;INFORMATION&nbsp;&nbsp;An
                      ignition interlock was installed on the following vehicle
                    </h3>
                  </div>

                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE MAKE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.make }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE MODEL
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.model }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.year }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.regnum }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTIFICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.vin }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        COLOR
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.color }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      3&nbsp;&nbsp;IGINITION&nbsp;INTERLOCK&nbsp;&nbsp;DEVICE&nbsp;&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF INSTALLATION
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF NEXT MONITOR CHECK
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.nextcmonitordate }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CLIENT NO.
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}</b>
                      </p>
                    </td>
                  </div>
                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION 4 &nbsp;&nbsp;FACILITY &nbsp;USE&nbsp;ONLY
                    </h3>
                  </div>
                  <div class="content">
                    <b style="font-size: 13px; margin-top: 20px">
                      &nbsp;This document,&nbsp;when signed by a representative,
                      certifies that the individual identified above has entered
                      into an interlock service agreement and that an approved
                      interlock device hasbeen installed on the above Vehicle.
                    </b>
                  </div>
                  <div class="reduce">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLR'S SIGNATURE
                      </p>
                      <!-- <p class="s3" style="text-indent: 0pt;text-align: left;"></p> -->
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <!-- uninstall form  -->
    <div id="interlockpdfcontent" v-else>
      <v-card-text>
        <v-container>
          <v-form autocomplete="off" ref="form2" style="width: 100%" class="my_text">
            <div id="color">
              <div class="interlocktable2" style="margin-top: 70px">
                <div class="interlockborder" style="overflow: hidden">
                  <v-row>
                    <v-col cols="4" class="py-2" style="margin-top: 1%;"> <!-- Adjust cols as needed -->
                      <div style="font-size: 15px;margin-left: 2%;"><b>Service Provider:</b></div>
                      <div style="font-size: 10px; ">
                        <h3 style="margin-left: 2%;">
                          Alcobrake Private Limited<br />
                          VIP Hills, Silicon Valley<br />
                          Madhapur, Telangana 500081 <br />
                          8977285706
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="3" class="py-2">
                      <div class="text-center" style="font-size: 20px;margin-top: 5%;">
                        VERIFICATION OF <br />INSTALLATION<br />IGNITION <br />INTERLOCK
                      </div>
                    </v-col>
                    <v-col cols="5" class="py-2" align="center" style="margin-top: 5%;margin-bottom: 7%;">
                      <img src="../assets/Alogo.png" width="400" />
                    </v-col>
                  </v-row>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="interlockborder">
                  <div>
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      1&nbsp;&nbsp;&nbsp;&nbsp;CLIENT&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CLIENT’S NAME (FIRST,MIDDLE,LAST)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.client }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DRIVERS LICENSE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.dlnum }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STREET ADDRESS (STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.clientAddress }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        APPARMENTSPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.space }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 266pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.city }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.state }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.zipcode }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 266pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        BIRTH DATE (MONTH,DAY,YEAR)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.dob.replaceAll(",", '/').split('/').join(' / ') }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        HOME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}
                        </b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        WORK TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}
                        </b>
                      </p>
                    </td>
                  </div>
                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #d9d9d9">
                      &nbsp;SECTION
                      2&nbsp;&nbsp;VEHICLE&nbsp;INFORMATION&nbsp;&nbsp;An
                      ignition interlock was installed on the following vehicle:
                    </h3>
                  </div>

                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE MAKE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.make }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE MODEL
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.model }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.year }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.regnum }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTIFICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.vin }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        COLOR
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.color }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      3&nbsp;&nbsp;IGINITION&nbsp;INTERLOCK&nbsp;&nbsp;DEVICE&nbsp;&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF INSTALLATION
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                    <td style="width: 170pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF NEXT MONITOR CHECK
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.nextcmonitordate }}</b>
                      </p>
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CLIENT NO.
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{ editedItem.clientphone1.replaceAll(" ", "&nbsp;") }}</b>
                      </p>
                    </td>
                  </div>
                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION 4 &nbsp;&nbsp;TECHNICIAN &nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="reduce">
                    <td style="width: 420pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        TECHNICIAN'S NAME (FIRS,LAST,INITIAL)
                      </p>
                      <!-- <p class="s3" style="text-indent: 0pt;text-align: left;"></p> -->
                    </td>
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                  </div>
                </div>
                <br />

                <div class="border" style="margin-left: 175px; width: 475px; height: 395px">
                  <div>
                    <h3 type="I" style="font-size: 17px; background-color: #c4c2c2">
                      SECTION 5 &nbsp;&nbsp;DATA &nbsp;LOG&nbsp;SUMMARY
                    </h3>
                  </div>
                  <table style="width: 100%">
                    <tr style="font-size: 17px">
                      <th>Service Item</th>
                      <th>Result</th>
                    </tr>
                    <tr>
                      <td>&nbsp;Handset Serial Number</td>
                      <td>&nbsp;{{ editedItem.sno }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Service interval</td>
                      <td>&nbsp;{{ editedItem.prohibstart }}&nbsp;-&nbsp; {{ editedItem.prohibend }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Period (days)</td>
                      <td>&nbsp;{{ this.prohibstartend }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Test Samples Given</td>
                      <td>&nbsp;{{ mylogdata.total }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Test Samples Passed</td>
                      <td>&nbsp;{{ mylogdata.pass }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Engine Starts</td>
                      <td>&nbsp;{{ mylogdata.enginerunstate }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Missed Rolling Retests</td>
                      <td>&nbsp;{{ mylogdata.refused }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Failed Rolling Retests</td>
                      <td>&nbsp;{{ mylogdata.fail }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Incorrect Breath Samples</td>
                      <td>&nbsp;{{ mylogdata.IncorrectBreathSamples }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Engine Starts Without Test</td>
                      <td>&nbsp;{{ mylogdata.Bypass }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Power Disconnections</td>
                      <td>&nbsp;{{ mylogdata.PowerDisconnections }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Handset Disconnections</td>
                      <td>&nbsp;{{ mylogdata.HandsetDisconnections }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Other Tampering</td>
                      <td>&nbsp;{{ mylogdata.othertampering }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Permanent Lockouts</td>
                      <td>&nbsp;{{ mylogdata.permanantlockout }}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;Hardware Errors</td>
                      <td>&nbsp;0</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
  </v-container>
</template>


<style scoped>
.interlocktable {
  padding-top: 20px;
  padding-left: 5px;
  height: 900px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}

.interlocktable2 {
  padding-top: 20px;
  padding-left: 5px;
  height: 1310px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}

.interlockborder {
  border-style: solid;
  border-width: 3px;
  margin-left: 35px;
  width: 770px;
}

.reduce {
  border-top: 2px solid #333333;
  /* border-bottom: 2px solid #333333; */
  align-items: center;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.content {
  align-items: center;
}

.interlocksection {
  border-top: 2px solid #333333;
  align-items: center;
}

.dateinst {
  /* border-top: 2px solid #333333; */
  align-items: center;
  width: 767px;
}

.my_text {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

.s2 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
  margin-bottom: -3px;
}

.s3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin-bottom: 5px;
}

.s5 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 9pt;
}
</style>