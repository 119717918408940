<script src="https://js.stripe.com/v3"></script>
<script>
import Vue from "vue";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import moment from 'moment';
import VueGPicker from './gdrive/VueGPicker.vue';
import {mapGetters} from 'vuex'
// import GDriveSelector from "./GdriveSelector";
const customStyles = {
    ul: {
        border: '2px solid red'
    },
    li: {
        display: 'inline-block',
        border: '2px dotted green'
    },
    a: {
        color: 'blue'
    }
};
const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};
export default {
    data: () => ({
      customStyles,customLabels,
    user  : JSON.parse(localStorage.getItem("user")),
    file: [],
    drivefiledialog: false,
    files:[],
    datafetch:"",
    uploadPercentage:0,
    isValiddrive:false,
    synctext:null,
    fetchdialog:false,
    imagedata : '',
    imagefile: [],
    pageOfItems: [],
    dialog: false,
    isSingle:true,
    paymentrow:{},
    isPayment:true,
    loading:false,
    clientimagefile:'',
    alldatas:'',
    viewall:false,
    selection: 0,
    selection2: 0,
    imgdialog:false,
    totalclear:"0.00",
    cimage:'',
    imagetext:'LOADING',
    loading:false,
    iscreated: false,
    iscreated2: false,
    iscreated3: false,
    singlerow:{},
    valid:'',
    tab: null,
    tabitems: ['Upload', 'Clients', 'Payments'],
    // tabitems: ['Upload', 'Clients'],
    text: 'Not Available',
    search: '',
    search2:'',
    countrycode: 'us',
    Zmobile:'',
    Id:'',
    billnum:'',
    //clientdetails
    FullName: '',
    Mobile: '',
    Email: '',
    Dlnum: '',
    Tablename: '',
    Handset: '',
    Cbid:'',
    Address: '',
    Regnum:'',
    Calibrationdate: "",
    Dealer: "",
    //end
    editedIndex: -1,
    editedIndex2: -1,
    paydialog:false,
    row: false,
    row1: false,
    row2: false,
    types:"",
    csvdata: [],
    rawData:[],
    imageData:[],
    clientLogs:[],
    pimage:'',
    viewtext:"View All",
    items:['Type 0', 'Type 1', 'Type 2', 'Type 3'],
    parsed: false,
    fullname:null,
    isdatauploading:false,
    isUpdating: false,
    imagebyts:null,
    statistics:{
      alllogs:0,
      pass:0,
      fails:0,
      bypass:0,
      total:0,
      disconnect:0

    },
    resultQuery:[],
     paymentItem: {
        clienthome:"",
        clientwork:"",
        id: "",
        manufacturer: "Alcobrake",
        isinstalledandworking: "1",
        driverisincompliance: "1",
        officeId: "",
        dob: "",
        client: "",
        clientId: "",
        sno: "",
        isunavailablevehicle: "0",
        dealer: "",
        clientphone1: "",
        isresidsame: "1",
        isbar: "",
        officelicensenumber: "",
        installer: "",
        licensefile:"",
        ticketfile:"",
        imobile: "",
        clientphone2: "",
        suffix: "",
        email: "",
        clientAddress: "",
        space: "",
        city: "",
        state: "",
        zipcode: "",
        rclientAddress: "",
        rspace: "",
        rcity: "",
        rstate: "",
        rzipcode: "",
        regdate: "",
        regnum: "",
        uregnum: "",
        device: "",
        installDate: "",
        calibrationDate: "",
        nextcmonitordate: "",
        make: "",
        blink:false,
        umake: "",
        model: "",
        year: "",
        uyear: "",
        color: "",
        vin: "",
        uvin: "",
        tag: "",
        tagstate: "",
        dlnum: "",
        prohibitionnumber: "",
        prohibstart: "",
        prohibend: "",
        conviction: "",
        calibrationperiod: "",
        timestamp: "",
        editlicensefile:"",
      },
    headers: [
       {text: "Id",value: "id"},
       {text: "Control Id ",value: "ControlBoxNum"},
       {text: "Handset",value: "HandsetNum"},
       {text: "Result ",value: "Resoult"},
       {text: "Alcounit",value: "Alcounit"},
       {text: "Test Time",value: "TestTime"},
       {text: "Event",value: "DataType"},
       {text: "Calibration Time",value: "CalibrationTime"},
    ],
    clientheader: [
       {text: "Id",value: "id"},
       {text: "Control Id ",value: "ControlBoxNum"},
       {text: "Handset",value: "HandsetNum"},
       {text: "Result ",value: "Resoult"},
       {text: "Alcounit",value: "Alcounit"},
       {text: "Test Time",value: "TestTime"},
       {text: "Event",value: "DataType"},
       {text: "Calibration Time",value: "CalibrationTime"},
       {text: "Image",value: "image"},
    ],
      clientsheaders: [
      //  {text: "Id",value: "id"},
       {text: "FullName ",value: "FullName"},
       {text: "Mobile",value: "Mobile"},
       {text: "Email ",value: "Email"},
       {text: "Cbid",value: "Cbid"},
       {text: "Handset",value: "Handset"},
       {text: "Dlnum",value: "Dlnum"},
        { text: "Tablename", value: "Tablename" },
        { text: "Edit", value: "edit" },
       {text: "Delete",value: "delete"},
      ],
      invoiceservices:  [
        { servicetype: "NoCost", rate: "0.00" },
        { servicetype: "New-Installation", rate: "150.00" },
        { servicetype: "Re-install/Swap Device", rate: "100.00" },
        { servicetype: "Removal", rate: "50.00" },
        { servicetype: "Monitoring Fee Monthly", rate: "75.00" },
        { servicetype: "Calibration Fee", rate: "35.00" },
        { servicetype: "Violation Reset Fee", rate: "35.00" },
        { servicetype: "Late Fee", rate: "30.00" },
        { servicetype: "Device Lock/Unlock Fee", rate: "75.00" },
        { servicetype: "Others1", rate: "0.00" },
        { servicetype: "Others2", rate: "0.00" },
      ],
    // pk:"pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
       pk: process.env.VUE_APP_STRIPE_KEY,
      elementsOptions: {
        appearance: {},
        clientsecret:'pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt' // appearance options
      },
    paymentdialog: false,
    singlepayment:null,
    allpayments:null,
    myservices:'',
    selectedServices:[],
    payind1:null,
      addInfo: {
        client: null,
        date: "",
        service: [],
        qty: '',
        billnum: '',
        amounts: '',
        balance: "0.00",
        grandtotal: "0.00",
        status: 'unpaid',
        amtpaid: "0.00",
        paymentindent: '',
      },
      addInfo2: {
        client: null,
        date: "",
        items: "",
        qty: '',
        billnum: '',
        amounts: '',
        balance: "0.00",
        grandtotal: "0.00",
        status: 'unpaid',
        amtpaid: "0.00",
        paymentindent: '',
      },
    results: [],
    // clientsdata: [],
    paymentdata:[],
    fullname: [(v) => !!v || "FullName is required"],
    status: [(v) => !!v || "Status is required"],
    statuss:['paid','unpaid'],
    billnum: [(v) => !!v || "Bill Number is required"],
    client: [(v) => !!v || "Client is required"],
    qty: [(v) => !!v ||    "Quntity is required"],
    services: [(v) => !!v || "Servies is required"],
    amounts: [(v) => !!v || "Amounts is required"],
    total: [(v) => !!v || "Total is required"],
    ruleamtpaid: [
      (v) => !!v || "Amount paid is required"
    // v => !!v || "Amount paid is required",
    // v => ( v && v >= 10 ) || "pay should be above 40rs",
    // v => ( v && v <= 50 ) || "Max should not be above 50rs",
    ],
    mobile: [
      (v) => !!v || "Mobile Required",
        (v) =>
          !v ||
          (v && v.length == 10) ||
          v.length == 12 ||
          "Enter valid Mobile number",
    ],
     mobile2: [
      (v) => !!v || "Mobile Required"
    ],
    handset: [
      (v) => !!v || "Handset Required"
    ],
    cbid: [
      (v) => !!v || "Cbid Required"
    ],
    calibration: [
      (v) => !!v || "Calibration Required"
    ],
    tablename: [
      (v) => !!v || "Table Name Required"
    ],
    email: [(v) => !!v || "Email is required"],
    dlnum: [(v) => !!v || "Dlnum is required"],
    address: [(v) => !!v || "Address is required"],
    regnum: [(v) => !!v || "Regnum is required"],

  }),
  components: {
    StripeElementPayment,
    StripeElementCard,
    VueGPicker,
    // GDriveSelector
  },

  watch: {
    selection(val){
      if(val == 0){
        this.resultQuery =  this.clientLogs;
      } else if(val == 1){
        // this.search == "Passed"
        this.resultQuery =  this.clientLogs.filter((item) => {
           return item.DataType.includes("Passed");
        })
      } else if(val == 2){
        // this.search == "Passed"
        this.resultQuery =  this.clientLogs.filter((item) => {
           return item.DataType.includes("Failed");
        })
      } else if(val == 3){
        // this.search == "Passed"
        this.resultQuery =  this.clientLogs.filter((item) => {
           return (item.DataType.includes("Passed") ||item.DataType.includes("Failed"));
        })
      }else if(val == 4){
        // this.search == "Passed"
        this.resultQuery =  this.clientLogs.filter((item) => {
           return item.DataType.includes("Free Start");
        })
      }  else if(val == 5){
        // this.search == "Passed"
        this.resultQuery =  this.clientLogs.filter((item) => {
           return item.DataType.includes("Handset Control Box Disconnect");
        })
      } else if(val == undefined){
        this.selection = 0;
        this.resultQuery =  this.clientLogs;
        setTimeout(()=>{
          this.selection =0;
        },2000)
        
      } else{
        this.selection = 0;
        this.resultQuery =  this.clientLogs;
      }
    },
     uploadPercentage:function(val){
      if (val == 100 || val == undefined || val == 0) {
        this.uploadgifdialog=false;
      } else{
        this.uploadgifdialog = true;
        this.count = val;
      }
    },
    paymentdialog(val){
      this.paymentdialog = val
    },
    isUpdating (val) {
        if (val) {
          setTimeout(() => (this.isUpdating = false), 3000)
        }
      },
     countrycode(val) {
      this.countrycode = val;
      setTimeout(() => {
        this.countrycode = 'us'
      },500)
    },
  },

  computed: {
    ...mapGetters(['kvclients']),
    // resultQuery() {
    //   if (this.search) {
    //     return this.clientLogs.filter((item) => {
    //       return item.DataType.includes(this.search);
    //     });
    //   } else {
    //     return this.clientLogs;
    //   }
    
    // },
     formTitle() {
      return this.editedIndex === -1 ? "Add Client" : "Edit Client";
    },
    Titlepayment() {
      return this.editedIndex2 === -1 ? "Add Payment" : "Edit Payment";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "PAY ONLINE" : "UPDATE";
    },
     laterTitle() {
      return this.editedIndex2 === -1 ? "PAY BY CASH" : "UPDATE";
    },
    buttonTitle2() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
  },
beforeMount(){
  var filesresp,imagesresp
    if(this.user.role=="uploader" && localStorage.getItem("isDrivesync")=='false'){
      this.fetchdialog = true;
      this.datafetch = "DATA FETCHING PLEASE WAIT"
                Vue.axios.get('https://robopower.xyz/v2/kvclients/checksync?client=' +this.user.dealerId).then((res) => {
                  if(res.data ==true){
                   this.synctext = "Drive Connected Syncing data Please Wait"
                   var rePromise = new Promise((resolve,reject)=>{

                    Vue.axios.get('https://alcobraketest.in/'+this.user.emailId+'/file').then((resp) => {
                      localStorage.setItem("isDrivesync", true);
                      filesresp = resp.data
                      if(resp.data == "Failed to Authorise" || resp.data == null || resp.data == "No credentials found"){
                        this.fetchdialog = false;
                          const Swal = require("sweetalert2");
                        Swal.fire({
                          title: "Data Updloading Failed due to "+resp.data,
                          icon: "error",
                          confirmButtonText: "Ok",
                        });
                        
                       reject('Data Not Processed Completely')
                                              
                      } else{
                        this.fetchdialog = false;
                          //  const Swal = require("sweetalert2");
                          //   Swal.fire({
                          //     title: "Data Updated Successfully",
                          //     text: this.user.dealerId,
                          //     icon: "success",
                          //     confirmButtonText: "Ok",
                          //   });
                            resolve({'status':'ok','client':this.user.dealerId})

                      }
                      // if(resp.status == 200){
                      //    const Swal = require("sweetalert2");
                      //       Swal.fire({
                      //         title: "Data Updated Successfully",
                      //         text: this.user.dealerId +" "+ "Data" +' '+  resp.data,
                      //         icon: "success",
                      //         confirmButtonText: "Ok",
                      //       });
                            
                      // } else{
                      //   const Swal = require("sweetalert2");
                      //   Swal.fire({
                      //     title: "Data Updloading Failed",
                      //     icon: "error",
                      //     confirmButtonText: "Ok",
                      //   });
                       
                      // }
                    })
                  })
                  rePromise.then((res)=>{
                    this.fetchdialog = true;
                    this.datafetch = "IMAGES UPLOADING PLEASE WAIT"
                    Vue.axios.get('https://alcobraketest.in/' +this.user.emailId+'/images').then((rem) => {
                      if(rem.status == 200){
                        imagesresp = rem.data
                        var imresp = JSON.parse(imagesresp)
                        var fileresp = JSON.parse(filesresp)
                        const Swal = require("sweetalert2");
                            Swal.fire({
                              title: "Total Files"+fileresp.Total_files +"Processed Files"+fileresp.Files_processed+"Total Images"+imresp.Total_images +"Processed Images "+imresp.Images_processed,
                              text: this.user.dealerId,
                              icon: "success",
                              confirmButtonText: "Ok",
                            });

                      this.fetchdialog = false;
                      }
                    })
                  })
                  } else{
                    this.fetchdialog = false;
                    this.$store.dispatch("alert/success", "Drive Details Not Found").then(()=>{
                  setTimeout(()=>{
                    this.$store.dispatch("alert/clear")
                  },2000)
                  })
                this.drivefiledialog = true
                  }                    
                })
              //end
            } else{
              this.$router.push("filetranslator");
            }     
},
  mounted() {
    this.allData();
   
  },

  methods: {

    
    payDue(item,type){
      if(type=='single'){
                this.addInfo2.client = item.client,
        this.addInfo2.items = item.items,
        this.addInfo2.qty= item.qty,
        this.addInfo2.billnum = item.billnum,
        this.addInfo2.amounts = item.amounts,
        this.addInfo2.balance =parseFloat(item.balance).toFixed(2).toString(),
        this.addInfo2.grandtotal = item.total,
        this.addInfo2.status = item.status,
        this.addInfo2.amtpaid = parseFloat(item.balance).toFixed(2).toString();
        this.singlepayment = true;
        this.generatePaymentIntent2(parseFloat(item.balance).toFixed(2).toString())
      } else if(type=='all'){
        this.allpayments = true;
        this.generatePaymentIntent3(item,type)
      } else{
        this.singlepayment = null;
        this.singlepayment = null;
      }
      
    },
    updatejsonfile(){
    const driveformData = new FormData();
      driveformData.append("file", this.files);
      driveformData.append("client", this.user.dealerId);
         Vue.axios
      .post("https://robopower.xyz/v2/kvclients/jsonupload", driveformData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          if (this.files != [])
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
        }.bind(this)
      })
      .then(() => {
        this.$store.dispatch("alert/success", "SERVICE FILE UPLOADED SUCCESS").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'SERVICE FILE UPDATED',
          icon: 'success',
          showCloseButton: true,
        }).then((result)=>{
          if(result){
          this.fetchdialog = true
          var filesresp
          var imagesresp
          // setTimeout(()=>{
            if(this.user.role=="uploader" && localStorage.getItem("isDrivesync")=='false'){
      this.fetchdialog = true;
      this.datafetch = "DATA FETCHING PLEASE WAIT"
                Vue.axios.get('https://robopower.xyz/v2/kvclients/checksync?client=' +this.user.dealerId).then((res) => {
                  if(res.data ==true){
                   this.synctext = "Drive Connected Syncing data Please Wait"
                   var rePromise = new Promise((resolve,reject)=>{

                    Vue.axios.get('https://alcobraketest.in/' + this.user.emailId+'/file').then((resp) => {
                      localStorage.setItem("isDrivesync", true);
                       filesresp = resp.data
                    //  if(reply[0].servicefile && reply[0].isfolder && reply[0].isfiles && reply[0].isdataupdated){
                      if(resp.data != null || resp.data != "Failed to Authorise"){
                        this.fetchdialog = false;
                          //  const Swal = require("sweetalert2");
                          //   Swal.fire({
                          //     title: "Data Updated Successfully",
                          //     text: this.user.dealerId,
                          //     icon: "success",
                          //     confirmButtonText: "Ok",
                          //   });
                            resolve({'status':'ok','client':this.user.dealerId})
                                              
                      } else{
                        this.fetchdialog = false;
                          const Swal = require("sweetalert2");
                        Swal.fire({
                          title: "Data Updloading Failed",
                          icon: "error",
                          confirmButtonText: "Ok",
                        });
                        // }
                        
                       reject('Data Not Processed Completely')
                      }
                    })
                  })
                  rePromise.then((res)=>{
                    this.fetchdialog = true;
                    this.datafetch = "IMAGES UPLOADING PLEASE WAIT"
                  Vue.axios.get('https://alcobraketest.in/' +this.user.emailId+'/images').then((rem) => {
                      if(rem.status == 200){
                        imagesresp = rem.data;
                        var imresp = JSON.parse(imagesresp)
                        var fileresp = JSON.parse(filesresp)
                        const Swal = require("sweetalert2");
                            Swal.fire({
                              title: "Total Files"+fileresp.Total_files +"Processed Files"+fileresp.Files_processed+"Total Images"+imresp.Total_images +"Processed Images "+imresp.Images_processed,
                              text: this.user.dealerId,
                              icon: "success",
                              confirmButtonText: "Ok",
                            });

                      this.fetchdialog = false;
                      }
                    })
                  })
                  } else{
                    this.fetchdialog = false;
                    this.$store.dispatch("alert/success", "Drive Details Not Found").then(()=>{
                  setTimeout(()=>{
                    this.$store.dispatch("alert/clear")
                  },2000)
                  })
                this.drivefiledialog = true
                  }                    
                })
              //end
            } else{
            this.$router.push("filetanslator");
            }
          // },5000)
          }
        })
        this.jsonclose();
      })
    },
    jsonclose(){
      this.drivefiledialog = false;
      this.files = []
      this.uploadPercentage = 0;
    },
     selectFile(file) {
      if (file != undefined || file != "" || file != null) {
        this.files = file;
      }
    },
      IsNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onChange (data) {
      window.console.log('on change:', data)
    },
    onAuthenticated (token) {
      window.console.log('oauth token:', token)
    },
    editpayment(item){
        // console.log(item.items)
        this.editedIndex2 = Object.assign({}, item);  
        this.addInfo.client = item.client,
        this.myservices = item.items,
        this.addInfo.qty= item.qty,
        this.addInfo.billnum = item.billnum,
        this.addInfo.amounts = item.amounts,
        this.addInfo.balance =item.balance,
        this.addInfo.grandtotal = item.total,
        this.addInfo.status = item.status,
        this.addInfo.amtpaid = item.amtpaid,
        this.paymentdialog = true
    },
    async generatePaymentIntent3(item,type) {
      if(type=='all') {
      this.fullname = item.FullName;
      this.totalclear = parseFloat(item.balance).toFixed(2);
      var amt = (parseFloat(item.balance).toFixed(2)).toString();
      amt = amt.replace(/\./g, "");
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          var that = this;
          this.payind1 = response.data.id;
          that.elementsOptions.clientSecret = response.data.client_secret;
          this.iscreated3 = true;
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
          }
    },
    async generatePaymentIntent2(amount) {
      var amt = amount.toString();
      amt = amt.replace(/\./g, "");
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          var that = this;
          this.payind1 = response.data.id;
          that.elementsOptions.clientSecret = response.data.client_secret;
          this.iscreated2 = true;
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    async generatePaymentIntent(amount) {
      // console.log(amount)
      var amt = amount.toString();
      amt = amt.replace(/\./g, "");
      // console.log(amt)
      await Vue.axios
        .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount=" + amt)
        .then((response) => {
          var that = this;
          this.payind1 = response.data.id;
          that.elementsOptions.clientSecret = response.data.client_secret;
          this.iscreated = true;
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
   async Viewimage(item){
      this.loading = true
      this.imagedata = item
      var time = item.TestTime.replaceAll('.', "")
      time = time.replaceAll(' ', "-")
      time = time.replaceAll(':', "-")
       if(this.imagedata != null){
       const response = await fetch('https://robopower.xyz/v2/kvgetimages/getimage?handset='+item.HandsetNum+"&eventtime="+time)
  const blob = await response.blob()
  if(blob.type != ''){
    this.imgdialog = true
    this.loading = false
  const url = URL.createObjectURL(blob)
  this.imagebyts =url
  }else{
    this.imagebyts = ''
    this.loading = false
  this.$store.dispatch("alert/error", "Image Not Found").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
  }
      }else{
        console.log("error")
      }
    },
    clearimg(){
this.imgdialog = false
this.imagebyts = null
    },
    updateService(item) {
      var tamount = 0
      var amts = ''
      var servs = ''
      this.addInfo.qty = item.length
      if(item.length >0){
      item.map((res)=>{
        amts +=res.rate+","
        tamount += parseFloat(res.rate)
        servs += res.servicetype +"," 
        this.addInfo.grandtotal = tamount
        this.addInfo.amounts = amts       
        this.myservices = servs
        this.addInfo.balance =  (tamount - parseFloat(this.addInfo.amtpaid)).toString()
      })
    }
    },
    updateamt(item) {
        this.addInfo.balance = parseFloat(this.addInfo.grandtotal) - parseFloat(item)
    },
    addpayment() {
      this.paymentdialog = true
      this.addInfo.billnum= "AB2022"+(this.paymentdata.length+1).toString().padStart(4, '0')
    },
    reserve () {
        // this.loading = true
        this.viewall = !this.viewall;
        this.viewtext=this.viewall==true?"Collapse All":"View All"

      },
      clientclose(){
        this.FullName= '',
    this.Mobile= '',
    this.Email= '',
    this.Dlnum= '',
    this.Tablename= '',
    this.Handset= '',
    this.Cbid='',
    this.Address= '',
    this.Regnum='',
    this.Calibrationdate= "",
    this.Dealer= "",
        this.clientLogs = []
        this.cimage = '';
        this. clientimagefile = '';
        this.isSingle=!this.isSingle;
        this.viewall = false;
      this.viewtext="View All" 
      this.statistics.alllogs=0
      this.statistics.fails = 0
      this.statistics.pass = 0
      this.statistics.bypass = 0
      this.statistics.disconnect= 0
      this.statistics.total= 0
      this.editedIndex = -1
      },
    onClientview(item){
      Vue.axios.get('https://robopower.xyz/app/getImage/getlicense?client='+item.FullName).then((res)=>{
        if(res.data){
        this.cimage = 'https://robopower.xyz/app/getImage/getlicense?client='+item.FullName
        }else{
          this.$store.dispatch("alert/success","NO CLIENT IMAGE").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      })
      
      this.singlerow = item;
      this.isSingle = false;
      Vue.axios.get("https://robopower.xyz/v2/readcsv/getdevlogs?cbid="+item.Cbid+"&handset="+item.Handset+"&tablename="+item.Tablename).then((res) => {
        if(res.data !=null){
          this.clientLogs = res.data;
          this.resultQuery = res.data;
          this.statistics.alllogs=this.clientLogs.length
          var done = this.clientLogs.find((obj)=>{
            if(obj.DataType =="Passed"){
              this.statistics.pass += 1
            } else if(obj.DataType =="Failed"){
              this.statistics.fails += 1
            } else if(obj.DataType =="Free Start"){
              this.statistics.bypass += 1
            } else if(obj.DataType =="Handset Control Box Disconnect"){
              this.statistics.disconnect += 1
            }
            
          })
          this.statistics.total = this.statistics.pass + this.statistics.fails
          this.loading = false
        } else{
           this.$store.dispatch("alert/success","NO LOGS").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      })
    },
    onPaymentview(item){
    //   Vue.axios.get('https://robopower.xyz/app/getImage/getlicense?client='+item.client).then((res)=>{
    //     if(res.data){
    //     this.pimage = 'https://robopower.xyz/app/getImage/getlicense?client='+item.client
    //     }else{
    //       this.$store.dispatch("alert/success","NO CLIENT IMAGE").then(()=>{
    //         this.pimage = ''
    // setTimeout(()=>{
    //   this.$store.dispatch("alert/clear")
    //   this.payclose()
    // },2000)
    // })
    //     }
    //   })
      this.paymentrow = item;
      this.isPayment = false;
    },
    onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
        onChangePayment(pageOfItem) {
            this.pageOfItem = pageOfItem;
    },
    paymentloadFormData(status) {
      var pdate =  moment(new Date()).format(
              "MM/DD/YYYY"
            );
            console.log(pdate)
      const paymentformData = new FormData();
      paymentformData.append("billnum", this.addInfo.billnum);
      paymentformData.append("client", this.addInfo.client);
      paymentformData.append("qty", this.addInfo.qty);
      paymentformData.append("items", this.myservices);
      paymentformData.append("amounts", this.addInfo.amounts);
      paymentformData.append("total", this.addInfo.grandtotal);
      paymentformData.append("amtpaid", this.addInfo.amtpaid);
      paymentformData.append("balance", this.addInfo.balance);
      paymentformData.append("status", status);
      paymentformData.append("paymentindent", this.payind1);
      paymentformData.append("paytime", pdate);
      return paymentformData;
    },
    paymentsave() {
      if (!this.$refs.paymentform.validate()) {
        return;
      }
      this.paydialog = true
      this.paymentdialog = false
      this.generatePaymentIntent(this.addInfo.amtpaid)
    },
    unpaidpaymentsave() {
       this.paymentdialog = false
      if (!this.$refs.paymentform.validate()) {
        return;
      }
      if(this.editedIndex2 == -1){
       var fdpayment;
        if(this.addInfo.balance == 0){
        fdpayment = this.paymentloadFormData('paid')
        }else{
          fdpayment = this.paymentloadFormData('unpaid')
        }
      Vue.axios.post('https://robopower.xyz/v2/kvpayments/addpayment', fdpayment).then(() => {
        this.allData()
        this.$refs.paymentform.reset()
        this.$store.dispatch("alert/success","PAY lATER").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      })
      }else{
        var fdpayment;
        if(this.addInfo.balance == 0){
        fdpayment = this.paymentloadFormData('paid')
        }else{
          fdpayment = this.paymentloadFormData('unpaid')
        }
        Vue.axios.post('https://robopower.xyz/v2/kvpayments/editpayment/'+this.paymentrow.id,fdpayment ).then(()=>{
          this.$store.dispatch("alert/success","PAYMENT EDITED").then(()=>{
            this.allData()
            this.isPayment = !this.isPayment
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
      
    },2000)
    })
        })
      }
      this.payclose()
    },
     allData() {
      // console.log(this.user)
      this.$store.dispatch('fetchkvClients',this.user.dealerId);
    Vue.axios.get("https://robopower.xyz/v2/kvpayments/getallpayments").then((res) => {
      if (res.data != null) {
        this.paymentdata = res.data
      } else {
        alert("No Payments Found")
      }

      })
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("FullName", this.FullName);
      formData.append("Mobile", this.Zmobile + this.Mobile);
      formData.append("Email", this.Email);
      formData.append("Cbid", this.Cbid);
      formData.append("Handset", this.Handset);
      formData.append("Dlnum", this.Dlnum);
      formData.append("Tablename", this.Tablename);
      formData.append("regnum", this.Regnum);
      formData.append("address", this.Address);
      formData.append("calibrationdate", this.Calibrationdate);
      formData.append("dealer", this.user.dealerId);
      return formData;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData()
      if (this.editedIndex == -1) {
        this.$store.dispatch('createKvclient',fd);
      } else {
        var postdata ={
          devdata:fd,
          id:this.Id
        }
        this.$store.dispatch('editKvclient',postdata);
    //     Vue.axios.post('https://robopower.xyz/v2/kvclients/editclient/'+this.Id, fd).then(() => {
    //        this.$store.dispatch("alert/success","successfully Updated").then(()=>{
    // setTimeout(()=>{
    //   this.$store.dispatch("alert/clear")
    // },2000)
    // })
    //     })
      }
      // this.allData();
      this.close()
    },
    close() {
      this.dialog = false
      this.editedIndex = -1;
      this.viewall= false;
      this.$refs.form.reset()
    },
    handleImagesUpload(event){
      document.getElementById("load").style.display = "block";
      this.imagefile = event.target.files[0]
       var imgData = new FormData()
      imgData.append('file',this.imagefile)
      imgData.append('handset',this.Handset)
      Vue.axios.post('https://robopower.xyz/v2/kvimages/kvimagesupload',imgData).then((res)=>{
        if(res.status = 200){
            this.$store.dispatch("alert/success",res.data).then(()=>{
              document.getElementById("load").style.display = "none";
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }else{
          this.$store.dispatch("alert/error","error").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      })
    },
    clearimages(){
      this.imageData = [];
      document.getElementById("imgFile").value = "";
    },
    handleFileUpload(event) {
      this.isdatauploading = true;
      this.file = event.target.files[0];
      var fData = new FormData()
      fData.append('file',event.target.files[0])
      Vue.axios.post("https://robopower.xyz/v2/readcsv/createtyperow",fData).then((res)=>{
        if(res.data != null){
          if(res.data == "Not able to access file"){
            alert(res.data)
          } else{
          this.isdatauploading = false;
          var myresp = JSON.stringify(res.data)
          myresp = JSON.parse(myresp)
          Vue.axios.get("https://robopower.xyz/v2/readcsv/getdevlogs?cbid="+myresp.cbid+"&handset="+myresp.handset+"&tablename="+myresp.tablename).then((res)=>{
            if(res.data != null){
              this.rawData = res.data;
               this.Cbid = myresp.cbid
          this.Handset = myresp.handset
          this.Tablename = myresp.tablename
               this.$store.dispatch("alert/success","Data Uploaded successfully").then(()=>{
                document.getElementById("load").style.display = "none"; 
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            } else{
              alert("No Data Found")
            }

          })
          }
        }
      })

    },
     clear() {
        this.csvdata = [];
        document.getElementById("csvFile").value = "";
    },
    addclient() {
      // console.log(this.editedIndex)
      this.dialog = true
    },
    pay(){
      const that = this;
      that.$refs.paymentRef.submit();
    },
    cancelPaymentindent(pid){
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid="+this.user.dealerId+"&intent=" +
            this.payind1
        )
        .then((res) => {
          if(res.status==200){
              this.$store.dispatch("alert/error", "Payment Cancelled").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    paycloseall(){
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      this.cancelPaymentindent(this.payind1)
      this.pimage = '';
        this.editedIndex2 = -1;
        this.allpayments=false;
        this.fullname = null;
    },
    payclosesingle(){
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      this.cancelPaymentindent(this.payind1)
      this.pimage = '';
        this.singlepayment = false
        this.editedIndex2 = -1
        // this.$refs.paymentform.reset();
      this.addInfo.billnum = "";
    this.addInfo.client = "";
      this.addInfo.qty="";
      this.myservices="";
      this.addInfo.amounts="";
     this.addInfo.grandtotal="0.00";
      this.addInfo.amtpaid="0.00";
      this.addInfo.balance="0.00";
      this.addInfo.status="";
    },
    payclose(){
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      this.cancelPaymentindent(this.payind1)
      this.pimage = '';
        this.paydialog = false
        this.paymentdialog = false;
        this.editedIndex2 = -1
        // this.$refs.paymentform.reset();
      this.addInfo.billnum = "";
    this.addInfo.client = "";
      this.addInfo.qty="";
      this.addInfo.service=[];
      this.myservices="";
      this.addInfo.amounts="";
     this.addInfo.grandtotal="0.00";
      this.addInfo.amtpaid="0.00";
      this.addInfo.balance="0.00";
      this.addInfo.status="";


    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    editclient(item) {
      // console.log(item)
      this.editedIndex = this.kvclients.indexOf(item);
      this.Id = item.id;
      this.FullName = item.FullName
      this.Cbid = item.Cbid;
      this.Dlnum = item.Dlnum;
      this.Handset = item.Handset;
      this.Mobile = item.Mobile;
      this.Email = item.Email;
      this.Tablename = item.Tablename;
      this.Regnum = item.regnum;
      this.Address = item.address;
      this.Calibrationdate = item.calibrationdate;
      this.Dealer = item.dealer;
      // console.log(this.editedIndex)
      this.dialog = true
    },
     uploadclientImage(e){
      const fileimage = e.target.files[0]
      var clientname = new File([fileimage], "client" + ".jpg",{type: "data:text/image;charset=utf-8" })
      this.clientimagefile = clientname
    },
    updateclient(){
        this.loading = true;
       const formData = new FormData();
       formData.append("client", this.singlerow.FullName);
        formData.append("licensefile",this.clientimagefile);
      Vue.axios.post('https://robopower.xyz/app/getImage/updatelicense' , formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(()=>{
this.$store.dispatch("alert/success", "New Client Photo Added").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    }) 
      this.loading = false;
      this.clientimagefile = ''
      })
    },
    onSelect2({dialCode }) {
      this.Zmobile = dialCode;
    },
    clearcsv() {
      this.rawData = [];
      document.getElementById("csvFile").value = "";
    },
    deleteclient(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want To Delete This Client - " + item.FullName,
        text: "You Can't Revert Your Action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          Vue.axios.get('https://robopower.xyz/v2/kvclients/deleteclient/' + item.id).then(() => {
            this.allData()
         this.$store.dispatch("alert/success","Client successfully deleted").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      })
        } else {
          console.log("Cancelled")
        }
        
      })
      
    }
  },
      
}
</script>
<template>
  <v-container fluid>
    <v-dialog v-model="fetchdialog" max-width="500px">
           <h2 class="blinking" style="text-align: center;font-weight:900">{{datafetch }}</h2>
      </v-dialog>
    <v-dialog v-model="drivefiledialog" max-width="750px" @click:outside="close">
        <v-card class="pa-3">
        <v-toolbar color="green" dark flat>
      <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">.JSON FILE</v-toolbar-title>
      <v-toolbar-title v-else style="font-size:17px">Update Google Drive Service File</v-toolbar-title>
    </v-toolbar>
            <v-form
              autocomplete="off"
              id="form"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-container class="py-0">
                <v-row justify="center">
                    <v-col cols="12" md="4">
                    <v-file-input
                      type="file" 
                      accept="application/JSON"
                      show-size
                      v-model="files"
                      label="Upload File"
                      required
                      @change="selectFile"
                    ></v-file-input>
                    <progress
                      max="100"
                      :value.prop="uploadPercentage"
                    ></progress>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      class="purple-input"
                      label="Client"
                      required
                      disabled
                      v-model="user.dealerId"
                    /> </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn color="success" class="mr-0" @click="jsonclose">
                     Proceed Without G_drive
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="success"
                      class="mr-0"
                      @click="updatejsonfile"
                    >
                      Proceed
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="allpayments" max-width="800px">
            <v-card class="elevation-12">
          <v-toolbar text color="#26BB44" class="" dark dense>
              <v-row>
                    <v-toolbar-title>You are paying the amount ${{this.totalclear}}</v-toolbar-title>
                    <v-layout justify-end>
                 <v-btn color="primary" @click="paycloseall" dark v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                      >Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="paycloseall" > mdi-close </v-icon>
                </v-layout>
              </v-row>
                  </v-toolbar>
                  &nbsp;
                  <div class="container">
        <stripe-element-payment
        v-if="iscreated3"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="{return_url: `https://alcobrakeadmin.com/gdrivesuccesstxn?clientid=`+this.fullname+'&paymentid='+this.payind1+`&clearall=true`}"
    />
    <br/>
    <v-layout justify-end>
      <!-- dialog paynow -->
    <v-btn class="primary" left @click="pay">Pay Now</v-btn>
    </v-layout>
                  </div>
            </v-card>
              </v-dialog>
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
            v-for="item in tabitems"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">

          <!-- first tab -->
          <!-- <v-tab-item>
            <div id="app">
     <GDriveSelector />
     <v-btn>yes</v-btn>

  </div>
          </v-tab-item> -->
<!-- <v-tab-item>
  <div>
    <VueGPicker
      :clientId="'297852850008-j16c8dqkdth890dmqm7s1dqrtia134ij.apps.googleusercontent.com'"
      :developerKey="'AIzaSyCDqoXOWYlrCtP4KI-dt_Ty1OTrVHJG5n0'"
      :scope="['https://www.googleapis.com/auth/drive.readonly']"
      @change="onChange"
      @authenticated="onAuthenticated"
      :multiselect="true"
      :navHidden="false"
      :authImmediate="false"
      :upload="true"
      :mimeTypes="['image/png', 'image/jpeg', 'image/jpg']"
      :viewId="'DOCS'">
      <button>VueGPicker</button>
    </VueGPicker>
  </div>
</v-tab-item> -->
      <v-tab-item>
        <v-dialog v-model="dialog" max-width="600px">
                <v-card>
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                    autocomplete="off"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                           <v-col cols="12" sm="12" md="6">
                             <v-text-field
                             :disabled="editedIndex==-1?false:true"
                            dense
                            required
                            :rules="fullname"
                            v-model="FullName"
                            label="FullName*"
                          ></v-text-field>
                          </v-col>
                          
                          <div class="left-btn" v-if="formTitle =='Add Client'">
                  <vue-country-code
                      @onSelect="onSelect2"
                      :enabledCountryCode="true"
                      :defaultCountry='countrycode'
                      style="border:none"
                      :preferredCountries="['in', 'us', 'gb']"
                    >
    <input type="text" v-model="Zmobile" name="phone" id="phone" placeholder="Mobile" />
  </vue-country-code>
                        </div>
                        &nbsp;
                        <div class="left-btn" v-if="formTitle =='Add Client'">
                            <v-text-field
                            style="width:100%"
                           dense
                            required
                            :rules="mobile"
                            v-on:keypress="IsNumber($event)"
                            v-model="Mobile"
                            label="Mobile*"
                            class="textfeild"
                          ></v-text-field>
                      </div>
                      <v-col v-else>
                            <v-text-field
                            v-on:keypress="isLetter($event)"
                              dense
                              v-model="Mobile"
                              required
                              :rules="mobile2"
                              label="Mobile*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Email"
                              required
                              :rules="email"
                              label="Email*"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                            v-on:keypress="isLetter($event)"
                              dense
                              v-model="Dlnum"
                              required
                              :rules="dlnum"
                              label="Dlnum*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Regnum"
                              required
                              :rules="regnum"
                              label="Registation Number*"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Address"
                              required
                              :rules="address"
                              label="Address*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Handset"
                              required
                              :rules="handset"
                              label="Handset*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Cbid"
                              required
                              :rules="cbid"
                              label="Cbid*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Tablename"
                              required
                              :rules="tablename"
                              label="Tablename*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="Calibrationdate"
                              required
                              :rules="calibration"
                              label="Calibration Date*"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!valid"
                      @click="save"
                      >{{buttonTitle2}}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            <v-col cols="11">
      <v-row class="justify-space-between">
    <input type="file" id="csvFile" name="csvFile" accept=".csv" @click="clearcsv" @change="handleFileUpload( $event )" />
    
    &nbsp;&nbsp;
    <v-row  v-if="rawData.length >0" justify="end" >
      <div>
    <a @click="addclient()" style="text-decoration: underline;">click here</a> to add client details
    &nbsp;&nbsp;&nbsp;&nbsp;
    </div>
      <label for="imgFile">Upload Images Collection(.zip):</label>
      <div class="loader" id="load"></div>
      <input type="file" id="imgFile" name="imageFile"  accept=".zip" @click="clearimages" @change="handleImagesUpload( $event )" />
    <v-btn class="justify-space-between primary" @click="clearcsv" >Reset</v-btn>
  </v-row>
        </v-row>
      </v-col>
      <div v-if="isdatauploading && rawData.length<=0" class="text-center">
      <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    </div>
  <v-data-table
  v-else-if="!isdatauploading && rawData.length>0"
        dense
        :headers="headers"
        :items="rawData"
        :search="search2"
        class="elevation-1"
      >
      </v-data-table>
      <div v-else>
        <h3>Upload only .csv file here</h3>
      </div>
      </v-tab-item>

      <!-- second tab -->

      <v-tab-item>
        
        <v-dialog v-model="imgdialog" max-width="600px">
                <v-card>
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>IMAGE</v-toolbar-title>
                    <v-row>
          <v-col cols="12" mx="4">
           <v-layout justify-end>
                 <v-btn size="40px"  color="primary" @click="clearimg">
                  Close</v-btn>
             
           </v-layout>
              </v-col>
                  </v-row>
                  </v-toolbar>
                  <img :src="imagebyts" width="590px" height="400px" style="margin-left:5px"/>
                </v-card>
              </v-dialog>
               <v-dialog v-model="loading" max-width="170px">
      <v-card style="background-color:#26BB44;color: azure;">
     <v-card-title class="loading">{{imagetext}}</v-card-title>
     </v-card>
    </v-dialog>
    <v-col class="text-right">
  <v-btn class="primary"  @click="addclient()">ADD CLIENT</v-btn>
  <!-- <v-btn @click="onclick">button</v-btn> -->
</v-col>
          <v-card v-if="isSingle">
      
            <div v-if="kvclients.length>0" class="card text-center m-3">
        
        <div class="card-body" >
            <v-card color="#f2f5f2" class="mx-auto"  v-for="item in pageOfItems" :key="item.id">
            <v-row>
              <v-col @click="onClientview(item)">
              <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Name</v-list-item-title>
        <v-list-item-subtitle>{{item.FullName}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onClientview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Handset</v-list-item-title>
        <v-list-item-subtitle>{{item.Handset}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onClientview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>ControlBox</v-list-item-title>
        <v-list-item-subtitle>{{item.Cbid}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onClientview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>DL number</v-list-item-title>
        <v-list-item-subtitle>{{item.Dlnum}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onClientview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>CalibrationDate</v-list-item-title>
        <v-list-item-subtitle>{{item.calibrationdate}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col > 
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Balance</v-list-item-title>
        <v-list-item-subtitle>${{item.balance}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col>
    <v-list-item two-line>
      <v-list-item-content>
        <!-- <v-list-item-title>Balance</v-list-item-title> -->
        <v-list-item-subtitle><v-chip :disabled="item.balance==0?true:false" @click="payDue(item,'all')" color="orange">Pay Now</v-chip></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
              <!-- <v-col>{{item.FullName}}</v-col>
              <v-col>{{item.Handset}}</v-col>
              <v-col>{{item.Dlnum}}</v-col> -->
            </v-row>
              
            </v-card>
        </div>
        <div class="card-footer pb-0 pt-3" style="background-color: white">
            <jw-pagination   :items="kvclients" @changePage="onChangePage"  :labels="customLabels"></jw-pagination>
        </div>
    </div>
    <div v-else class="card text-center m-3">No Clients Found</div>
          </v-card> 
         
  <v-card
  v-else
    class="mx-auto my-12"
    
  >
  <v-row>
    <v-col cols="8">
   <v-row>
    <v-col>
      <!-- <v-card-title v-if="cimage"><img :src="cimage"  width="120px" height="120px"/></v-card-title> -->
      <v-card width="220" v-if="cimage">
          <v-img
            width="220px"
            height="220px"
            :src="cimage"
            style="margin-left:4px"
          > 
            <v-card-title class="black--text mt-8">
              <div class="row">
  <div class="column">
    <input style="margin-top:130px;font-size: 8px; text-decoration: none;" x-small type="file" accept=".jpg" id="imageFile" name="imageFile" @change="uploadclientImage( $event )" />
  </div>
  <div class="column" v-if="clientimagefile != ''">
    <v-btn @click="updateclient" style="margin-top:140px;font-size:8px;margin-left:-20px" x-small>Update</v-btn>
  </div>
</div>
              <!-- <input style="margin-top:100px;font-size: 8px; text-decoration: none;" type="file" accept=".jpg" id="imageFile" name="imageFile" @change="uploadclientImage( $event )" />
              <v-btn @click="updateclient" style="font-size:8px">Update</v-btn> -->
            </v-card-title>
          </v-img>
        </v-card>
      <!-- <v-card-title v-else>NO CLIENT IMAGE</v-card-title> -->
      <v-card-title v-else><span><v-icon>mdi-account</v-icon></span></v-card-title>
    <v-card-title style="margin-top:-10px">{{singlerow.FullName}}</v-card-title>
    <div v-if="!cimage">
    <label style="font-size:10px">CHANGE CLIENT IMAGE:</label>
    <input type="file" accept=".jpg" id="imageFile" name="imageFile" @change="uploadclientImage( $event )" />
    <v-btn @click="updateclient" v-if="clientimagefile != ''">Update</v-btn>
    </div>
    <v-row style="margin-top:-10px">
      <v-col>
  <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Handset Number</v-list-item-title>
        <v-list-item-subtitle>{{singlerow.Handset}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Control Box Number</v-list-item-title>
        <v-list-item-subtitle>{{singlerow.Cbid}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
    </v-row>
  </v-col>

    
  </v-row>
    <v-card-title>Report</v-card-title>

    <v-card-text>
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
      <v-chip>All Logs: {{statistics.alllogs}}</v-chip>
        <v-chip>Pass: {{statistics.pass}}</v-chip>

        <v-chip>Fail: {{statistics.fails}}</v-chip>

        <v-chip>Total Tests: {{statistics.total}}</v-chip>

        <v-chip>Bypass: {{statistics.bypass}}</v-chip>
        <v-chip>Handset Disconnections: {{statistics.disconnect}}</v-chip>
        
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        {{viewtext}}
      </v-btn>
    </v-card-actions>
    </v-col>
    <v-col cols="4">
   <!-- <v-icon @click="isSingle=!isSingle">mdi-keyboard-return</v-icon> -->
   <v-btn @click="editclient(singlerow)" class="warning">Edit</v-btn> &nbsp;&nbsp;&nbsp;
   <v-btn @click="clientclose()" class="primary">Close</v-btn>
          <v-list-item>
      <v-list-item-content>
        <v-list-item-title>License Number</v-list-item-title>
        <v-list-item-subtitle>{{singlerow.Dlnum}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Registration Number</v-list-item-title>
        <v-list-item-subtitle>{{singlerow.regnum}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Mobile</v-list-item-title>
        <v-list-item-subtitle>
          {{singlerow.Mobile}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Email</v-list-item-title>
        <v-list-item-subtitle>
          {{singlerow.Email}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Address</v-list-item-title>
        <v-list-item-subtitle>
          {{singlerow.address}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    </v-col>
  </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-data-table
    
        dense
        :headers="clientheader"
        :items="resultQuery"
        :search="search"
        class="elevation-1"
      >
      <template slot=item.image slot-scope="{ item }">
          <v-icon class="mt-2" color="green" @click="Viewimage(item)" v-if="item.DataType == 'Passed' || item.DataType == 'Failed'">
          mdi-image-area
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
  </v-card>
      </v-tab-item>

      <!-- third tab -->

      <v-tab-item>
        <v-card flat>
  <v-col class="text-right">
  <v-btn class="primary" v-if="isPayment" @click="addpayment()">ADD PAYEMENT</v-btn>
  <!-- <v-btn @click="onclick">button</v-btn> -->
</v-col>
<v-dialog v-model="paymentdialog" max-width="600px">
                <v-card>
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>{{ Titlepayment }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                    autocomplete="off"
                    ref="paymentform"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              v-model="addInfo.billnum"
                              required
                              disabled
                              :rules="billnum"
                              label="BillNum*"
                            ></v-text-field>
                          </v-col>

                           <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            required
                            v-model="addInfo.client"
                            :items="kvclients"
                            label="Select Client*"
                            item-value="FullName"
                            item-text= "FullName"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12">
            <v-autocomplete
              v-model="addInfo.service"
              :disabled="isUpdating"
              auto-select-first
              :items="invoiceservices"
              color="blue-grey lighten-2"
              label="Select Services"
              @change="updateService"
              item-text="servicetype"
              multiple
              return-object
            >
            <template slot="selection" slot-scope="data">
              <v-chip>{{ data.item.servicetype }}</v-chip>
              </template>
              <!-- <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  {{ item.name }}
                </v-chip>
              </template> -->
            </v-autocomplete>
          </v-col>
                          
                          <v-col>
                            <v-text-field
                              dense
                              v-model="addInfo.qty"
                              disabled
                              required
                              :rules="qty"
                              label="Quntity*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="addInfo.amounts"
                              disabled
                              required
                              :rules="amounts"
                              label="Amounts*"
                            >
                            <template slot="selection" slot-scope="data">
                                {{ data.item.rate }}
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="addInfo.amtpaid"
                              required
                              @change="updateamt"
                              :rules="ruleamtpaid"
                              label="AmtPaid*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="addInfo.grandtotal"
                              disabled
                              required
                              :rules="total"
                              label="Total*"
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-model="addInfo.balance"
                              disabled
                              required
                              label="Balance*"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              v-model="addInfo.status"
                              :items="statuss"
                              required
                              :rules="status"
                              label="Status*"
                            ></v-autocomplete>
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-btn color="green darken-1" text @click="payclose"
                      >CANCEL</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="unpaidpaymentsave"
                      >{{laterTitle}}</v-btn
                    >
                    <v-btn
                    v-if="editedIndex2 == -1"
                      color="blue darken-1"
                      text
                      :disabled="!valid"
                      @click="paymentsave"
                      >{{buttonTitle}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="paydialog" max-width="800px">
            <v-card class="elevation-12">
          <v-toolbar text color="#26BB44" class="" dark dense>
              <v-row>
                    <v-toolbar-title>You are paying the amount ${{this.addInfo.amtpaid}}</v-toolbar-title>
                    <v-layout justify-end>
                 <v-btn color="primary" @click="payclose" dark v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                      >Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="payclose" > mdi-close </v-icon>
                </v-layout>
              </v-row>
                  </v-toolbar>
                  &nbsp;
                  <div class="container">
        <stripe-element-payment
        v-if="iscreated"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="{return_url: `https://alcobrakeadmin.com/successpayment?myinfo=`+JSON.stringify(this.addInfo)+`&services=`+this.myservices+`&paymentid=`+this.payind1+`&clearall=true`}"
    />
    <br/>
    <v-layout justify-end>
      <!-- dialog paynow -->
    <v-btn class="primary" left @click="pay">Pay Now</v-btn>
    </v-layout>
                  </div>
            </v-card>
              </v-dialog>
              <v-dialog v-model="singlepayment" max-width="800px">
            <v-card class="elevation-12">
          <v-toolbar text color="#26BB44" class="" dark dense>
              <v-row>
                    <v-toolbar-title>You are paying the amount ${{this.addInfo2.amtpaid}}</v-toolbar-title>
                    <v-layout justify-end>
                 <v-btn color="primary" @click="payclosesingle" dark v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                      >Close</v-btn>
                      <v-icon v-else color="primary" size="40px" @click="payclosesingle" > mdi-close </v-icon>
                </v-layout>
              </v-row>
                  </v-toolbar>
                  &nbsp;
                  <div class="container">
        <stripe-element-payment
        v-if="iscreated2"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="{return_url: `https://alcobrakeadmin.com/gdrivesuccesstxn?myinfo=`+JSON.stringify(this.addInfo2)+'&paymentid='+this.payind1+`&clearall=false`}"
    />
    <br/>
    <v-layout justify-end>
      <!-- dialog paynow -->
    <v-btn class="primary" left @click="pay">Pay Now</v-btn>
    </v-layout>
                  </div>
            </v-card>
              </v-dialog>

    <v-card v-if="isPayment">
            <div class="card text-center m-3">
        
        <div v-if="paymentdata.length" class="card-body" >
            <v-card color="#f2f5f2" class="mx-auto"  v-for="item in paymentdata" :key="item.id">
            <v-row >
              <v-col @click="onPaymentview(item)">
              <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title style="font-size:15px">BillNumber</v-list-item-title>
        <v-list-item-subtitle>{{item.billnum}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onPaymentview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title style="font-size:15px">Client</v-list-item-title>
        <v-list-item-subtitle>{{item.client}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onPaymentview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title style="font-size:15px">Status</v-list-item-title>
        <v-list-item-subtitle>{{item.status}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col @click="onPaymentview(item)">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title style="font-size:15px">Balance</v-list-item-title>
        <v-list-item-subtitle>${{item.balance}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col>
    <v-list-item two-line>
      <v-list-item-content>
        <!-- <v-list-item-title style="font-size:15px">Balance</v-list-item-title> -->
        <v-list-item-subtitle><v-chip :disabled="item.balance==0?true:false" @click="payDue(item,'single')" color="orange">Pay Now</v-chip></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
            </v-row>
              
            </v-card>
        </div>
        <div v-else class="card text-center m-3">No Payments Found</div>
        <div class="card-footer pb-0 pt-3" style="background-color: white">
            <jw-pagination   :items="paymentdata" @changePage="onChangePayment"  :labels="customLabels"></jw-pagination>
        </div>
    </div>
          </v-card>
  <v-card v-else
    class="mx-auto my-12">
    <v-row>
      <!-- first column -->
      <v-col cols="4">
        <v-row>
          <v-list-item-title>INVOICE NO.</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.billnum}}</v-list-item-subtitle>
        </v-row>
        <v-row>
          <v-list-item-title>QUNATITY</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.qty}}</v-list-item-subtitle>
        </v-row>
        <v-row>
          <v-list-item-title>ITEMS.</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.items}}</v-list-item-subtitle>
        </v-row>

      </v-col>
            <!-- second column -->
            <v-col cols="4">
              <v-row>
          <v-list-item-title>total</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.total}}</v-list-item-subtitle>
        </v-row>
        <v-row>
          <v-list-item-title>AMOUNT PAID</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.amtpaid}}</v-list-item-subtitle>
        </v-row>
        <v-row>
          <v-list-item-title>BALANCE</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.balance}}</v-list-item-subtitle>
        </v-row>

</v-col>
      <!-- third column -->
      <v-col>

</v-col>
    </v-row>
  <!-- <v-row>
    <v-col cols="8">
   <v-row>
    <v-col>
    <v-card-title style="margin-top:-20px">INVOICE NO:{{paymentrow.billnum}}</v-card-title>
    <v-row>
      <v-col>
  <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>CUSTOMER NAME</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.client}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
  <v-col>
  <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>BALANCE</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.balance}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
    </v-row>
  </v-col>

    
  </v-row>
  <v-row>
    <v-col>
    <v-card-title>ITEMS</v-card-title>
  </v-col>
  <v-col>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>STATUS</v-list-item-title>
        <v-list-item-subtitle>
          {{paymentrow.status}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
    </v-row>


    <v-card-text>
      <v-chip-group
        v-model="selection2"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>QUANTITY: {{paymentrow.qty}}</v-chip>
      </v-chip-group>
    </v-card-text>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>ITEM NAMES</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.items}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    </v-col>
    <v-col  cols="4">
   <v-btn @click="editpayment(paymentrow)" class="warning" v-if="paymentrow.balance != 0">Edit</v-btn> &nbsp;&nbsp;&nbsp;
   <v-col>
  <v-list-item-title>Bill Date: {{ paymentrow.paytime }}</v-list-item-title>
  </v-col>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>AMOUNTS</v-list-item-title>
        <v-list-item-subtitle>{{paymentrow.amounts}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>TOTAL</v-list-item-title>
        <v-list-item-subtitle>
          {{paymentrow.total}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>AMOUNT PAID</v-list-item-title>
        <v-list-item-subtitle>
          {{paymentrow.amtpaid}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    </v-col>    
  </v-row>
  <v-btn @click="isPayment=!isPayment" class="primary">Close</v-btn> -->

  </v-card>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<style>

.v-file-input{
      width: 700px;
}
#label {
    background-color: aliceblue;
}
.select {
      max-width: 400px;
      max-height: 100px;
      font-size: 15px;
}
.v-progress-circular {
  margin: 1rem;
}
.loading:after {
  content: ' .';
  animation: dots 2s steps(1, end) infinite;
}

@keyframes dots {
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    62.5% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid green;
  width: 27px;
  height: 27px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.blinking {
   
    animation: opacity 2s ease-in-out infinite;
    opacity: 1;
    color:green
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
</style>