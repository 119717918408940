<script>
import Vue from "vue"
import moment from "moment";
import { mapGetters } from "vuex";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// import this.oledDevices from './oled_list.json';
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    viewdialog:false,
    imgdialog:false,
    imageBytes: null,
    loadingdialog: false,
    beforedata:"",
    checkbox2: false,
    samplercheckbox: true,
    iscam:"",
    loadingtext : "Please Wait Data Loading",
     bacvalue:'',
      devid:'',
      rtime:'',
      result:'',
    page: 1,
    items: [],
     options: {
        itemsPerPage: 10,
    },
    pagination: {},
    checkbox:true,
    caltrackdata:[],
    postdata:null,
    search: "",
    search2: "",
    search3: "",
    type:null,
    show: false,
    dialog13:false,
    req:{
      iscam:null,
      issign:null
    },
    logheaders: [],
    headers: [
      { text: "SNO", value: "sno" },
      {
        text: "Client",
        align: "left",
        value: "client",
      },
      // { text: "License Plate Number", value: "regnum" },
      { text: "DeviceId", value: "cbid" },
      { text: "HandSet", value: "devId" },
      { text: "History", value: "history", sortable: false },
      { text: "Download", value: "download", sortable: false },
      { text: "View", value: "viewlog", sortable: false },
      // { text: "Clear Log", value: "delete", sortable: false },
    ],
    headers2: [
      {text: "DeviceId",align: "left",value: "cbid"},
      {
        text: "HandSet",
        align: "left",
        value: "devid"
      },
      { text: "Updated Time", value: "updatedtime" },
      { text: "Calibration Date", value: "caldate" },
      { text: "Brac Peak Factor", value: "bracpeakfactor" },
      { text: "Offset Pressure", value: "offset_pressure" },      
      { text: "F/W version", value: "fwversion"},
      { text: "C/B version", value: "cbversion"},
    ],
    editedIndex: -1,
    editedItem: {
      officeId: "",
      devId: "",
      status: "",
      content_control: "",
      calibrationdate: "",
    },
    defaultItem: {
      officeId: "",
      devId: "",
    },
    valid: true,
    officeRules: [(v) => !!v || "Select office"],
    deviceRules: [
      (v) => !!v || "device id is required",
      (v) => (v && v.length >= 8) || "Enter 8digit number",
    ],
  }),

  computed: {
     firstItem () {
      return this.pagination.pageStart
    },
    itemsPerPage () {
      return this.pagination.itemsPerPage
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Device" : "Edit Device";
    },
    ...mapGetters(['fullclients']),
    ...mapGetters(['offices']),
    ...mapGetters(['logs']),
     ...mapGetters(['oledDevices'])
  },

  watch: {
    checkbox2(val) {
      this.checkbox2 = val
    },
    samplercheckbox(val) {
      this.samplercheckbox = val
    },
    dialog(val) {
      val || this.close();
    },
  },
  beforeMount() {
    this.mounteddata(); 
  },

  methods: {
    getUniqueObjects(array, key1, key2) {
  const seen = new Set();
  return array.filter(obj => {
    const key = obj[key1] + '|' + obj[key2];
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
},
    toggleChecksampler() {
       this.viewdialog = false;
      this.loadingdialog = true
      this.loadingtext = "Please Wait"
      this.samplercheckbox = !this.samplercheckbox
      this.$emit('setCheckboxSampler', this.samplercheckbox)
      if (this.samplercheckbox == true) {
        if (this.iscam == "YES") {
          this.logheaders = [
            { text: "DeviceId", value: "controlId" },
            {
              text: "HandSet",
              align: "left",
              value: "devid",
            },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
            { text: "DELETE", value: "delete", sortable: false },
          ]
        } else {
          this.logheaders = [
            { text: "DeviceId", value: "controlId" },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
            },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        // { text: "IMAGE", value: "image", sortable: false },
        { text: "DELETE", value: "delete", sortable: false },
      ]
        }
        // console.log(this.checkbox2)
        var obj ={
          role:this.user.role,
        item:this.beforedata,
        sorted:this.checkbox2
      }
        this.$store.dispatch('controlidfetchLogs',obj);
        setTimeout(() => {
          this.loadingdialog = false
          this.viewdialog = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        },6000)
      } else {
        // console.log(this.checkbox2)
           if (this.iscam == "YES") {
          this.logheaders = [
            {
                text: "SamplerId",
                align: "left",
                value: "devid",
              },
              { text: "CBID", value: "controlId" },
            { text: "TIME", value: "rtime" },
            { text: "EVENT", value: "typeofevent", sortable: false },
            { text: "BAC", value: "bacvalue", sortable: false },
            { text: "RESULT", value: "passfailstatus", sortable: false },
            { text: "IMAGE", value: "image", sortable: false },
            { text: "DELETE", value: "delete", sortable: false },
          ]
        } else {
          this.logheaders = [
            {
                text: "SamplerId",
                align: "left",
                value: "devid",
              },
              { text: "CBID", value: "controlId" },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        // { text: "IMAGE", value: "image", sortable: false },
        { text: "DELETE", value: "delete", sortable: false },
      ]
        }
          var obj2 ={
            role:this.user.role,
        item:this.beforedata,
        sorted:this.checkbox2
      }
           this.$store.dispatch('samplefetchLogs',obj2);      
          setTimeout(() => {
            this.loadingdialog = false
          this.viewdialog = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        },6000)
      }
    },
    toggleCheckbox() {
      console.log(this.checkbox2);
      console.log(this.beforedata)
     this.viewdialog = false;
      this.loadingdialog = true;
      this.loadingtext = "Please Wait"
      this.checkbox2 = !this.checkbox2
      console.log(this.logs);
      console.log(this.checkbox2)
      if(this.oledDevices.filter(value => value.sno == this.beforedata.sno).length ==0){
          var obj ={
          role:this.user.role,
        item:this.beforedata,
        sorted:this.checkbox2
      }
        this.$store.dispatch('controlidfetchLogs',obj);
        setTimeout(() => {
            this.loadingdialog = false;
          this.viewdialog = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        },6000)
        } else{
          var obj2 ={
          role:this.user.role,
        item:this.beforedata,
        sorted:this.checkbox2
      }
        this.$store.dispatch('samplefetchLogs',obj2);
        setTimeout(() => {
            this.loadingdialog = false
          this.viewdialog = true;
          this.pagination.page = 1;
          this.options.itemsPerPage = 10;
        },6000)
          
        }
    },
    historyupdatePage(pagination) {
        var count = this.$store.state.logs.length
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < count; p++) {
          const start = p * pagination.itemsPerPage
          const end = start + pagination.itemsPerPage
          if (this.firstItem >= start && this.firstItem < end) {
            pagination.page = p + 1
            // break out of the loop
            break;
          }
        }
      }
      this.pagination = pagination
    },
    updatePage(pagination) {
        var count = this.$store.state.logs.length
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < count; p++) {
          const start = p * pagination.itemsPerPage
          const end = start + pagination.itemsPerPage
          if (this.firstItem >= start && this.firstItem < end) {
            pagination.page = p + 1
            // break out of the loop
            break;
          }
        }
      }
      this.pagination = pagination
    },
     calTracking(id) {
  const mProm = new Promise((res,rej)=>{
  Vue.axios
    .get("https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid="+id.cbid)
    .then(response => {
      if(response.data != null){
        var calibdata = response.data
       calibdata =  calibdata.sort((a,b) => moment(a.updatedtime).valueOf() - moment(b.updatedtime).valueOf())
        var revcalib = calibdata.reverse();
        let unique_values =this.getUniqueObjects(revcalib, 'caldate', 'devid');
        this.caltrackdata = unique_values
        res({status:"ok",vdata:unique_values})
      } else{
        rej("Data Not Exists");
      }
    }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
  });
  mProm.then((red)=>{
    this.dialog13 = true;
  }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
},
 close13(){
      // this.editedItem = Object.assign({}, this.defaultItem);
   this.caltrackdata = [];
       this.pagination.page = 1;
      this.options.itemsPerPage = 10;
      this.dialog13 = false;
    },
    mounteddata(){
      this.$store.dispatch('fetchOLEDDevices');
      if(this.user.role == "Admin"|| this.user.role == 'superadmin') {
    this.$store.dispatch('fetchfullClients')
    this.$store.dispatch('fetchOffices')
    } else{
      this.$store.dispatch('fetchdealerfullClients',this.user.dealerId)
      // this.$store.dispatch('fetchdealerDevices',this.user.dealerId)
      this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
    }
    },
    viewItem(vdata) {
      this.beforedata = vdata
      console.log(vdata)
          Vue.axios.get('https://robopower.xyz/us/req/getuniquestaterequirement?state='+vdata.tagstate)
      .then((res)=>{
        console.log(res)
        this.req.iscam = res.data[0].iscamera;
        this.req.issign = res.data[0].issignature;
        this.iscam = res.data[0].iscamera
        if(this.user.role == "Admin"|| this.user.role == 'superadmin'){
        if(res.data[0].iscamera == "YES"){
          this.logheaders = [
        { text: "DeviceId", value: "controlId" },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
            },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        { text: "IMAGE", value: "image", sortable: false },
        { text: "DELETE", value: "delete", sortable: false },
      ]
        }else{
          this.logheaders = [
      { text: "DeviceId", value: "controlId" },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
      },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        // { text: "IMAGE", value: "image", sortable: false },
        { text: "DELETE", value: "delete", sortable: false },
      ]
        }
        } else{
          if(res.data[0].iscamera == "YES"){
          this.logheaders= [
         { text: "DeviceId", value: "controlId" },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
      },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        { text: "IMAGE", value: "image", sortable: false },
        // { text: "DELETE", value: "delete", sortable: false },
      ]
        }else{
    this.logheaders= [
       { text: "DeviceId", value: "controlId" },
        {
          text: "HandSet",
          align: "left",
          value: "devid",
      },
        { text: "TIME", value: "rtime" },
        { text: "EVENT", value: "typeofevent", sortable: false },
        { text: "BAC", value: "bacvalue", sortable: false },
        { text: "RESULT", value: "passfailstatus", sortable: false },
        // { text: "IMAGE", value: "image", sortable: false },
        // { text: "DELETE", value: "delete", sortable: false },
      ]
        }
    }
        /* eslint-disable no-console */
        // this.$store.dispatch('fetchLogs', vdata.devId);
        if(this.oledDevices.filter(value => value.sno == vdata.sno).length ==0){
          var obj ={
          role:this.user.role,
        item:vdata,
        sorted:this.checkbox2
      }
        this.$store.dispatch('controlidfetchLogs',obj);
        } else{
          var obj2 ={
          role:this.user.role,
        item:vdata,
        sorted:this.checkbox2
      }
        this.$store.dispatch('samplefetchLogs',obj2);
          
        }

          this.loadingdialog = true
          this.loadingtext = "Please Wait Data Loading"
          setTimeout(() => {
            this.viewdialog = true;
            this.loadingdialog = false;
            this.loadingtext = "Please Wait Data Loading"
          }, 6000)
      }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
       getColor(it) {
        if (it.passfailstatus == "FAIL") return "red";
        else if (it.result == null) return "green";
        else if (it.passfailstatus == "PASS") return "green";
        else return "orange";
    },
     imgclose() {
      this.imgdialog = false;
      this.viewdialog = true;
      this.imageBytes =null;
    },
      viewImage(data){
      this.imgdialog = true;
      this.viewdialog = false;
      var itemsname1 = data.rtime;
       
        Vue.axios
          .get(
            "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
              data.controlId +
              "&Image=" +
              itemsname1
          )
          .then(res => {
            if (res.data) {
              this.imageBytes = res.data;
              this.bacvalue = data.bacvalue;
              this.devid = data.devid;
              this.rtime = data.rtime;
              this.result = data.passfailstatus;
            } else {
      
    
            this.$store.dispatch("alert/error", "NO Image Found").then(()=>{
            setTimeout(()=>{
              this.$store.dispatch("alert/clear")
            },2000)
            })
  
            }
          })
          .catch(error => {
            console.error("Error: ", error);
          });
      },
    downloadLog(item){
      /* eslint-disable no-console */
      if (item != null) {
        this.dialog = true;
        this.postdata = item;
      }
    },
    textdownload(){
this.type = "text"
this.downloadItem(this.postdata);
  this.postdata = null;
  this.dialog = false;
    },
    csvdownload(){
      this.type = "csv"
      this.downloadItem(this.postdata);
      this.postdata = null;
      this.dialog = false;

    },
    deleteevent(event) {
      if (this.user.emailId != 'Justin@alcobrake.com') {
        const Swal = require("sweetalert2");
        Swal.fire({
          title: "Are you sure? You want to Delete " + event.typeofevent + " " + event.rtime,
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            Vue.axios.post("https://robopower.xyz/v2/bractest/delete/" + event.id).then(() => {
              // this.$store.dispatch('fetchLogs', event.devid)
              this.$store.dispatch("alert/success", "Event Log Deleted").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
              var obj ={
          role:this.user.role,
        item:event,
        sorted:this.checkbox2
      }
        this.$store.dispatch('controlidfetchLogs',obj);
            })
          } else {
            // this.$swal("Cancelled", "Your file is still intact", "info");
            this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
      } else {
             this.$store.dispatch("alert/success","YOU DONT HAVE PERMISSION TO DO THIS ACTION").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
      }
    },
    downloadItem(datas) {
      if(datas.state=="TX"){
        this.istexasloading = true;
       var zip = new JSZip();
       var img = zip.folder("images");
       var updatecount = 0;
       
       }
      this.loadingdialog = true
        this.loadingtext = "Please Wait Data Downloading"
    Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + datas.cbid
        )
        .then((response) => {
          if (response.data != null) {
            console.log("cbid based")
            var remData = response.data
            // .filter((r)=>{
            //   return moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") >= moment(datas.installDate).format("MM/DD/YYYY HH:mm:ss") && moment(r.rtime).format("MM/DD/YYYY HH:mm:ss") < moment(new Date()).add(1, 'days').format("MM/DD/YYYY HH:mm:ss")
            // })

        this.eventscount=  response.data.filter((res)=>{
                   return  res.typeofevent == "254"
                  });      
  
            var wholedata = response.data;
            this.totaleventscount = wholedata.length;
            wholedata = wholedata.sort(
              (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
            );
        if (this.user.role == "superadmin") {
          remData = remData.filter(o => o.typeofevent !== "0");
        } else {
          remData = remData.filter(o => o.typeofevent !== "0"
          && o.typeofevent !== "151"
         && o.typeofevent !== "152"
          && o.typeofevent !== "153"
          && o.typeofevent !== "154"
          && o.typeofevent !== "155"
          && o.typeofevent !== "156"
            && o.typeofevent !== "157"
            && o.typeofevent !== "218"
            && o.typeofevent !== "219"
        );
        }
            var sorteddata = wholedata.reverse();
            var arrData = sorteddata;
            arrData.push(remData)
            var today = new Date();
            var row_width = 5;
            var object;
            if (this.type == "text") {
              object =
                "Alcobrake" +
                new Array(row_width).join(" ") +
                "Log Summary Report \r\n\r\nDevice Id:" +
                datas.cbid 
                // +"\r\n \r\n";
                + new Array(row_width).join(" ") +
                 "Device Installed On:" + datas.installDate +
                "\r\n \r\n"
              object +=
                "LogRead Date " +
                new Array(row_width).join(" ") +
                today +
                "\r\n \r\n"
                
              object +=
                "Record id" +
                new Array(row_width + 5).join(" ") +
                "Date" +
                new Array(row_width + 10).join(" ") +
                "Week" +
                new Array(row_width + 15).join(" ") +
                "EventType" +
                new Array(row_width + 9).join(" ") +
                "brac" +
                new Array(row_width + 8).join(" ") +
                "Result \r\n";
              if (remData != null) {
                var rcid = 1;
                var neProm2 = new Promise((resolve,reject)=>{
                  if(remData.length>0){
                remData.map((element) => {
                  if (element.typeofevent != "101") {
                    if (element.typeofevent != "29") {
                      var dt = new Date(element.rtime);
                      var week = [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ];
                      var weekday = week[dt.getDay()];
                      var eventType = " ";
                      var Result = " ";
                      var Bacvalue = " ";
                      //AlcoholTest
                      if (element.typeofevent == 254) {
                        //initialtest
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
                          eventType = "INITIAL TEST";
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          eventType = "RANDOM TEST";
                        } else {
                          eventType = "NA";
                        }
                        if (element.passfailstatus == 1) {
                          Result = "PASS";
                        } else if (element.passfailstatus == 0) {
                          Result = "FAIL";
                        } else {
                          Result = " ";
                        }
                      } else if (element.typeofevent == 15) {
                        if (element.enginerunstate == 0) {
                          eventType = "ENGINE OFF";
                        } else if (element.enginerunstate == 1) {
                          eventType = "ENGINE ON";
                        } else {
                          eventType = "NA";
                        }
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 66) {
                        eventType = "HARD_BLOW";
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 21) {
                        eventType = "CAR_BATTERY_TAMPERING";
                      } else if (element.typeofevent == 19) {
                        eventType = "VIOLATION_LOCKOUT";
                      } else if (element.typeofevent == 4) {
                        eventType = "BYPASS";
                      } else if (element.typeofevent == 18) {
                        eventType = "TEST_REFUSED_RR_TEST";
                      } else if (element.typeofevent == 28) {
                        eventType = "TAMPERED";
                      } else if (element.typeofevent == 22) {
                        eventType = "CALIBRATION DONE";
                      } else if (element.typeofevent == 29) {
                        eventType = "START_TEST_ATTEMPT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 27) {
                        eventType = "COOL_SAMPLE";
                      } else if (element.typeofevent == 25) {
                        eventType = "HOT_SAMPLE";
                      } else if (element.typeofevent == 8) {
                        eventType = "ONE_TIME_PASS_CODE";
                      } else if (element.typeofevent == 6) {
                        eventType = "SERVICE_PERIOD_SET";
                      } else if (element.typeofevent == 7) {
                        eventType = "VOILATION_RESET";
                      } else if (element.typeofevent == 11) {
                        eventType = "LOG_READ";
                      } else if (element.typeofevent == 220) {
                        eventType = "LOCKOUT 0";
                      } else if (element.typeofevent == 221) {
                        eventType = "LOCKOUT 1";
                      } else if (element.typeofevent == 222) {
                        eventType = "LOCKOUT 2";
                      } else if (element.typeofevent == 223) {
                        eventType = "LOCKOUT 3";
                      } else if (element.typeofevent == 224) {
                        eventType = "LOCKOUT 4";
                      } else if (element.typeofevent == 225) {
                        eventType = "LOCKOUT 5";
                      } else if (element.typeofevent == 226) {
                        eventType = "LOCKOUT 6";
                      } else if (element.typeofevent == 227) {
                        eventType = "LOCKOUT 7";
                      } else if (element.typeofevent == 56) {
                        eventType = "TAKE_A_PICTURE";
                      } else if (element.typeofevent == 101) {
                        eventType = "PICTURE_CAPTURED";
                      } else if (element.typeofevent == 60) {
                        eventType = "BYPASS_START";
                      } else if (element.typeofevent == 61) {
                        eventType = "BYPASS_STOP";
                      } else if (element.typeofevent == 14) {
                        eventType = "SERVICE_ALERT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 62) {
                        eventType = "SAMPLER_DISCONNECTED";
                      } else if (element.typeofevent == 10) {
                        eventType = "CAR_BATTERY_OFF";
                      } else if (element.typeofevent == 20) {
                        eventType = "CAR_BATTERY_ON";
                      }else if (element.typeofevent == 217) {
                    eventType = "MEMSET";
                  } else if (element.typeofevent == 219) {
                    eventType = "TIME_SET";
                  } else if (element.typeofevent == 218) {
                    eventType = "DEVICE RESTART";
                  }
                      if (element.bacvalue != 255) {
                        Bacvalue = element.bacvalue;
                      }

                      var rcidlength, rtimelength, braclength;

                      if (remData.indexOf(element).toString().length == 1) {
                        rcidlength = row_width + 10;
                      } else if (
                        remData.indexOf(element).toString().length == 2
                      ) {
                        rcidlength = row_width + 9;
                      } else if (
                        remData.indexOf(element).toString().length == 3
                      ) {
                        rcidlength = row_width + 8;
                      } else if (
                        remData.indexOf(element).toString().length == 4
                      ) {
                        rcidlength = row_width + 7;
                      }
                      // console.log(weekday)
                      if(weekday){
                      if (weekday.length == 6) {
                        rtimelength = row_width + 12;
                      } else if (weekday.length == 7) {
                        rtimelength = row_width + 11;
                      } else if (weekday.length == 8) {
                        rtimelength = row_width + 10;
                      } else if (weekday.length == 9) {
                        rtimelength = row_width + 9;
                      } else if (weekday.length == 10) {
                        rtimelength = row_width + 8;
                      } else if (weekday.length == 11) {
                        rtimelength = row_width + 7;
                      } else if (weekday.length == 12) {
                        rtimelength = row_width + 6;
                      } else if (weekday.length == 13) {
                        rtimelength = row_width + 5;
                      } else if (weekday.length == 14) {
                        rtimelength = row_width + 4;
                      } else if (weekday.length == 15) {
                        rtimelength = row_width + 3;
                      } else if (weekday.length == 16) {
                        rtimelength = row_width + 2;
                      } else if (weekday.length == 17) {
                        rtimelength = row_width + 1;
                      } else if (weekday.length == 18) {
                        rtimelength = row_width;
                      }
                    }
                      if (eventType.length == 9) {
                        braclength = row_width + 12;
                      } else if (eventType.length == 10) {
                        braclength = row_width + 11;
                      } else if (eventType.length == 11) {
                        braclength = row_width + 10;
                      } else if (eventType.length == 12) {
                        braclength = row_width + 9;
                      } else if (eventType.length == 13) {
                        braclength = row_width + 8;
                      } else if (eventType.length == 14) {
                        braclength = row_width + 7;
                      } else if (eventType.length == 15) {
                        braclength = row_width + 6;
                      } else if (eventType.length == 16) {
                        braclength = row_width + 5;
                      } else if (eventType.length == 17) {
                        braclength = row_width + 4;
                      } else if (eventType.length == 18) {
                        braclength = row_width + 3;
                      } else if (eventType.length == 19) {
                        braclength = row_width;
                      } else if (eventType.length == 20) {
                        braclength = row_width;
                      } else if (eventType.length == 21) {
                        braclength = row_width;
                      }

                      object +=
                        rcid +
                        new Array(rcidlength).join(" ") +
                        moment(element.rtime).format("MM/DD/YYYY hh:mm:ss") +
                        new Array(row_width).join(" ") +
                        weekday +
                        new Array(rtimelength).join(" ") +
                        eventType +
                        new Array(braclength).join(" ") +
                        Bacvalue +
                        new Array(row_width + 10).join(" ") +
                        Result +
                        "\r\n";
                      rcid++;
                    }
                  }
                });
                resolve("success");
                  } else{
                    this.dialog10 = false;
                    this.loadingdialog = false;
                    reject("No Logs Found");
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'No Logs Found From Device Install Date',
          icon: 'error',
          showCloseButton: true,
          confirmButtonText: "Ok",
        });
                  }
                }); //end promise
                neProm2.then(async (res)=>{
                  if(res == "success"){
                    this.texasdisplay = "Downloading Images Please wait....."
                if(datas.state=="TX"){
  await Promise.all(this.eventscount.map((element) => {
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
                this.istexasloading = true;
       var itemname3 = element.rtime;
      var stname3 = itemname3.replaceAll("/", "_");
      var ssname3 = stname3.replaceAll(" ", "_");
      var smname3 = ssname3.replaceAll(":", "_");
      // console.log(smname);
                            Vue.axios
        .get(
          "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
            element.controlId +
            "&Image=" +
            itemname3
        )
        .then((res) => {
          if(res.data){
            img.file(smname3+".png", res.data,{base64: true});
            this.imagecount++;
            updatecount ++;
           
          } else{
             
        this.$store.dispatch("alert/error", "No Image Found").then(() => {
            this.loadingdialog = false;
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch(
    function (error) {
      updatecount++;
       this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
    }
  )
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          // console.log(element);
       this.istexasloading = true;
      var itemname4 = element.rtime;
      var stname4 = itemname4.replaceAll("/", "_");
      var ssname4 = stname4.replaceAll(" ", "_");
      var smname4 = ssname4.replaceAll(":", "_");
      // console.log(smname);
                            Vue.axios
        .get(
          "http://robopower.xyz/app/getImage/getb64v4?deviceId=" +
            element.controlId +
            "&Image=" +
           itemname4
        )
        .then((res) => {
          if(res.data){
            img.file(smname4+".png", res.data,{base64: true});
            this.imagecount++;
            updatecount ++;
          } else{
             
           this.$store.dispatch("alert/error", "No Image Found").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch(
    function (error) {
      updatecount++;
       this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
    }
  )
                        } else {
                          console.log("NA");
                          this.loadingdialog = false;
                        }
// }
    }))
    var checkimagesInterval = setInterval(()=>{
      var completedper = (updatecount/this.eventscount.length)*100
      // console.log(completedper)
      this.texasdisplay = "Zipping files..... "+ parseInt( completedper )+" %"
if(updatecount >= this.eventscount.length){
  setTimeout(()=>{
  this.confirmtexasloading = true;
clearInterval(checkimagesInterval);
this.istexasloading = false;
setTimeout(()=>{
  this.confirmtexasloading = false;
zip.file(datas.client+".txt", object);
zip.generateAsync({type:"blob"})
.then(function(content) {
    //make default
    saveAs(content, datas.client+".zip");
}).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
})
    this.loadingdialog = false;
    this.imagecount=0;
    this.eventscount=[];
    updatecount=0;
    this.texasdisplay="Downloading data please wait.....",
this.dialog10 = false;
},5000)
  },2000)
}
    },2000)
    
  
} else{
                              var element = document.createElement("a");
                element.setAttribute(
                  "href",
                  "data:text/plain;charset=utf-8," + encodeURIComponent(object)
                );
                element.setAttribute("download", remData[0].controlId + "_log");
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                  this.dialog10 = false;
                this.loadingdialog = false;
                }
                  }
                })
              } else {
                this.istexasloading = false;
                this.loadingdialog = false;
                this.$store.dispatch("alert/error", "Log file empty").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
              }
            } else {
              //CSV data
              this.texasdisplay = "Preparing Logs please wait....."
              object =
                "Alcobrake" +
                new Array(row_width).join(" ") +
                "Log Summary Report \r\n\r\nDevice Id:" +
                datas.cbid 
                // +"\r\n \r\n";
                 + new Array(row_width).join(" ") +
                 "Device Installed On :" + datas.installDate +
                "\r\n \r\n"
                object +=
                "LogRead Date " +
                new Array(row_width).join(" ") +
                today +
                "\r\n \r\n";
              object +=
                "Record id" +
                new Array(row_width + 5).join(" ") +
                ";" +
                "Date" +
                new Array(row_width + 10).join(" ") +
                ";" +
                "Week" +
                new Array(row_width + 15).join(" ") +
                ";" +
                "EventType" +
                new Array(row_width + 9).join(" ") +
                ";" +
                "brac" +
                new Array(row_width + 8).join(" ") +
                ";" +
                "Result \r\n";
              if (remData != null) {
                rcid = 1;
                var neProm = new Promise((resolve,reject)=>{
                  if(remData.length>0){
                remData.map((element) => {
                  // console.log(element)
                  if (element.typeofevent != "101") {
                    if (element.typeofevent != "29") {
                      var dt = new Date(element.rtime);
                      var week = [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ];
                      var weekday = week[dt.getDay()];
                      var eventType = " ";
                      var Result = " ";
                      var Bacvalue = " ";
                      //AlcoholTest
                      if (element.typeofevent == 254) {
                        //initialtest
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
    
                          eventType = "INITIAL TEST";
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          eventType = "RANDOM TEST";
                        } else {
                          eventType = "NA";
                        }
                        if (element.passfailstatus == 1) {
                          Result = "PASS";
                        } else if (element.passfailstatus == 0) {
                          Result = "FAIL";
                        } else {
                          Result = " ";
                        }
                      } else if (element.typeofevent == 15) {
                        if (element.enginerunstate == 0) {
                          eventType = "ENGINE OFF";
                        } else if (element.enginerunstate == 1) {
                          eventType = "ENGINE ON";
                        } else {
                          eventType = "NA";
                        }
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 66) {
                        eventType = "HARD_BLOW";
                      } else if (element.typeofevent == 65) {
                        eventType = "INSUFFICIENTVOLUME";
                      } else if (element.typeofevent == 21) {
                        eventType = "CAR_BATTERY_TAMPERING";
                      } else if (element.typeofevent == 19) {
                        eventType = "VIOLATION_LOCKOUT";
                      } else if (element.typeofevent == 4) {
                        eventType = "BYPASS";
                      } else if (element.typeofevent == 18) {
                        eventType = "TEST_REFUSED_RR_TEST";
                      } else if (element.typeofevent == 28) {
                        eventType = "TAMPERED";
                      } else if (element.typeofevent == 22) {
                        eventType = "CALIBRATION DONE";
                      } else if (element.typeofevent == 29) {
                        eventType = "START_TEST_ATTEMPT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 27) {
                        eventType = "COOL_SAMPLE";
                      } else if (element.typeofevent == 25) {
                        eventType = "HOT_SAMPLE";
                      } else if (element.typeofevent == 8) {
                        eventType = "ONE_TIME_PASS_CODE";
                      } else if (element.typeofevent == 6) {
                        eventType = "SERVICE_PERIOD_SET";
                      } else if (element.typeofevent == 7) {
                        eventType = "VOILATION_RESET";
                      } else if (element.typeofevent == 11) {
                        eventType = "LOG_READ";
                      } else if (element.typeofevent == 220) {
                        eventType = "LOCKOUT 0";
                      } else if (element.typeofevent == 221) {
                        eventType = "LOCKOUT 1";
                      } else if (element.typeofevent == 222) {
                        eventType = "LOCKOUT 2";
                      } else if (element.typeofevent == 223) {
                        eventType = "LOCKOUT 3";
                      } else if (element.typeofevent == 224) {
                        eventType = "LOCKOUT 4";
                      } else if (element.typeofevent == 225) {
                        eventType = "LOCKOUT 5";
                      } else if (element.typeofevent == 226) {
                        eventType = "LOCKOUT 6";
                      } else if (element.typeofevent == 227) {
                        eventType = "LOCKOUT 7";
                      } else if (element.typeofevent == 56) {
                        eventType = "TAKE_A_PICTURE";
                      } else if (element.typeofevent == 101) {
                        eventType = "PICTURE_CAPTURED";
                      } else if (element.typeofevent == 60) {
                        eventType = "BYPASS_START";
                      } else if (element.typeofevent == 61) {
                        eventType = "BYPASS_STOP";
                      } else if (element.typeofevent == 14) {
                        eventType = "SERVICE_ALERT";
                      } else if (element.typeofevent == 34) {
                        eventType = "ENGINE_NOT_STARTED";
                      } else if (element.typeofevent == 62) {
                        eventType = "SAMPLER_DISCONNECTED";
                      } else if (element.typeofevent == 10) {
                        eventType = "CAR_BATTERY_OFF";
                      } else if (element.typeofevent == 20) {
                        eventType = "CAR_BATTERY_ON";
                      }else if (element.typeofevent == 217) {
                    eventType = "MEMSET";
                  } else if (element.typeofevent == 219) {
                    eventType = "TIME_SET";
                  } else if (element.typeofevent == 218) {
                    eventType = "DEVICE RESTART";
                  }
                      if (element.bacvalue != 255) {
                        Bacvalue = element.bacvalue;
                      }

                      var rcidlength, rtimelength, braclength;

                      if (remData.indexOf(element).toString().length == 1) {
                        rcidlength = row_width + 10;
                      } else if (
                        remData.indexOf(element).toString().length == 2
                      ) {
                        rcidlength = row_width + 9;
                      } else if (
                        remData.indexOf(element).toString().length == 3
                      ) {
                        rcidlength = row_width + 8;
                      } else if (
                        remData.indexOf(element).toString().length == 4
                      ) {
                        rcidlength = row_width + 7;
                      }
                    if(weekday){
                      if (weekday.length == 6) {
                        rtimelength = row_width + 12;
                      } else if (weekday.length == 7) {
                        rtimelength = row_width + 11;
                      } else if (weekday.length == 8) {
                        rtimelength = row_width + 10;
                      } else if (weekday.length == 9) {
                        rtimelength = row_width + 9;
                      } else if (weekday.length == 10) {
                        rtimelength = row_width + 8;
                      } else if (weekday.length == 11) {
                        rtimelength = row_width + 7;
                      }
                    }
                      if (eventType.length == 9) {
                        braclength = row_width + 12;
                      } else if (eventType.length == 10) {
                        braclength = row_width + 11;
                      } else if (eventType.length == 11) {
                        braclength = row_width + 10;
                      } else if (eventType.length == 12) {
                        braclength = row_width + 9;
                      } else if (eventType.length == 13) {
                        braclength = row_width + 8;
                      } else if (eventType.length == 14) {
                        braclength = row_width + 7;
                      } else if (eventType.length == 15) {
                        braclength = row_width + 6;
                      } else if (eventType.length == 16) {
                        braclength = row_width + 5;
                      } else if (eventType.length == 17) {
                        braclength = row_width + 4;
                      } else if (eventType.length == 18) {
                        braclength = row_width + 3;
                      } else if (eventType.length == 19) {
                        braclength = row_width + 2;
                      } else if (eventType.length == 20) {
                        braclength = row_width + 1;
                      } else if (eventType.length == 21) {
                        braclength = row_width;
                      }
                      object +=
                        rcid +
                        new Array(rcidlength).join(" ") +
                        ";" +
                        moment(element.rtime).format("MM/DD/YYYY hh:mm:ss") +
                        new Array(row_width).join(" ") +
                        ";" +
                        weekday +
                        new Array(rtimelength).join(" ") +
                        ";" +
                        eventType +
                        new Array(braclength).join(" ") +
                        ";" +
                        Bacvalue +
                        new Array(row_width + 10).join(" ") +
                        ";" +
                        Result +
                        "\r\n";
                      rcid++;
                    }
                  }
                });
                resolve("success");
                  } else{
                    this.dialog10 = false;
                    this.loadingdialog = false;
                    reject("No Logs Found");
        const Swal = require("sweetalert2");
         Swal.fire({
           title: 'No Logs Found From Device Install Date',
          icon: 'error',
          showCloseButton: true,
          confirmButtonText: "Ok",
        });
                  }
                }); //end promise
                neProm.then(async (res)=>{
                  if(res == "success"){
                    this.texasdisplay = "Downloading Images Please wait....."
                if(datas.state=="TX"){
  await Promise.all(this.eventscount.map((element) => {
                        if (
                          element.randominitial == 2 ||
                          element.randominitial == 0
                        ) {
                this.istexasloading = true;
       var itemname5 = element.rtime;
      var stname5 = itemname5.replaceAll("/", "_");
      var ssname5 = stname5.replaceAll(" ", "_");
      var smname5 = ssname5.replaceAll(":", "_");
      // console.log(smname);
                            Vue.axios
        .get(
          "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
            element.controlId +
            "&Image=" +
            itemname5
        )
        .then((res) => {
          if(res.data){
            img.file(smname5+".png", res.data,{base64: true});
            this.imagecount++;
            updatecount ++;
          } else{
            
        this.$store.dispatch("alert/error","No Image Found").then(() => {
            this.loadingdialog = false;
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch(
    function (error) {
      updatecount++;
       this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
    }
  )
                        } else if (
                          element.randominitial == 3 ||
                          element.randominitial == 1
                        ) {
                          // console.log(element);
       this.istexasloading = true;
      var itemname6 = element.rtime;
      var stname6 = itemname6.replaceAll("/", "_");
      var ssname6 = stname6.replaceAll(" ", "_");
      var smname6 = ssname6.replaceAll(":", "_");
      // console.log(smname);
                            Vue.axios
        .get(
          "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
            element.controlId +
            "&Image=" +
           itemname6
        )
        .then((res) => {
        if(res.data){
            img.file(smname6+".png", res.data,{base64: true});
            this.imagecount++;
            updatecount ++;
          } else{
          
           this.$store.dispatch("alert/error", "No Image Found").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
        }).catch(
    function (error) {
      updatecount++;
       this.$store.dispatch("alert/error", error).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
    }
  )
                        } else {
                          console.log("NA");
                          this.loadingdialog = false;
                        }
// }
    }))
    var checkimagesInterval = setInterval(()=>{
      var completedper = (updatecount/this.eventscount.length)*100
      this.texasdisplay = "Zipping files..... "+ parseInt( completedper )+" %"
if(updatecount >= this.eventscount.length){
  this.confirmtexasloading = true;
clearInterval(checkimagesInterval);
this.istexasloading = false;
setTimeout(()=>{
  this.confirmtexasloading = false;
zip.file(datas.client+".csv", object);
zip.generateAsync({type:"blob"})
.then(function(content) {
    //make default
    saveAs(content, datas.client+".zip");
}).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
})
    this.loadingdialog = false;
    this.imagecount=0;
    this.eventscount=[];
    updatecount=0;
    this.texasdisplay="Downloading data please wait.....",
this.dialog10 = false;
},5000)

}
    },2000)
    
  
} else{
                const link = document.createElement("a");
                link.setAttribute(
                  "href",
                  "data:text/csv;charset=utf-8," + encodeURIComponent(object)
                );
                link.setAttribute("download", remData[0].controlId + ".csv")                
                link.click();
                this.loadingdialog = false;
                this.dialog10 = false;
                }
                  }
                })

              } else {
                this.istexasloading = false;
                this.loadingdialog = false;
                this.$store.dispatch("alert/error", "Log file empty").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
              }
            }
          } else {
            this.istexasloading = false;
            this.loadingdialog = false;
                this.$store.dispatch("alert/error", "Log file empty").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
          }
           
        })
    },
    close() {
      this.dialog = false;
      this.viewdialog = false;
      this.postdata = null;
      this.pagination.page = 1;
      this.options.itemsPerPage = 10;     
      this.$store.state.logs = [];
      this.samplercheckbox = true;
      this.checkbox2 = false;
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-dialog width="890" v-model="viewdialog" @click:outside="close">
      <v-data-table
        dense
        :headers="logheaders"
        :items="logs"
        sort-by="office"
        :options.sync="options"
        :page.sync="pagination.page"
        @pagination="updatePage"
        :search="search2"
        class="elevation-1"   style="border-radius:20px !important"
      >
      <template v-slot:top>
         
            <v-toolbar flat color="#00A3A3" class="mb-4" dark dense style="border-radius:20px">
              <v-toolbar-title>Log Events</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
                 <!-- <div v-if="user.role == 'Admin' || user.role == 'superadmin'" style="margin-top:8px">
              <h6 v-if="samplercheckbox == false"> SAMPLER REFRENCE</h6>
              <h6 v-if="samplercheckbox == true"> CONTROL BOARD REFRENCE</h6>
              </div>
                &nbsp; -->
                <!-- <div class="panel-body" v-if="user.role == 'Admin' || user.role == 'superadmin'" style="margin-top:5px">  
      <label class="switch">
        <input type="checkbox" v-model="samplercheckbox" @click="toggleChecksampler">
        <div class="slider round"></div>
      </label>
    </div> -->
    &nbsp;&nbsp;
              <div v-if="user.role == 'Admin' || user.role == 'superadmin'">
              <h6 v-if="checkbox2 == false"> Sorted</h6>
              <h6 v-if="checkbox2 == true"> Unsorted</h6>
              </div>
              &nbsp;
              <div class="panel-body" v-if="user.role == 'Admin' || user.role == 'superadmin'">  
      <!--Only code you need is this label-->
      <label class="switch" id="check">
        <input type="checkbox" v-model="checkbox2" @click="toggleCheckbox">
        <div class="slider round"></div>
      </label>
      
    </div>
    &nbsp;
              <v-btn v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.md" @click="close" style="border-radius:20px"><v-icon>mdi-close</v-icon></v-btn>
              <v-btn v-else @click="close" style="border-radius:20px;"><v-icon >mdi-close</v-icon></v-btn>
            </v-toolbar>
          
        </template>
        <template slot=item.image slot-scope="{ item }">
          <v-icon class="mt-2" @click="viewImage(item)" :color="getColor(item)" v-if="item.typeofevent == 'RANDOM TEST' || item.typeofevent == 'INITIAL TEST'">
            mdi-image-area
          </v-icon>
        </template>
         <template slot=item.delete slot-scope="{ item }">
          <v-icon class="mt-2" color="red" @click="deleteevent(item)">
            mdi-delete-circle
          </v-icon>
        </template>
      </v-data-table>
    </v-dialog>

<!-- image dialog -->
     <v-dialog v-model="imgdialog" width="600px" >
                <v-toolbar text color="#00A3A3" class="" dark dense>
              <v-toolbar-title > <span style="font-size:15px;">TIME:{{rtime}}</span>&nbsp;&nbsp;<span style="font-size:15px;">BAC: {{bacvalue}}</span></v-toolbar-title>
               <v-layout justify-end>
              <v-btn color="black" @click="imgclose" dark style="border-radius:20px"><v-icon color="white" size="40px">
            mdi-close
          </v-icon></v-btn>
           
              </v-layout>
                </v-toolbar>
                <img :style="result == 'PASS' ? 'border: 3px solid green;' : 'border: 3px solid red;'" v-bind:src="'data:image/jpeg;base64,'+imageBytes" v-if="imageBytes != ''"/> 
                <v-card-text v-else class=text-center>
                <h2 style="background:black;color:white;padding:5px;border-radius:20px">IMAGE NOT FOUND</h2>
                </v-card-text>
                <v-toolbar text color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>
                    <span style="font-size:16px;">RESULT: {{result}}</span>
                  </v-toolbar-title>
                </v-toolbar>
              </v-dialog>


    <v-dialog width="600" v-model="dialog" style="border-radius:20px !important">
        <v-card color="" text style="border-radius:20px" >
                <v-toolbar class="mb-4" flat color="#00A3A3" dark dense style="border-radius:20px">
                    <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Please Choose The Format</v-toolbar-title>
                    <v-toolbar-title v-else style="font-size:12px">Please Choose The Format</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-layout justify-end>
                      <v-btn color="black" @click="close"   class="sm-0" style="border-radius:20px"><v-icon>mdi-close</v-icon>
                      </v-btn>
                  
                    </v-layout>
                </v-toolbar>
                <div align="center">
                  
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mb-2"
                          tile
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                          @click="csvdownload" style="border-radius:10px;color:white"
                        >
                          CSV
                          
                          <v-icon right>
                            mdi-download
                          </v-icon>
                        </v-btn>
                          </template>

                          <span>split data with delimiter semi-colon(;)</span>
                        </v-tooltip>
                </div>
                <div align="center">
                      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
    <v-btn class="mb-2"
      tile 
       v-bind="attrs"
      v-on="on"
      color="#334c3e"
      @click="textdownload" style="border-radius:10px;color:white"
    >
      TEXT Document
      <v-icon right>
        mdi-download
      </v-icon>
    </v-btn>
      </template> 
    <span>TEXT Document</span>
                      </v-tooltip>
                </div>
        </v-card>
    </v-dialog>
     <v-dialog v-model="loadingdialog" max-width="330px">
      <v-card style="background-color:black;color: azure;">
     <v-card-title class="loading">{{loadingtext}}</v-card-title>
     </v-card>
    </v-dialog>
    
      <v-dialog v-model="dialog13" max-width="900px">
          
              <v-data-table 
               dense
        :headers="headers2"
        :items="caltrackdata"
        :options.sync="options"
        :page.sync="pagination.page"
        @pagination="updatePage"
        :search="search"
        class="elevation-1" style="border-radius:20px" >
        <template v-slot:top>
          <v-toolbar flat color="#00A3A3" class="mb-4" dark dense style="border-radius:20px">
            <v-row>
              <v-col>
                    <v-toolbar-title>Calibration History</v-toolbar-title>
              </v-col>
              <v-col>
                <v-layout justify-end>
                 <v-btn @click="close13" style="border-radius:20px;background:black !important"><v-icon    
                      >mdi-close</v-icon></v-btn>
                      
                </v-layout>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
              </v-data-table>
      </v-dialog>
      
    <v-card flat style="border-radius:20px">
      <v-data-table
        dense
        :headers="headers"
        :items="fullclients"
        sort-by="office"
        :search="search3"
        class="elevation-1"
      >
        <template v-slot:top>
     
            <v-toolbar flat color="#00A3A3" class="mb-4" dark dense style="border-radius:20px">
             <span style="font-size:40%">
              <v-toolbar-title>LOG FILES</v-toolbar-title>
             </span>
              <v-divider class="mx-4" inset vertical></v-divider>
               <v-row>
              <v-col cols="12" md="12">
              <v-text-field
                v-model="search3"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-toolbar>
          
        </template>
         <template slot=item.history slot-scope="{ item }">
          <v-icon class="mt-2" color="green" @click="calTracking(item)">
            mdi-history
          </v-icon>
        </template>
         <!-- <v-btn flat color="green" style="margin-right:0px;" @click="calTracking(item)">History</v-btn> -->
        <template slot=item.download slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="downloadLog(item)">
            mdi-download
          </v-icon>
        </template>
        <template slot=item.viewlog slot-scope="{ item }">
          <v-icon class="mt-2" color="blue" @click="viewItem(item)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
/* loading dots */
.loading:after {
  content: ' .';
  animation: dots 2s steps(1, end) infinite;
}

@keyframes dots {
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    62.5% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
}
/* body {
  text-align: center;
  background: #51C3A0; 
  padding: 50px
} */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 23px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 100%;
  background-color:tan;
}
</style>
