import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css' 
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {
    iconfont: 'mdi'
});

export default new Vuetify({
});
