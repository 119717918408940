<script>
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    imgdialog: false,
    expanded: [],
    show: false,
    image: null,
    imageBytes: null,
    headers2: [
         {
        text: "devid",
        align: "left",
        value: "devid",
      },
      { text: "controlId", value: "controlId" },
      { text: "rtime", value: "rtime" },
      { text: "typeofevent", value: "typeofevent" },
      // { text: "randominitial", value: "randominitial" },
      // { text: "enginerunstate", value: "viewdups" },
      { text: "count", value: "count" },
      { text: "dups", value: "viewdups" },
    ],
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
      },
      { text: "Short Name", value: "shortname" },
      { text: "Part Number", value: "partnum" },
      { text: "Quantity", value: "qty" },
      { text: "Used", value: "used" },
      { text: "Unused", value: "unused" },
      { text: "Damaged", value: "damaged" },
      { text: "Orderfrom", value: "seller" },
      { text: "Image", value: "file" },
      { text: "Edit", value: "edit" },
      { text: "Delete", value: "delete", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      shortname: "",
      partnum: "",
      qty: "",
      used: "",
      unused: "",
      damaged: "",
      seller: "",
      file: "",
    },
    defaultItem: {
      id: "",
      name: "",
      shortname: "",
      partnum: "",
      qty: "",
      used: "",
      unused: "",
      damaged: "",
      file: "",
      seller: "",
    },
    valid: true,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Component" : "Edit Component";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(["components"]),
    ...mapGetters(["duplogs"]),
  },

  mounted() {
    this.mounteddata();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    imgdialog(val) {
      val || this.imgclose();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    viewevent(item) {
      // console.log(item)
      Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/viewdups?deviceId="+"deviceId="+item.devid+"controlId="+item.controlId+"rtime="+item.rtime+"typeofevent="+item.typeofevent+"randominitial="+item.randominitial+"enginerunstate="+item.enginerunstate
        )
        .then((res) => {
console.log(res.data)
        })
    },
      fullscreen() {
      var elem = document.getElementById("file");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    compimg(item) {
      var itemname = item.partnum;
      // Vue.axios
      //   .get(
      //     "https://robopower.xyz/us/a200comps/viewcomponent?image=" + itemname
      //   )
      //   .then((res) => {
      //     console.log(res.data);
      //     this.imageBytes = res.data
      //     this.imgdialog = true;
      //   });

      window.open(
          "https://robopower.xyz/us/a200comps/viewcomponent?image=" + itemname
        )
      
    },
    imgclose() {
      this.imgdialog = false;
      this.image = "";
    },
    isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    deleteComponents(components) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title:
          "Are you sure? You want to delete Component Part Number - " +
          components.partnum,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          console.log(result);
          this.$store.dispatch("deleteComponents", components.id).then(() => {
            this.mounteddata();
          });
          this.$swal(
            "Deleted",
            "You successfully deleted this file",
            "success"
          );
        } else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }
      });
    },
    mounteddata() {
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch("fetchComponents");
        this.$store.dispatch("fetchDuplogs");
      } else {
        this.$store.dispatch("fetchComponents");
      }
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("name", this.editedItem.name);
      formData.append("shortname", this.editedItem.shortname);
      formData.append("partnum", this.editedItem.partnum);
      formData.append("qty", this.editedItem.qty);
      formData.append("used", this.editedItem.used);
      formData.append("unused", this.editedItem.unused);
      formData.append("damaged", this.editedItem.damaged);
      formData.append("seller", this.editedItem.seller);
      formData.append("file", this.image);
      return formData;
    },
    initialize() {},

    editComponents(item) {
      this.editedIndex = this.components.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    uploadImage(e) {
      this.image = e;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch("createComponents", fd);
        this.$store.dispatch("fetchComponents");
      } else {
        var postdata = {
          cdata: fd,
          id: this.editedItem.id,
        };
        this.$store.dispatch("editComponents", postdata);
        this.$store.dispatch("fetchComponents");
      }
      this.close();
    },

    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        dense
        :headers="headers2"
        :items="duplogs"
        item-key="name"
        sort-by="name"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-form
              autocomplete="off"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-toolbar flat color="#26BB44" dark dense>
                <span style="font-size: 40%">
                  <v-toolbar-title>Duplogs</v-toolbar-title>
                </span>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-row>
                      <v-col cols="12" mx="4">
                        <v-layout justify-end>
                          <v-btn
                            color="primary"
                            class="sm-0"
                            v-on="on"
                            v-if="
                              $vuetify.breakpoint.md || $vuetify.breakpoint.lg
                            "
                          >
                            Add Component
                          </v-btn>
                          <v-icon v-else v-on="on" size="40px" color="primary"
                            >mdi-plus</v-icon
                          >
                        </v-layout>
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-toolbar flat color="#26BB44" class="" dark dense>
                      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              required
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.name"
                              label="Name*"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              required
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.shortname"
                              label="ShortName*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.partnum"
                              label="Part Number*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-on:keypress="isNumber($event)"
                              v-model="editedItem.qty"
                              label="Quantity"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-model="editedItem.used"
                              label="Used*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-model="editedItem.unused"
                              label="Unused*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-model="editedItem.damaged"
                              label="Damaged*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              required
                              v-model="editedItem.seller"
                              label="Seller*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-file-input
                              v-model="editedItem.file"
                              chips
                              accept="image/jpg"
                              label="Image"
                              @change="uploadImage"
                            />
                          </v-col>
                          <!-- <img v-if="item.imageUrl" :src="item.imageUrl" /> -->
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        :disabled="!valid"
                        @click="save"
                        text
                        >{{ buttonTitle }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="imgdialog" max-width="600px">
                  <v-card>
                    <v-toolbar flat color="#26BB44" class="" dark dense>
                      <div style="width: 100%; overflow: hidden">
                        <div style="float: left">
                          <v-toolbar-title>Component Image</v-toolbar-title>
                        </div>
                        <div style="float: right">
                        <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="black" size="25px">mdi-fullscreen</v-icon>
                          <v-btn
                            color="blue darken-1"
                            left
                            text
                            @click="imgclose"
                            >Cancel</v-btn>
                        </div>
                      </div>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <!-- <img
                            id="file"
                            
                            :src="imageBytes"
                           width="200px"
                            
                          /> -->
                          <img id="file" v-bind:src="imageBytes" v-if="imageBytes != 'No Image'"/> 
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </v-form>
          </v-card>
        </template>
        <template slot="item.edit" slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editComponents(item)">
            mdi-border-color
          </v-icon>
        </template>
        <template slot=item.viewdups slot-scope="{ item }">
          <v-icon class="mt-2" color="red" @click="viewevent(item)">
          mdi-note-outline
          </v-icon>
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon
            class="mr-2 mt-2"
            larger
            color="red"
            @click="deleteComponents(item)"
          >
            mdi-delete-circle
          </v-icon>
        </template>
        <template slot="item.file" slot-scope="{ item }">
          <v-icon class="mt-2" color="grren" @click="compimg(item)">
            mdi-file-image
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>

        <!-- <template v-slot:expanded-item="{ headers, item}">
          <td :colspan="headers.length" class="ma-0 pa-0">
           <v-list dense class="ma-0 pa-0">
               <v-list-item>
                  <v-list-item-content>ID:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeId }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Name:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeName }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>phone:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeMobile1 }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Phone 2:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeMobile2 }}</v-list-item-content>
                </v-list-item>
                                <v-list-item>
                  <v-list-item-content>Email:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeemail }}</v-list-item-content>
                </v-list-item>
                                <v-list-item>
                  <v-list-item-content>Address:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officeaddress }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Bar License 2:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.officelicense }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>Dealer:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.dealer }}</v-list-item-content>
                </v-list-item>
           </v-list>
           </td>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>
