<template>
  <div class="rtmp-player">
    <video
    ref="videoPlayer"
      id="rtmp-video"
      class="video-js vjs-default-skin"
      controls
      preload="auto"
      width="640"
      height="360"
    >
      <!-- Fallback content for browsers that don't support the video tag -->
      <source
        src="rtmp://151.106.108.250/live/abcdmykey"
        type="rtmp/mp4"
      />
    </video>
  </div>
</template>
<script>
import videojs from 'video.js';
import 'videojs-flash';

export default {
  name: 'RTMPPlayer',
  mounted() {
    // Initialize video.js
    this.player = videojs(this.$refs.videoPlayer, {  // Correct reference to the video element
      techOrder: ['flash'],
      autoplay: true,
      controls: true,
      sources: [
        {
          src: 'rtmp://151.106.108.250/live/abcdmykey',
          type: 'rtmp/mp4',
        },
      ],
      flash: {
        swf: 'https://cdnjs.cloudflare.com/ajax/libs/videojs-swf/5.4.1/video-js.swf',
      },
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
