<template>
  <v-container id="firmwares" fluid tag="section">
    
            <v-toolbar flat color="#00A3A3" dark dense class="hidden-sm-and-down mb-4" style="border-radius:20px">
              <v-toolbar-title>BACKUP'S</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <template v-slot:extension>
                <v-tabs v-model="tab"  centered>
                  <v-tabs-slider color="black"></v-tabs-slider>

                  <v-tab style="font-weight:bold">
                   Form 920
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    Form 922
                  </v-tab>                 
                </v-tabs>
              </template>
            </v-toolbar>

            <v-toolbar flat color="#00A3A3" dark dense class="hidden-md-and-up mb-4" style="border-radius:20px">
               <v-toolbar-title style="width:200px">BACKUPS</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>                 
              <template v-slot:extension>
                <v-tabs v-model="tab" centered>
                  <v-tabs-slider color="black" style="margin-Right:200px"></v-tabs-slider>
                   <v-tab style="font-weight:bold">
                   Form 920
                  </v-tab>
                   <v-tab style="font-weight:bold">
                    Form 922
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>
          <v-tabs-items v-model="tab">
              <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="form920"
                    class="elevation-1"
                  >
                  <template slot=item.view slot-scope="{ item }">
          <v-icon class="mt-2" color="#400080" @click="viewpdf(item,'acceptance')">
           mdi-file-pdf
          </v-icon>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>


             <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="form922"
                    class="elevation-1"
                  >
                  <template slot=item.view slot-scope="{ item }">
          <v-icon class="mt-2" color="#6666ff" @click="viewpdf(item,'removals')">
           mdi-file-pdf
          </v-icon>
        </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
    
    <v-dialog  persistent
      v-model="imgloading"
      width="325px" 
    >
      <v-card style="background-color:black;color: azure;">
        <v-card-title class="loading">{{loadingtext}}</v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pdfdialog" max-width="700px">
                <v-toolbar text color="#00A3A3" class="" dark dense>
              <v-toolbar-title v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.md">{{client}}</v-toolbar-title>
              <v-toolbar-title v-else style="font-size:12px">{{client}}</v-toolbar-title>
              <v-layout justify-end>
                 <v-btn color="black"  @click="Dclose" dark 
                     style="border-radius:20px" ><v-icon>mdi-close</v-icon> </v-btn>
                </v-layout>
                </v-toolbar>
                <img v-if="imageBytes" v-bind:src="imageBytes" /> 
              <!-- <div style="margin-left:350px;" v-else  class="loader"></div> -->
              </v-dialog> 
  </v-container>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/Base64/1.1.0/base64.min.js"></script>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'
export default {
  name: "Backups",
  data() {
    return {
      tab: null,
      dialog: false,
      file: "",
      // loading:"loading....",
      imgloading:false,
      uploadPercentage: null,
      cfile: "",
      version: "",
      myppf:null,
      comment: "",
      valid: true,
      loadingtext:"Please Wait Loading File",
      search: "",
      percent: 90,
      client:"",
      imageBytes:null,
      pdfdialog:false,
      headers: [
        // { text: "ID", value: "id" },
        { text: "FileName", value: "fileName" },
        { text: "TimeStamp", value: "timestamp" },
        { text: "Client", value: "client" },
        { text: 'Device', value: 'device' },
        { text: 'View', value: 'view' },
      ],
      
    };
  },
  computed: {
    ...mapGetters(["form920"]),
    ...mapGetters(["form922"]),
  },
  mounted() {
    this.$store.dispatch("fetchForm920"); // 920 form
    this.$store.dispatch("fetchForm922"); // 922 form
  },
  methods: { 
      Dclose(){
        this.pdfdialog = false;
        this.imageBytes = null;
        this.client = null
      },
    viewpdf(data,decision){
        // console.log(data)
        var that = this
        that.imgloading = true
      Vue.axios.get("https://robopower.xyz/us/acceptance/getb64?filename="+
      data.fileName+"&decision="+decision+"&client="+data.client)
      .then((res)=>{
            if(res.data != null){
              that.imgloading = false
              that.pdfdialog = true
              that.loadingtext = "please Wait Loading File"
            var img = res.data;
            that.client = data.client
            that.imageBytes = res.data;
            // pdf download part
              var imgData = img;
              var pdf = new jsPDF('p','mm','a4');
              var width = pdf.internal.pageSize.getWidth();
              var height = pdf.internal.pageSize.getHeight();
              pdf.addImage(imgData, 'JPEG', 0, 0,width,height);
              pdf.save(data.fileName);
              // image download part
              // var str = img.split(',')[1]
              //   console.log(str)
              //  var a = document.createElement("a"); //Create <a>
              //   a.href = "data:image/png;base64," + str; //Image Base64 Goes here
              //   a.download = "image.png"; //File name Here
              //   a.click(); //Downloaded fil 
            }else{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
      that.imgloading = false
    },2000)
    })
            }     
      }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
             
    }
  }
};
</script>
<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
   border-bottom: 16px solid #3498db;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* loading dots */
.loading:after {
  content: ' .';
  animation: dots 2s steps(1, end) infinite;
}

@keyframes dots {
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    62.5% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
}
.v-dialog {
  box-shadow: none !important;
}
</style>