<template>
    <div v-if="type == 'add'">
      <!-- <v-dialog v-model="dialog" max-width="500px"> -->
        <v-card>
          <v-card-title>
            <span class="headline">Select Operation</span>
          </v-card-title>
  
          <v-card-text>
            <v-container>
              <v-col>
                <v-row>
                  <v-select
                    v-model="selectedCommand"
                    :items="commands"
                    item-text="name"
                    item-value="command"
                    label="Select Operation"
                    required
                    :return-object="true"
                  ></v-select>
                </v-row>
                <v-row>
                  <v-datetime-picker
                    v-model="selectedDate"
                    label="Select Operation Date"
                    :format="'YYYY-MM-DD HH:mm:ss'"
                    required
                  ></v-datetime-picker>
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="proceed">
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      <!-- </v-dialog> -->
      <!-- <v-btn @click="dialog = true">Open Dialog</v-btn> -->
    </div>
    <div v-else>
        <v-card class="command-list-card">
      <v-card-title>
        <span class="headline">Command List</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addNewCommand">Add New Command</v-btn>
        <v-btn color="red" style="margin-left: 20px;"  @click="onClose">Close</v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="postcommands"
        class="elevation-1"
        item-key="id"
      >
        <template v-slot:item.operationdate="{ item }">
          {{ item.operationdate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editCommand(item)">
            <v-icon color="blue">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteCommand(item.id)">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>   
    </div>
  </template>
  
  <script>
//   import DatetimePicker from 'vue-datetime-picker';
import { mapGetters } from "vuex";
import DatetimePicker from "vuetify-datetime-picker";
import Vue from "vue";
import moment from "moment";
import Eventemit from "./eventemit";
import { watch } from "vue";
  Vue.use(DatetimePicker);
  export default {
    props:["item"],
    components: {
    //   DatetimePicker,
    },
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        type:"view",
        dialog: false,
        isEditedIndex:-1,
        selectedCommand: null, //form field
        selectedResponse: null, //form field
        selectedDate: null, //form field
        createddate:null, 
        commanditem:[],

        // operations:[],
        headers: [
        { text: "ID", value: "id" },
        { text: "Device ID", value: "deviceid" },
        { text: "Client ID", value: "clientid" },
        { text: "Command", value: "command" },
        { text: "Operation Date", value: "operationdate" },
        { text: "Ref By", value: "refby" },
        { text: "Actions", value: "actions", sortable: false },
      ],
        commands: [
          { command: '84', resp: '', name: 'Take Test' },
          { command: '90', resp: '', name: 'Lock device' },
          { command: '91', resp: '', name: 'Unlock Device' },
          { command: '84', resp: '', name: 'Take Test' },
          { command: '82', resp: '', name: 'Restart Device' },
          { command: '104', resp: '', name: 'Set OTP' },
          { command: '94', resp: '60,30,25', name: 'Device Config' },
          { command: '80', resp: '', name: 'Device Info' },
          { command: '88', resp: '', name: 'Bypass 5' },
          { command: '89', resp: '', name: 'UnBypass 5' },
        ],
      };
    },
    computed:{
      ...mapGetters(['postcommands']),
    },

    created() {
    // Eventemit.$on("postcommand", (data) => {
    //   console.log(data.commanditem, "Received Data");
    //   this.command(data);
    // });
  },

    mounted(){
      this.$store.dispatch('fetchpostCommands', this.item.id)
    },
    
    watch:{
        type(val){
            this.type = val;
        }
    },
    methods: {
   
      onClose() {
      // console.log(data.commanditem, "EEEEE");
      // EMIT
      this.$emit("onClose");
    },

  
         addNewCommand() {
            this.type = "add";
        },
        editCommand(item){
          console.log("check");
          console.log(this.commands.filter((red)=>red.command = item.command))
          this.type = "add"
          this.isEditedIndex = item.id;
          this.selectedCommand =this.commands.find((red)=>red.command = item.command);
          this.selectedResponse = item.resp;
          this.selectedDate = moment(item.operationdate,"MM/DD/YYYY HH:mm:ss").toDate();
          this.createddate = item.createddate;

        },
      async proceed() {
        // Fields: deviceid,clientid,command,resp,operationdate,refby,createddate,updateddate
console.log(this.selectedCommand)
console.log("Selected Date:", this.selectedDate);

        const payload = {
            deviceid:this.item.cbid,
            clientid:parseInt(this.item.id), 
          command: this.selectedCommand.command,
          resp:this.selectedCommand.resp,
          operationdate: moment(this.selectedDate).format("MM/DD/YYYY"),
          // operationdate: this.selectedDate,
          refby: this.user.userName,
          createddate:this.isEditedIndex == -1? moment(new Date()).format("MM/DD/YYYY HH:mm:ss"):this.createddate,
          updateddate:this.isEditedIndex == -1?"":moment(new Date()).format("MM/DD/YYYY HH:mm:ss")
          
        };
        console.log(payload)
        try {
          if(this.isEditedIndex == -1){
          this.$store.dispatch("createPostcommand",payload);
          setTimeout(()=>{
            this.type = "view";
            console.log('Data updated successfully!');
            this.dialog = false;
            this.isEditedIndex = -1,
        this.selectedCommand= null, //form field
        this.selectedRespons= null, //form field
        this.selectedDate= null, //form field
        this.createddate = null, 
            this.selectedCommand = '';
          },1000)
        } else{
          console.log("Yes edit")
          console.log(payload.clientid)
          console.log(this.isEditedIndex)
          var str = {
            "id":this.isEditedIndex,
            "data":payload

          }
          this.$store.dispatch("editPostcommand",str);
          setTimeout(()=>{
            this.type = "view";
            console.log('Data updated successfully!');
            this.dialog = false;
            this.isEditedIndex = -1,
        this.selectedCommand= null, //form field
        this.selectedRespons= null, //form field
        this.selectedDate= null, //form field
        this.createddate = null, 
            this.selectedCommand = '';
          },1000)

        }
        } catch (error) {
          console.error('Error:', error);
        }
        
      },
    },
  };
  </script>
  
  <style scoped>
.dialog-card {
  border-radius: 8px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.dialog-title {
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 10px;
}

.dialog-content {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.select-field,
.datetime-picker {
  margin-bottom: 20px;
}

.dialog-actions {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.open-dialog-btn {
  margin-top: 20px;
  font-weight: bold;
}
  