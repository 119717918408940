<script>
//import getInfo from "../scripts/getInfo";
import Vue from "vue";
import axios from "axios";
//  import Imagedmv from '../../public/dmv.png'

Vue.use(axios);
export default {
    name: "Californa",
    props:['editedItem','type'],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
        // img:Imagedmv
  }),

  computed: {
    
  },

  watch: {
  },

  created() {
      },
    mounted() {
      console.log(this.editedItem.dob)
      if(this.editedItem.dob == 'undefined / undefined / '){
        console.log("UNDEF")
      }
    console.log(this.editedItem.clientphone2)
  },

    methods: {

  },
};
</script>
<template>
  <v-container fluid>
    <div v-if="type=='install'" id="pdfcontent">
      <v-card-text>
        <v-container>
          <v-form
            autocomplete="off"
            ref="form2"
            style="width: 100%"
            class="my_text"
          >
            <div id="color" class="my_text">
              <div class="interlocktable">
                  <div class="interlockborder">
                  <div class="row">
                    <div class=border style="width: 300px; float: left;margin-left:20px;margin-top:15px">  
                              <p style="font-size:15px;padding-top:150px;padding-left:40px;">
                               <b>&nbsp;&nbsp;&nbsp;&nbsp;NOT VALID WITHOUT<br>
                               MANUFACTURER'S STAMP</b></p>
                            </div>
                    &nbsp;&nbsp;
                  
                    <!-- <div class="column"> -->
                      <!-- <div style="margin-left: 7px;margin-top:0px;"> -->
                        <v-col style="margin-top: 5%;">
                              <v-row>
                              <img src='../../public/dmv.png' height="50" style="margin-left: 18%;" />
                              <p style="margin-left:500px;margin-top: -28%;">920</p>
                              </v-row>
                              <v-row>
                              <b  style="margin-left:150px; font-size:11px;"><i>A Public Service Agency</i></b>
                            </v-row>
                            </v-col>
                        <div style="margin-top:-30px;margin-left:350px;">
                             <h3 style="font-size:20px;padding-left:30px;">VERIFICATION OF INSTALLATION
                               <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               IGINITION INTERLOCK<br/>&nbsp;&nbsp;&nbsp;<br/>&nbsp;&nbsp;&nbsp;
                               (See back for instructions)
                             </h3>
                          </div> 
                          <!-- </div> -->
                    <!-- </div> -->
                  </div>
                 <div class="start" style="margin-bottom:0px">
                            <p style="font-size:12px;">
                            DRIVER LICENSE NUMBER<br>&nbsp;&nbsp;&nbsp;<b style="font-size:15px">{{editedItem.dlnum}}</b></p>
                            </div>
                            <div class="interlocksection2"></div>
                  <div>
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      1&nbsp;&nbsp;&nbsp;&nbsp;DRIVER&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 620pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DRIVER NAME (FIRST,MIDDLE,LAST)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.client }}</b> 
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        SUFFIX (JR,SR,III)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.suffix }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 696pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MAILING ADDRESS(STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.clientAddress}}</b>
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        APARTMENT/SPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.space }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.city }}</b>
                      </p>
                    </td>
                    <td style="width: 144pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="width: 10pt;text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.state }}</b>
                      </p>
                    </td>
                    <td style="width: 194pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.zipcode }}</b>
                      </p>
                    </td>
                  </div>

                     <div class="dateinst">
                    <td style="width: 696pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        RESIDENCE ADDRESS &nbsp; (IF DIFFERENT FROM MAILING ADDRESS)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.rclientAddress}}</b>
                      </p>
                    </td>
                    <td style="width: 251pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                       APARTMENT/SPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rspace }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rcity }}</b>
                      </p>
                    </td>
                    <td style="width: 144pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="width: 10pt;text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rstate }}</b>
                      </p>
                    </td>
                    <td style="width: 194pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rzipcode }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 328pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        BIRTH DATE (MONTH,DAY,YEAR)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b v-if="editedItem.dob !='undefined / undefined / '">{{ editedItem.dob.replaceAll(",",'/').split('/').join(' / ') }}</b>
                        <b v-else></b>
                      </p>
                    </td>
                    <td style="width: 145pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        HOME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.clientphone1.replaceAll(" ", "&nbsp;")}}
                        </b>
                      </p>
                    </td>
                    <td style="width: 197pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        WORK TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{editedItem.clientphone2.replaceAll(" ", "&nbsp;")}}
                        </b>
                      </p>
                    </td>
                  </div>

                  <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #d9d9d9">SECTION
                      2&nbsp;MANUFACTURER/INSTALLER&nbsp;INFORMATION
                      The following facility installed this device manufactured by:
                    </h3>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MANUFACTURER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>SMART ALCO SOLUTIONS LLC</b>
                      </p>
                    </td>
                    <td style="width: 150pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DEVICE NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>ALCOBRAKE</b>
                      </p>
                    </td>
                    <td style="width: 195pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MODEL/SERIAL NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>A200</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 316pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.officeName}}</b>
                      </p>
                    </td>
                    <td style="width: 338pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        &nbsp;LICENSE NUMBER
                      </p>
                      <!-- <br/> -->
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;margin-top: 10px;">
                    <v-row>
                         <input type="checkbox" id="barnum" name="barnum" style="margin-left:5px;">
                              <label style="font-size:14px" for="vehicle1">&nbsp;BAR NUMBER:<u><b style="font-size:12px">&nbsp;&nbsp;{{editedItem.officelicensenumber}}</b></u></label>
                              &nbsp;
                          <input type="checkbox" id="barnum" name="barnum">
                              <label style="font-size:14px" for="barnum">&nbsp;BHGS NUMBER:__________</label>
                    </v-row>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 307pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER ADDRESS(STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.faddress}}</b>
                      </p>
                    </td>
                    <td style="width: 101pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fcity}}</b>
                      </p>
                    </td>
                    <td style="width: 140pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fstate}}</b>
                      </p>
                    </td>
                     <td style="width: 90pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIPCODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fzipcode}}</b>
                      </p>
                    </td>
                  </div>

                  <div class="interlocksection">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION 3&nbsp;&nbsp;IGINITION&nbsp;INTERLOCK&nbsp;&nbsp;DEVICE&nbsp;&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 360pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF INSTALLATION
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                    <td style="width: 390pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF NEXT MONITOR CHECK (OPTIONAL)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.nextcmonitordate }}</b>
                      </p>
                    </td>
                  </div>
                  <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION 4 &nbsp;VEHICLE INFORMATION&nbsp;&nbsp;&nbsp; An ignition interlock device was installed on the following  vehicle:
                    </h3>
                  </div>

                <div class="dateinst">
                    <td style="width: 205pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.make}}</b>
                      </p>
                    </td>
                    <td style="width: 180pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.year}}</b>
                      </p>
                    </td>
                    <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.regnum}}</b>
                      </p>
                    </td>
                     <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTIFICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.vin}}</b>
                      </p>
                    </td>
                  </div>
                    <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2">
                     &nbsp;SECTION 5 &nbsp;INSTALLER&nbsp;&nbsp;INSPECTION&nbsp;&nbsp;AFTER&nbsp;&nbsp;NOTICE &nbsp;&nbsp;OF &nbsp;&nbsp;NON-COMPLIANCE
                    </h3>
                  </div>

                  <div class="reduce">
                    <td style="width: 332pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF MOST RECENT NOTICE OF NON-COMPLIANCE
                      </p>
                      <p class="s3" style="text-indent: 0pt;text-align: left;"><b>{{editedItem.prohibstart}}</b></p>
                    </td>
                    <td style="width: 358pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF INSPECTION OF IGNITION INTERLOCK DEVICE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                       <b>{{editedItem.prohibend}}</b>
                      </p>
                    </td>
                  </div>

                <div class="reduce">
                  <td style="width: 720pt" colspan="1">
                    &nbsp;<input type="checkbox" id="interlockdevice" name="interlockdevice">
                <label for="interlockdevice">&nbsp;&nbsp;  An ignition interlock device remains installed in the above vehicle and is functioning properly.</label>
                <br/>
                    &nbsp;<input type="checkbox" id="interlockdevice" name="interlockdevice">
                <label for="interlockdevice">&nbsp;&nbsp;  The above driver is currently in compliance with the ignition interlock maintenance and calibration requirements.</label>
                    </td>
                  </div>

                    <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION 6&nbsp;UNAVAILABLE&nbsp;VEHICLE
                    </h3>
                  </div>

                <div class="dateinst">
                    <td style="width: 205pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE MAKE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.umake}}</b>
                      </p>
                    </td>
                    <td style="width: 165pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.uyear}}</b>
                      </p>
                    </td>
                    <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.uregnum}}</b>
                      </p>
                    </td>
                     <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTIFICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.uvin}}</b>
                      </p>
                    </td>
                  </div>

                   <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION 7&nbsp; INSTALLER USE ONLY
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 805pt">
                      <h4 style="margin-top:5px">&nbsp;&nbsp;&nbsp;I certify(or declare) under penalty of perjury under the law of the state of california that the foregoing is true and correct.</h4>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 366pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER PRINTEDNAME
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.installer.userName}}</b>
                      </p>
                    </td>
                    <td style="width: 390pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DAYTIME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.imobile }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 370pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLERS SIGNATURE
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;font-size: 20px;">
                        X
                      </p>
                    </td>
                    <td style="width: 390pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                  </div>
                   <div class="interlocksection">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION 8&nbsp;DMV&nbsp;USE&nbsp;ONLY
                    </h3>
                  </div>
                   <div class="dateinst">
                    <td style="width: 805pt">
                      <h4 style="margin-top:5px">&nbsp;&nbsp;&nbsp;I have reviewed the DL920 for completion and affirm this form to be ana original.</h4>
                    </td>
                  </div>
                   <div class="dateinst">
                    <td style="width: 356pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DMV EMPLOYEE SIGNATURE/OFFICE NUMBER 
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;font-size: 20px;">
                        X
                      </p>
                    </td>
                    <td style="width: 390pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">

                      </p>
                    </td>
                  </div>
                
                </div>
              </div>

               <div>
                 <h4 style="margin-left:20px;margin-top:5px"><b>DISTRIBUTE COPIES AS FOLLOWS:</b></h4>
                  <p style="margin-left:150px"><b>Original  : </b>Department of Motor Vehicles</p><b style="margin-left:150px">Photocopy : </b>Driver, Installer, Manufacturer or Manufacturer's Agent
                </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
   

                <!-- unintsall form -->
                     <div v-else id="pdfcontent">
      <v-card-text>
        <v-container>
          <v-form
            autocomplete="off"
            ref="form2"
            style="width: 100%"
            class="my_text"
          >
            <div id="color" class="my_text">
              <div class="interlocktable" style="margin-top: 5px">
                  <div class="interlockborder">
                    <div class="row">
                    <div class=border style="width: 300px; float: left;margin-left:20px;margin-top:15px">  
                              <p style="font-size:15px;padding-top:150px;padding-left:40px;">
                               <b>&nbsp;&nbsp;&nbsp;&nbsp;NOT VALID WITHOUT<br>
                               MANUFACTURER'S STAMP</b></p>
                            </div>
                    &nbsp;&nbsp;
                  
                    <!-- <div class="column"> -->
                      <!-- <div style="margin-left: 7px;margin-top:0px;"> -->
                        <v-col style="margin-top: 5%;">
                              <v-row>
                              <img src='../../public/dmv.png' height="50" style="margin-left: 18%;" />
                              <p style="margin-left:500px;margin-top: -28%;">920</p>
                              </v-row>
                              <v-row>
                              <b  style="margin-left:150px; font-size:11px;"><i>A Public Service Agency</i></b>
                            </v-row>
                            </v-col>
                        <div style="margin-top:-30px;margin-left:350px;">
                             <h3 style="font-size:20px;padding-left:30px;">VERIFICATION OF INSTALLATION
                               <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               IGINITION INTERLOCK<br/>&nbsp;&nbsp;&nbsp;<br/>&nbsp;&nbsp;&nbsp;
                               (See back for instructions)
                             </h3>
                          </div> 
                          <!-- </div> -->
                    <!-- </div> -->
                  </div>
                 <div class="start" style="margin-bottom:0pt;">
                            <p style="font-size:12px;">
                            DRIVER LICENSE NUMBER<br>&nbsp;&nbsp;&nbsp;<b style="font-size:15px">{{editedItem.dlnum}}</b></p>
                            </div>
                            <div class="interlocksection2">
                              </div>
                  <div class="interlocksection" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <h3 type="I" style="font-size: 15px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      1&nbsp;&nbsp;&nbsp;&nbsp;DRIVER'S&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="interlocksection" v-else>
                    <h3 type="I" style="font-size: 12px; background-color: #c4c2c2">
                      &nbsp;SECTION
                      1&nbsp;&nbsp;&nbsp;&nbsp;DRIVER'S&nbsp;INFORMATION
                    </h3>
                  </div>
                  
                  <div class="dateinst">
                    <td style="width: 629pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DRIVER’S NAME (FIRST,MIDDLE,LAST)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.client }}</b> 
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        SUFFIX (JR,SR,III)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.suffix }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 700pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MAILING ADDRESS(STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.clientAddress}}</b>
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        APPARMENTSPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.space }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.city }}</b>
                      </p>
                    </td>
                    <td style="width: 150pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="width: 10pt;text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.state }}</b>
                      </p>
                    </td>
                    <td style="width: 196pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.zipcode }}</b>
                      </p>
                    </td>
                  </div>

                     <div class="dateinst">
                    <td style="width: 700pt" colspan="1">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        RESIDENCE ADDRESS &nbsp; (IF DIFFERENT FROM MAILING ADDRESS)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.rclientAddress}}</b>
                      </p>
                    </td>
                    <td style="width: 250pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                       APARTMENT/SPACE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rspace }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rcity }}</b>
                      </p>
                    </td>
                    <td style="width: 150pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="width: 10pt;text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rstate }}</b>
                      </p>
                    </td>
                    <td style="width: 196pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIP CODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{ editedItem.rzipcode }}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        BIRTH DATE (MONTH,DAY,YEAR)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b v-if="editedItem.dob != 'undefined / undefined / '">{{ editedItem.dob.replaceAll(",",'/').split('/').join(' / ')  }}</b>
                        <b v-else></b>
                      </p>
                    </td>
                    <td style="width: 150pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        HOME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.clientphone1.replaceAll(" ", "&nbsp;")}}
                        </b>
                      </p>
                    </td>
                    <td style="width: 196pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        WORK TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        <b>{{editedItem.clientphone2.replaceAll(" ", "&nbsp;")}}
                        </b>
                      </p>
                    </td>
                  </div>

                  <div class="interlocksection" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #d9d9d9">SECTION
                      II&nbsp;&nbsp;MANUFACTURER/FACILITY&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="interlocksection" v-else>
                    <h3
                      type="I"
                      style="font-size: 12px; background-color: #d9d9d9">SECTION
                      II&nbsp;&nbsp;MANUFACTURER/FACILITY&nbsp;INFORMATION
                    </h3>
                  </div>

                  <!-- <div class="dateinst">
                    <td style="width: 725pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MANUFACTURER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>SMART ALCO SOLUTIONS LLC</b>
                      </p>
                    </td>
                  </div> -->

                  <div class="dateinst">
                    <td style="width: 325pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MANUFACTURER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>SMART ALCO SOLUTIONS LLC</b>
                      </p>
                    </td>
                    <td style="width: 150pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DEVICE NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>ALCOBRAKE</b>
                      </p>
                    </td>
                    <td style="width: 195pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        MODEL/SERIAL NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>A200</b>
                      </p>
                    </td>
                  </div>

                  <!-- <div class="dateinst">
                    <td style="width: 468pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        FACILITY NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.officeName}}</b>
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        BUREAU OF AUTOMOTIVE REPAIR NUMBER
                        
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                            <b>{{editedItem.officelicensenumber}}</b>                           
                      </p>
                    </td>
                  </div> -->

                  <div class="dateinst">
                    <td style="width: 316pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.officeName}}</b>
                      </p>
                    </td>
                    <td style="width: 338pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        &nbsp;LICENSE NUMBER<b style="font-size:14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{editedItem.officelicensenumber}}</b>
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                    <v-row>
                         <input type="checkbox" id="barnum" name="barnum" style="margin-left:20px">
                              <label for="vehicle1">&nbsp;BAR NUMBER:____________</label>
                              &nbsp;
                          <input type="checkbox" id="barnum" name="barnum">
                              <label for="barnum">&nbsp;BHGS NUMBER:_________</label>
                    </v-row>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 305pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER ADDRESS (STREET)
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.faddress}}</b>
                      </p>
                    </td>
                    <td style="width: 100pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      CITY
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fcity}}</b>
                      </p>
                    </td>
                    <td style="width: 140pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        STATE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fstate}}</b>
                      </p>
                    </td>
                     <td style="width: 90pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        ZIPCODE
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.fzipcode}}</b>
                      </p>
                    </td>
                  </div>

                    <div class="interlocksection" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #d9d9d9">SECTION
                      III&nbsp;&nbsp;REMOVAL/INSTALLATION&nbsp;INFORMATION
                    </h3>
                  </div>
                  <div class="interlocksection" v-else>
                    <h4
                      type="I"
                      style="font-size: 12px; background-color: #d9d9d9">SECTION
                      III&nbsp;&nbsp;REMOVAL/INSTALLATION&nbsp;INFORMATION
                    </h4>
                  </div>

                    <div class="dateinst" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <td style="width: 725pt">
                    
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;font-size: 10px;color: red;margin-top:10px">
                      <!-- <b style="margin-left:600px;color:black;font-size: 15px"></b> -->
                        THE IGNITION INTERLOCK DEVICE INSTALLED IN THE VEHICLE DESCRIBED BELOW WAS REMOVED ON:<u style="margin-left: 50px;font-size: 20px;">{{editedItem.prohibend}}</u>
                        
                      </p>
                      <span style="margin-left:600px;font-size:9px;">DATE</span>
                      
                      
                    </td>
                  </div>
                  <div class="dateinst" v-else>
                    <td style="width: 725pt">
                    
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;font-size: 12px;color: red;margin-top:10px">
                        THE IGNITION INTERLOCK DEVICE INSTALLED<br/> IN THE VEHICLE DESCRIBED<br/> BELOW WAS REMOVED ON :
                        <b style="margin-right:40px;color:black;font-size: 15px">{{editedItem.prohibend}}</b>
                      </p>
                      
                      
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.make}}</b>
                      </p>
                    </td>
                    <td style="width: 70pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.year}}</b>
                      </p>
                    </td>
                    <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.regnum}}</b>
                      </p>
                    </td>
                     <td style="width: 237pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <b>{{editedItem.vin}}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 725pt">
                    <h5>&nbsp;AN IGNITION INTERLOCK DEVICE WAS INSTALLED OR REMAINS INSTALLED IN ANOTHER VEHICLE DESCRIBED BELOW</h5>
                    </td>
                    </div>
                    <div class="dateinst">
                    <td style="width: 156pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE NAME
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <!-- <b>{{editedItem.make}}</b> -->
                      </p>
                    </td>
                    <td style="width: 70pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                      YEAR
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <!-- <b>{{editedItem.year}}</b> -->
                      </p>
                    </td>
                    <td style="width: 190pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        LICENSE PLATE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <!-- <b>{{editedItem.regnum}}</b> -->
                      </p>
                    </td>
                     <td style="width: 237pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        VEHICLE IDENTICATION NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt; text-align: left">
                        <!-- <b>{{editedItem.vin}}</b> -->
                      </p>
                    </td>
                  </div>

                   <div class="dateinst">
                    <td style="width: 455pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF INSTALLATION
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                    <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE OF NEXT MONITOR CHECK (OPTIONAL)
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.nextcmonitordate }}</b>
                      </p>
                    </td>
                  </div>

                  

                   <div class="interlocksection" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    <h3
                      type="I"
                      style="font-size: 15px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION IV&nbsp;  FACILITY USE ONLY
                    </h3>
                  </div>
                  <div class="interlocksection" v-else>
                    <h3
                      type="I"
                      style="font-size: 12px; background-color: #c4c2c2"
                    >
                      &nbsp;SECTION IV&nbsp;  FACILITY USE ONLY
                    </h3>
                  </div>
                  <div class="dateinst">
                    <td style="width: 705pt">
                      <p style="font-size: 14px;">&nbsp;<b>I certify(or declare) under penalty of perjury under the law of the state of california that the foregoing is true and correct.</b></p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 456pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER PRINTEDNAME
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.installer.userName}}</b>
                      </p>
                    </td>
                     <td style="width: 256pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DAYTIME TELEPHONE NUMBER
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{editedItem.imobile}}</b>
                      </p>
                    </td>
                  </div>

                  <div class="dateinst">
                    <td style="width: 356pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        INSTALLER SIGNATURE
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        
                      </p>
                    </td>
                     <td style="width: 346pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE SENT TO DMV
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <!-- <b>{{ editedItem.installDate }}</b> -->
                      </p>
                    </td>
                    <td style="width: 382pt">
                      <p class="s2" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
                        DATE
                      </p>
                      <p class="s3" style="text-indent: 0pt;line-height: 14pt;text-align: left;">
                        <b>{{ editedItem.installDate }}</b>
                      </p>
                    </td>
                  </div>
                
                </div>
              </div>

               <div>
                 <h4 style="margin-left:20px;margin-top:5px"><b>DISTRIBUTE COPIES AS FOLLOWS:</b></h4>
                  <p style="margin-left:60px"><b>original  : </b>Mail the original document to:<br/>Department of Motor Vehicles<br/>
                    Alcobrake Private Limited,<br/> 1421 Champion Drive Ste. 352, <br/>
                    Carrollton, TX 75006.<br/></p>
                  <p style="margin-left:60px"><b>photocopy : </b>Driver</p>
                  <p style="margin-left:60px"><b>photocopy : </b>Installer</p>
                  <p style="margin-left:60px"><b>photocopy : </b>Manufacturer or Manufacturer's Agent</p>
                </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
  </v-container>
</template>


<style scoped>
table {
  padding-top: 20px;
  padding-left: 5px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}
.interlocktable {
  padding-left: 5px;
  border-color: white;
  border-style: solid;
  border-width: 4px;
  position: center;
}
.interlockborder {
  border-style: solid;
  border-width: 3px;
}

.reduce {
  border-top: 2px solid #333333;
  /* border-bottom: 2px solid #333333; */
  align-items: center;
}
table,th,td {
  border: 1px solid black;
  border-collapse: collapse;
}
.content {
  align-items: center;
}
.interlocksection {
  border-top: 2px solid #333333;
  align-items: center;
}
.interlocksection2 {
  border-top: 2px solid #333333;
  align-items: center;
  margin-bottom:5px;
  width:681px
}
.dateinst {
  /* border-top: 2px solid #333333; */
  align-items: center;
  /* width: 1000px; */
}
.my_text {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.s2 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
  margin-bottom: -3px;
}
.s3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin-bottom: 0px;
}
.s5 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 9pt;
}
.start{
margin-left:680px;
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}
  .input-field input,
  table input {
    border-bottom: none;
  }
</style>