/* eslint-disable no-console */
<script src="https://js.stripe.com/v3"></script>
<script>
//import { use } from 'vue/types/umd';
import {
  StripeElementPayment,
  StripeElementCard,
} from "@vue-stripe/vue-stripe";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    driveupload: JSON.parse(localStorage.getItem("alcobrakeadminupload")),
    drivedetails: JSON.parse(localStorage.getItem("alcobrakeadmindrive")),
    loading:false,
    width: 2,
    radius: 10,
    padding: 24,
    lineCap: "round",
    gradientDirection: "top",
    fill: false,
    gradient: gradients[5],
    type: "trend",
    autoLineWidth: false,
    devices: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    calibrations: [],
    myimage:require('../../public/alcobrake.gif'),
    grantAccess:false,
    message:"",
    header:"",
    totalRevenue: 0,
    dialog: false,
    payind:null,
    dealerpassword:null,
    payingamount:0,
    pk: process.env.VUE_APP_STRIPE_KEY,
    elementsOptions: {
        appearance: {},
        clientsecret:"", // appearance options
      },
      dialog15:false,
      dialog2:false,
      iscreated2 :false,
    headers: [
      {
        text: "Office",
        align: "left",
        value: "officeName",
      },
      { text: "Device Id", value: "device" },
      { text: "Calibration Date", value: "calibrationDate" },
      { text: "Client", value: "client" },
      { text: "Vehicle", value: "regnum" },
    ],
    lockoutheaders:[
      {
        text: "DealerName",
        align: "left",
        value: "dealerName",
      },
      { text: "Sno", value: "sno" },
      { text: "Device Id", value: "devId" },
      { text: "Typeofevent", value: "typeofevent" },
      { text: "Rtime", value: "rtime" },
    ]
    
  }),
  /* eslint-disable no-console */
  created() {
    const reloaded = localStorage.getItem("alcobrakeadminreloader");
    if (reloaded !== "true") {
      localStorage.setItem("alcobrakeadminreloader", "true");
      location.reload();
    }
  },
  
  mounted(){
    if (this.user.role == "Admin" || this.user.role == "superadmin") {
       this.$store.dispatch("fetchDashboard");
       this.$store.dispatch("fetchalllockoutdevicesDashboard")
    } else if (this.user.role == "dealer" || this.user.role=="school") {
       this.$store.dispatch("fetchlockoutdevicesDashboard",this.user.dealerId);
       this.$store.dispatch("fetchdealerDashboard", this.user.dealerId);
    }
      else {
         this.$store.dispatch("fetchofficeDashboard", this.user.officeId);
    }
    // console.log("check and create invoices");
    // if (this.user.role == "Admin") {
    //   Vue.axios
    //     .get("https://robopower.xyz/us/fullclients/getallinstalledandsubscribedfullrclients")
    //     .then((response) => {
    //       if(response.data != null){
    //       var whdata = response.data;
    //       whdata.map((rem)=>{
    //         var installdate  = moment(rem.installDate).format("MM/YYYY");
    //         var todaysDate = moment(new Date()).format("MM/YYYY");
    //         const startDate = moment(installdate, 'MM/YYYY');
    //         const endDate = moment(todaysDate, 'MM/YYYY');
    //         //creating array
    //         const monthYearArray = [];
    //         let currentDate = startDate.clone();
    //         while (currentDate.isSameOrBefore(endDate)) {
    //           monthYearArray.push(currentDate.format('MM/YYYY'));
    //           currentDate.add(1, 'month');
    //         }

    //         Vue.axios
    //     .get("https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum="+rem.regnum)
    //     .then((resp)=>{
    //       // if(rem.sno=="612CA00150"){
    //       if(resp.data != null){
    //          var allinvoices = resp.data;
             
    //           monthYearArray.map((regs)=>{
    //      if(!this.isDateInInvoices(regs,allinvoices)){
    //       this.autoCreateInvoice(rem,regs);
    //      }
    //     })
    //   } else {
    //     this.autoCreatenewinstallationInvoice(rem,rem.installDate)
    //         // console.log("No INvoices")
    //         monthYearArray.shift()
    //         // console.log(monthYearArray)
    //         // console.log(rem.installDate);
    //         monthYearArray.map((regs)=>{
    //     //  if(!this.isDateInInvoices(regs,allinvoices)){
    //       this.autoCreateInvoice(rem,regs);
    //     //  }
    //           })
            
    //       }
    //       // }
    //     })

    //       })
    //       }

    //     })
    // } else {
      
    //   Vue.axios
    //     .get(
    //       "https://robopower.xyz/us/fullclients/getdealerinstalledfullrclients?dealerName=" +
    //         this.user.dealerId
    //     )
    //     .then((response) => {
    //       if(response.data != null){
    //       var whdata = response.data;
    //       whdata.map((rem)=>{
    //         var installdate  = moment(rem.installDate).format("MM/YYYY");
    //         var todaysDate = moment(new Date()).format("MM/YYYY");
    //         const startDate = moment(installdate, 'MM/YYYY');
    //         const endDate = moment(todaysDate, 'MM/YYYY');
    //         const monthYearArray = [];
    //         let currentDate = startDate.clone();
    //         while (currentDate.isSameOrBefore(endDate)) {
    //           monthYearArray.push(currentDate.format('MM/YYYY'));
    //           currentDate.add(1, 'month');
    //         }

    //         Vue.axios
    //     .get("https://robopower.xyz/us/cainvoices/getuniquerinvoice?regnum="+rem.regnum)
    //     .then((resp)=>{
    //       if(resp.data != null){
    //          var allinvoices = resp.data;
    //         //  if(rem.sno=="612CA00150"){
    //           monthYearArray.map((regs)=>{
    //      if(!this.isDateInInvoices(regs,allinvoices)){
    //       this.autoCreateInvoice(rem,regs);
    //      }
    //     })
    //   // }
    //       }
    //     })

    //       })
    //       }

    //     })
    // }
    // console.log(this.fullclients)

  },
   beforeMount() {
      Vue.axios.get("https://robopower.xyz/us/cadealerpayments/checkdealerpayment?dealer=" + 
      this.user.dealerId).then((res)=>{
        if(res.data == "1" || this.user.role == 'Admin' || this.user.role == 'superadmin'|| this.user.role == 'school'){
          this.grantAccess = true;
          // console.log("hiii1")
        }else if(res.data == "0" ){
          this.grantAccess = false;
          // console.log("hiii1")
          this.header = "Payment Pending"
          this.message="Your payment is currently pending";
          this.createIndent();
        }
        else{
          this.grantAccess = false;
          this.header = "Dealer Not Authorized"
          this.message = "Devices Not Assigned to the Dealer"
        }
      });
     
    
  },
  components:{

    StripeElementPayment,
    StripeElementCard,
  },
  computed: {
    ...mapGetters(["dashboard"]),
    ...mapGetters(["lockouts"]),
    ...mapGetters(["fullclients"])
  },
  watch:{
    payind(val){
      this.payind=val
    },
    dashboard(val){
      if(val){
        this.loading=true;
      }else {
        this.loading = false;
      }
    }

  },
  methods: { 
    clearclose(){
      this.dialog2 = false;
      this.dealerpassword =null;
    },
    pay() {
      const that = this;
      that.$refs.paymentRef.submit();
    },
    payclose() {
      if (this.$refs.paymentRef && this.$refs.paymentRef.clear) {
        this.$refs.paymentRef.clear();
      }
      Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid=25 &intent=" +
            this.payind
        )
        .then((res) => {
          // console.log(res)
          if (res.status == 200) {
            this.$store
              .dispatch("alert/error", "Payment Cancelled")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              this.dialog15 = false;
              this.payingamount = 0;
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
    },
    payCash(){
      this.dialog2 = true;
    },
    confirmPaycash(){
      if (this.user.emailId && this.dealerpassword) {
        var usermail = "admin@alcobrake.com";
        var userpassword = this.dealerpassword;
        Vue.axios
          .get(
            "https://robopower.xyz/us/calogin/getuniquelogin?emailId=" +
              usermail +
              "&password=" +
              userpassword
          )
          .then((response) => {
              if(response.data){
              var logindata = response.data[0];
              if (
                usermail == logindata.emailId &&
                this.dealerpassword == logindata.password
              ) {
                Vue.axios
        .get(
          "https://robopower.xyz/us/payments/cancelintentnew?dealerid=25&intent=" +
this.payind
        )
        .then((res) => {
          if (res.status == 200) {
            Vue.axios.post('https://robopower.xyz/us/cadealerpayments/clearpayments/' + this.user.dealerId)
        .then((res) => {
            // console.log(res.data)
          if (res.data == "Dealer Payment cleared") {
            var fData = new FormData();
            fData.append('to', this.user.emailId)
            fData.append('message', "Dear dealer, we are pleased to inform you that your outstanding payment has been successfully processed and cleared in cash mode of invoice " +this.payind+" -Alcobrake.")
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                // console.log(res.status)
                if (res.status == 200) {
                  setTimeout(() => {
                    this.$store
              .dispatch("alert/error", "Cash Collected")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              this.dealerpassword =null;
              this.dialog2 = false;
              window.location.reload();
                  }, 2000)
                }
              }).catch((err) => {
                alert(err)
              })

          }
        }).catch((err) => {
          alert(err)
        })

              //clear payment here
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
        });
            
              } else{
                this.$store
              .dispatch("alert/error", "Entered Incorrect Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              }
            } else{
              this.$store
              .dispatch("alert/error", "Entered Incorrect Password2")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }
      
    },
    payNow(){
      this.dialog15 = true;
      // console.log("dokdfnjkd")
      this.createIndent()
    },
    async createIndent(){
      await Vue.axios
        .get("https://robopower.xyz/us/cadealerpayments/getdealerinvoices?dealer="+ this.user.dealerId)
        .then(async (response) => {
          if(response.data != null){
          let sum = response.data.filter(transaction => transaction.paymentstatus === 'unpaid').reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.total);
          }, 0);
          this.payingamount = sum;
          var amt = sum.toFixed(2).toString();
          amt  = amt.replace(/\./g, "");
          await Vue.axios
            .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid=25&amount="+ amt) //dealership pyment goes to Admin i.e., Alcobrake
            .then((response) => {
             if (response.data.client_secret != "") {
                var that = this;
                that.payind = response.data.id;
                that.elementsOptions.clientSecret = response.data.client_secret;
                that.iscreated2 = true;
                // this.dialog15 = true;
              }
            })
            .catch((err) => {
                this.$store.dispatch("alert/error", err).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
             });
          });
      } else{
        alert("No Payments Created")
      }
      })
    },
    isServiceAvailable(searchService,services) {
      // console.log("searchService",services,"1")
      // Check if the search date exists in any of the invoices
      return services.some(service => {
        return service.service==searchService;
      });
    },
    isDateInInvoices(searchDate,invoices) {
      const searchMoment = moment(searchDate, 'MM/YYYY');

      // Check if the search date exists in any of the invoices
      return invoices.some(invoice => {
        // console.log(invoice)
        const invoiceMoment = moment(moment(invoice.date).format('MM/YYYY'), 'MM/YYYY');
        // console.log(invoiceMoment)
        // console.log(searchMoment)
        return (searchMoment.isSame(invoiceMoment) && (this.isServiceAvailable("Monitoring Fee Monthly",invoice.Service) ||this.isServiceAvailable("New-Installation",invoice.Service)));
      });
    },
    autoCreatenewinstallationInvoice(item,invoicedate){      
      var body;
      var newInfo= {
        invoiceId: null,
        office: item.officeId,
        client: item.client,
        date: invoicedate,
        service: [{
          actualamount: "75.00",
        description: "",
        discount: "0",
        invoiceId: null,
        service: "New-Installation",
        total:"75"
        }],
        regnum: item.regnum,
        total: "75.00",
        prevbalance: "75.00",
        grandtotal: "0.00",
        amountpaid: "0.00",
      }
        body = JSON.stringify(newInfo);
        this.$store.dispatch("createInvoice", body).then((resp) => {
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    autoCreateInvoice(item,invoicedate){
      // console.log(item.id)
      Vue.axios.get("https://robopower.xyz/us/cainvoices/cagetclientspecialrates?clientId=" + item.id)
      .then((res)=>{
        // console.log(res.data,'dhbidukfjmalkdfm')
        if(res.data.status === 'Active'){
          // console.log(res.data.status)
          const monthlyprice = res.data.pricelist.find(user => user.servicetype === 'Monitoring Fee Monthly');
          const monthlyprice1 =  monthlyprice ? monthlyprice.rate : null;
          const modifiedMoment = moment({
        year: moment(invoicedate,"MM/YYYY").year(),
        month: moment(invoicedate,"MM/YYYY").month(),
        date: 1,
      });
      // console.log(monthlyprice1,modifiedMoment)
      
      var body;
      var newInfo= {
        invoiceId: null,
        office: item.officeId,
        client: item.client,
        date: modifiedMoment.format('MM/DD/YYYY'),
        service: [{
          actualamount: monthlyprice1,
          description: "",
          discount: "0",
          invoiceId: null,
          service: "Monitoring Fee Monthly",
          total:monthlyprice1
        }],
        regnum: item.regnum,
        total: monthlyprice1,
        prevbalance: monthlyprice1,
        grandtotal: "0.00",
        amountpaid: "0.00",
      }
        }else{
          // console.log('else')
          const modifiedMoment = moment({
        year: moment(invoicedate,"MM/YYYY").year(),
        month: moment(invoicedate,"MM/YYYY").month(),
        date: 1,
      });
      
      var body;
      var newInfo= {
        invoiceId: null,
        office: item.officeId,
        client: item.client,
        date: modifiedMoment.format('MM/DD/YYYY'),
        service: [{
          actualamount: "75.00",
          description: "",
          discount: "0",
          invoiceId: null,
          service: "Monitoring Fee Monthly",
          total:"75"
        }],
        regnum: item.regnum,
        total: "75.00",
        prevbalance: "75.00",
        grandtotal: "0.00",
        amountpaid: "0.00",
      }

        }
        body = JSON.stringify(newInfo);
        this.$store.dispatch("createInvoice", body).then((resp) => {
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
                setTimeout(()=>{
                  this.$store.dispatch("alert/clear")
                },2000)
              })
            })
          })
        }
      },
      };
</script>
<template>
  <v-container v-if="loading">
    <v-container v-if="grantAccess" class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col colos="12">
          <v-row>
            <v-col md="3" align="center">
              <v-card align="center" class="rounded-circle border pt-10" width="250" height="250" flat>
                <v-card-text class="grey--text text-lg-h6 p-0">
                  <p>Total devices</p>
                  <span style="color:black;font-weight:bold">{{ dashboard.totaldevices }}</span>
                  <p>Available devices</p>
                  <span style="color:black;font-weight:bold">{{ dashboard.availabledevices }}</span>

                </v-card-text>
                <v-btn absolute color="#00A3A3" class="top-circle white--text" fab right top>
                  <v-icon color="white">
                    mdi-tablet-cellphone
                  </v-icon>
                </v-btn>

              </v-card>
            </v-col>
            <v-col md="3" align="center">
              <v-card align="center" class="rounded-circle border pt-10" width="250" height="250" flat>
                <v-card-text class="grey--text text-lg-h6 p-0">
                  <p>Revenue this month</p>
                  <span style="color:black;font-weight:bold">${{ dashboard.currentrevenue }}</span>
                  <p>Total revenue</p>
                  <span style="color:black;font-weight:bold">${{ dashboard.totalrevenue }}</span>

                </v-card-text>
                <v-btn absolute color="#00A3A3" class="top-circle white--text" fab right top>
                  <v-icon color="white">
                    mdi-cash-multiple
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col md="3" align="center">
              <v-card align="center" class="rounded-circle border pt-10" width="250" height="250" flat>
                <v-card-text class="grey--text text-lg-h6 p-0">
                  <p>New Clients(Last 30Days)</p>
                  <span style="color:black;font-weight:bold">{{ dashboard.newclients }}</span>
                  <p>Total Clients </p>
                  <span style="color:black;font-weight:bold">{{ dashboard.totalclients }}</span>

                </v-card-text>
                <v-btn absolute color="#00A3A3" class="top-circle white--text" fab right top>
                  <v-icon color="white">
                    mdi-account-group
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col md="3" align="center">
              <v-card align="center" class="rounded-circle border pt-10" width="250" height="250" flat>
                <v-card-text class="grey--text text-lg-h6 p-0">
                  <p>Total Installations</p>
                  <span style="color:black;font-weight:bold">{{ dashboard.newinstallations }}</span>
                  <p>Removals</p>
                  <span style="color:black;font-weight:bold">{{ dashboard.removalthismonth }}</span>

                </v-card-text>
                <v-btn absolute color="#00A3A3" class="top-circle white--text" fab right top>
                  <v-icon color="white">
                    mdi-cog-transfer
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="user.state != 'INDIA'">
        <v-col align="center">

          <v-card class="mt-6 mx-auto"
            style="margin-top:10%;border-radius:20px;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);">
            <v-sheet class="v-sheet--offset mx-auto" color="#00A3A3" elevation="12" max-width="calc(100% - 32px)" style="top: -30px;
              position: relative;">
              <v-sparkline :labels="dashboard.saleslabels" :value="dashboard.salescount" color="white" line-width="2"
                padding="16"></v-sparkline>
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="text-h5 font-weight-light mb-2" style="text-align:center;">
                <v-avatar color="green"><v-icon dark>mdi-sale</v-icon></v-avatar> Last 6 Months Sales Report
              </div>
            </v-card-text>
          </v-card>
          <!-- v-if="user.state != 'INDIA'" -->
          <v-card class="mt-4 rounded-circle border pt-10" width="250" height="250" flat>
            <v-card-text class=" grey--text  p-0">
              <div class="feature-div elevation-5">
                First In Camera Technology
              </div>
              <div class="feature-div elevation-5">
                Best in Class Reporting
              </div>
              <div class="feature-div elevation-5">
                GPS Tracking
              </div>
              <v-btn @click="dialog = true" color="#00A3A3" class="mt-2 white--text">
                View More
              </v-btn>
            </v-card-text>


          </v-card>


        </v-col>

        <!-- features dialog start -->
        <template>
          <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-toolbar flat dark color="#00A3A3">
              <v-btn icon dark @click="dialog = false" color="black" style="border-radius:20px;background:black">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title><v-img max-height="40" max-width="200"
                  src="../assets/Alogo.png"></v-img></v-toolbar-title>
              <v-spacer></v-spacer>

            </v-toolbar>
            <v-card style="background:#171717">

              <v-card-text>

                <v-row justify="center">
                  <v-col cols="12" sm="4" md="6" class="col-md-8">
                    <v-row>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-camera</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">First In Camera Technology</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <div class="content">
                              <p class="feature-content">Positive Photo
                                Identification. A cloud based optional facial recognition system is a technology capable
                                of identifying a person from a digital image weather concern person is taking BrAc test
                                or not.</p>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-map-marker</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">GPS Tracking</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <div class="content">
                              <p class="feature-content">Positive Photo
                                Identification. A cloud based optional facial recognition system is a technology capable
                                of identifying a person from a digital image weather concern person is taking BrAc test
                                or not.</p>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-information-outline</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">Best in Class Reporting</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <div class="content">
                              <p class="feature-content">Positive Photo
                                Identification. A cloud based optional facial recognition system is a technology capable
                                of identifying a person from a digital image weather concern person is taking BrAc test
                                or not.</p>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-face</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">AI Face recognition</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <div class="content">
                              <p class="feature-content">Positive Photo
                                Identification. A cloud based optional facial recognition system is a technology capable
                                of identifying a person from a digital image weather concern person is taking BrAc test
                                or not.</p>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-camera</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">First In Camera Technology</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <div class="content">
                              <p class="feature-content">Positive Photo
                                Identification. A cloud based optional facial recognition system is a technology capable
                                of identifying a person from a digital image weather concern person is taking BrAc test
                                or not.</p>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card width="344" height="350" outlined class="feature-card">
                          <v-card-title class="feature-card-title">
                            <v-row>
                              <v-col md="3"><v-icon size="60" color="white">mdi-camera</v-icon></v-col>
                              <v-col md="9">
                                <h5 class="feature-head">First In Camera Technology</h5>
                              </v-col>
                            </v-row></v-card-title>

                          <v-card-text class="mt-4">
                            <v-row><v-col>
                                <div class="content">
                                  <p class="feature-content">Positive Photo
                                    Identification. A cloud based optional facial recognition system is a technology
                                    capable of identifying a person from a digital image weather concern person is
                                    taking BrAc test or not.</p>
                                </div>
                              </v-col></v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>


                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>
          </v-dialog>
        </template>
        <!-- features dialog end -->

        <v-col class="col-md-7">
          <v-row>
            <v-col>
              <v-data-table dense :headers="headers" :items="dashboard.calibrations" sort-by="office_name"
                :items-per-page="5" :search="search" class="elevation-3 test"
                style="border-radius:20px 20px 20px 20px !important;">
                <template v-slot:top>
                  <v-toolbar color="" style="background:#00A3A3 !important;border-radius:20px !important" class="mb-3"
                    dark dense>
                    <v-toolbar-title>Upcoming Calibrations</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                      hide-details></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  <v-btn color="error">NO DATA</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- <v-row v-if="user.state != 'INDIA'"> -->
          <v-row>
            <v-col>
              <v-data-table dense :headers="lockoutheaders" :items="lockouts" sort-by="dealer_name" :items-per-page="5"
                :search="search" class="elevation-3" style="border-radius:20px 20px 20px 20px !important;">
                <template v-slot:top>

                  <v-toolbar style="background:#00A3A3 !important;border-radius:20px !important" class="mb-3" dark
                    dense>
                    <v-toolbar-title>Lockout Devices</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                      hide-details></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  <v-btn color="error">NO DATA</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-data-table dense :headers="lockoutheaders" :items="lockouts" sort-by="dealer_name" :items-per-page="5"
            :search="search" class="elevation-3" style="border-radius:20px 20px 20px 20px !important;">
            <template v-slot:top>

              <v-toolbar style="background:#00A3A3 !important;border-radius:20px !important" class="mb-3" dark dense>
                <v-toolbar-title>Lockout Devices</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              <v-btn color="error">NO DATA</v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col>
          <v-data-table dense :headers="headers" :items="dashboard.calibrations" sort-by="office_name"
            :items-per-page="5" :search="search" class="elevation-3 test"
            style="border-radius:20px 20px 20px 20px !important;">
            <template v-slot:top>
              <v-toolbar color="" style="background:#00A3A3 !important;border-radius:20px !important" class="mb-3" dark
                dense>
                <v-toolbar-title>Upcoming Calibrations</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              <v-btn color="error">NO DATA</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-dialog v-model="dialog2" max-width="800px">
        <v-card class="elevation-12">
          <v-toolbar text color="teal" class="" dark dense>
            <v-row>
              <v-toolbar-title style="margin-left: 10px; color: black"><v-icon color="white"
                  class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount ${{ payingamount }} </v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="primary" @click="clearclose" dark
                  v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
                <v-icon v-else color="primary" size="40px" @click="clearclose">
                  mdi-close
                </v-icon>
              </v-layout>
            </v-row>
          </v-toolbar>

          <!-- <template> -->

          <v-card-text style="margin-top:20px">
            <v-form>
              <!-- <v-text-field
              v-model="dealeremail"
              label="Email"
              name="dealeremail"
              prepend-icon="mdi-account"
              type="text"
            ></v-text-field> -->
              <!-- <div
                style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                "
              >
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount $xxx 
              </div> -->
              <v-text-field v-model="dealerpassword" prepend-icon="mdi-lock" name="dealerpassword" label="AdminPassword"
                counter @click:append="show2 = !show2"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-end>
              <v-btn color="#666600" @click="confirmPaycash" style="color: white">CASH PAID</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
        <!-- <v-card class="elevation-12">
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col class="col-md-8" align="center">
              <div
                style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                "
              >
                <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                are paying the Cash amount $xxx
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-card> -->
      </v-dialog>
      <v-dialog v-model="dialog15" max-width="800px">
        <v-card class="elevation-12">
          <v-col cols="12">
            <v-row class="justify-center">
              <v-col class="col-md-8" align="center">
                <div style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                ">
                  <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon>You
                  are paying the amount ${{ payingamount }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <div class="container">
            <stripe-element-payment v-if="iscreated2" ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
              :confirm-params="{
                return_url: `https://alcobrakeadmin.com/successdealertransaction?dealer=` + this.user.dealerId + `&email=` + this.user.emailId + `&paymentmode=online&paymentid=` + payind
              }" />
            <br />
            <v-layout justify-center>
              <!-- dialog paynow -->
              <v-btn class="mr-4" @click="payclose" dark style="background: #ff3d41; color: white">Cancel</v-btn>
              <v-btn class="primary" left @click="pay">Pay Now</v-btn>
            </v-layout>
          </div>
        </v-card>
      </v-dialog>
      <div>
        <h2>{{ this.header }}</h2>
        <p>{{ this.message }}. Please contact customer care for assistance.</p>
        <v-btn class="primary" @click="payNow" v-if="header == 'Payment Pending'"><v-icon color="white"
            class="mr-4">mdi-web</v-icon>PAY Online</v-btn>
        &nbsp;
        <v-btn class="success" @click="payCash" v-if="header == 'Payment Pending'"><v-icon color="white"
            class="mr-4">mdi-cash-multiple</v-icon>PAY Cash</v-btn>
        <v-btn class="primary" v-else>Send Request</v-btn>
        <div>
          <p>Contact Customer Care:</p>
          <ul>
            <li>Email: info@alcobrake.com</li>
            <li>Phone: +1 844-425-2627</li>
          </ul>
        </div>
      </div>
    </v-container>
  </v-container>
  <v-container v-else>
    <v-img width="700" class="load" :src="myimage" />
  </v-container>
</template>
<style scoped>
.border {
  border: 3px solid #00A3A3 !important;
}

.load {
  margin-left: 150px;
  margin-top: -95px;
}


.top-circle.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 100px !important;
}

.top-circle.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: -25px !important;
}

::v-deep .v-data-table-header tr {
  color: white !important;

}

.feature-div {
  background: rgb(65, 64, 64);
  color: white;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  padding: 2px;
  border-radius: 10px;
}

.feature-card {
  background: #2E2E2E;
  border: 0 !important;
  border: 0 !important;
}

.feature-card-title {
  height: 100px;
  border-bottom: 2px solid #171717;
  background: #2E2E2E;
}

.feature-head {
  color: white;
  text-align: left;
}

.feature-content {
  color: #8A8A8A;
  text-align: justify;
  font-size: 16px;
}

.content {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-behavior: smooth;
  height: 200px;
}

.content::-webkit-scrollbar {
  display: none;
}
</style>