<script>
//import getInfo from "../scripts/getInfo";
import Vue from "vue";
import { mapGetters } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  name: "Acceptance",
  components: {
    vueCustomScrollbar,
  },

  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    Dlnum: null,
    isbar:true,
    dob:"03/04/2022",
    mymobile:"612 386-2775",
    drivername: null,
    year: null,
    driveraddress: null,
    regid: null,
    tagstate: null,
    installdate: null,
    drivermobile: null,
    tag: null,
    vin: null,
    id: null,
    dialog: false,
    filteredClient: null,
    filteredState: null,
    manufacturer:null,
    page: 1,
    e1: 1,
    pageCount: 0,
    valid: true,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    isCheck: false,
    value: "",
    sign: "",

    calibration_periods: [
      { text: "30 days", value: 30 },
      { text: "60 days", value: 60 },
      { text: "90 days", value: 90 },
    ],

    return: {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    },
    headers: [
      { text: "ID", value: "Id" },
      {
        text: "DriverName",
        align: "left",
        value: "drivername",
      },
      { text: "Driver LicenseNo", value: "driver licenseno" },
      { text: "Mail", value: "mail" },
      { text: "Appartment No", value: "appartmentno" },
      { text: "City", value: "quantity" },
      { text: "state", value: "state" },
      { text: "Zip Code", value: "zipcode" },
      { text: "Address", value: "address" },
      { text: "DateofBirth", value: "dob" },
      { text: "Telephone No", value: "phoneno" },
      { text: "Work TelephoneNo", value: "telephoneno" },
      { text: "Date of installtion", value: "installation date" },
      { text: "Date of nextmonitor", value: "nextmonitor" },
      { text: "vehicle Name", value: "name" },
      { text: "year", value: "year" },
      { text:"manufacturer",value:"manufacturer"},
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    editedItem: {
      Dlnum: "",
      driverId: "",
      Id: "",
      drivername: "",
      driverlicenseno: "",
      mail: "",
      appartmentno: "",
      quantity: "",
      state: "",
      zipcode: "",
      address: "",
      dob: "",
      phoneno: "",
      telephoneno: "",
      installationdate: "",
      nextmonitor: "",
      name: "",
      year: "",
      plateno: "",
      identificationno: "",
      printedname: "",
      // datetimeno: "",
      installerssign: "",
      manufacturer:"",
      date: "",
    },
    defaultItem: {
      Id: "",
      drivername: "",
      driverlicenseno: "",
      mail: "",
      appartmentno: "",
      quantity: "",
      state: "",
      zipcode: "",
      address: "",
      dob: "",
      phoneno: "",
      telephoneno: "",
      installationdate: "",
      nextmonitor: "",
      name: "",
      year: "",
      plateno: "",
      identificationno: "",
      printedname: "",
      // datetimeno: "",
      installerssign: "",
      manufacturer:"",
      date: "",
    },
    birthdayRules: [(v) => !!v || "Select birthday date"],
    installationRules: [(v) => !!v || "Select date of installation"],
    nextmoniterRules: [(v) => !!v || "Select Next Moniter Date"],
    yearRuless: [(v) => !!v || "Select Year"],
    dateRuless: [(v) => !!v || "Select date"],

    motoristRules: [
      (v) => !!v || "Motorist is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    regRules: [
      (v) => !!v || "Registration number is required",
      (v) => (v && v.length == 10) || "Enter a valid Registration number",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) =>
        (v && v.length >= 10 && v.length <= 11) || "Enter valid phone number",
    ],
    addressRules: [
      (v) => !!v || "Address is required",
      (v) => (v && v.length < 100) || "Enter valid phone number",
    ],
    driverRules: [(v) => !!v || "Select drivers"],
    clientRules: [(v) => !!v || "Select client"],
    deviceRules: [(v) => !!v || "Select Device"],
    installDateRules: [(v) => !!v || "Select Install date"],
    calibDateRules: [(v) => !!v || "Select Calibration date"],
    makeRules: [
      (v) => !!v || "Make is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    modelRules: [
      (v) => !!v || "Model is required",
      (v) => (v && v.length >= 2) || "Name must contain 2 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    yearRules: [
      (v) => !!v || "Year is required",
      (v) => (v && v.length == 4) || "Enter valid Year",
    ],
    colorRules: [
      (v) => !!v || "Color is required",
      (v) =>
        (v && v.length >= 3) || "Color name must contain 3 or more characters",
    ],
    vinRules: [(v) => !!v || "vin is required"],
    tagRules: [(v) => !!v || "tag is required"],
    tagStateRules: [(v) => !!v || "Select Tag State"],
    dlRules: [
      (v) => !!v || "DL Number is required",
      (v) => (v && v.length >= 8) || "Enter a valid DL number",
    ],
    calibPRules: [(v) => !!v || "Select Calibration Period"],
  }),

  computed: {
    ...mapGetters(["drivers"]),
  },
  beforeMount() {
    this.$store.dispatch("fetchdealerDrivers", this.user.dealerId);
  },
  methods: {
    onPrint(){
        this.$htmlToPaper('printme');
    },
    updateRegion(value) { 
      this.Dlnum = value.Dlnum;
      this.drivername = value.Prohibs[0].Make;
      this.year = value.Prohibs[0].Year;
      this.driveraddress = value.Prohibs[0].Driveraddress;
      this.regid = value.Prohibs[0].Regid;
      this.tagstate = value.Prohibs[0].Tagstate;
      this.installdate = value.Prohibs[0].Installdate;
      this.drivermobile = value.Prohibs[0].Drivermobile;
      this.tag = value.Prohibs[0].Tag;
      this.vin = value.Prohibs[0].Vin;
    },
    onCancel() {
      this.isCheck = false;
      this.editedItem.driverId = ""
      this.undo();
      this.$refs.form.reset();
      this.$refs.form2.reset();
      this.e1 = 1;
      this.sign = "";
      this.dialog = false;
    },
    scrollHanle(evt) {
      {
        {
          evt;
        }
      }
    },
    download() {
      window.html2canvas = html2canvas;
      var pdf = new jsPDF("p", "pt", "letter", "A4");
      pdf.html(document.querySelector("#color"), {
        callback: function (pdf) {
          pdf.save("vehiclelicense.pdf");
        },
      });
    },
    opendialog() {
      this.dialog = true;
    },
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      // console.log("=== End ===");
      setTimeout(() => {
        this.save();
      }, 1000);
    },
    save() {
      var _this = this;
      this.$refs.signaturePad.resizeCanvas();
      var png = _this.$refs.signaturePad.saveSignature();
      this.sign = png.data;
    },
    undo() {
      var _this = this;
      _this.$refs.signaturePad.undoSignature();
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        sort-by="name"
        :search="search"
        class="elevation-1"
        item-key="Id"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#7fb833" dark dense>
              <v-toolbar-title>ACCEPTANCES</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            <v-btn color="blue" style="margin-left:30px" dark @click="opendialog"
                    >Add ACCEPTANCES</v-btn
                  >
              <v-dialog v-model="dialog" width="1000px">
                 <template>
                  
                </template>
                <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">Add Details</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">Signature</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12" height="600px">
                   
                  <v-toolbar flat color="#7fb833" class="" dark dense>
                    <v-toolbar-title>Driver and Vehicle Details</v-toolbar-title>
                  </v-toolbar>

                  <v-col>
                    <v-row>
                      <div>
                        <div>
                      <v-checkbox
                      label="Auto Fetch Details"
                      style="margin-left:20px"
                      v-model="isCheck"
                    ></v-checkbox>
                        </div>
                        <div v-if="isCheck">
                   <v-autocomplete
                              dense
                              v-model="editedItem.driverId"
                              required
                              @change="updateRegion(editedItem.driverId)"
                              :rules="driverRules"
                              :items="drivers"
                              
                              label="Select driver*"
                              item-text="Dlnum"
                              item-value="Dlnum"
                              return-object
                              style="margin-left:350px"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.Dlnum }}
                              </template>
                            </v-autocomplete>
                        </div>
                      </div>
                    </v-row>
                      </v-col>
                    
                   <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation>
                 <vue-custom-scrollbar class="scroll-area"  @ps-scroll-y="scrollHanle">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="regid"
                            :disabled="isCheck"
                            label="ID"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="drivername"
                            :disabled="isCheck"
                            label="Driver Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="Dlnum"
                            :disabled="isCheck"
                            label="Driver LicenseNo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.mail"
                            label="Mailing Address"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.appartmentno"
                            label="Appartment Space No"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.quantity"
                            label="City"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="tagstate"
                            :disabled="isCheck"
                            label="State"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="tag"
                            :disabled="isCheck"
                            label="Zip Code"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="driveraddress"
                            :disabled="isCheck"
                            label="Residence Address"
                          ></v-text-field>
                        </v-col>
                          <v-col cols="12" sm="12" md="6">
                          <v-menu
                            v-model="check1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                required
                                :rules="birthdayRules"
                                v-model="editedItem.dob"
                                label="Date of birth"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.dob"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="check1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        
                         <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="drivermobile"
                            :disabled="isCheck"
                            label="Telephone No"
                          ></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="drivermobile"
                            :disabled="isCheck"
                            label="work Telephone No"
                          ></v-text-field>
                        </v-col>
                         <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.manufacturer"
                            label="manufacturer"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu
                            v-model="check2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                required
                                :rules="installationRules"
                                :disabled="isCheck"
                                v-model="installdate"
                                label="Date of installation"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="installdate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="check2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu
                            v-model="check3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                required
                                :rules="nextmoniterRules"
                                v-model="editedItem.nextmonitor"
                                label="Date of Next Monitor"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                               v-model="editedItem.nextmonitor"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="check3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.name"
                            label="Vehicle Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                           :rules="yearRuless"
                                v-model="year"
                                :disabled="isCheck"
                                label="year"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.plateno"
                            label="License plateNumber"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="vin"
                            :disabled="isCheck"
                            label="vehicle identificationNumber"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="editedItem.printedname"
                            label="Installers Printed Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            v-model="drivermobile"
                            :disabled="isCheck"
                            label="DAYTIME Telephone No"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-menu
                            v-model="check5"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                required
                                :rules="dateRuless"
                                v-model="editedItem.date"
                                label="Date"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                               v-model="editedItem.date"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="check5 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    
                    </v-container>
                  </v-card-text>
                   </vue-custom-scrollbar>
                   </v-form>                
                  </v-card>
                  <v-btn text color="primary" @click="e1 = 2">Next</v-btn>
                   <v-btn color="primary" @click="onCancel">close</v-btn>
                </v-stepper-content>
                
                <v-stepper-content step="2">
                  <v-card class="mb-12" height="400px">
                      
                       <VueSignaturePad
                          id="signature"
                          width="100%"
                          height="400px"
                          ref="signaturePad"
                          :options="{ onBegin, onEnd }"
                        />
                         <br>
                  <div>
                  </div>
                  </v-card>
                  <v-btn color="primary" @click="e1=1">Previous</v-btn>&nbsp;&nbsp;&nbsp;
                    <v-btn color="primary" @click="save">Save</v-btn>&nbsp;&nbsp;&nbsp;
                    <v-btn color="primary" @click="undo">Undo</v-btn>&nbsp;&nbsp;&nbsp;
                    <v-btn color="primary" @click="onPrint">Print</v-btn>&nbsp;&nbsp;&nbsp;
                    <v-btn color="primary" @click="onCancel">Cancel</v-btn>
                    
                </v-stepper-content>
                 <v-stepper-content step="0">
                  <div id="printme" >
                  
                            <div style="margin-top:240px">
      <p style="font-size:20px;margin-left:850px;font-weight:bold;">LICENSE</p>
      <br/>
      <v-row style="margin-top:22px;">
        <v-col cols="9">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">DRIVER NAME</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">SUFFIX</p>
        </v-col>
      </v-row>
      
      <v-row style="margin-top:4px;">
        <v-col cols="9">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">MAILING ADDRESS NAME</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">APPARTMENT</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:4px;">
        <v-col cols="5">
        <p style="font-size:20px;margin-left:10px;font-weight:bold;">CITY</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">STATE</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">ZIPCODE</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:4px;">
        <v-col cols="9">
        <p style="font-size:20px;margin-left:20px;font-weight:bold;">RESIDENCE ADDRESS NAME</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">APPARTMENT</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <p style="font-size:20px;margin-left:20px;font-weight:bold;">CITY</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">STATE</p>
        </v-col>
        <v-col cols="3">
        <p style="font-size:20px;margin-left:10PX;font-weight:bold;">ZIPCODE</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4.5">
        <p style="font-size:20px;margin-left:30px;font-weight:bold;">{{dob.replaceAll("/",'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')}}</p>
        </v-col>
        <v-col cols="3.5">
        <p style="font-size:20px;font-weight:bold;">{{mymobile.replaceAll(" ",'&nbsp;&nbsp;')}}</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;font-weight:bold;">{{mymobile.replaceAll(" ",'&nbsp;&nbsp;')}}</p>
        </v-col>
      </v-row>

    <br/>
      <v-row style="margin-top:20px;">
        <v-col cols="12">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">ALCOBRAKE PRIVATE LIMITED</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:20px;">
        <v-col cols="5">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">ALCOBRAKE OFFICE</p>
        </v-col>
        <v-col cols="7">
          <v-row>
          <v-col style="margin-left:15px;margin-bottom:30px" cols="3">
          <v-row >
            <b-icon style="width:25px" icon="check"/>
          <!-- <v-checkbox v-model="isbar" input-value="1" ></v-checkbox> -->
          </v-row>
          <v-row>
          <b-icon style="width:25px" icon="check"/>
          </v-row>
          </v-col>
          <v-col cols="4">
            <p style="font-size:20px;margin-left:30PX;font-weight:bold;">BAR LICENSE</p>
          </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <p style="font-size:20px;margin-left:18px;font-weight:bold;"> FACILITY ADDRESS</p>
        </v-col>
        <v-col cols="4">
        <p style="font-size:20px;font-weight:bold;">FACILITY CITY</p>
        </v-col>
        <v-col cols="1.5">
        <p style="font-size:20px;margin-left:8PX;font-weight:bold;">STATE</p>
        </v-col>
        <v-col cols="1.5">
        <p style="font-size:20px;margin-left:5PX;font-weight:bold;">ZIPCODE</p>
        </v-col>
      </v-row>
           <v-row style="margin-top:40px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">Date of install</p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">Next monitor date</p>
        </v-col>
      </v-row>
       <v-row style="margin-top:60px;">
        <v-col cols="2.5">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">Vehicle Make</p>
        </v-col>
        <v-col cols="1">
          <p style="font-size:20px;font-weight:bold;">Year</p>
        </v-col>
        <v-col cols="2.5">
          <p style="font-size:20px;margin-left:10PX;font-weight:bold;">Plate num</p>
        </v-col><v-col cols="6">
          <p style="font-size:20px;font-weight:bold;">VIN NUMBER</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:40px;">
        <v-col cols="6">
          <p style="font-size:20px;margin-left:4PX;font-weight:bold;">Start Prohubition</p>
        </v-col>
        <v-col cols="6">
          <p style="font-size:20px;margin-left:4PX;font-weight:bold;">End Prohibition</p>
        </v-col>
      </v-row>
       <v-row style="margin-top:10px;">
        <b-icon style="width:25px" icon="check"/>
      </v-row>
      <v-row style="margin-top:5px;">
        <b-icon style="width:25px" icon="check"/>
      </v-row>
      <v-row style="margin-top:40px;">
        <v-col cols="3">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">UnAvailable</p>
        </v-col>
        <v-col cols="1">
          <p style="font-size:20px;font-weight:bold;">Year</p>
        </v-col>
        <v-col cols="2">
          <p style="font-size:20px;margin-left:10PX;font-weight:bold;">Plate num</p>
        </v-col><v-col cols="6">
          <p style="font-size:20px;font-weight:bold;">VIN NUMBER</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:60px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">INSTALLER NAME</p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;font-weight:bold;">{{mymobile.replaceAll(" ",'&nbsp;&nbsp;')}}</p>
        </v-col>
      </v-row>
      <v-row style="margin-top:30px;">
        <v-col cols="8">
          <p style="font-size:20px;margin-left:8PX;font-weight:bold;">INSTALLER SIGNATURE</p>
        </v-col>
        <v-col cols="4">
          <p style="font-size:20px;font-weight:bold;">02/03/2022</p>
        </v-col>
      </v-row>
  </div>
                  </div>
                         <!-- </div>

                          <div class = "lines">
                            <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION I&nbsp;&nbsp;&nbsp;&nbsp;DRIVER&nbsp;INFORMATION</h3></div>
                          </div>

    <div class="lines1" style="margin-top:10px">
        <div class="row align-items-start">
            <div class="col order-first">
              <p style="!important;font-size:20px;font-weight:bold" >DRIVER NAME</p>
            </div>
          <div class="col order-last">
            <p style="margin-left:300px !important;font-size:20px;font-weight:bold">SUFFIX</p>
          </div>
        </div>  
    </div>
    </div> -->
<!-- 
  <div class="lines1">
    <div class="row align-items-start" >
      <div class="col order-first">
        <p style="margin-left:210px !important;font-size:15px;font-weight:bold">AAAmail@gmail.com</p>
      </div>
      <div class="col order-last" >
        <p style="margin-left:280px !important;font-size:15px;font-weight:bold">AAA12/1222Appartment</p>
      </div>
    </div>
  </div>
                        
    <div class="lines1">
            <div class="row align-items-start" style="margin-top:3px;">
                      <div class="col order-first" >
                           <p style="margin-left:120px !important;font-size:15px;font-weight:bold;">Hyderabad</p>
                      </div>
                      <div class="col"   >
                           <p style="margin-left:200px !important;font-size:15px;font-weight:bold;">Telanagana</p>
                      </div>
                      <div class="col order-last" >
                        <p style="margin-left:140px !important;font-size:15px;font-weight:bold;">AAA50008</p>
                      </div>
            </div>
    </div>
                          <br/>
                          <div class="lines1">
                               <div class="row align-items-start" style="margin-top:-7px;" >
                              <div class="col order-first" >
                                <p style="margin-left:120px !important;font-size:15px;font-weight:bold">AAAMadhapur,Hyderabad,500036</p>
                              </div>
                              <div class="col order-last" >
                                <p style="margin-left:260px !important;font-size:15px;font-weight:bold">AAA12/1222Appartment</p>
                              </div>
        </div>
</div>
                          <div class="lines1">
                            <div class="row align-items-start">
                              <div class="col order-first">
                                  <p style="margin-left:120px !important;font-size:15px;font-weight:bold">AAAHyderabad</p>
                              </div>
                              <div class="col">
                               <p style="margin-left:200px !important;font-size:15px;font-weight:bold">AAATelanagana</p>
                              </div>
                              <div class="col order-last">
                                 <p style="margin-left:140px !important;font-size:15px;font-weight:bold">AAA50008</p>
                              </div>
                            </div>
                            </div>
                          <br/>
                           <div class="lines1">
                              <div class="row align-items-start"  style="margin-top:-15px;">
                            <div class="col order-first">
                              <p style="margin-left:120px !important;font-size:15px;font-weight:bold">23/10/2020</p>
                            </div>
                            <div class="col">
                              <p style="margin-left:150px !important;font-size:15px;font-weight:bold">9876543210</p>
                            </div>
                            <div class="col order-last">
                             <p style="margin-left:70px !important;font-size:15px;font-weight:bold">987654321</p>
                            </div>
                          </div>
                          </div>

                           <div class="lines">
                             <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION II&nbsp;&nbsp;MANUFACTURER/FACILITY&nbsp;INFORMATION&nbsp;The following facility installed this device manufactured by</h3>
                          </div>

                          
                           <div class="lines1">
                              <div class="row align-items-start" style="margin-top:10px;">
                                <div class="col-4">
                                  <p style="margin-left:150px !important;font-size:15px;font-weight:bold">Manufacturer</p>
                                </div>
                                 <div class="col-4">
                                  <p style="margin-left:135px !important;font-size:15px;font-weight:bold">Device name</p>
                                </div>
                                 <div class="col-4">
                                  <p style="margin-left:150px !important;font-size:15px;font-weight:bold">serial number</p>
                                </div>
                              </div>
                          </div>                      
                           <div class="middle">
                               <div class="row align-items-start" style="margin-top:8px;">
                                  <div class="col-4">
                                    <p style="margin-left:190px !important;font-size:15px;font-weight:bold">ALcobrake</p>
                                  </div>
                                    <div class="col-4">
                                      <b-icon icon="check" style="margin-left:46px;margin-top:12px;"></b-icon>
                                    </div>
                                    <div class="col-4">
                                    <b-icon icon="check" style="margin-left:14px;margin-top:12px;"></b-icon>
                                    </div>
                              </div>
                              </div>
                           <div class="lines1">
                             <div class="container">
                               <div class="row align-items-start" style="margin-top:5px">
                                <div class="col-5 order-first">
                                  <p  style="margin-left:115px !important;font-size:15px;font-weight:bold;">Madhapur,Hyderabad500045</p>
                                </div>
                                <div class="col-3">
                                  <p style="margin-left:60px;font-size:15x;font-weight:bold;">Hyderabad</p>
                                </div>
                                <div class="col-2">
                                <p style="margin-left:60px; !important;font-size:15px;font-weight:bold;">Telangana</p>
                                </div>
                                <div class="col-2">
                                  <p  style="margin-left:9px; !important;font-size:15px;font-weight:bold;">50008</p>
                                </div>
                              </div> 
                              </div>
                             
                          

                          </div>
                           <div class="lines">
                             <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION III&nbsp;&nbsp;IGINITION&nbsp;INTERLOCK&nbsp;&nbsp;DEVICE&nbsp;&nbsp;INFORMATION</h3>
                          </div>
                          <br/>
                           <div class="lines1">
                            <div class="row align-items-start">

                            <div class="col-6">
                              <p style="font-size:15px;font-weight:bold;margin-left:200px;">12/10/2022</p>
                            </div>
                            <div class="col-6">
                              <p style="font-size:15px;font-weight:bold;margin-left:170px;">23/04/2022</p>
                            </div>
                          </div>
                          </div>
                           <div class="lines">
                               <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION IV &nbsp;&nbsp;VEHICLE INFORMATION&nbsp;&nbsp;&nbsp; An ignition interlock device was installed on the following vehicle:</h3>
                          </div>
                          <br/>                           
                           <div class="lines1">
                              <div class="row align-items-center">
                                <div class="col-2">
                                      <p  style="margin-left:60px !important;font-size:15px;font-weight:bold;">CAR</p>
                                </div>
                                <div class="col-2">
                                      <p  style="font-size:15px;font-weight:bold;">2022</p>
                                </div>
                                <div class="col-3">
                                      <p  style="font-size:15px;font-weight:bold;margin-left:-47px;">TESD12344</p>
                                </div>
                                <div class="col-5">
                                  <p  style="font-size:15px;font-weight:bold;">123455</p>
                                </div>
                             </div>
                          </div>
                           
                           <div class="lines1">
                               <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION V&nbsp;&nbsp;FACILITY&nbsp;&nbsp;INSPECTION&nbsp;&nbsp;AFTER&nbsp;&nbsp;NOTICE &nbsp;&nbsp;OF &nbsp;&nbsp;NON-COMPLIANCE</h3>
                          </div>
                           <div class="lines1">
                               <div class="row align-items-start" style="margin-top:30px;">
                            <div class="col-6">
                              <p style="margin-left:120px !important;font-size:15px;font-weight:bold;">Dateofmost12/20/2022</p>
                            </div>
                            <div class="col-6">
                              <p style="font-size:15px;font-weight:bold;margin-left:130px;">DateofNext13/10/2022</p>
                            </div>
                           
                          </div>
                          </div>
                           <div class="middle">
                                 <div class="row align-items-start"  style="margin-top:-3px;">
                                   
                                   <div class="col-4" >
                                    <div class="col-4" >
                                      <b-icon icon="check" style="font-size:15px;font-weight:bold;margin-left:-23px;margin-top:-8px;"></b-icon>
                                    </div>
                                    <div class="col-4" >
                                    <b-icon icon="check" style="font-size:15px;font-weight:bold;margin-left:-23px;margin-top:-1px;"></b-icon>
                                    </div>
                                   </div>
                          </div>
                          </div>
                           <div class="lines">
                              <h3 style="font-size:15px;media:print;display:inline;;visibility:hidden;">SECTION VI&nbsp;&nbsp;UNAVAILABLE&nbsp;VEHICLE</h3>
                          </div>
                            <div class="lines1">
                            <div class="row align-items-center" style="margin-top:35px;">
                                <div class="col-2">
                                      <p  style="margin-left:60px !important;font-size:15px;font-weight:bold;">CAR</p>
                                </div>
                                <div class="col-2">
                                      <p  style="font-size:15px;font-weight:bold;">2022</p>
                                </div>
                                <div class="col-3">
                                      <p  style="margin-left:-47px !important;font-size:15px;font-weight:bold;">TESD12344</p>
                                </div>
                                <div class="col-5">
                                  <p  style="font-size:15px;font-weight:bold;;">123455</p>
                                </div>
                             </div>
                          </div>
                           <div class="lines">
                              <h3 style="font-size:15px;media:print;display:inline;visibility:hidden;">SECTION VII&nbsp;FACILITY&nbsp;USE&nbsp;ONLY</h3>
                          </div>
                           <div class="lines1">
                              <h4 style="media:print;display:inline;visibility:hidden;">I certify(or declare) under penalty of perjury under the law of the state of california that the foregoing is true and correct.</h4>
                              <div class="row align-items-start">
                                  <div class="col-6">
                                    <p style="font-size:15px;font-weight:bold;margin-left:250px;">name</p>
                                  </div>
                                  <div class="col-6">
                                    <p style="font-size:15px;font-weight:bold;margin-left:195px;">986754211</p>
                                  </div>
                                </div>
                              </div>
                      
                           <div class="lines1">
                             <div class="lastline">
                              <div class="row align-items-start">
                                  <div class="col-6">
                                    <p style="font-size:15px;font-weight:bold;margin-left:250px;"><img :src="sign" v-if="sign" height="40" width="150" /></p>
                                  </div>
                                  <div class="col-6">
                                    <p style="font-size:15px;font-weight:bold;margin-left:170px;">12/10/2020</p>
                                  </div>
                                </div>
                             </div>
                          </div>
                        </div>
                      <div>
                        <h4 hidden style="margin-left:10px;margin-top:5px"><b>DISTRIBUTE COPIES AS FOLLOWS:</b></h4>
                        <p hidden style="margin-left:50px"><b>original  : </b>Department of Motor Vehicles</p>
                        <p hidden style="margin-left:50px"><b>photocopy : </b>Driver Installer Manufacturer or Manufacturers Agent</p>
                      </div> -->
                     <!-- </div>
                    </v-container>
                  </v-card-text>
              </v-card> 
                </v-form>
                  </div>  -->
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
              </v-dialog>
            </v-toolbar>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 600px;
  height: 400px;
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.table {
  padding-top: 20px;
  padding-left: 5px;
  height: 1175px;
  border-color: green;
  border-style: solid;
  border-width: 4px;
  width: 99%;
}
.border {
  height: 200px;
  border-style: solid;
  width: 250px;
  border-width: 3px;
}
.form-check-input{
  height: 20px !important;
  width: 20px !important;
}
.lines {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  width: 860px;
  height: 30px;
  align-self: center;
}
.lines2 {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  margin-left: 70px;
  background-color: silver;
  width: 700px;
  height: 30px;
  align-self: center;
}
.lines3 {
  width: 762px;
  text-align: "justify";
}
.lines1 {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  width: 860px;
  height: 38px;
  align-self: center;
}
.start {
  border: 1px solid black;
  height: 35px;
  margin-left: 697px;
  border-bottom: 0;
  border-right: 0;
  /* display: block; */
  /* padding-left: 100px; */
  /* justify-content: flex-end; */
  width: 160px;
  /* background: #808080;
    border-right: 1px white;
    content: ''; */
}
.start1 {
  border: 1px solid black;
  height: 35px;
  margin-left: 697px;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  justify-content: flex-end;
  width: 160px;
}
.firstline {
  border-top: 2px solid #333333;
  width: 700px;
  height: 30px;
  align-self: center;
}

.middle {
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  width: 860px;
  height: 60px;
  align-self: center;
  font-size: 13px;
  font-family: courier;
  line-height: 11px;
}
.lastline {
  border-bottom: 2px solid #333333;
  width: 860px;
  align-self: center;
}

#row {
  color: black;
  font-size: 18px;
}

#color {
  color: black;
}
.lines1 p span {
  font-size: 13px;
  font-family: "courier";
  visibility: hidden;
}
.lines1 p b {
  font-size: 13px;
  margin-right: 12px;
}
.lines1 p {
  display: flex;
}
.paragraph {
  color: black;
  text-align: "center";
  margin-left: 50px;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}
</style>
