<script>
import Vue from "vue";
import moment from 'moment';
export default {
    name: "Swapdevice",
    props: ["myclient","mylogdata","close"],
      data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
          e1: 1,
          minutes: 0,
          seconds: 0,
          hours: 0,
          act: "",
          total: 0,
          pass: 0,
          fail: 0,
          items:[],
          setcount:0
  }),
  mounted() {
  this.setcount = setInterval(() => {
        this.mydata()
  }, 500)  
    },
    methods: {
      mydata() {
         // console.log(this.myclient)
          var newtime = moment(new Date()).format("MM/DD/YYYY HH:mm:ss")
          // console.log(newtime)
        var latestupdate = this.mylogdata[0].rtime
          // console.log(latestupdate)
          var result = moment(newtime).diff(moment(latestupdate), 'seconds')
          var result2 = moment(newtime).diff(moment(latestupdate), 'minutes')
          var result3 = moment(newtime).diff(moment(latestupdate), 'hours')
          // seconds
          var secondsdec = result - Math.floor(result)
          result = result - result2 * 60;
          var formattedseconds = ("0" + result).slice(-2) + secondsdec.toString().substr(1);
          this.seconds = formattedseconds
          // minutes
          var minutesdec = result2 - Math.floor(result2)
          result2 = result2 - result3 * 60;
          var formattedminutes = ("0" + result2).slice(-2) + minutesdec.toString().substr(1);
          this.minutes = formattedminutes
          // hours
          var hoursdec = result3 - Math.floor(result3)
          result3 = result3 - hoursdec;
          var formattedhours = ("0" + result3).slice(-4).substr(1)
              this.hours = formattedhours

            if (this.minutes < 15) {
                this.act = "Active"
                document.getElementById("changecolor").style.color = "blue";
            } else {
                this.act = "InActive"
                document.getElementById("changecolor").style.color = "red";
            }
      },
    logCommandread(item) {
        // console.log(item)
        const Swal = require("sweetalert2");
        Swal.fire({
          title:
            "Are you sure? You want to Perform an action on " +
            item.regnum +
            "Vehicle",
          text: "You can't revert your action",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            // console.log(result.value)
            this.pass = this.mylogdata.filter(
        (item) => item.passfailstatus === "PASS"
      ).length;
       this.fail = this.mylogdata.filter(
        (item) => item.passfailstatus === "FAIL"
            ).length;
            this.total = this.mylogdata.length
          }
        }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
          
      },
      closeswap() {
        this.close();
        this.total = 0;
        this.pass = 0;
        this.fail = 0;
        clearInterval(this.setcount)
        },
    }
  }
</script>

<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Logs Update
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Select new Device
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="e1 > 3">
        Copy Logs
      </v-stepper-step>

       <v-divider></v-divider>

      <v-stepper-step step="4" :complete="e1 > 4">
        Finish
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
    <v-card
   class="mb-12"
          color="grey lighten-1"
          height="230px"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div>
         <div style="width: 100%; display: table;">
    <div style="display: table-row">
        <div style="width: 800px; display: table-cell;"> 
            <h3>Device:<span>{{this.myclient.device}}</span></h3>
        &nbsp;
        <h3>last Update(HH:MM:SS):<span>{{this.hours}}:{{this.minutes}}:{{this.seconds}}</span></h3>
        &nbsp;
        <h3>Total Logs:<span>{{this.total}}</span></h3>
        &nbsp;
        <h3>Pass Logs:<span>{{this.pass}}</span></h3>
         &nbsp;
         </div>
        <div style="display: table-cell;"> 
        <v-card-subtitle id="changecolor" style="color: blue;font-size: large;">{{this.act}}</v-card-subtitle>
        </div>
    </div>
     <div style="display: table-row">
        <div style="width: 800px; display: table-cell;">
          <h3>Fail Logs:<span>{{this.fail}}</span></h3>
          </div>
          <div style="display: table-cell;"> 
          <v-btn color="primary" @click="logCommandread(myclient)">
          LOG READ
        </v-btn>
        </div>
          </div>

        </div> 
        
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
        <v-btn
        text
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>
        
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mb-12"
          color="grey lighten-1"
          height="200px"
        >
          <v-layout row wrap justify-center>
        <v-flex xs6>
       <v-col
        class="d-flex"
        cols="12"
        sm="12"
      >
        <!-- <v-select
          v-model="items"
          :items="items"
          label="Select New Device"
          item-text="items"
          dense
          outlined
          return-object
          style="margin-top:60px"
        ></v-select> -->
        <v-col cols="12">
          <v-autocomplete
            v-model="items"
            :items="items"
            label="Select New Device"
            outlined
            style="margin-top:50px"
          ></v-autocomplete>
        </v-col>
      </v-col>
      </v-flex>
      </v-layout>
      </v-card>

        <v-btn
          color="primary"
          @click="e1 = 3"
        >
          Continue
        </v-btn>

        <v-btn text @click="e1 = 1">
          previous
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          class="mb-12"
          color="grey lighten-1"
          height="200px"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <h3>Total Logs:<span>{{this.total}}</span></h3>
        &nbsp;
        <h3>Pass Logs:<span>{{this.pass}}</span></h3>
         &nbsp;
         <h3>Fail Logs:<span>{{this.fail}}</span></h3>
      </v-card>

        <v-btn
          color="primary"
          @click="e1 = 4"
        >
          Continue
        </v-btn>

        <v-btn text @click="e1 = 2">
          previous
        </v-btn>
      </v-stepper-content>
       <v-stepper-content step="4">
        <v-card
          class="mb-12"
          color="grey lighten-1"
          height="200px"
        ></v-card>

        <v-btn
          color="primary"
          @click="e1 = 1"
        >
          OK
        </v-btn>

        <v-btn text @click="e1 = 3">
          previous
        </v-btn>
      </v-stepper-content>
        <v-card-actions>
      <v-spacer class="d-flex justify-space-between align-end" />
      <v-btn color="primary" @click="closeswap">Close</v-btn>
    </v-card-actions>
    </v-stepper-items>
   
  </v-stepper>
</template>