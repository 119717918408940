<script>
// import getInfo from "../scripts/getInfo";
import { mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";
import moment from 'moment';
// import VueHtml2pdf from 'vue-html2pdf'
// import jsPDF from 'jspdf';
Vue.use(axios);
export default {
  data() {
    return {
      isSwitchOn: false,
      user: JSON.parse(localStorage.getItem("user")),
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      deviceoffice: "",
      selectoffice: false,
      loadingtext: "Please Wait",
      textloading: false,
      // content_control: ["Return from Service", "Send to Service","Available"],
      status: [
        "NotAssigned",
        "Installed",
        "Removed",
        // "Went to Service",
      ],
      show: false,
      messages: "",
      qrname: null,
      dialog1: false,
      states: [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "INDIA", // India Country with control board
        "INDIAV2", // India Country without control board
        "INDIAV3", // India Country with control board New Production
        "BZ" //brazil Country
      ],
      headers: [

      ],
      firmware: "",
      editedIndex: -1,
      dropdownOptions: [
        { text: 'America/Los_Angeles', value: 'America/Los_Angeles' },
        { text: 'Asia/Kolkata', value: 'Asia/Kolkata' },
        { text: 'GMT', value: 'GMT' },
        { text: 'America/Chicago', value: 'America/Chicago' }
      ],
      selectedOption: null, // Store the selected option here
      editedItem: {
        dealerName: "",
        dealerId: "",
        devId: "",
        cbid: "",
        cchipid: "",
        schipid: "",
        snostate: "",
        status: "",
        iswifi: "",
        sno: "",
        lastopdate: "",
        lastoperation: "",
        autotimeset: "",
        calibrationdate: "",
        lastiddownload: 0,
        lastsentdate: 0,
        timezone: '',
      },
      defaultItem: {
        dealerName: "",
        dealerId: "",
        devId: "",
      },
      valid: true,
      dealerRules: [(v) => !!v || "Select Dealer"],
      actionRules: [(v) => !!v || "Select action"],
      snoRules: [(v) => !!v || "Sno is required"],
      stateRules: [
        v => !!v || 'Select State',
      ],
      deviceRules: [
        (v) => !!v || "Device Id is required",
        v =>
          !this.devices.find(device => device.devId == v) ||
          this.editedIndex != -1 ||
          "Device Id already exists use different one"
      ],
      controlidRules: [
        (v) => !!v || "ControlBoard Id is required",
        v =>
          !this.devices.find(device => device.cbid == v) ||
          this.editedIndex != -1 ||
          "ControlBoard Id already exists use different one"
      ],
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Device" : "Edit Device";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "SAVE" : "UPDATE";
    },
    ...mapGetters(['devices']),
    ...mapGetters(['dealers']),
    ...mapGetters(['offices'])
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  beforeMount() {
    this.mounteddata();
  },
  mounted() {
    // console.log(this.user)
//     if(this.user.dealerId=="256"){
//       var documentid= "mozobus"
//       console.log("YES")
//       this.devices.map((dev)=>{
//         Vue.axios
//           .get(
//             "https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid=" +
//               dev.cbid
//           )
//           .then((response) => {
//             if (response.data != null) {
//               var calibdata = response.data;
//               calibdata = calibdata.sort(
//                 (a, b) =>
//                   moment(a.updatedtime).valueOf() -
//                   moment(b.updatedtime).valueOf()
//               );
//               var devparam = calibdata.reverse()[0];
//               console.log(devparam.alcooffset.String)
//             var obj={"fields":{}};
//     obj.fields.cbversion = {"stringValue":"4"};
//     obj.fields.deviceid = {"stringValue":dev.cbid};
//     obj.fields.model = {"stringValue":"A200"};
//     obj.fields.samplerid = {"stringValue":dev.devId};
//     obj.fields.status = {"stringValue":"unassigned"};
//     obj.fields.type={"stringValue":"owned"};
//     obj.fields.customer={"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/customers/'+documentid};
//     // var docref ={"referenceValue":'projects/alcobrakeuser/databases/(default)/documents/devices/'+dev.cbid};
//     Vue.axios.patch("https://firestore.googleapis.com/v1/projects/alcobrakeuser/databases/(default)/documents/devices/"+dev.cbid,obj).then((res2)=>{
//         console.log(res2)
//     })  
// var obj2={"fields":{}};
//     obj2.fields.alco_adc = {"stringValue":devparam.bracpeakfactor};
//     obj2.fields.alco_offset = {"stringValue":devparam.alcooffset.String};
//     obj2.fields.altitude = {"stringValue":devparam.altitude.String};
//     obj2.fields.amb_temp = {"stringValue":devparam.ambtemp.String};
//     obj2.fields.bmp = {"stringValue":devparam.altitude.String};
//     obj2.fields.calibration_date={"stringValue":devparam.caldate};
//     obj2.fields.fwversion = {"stringValue":devparam.fwversion};
//     obj2.fields.peakfactor = {"stringValue":devparam.bracpeakfactor};
//     obj2.fields.press_offset = {"stringValue":devparam.offset_pressure};
//     obj2.fields.samplerid = {"stringValue":dev.devId};
//     Vue.axios.patch("https://firestore.googleapis.com/v1/projects/alcobrakeuser/databases/(default)/documents/samplers/"+dev.devId,obj2).then((res2)=>{
//         console.log(res2)
//     }) 

//             }
//             })
//       })

//     }
    if (this.user.role == "Admin" || this.user.role == "superadmin") {
      this.headers = [
        {
          text: "sno",
          align: "left",
          value: "sno",
        },
        { text: "Dealer", value: "dealerName" },
        { text: "Device ID", value: "cbid" },
        { text: "HandSet", value: "devId" },
        { text: "Status", value: "status" },
        // { text: "Operation", value: "operation" },
        { text: "AutoTimeSet", value: "autotimeset" },
        { text: "TimeZone", value: "timezone" },
        { text: "COTA", value: "cota" },
        { text: "SOTA", value: "sota" },
        { text: "S3OTA", value: "s3ota" },
        { text: "Actions", value: "action", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
        { text: "Generate QR", value: "qr" },
        // { text: "Device Status", value: "toggle" }
      ]
    } else {
      this.headers = [
        {
          text: "sno",
          align: "left",
          value: "sno",
        },
        { text: "Dealer", value: "dealerName" },
        { text: "Device ID", value: "cbid" },
        { text: "HandSet", value: "devId" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action", sortable: false },
        // { text: "Delete", value: "delete", sortable: false },
        { text: "Generate QR", value: "qr" },
        // { text: "Device Status", value: "toggle" },

      ]
    }
  },

  methods: {
    toggleSwitch(item) {
      // You can perform additional actions here when the switch changes state
      console.log(this.isSwitchOn);
    },
    getSerialnum() {
      // console.log(this.editedItem.snostate)
      Vue.axios
        .get("https://robopower.xyz/us/cadevices/getserialnumber?state=" + this.editedItem.snostate)
        .then(response => {
          var mynum = parseInt(response.data) + 1
          var sno = "612" + this.editedItem.snostate + String(mynum).padStart(5, '0');
          // var sno = "612" + this.editedItem.snostate + String(mynum).padStart(5, '1000');
          if (this.editedItem.snostate) {
            this.editedItem.sno = sno;
          } else {
            this.editedItem.sno = "";
          }
        }).catch((err) => {
          this.$store.dispatch("alert/error", err).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        })
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    mounteddata() {
      if (this.user.role == "Admin" || this.user.role == "superadmin") {
        this.$store.dispatch('fetchDevices')
        this.$store.dispatch('fetchDealers')
      } else {
        this.$store.dispatch('fetchdealerDevices', this.user.dealerId)
        this.$store.dispatch('fetchDealers')
        // this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
      }
    },
    deleteDevice(device) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want To Delete This Device - " + device.devId,
        text: "You Can't Revert Your Action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteDevice', device.devId);
          this.$store.dispatch("alert/success", "You successfully deleted this file").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    initialize() { },
    qrclose() {
      this.messages = "";
      this.dialog1 = false;
    },
    changeContent(content) {
      if (content == "Return from Service") {
        this.editedItem.status = "Available";
      } else if (content == "Send to Service") {
        this.editedItem.status = "Sent to Service";
      } else {
        this.editedItem.status = "Available";
      }
    },
    onSelectoffice() {
      this.selectoffice = false;
    },
    onChangedealer(event) {
      if (this.editedIndex != -1) {
        this.editedItem.dealerId = event.dealerId;
        //get dealer offices
        this.$store.dispatch("fetchdealerOffices", event.dealerId).then((res) => {
          this.selectoffice = true;
        })
        // getDealeroffices(event.dealerId)
      }

    },
    getOffice(item) {
      Vue.axios
        .get("https://robopower.xyz/us/fullclients/getuniquedevicefullclient?sno=" + item.sno).then((res) => {
          if (res.data) {
            this.deviceoffice = res.data[0].officeId;
          } else {
            this.$store.dispatch("alert/success", "Device Not Assigned to Client").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          }
        })
    },
    editItem(item) {
      // console.log(item)
      this.getOffice(item)
      if (item.sno.length == 10) {
        var statesp = item.sno.split("")
        var state = statesp[3] + statesp[4];
        // console.log(state);
        this.editedItem.snostate = state;
      } else if(item.sno.length == 13) {
        var statesp2 = item.sno.split("")
        var state2 = statesp2[3] + statesp2[4] + statesp2[5] + statesp2[6] + statesp2[7];
        // console.log(state2);
        this.editedItem.snostate = state2;
      } else{
        var statesp3 = item.sno.split("")
        var state3 = statesp3[3] + statesp3[4] + statesp3[5] + statesp3[6] + statesp3[7] +statesp3[8] + statesp3[9];
        // console.log(state3);
        this.editedItem.snostate = state3;
      }
      this.editedIndex = this.devices.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem.sno = item.sno
      this.editedItem.dealerName = item;
      this.editedItem.dealerId = item.dealerid;
      this.editedItem.devId = item.devId;
      this.editedItem.cbid = item.cbid;
      this.editedItem.status = item.status;
      // this.editedItem.content_control = item.content_control;
      this.editedItem.calibrationdate = item.calibrationdate;
      this.editedItem.lastiddownload = item.lastiddownload;
      this.editedItem.lastsentdate = item.lastsentdate;
      this.editedItem.iswifi = item.iswifi;
      this.editedItem.timezone = item.timezone;
      this.editedItem.autotimeset = item.autotimeset;
      this.editedItem.lastoperation = item.lastoperation;
      this.editedItem.lastopdate = item.lastopdate;
      this.dialog = true;
    },
    samdoota(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do Sampler OTA - " + item.devId,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData = {
            devId: item.cbid,
            command: "103"
          };
          var cmdData2 = {
            devId: item.devId,
            command: "103"
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " Sampler OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", 'Your file is still intact').then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    cbdoota(item) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do Control Board OTA - " + item.cbid,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData2 = {
            devId: item.cbid,
            command: "98"
          };
          var cmdData = {
            devId: item.devId,
            command: "98"
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            this.$store.dispatch("alert/success", "OTA sent successfully").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          })
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " ControlBoard OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            this.$store.dispatch("alert/success", "OTA sent successfully").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
          })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })

        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },
    s3ota(item, command) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are You Sure? You Want Do S3 OTA - " + item.cbid,
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.textloading = true
          var cmdData2 = {
            devId: item.cbid,
            command: command,
            resp: "",
          };
          Vue.axios.post("https://robopower.xyz/app/commands", cmdData2).then((res) => {
            if (res.data == "OPERATION DONE") {
              this.textloading = false
              this.$store.dispatch("alert/success", " S3 OTA DONE SUCCESSFULLY").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            } else {
              this.textloading = false
              this.$store.dispatch("alert/success", " S3 OTA FAILED").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            }
            //     this.$store.dispatch("alert/success","OTA sent successfully").then(()=>{
            // setTimeout(()=>{
            //   this.$store.dispatch("alert/clear")
            // },2000)
            // })
          })
        } else {
          this.$store.dispatch("alert/error", "Your file is still intact").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })

        }
      }).catch((err) => {
        this.$store.dispatch("alert/error", err).then(() => {
          setTimeout(() => {
            this.$store.dispatch("alert/clear")
          }, 2000)
        })
      })
    },

    generateqr(qrcode) {
      this.qrname = qrcode
      var alldate = {
        sno: qrcode.sno,
        devId: qrcode.devId,
        cbid: qrcode.cbid,
        calibrationdate: qrcode.calibrationdate,
        status: qrcode.status,
        dealerName: qrcode.dealerName
      }
      this.messages = JSON.stringify(alldate)
      this.dialog1 = true;
    },
    qrprint() {
      this.$htmlToPaper("printme");
      this.dialog1 = false
    },

    close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.deviceoffice = "";
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("sno", this.editedItem.sno);
      formData.append("devId", this.editedItem.devId);
      formData.append("cbid", this.editedItem.cbid);
      if (this.editedIndex == -1) {
        formData.append("dealer", this.editedItem.dealerName.dealerId);
      } else {
        formData.append("dealer", this.editedItem.dealerName.dealerId);
      }
      formData.append("calibrationdate", this.editedItem.calibrationdate);
      formData.append("status", this.editedItem.status);
      formData.append("lastiddownload", this.editedItem.lastiddownload);
      formData.append("lastsentdate", this.editedItem.lastsentdate);
      formData.append("iswifi", this.editedItem.iswifi);
      formData.append("autotimeset", this.editedItem.autotimeset);
      formData.append("timezone", this.editedItem.timezone);
      formData.append("lastoperation", this.editedItem.lastoperation);
      formData.append("lastopdate", this.editedItem.lastopdate);
      return formData;
    },
    loadFormData2() {
      // console.log(this.editedItem)
      const formData = new FormData();
      formData.append("sno", this.editedItem.sno);
      formData.append("devId", this.editedItem.devId);
      formData.append("cbid", this.editedItem.cbid);
      if (this.editedIndex == -1) {
        formData.append("dealer", this.editedItem.dealerId);
      } else {
        // console.log(this.editedItem)
        formData.append("dealer", this.editedItem.dealerId);
      }
      formData.append("calibrationdate", this.editedItem.calibrationdate);
      formData.append("status", this.editedItem.status);
      formData.append("lastiddownload", this.editedItem.lastiddownload);
      formData.append("lastsentdate", this.editedItem.lastsentdate);
      formData.append("iswifi", this.editedItem.iswifi);
      formData.append("autotimeset", this.editedItem.autotimeset);
      formData.append("timezone", this.editedItem.timezone);
      formData.append("lastoperation", this.editedItem.lastoperation);
      formData.append("lastopdate", this.editedItem.lastopdate);
      formData.append("office", this.deviceoffice.officeId);
      return formData;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedIndex == -1) {
        const fd = this.loadFormData();
        this.$store.dispatch('createDevice', fd);
      } else {
        const fd = this.loadFormData2();
        var postdata = {
          devdata: fd,
          id: this.editedItem.sno
        }
        // console.log(fd)
        this.$store.dispatch('editDevice', postdata);
      }
      this.close();
    },
  },
};
</script>
<template>
  <v-container fluid>

    <v-data-table dense :headers="headers" :items="devices" sort-by="id" :search="search" class="elevation-1"
      style="border-radius:20px">
      <template v-slot:top>

        <v-toolbar flat color="#00A3A3" class="mb-4" dark dense style="border-radius:20px">
          <span style="font-size:40%">
            <v-toolbar-title>DEVICES</v-toolbar-title>
          </span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-row>
            <v-col cols="12" lg="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-dialog max-width="550px" v-model="selectoffice">
            <v-card>
              <div v-if="offices.length > 0">
                <!-- <h2>Please Select the Office </h2> -->
                <v-toolbar flat color="#00A3A3" class="" dark dense>
                  <v-toolbar-title>Please Select the Office</v-toolbar-title>

                </v-toolbar>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete outlined dense v-model="deviceoffice" required :items="offices" label="Select Office*"
                    item-text="officeName" return-object>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.officeName }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-row>
                    <v-btn class="error" @click="selectoffice = false">Close</v-btn>
                    <v-layout justify-end>
                      <v-btn class="primary" @click="onSelectoffice()">Select</v-btn>
                    </v-layout>
                  </v-row>
                </v-col>
              </div>
              <div v-else>
                No Office details
              </div>
            </v-card>
          </v-dialog>
          <v-dialog v-model="textloading" width="450px">
            <v-card style="background-color:black;color: azure;">
              <v-card-title class="justify-center">{{ loadingtext }}</v-card-title>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-row>
                <v-col cols="12" mx="4">
                  <v-layout justify-end>
                    <!-- <v-btn color="black"  class="sm-0" v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="border-radius:20px" >
                  Add Device
                </v-btn> -->
                    <v-btn v-if="user.role == 'Admin' || user.role == 'superadmin'" v-on="on" style="border-radius:20px">
                      <h2>+</h2><v-icon>mdi-tablet-cellphone</v-icon>
                    </v-btn>


                  </v-layout>
                </v-col>
              </v-row>
            </template>
            <v-card style="border-radius:20px">
              <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-form autocomplete="off" ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" >
                        <v-autocomplete outlined dense  required :rules="stateRules" @change="getSerialnum"
                          v-model="editedItem.snostate" :items="states" label="Select State*"></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field v-on:keypress="isLetter($event)" dense v-model="editedItem.sno" type="text" disabled
                          required :rules="snoRules" label="Sno*"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" v-if="user.role == 'Admin' || user.role == 'superadmin'">
                        <v-autocomplete outlined dense v-model="editedItem.dealerName" required :rules="dealerRules" 
                          :items="dealers" @change="onChangedealer($event)" label="Select Dealer*" item-text="dealerName"
                          return-object>
                          <template slot="selection" slot-scope="data">
                            {{ data.item.dealerName }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.devId"
                          type="text"  required :disabled="editedIndex != -1" :rules="deviceRules"
                          label="Device ID*"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.cbid"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Control Board ID*"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <!-- <v-select
                              max-width="200px"
                              dense
                              v-model="editedItem.status"
                              
                              @change="changeContent"
                              :items="content_control"
                              label="Select Action"
                            ></v-select> -->
                        <v-autocomplete outlined dense label="Select Action*" required class="purple-input"
                          :rules="actionRules" v-model="editedItem.status"
                          :items="['Installed', 'Removed', 'NotAssigned']">
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.cbid"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Control Board ID*"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined dense v-on:keypress="isLetter($event)" v-model="editedItem.cchipid"
                          type="text" required :disabled="editedIndex != -1" :rules="controlidRules"
                          label="Control Board Chip ID*"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.devId"
                          type="text" :disabled="editedIndex != -1" required :rules="deviceRules"
                          label="Sampler ID*"></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field outlined v-on:keypress="isLetter($event)" dense v-model="editedItem.schipid"
                          type="text" :disabled="editedIndex != -1" required :rules="deviceRules"
                          label="Sampler Chip ID*"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          v-model="selectedOption"
                          :items="dropdownOptions"
                          label="Select an option"
                          outlined
                          dense
                          required
                          :rules="[value => !!value || 'Please select an option']"
                        ></v-select>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close" style="background:#ff3d41;color:white">Cancel</v-btn>
                <v-btn color="blue darken-1" text :disabled="!valid" @click="save">{{ buttonTitle }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>

      </template>

      <template slot=item.operation slot-scope="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="short" color="#c9bfc9" v-if="item.lastoperation == ''" v-bind="attrs" v-on="on">
              {{ item.lastoperation == "" ? "NO OPERATION DONE" : item.lastoperation }}
            </v-btn>
            <v-btn class="short2" color="#00A300" v-else v-bind="attrs" v-on="on">
              {{ item.lastoperation == "97" ? "LOG READ" : item.lastoperation == "85" ? "VIOLATION_RESET" : item.lastoperation ==
                "82" ? "DEVICE RESTART"
                : item.lastoperation == "86" ? "TIME SET" : item.lastoperation == "80" ? "DEVICE INFO" : item.lastoperation ==
                  "94" ? "DEVICE CONFIG"
                  : item.lastoperation == "95" ? "DEVICE CONFIG" : item.lastoperation == "82" ? "LOG READ" : item.lastoperation ==
                    "96" ? "SET BRAC"
                    : item.lastoperation == "84" ? "TAKE TEST" : item.lastoperation == "88" ? "BYPASS ENABLE"
                      : item.lastoperation == "89" ? "BYPASS DISABLE" : item.lastoperation == "108" ? "AUTO CAL" : item.lastoperation ==
                        "107" ? "THRESHOLD"
                        : item.lastoperation == "90" ? "TAMPER ENABLE" : item.lastoperation == "91" ? "TAMPER DISABLE"
                          : item.lastoperation == "98" ? "CONTROL BOARD OTA" : item.lastoperation == "103" ? "SAMPLER OTA" : item.lastoperation
                            == "92" ? "LOG READ" : "NO OPERATION DONE" }}
            </v-btn>
          </template>
          <span>{{ item.lastoperation == "" ? "NA" : item.lastopdate }}</span>
        </v-tooltip>
      </template>

      <template slot=item.sota slot-scope="{ item }">
        <v-icon class="mt-2" color="orange" @click="samdoota(item)">
          mdi-chip
        </v-icon>

      </template>
      <template slot=item.cota slot-scope="{ item }">
        <v-icon class="mt-2" color="teal" @click="cbdoota(item)">
          mdi-chip
        </v-icon>

      </template>
      <template slot=item.s3ota slot-scope="{ item }">
        <v-icon class="mt-2" color="#445fc2" @click="s3ota(item, '107')">
          mdi-chip
        </v-icon>

      </template>
      <template slot=item.autotimeset slot-scope="{ item }">
        {{ item.autotimeset == 1 ? "ENABLE" : "DISABLE" }}

      </template>
      <template slot=item.action slot-scope="{ item }">
        <v-icon class="mt-2" color="orange" @click="editItem(item)">
          mdi-border-color
        </v-icon>

      </template>
      <template slot="item.delete" slot-scope="{ item }">
        <v-icon class="mr-2 mt-2" larger color="red" @click="deleteDevice(item)" disabled>
          mdi-delete-circle
        </v-icon>
      </template>
      <template slot="item.qr" slot-scope="{ item }">
        <v-icon class="mr-2 mt-2" larger color="red" @click="generateqr(item)">
          mdi-qrcode
        </v-icon>
      </template>
      <!-- <template v-slot:[`item.toggle`]="{ item }">
        <div>
          <label class="switch" :key="item">
            <input type="checkbox" v-model="isSwitchOn"  @change="toggleSwitch(item)">
            <span class="slider"></span>
          </label>
        </div>
      </template> -->
      <template v-slot:no-data>
        <v-btn color="error" @click="initialize">NO DATA</v-btn>
      </template>
    </v-data-table>

    <!-- this qr dialog -->
    <v-dialog v-model="dialog1" max-width="300px">
      <v-card style="border-radius:20px">
        <v-toolbar flat color="#00A3A3" class="" dark dense style="border-radius:20px">
          <v-toolbar-title>QR Generate</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex align-center justify-space-between">
          <v-card-text>
            <qr-code :text="messages" :value="messages" id="printme"></qr-code>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="qrclose" style="background:#ff3d41;color:white">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="qrprint">Print</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style scoped>
.short {
  width: 180px;
  height: 25px;
  color: black;
  font-weight: bold;
  justify-content: center;
}

.short2 {
  width: 180px;
  height: 25px;
  color: white;
  font-weight: bold;
  justify-content: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 2px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  /* left:2px; */
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #00A3A3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00A3A3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>