<script>
import {mapGetters} from "vuex";
//import moment from "moment";
import Vue from "vue"
export default {
  props:['itemss'],
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    model:null,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    offices: [],
    show: false,
    menu: false,
    caltrackdata:[],
    caltrack:false,
    headers: [
      {
        text: "Office",
        align: "left",
        value: "office"
      },
      { text: "Device ID", value: "deviceid" },
      { text: "Calibration Date", value: "calibrationDate" },
      { text: "Client", value: "client" },
      { text: "Vehicle", value: "regnum" },      
      { text: "Next Calibration", value: "nextcalibrationdate" },
      { text: "History", value: "history" },
      // { text: "Actions", value: "action", sortable: false }
    ],
    headers2: [
      {
        text: "Deviceid",
        align: "left",
        value: "devid"
      },
      { text: "Updated Time", value: "updatedtime" },
      { text: "Calibration Date", value: "caldate" },
      { text: "Brac Peak Factor", value: "bracpeakfactor" },
      { text: "Offset Pressure", value: "offset_pressure" },      
      { text: "F/W version", value: "fwversion"},
    ], 
    editedIndex: -1,
    search3:'',
    editedItem: {
      sno: "",
      calibrationDate: "",
    },
    defaultItem: {
      sno: "",
      calibrationDate: "",
    },
    valid: true,
     calibrationRules: [
      v => !!v || 'Select calibration date',
      ],
      deviceRules: [
        v => !!v || 'Select device id', 
      ],
  }),

  computed: {
    resultQuery() {
      if (this.search3) {
        return this.calibrations.filter((item) => {
          return item.deviceid.includes(this.search3);
        });
      } else {
        return this.calibrations;
      }
    
    },
    formTitle() {
      return this.editedIndex === -1 ? "Add Calibration" : "Edit Calibration";
    },
    ...mapGetters(['calibrations']),
    ...mapGetters(['devices'])
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.mounteddata();
  },

  methods: {
    mounteddata(){
    this.$store.dispatch('fetchclientCalibrations',this.itemss)
      
    },
    initialize() {},
    editItem(item) {
      /* eslint-disable no-console */
      this.editedIndex = this.calibrations.indexOf(item);
      this.editedItem.sno = item.sno;
      this.editedItem.calibrationDate = item.calibrationDate;
      this.dialog = true;
    },
    loadFormData() {
      /* eslint-disable no-console */
      const formData = new FormData();
      formData.append("sno", this.editedItem.sno);
      formData.append("calibrationdate", this.editedItem.calibrationDate);
      return formData;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
calTracking(id) {
  const mProm = new Promise((res,rej)=>{
  Vue.axios
    .get("https://robopower.xyz/v2/caltrack/getdevcaltracks2?cbid="+id.cbid)
    .then(response => {
      if(response.data != null){
        this.caltrackdata = response.data;
        res({status:"ok",vdata:response.data})
      } else{
        rej("Data Not Exists");
      }


    }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
  });
  mProm.then((red)=>{
    this.caltrack = true;
  }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })

  // this.caltrack = true;
},
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData();
      if (this.editedIndex != -1) {
      this.$store.dispatch('createCalibration',fd);
      }
      this.close()
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-dialog v-model="caltrack" max-width="800px">
              <v-data-table 
               dense
        :headers="headers2"
        :items="caltrackdata"
        sort-by="id"
        :search="search"
        class="elevation-1">
              </v-data-table>
              </v-dialog>
      <v-toolbar  light dense>
              <!-- <v-toolbar-title>INVOICES</v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
              <v-text-field
                v-model="search3"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              
              <v-spacer></v-spacer>
              <v-row v-if="model" >
                <v-btn color="primary"  @click="calTracking(model)"> View History
        <v-icon class="mt-2">
            mdi-border-color
          </v-icon>
                </v-btn>
          :&nbsp;
        </v-row>
                  <!-- <v-btn color="blue" dark @click="getId">Add Invoice</v-btn> -->
            </v-toolbar>
      <v-list v-if="calibrations.length>0"  dense class="ma-0 pa-0">
         <v-list-item-group
        v-model="model"
        
      >
      <!-- <v-list-item v-if="model"> -->
        
      <!-- </v-list-item> -->
      <v-list-item>
        <v-list-item-content>
                <v-list-item-title><b>Select</b></v-list-item-title>
              </v-list-item-content>
        <v-list-item-content>
                <v-list-item-title><b>Office</b></v-list-item-title>
              </v-list-item-content>
             <v-list-item-content>
                <v-list-item-title><b>DeviceId</b></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title><b>Calibration Date</b></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title><b>Client</b></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title><b>Vehicle</b></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title><b>Next Calibration Date</b></v-list-item-title>
              </v-list-item-content>                
    </v-list-item>
        <template v-for="(item, i) in resultQuery">
          <v-divider
            v-if="!item"
            :key="`divider-${i}`"
          ></v-divider>
   
          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item"
            active-class="deep-purple--text text--accent-4"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="deep-purple accent-4"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content style="margin-left:100px;">
                <v-list-item-title v-text="item.office"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.deviceid"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.calibrationDate"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.client"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.regnum"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.nextcalibrationdate"></v-list-item-title>
              </v-list-item-content>
              
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
            </v-list>
            <v-list v-else>
        <v-btn color="blue" style="margin-left:50%">NO DATA</v-btn>
      </v-list>
    </v-card>
  </v-container>
</template>
