<script>
//import getInfo from "../scripts/getInfo";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    sortby:'prohibitions',
    filteredClient: null,
    filteredState: null,
    page: 1,
    pageCount: 0,
    valid: true,
    itemsPerPage: 10,
    search: "",
    expanded: [],
    menusort:false,
    menux: false,
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    show: false,
    calibration_periods: [
      { text: "30 days", value: 30 },
      { text: "60 days", value: 60 },
      { text: "90 days", value: 90 },
    ],
    states: [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
      "INDIA"
    ],
    driverheaders: [
      { text: "DL number", align: "left", value: "Dlnum" },
      { text: "No.of Prohibitions", value: "count" },
      { text: "More..", value: "data-table-expand" },
    ],
    vehicleheaders: [
      { text: "Registration No", align:"left", value: "regnum" },
    { text: "No.of Prohibitions", value: "count" },
      { text: "More..", value: "data-table-expand" }
    ],
    headers: [
      // { text: "ID", value: "regId" },
      {
        text: "Prohibition No.",
        align: "left",
        value: "prohibitionnumber",
      },
      { text: "Client", value: "client" },
      { text: "Registration No", value: "regnum" },
      { text: "Device ID", value: "device" },
      { text: "Install Date", value: "installDate" },
      { text: "Calibration Date", value: "calibrationDate" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
      { text: "More..", value: "data-table-expand" },
    ],
    editedIndex: -1,
    editedItem: {
      regId: "",
      client: "",
      regnum: "",
      device: "",
      installDate: "",
      calibrationDate: "",
      make: "",
      model: "",
      year: "",
      color: "",
      vin: "",
      tag: "",
      tagstate: "",
      motorist: "",
      DLnum: "",
      driverMobile: "",
      driverAddress: "",
      prohibitionnumber: "",
      prohibstart: "",
      prohibend: "",
      conviction: "",
      calibrationperiod: "",
    },
    defaultItem: {
      regId: "",
      client: "",
      regnum: "",
      device: "",
      installDate: "",
      calibrationDate: "",
      make: "",
      model: "",
      year: "",
      color: "",
      vin: "",
      tag: "",
      tagstate: "",
      motorist: "",
      DLnum: "",
      driverMobile: "",
      driverAddress: "",
      prohibitionnumber: "",
      prohibstart: "",
      prohibend: "",
      conviction: "",
      calibrationperiod: "",
    },
    sorts:['prohibitions','drivers','vehicles'],
    motoristRules: [
      (v) => !!v || "Motorist is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    regRules: [
      (v) => !!v || "Registration number is required",
      (v) => (v && v.length > 5 && v.length < 15) || "Enter a valid Registration number",
    ],
    phoneRules: [
        v => !!v || "Phone is Required",
        v => /^[0-9+]\d*$/.test(v) || "Phone Number is invalid",
        v => v != 0 || "Phone Number can't be zeoro!"
      ],
    addressRules: [
      (v) => !!v || "Address is required",
      (v) => (v && v.length < 100) || "Enter valid phone number",
    ],
    clientRules: [(v) => !!v || "Select client"],
    deviceRules: [(v) => !!v || "Select Device"],
    installDateRules: [(v) => !!v || "Select Install date"],
    calibDateRules: [(v) => !!v || "Select Calibration date"],
    makeRules: [
      (v) => !!v || "Make is required",
      (v) => (v && v.length >= 3) || "Name must contain 3 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    modelRules: [
      (v) => !!v || "Model is required",
      (v) => (v && v.length >= 2) || "Name must contain 2 or more characters",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    yearRules: [
      (v) => !!v || "Year is required",
      (v) => (v && v.length == 4) || "Enter valid Year",
    ],
    colorRules: [
      (v) => !!v || "Color is required",
      (v) =>
        (v && v.length >= 3) || "Color name must contain 3 or more characters",
    ],
    vinRules: [(v) => !!v || "vin is required"],
    tagRules: [(v) => !!v || "tag is required"],
    tagStateRules: [(v) => !!v || "Select Tag State"],
    dlRules: [
      (v) => !!v || "DL Number is required",
      (v) => (v && v.length >= 8) || "Enter a valid DL number",
    ],
    calibPRules: [(v) => !!v || "Select Calibration Period"],
  }),

  computed: {
     formatDate() {
    return this.editedItem.calibrationDate
      ? moment(this.editedItem.calibrationDate).format("MM/DD/YYYY HH:mm:ss")
      : "";
  },
    formTitle() {
      return this.editedIndex === -1 ? "Add Prohibition" : "Edit Prohibition";
    },
    ...mapGetters(["prohibitions"]),
    ...mapGetters(["clients"]),
    ...mapGetters(["devices"]),
    ...mapGetters(["drivers"]),
    ...mapGetters(["vehicles"]),
    resultQuery() {
      if (this.filteredState && !this.filteredClient) {
        return this.prohibitions.filter((item) => {
          return item.tagstate.includes(this.filteredState);
        });
      } else if (this.filteredClient && !this.filteredState) {
        return this.prohibitions.filter((item) => {
          return item.client.includes(this.filteredClient);
        });
      } else if (this.filteredClient && this.filteredState) {
        return this.prohibitions.filter((item) => {
          return (
            item.client.includes(this.filteredClient) &&
            item.tagstate.includes(this.filteredState)
          );
        });
      } else {
        return this.prohibitions;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  beforeMount() {
    /* eslint-disable no-console */
    this.mounteddata();
  },
  methods: {
    isonlyLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9_.-\s]*$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    removeSortby() {
      this.sortby = 'prohibitions';
      this.menusort = false;
    },
    removeFilters() {
      this.filteredClient = null;
      this.filteredState = null;
      this.menux = false;
    },
    mounteddata() {
      if (this.user.role == "Admin"|| this.user.role == 'superadmin') {
        this.$store.dispatch("fetchProhibitions");
        this.$store.dispatch("fetchClients");
        this.$store.dispatch("fetchDrivers");
        this.$store.dispatch('fetchVehicles');
      } else {
        this.$store.dispatch("fetchdealerProhibitions", this.user.dealerId);
              this.$store.dispatch('fetchdealerVehicles',this.user.dealerId)
        this.$store.dispatch("fetchdealerClients", this.user.dealerId);
                this.$store.dispatch("fetchdealerDrivers", this.user.dealerId);
      }
    },
    getclientDevices() {
      this.$store.dispatch("fetchDevices");
    },
    deleteProhibition(proh) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to delete Prohibition - " + proh.prohibitionnumber,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteProhibition", proh.regId).then(() => {
            this.mounteddata();
          }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
          // this.$swal(
          //   "Deleted",
          //   "You successfully deleted this file",
          //   "success"
          // );
           this.$store.dispatch("alert/succes", 'You successfully deleted this file').then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("regId", this.editedItem.regId);
      formData.append("client", this.editedItem.client.clientId);
      formData.append("regnum", this.editedItem.regnum);
      formData.append("device", this.editedItem.device);
      formData.append("installDate", this.editedItem.installDate);
      formData.append("make", this.editedItem.make);
      formData.append("model", this.editedItem.model);
      formData.append("year", this.editedItem.year);
      formData.append("color", this.editedItem.color);
      formData.append("vin", this.editedItem.vin);
      formData.append("tag", this.editedItem.tag);
      formData.append("tagstate", this.editedItem.tagstate);
      formData.append("motorist", this.editedItem.motorist);
      formData.append("DLnum", this.editedItem.DLnum);
      formData.append("driverMobile", this.editedItem.driverMobile);
      formData.append("driverAddress", this.editedItem.driverAddress);
      formData.append("prohibitionnumber", this.editedItem.prohibitionnumber);
      formData.append("prohibstart", this.editedItem.prohibstart);
      formData.append("prohibend", this.editedItem.prohibend);
      formData.append("conviction", this.editedItem.conviction);
      formData.append("calibrationDate", this.formatDate);
      formData.append("calibrationperiod", this.editedItem.calibrationperiod);
      return formData;
    },
    initialize() {},
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    editItem(item) {
      this.getclientDevices();
      this.editedIndex = this.prohibitions.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem.regId = item.regId;
      this.editedItem.client = item;
      this.editedItem.regnum = item.regnum;
      this.editedItem.device = item.device;
      this.editedItem.installDate = item.installDate;
      this.editedItem.calibrationDate = item.calibrationDate;
      this.editedItem.make = item.make;
      this.editedItem.model = item.model;
      this.editedItem.year = item.year;
      this.editedItem.color = item.color;
      this.editedItem.vin = item.vin;
      this.editedItem.tag = item.tag;
      this.editedItem.tagstate = item.tagstate;
      this.editedItem.motorist = item.motorist;
      this.editedItem.DLnum = item.DLnum;
      this.editedItem.driverMobile = item.driverMobile;
      this.editedItem.driverAddress = item.driverAddress;
      this.editedItem.prohibitionnumber = item.prohibitionnumber;
      this.editedItem.prohibstart = item.prohibstart;
      this.editedItem.prohibend = item.prohibend;
      this.editedItem.conviction = item.conviction;
      this.editedItem.calibrationperiod = item.calibrationperiod;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch("createProhibition", fd);
      } else {
        /* eslint-disable no-console */
        // console.log(this.editedItem.client)
        var postdata = {
          vdata: fd,
          id: this.editedItem.regId,
        };
        this.$store.dispatch("editProhibition", postdata);
      }
      // window.location.reload()
      this.close();
    },
  },
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-data-table
      v-if="sortby=='prohibitions'"
        dense
        :headers="headers"
        :items="resultQuery"
        sort-by="name"
        :search="search"
        class="elevation-1"
        item-key="regId"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#26BB44" dark dense>
              <v-toolbar-title>PROHIBITIONS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>

              <v-menu
                v-model="menux"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>Select Filter</v-card-title>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-select
                        :items="states"
                        label="Select State"
                        v-model="filteredState"
                      ></v-select>
                    </v-list-item>

                    <v-list-item>
                      <v-select
                        :items="clients"
                        v-model="filteredClient"
                        item-text="client"
                        label="Select Client"
                        return-object
                      ></v-select>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menux = false"> Close </v-btn>
                    <v-btn color="primary" text @click="removeFilters">
                      Remove Filters
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
                            <v-menu
                v-model="menusort"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-sort</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>Sort By</v-card-title>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-select
                        :items="sorts"
                        label="Select Type"
                        v-model="sortby"
                      ></v-select>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menusort = false"> Close </v-btn>
                    <v-btn color="primary" text @click="removeSortby">
                      Default
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn color="blue" dark v-on="on">Add Prohibition</v-btn>
                </template>
                <v-card>
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-form
                    autocomplete="off"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              disabled
                              v-model="editedItem.regId"
                              label="ID"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              v-on:change="getclientDevices"
                              label="Select Client"
                              required
                              class="purple-input"
                              v-model="editedItem.client"
                              :items="clients"
                              item-text= "client"
                              return-object
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.client }}
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              :rules="regRules"
                              v-model="editedItem.regnum"
                              label="Registration Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              v-model="editedItem.device"
                              :items="devices"
                              :rules="deviceRules"
                              label="Select Device"
                              item-text="devId"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  dense
                                  :rules="installDateRules"
                                  v-model="editedItem.installDate"
                                  label="Install Date"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.installDate"
                                @input="menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-menu
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  dense
                                  :value="formatDate"
                                  :rules="calibDateRules"
                                  v-model="formatDate"
                                  label="Calibration Date"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-datetime-picker
                                v-model="editedItem.calibrationDate"
                                
                              ></v-datetime-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.make"
                              :rules="makeRules"
                              label="Make"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              :rules="modelRules"
                              v-model="editedItem.model"
                              label="Model"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isNumber($event)"
                              dense
                              :rules="yearRules"
                              v-model="editedItem.year"
                              label="Year"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isonlyLetter($event)"
                              dense
                              :rules="colorRules"
                              v-model="editedItem.color"
                              label="Color"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              :rules="vinRules"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.vin"
                              label="VIN"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              :rules="tagRules"
                              v-on:keypress="isLetter($event)"
                              v-model="editedItem.tag"
                              label="Tag Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.tagstate"
                              :items="states"
                              :rules="tagStateRules"
                              label="Tag State"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.motorist"
                              :rules="motoristRules"
                              label="Motorist"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.DLnum"
                              :rules="dlRules"
                              label="Driving Licence"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-on:keypress="isNumber($event)"
                              :rules="phoneRules"
                              v-model="editedItem.driverMobile"
                              label="Driver Mobile"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              dense
                              v-on:keypress="isLetter($event)"
                              :rules="addressRules"
                              v-model="editedItem.driverAddress"
                              label="Driver Address"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-on:keypress="isLetter($event)"
                              dense
                              v-model="editedItem.prohibitionnumber"
                              label="Probation Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  dense
                                  v-model="editedItem.prohibstart"
                                  label="Probation Start Date"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.prohibstart"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-menu
                              v-model="menu3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  dense
                                  v-model="editedItem.prohibend"
                                  label="Probation End Date"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.prohibend"
                                @input="menu3 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              v-model="editedItem.conviction"
                              :items="['Conviction', 'Diversion']"
                              label="Conviction/Diversion"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-autocomplete
                              dense
                              :rules="calibPRules"
                              v-model="editedItem.calibrationperiod"
                              :items="['30','60','90']"
                              label="Calibration Period"
                            >
                             <template slot="selection" slot-scope="data">
                                {{ data.item }} days
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :disabled="!valid"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </v-card>
        </template>
        <template slot="item.action" slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon
            class="mr-2 mt-2"
            larger
            color="red"
            @click="deleteProhibition(item)"
          >
            mdi-delete-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0">
            <v-list dense class="ma-0 pa-0">
              <v-list-item>
                <v-list-item-content>Vehicle ID</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.regId }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Client</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.client }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Registration Number</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.regnum }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Device ID</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.device }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Install Date</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.installDate }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Calibration Date</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.calibrationDate }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Make</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.make }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Model</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.model }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Year</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.year }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Color</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.color }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Tag Number</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.tag }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Tag State</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.tagstate }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Motorist</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.motorist }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Driving License</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.DLnum }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Probation Number</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.prohibitionnumber }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Probation Start Date</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.prohibstart }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Probation End Date</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.prohibend }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Conviction Diversion</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.conviction }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Calibration Period</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.calibrationperiod }} days
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </td>
        </template>
      </v-data-table>
            <v-data-table
        v-else-if="sortby=='drivers'"
        dense
        :headers="driverheaders"
        :items="drivers"
        sort-by="Dlnum"
        :search="search"
        class="elevation-1"
        item-key="Dlnum"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#26BB44" dark dense>
              <v-toolbar-title>DRIVERS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                                          <v-menu
                v-model="menusort"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-sort</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>Sort By</v-card-title>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-select
                        :items="sorts"
                        label="Select Type"
                        v-model="sortby"
                      ></v-select>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menusort = false"> Close </v-btn>
                    <v-btn color="primary" text @click="removeSortby">
                      Default
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </template>
        <template slot="item.action" slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template slot="item.delete" slot-scope="{ item }">
          <v-icon
            class="mr-2 mt-2"
            larger
            color="red"
            @click="deleteVehicle(item)"
          >
            mdi-delete-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>
       

        <template v-slot:expanded-item="{ headers,item}">

          <td :colspan="headers.length" class="ma-0 pa-0">
            <v-list dense class="ma-0 pa-0">
              <v-list-item >
                <v-list-item-content><b>Regid :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end" >{{
                  sitem.Regid
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Clientid:</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">
                  {{sitem.Clientid}}
                 </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Client :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Client
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Regnum :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem. Regnum
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Device :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Device
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Installdate :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Installdate
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Calibdate :</b></v-list-item-content>
                <v-list-item-content  v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Calibdate
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Make:</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Make
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Model :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Model
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Year :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Year
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Color :</b></v-list-item-content>
                <v-list-item-content  v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Color
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Vin :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Vin
                }}</v-list-item-content> </v-list-item
              >
              <v-list-item>
                <v-list-item-content><b>Tag :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Tag
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Tagstate :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Tagstate
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>CalPeriod :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.CalPeriod
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Conviction :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Conviction
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibend :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibend
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibnumber :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibnumber
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibstart :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibstart
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Motorist :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Motorist
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Dlnum :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Dlnum
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Drivermobile :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Drivermobile
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Driveraddress :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Driveraddress
                }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </td>
        </template>
      </v-data-table>
            <v-data-table
            v-else
        dense
        :headers="vehicleheaders"
        :items="vehicles"
        sort-by="name"
        :search="search"
        class="elevation-1"
        item-key="regnum"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#26BB44" dark dense>
              <v-toolbar-title>VEHICLES</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
                                          <v-menu
                v-model="menusort"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-sort</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>Sort By</v-card-title>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-select
                        :items="sorts"
                        label="Select Type"
                        v-model="sortby"
                      ></v-select>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menusort = false"> Close </v-btn>
                    <v-btn color="primary" text @click="removeSortby">
                      Default
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              
            </v-toolbar>
          </v-card>
        </template>
        
<!--
        <template v-slot:expanded-item="{ headers, items }">
          <v-data-table
        dense
        :headers="headers"
        :items="items"
        sort-by="name"
        :search="search"
        class="elevation-1"
        item-key="regId"
        show-expand
        single-expand
        :expanded.sync="expanded"
      />-->
         <template v-slot:expanded-item="{ headers,item}">

          <td :colspan="headers.length" class="ma-0 pa-0">
            <v-list dense class="ma-0 pa-0">
              <v-list-item >
                <v-list-item-content><b>Regid :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end" >{{
                  sitem.Regid
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Clientid:</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">
                  {{sitem.Clientid}}
                 </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Client :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Client
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Regnum :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem. Regnum
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Device :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Device
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Installdate :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Installdate
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Calibdate :</b></v-list-item-content>
                <v-list-item-content  v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Calibdate
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Make:</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Make
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Model :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Model
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Year :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Year
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Color :</b></v-list-item-content>
                <v-list-item-content  v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Color
                }}</v-list-item-content> </v-list-item
              ><v-list-item>
                <v-list-item-content><b>Vin :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Vin
                }}</v-list-item-content> </v-list-item
              >
              <v-list-item>
                <v-list-item-content><b>Tag :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Tag
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Tagstate :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Tagstate
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>CalPeriod :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.CalPeriod
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Conviction :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Conviction
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibend :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibend
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibnumber :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibnumber
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Prohibstart :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Prohibstart
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Motorist :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Motorist
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Dlnum :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Dlnum
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Drivermobile :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Drivermobile
                }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content><b>Driveraddress :</b></v-list-item-content>
                <v-list-item-content v-for="sitem in item.Prohibs" :key="sitem.Regid" class="align-end">{{
                  sitem.Driveraddress
                }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </td>

        </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
