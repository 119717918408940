var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"flutterpayment","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"col-md-8",attrs:{"align":"center"}},[_c('div',{staticStyle:{"background":"#334c3e !important","color":"white","border-radius":"20px","padding":"10px"}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"green"}},[_vm._v("mdi-cash-multiple")]),_vm._v("You are paying the amount "+_vm._s(this.$route.query.amount/100)+" ")],1)])],1)],1),(_vm.created)?_c('div',{staticClass:"container"},[_c('stripe-element-payment',{ref:"paymentRef",attrs:{"pk":_vm.pk,"elements-options":_vm.elementsOptions,"confirm-params":{  return_url:
                "https://alcobrakeadmin.com/successtransaction?mobile=" +
                this.$route.query.mobile +
                "&regnum=" +
                this.$route.query.regnum +
                "&email=" +
                this.$route.query.email +
                "&message=Dear customer your last transaction  is success with Payment id:" +
                this.$route.query.pi +
                "&paymentid=" +
                this.$route.query.pi +
                "&clearall=true",
            }}}),_c('br'),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"primary",attrs:{"left":""},on:{"click":_vm.finalPay}},[_vm._v("Pay Now")])],1)],1):_c('div',[_c('h1',[_vm._v("Thank You for Choosing Alcobrake")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }