import Vue from "vue";
export const userService = {
  login,
  parentlogin,
  parentlogout,
  fleetlogin,
  logout,
  fleetlogout
};
function parentlogin(sid, pmobile) {
  var info = {
    studentId: sid,
    parentMobile: pmobile
  };
  return Vue.axios
    .post("https://robopower.xyz/app/login/onparentlogin", info)
    .then(response => {
      var repdata = response.data;
      const myPromise = new Promise((resolve, reject) => {
        if (repdata != "user not found") {
          localStorage.setItem(
            "alcotrackerparentuser",
            JSON.stringify(repdata[0])
          );
          resolve({ ok: true, studData: repdata[0] });
        } else {
          reject("Promise is rejected");
        }
      });
      return myPromise;
    });
}
function login(email, password) {
    /* eslint-disable no-console */
  return Vue.axios
    .get("https://robopower.xyz/us/calogin/getuniquelogin?emailId=" + email+"&password="+password)
    .then(response => {
      var info = response.data[0];
              const myPromise = new Promise((resolve, reject) => {
            if (info) {
              localStorage.setItem("user", JSON.stringify(info));
              localStorage.setItem("alcobrakeadminreloader", false);
              localStorage.setItem("isDrivesync", false);
              //  else{
                resolve({ ok: true, userdata: info });
              // }
            } else {
              reject("Promise is rejected");
            }
          });
          return myPromise;
    });
}
function fleetlogin(email, password) {
  console.log("fff")
  /* eslint-disable no-console */
return Vue.axios
  .get("https://robopower.xyz/us/calogin/getuniquelogin?emailId=" + email+"&password="+password)
  .then(response => {
    var info = response.data[0];
    console.log(info)
            const myPromise = new Promise((resolve, reject) => {
          if (info) {
            localStorage.setItem("fleetuser", JSON.stringify(info));
            localStorage.setItem("alcobrakeadminfleetreloader", false);
            //  else{
              resolve({ ok: true, userdata: info });
            // }
          } else {
            reject("Promise is rejected");
          }
        });
        return myPromise;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.clear();

}
function parentlogout() {
  // remove user from local storage to log user out
  localStorage.clear();

}
function fleetlogout() {
  // remove user from local storage to log user out
  localStorage.clear();

}