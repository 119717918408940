<script>
import Vue from 'vue';
import router from "../router";
//import getInfo from "../scripts/getInfo";
export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
     
  }),

  computed: {

  },

  watch: {
    
  },

  created() {
    // this.initialize();
  },
  mounted() {

      if (this.$route.query.myinfo) {
        // console.log(this.$route.query.myinfo)
    this.paymentFinish()
      } 
  },

  methods: {
    paymentloadFormData() {
      var addInfo = JSON.parse(this.$route.query.myinfo)
      const paymentformData = new FormData();
      paymentformData.append("billnum", addInfo.billnum);
      paymentformData.append("client", addInfo.client);
      paymentformData.append("qty", addInfo.qty);
      paymentformData.append("items", this.$route.query.services);
      paymentformData.append("amounts", addInfo.amounts);
      paymentformData.append("total", addInfo.grandtotal);
      paymentformData.append("amtpaid", addInfo.amtpaid);
      paymentformData.append("balance", addInfo.balance);
      paymentformData.append("status", "paid");
      return paymentformData;
    },
    paymentFinish() {
      const fdpayment = this.paymentloadFormData()
      Vue.axios.post('https://robopower.xyz/v2/kvpayments/addpayment', fdpayment).then((res) => {
        console.log(res.data)
      })
    },

  }

};
</script>
<template>
  <v-container fluid>
  <v-card
    class="mx-auto"
    max-width="380"
    outlined
    style="margin-top:240px;background-color:white"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          <v-img  src="../../src/assets/successicon.png" width="100" height="100"/>
          <b>Success</b>
        </div>
        <v-list-item-subtitle><b>Your Last Transaction Success</b></v-list-item-subtitle>
        <v-list-item-subtitle>Payment ID :<b>{{this.$route.query.paymentid}}</b></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  </v-container>
</template>
