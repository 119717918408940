<script>
//import getInfo from '../scripts/getInfo';
import {mapGetters} from "vuex";

export default {
  data: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    search: "",
    show: false,
    service_types: [
        "60-Day Service Fee",
        "Administrative Fee (Paperwork)",
        "Disconnect (Temporary Bypass)",
        "Key-Ignition Install",
        "Key-Ignition Transfer",
        "Late Fee",
        "Mobile Service Fee",
        "Monthly Payment",
        "Office Service Fee (General Service)",
        "One-Time Free Service",
        "Phone Service Fee (General Service)",
        "Push Start Transfer",
        "Re-Connect (Temporary Bypass)",
        "Removal Fee",
        "Smart-Key/Push-Start Install"
    ],
    valid:true,
    headers: [
      {
        text: "Office",
        align: "left",
        value: "officeName"
      },
      { text: "Service Type", value: "servicetype" },
      { text: "Rate", value: "rate" },
      { text: "Service ID", value: "serviceId" },
      { text: "Actions", value: "action", sortable: false },
      { text: "Delete", value: "delete", sortable: false }
    ],
    serviceRules: [
      v => !!v || 'Select Service',
      // v => v && v.length >= 3 || 'Name must contain 3 or more characters',
      // v => v && v.length <= 50 || 'Name must be less than 50 characters'
    ],
    rateRules: [
      v => !!v || 'Rate is required',
      // v => v && v.length >= 3 || 'Name must contain 3 or more characters',
      // v => v && v.length <= 50 || 'Name must be less than 50 characters'
    ],
    officeRules: [
      v => !!v || 'Select Office',
      // v => v && v.length >= 3 || 'Name must contain 3 or more characters',
      // v => v && v.length <= 50 || 'Name must be less than 50 characters'
    ],
    editedIndex: -1,
    editedItem: {
      officeId: "",
      serviceId: "",
      servicetype: "",
      rate: ""
    },
    defaultItem: {
      officeId: "",
      serviceId: "",
      servicetype: "",
      rate: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Service" : "Edit Service";
    },
    ...mapGetters(['services']),
    ...mapGetters(['offices'])
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.mounteddata();
  },

  methods: {
      isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
      },
    mounteddata(){
      if(this.user.role=="Admin"){
    this.$store.dispatch('fetchServices')
    this.$store.dispatch('fetchOffices')
    } else{
      this.$store.dispatch('fetchdealerServices',this.user.dealerId)
    this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
    }
    },
    deleteService(service) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title: "Are you sure? You want to delete Service - " + service.serviceId,
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteService',service.serviceId);
          // this.$swal(
          //   "Deleted",
          //   "You successfully deleted this file",
          //   "success"
          // );
          this.$store.dispatch("alert/success", 'You successfully deleted this file').then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
          this.$store.dispatch("alert/error", "Your file is still intact").then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
        }
      }).catch((err)=>{
              this.$store.dispatch("alert/error", err).then(()=>{
    setTimeout(()=>{
      this.$store.dispatch("alert/clear")
    },2000)
    })
            })
    },
    loadFormData() {
      const formData = new FormData();
      formData.append("office", this.editedItem.officeId.officeId);
      formData.append("servicetype", this.editedItem.servicetype);
      formData.append("rate", this.editedItem.rate);
      return formData;
    },
    initialize() {},
    editItem(item) {
      this.editedIndex = this.services.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem.officeId = item;
      this.editedItem.serviceId = item.serviceId;
      this.editedItem.servicetype =item.servicetype;
      this.editedItem.rate = item.rate;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const fd = this.loadFormData();
      if (this.editedIndex == -1) {
        this.$store.dispatch('createService',fd);

      } else {
      var postdata ={
        sdata:fd,
        id:this.editedItem.serviceId
      }
        this.$store.dispatch('editService',postdata);
       }
          this.close();
    }
  }
};
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="services"
        sort-by="office_name"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-card color="grey lighten-4" flat>
            <v-toolbar flat color="#26BB44" class="" dark dense>
             <span style="font-size:40%">
              <v-toolbar-title>SERVICE FEE</v-toolbar-title>
              </span>
              <v-divider class="mx-4" inset vertical></v-divider>
                <v-row>
                <v-col cols="12" lg="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                </v-col>
                </v-row>
              <v-spacer class="mx-2"></v-spacer>
              
              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
            <v-row>
          <v-col cols="12" lg="12">
           <v-layout justify-end>
                <v-btn color="primary"  class="sm-0"  v-on="on" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"  >
                  Add Service
                </v-btn>
                 <v-icon v-else v-on="on" size="40px"  color="primary" >mdi-plus</v-icon>
             
           </v-layout>
              </v-col>
                  </v-row>
                </template>
                <v-card>
                    <v-form
                      autocomplete="off"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                  <v-toolbar flat color="#26BB44" class="" dark dense>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            v-model="editedItem.officeId"
                            :items="offices"
                            required
                            :rules="officeRules"
                            label="Select Office"
                            item-text="officeName"
                            item-value="officeName"
                            return-object
                          >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.officeName }}
                              </template>
                          </v-autocomplete>
                        </v-col>
                         <!-- <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            disabled
                            v-model="editedItem.serviceId"
                            label="Service ID"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            dense
                            required
                            :rules="serviceRules"
                            v-model="editedItem.servicetype"
                            :items="service_types"
                            label="Select Service"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            dense
                            required
                            :rules="rateRules"
                            v-on:keypress="isNumber($event)"
                            v-model="editedItem.rate"
                            label="Rate ($)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                    </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
             
            </v-toolbar>
          </v-card>
        </template>
        <template slot=item.action slot-scope="{ item }">
          <v-icon class="mt-2" color="orange" @click="editItem(item)">
            mdi-border-color
          </v-icon>
          <!-- <v-icon color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template slot="item.delete" slot-scope="{ item }">
                <v-icon
                  class="mr-2 mt-2"
                  larger
                  color="red"
                  @click="deleteService(item)"
                >
                  mdi-delete-circle
                </v-icon>
              </template>
        <template v-slot:no-data>
          <v-btn color="blue" @click="initialize">NO DATA</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
