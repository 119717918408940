<script>
import Vue from 'vue';
import config from '../config';
// import LocaleSwitcher from "../src/components/LocalSwitcher.vue";
export default {
  name: "Login",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      show: false,
      email: "",
      password: "",
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      mobileRules: [
        v => !!v || 'Mobile number is required',
        v => /^\d+$/.test(v) || 'Mobile number must be a valid integer',
      ],
      remail: "",
      rmobile: "",
      cpwd:"",
      npwd: "",
      changingdealer:null,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      },
      dialogfpwd: false,
      resetdlg: false,
      text:'',
      saveDisabled:null
    };
  },
  // components: {
  // LocaleSwitcher 
  // },
  beforeCreate() {

  },
  mounted(){
    // console.log(process.env.VUE_APP_STRIPE_KEY)
  },
  created() {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    login() {
      if (this.email && this.password) {
        var usermail = this.email;
        var userpassword = this.password;
        this.$store.dispatch("authentication/login", {
          usermail,
          userpassword
        });
      }
    },
    forgotpwd() {
      this.dialogfpwd = true;
    },
    changePassword() {
      console.log(this.changingdealer)
      const formData = new FormData();
      formData.append("dealerPassword", this.npwd);
      formData.append("dealerEmail", this.remail);
      Vue.axios.post(`https://robopower.xyz/us/cadealers/editdealerpassword/` + this.changingdealer.dealerId,formData)
        .then((res) => {
          console.log(res.data)
          this.resetdlg = false;
          this.$store
              .dispatch("alert/error", "Password Successfully Updated")
        })
    },
    confmail() {
      console.log(this.remail)
      console.log(this.rmobile)
      Vue.axios.get(`https://robopower.xyz/us/cadealers/getuniquedealermobile?dealeremail=` +this.remail + `&dealermobile=` + this.rmobile )
        .then((res) => {
          if (res.data != null) {
            this.changingdealer = res.data[0];
            this.dialogfpwd = false;
          this.resetdlg = true;
        }else{
          this.text = "Invalid EmailId or Mobile"
        }
      })
    },
    dialogfpwdclose(){
      this.remail = "";
      this.rmobile = "";
      this.dialogfpwd = false;
    },
    checkPassword() {
      this.saveDisabled = this.npwd !== this.cpwd || this.npwd.length < 4;
    },
  }
};
</script>

<template>
  <v-content>
    <div class="mt-4 ml-4">
      <v-dialog v-model="dialogfpwd" max-width="600px">
        <v-card>
          <v-card-text>
            <br/>
            <v-row style="flex-direction: row-reverse;">
            <v-icon @click="dialogfpwdclose">mdi-close</v-icon>
          </v-row>
            <p style="color: crimson;">{{ this.text }}</p>
            <v-text-field v-model="remail" label="Email" name="remail" :rules="emailRules">Email ID</v-text-field>
            <v-text-field v-model="rmobile" label="mobile" name="mobile" :rules="mobileRules">Mobile Number</v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="confmail" color="primary">submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="resetdlg" max-width="600px">
        <v-card>
          <v-card-text>
            <br/>
            <br/>
            <v-text-field @input="checkPassword" v-model="npwd" label="New Password" name="new password"></v-text-field>
            <v-text-field @input="checkPassword" v-model="cpwd" label="Confirm Password" name="confirm password"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="changePassword" :disabled="saveDisabled || npwd.length < 4 || npwd !== cpwd" color="primary">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col>
          <v-img max-height="40" max-width="200" src="../src/assets/Alogo.png"></v-img>
        </v-col>
        <!-- <v-col  class="d-flex justify-end align-center">
        <LocaleSwitcher />
      </v-col> -->
      </v-row>
    </div>
    <v-row><v-col align="center">
        <div class="main" style="text-align:center">
          <div class="para" style="color:#00A3A3">IOT FOR ROAD SAFETY <div class="roller">
              <span id="rolltext">Alcobrake-100<br />
                Alcobrake-200<br />
                Alcobrake-300<br />
                BREATHALYZERS<br />
                <span id="spare-time" style=" font-size: 1rem;letter-spacing: 0.1rem;color :black !important;">Simple &
                  Secure</span></span><br />
            </div>
          </div>

        </div>
      </v-col></v-row>

    <v-container fluid class="mt-8">
      <v-layout justify-center>
        <v-flex xs12 sm8 md4>

          <v-card class="elevation-12" style="border-radius:20px;border:1px solid white;color:black">
            <v-toolbar class="elevation-2" color="#00A3A3" flat style="border-radius:20px">
              <v-row>
                <v-col align="center">
                  <v-avatar color="black"
                    style="height: 80px !important;width: 80px !important;position: relative;bottom:30px;border:2px solid white !important">
                    <v-icon dark style="font-size:50px !important">
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-card-text class="mt-4">
              <v-form>
                <v-text-field @keyup.enter="login" v-model="email" label="Email" name="email" prepend-icon="mdi-account"
                  type="text" outlined></v-text-field>

                <v-text-field @keyup.enter="login" v-model="password" prepend-icon="mdi-lock"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]"
                  :type="show ? 'text' : 'password'" name="password" label="Password" hint="At least 8 characters"
                  counter @click:append="show = !show" outlined></v-text-field>
              </v-form>
              <v-row>
              <p style="cursor: pointer;margin-left: 5%;" @click="forgotpwd">Forgot Password ?</p>
              </v-row>
            </v-card-text>
            <v-row class="justify-center" style="margin-bottom: 2%;">
              <v-col class="col-md-4" align="center" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                <v-btn block color="primary" @click="login" class="form-control">Login</v-btn>
              </v-col>
              <v-col align="center" v-else>
                <v-btn color="primary" @click="login" class="form-control">Login</v-btn>
              </v-col>
            </v-row>

          </v-card>

        </v-flex>
      </v-layout>
    </v-container>
  </v-content>


</template>
<style scoped>
.para {
  text-align: center;
  text-transform: uppercase;
  color: #F1FAEE;
  font-size: 30px;
  font-weight: bold;
}

.roller {
  height: 50px;
  line-height: 4rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #2b71d3;
}


#spare-time {
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 1rem;
  margin-top: 0;
  color: #A8DADC;

}

.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 8s infinite;
}

@keyframes slide {
  0% {
    top: 0;
  }

  25% {
    top: -4rem;
  }

  50% {
    top: -8rem;
  }

  72.5% {
    top: -12.25rem;
  }
}

@media screen and (max-width: 600px) {
  .para {
    text-align: center;
    text-transform: uppercase;

    font-size: 2.125rem;
  }

  .roller {
    height: 2.6rem;
    line-height: 2.125rem;
  }



  .roller #rolltext {
    animation: slide-mob 5s infinite;
  }

  @keyframes slide-mob {
    0% {
      top: 0;
    }

    25% {
      top: -2.125rem;
    }

    50% {
      top: -4.25rem;
    }

    72.5% {
      top: -6.375rem;
    }
  }
}
</style>