<template>
  <v-app>
    <v-card style="width: 95%;margin-left: 2.5%;background-color: aliceblue;margin-top: 2%; height: 600px;">
      <v-toolbar>
        <v-toolbar-title style="font-weight: bold;margin-left: 1%;">{{ isgeofence?"Geofence":"Create Geofence" }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="isgeofence">+ Add an Entity <v-icon>mdi-chevron-down</v-icon></v-btn>
      </v-toolbar>
      <v-row v-if="isgeofence">
        <v-col class="col-sm-4">
          <v-toolbar>
            <v-toolbar-title>Map</v-toolbar-title>
            <v-spacer></v-spacer>
            <div style="width: 30%;">
              <v-text-field v-model="search" append-icon="mdi-magnify"></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-btn style="margin-left: 1%;">
              <v-icon>mdi-filter-outline</v-icon> Filter
            </v-btn>
          </v-toolbar>
          <v-list v-if="geofences.length>0" class="max-v-list-height">
            <v-list-item v-for="(item, index) in geofences" :key="index">
              <v-card style="padding: 2%; width: 100%; margin: 1%;">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.lon }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.lon }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-card>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col class="col-sm-8">
          <div ref="map" class="map-container"></div>
       </v-col>
      </v-row>
      <v-row v-else style="height: calc(100% - 64px);">
  <div style="margin: auto; text-align: center;">
    <v-btn @click="opendialog = true">
      <v-icon>mdi-select</v-icon> Create Geofence
    </v-btn>
  </div>
</v-row>
    </v-card>
    <v-dialog v-model="opendialog" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="headline">Geofence Name</span>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="geofenceName" label="Enter Geofence Name" />
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="opendialog = false">Cancel</v-btn>
      <v-btn color="primary" @click="startGeofenceCreation">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
       <!-- Dialog component -->
       <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Entity</span>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="newEntity.name" label="Name" />
            <v-text-field v-model="newEntity.lon" label="Longitude" />
            <v-text-field v-model="newEntity.lat" label="Latitude" />
            <v-text-field v-model="newEntity.radius" label="Radius" />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="dialog = false">Cancel</v-btn>
            <v-btn color="primary" @click="addEntity">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>



<script>
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import Vue from "vue";
import { watch } from 'vue';

export default {
  name: 'MapComponent',
  data() {
    return {
      items: [],
      createdBy:null,
      user:JSON.parse(localStorage.getItem("user")),
      search:null,
      map: null,
      geofence: null,
      fencedata: null,
      geometry: null,
      showDropPointPrompt: false,
      radiusKm: 0,
      pickupMarker: null,
      dropMarker: null,
      circleLayerId: 'radius-circle',
      selectingPickup: true,
      startPointName: '',
      stops: [],
      stopName: '',
      stopRadius: 0,
      dialog: false,
      isgeofence:false,
      opendialog: false,

      isaddentity:false,
      loadedEntity:null,
      geofenceName:null,
      newEntity: {
        name: '',
        lon: '',
        lat: '',
        radius: ''
      },
      dialogCoords: { lng: 0, lat: 0 }
    };
  },
watch:{
  isgeofence(val){
    this.isgeofence = val;
    this.initMap();
  }
},

  methods: {

    openDialog() {
      this.dialog = true;
    },

    backgps() {
      this.$router.push('/fleet/gpsdashboard');
    },

// addEntity(){
//   this.isaddentity = true;
// },

showDialog(lng,lat) {
  this.newEntity.lat = lat;
  this.newEntity.lon = lng;
      this.dialog = true;
    },
    startGeofenceCreation(){
      this.geofences =[]
  this.isgeofence=true;
      this.isaddentity=true;
      this.loadedEntity={
        "id":null,
        "name":this.geofenceName,
        "dealerId":this.user.dealerId,
        "geometry":`[]`,
        "metadata":{
          "created_at":"now",
          "created_by":this.user.userName || "TEST" 
        }     
      }
      console.log(this.user)
      this.opendialog = false;


      
},
Addview(){
  // this.loadedEntity={
  //       "id":null,
  //       "name":this.geofenceName,
  //       "dealerId":this.user.dealerId,
  //       "geometry":`[]`,
  //       "metadata":{
  //         "created_at":"now",
  //         "created_by":this.user.userName || "TEST" 
  //       }     
  //     }



},
 saveGeofence() {
      // Add new geofence to the list and update the map
      const newGeofence = {
        name: this.newEntity.name,
        lon: parseFloat(this.newEntity.lon),
        lat: parseFloat(this.newEntity.lat),
        radius: parseFloat(this.newEntity.radius)
      };
      this.geofences.push(newGeofence);
      this.dialog = false;
      this.initMap(); // Reinitialize the map to show the new geofence
    },

    addEntity() {
      console.log(this.loadedEntity)
      if(this.loadedEntity.id == null){
        const newEntity = {
        lon: this.newEntity.lon.toFixed(3).toString(),
        lat: this.newEntity.lat.toFixed(3).toString(),
        name: this.newEntity.name,
        radius: this.newEntity.radius.toString()
      };
      
      this.geofences.push(newEntity);
console.log(JSON.stringify(this.geofences),'geooooooooooooooooooooooooo')
      var formdata2 = new FormData();
      formdata2.append("dealerId","258"),
      formdata2.append("name",this.geofenceName)
      formdata2.append("geometry",JSON.stringify(this.geofences)),
      formdata2.append("metadata", JSON.stringify({
    "created_at": "now",
    "created_by": this.user.userName || "TEST"
  }));   
      
      Vue.axios.post('https://robopower.xyz/us/geofence/addgeofence',formdata2)
    .then(response => {
          if (response.data) {
            console.log(response.status)
            this.dialog = false;
           this.newEntity = {
        name: '',
        lon: '',
        lat: '',
        radius: ''
      },
            this.fetchGeofenceData();

          }
        })
      } else{
      const newEntity = {
        lon: this.newEntity.lon.toFixed(3).toString(),
        lat: this.newEntity.lat.toFixed(3).toString(),
        name: this.newEntity.name,
        radius: this.newEntity.radius.toString()
      };
      console.log(this.newEntity)
      
      this.geofences.push(newEntity);
      console.log(JSON.stringify(this.geofences),'fffffffffffffffffffffffffffffffff')
      console.log(this.loadedEntity.id)
      //POST
      var formdata = new FormData()
        formdata.append("geometry",JSON.stringify(this.geofences))
      Vue.axios.post('https://robopower.xyz/us/geofence/editgeofence/'+JSON.stringify(this.loadedEntity.id),formdata)
        .then(response => {
          if (response.data) {
            console.log(response.status)
            this.initMap();
            this.newEntity = {
        name: '',
        lon: '',
        lat: '',
        radius: ''
      },
      this.dialog = false;

          }
        })      
      }
    },

    createCircle(center, radius) {
      const points = 64;
      const coords = {
        latitude: center[1],
        longitude: center[0]
      };
      const km = radius / 1000;
      const ret = [];
      const distanceX = km / (111.32 * Math.cos(coords.latitude * Math.PI / 180));
      const distanceY = km / 110.574;

      for (let i = 0; i < points; i++) {
        const theta = (i / points) * (2 * Math.PI);
        const x = distanceX * Math.cos(theta);
        const y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return ret;
    },



    pointSelection(){
      try{
        const date = new Date();
            const datetime = 
              (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
              date.getDate().toString().padStart(2, '0') + '/' +
              date.getFullYear() + ' ' +
              date.getHours().toString().padStart(2, '0') + ':' +
              date.getMinutes().toString().padStart(2, '0') + ':' +
              date.getSeconds().toString().padStart(2, '0');
                  console.log(datetime,'date');
          }
          catch (err) {
            console.error(err);
        }

    },


    fetchGeofenceData() {
      console.log(this.user.dealerId)
      // Vue.axios.get('https://robopower.xyz/us/geofence/getdealergeofences?dealerId='+this.user.dealerId)
      Vue.axios.get('https://robopower.xyz/us/geofence/getdealergeofences?dealerId=258')

        .then(response => {
          if (response.data) {
            this.isgeofence = true;
            console.log(response.data, 'dddddddddd');
            const res = response.data;
            this.loadedEntity = res[0];
            console.log(res[0].geometry, 'resssssssssssssssssssssssssssssss');
            const geometryData = JSON.parse(res[0].geometry);
            this.geofences = geometryData;
            this.initMap();
          } else{
            this.isgeofence = false
          }
        })
        .catch(error => {
          console.error('Error fetching geofence data:', error);
        });
    },
    initMap() {
  this.$nextTick(() => {
    if (this.geofences.length === 0) {
      console.error('No geofence data available');
      // return;
    }

    mapboxgl.accessToken = 'pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA';
    this.map = new mapboxgl.Map({
      container: this.$refs.map, 
      style: 'mapbox://styles/mapbox/streets-v11',
      // center: [this.geofences[0].lon, this.geofences[0].lat], // Center on the first geofence
      center: [this.geofences[0]?.lon || 78.898, this.geofences[0]?.lat || 17.987],

      zoom: 9
    });

    this.map.on('load', () => {
      this.geofences.forEach((geofence, index) => {
        const circle = {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [geofence.lon, geofence.lat]
          }
        };

        this.map.addSource(`circle-${index}`, {
          'type': 'geojson',
          'data': circle
        });

const color = '#0000FF'; 

        this.map.addLayer({
          'id': `circle-radius-${index}`,
          'type': 'circle',
          'source': `circle-${index}`,
          'paint': {
            'circle-radius': {
              'base': 2,
              'stops': [
                [5, this.metersToPixelsAtMaxZoom(geofence.radius, geofence.lat)],
                [15, this.metersToPixelsAtMaxZoom(geofence.radius, geofence.lat)]
              ]
            },
            'circle-color': color,
            'circle-opacity': 0.2
          }
        });

        new mapboxgl.Marker({
          color: color
        })
        .setLngLat([geofence.lon, geofence.lat])
        .addTo(this.map);
      });

      // Add click event listener
      this.map.on('click', (e) => {
        const coords = e.lngLat;
        this.showDialog(coords.lng, coords.lat);
      });
    });
  });
},

    metersToPixelsAtMaxZoom(meters, latitude) {
      const earthCircumference = 40075017; // Earth's circumference in meters
      const latitudeRadians = latitude * (Math.PI / 180);
      const metersPerPixel = earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, 20);
      return meters / metersPerPixel;
    }
  },
  mounted() {
    this.fetchGeofenceData();
  }
};
</script>

<style scoped>
.map-container {
  height: 520px;
  width: 710px;
  margin-left: -20px;
}
.max-v-list-height {
  max-height: 440px;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-wrap: normal;
}
</style>
