module.exports = {
  devServer: {
    host: 'localhost',
    proxy: 'http://alcobrakeadmin.com',
  },
  transpileDependencies: ["vuetify"],
  pluginOptions: {
    i18n: {
       locale: "'english'",
       fallbackLocale: "'english'",
       localeDir: "locales",
        enableInSFC: false
       }
   }
  // configureWebpack: (config) => {
  //   if (process.env.NODE_ENV === 'development') {
  //     config.devtool = 'source-map'
  //   }
  // }
}