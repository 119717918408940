<script>
import {mapGetters} from "vuex";
import Vue from "vue";
  export default {
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        logg:{
          testattempts:0,
          passevents:0,
          failevents:0,
          insufficientvolumes:0
        },
        tab: null,
        officeInfo: null,
        officeInfo2: null,
        officeInfo3: null,
        vehicleInfo:null,
        // devices: [],
        total_clients: '',
        totalDevices: '',
        availableDevices: '', 
        devicesWentToService: '',
        installedDevices: '',
        active_tab:0,
        items: [
          '', '', 'DEVICE REPORTS'
        ],
      officeRules: [
      v => !!v || 'Select office',
      ],
      }
    },
    watch:{
      active_tab(val){
        /* eslint-disable no-console */
        if(val ==0){
          this.$store.state.clientcount={}
         this.$store.state.devicecount={}     
        this.officeInfo2= null;
        this.officeInfo3=null;
        } else if(val ==1){
          this.$store.state.totalrevenue={}
         this.$store.state.devicecount={}  
         this.officeInfo= null;
        this.officeInfo3=null;
        } else if(val==2){
          this.$store.state.totalrevenue={}
          this.$store.state.clientcount={}
          this.officeInfo= null;
        this.officeInfo2= null;
        } else{
          this.$store.state.totalrevenue={}
          this.$store.state.clientcount={}
          this.$store.state.devicecount={}        
        }
      }
    },
    computed: {
      ...mapGetters(['installations']),
    ...mapGetters(['clients']),
    ...mapGetters(['fullclients']),
    ...mapGetters(['clientdevices']),
    ...mapGetters(['devices']),
    ...mapGetters(['offices']),
    ...mapGetters(['officeinvoices']),
    ...mapGetters(['totalrevenue']),
    ...mapGetters(['clientcount']),
    ...mapGetters(['devicecount']),
    },
    beforeDestroy(){
    this.$store.state.totalrevenue={}
    this.$store.state.clientcount={}
    this.$store.state.devicecount={}  
    },
    mounted() {
      this.mounteddata();
    },
    methods: {
      mounteddata(){
        
        if(this.user.role=="Admin"|| this.user.role == 'superadmin'){
     this.$store.dispatch('fetchfullClients')
    this.$store.dispatch('fetchOffices')
    this.$store.dispatch('fetchDevices')   
      } else{
     this.$store.dispatch('fetchdealerfullClients',this.user.dealerId)
    this.$store.dispatch('fetchdealerOffices',this.user.dealerId)
    this.$store.dispatch('fetchdealerDevices',this.user.dealerId)
      }
      },
      getSales() {
        /* eslint-disable no-console */
        if(this.officeInfo != null){
        this.$store.dispatch('fetchofficeInvoices',this.officeInfo)
        }
      },
      getEvents(){
// console.log(this.vehicleInfo);
this.logg={
          testattempts:"Loading...",
          passevents:"Loading...",
          failevents:"Loading...",
          insufficientvolumes:"Loading..."
        },
Vue.axios
        .get(
          "https://robopower.xyz/us/cadevices/getuniquedevice?sno=" +
            this.vehicleInfo
        )
        .then((res) => {
          // console.log(res.data)
          if(res.data != null){
            var devInf = res.data[0];
            Vue.axios
        .get(
          "https://robopower.xyz/v2/eeprom/getalllogs2?cbid=" + devInf.cbid
        )
        .then((response) => {
          if (response.data != null) {
            // console.log("cbid based")
            var remData = response.data;
            // console.log(remData);
            this.logg.passevents = remData.filter(
        (item) => item.passfailstatus === "1" && item.typeofevent=="254"
      ).length;
      this.logg.failevents = remData.filter(
        (item) => item.passfailstatus === "0" && item.typeofevent=="254"
      ).length;
      this.logg.testattempts = remData.filter(
        (item) => item.typeofevent === "65" || item.typeofevent=="254"
      ).length;
      this.logg.insufficientvolumes = remData.filter(
        (item) => item.typeofevent === "65"
      ).length;


          } else{
            this.logg={
          testattempts:0,
          passevents:0,
          failevents:0,
          insufficientvolumes:0
        }
      }
        })

          }

        })
      },
    getDevicesInfo() {
      if(this.officeInfo3 != null){
      this.$store.dispatch('fetchofficeDevices',this.officeInfo3)
      }
    },
    getClients() {
      if(this.officeInfo2 != null){
      this.$store.dispatch('fetchfullofficeClients',this.officeInfo2)
      }
    }
     
    }
  }
</script>

<template>
<v-row class="justify-center mt-10">
    <v-col class="col-md-6">
      
      <v-tabs 
       background-color="#00A3A3" color="white" 
      v-model="active_tab" 
      dark centered style="border-radius:20px">
      <v-tabs-slider color="black"></v-tabs-slider>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-sale</v-icon>
        SALES REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-account-group</v-icon>
        CLIENTS REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-cellphone-link</v-icon>
        DEVICE REPORT
      </v-tab>
      <v-tab style="font-weight:bold">
        <v-icon left>mdi-cellphone-link</v-icon>
        EVENTS REPORT
      </v-tab>

      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
            <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo"
                            @change="getSales"
                            :items="offices"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId" 
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
            <p class="p-text">
              Total Revenue <span class="p-number">${{totalrevenue.totalrev}}</span>
            </p>

            <p class="p-text">Revenue from past 30days <span class="p-number">${{totalrevenue.lastrev}}</span>
            </p>
          </v-card-text>
          </v-card>
          </v-col>
        </v-row>
        
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
             <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo2"
                            :items="offices"
                            @change="getClients"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId"
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
            <p class="p-text">Total Clients <span class="p-number">{{clientcount.allclients}}</span> 
            </p>

            <p class="p-text">New clients from past 30days <span class="p-number">{{clientcount.latestclients}}</span> 
            </p>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
       
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
                 <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="officeInfo3"
                            :items="offices"
                            @change="getDevicesInfo"
                            label="Select Office*"
                            item-text="officeName"
                            item-value="officeId"
                          ></v-autocomplete>
              </div>
                          
                        </v-col>
             <p class="p-text">Total devices <span class="p-number">{{devicecount.alldevices}}</span>
            </p>

            <p class="p-text">Available devices <span class="p-number">{{devicecount.availabledevices}}</span>
            </p>

            <p class="p-text">Devices went to service <span class="p-number">{{devicecount.servicedevices}}</span> 
            </p>

            <p class="p-text">Installed devices <span class="p-number">{{devicecount.installeddevices}}</span>
            </p>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
   
      </v-tab-item>
      <v-tab-item>
        <v-row class="justify-center mt-5">
          <v-col class="col-md-10">
            <v-card flat color="#334c3e" style="border-radius:20px">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <div style="background:white;padding:25px 8px 8px 5px;border-radius:10px;border:2px solid #00A3A3">
                 <v-autocomplete
                            dense outlined
                            required
                            :rules="officeRules"
                            v-model="vehicleInfo"
                            @change="getEvents"
                            :items="fullclients"
                            label="Select Vehicle*"
                            item-text="sno"
                            item-value="sno" 
                          ></v-autocomplete>
              </div>
                         
                        </v-col>
                        <p class="p-text">
              TOTAL TESTS <span class="p-number">${{logg.testattempts}}</span>
            </p>
            <p class="p-text">
              PASS TESTS <span class="p-number">${{logg.passevents}}</span>
            </p>

            <p class="p-text">FAIL TESTS<span class="p-number">${{logg.failevents}}</span>
            </p>
            <p class="p-text">INSUFFICIENT VOLUMES<span class="p-number">${{logg.insufficientvolumes}}</span>
            </p>
          </v-card-text>
          </v-card>
          </v-col>
        </v-row>
        
      </v-tab-item>
    </v-tabs>

    </v-col>
</v-row>
 
</template>

<style scoped>
.p-text{
  color:white;
  text-align: center;
  margin-top:10px;
}
.p-number
{
  background:#00A3A3;padding:5px;border-radius:20px;margin-left:10px
}
template
{
  background: white !important;
}
</style>