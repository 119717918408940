import axios from "axios";
import html2pdf from "html2pdf.js";
import moment from "moment";

export function distance(data,decimalPlaces) {
  const distancetravel = roundNumber(data.distance,decimalPlaces);
  const isloading = data.loading
  return { distancetravel, isloading }; 
}
function roundNumber(number, decimalPlaces) {
  const factor = 10 ** decimalPlaces;
  return Math.round(number * factor) / factor + "KM";
}
export function payonline(data){
  const  clientphone1 = data.clientphone1;
  const regnum = data.regnum;
  const  email = data.email;
  const amount = data.invoc.pending;
  return {clientphone1,regnum,email,amount};
  
}
// export function paymentLink(item,dialog21){
//   axios
//     .get("https://robopower.xyz/us/payments/getsecretkeynew?dealerid="+this.user.dealerId+"&amount="+ item.invoc.pending)
//     .then((response) => {
//       // console.log(item.email,response,'hsbfkjsf')
//       const linkemail = item.email;
//       const linkmobile= item.clientphone1;
//       const dialog = dialog21;
//       const paymentlink = "https://alcobrakeadmin.com/paymentlink?pi="+response.data.client_secret+"&regnum="+item.regnum+"&amount="+item.invoc.pending+"&mobile="+item.clientphone1+"&email="+item.email
//       // console.log(linkemail,linkmobile,dialog,paymentlink,"linkemail,linkmobile,dialog,paymentlink")
//       return {linkemail,linkmobile,dialog,paymentlink};
//      })
//       .catch((err) => {
//       this.$store.dispatch("alert/error", err).then(() => {
//         setTimeout(() => {
//           this.$store.dispatch("alert/clear");
//         }, 2000);
//       });
//     });
// }
function getmonthList(id,log) {
  //initial install date
  const startDatetime = moment(id.installDate, "MM/DD/YYYY HH:mm:ss");

  // Array to store objects
  const dataList = [];

  // Number of objects to create

  const endDat = moment(
    moment().format("MM/DD/YYYY HH:mm:ss"),
    "MM/DD/YYYY HH:mm:ss"
  );
  // const endDat = moment().format('MM/DD/YYYY HH:mm:ss');
  // console.log(startDatetime)
  const monthsDiff = endDat.diff(startDatetime, "months");

  // console.log(Math.round(monthsDiff+1));
  const numberOfObjects = Math.round(monthsDiff + 1) / 2;

  // Loop to create objects with incremented datetime and push data
  for (let i = 1; i < numberOfObjects + 1; i++) {
    const currentDatetime = startDatetime.clone().add(2 * i - 2, "months");
    const endedDatetime = startDatetime.clone().add(2 * i, "months");
    // console.log(currentDatetime);
    // console.log(endedDatetime);
    var fData = [];
    // let currentMonthRange = null;
    log.forEach((item) => {
      1;
      const checkDate = moment(item.rtime, "MM/DD/YYYY HH:mm:ss");
      const isBetween = checkDate.isBetween(currentDatetime, endedDatetime);
      // console.log(isBetween)
      if (isBetween) {
        fData.push(item);
      }
    });
    const dataObject = {
      id: i + 1,
      startDate: currentDatetime.format("MM/DD/YYYY HH:mm:ss"),
      endDate: endedDatetime.format("MM/DD/YYYY HH:mm:ss"),
      data: fData,
    };
    dataList.push(dataObject);
  }

  return dataList;
}
export function Report(item,client,user,logs){
var wholelist = getmonthList(item,logs);
  const nameParts = client.client.split(" ");
  if (nameParts.length === 1) {
    const firstName = nameParts[0];
  } else if (nameParts.length === 2) {
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    const names1 = firstName;
    const names3 = lastName;
  } else {
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    const middleName = nameParts
      .slice(1, nameParts.length - 1)
      .join(" ");
    var names1 = firstName;
    var names2 = middleName;
    var names3 = lastName;
  }
const contentDiv = document.createElement("div");
    contentDiv.innerHTML = `
    <p style="margin-left:320px">Data Summary Report</p>

    <div class="container" style="display: flex;justify-content: space-between;margin:30px;">
        <div class="column" style="width: 45%;padding: 10px;">
            <p>Control Box S/N:${client.sno}</p>
            <p style="margin-top:20px">First Name:${names1} </p>
            <p style="margin-top:20px">Last Name:${names3}</p>
            <p style="margin-top:20px">Agency Name:${user.userName}</p>
          
        </div>

        <div class="column" style="width: 45%;padding: 10px;">
            <p>Customer No:${client.id}</p>
            <p style="margin-top:20px">Middle Name :${names2}</p>
            <p style="margin-top:20px">License No:${client.DLnum}</p>
            <p style="margin-top:20px">State/Country:${client.state} </p>
            <p style="margin-top: 20px">mileage: <u>__________________</u></p>

        </div>
    </div>`
   
wholelist.map((res) => {
  var rest = res.data;
  var datastats = {
    total: 0,
    pass: 0,
    fail: 0,
    enginerunstate: 0,
    refused: 0,
    IncorrectBreathSamples: 0,
    Bypass: 0,
    othertampering: 0,
    hot: 0,
    permanantlockout: 0,
    PowerDisconnections: 0,
    HandsetDisconnections: 0,
    HardwareErrors: 0,
    highalc: 0,
    medalc: 0,
    lowalc: 0,
  };
  datastats.pass = rest.filter(
    (item) => item.passfailstatus === "PASS"
  ).length;
  datastats.fail = rest.filter(
    (item) => item.passfailstatus === "FAIL"
  ).length;
  datastats.enginerunstate = rest.filter(
    (item) => item.typeofevent === "ENGINE ON"
  ).length;
  datastats.refused = rest.filter(
    (item) => item.typeofevent === "TEST_REFUSED_RR_TEST"
  ).length;
  datastats.othertampering = rest.filter(
    (item) =>
      item.typeofevent === "COOL_SAMPLE" ||
      item.typeofevent === "HOT_SAMPLE"
  ).length;
  datastats.Bypass = rest.filter(
    (item) => item.typeofevent === "BYPASS"
  ).length;
  datastats.PowerDisconnections = rest.filter(
    (item) => item.typeofevent === "CAR_BATTERY_TAMPERING"
  ).length;
  datastats.HandsetDisconnections = rest.filter(
    (item) => item.typeofevent === "SAMPLER_DISCONNECTED"
  ).length;
  datastats.permanantlockout = rest.filter(
    (item) => item.typeofevent === "LOCKOUT 0"
  ).length;
  datastats.highalc = rest.filter(
    (item) =>
      parseFloat(item.bacval) >= 20 && parseFloat(item.bacval) < 255
  ).length;
  datastats.medalc = rest.filter(
    (item) =>
      parseFloat(item.bacval) >= 10 && parseFloat(item.bacval) < 20
  ).length;

  datastats.lowalc = rest.filter(
    (item) =>
      parseFloat(item.bacval) >= 0 && parseFloat(item.bacval) < 10
  ).length;
  datastats.total = rest.length;
  const totalvoilationdata = [
    datastats.othertampering +
      datastats.HandsetDisconnections +
      datastats.refused,
  ];
  const itemDiv = document.createElement('div');
  itemDiv.innerHTML = 
   `<div class="container" style="display: flex;justify-content: space-between;margin:30px;">
    <div class="column" style="width: 45%;padding: 10px;">
      <p style="margin-top:20px">Start Date:${res.startDate}</p>
        <p style="margin-top:20px">Low Alc: ${datastats.lowalc} </p>
        <p style="margin-top:20px">High Alc:${datastats.highalc}</p>
        <p style="margin-top:20px">Failed Retest:${datastats.fail} </p>
        <p style="margin-top:20px">Total Violations: ${totalvoilationdata}</p>
      </div>
      <div class="column" style="width: 45%;padding: 10px;">
          <p style="margin-top:20px">End Date:${res.endDate}</p>
          <p style="margin-top:20px">Med Alc:${datastats.medalc} </p>
          <p style="margin-top:20px">Illegal Start: ${datastats.Bypass} </p>
          <p style="margin-top:20px">Refuse Retest: ${datastats.refused}</p>
          <p style="margin-top:20px">Total Test: ${datastats.total} </p>
      </div>
    </div>`
    contentDiv.appendChild(itemDiv);
});
setTimeout(()=>{
html2pdf(contentDiv, {
      margin: 1,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    },5000);
  },10000)
}
export function Report2(item,logs){
         var rest = logs;
          const llegalStarts = rest.filter(
            (item) =>  item.typeofevent === "BYPASS"
          ).length;
          const HighBrAC = rest.filter(
            (item) =>
              parseFloat(item.bacval) >= 20 && parseFloat(item.bacval) < 255
          ).length;
          const RRRefused = rest.filter(
            (item) => item.typeofevent === "TEST_REFUSED_RR_TEST"
          ).length;
          const EngineStops = rest.filter(
            (item) => item.typeofevent === "ENGINE OFF"
          ).length;
         const EngineStarts = rest.filter(
            (item) => item.typeofevent === "ENGINE ON"
          ).length;
          const HandsetDisconnections = rest.filter(
            (item) => item.typeofevent === "SAMPLER_DISCONNECTED"
          ).length;
          return {llegalStarts,HighBrAC,RRRefused,EngineStops,EngineStarts,HandsetDisconnections}
}